import React, { useContext, useState } from 'react';
import { message } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

import { NavLink } from 'react-router-dom';
import client from 'apollo';
import { AppContext } from 'AppContext';
import Button from 'atoms/Button';
import Meta from 'atoms/Meta';
import Card from 'atoms/Card';
import Input from 'atoms/Input';
import {
  BUTTON_TYPE,
  META_TYPE,
  FORM_NAME,
  ONBOARDING,
  ACTIONS_TYPE,
  LINKS,
  REGEX,
} from 'utils/constants';
import { SET_PASSWORD } from 'graphql/Onboarding/Mutations';
import * as Sentry from '@sentry/react';
import { UndifferLogo } from 'assets';
import { useForm } from 'antd/lib/form/util';
import {
  Container,
  EmailVerified,
  Title,
  Logo,
  Background,
  Wrapper,
  Form,
  Terms,
} from './AntStyled';
import TermsAndPolicy from '../TermsAndPolicy';

const SetPassword = (props) => {
  const { history, intl } = props;
  const { dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const onFinish = async (values) => {
    const { password, newPassword } = values;
    if (password === newPassword) {
      try {
        setLoading(true);
        await client
          .mutate({
            mutation: SET_PASSWORD,
            variables: {
              newPassword,
            },
          })
          .then(() => {
            setLoading(false);
            history.replace(ONBOARDING.SELECTION);
            dispatch({ type: ACTIONS_TYPE.LOCAL_SIGN_UP, data: true });
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
        //
      }
    } else {
      message.error(
        intl.formatMessage({ id: 'resetPassword.errorMessages.confirm' })
      );
    }
  };

  const handleFieldsChange = (e) => {
    form.setFieldsValue({ [e?.[0]?.name[0]]: e?.[0]?.value?.trim() });
  };

  return (
    <Container>
      <Background>
        <Logo src={UndifferLogo} alt="logo"></Logo>
        <NavLink to="/help" className="bottom-right">
          <FormattedMessage id="terms.help" />
        </NavLink>
        <NavLink to="/copyright" className="bottom-left">
          <FormattedMessage id="terms.copyright" />
        </NavLink>
      </Background>
      <Wrapper>
        <Card padding="32">
          <Meta title={META_TYPE.SET_PASSWORD} />
          <Form
            form={form}
            onFieldsChange={handleFieldsChange}
            name={FORM_NAME.BASIC}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Title>
              <FormattedMessage id="resetPassword.title" />
            </Title>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.errorMessage" />
                  ),
                },
                {
                  whitespace: true,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.errorMessage" />
                  ),
                },
                {
                  pattern: REGEX.PASSWORD,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.passwordPattern" />
                  ),
                },
              ]}
            >
              <Input type="password" placeholder="Password" allowClear />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="Confirm Password"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.newPassword.errorMessage" />
                  ),
                },
                {
                  min: 6,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.minCharacters" />
                  ),
                },
                {
                  whitespace: true,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.errorMessage" />
                  ),
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Confirm password"
                allowClear
              />
            </Form.Item>
            <Form.Item className="mb-0">
              <Button
                accent
                className="right"
                loading={loading}
                type={BUTTON_TYPE.PRIMARY}
                htmlType={BUTTON_TYPE.SUBMIT}
                name={<FormattedMessage id="resetPassword.confirm" />}
              />
            </Form.Item>
          </Form>
        </Card>
        <TermsAndPolicy />
      </Wrapper>
    </Container>
  );
};

export default injectIntl(SetPassword);
