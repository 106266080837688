import React, { useContext, useEffect, useState } from 'react';
import { Layout, Tabs } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from 'atoms/InfoIcon';
import { AppContext } from 'AppContext';
import Card from 'atoms/Card';
import { ACTIONS_TYPE } from 'utils/constants';
import ability from 'utils/ability/ability';
import { FeatureActivation } from 'features/components/FeatureActivation';
import { FeatureDeactivation } from 'features/components/FeatureDeactivation';
import { Content, Wrapper } from './AntStyled';
import WorkTimeSchedule from './Attendance/WorkTimeSchedule';
import ManageLeaves from './Leaves/ManageLeaves';
import Breaks from './Attendance/Breaks';
import CheckInSettings from './CheckIn';
const { TabPane } = Tabs;

const AttendanceSettings = () => {
  const { state } = useContext(AppContext);

  return (
    <Layout>
      <Content>
        <Wrapper>
          <Card padding="16" className="card">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Timing" key="1">
                <WorkTimeSchedule locationId={state.loginUser.location.id} />
              </TabPane>
              <TabPane tab="Leaves" key="2">
                <ManageLeaves locationId={state.loginUser.location.id} />
              </TabPane>
              <TabPane tab="Breaks" key="3">
                <Breaks locationId={state.loginUser.location.id} />
              </TabPane>
              <TabPane tab="Check-in" key="4">
                <CheckInSettings locationId={state.loginUser.location.id} />
              </TabPane>
            </Tabs>
          </Card>
        </Wrapper>
      </Content>
    </Layout>
  );
};

export default injectIntl(AttendanceSettings);
