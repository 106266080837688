import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    profileImage
    originalEmail
  }
`;

export const groupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    color
    type
  }
`;

export const questionFragment = gql`
  fragment QuestionFragment on FeedbackQuestion {
    id
    questionType
    sequenceNo
    question
    choices
    scaleLabel
  }
`;

export const feedbackFragment = gql`
  fragment FeedbackFragment on Feedback {
    id
    createdBy{
      ...UserFragment
    }
    type
    title
    responsePercent
    description
    groups{
      ...GroupFragment
    }
    scheduleOn
    endOn
    status
    isTemplate
    resultVisibility
    questions{
      questions{
        ...QuestionFragment
      }
      count
    }
  }
  ${userFragment}
  ${groupFragment}
  ${questionFragment}
`;
