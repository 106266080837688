import moment from 'moment';
import _map from 'lodash/map';
import React, { useState, useEffect, useCallback } from 'react';

const Timer = ({
  punches, pause, showBreakTimer, status,
}) => {
  const calculateTimer = useCallback(() => {
    let totalTime = 0;
    _map(punches, (punch, index) => {
      if (!punch.result) {
        return;
      }
      const diff = punch.status === 'PUNCHOUT'
        ? moment(punch.punchOut).diff(punch.punchIn, 'seconds') : moment().diff(punch.punchIn, 'seconds');
      if (showBreakTimer) {
        totalTime += punch.result.includes('BREAK') || punch.isBreak ? diff : 0;
      } else {
        totalTime += punch.result.includes('BREAK') || punch.isBreak ? 0 : diff;
      }
    });
    return totalTime;
  }, [punches, showBreakTimer]);

  const [totalTime, setTotalTime] = useState(calculateTimer());

  useEffect(() => {
    if (!pause) {
      const timer = setTimeout(() => {
        setTotalTime(calculateTimer());
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [calculateTimer, totalTime, pause]);

  useEffect(() => {
    setTotalTime(calculateTimer());
  }, [showBreakTimer, calculateTimer]);

  const hours = `${parseInt(totalTime / 3600, 10)}`;
  const mins = `${parseInt((totalTime % 3600) / 60, 10)}`;
  const seconds = `${totalTime % 60}`;
  return <span>{hours.length === 1 ? `0${hours}` : hours}:{mins.length === 1 ? `0${mins}` : mins}:{seconds.length === 1 ? `0${seconds}` : seconds}</span>;
};

export default Timer;
