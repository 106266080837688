import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import _get from 'lodash/get';
import Button from 'atoms/Button';
import { Row, Col } from 'antd';
import UserList from 'organisms/UserList';
import { GET_USERS } from 'graphql/Settings/Users/Queries';
import { useQuery } from '@apollo/client';
import { BUTTON_TYPE, FETCH_POLICY, UNDEFINED } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import { ArrowIcon, CalendarIcon } from 'assets';
import moment from 'moment';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { LeaveRange } from './MockData';
import {
  Modal,
  Form,
  DatePicker,
  Select,
  TextBox,
  Arrow,
  Calendar,
} from './AntStyled';

const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

export default function ({
  createLeaveRequest,
  setIsLeaveModalVisible,
  isLeaveModalVisible,
  userGroups,
  leaveTypes = [],
}) {
  const [groupManager, setGroupManager] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { data: userData } = useQuery(GET_USERS, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    variables: {
      where: {
        groupIds: userGroups
          ? userGroups.map((a) => parseInt(a.group.id, 10))
          : UNDEFINED,
      },
    },
  });

  const LeaveTypeChildren = _map(leaveTypes, (items, index) => (
    <Option key={index} value={items}>
      {items}
    </Option>
  ));

  const LeaveRangeChildren = _map(LeaveRange, (items, index) => (
    <Option key={index} value={items.value}>
      {items.range}
    </Option>
  ));

  const showModal = () => {
    setIsLeaveModalVisible(true);
  };

  const handleOk = () => {
    setIsLeaveModalVisible(false);
  };

  const handleCancel = () => {
    setIsLeaveModalVisible(false);
  };

  const handleSubmit = (values) => {
    createLeaveRequest(values, form, setLoading);
  };

  useEffect(() => {
    if (userData) {
      setGroupManager(
        _filter(userData.users.users, (e) =>
          _find(e.userGroups, (y) => y.role === 'MANAGER')
        )
      );
    }
  }, [userData]);

  return (
    <span>
      <Button
        onClick={() => showModal()}
        className="button"
        name="Request Leave"
        type={BUTTON_TYPE.PRIMARY}
      />
      <Modal
        width="700px"
        title="Request Leave"
        visible={isLeaveModalVisible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <span>
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
            initialValues={{ from: moment(), to: moment() }}
          >
            <Form.Item
              label={
                <FormattedMessage id="myProfile.formRules.leaveType.label" />
              }
              name="leaveTypes"
              className="left-section"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="myProfile.formRules.leaveType.errorMessage" />
                  ),
                },
              ]}
            >
              <Select
                placeholder="Leave Type"
                allowClear
                showSearch
                filterOption={(input, option) => {
                  const role = _get(option, 'children', '');
                  return (
                    role && role.toLowerCase().includes(input.toLowerCase())
                  );
                }}
                suffixIcon={<Arrow src={ArrowIcon} />}
                getPopupContainer={(trigger) => trigger?.parentNode}
              >
                {LeaveTypeChildren}
              </Select>
            </Form.Item>
            <Form.Item>
              {userData && (
                <UserList
                  style={{ MarginBottom: '0px' }}
                  label={
                    <FormattedMessage id="myProfile.formRules.sendTo.label" />
                  }
                  name="assignmentIds"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="myProfile.formRules.sendTo.errorMessage" />
                      ),
                    },
                  ]}
                  mode="multiple"
                  users={groupManager}
                />
              )}
            </Form.Item>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  required
                  className="no-gap"
                  label={
                    <FormattedMessage id="myProfile.formRules.startFrom.label" />
                  }
                  name="from"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="myProfile.formRules.startFrom.errorMessage" />
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    defaultValue={moment().format('YYYY-MM-DD')}
                    format={dateFormat}
                    suffixIcon={<Calendar src={CalendarIcon} />}
                    disabledDate={(current) =>
                      moment().add(-1, 'days') >= current
                    }
                  />
                </Form.Item>
                <Form.Item
                  className="left-section"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="myProfile.formRules.startFrom.errorMessage" />
                      ),
                    },
                  ]}
                  name="fromDayType"
                >
                  <Select
                    placeholder="Select Range"
                    allowClear
                    showSearch
                    className="dropdown"
                    filterOption={(input, option) => {
                      const role = _get(option, 'children', '');
                      return (
                        role && role.toLowerCase().includes(input.toLowerCase())
                      );
                    }}
                    suffixIcon={<Arrow src={ArrowIcon} />}
                    getPopupContainer={(trigger) => trigger?.parentNode}
                  >
                    {LeaveRangeChildren}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  required
                  className="no-gap"
                  label={
                    <FormattedMessage id="myProfile.formRules.endOn.label" />
                  }
                  name="to"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="myProfile.formRules.endOn.errorMessage" />
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    defaultValue={moment()}
                    format={dateFormat}
                    suffixIcon={<Calendar src={CalendarIcon} />}
                    disabledDate={(current) =>
                      moment().add(-1, 'days') >= current
                    }
                  />
                </Form.Item>
                <Form.Item
                  className="left-section"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="myProfile.formRules.endOn.errorMessage" />
                      ),
                    },
                  ]}
                  name="toDayType"
                >
                  <Select
                    placeholder="Select Range"
                    className="dropdown"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      const role = _get(option, 'children', '');
                      return (
                        role && role.toLowerCase().includes(input.toLowerCase())
                      );
                    }}
                    suffixIcon={<Arrow src={ArrowIcon} />}
                    getPopupContainer={(trigger) => trigger?.parentNode}
                  >
                    {LeaveRangeChildren}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              className="gap"
              required
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="myProfile.formRules.reasonDescription.errorMessage" />
                  ),
                },
              ]}
              colon={false}
              label="Reason Description"
              name="description"
            >
              <TextBox rows={6} />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                primary
                name="Request Leave"
                className="btn-request"
                htmlType={BUTTON_TYPE.SUBMIT}
              />
            </Form.Item>
          </Form>
        </span>
      </Modal>
    </span>
  );
}
