import { Layout } from 'antd';
import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { scrollbars } from 'design/Styled';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`

`;
