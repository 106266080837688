import React, { useContext, useState, useEffect } from 'react';
import _map from 'lodash/map';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Spin, Menu } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { ArrowIcon, BellIcon, CheckIcon } from 'assets';
import { useMutation, useQuery } from '@apollo/client';
import client, { subscriptionClient } from 'apollo';
import Badge from 'atoms/Badge';
import Notifications from 'pages/Notifications';
import { AppContext } from 'AppContext';
import {
  UPDATE_CHECK_IN_STATUS,
  CHECK_IN_AGAIN,
  CHECK_IN,
} from 'features/people/graphql/CheckIns/CheckIn/mutations';
import { UPDATE_USER_LAST_SEEN } from 'graphql/Notifications/Mutations';
import {
  ACTIONS_TYPE,
  EMPTY_STRING,
  FETCH_POLICY,
  CHECKIN_STATUS,
  BUTTON_TYPE,
  POP_CONFIRM_PLACEMENT,
  MENU_MODE,
} from 'utils/constants';
import { injectIntl } from 'react-intl';
import Timer from 'atoms/Timer';
import * as Sentry from '@sentry/react';
import {
  GET_TODAY_CHECK_IN,
  GET_TODAY_PUNCH_IN,
} from 'features/people/graphql/CheckIns/CheckIn/queries';
import { WORK_STATUS_SUBSCRIPTION } from 'features/people/graphql/CheckIns/subscription';
import Button from 'atoms/Button';
import { useLocation } from 'react-router-dom';
import { CHECK_OUT } from 'features/people/graphql/CheckIns/CheckOut/mutations';
import ability from 'utils/ability/ability';
import {
  statusOption,
  statusOptionAfterCheckedIn,
  statusOptionCheckedOut,
  statusColor,
  statusText,
} from './StatusData';
import {
  Clock,
  HeaderWrapper,
  TagContent,
  Wrapper,
  Arrow,
  Bell,
  StatusWrapper,
  StyledPopover,
} from './AntStyled';

const Header = (props) => {
  const { title } = props;
  const appContext = useContext(AppContext);
  const {
    state: { loginUser, checkInStatus, notificationCount },
    dispatch,
  } = appContext;
  const { pathname } = useLocation();
  const {
    data: todaysCheckInData,
    refetch: refetchCheckIn,
  } = useQuery(GET_TODAY_CHECK_IN, { fetchPolicy: FETCH_POLICY.CACHE_FIRST });
  const {
    data: todaysPunchInData,
    refetch: refetchPunchIn,
    loading: punchInLoading,
  } = useQuery(GET_TODAY_PUNCH_IN, { fetchPolicy: FETCH_POLICY.CACHE_FIRST });
  const [checkInMutation, { loading: checkingIn }] = useMutation(CHECK_IN);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(EMPTY_STRING);
  const [checkedIn, setCheckedIn] = useState(true);
  const [checkedOut, setCheckedOut] = useState(false);
  const [punches, setPunches] = useState();
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [key, setKey] = useState();

  const getStatusChildrens = () => {
    let children = statusOption;
    if (checkedOut && status === CHECKIN_STATUS.DONE) {
      children = statusOptionCheckedOut;
    } else if (checkedIn || checkedOut) {
      children = statusOptionAfterCheckedIn;
    }
    return _map(children, (items, index) => {
      if (status === items.value && index.toString() !== key) {
        setKey(index.toString());
      }
      return (
        <Menu.Item key={index} onClick={() => handleChange(items.value)}>
          <span>{items.text}</span>
          <span>
            <img src={CheckIcon} alt="check-icon" height={20} width={20} />
          </span>
        </Menu.Item>
      );
    });
  };

  useEffect(() => {
    if (todaysCheckInData) {
      if (todaysCheckInData?.todaysCheckIn) {
        setCheckedIn(true);
        setStatus(todaysCheckInData?.todaysCheckIn?.status);
        setCheckedOut(todaysCheckInData?.todaysCheckIn?.checkOut);
      } else if (checkInStatus && !isEmpty(checkInStatus)) {
        setStatus(checkInStatus?.status);
      } else {
        setCheckedIn(false);
      }
    }
  }, [checkInStatus, todaysCheckInData]);

  useEffect(() => {
    if (todaysPunchInData) {
      setPunches(todaysPunchInData?.todaysPunches);
    }
  }, [checkInStatus, todaysPunchInData]);

  useEffect(() => {
    refetchPunchIn().catch((e) => {
      Sentry.captureException(e);
      // eslint-disable-next-line no-console
      console.log(e);
    });
  }, [checkInStatus, refetchPunchIn]);

  const closeNotificationModal = () => {
    setShowNotificationModal(false);
  };

  const onBadgeClick = () => {
    if (loginUser?.id) {
      client
        .mutate({
          mutation: UPDATE_USER_LAST_SEEN,
          variables: {
            lastNotificationSeenAt: new Date(),
            where: {
              id: parseInt(loginUser?.id, 10),
            },
          },
        })
        .catch((e) => {
          Sentry.captureException(e);
          // eslint-disable-next-line no-console
          console.log(e);
        });
    }
    setShowNotificationModal(true);
  };

  const handleChange = async (value) => {
    if (value === status) {
      return;
    }
    setLoading(true);
    if (checkedOut && status === 'DONE') {
      client
        .mutate({
          mutation: CHECK_IN_AGAIN,
          refetchQueries: [
            {
              query: GET_TODAY_CHECK_IN,
            },
          ],
        })
        .then(() => {
          refetchPunchIn().catch((e) => {
            Sentry.captureException(e);
            // eslint-disable-next-line no-console
            console.log(e);
          });
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          Sentry.captureException(e);
          // eslint-disable-next-line no-console
          console.log(e);
        });
    } else if (value === 'DONE') {
      client
        .mutate({
          mutation: CHECK_OUT,
          variables: {
            data: {
              manualCheckout: true,
            },
          },
          refetchQueries: [
            {
              query: GET_TODAY_CHECK_IN,
            },
          ],
        })
        .then(() => {
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          Sentry.captureException(e);
          // eslint-disable-next-line no-console
          console.log(e);
        });
    } else {
      client
        .mutate({
          mutation: UPDATE_CHECK_IN_STATUS,
          variables: {
            data: {
              status: value,
            },
          },
          refetchQueries: [
            {
              query: GET_TODAY_CHECK_IN,
            },
          ],
        })
        .then(() => {
          refetchPunchIn().catch((e) => {
            Sentry.captureException(e);
            // eslint-disable-next-line no-console
            console.log(e);
          });
          setLoading(false);
        })
        .catch((e) => {
          Sentry.captureException(e);
          // eslint-disable-next-line no-console
          console.log(e);
          setLoading(false);
        });
    }
  };

  const handleCheckIn = () => {
    checkInMutation().then(({ data }) => {
      const checkinStatus = _get(data, 'checkIn.status');
      const createdAt = _get(data, 'checkIn.createdAt');
      dispatch({
        type: ACTIONS_TYPE.CHECK_IN_STATUS,
        data: { status: checkinStatus, time: createdAt },
      });
      refetchCheckIn().catch((e) => {
        Sentry.captureException(e);
        // eslint-disable-next-line no-console
        console.log(e);
      });
    });
  };

  useEffect(() => {
    subscriptionClient
      .subscribe({
        query: WORK_STATUS_SUBSCRIPTION,
        variables: { userId: loginUser?.id },
      })
      .subscribe({
        next(data) {
          if (
            _get(
              data,
              'checkInStatus.status' && _get(data, 'checkInStatus.punches')
            )
          ) {
            const {
              checkInStatus: {
                status: subscriptionStatus,
                punches: subscriptionPunches,
              },
            } = data;
            setStatus(subscriptionStatus);
            setPunches(subscriptionPunches);
            // commented next because it conflicts with new user scenarios
            // setCheckedIn(status !== CHECKIN_STATUS.CHECKING_IN);
            setCheckedOut(subscriptionStatus === 'DONE');
          }
        },
        error(err) {
          console.error(err);
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HeaderWrapper>
      {showNotificationModal && (
        <Notifications closeNotificationModal={closeNotificationModal} />
      )}
      <div className="title-content">{title}</div>
      {!pathname.includes('/settings') && (
        <Wrapper color={status && statusColor[status]}>
          {ability.can('use', 'people_feature')
            && status === EMPTY_STRING
            && !checkedIn && (
            <Button
              name="Check In"
              type={BUTTON_TYPE.PRIMARY}
              className="check-in-btn"
              onClick={handleCheckIn}
              loading={checkingIn}
            />
          )}
          {ability.can('use', 'people_feature') && status && punches && (
            <Spin spinning={loading || punchInLoading}>
              <TagContent className="splitline">
                <Clock color={statusColor[status]}>
                  <ClockCircleOutlined style={{ fontSize: '16px' }} />
                  <Timer
                    className="check-in-clock"
                    punches={punches}
                    showBreakTimer={status === CHECKIN_STATUS.ON_BREAK}
                    pause={
                      loading
                      || punchInLoading
                      || !(
                        status === CHECKIN_STATUS.CHECKED_IN
                        || status === CHECKIN_STATUS.ON_BREAK
                      )
                    }
                    status={status}
                  />
                </Clock>
                <StyledPopover
                  placement={POP_CONFIRM_PLACEMENT.BOTTOM_RIGHT}
                  content={(
                    <Menu selectedKeys={[key]} mode={MENU_MODE.IN_LINE}>
                      {getStatusChildrens()}
                    </Menu>
                  )}
                  getPopupContainer={triggerNode => triggerNode}
                >
                  <StatusWrapper color={statusColor[status]}>
                    <div className="status">{statusText[status]}</div>
                    <div>
                      <Arrow
                        color={status && statusColor[status]}
                        src={ArrowIcon}
                      />
                    </div>
                  </StatusWrapper>
                </StyledPopover>
              </TagContent>
            </Spin>
          )}
          <Badge
            count={notificationCount}
            onClick={() => onBadgeClick()}
            content={<Bell src={BellIcon} className="icon-bell" />}
          />
        </Wrapper>
      )}
    </HeaderWrapper>
  );
};

export default injectIntl(Header);
