import { gql } from '@apollo/client';

export const CREATE_SOP = gql`
  mutation createSOP($data: createSOPInput) {
    createSOP(data: $data){
      id
      title
      description
      departments{
        id
      }
      body
      writtenBy{
        id
      }
      version
      status
      isTemplate
      company{
        id
      }
    }
  }
`;

export const UPDATE_SOP = gql`
  mutation updateSOP($where: SOPWhereUniqueInput!, $data: updateSOPInput) {
    updateSOP(data: $data,where: $where){
      id
      title
      description
      departments{
        id
      }
      body
      writtenBy{
        id
      }
      version
      status
      isTemplate
      company{
        id
      }
    }
  }
`;

export const PUBLISH_SOP = gql`
  mutation publishSop ($where: SOPWhereUniqueInput!) {
  publishSOP(where: $where){
    id
  }
}
`;
