const Strings = {
  error: {
    somethingWentWrong: 'Something went wrong!',
  },
  alreadyRegistered: {
    title: 'Domain Already Registered',
    description:
      'This domain is already registered with us, please contact your admin',
  },
  dashboard: {
    blockers: ' Blockers',
    tasks: ' Tasks',
    online: 'ONLINE',
    offline: 'OFFLINE',
    hrs: ' hrs',
    title: 'Dashboard',
    leavesName: {
      halfDay: 'Half Day, Yayy!',
      festival: 'Happy Holiday',
      groupFestival: 'Happy Holidays',
    },
    leavesMessage: {
      firstHalf: "It's a slow start today, the",
      secondHalf: 'Today is an early wrap up,',
      off: 'is an off!',
      weekend: "It's the Weekend, go unwind!",
      holiday: 'Holiday on account of',
      celebration: 'celebrations!',
    },
    leavesEmpty: {
      halfDay: 'You were on a',
      leave: 'leave on this day',
      weekend: 'No tasks to show as it was a',
      pastHoliday: 'It was a Holiday for',
      yesterdayHoliday: 'Yesterday was a Holiday for',
      celebration: 'Hope you enjoyed!',
      checkInYesterday: "Didn't check-in yesterday",
      checkInPast: "Didn't check-in on this day",
    },
  },
  dailyGoals: {
    text: 'Daily Goals',
    active: 'Active',
    archived: 'Archived',
    archiveSuccess: 'Goal archived successfully!',
  },
  blockers: {
    text: 'Blockers',
    active: 'Active',
    archived: 'Archived',
    archiveSuccess: 'Blocker archived successfully!',
  },
  todos: {
    text: 'Todos',
    current: 'Current',
    history: 'History',
    action: 'Action on request',
    reason: 'Reason Description',
    formRules: {
      isRequired: 'This field is required!',
    },
  },
  header: {
    projects: 'Projects',
    departments: 'Departments',
    all: 'All',
    takeBreak: 'Take a Break',
    me: 'Me',
    reset: 'Reset',
    action: 'Action in progress...',
    success: 'Status updated successfully!',
  },
  confirmationModal: {
    sure: 'Are you sure?',
    yes: 'Yes, delete it!',
    no: 'No, cancel!',
  },
  attendanceLeaves: {
    all: 'All',
    myself: 'Myself',
    departments: 'Departments',
    projects: 'Projects',
    me: 'Me',
    title: 'Attendance & Leaves',
    attendanceLeaves: 'Attendance & Leaves',
    leave: 'Leave',
    attendance: 'Attendance',
    infoTitle: 'Attendance',
    infoContent:
      'the action or state of going regularly to or being present at a place or event.',
  },
  confirmation: {
    account: "Didn't received email?",
    title: 'Email Sent!',
    description: 'We’ve emailed a special link to',
    sentEmail: 'An email has been sent to',
    confirmationLink:
      'Click the link in the mail to confirm your email address and get started. ',
    forgotConfirmationLink:
      'Click the link in the mail to reset your password.',
    verifiedTitle: 'Email verified successfully!',
    verifying: 'Verifying...',
    wait: 'Please wait while we verify your email',
    congratulations: 'Congratulations!',
    verifiedSuccessfully: 'Your email id is successfully verified!',
    verificationFailed: 'Sorry, verification failed!',
    newRequest: 'Please request a new verification link.',
    requestToManager: 'Please contact to your admin.',
    needHelp: 'Need Help?',
    copyright: '© 2020 undiffer',
  },
  comments: {
    successMessages: {
      action: 'Action in progress..',
      add: 'Comment successfully added',
      update: 'Comment successfully updated',
      delete: 'Comment successfully deleted',
      addComment: 'Please enter comment',
    },
  },
  checkIn: {
    sure: 'Are you sure to delete this action?',
    working: 'Working',
    onBreak: 'On Break',
    notStarted: 'Not Started',
    workDone: 'Work Done',
    blockers: 'Blockers',
    noBlocker: 'No Blockers',
    noTask: 'No Goals',
    update: 'Update',
    next: 'Next',
    previous: 'Previous',
    auto: 'Auto Checkout at 8:00 PM',
    online: 'ONLINE',
    tasks: 'Goals',
    startBreak: 'Start Break',
    getStarted: 'Get Started',
    checkOut: 'Check Out',
    endBreak: 'End Break',
    today: 'Plan For Today',
    progress: 'Progress',
    checkIn: 'Check In',
    checking: 'Check In:',
    title: 'Good Morning! it’s ',
    description: 'The way to get started is to quit talking and begin doing.',
    assignedBlockers: 'Assigned Blockers',
    noAssignedBlockers: 'No Assigned Blockers',
    successMessages: {
      action: 'Action in progress..',
      update: 'Plan and blocker successfully updated',
      planDelete: 'Plan successfully deleted',
      blockerDelete: 'Blocker successfully deleted',
    },
    errorMessages: {
      properHours: 'Please enter proper hours',
    },
  },
  dropdownButton: {
    saveAsDraft: 'Save as Draft',
  },
  git: {
    github: 'GitHub',
    gitlab: 'GitLab',
  },
  checkOut: {
    blockers: 'Blockers',
    getStarted: 'Get Started',
    today: 'Tasks',
    checking: 'Checking Out',
    checkOut: 'Check Out',
    title: 'You checked out at',
    description: 'Thank you. Happy Weekend!',
    close: 'Close',
    successMessages: {
      action: 'Action in progress..',
      apply: 'Checkout successfully applied',
    },
  },
  Leaves: {
    request: 'Leave Request',
    summary: 'Leave Summary',
  },
  insights: {
    text: 'Insights',
    checkIn: 'Check Ins',
    tasks: 'Goals',
    blockers: 'Blockers',
    action: 'Action in progress...',
    select: {
      location: 'Please select location',
      group: 'Please select group',
      time: 'Please select time period',
    },
  },
  activities: {
    text: 'Activities',
  },
  departments: {
    departments: 'Departments',
    addDepartment: 'Add Department',
    create: 'Create Department',
    update: 'Update Department',
    delete: 'Delete Department',
    successMessages: {
      action: 'Action in progress..',
      addDepartment: 'Department successfully added',
      updateDepartment: 'Department successfully updated',
      deleteDepartment: 'Department successfully deleted',
      addUser: 'User successfully added',
      deleteUser: 'User successfully deleted',
      sure: "You won't be able to revert this action!",
      memberUpdated: 'Member role updated successfully!',
    },
  },
  groupSetting: {
    text: 'Group Access Settings',
    columns: {
      header: 'Header',
      comments: 'Comments',
      attendance: 'Attendance',
      generalNotification: 'GeneralNotification',
      tasks: 'Tasks',
    },
    successMessages: {
      action: 'Action in progress..',
      update: 'Group Access successfully updated',
    },
  },
  notificationSetting: {
    text: 'Notification Settings',
    summary:
      'Recieve report and summaries notification of your team on your communication channels.',
    successMessages: {
      action: 'Action in progress..',
      update: 'Notification successfully updated',
    },
  },
  integrations: {
    title: 'Integrations',
    connectSlack: 'Ask your admin to integrate Slack workspace.',
    enableFeature: 'No feature has been enabled yet!',
    activity: {
      sure:
        'first you need to delete webhook from repository then you can disconnect it from here,have you done that?',
    },
    disconnect: {
      sure: 'Are you sure,You want to disconnect?',
    },
    notification: 'Select what notification you want to send',
    summary:
      'We’ll send you notifications on Google chat when any new Announcements or \nFeedback are published and Stand-up reminder if you haven’t checked in.',
  },
  generalSetting: {
    text: 'General Settings',
    applyChanges: 'Apply Changes',
    name: "Your workspace's name.",
    successMessages: {
      action: 'Action in progress..',
      update: 'Company successfully updated',
    },
    errorMessage: {
      fileUpload: 'You can only upload JPG/PNG file!',
      fileSize: 'Image must smaller than 5MB!',
    },
    formRules: {
      logo: {
        label: 'Logo (use square logo)',
        errorMessage: 'Please add company logo',
      },
      name: {
        label: 'Company Name',
        errorMessage: 'Please enter company name',
      },
      workspace: {
        label: 'Workspace Name',
        errorMessage: 'Please enter workspace name',
      },
      timeZone: {
        label: 'Select Timezone',
        errorMessage: 'Select Timezone',
      },
    },
  },
  attendanceSetting: {
    title: 'Attendance Settings',
  },
  forgotPassword: {
    title: 'Forgot Password?',
    description: 'Enter your Email address associated with your account',
    send: 'Send',
    text: 'Forgot Password',
    formRules: {
      email: {
        errorMessage: 'Please add email!',
        emailPattern: 'The input is not valid Email!',
      },
    },
  },
  login: {
    account: 'Don’t have an account?',
    title: 'Log in',
    forgot: 'Forgot password?',
    follow: 'Login with one of the following platforms',
    signUp: 'Sign up',
    signIn: 'Sign in',
    resend: 'Resend Email',
    text: 'Login',
    formRules: {
      email: {
        errorMessage: 'Please add email!',
      },
      password: {
        errorMessage: 'Please add password!',
      },
    },
  },
  me: {
    tasks: 'Goals',
    dailyGoals: "Today's Goals",
    text: 'Me',
    myBlockers: 'Blockers',
    Blockers: 'Blockers Assigned',
    reason: 'Reason for archive',
    spent: 'Add spent hours',
    save: 'Save',
    action: 'Action in progress',
    success: 'Successfully added!',
    failed: 'Request Failed!',
    formRules: {
      name: {
        errorMessage: 'Please enter name',
      },
      groups: {
        errorMessage: 'Please select group',
      },
      estimation: {
        hoursPattern: 'Please enter proper hours',
        errorMessage: 'Please enter hours',
      },
      reason: {
        label: 'Reason',
        errorMessage: 'Please enter reason',
      },
      spent: {
        label: 'Hours spent',
        errorMessage: 'Please enter hours spent',
      },
    },
  },
  time: {
    title: 'People',
    all: 'All',
    myself: 'Myself',
    departments: 'Departments',
    projects: 'Projects',
    me: 'Me',
    leave: 'Leave',
    attendance: 'Attendance',
    infoTitle: 'People',
    infoContent: 'Description',
  },
  mainSidebar: {
    analytics: 'Analytics',
    alex: 'Alex P',
    settings: 'Settings',
    profile: 'Profile',
    inviteTeammates: 'Invite teammates',
    integrations: 'Integrations',
    signOut: 'Logout',
    workspaceSettings: 'Workspace Settings',
    logicwind: 'LOGICWIND',
    home: 'Home',
    attendanceLeaves: 'Attendance',
    dashboard: 'Dashboard',
    myProfile: 'My Profile',
    profileSettings: 'My Settings',
    dailyGoals: 'Daily Goals',
    blockers: 'Blockers',
    okr: 'OKR',
    today: 'Today',
    me: 'Me',
    todos: 'My Todos',
    people: 'People',
    teams: 'Teams',
    meetings: 'Meetings',
    updates: 'My Updates',
    actionList: 'Action List',
    goals: 'Goals',
    checkList: 'CheckList',
    insight: 'Insights',
    activities: 'Activities',
    announcements: 'Announcements',
    guide: 'Guide',
    messages: 'Messages',
    projects: 'Projects',
    departments: 'Departments',
    actionItems: 'Action Items',
    search: 'Seleavearch',
    feedBack: 'FeedBack',
    help: 'Help',
    update: 'Update',
    leave: 'Leaves',
    leaveRequest: 'Leave Request',
    leaveSummary: 'Leave Summary',
    teamGoals: 'Team Goals',
    teamBlockers: 'Team Blockers',
  },
  onboarding: {
    title: 'Tell us more about you',
    changeEmail: 'Change Email Address',
    addLogo: 'Add Logo',
    allSet: 'You are all set!',
    addPicture: 'Add Picture',
    invite: 'Invite Team Members',
    inviteToday:
      'Invite the people you work with to start having more effective meetings today.',
    inviteRequired: 'Add email to continue or skip',
    back: 'Back',
    continue: 'Continue',
    features: 'Select Features to you want start with',
    trial: 'Do great work, together with undiffer',
    attendance: 'Attendance & Leaves',
    inviteTeam: 'Invite Team members info here...',
    together:
      'Create agendas together, record and assign action items, take notes, and keep track of all of these interactions in one place!',
    organization: 'Tell Us About your organization',
    workspace: 'Workspace',
    workspaceNotFound: 'Workspace not found!',
    workspaceRequestRejected: 'Workspace request rejected',
    skip: 'Skip',
    admin: 'admin',
    getStarted: 'Let’s Get Started',
    alreadyRegistered: 'Already Registered',
    authError: 'Auth Error',
    confirmation: 'Confirmation',
    special: 'We’ve emailed a special link to',
    click: 'Click the link to confirm your email address and get started.',
    selection: {
      new: 'Create new one',
      existing: 'Select the existing one',
      cancelRequest: 'Cancel request',
      results: {
        tryagain: 'Try Again',
        successMessages: 'Your request has been sent to',
        errorMessage:
          'The workspace you looking for may have different name as we haven’t found any workspace as name',
        notification: 'You’ll be notified with email for the status of request',
        requestRejected: 'Your workspace joining request is rejected by admin.',
      },
      location: {
        text: 'Select Location',
        submit: 'Submit',
      },
    },
    successMessages: {
      action: 'Action in progress..',
      feature: 'Please select at least one Feature',
    },
    errorMessage: {
      fileUpload: 'You can only upload JPG/PNG file!',
      fileSize: 'Image must smaller than 5MB!',
    },
    formRules: {
      name: {
        label: 'Company Name',
        errorMessage: 'Please enter company name!',
      },
      subDomain: {
        errorMessage: 'Please enter workspace name!',
      },
      fullName: {
        label: 'Your Full Name',
        fullNamePattern: 'Invalid full name',
        errorMessage: 'Please enter your full name!',
      },
    },
  },
  profile: {
    analytics: 'Analytics',
    attendance: 'Attendance',
    count: '2',
    details: 'Details',
    description: 'UX Designer  |  12:30 PM Thu Sep 15, 2020 (PDT)',
    issues: 'Issues',
    profile: 'Profile',
    leave: 'Leaves',
    tasksBlockers: 'Goals and Blockers',
    productivity: 'Productivity',
    hours: 'Group wise hours spent',
    userName: 'Ronald Richards',
    upcomingLeaves: 'Upcoming Leaves',
  },
  projects: {
    create: 'Create Project',
    update: 'Update Project',
    delete: 'Delete Project',
    addProject: 'Add Project',
    addLeader: 'Add Leaders',
    addManage: 'Add Managers',
    addMember: 'Add Members',
    group: 'Groups',
    managers: 'Managers in this team',
    members: 'Members',
    leaders: 'Leaders in this team',
    projects: 'Projects',
    text: 'Project',
    sure: "You won't be able to revert this action!",
    successMessages: {
      action: 'Action in progress..',
      deleteProject: 'Project successfully deleted',
      addUser: 'User successfully added',
      updateUser: 'User successfully updated',
      deleteUser: 'User successfully deleted',
      addProject: 'Project successfully added',
      updateProject: 'Project successfully updated',
      sure: 'Are you sure to delete this user?',
    },
    formRules: {
      required: 'This field is required!',
      name: {
        label: 'Name',
        errorMessage: 'Please enter name',
      },
      color: {
        label: 'Color',
        errorMessage: 'Please select color',
      },
      managers: {
        label: 'Managers',
        errorMessage: 'Please select managers',
      },
      members: {
        label: 'Members',
        errorMessage: 'Please select members',
      },
      createdOn: {
        label: 'CREATED ON',
      },
      group: {
        errorMessage: 'Select group',
      },
      role: {
        errorMessage: 'Select role',
      },
    },
  },
  groups: {
    create: 'Create Group',
    update: 'Update Group',
    delete: 'Delete Group',
    addGroup: 'Add Group',
    addLeader: 'Add Leaders',
    addManage: 'Add Managers',
    addMember: 'Add Members',
    group: 'Group',
    managers: 'Managers in this team',
    members: 'Members',
    leaders: 'Leaders in this team',
    groups: 'Groups',
    text: 'Group',
    sure: "You won't be able to revert this action!",
    successMessages: {
      action: 'Action in progress..',
      deleteGroup: 'Group successfully deleted',
      addUser: 'User successfully added',
      deleteUser: 'User successfully deleted',
      addGroup: 'Group successfully added',
      updateGroup: 'Group successfully updated',
      sure: 'Are you sure to delete this user?',
    },
    formRules: {
      required: 'This field is required!',
      type: {
        label: 'Type',
        errorMessage: 'Please enter group type',
        placeholder: 'Please select group type',
      },
      name: {
        label: 'Group Name',
        errorMessage: 'Please enter name',
      },
      color: {
        label: 'Select color of the group',
        errorMessage: 'Please select color',
      },
      managers: {
        label: 'Managers',
        errorMessage: 'Please select managers',
      },
      members: {
        label: 'Members',
        errorMessage: 'Please select members',
      },
      createdOn: {
        label: 'Created On:',
      },
      role: {
        errorMessage: 'Select role',
      },
    },
  },
  myProfile: {
    workingDays: 'Working Days',
    workingHours: 'Hours Worked',
    total: 'Working Hours',
    workingHour: 'Working Hour',
    week: 'Leaves this week',
    issues: 'Issues',
    profileText: 'Settings',
    count: '40',
    changePassword: 'Change Password',
    profileSettings: 'Profile Settings',
    requestLeave: 'Request Leave',
    text: 'Leave',
    leaves: 'Leaves',
    presentDays: 'Present Days',
    trackedHours: 'Tracked Hours',
    breakHours: 'Break-Time Hours',
    tabs: {
      upcoming: 'Upcoming',
      past: 'Past',
      details: 'Details',
      attendance: 'Attendance',
      leaves: 'Leaves',
      history: 'History',
    },
    successMessages: {
      action: 'Action in progress..',
      changePassword: 'Password successfully changed',
      match: "New password & confirm password doesn't match",
      updateUser: 'User successfully updated',
    },
    errorMessage: {
      fileUpload: 'You can only upload JPG/PNG file!',
      fileSize: 'Image must smaller than 5MB!',
    },
    formRules: {
      profileImage: {
        errorMessage: 'Please add user profile',
      },
      fullName: {
        label: 'Full Name',
        errorMessage: 'Please enter your full name',
        fullNamePattern: 'Invalid full name',
      },
      role: {
        label: 'Role',
        errorMessage: 'Please enter role',
      },
      title: {
        label: 'Job Title',
        errorMessage: 'Please enter job title',
      },
      oldPassword: {
        label: 'Old Password',
        errorMessage: 'Please enter old password',
      },
      name: {
        errorMessage: 'Please enter full name',
      },
      newPassword: {
        label: 'New Password',
        errorMessage: 'Please enter new password',
        passwordPattern:
          'Password should be (UpperCase, LowerCase, Number/SpecialChar and min 8 Chars)!',
      },
      confirmPassword: {
        label: 'Confirm Password',
        errorMessage: 'Please enter confirm password',
        passwordPattern:
          'Password should be (UpperCase, LowerCase, Number/SpecialChar and min 8 Chars)!',
      },
      leaveType: {
        label: 'Leave Type',
        errorMessage: 'Please enter leave type',
      },
      sendTo: {
        label: 'Send To',
        errorMessage: 'Please select assignees',
      },
      startFrom: {
        label: 'Start From',
        errorMessage: 'Please enter start date & time',
      },
      startDay: {
        label: 'Day',
        errorMessage: 'Please select day',
      },
      endOn: {
        label: 'End On',
        errorMessage: 'Please enter end date & time',
      },
      endDay: {
        label: 'Day',
        errorMessage: 'Please select day',
      },
      reasonDescription: {
        label: 'Reason Description',
        errorMessage: 'Please enter reason description',
      },
    },
  },
  resetPassword: {
    title: 'Set your password',
    resetTitle: 'Reset your password',
    description: 'nachiket@logicwind.com',
    confirm: 'Confirm',
    email: 'Thank you for activating your account. Set your password below.',
    signIn: 'Sign in',
    updatePassword: 'Update password',
    successMessages: {
      change: 'Password successfully changed',
    },
    errorMessages: {
      confirm: 'password & confirm password are not matching',
    },
    formRules: {
      password: {
        errorMessage: 'Please enter password',
        minCharacters: 'Please should contain at least 6 characters',
        passwordPattern:
          'Should have At least 8 to 16 Alphanumeric characters, 1 symbol and 1 upper case letter.',
        emailPattern:
          'Password should be (UpperCase, LowerCase, Number/SpecialChar and min 8 Chars)!',
      },
      newPassword: {
        errorMessage: 'Please enter confirm password',
        emailPattern:
          'Password should be (UpperCase, LowerCase, Number/SpecialChar and min 8 Chars)!',
      },
    },
  },
  okr: {
    text: 'OKR',
  },
  dailyGoalsSettings: {
    text: 'Daily Goals',
  },
  announcementSettings: {
    text: 'Company Announcements',
  },
  workUpdate: {
    approve: 'Approve',
    endsOn: 'Ends on: ',
    duration: 'Duration: ',
    from: 'From: ',
    surveyContent: 'Survey Content: ',
    createdBy: 'Created by : ',
    responses: 'Responses',
    takeSurvey: 'Take Survey',
    reject: 'Reject',
    addTodo: 'Add To Todo',
    ansPoll: 'Answer Poll',
    readAnnouncement: 'Read Announcement',
    viewResult: 'View Results',
    current: 'Current',
    cleared: 'Cleared',
  },
  settings: {
    selectTemplate: 'Select Template',
    textSetting: 'Settings',
    attendanceLeaves: 'Attendance & Leaves',
    back: 'Main menu',
    billings: 'Billings',
    companySetting: 'Company Settings',
    departments: 'Departments',
    quotes: 'Quotes',
    quotesSettings: 'Quotes Settings',
    standUps: 'Stand-Up Settings',
    generalSetting: 'General',
    features: 'Features Settings',
    notification: 'Notification',
    groupAccessManagement: 'Access Settings',
    projects: 'Projects',
    groups: 'Groups',
    dangerArea: 'Danger Area',
    okr: 'OKR (on)',
    checkInCheckOutSetting: 'Check In Check Out',
    settings: 'Settings',
    users: 'Users',
    integrations: 'Integrations',
    locations: 'Locations',
    userSetting: 'User Settings',
    myProfile: 'My Profile',
    saveChanges: 'Save Changes',
    addPicture: 'Add Picture',
    export: 'Export',
    updateMyPassword: 'Update My Password',
    deleteAccountButton: 'Delete Account',
    text: 'Change Owner',
    sure: 'Are you sure you want Delete Account?',
    deleteAccountText: 'Delete Account',
    exportDataText: 'Export Data',
    dangerAreaText: 'Danger Area',
    labelChangeOwner: 'Change Ownership to',
    dangerAreaDescription:
      'The owner of an account is a special admin who is responsible for managing the account’s paid subscription, and who receives all billing-related emails, including payment receipts. An account’s owner (or any account admin) can change the owner of the account via the . There can only be one owner.',
    exportData:
      'Export data contains Information like, like feature settings, Members details etc.',
    deleteAccount:
      "We're sorry to see you go. If you want to permanently delete your account, click Delete account. Note: You won't be able to create a new account with this email.",
    discard: 'Discard',
    attendance: {
      attendance: 'Attendance',
      leave: 'Leave',
      attendanceLeaves: 'Attendance & Leaves',
      activateText: 'Activate',
      deactivateText: 'Deactivate',
      notifyMe: 'Notify me',
      comingSoon: 'Coming Soon',
      calendar: 'Choose calendar from Below:',
      activateDescription:
        'all Leave and Attendance activities in an organization. all Leave and Attendance activities in an organization.  all Leave and Attendance activities in an organization.',
      comingSoonDescription:
        'Comprehensive Leave and Attendance Management Solutions to manage all Leave and Attendance activities in an organization.',
      updated: 'Updated Successfully!',
      workDaySchedule: {
        halfDayLabel: 'Do You allow Half Day?',
        title: 'Workdays',
        subHeading: 'Set the workday schedule for your organization',
        note: 'Note: Filled Square Denotes Leave On That Day.',
        yes: 'Yes',
        no: 'No',
        updateSchedule: 'Update Schedule',
      },
      departments: {
        successMessages: {
          addDepartment: 'Department successfully added',
          updateDepartment: 'Department successfully updated',
          deleteDepartment: 'Department successfully deleted',
        },
        formRules: {
          name: {
            errorMessage: 'Please enter department name',
          },
        },
      },
      successMessages: {
        action: 'Action in progress..',
        deactivate: 'Feature successfully deactivate',
        update: 'Timings successfully updated',
        break: 'Break successfully added',
        updateBreak: 'Break setting updated!',
        deleteBreak: 'Break setting deleted!',
        addHoliday: 'Holiday successfully added',
        updateHoliday: 'Holiday successfully updated',
        deleteHoliday: 'Holiday successfully deleted',
        active: 'Feature successfully activate',
      },
      workTimeSchedule: {
        addBreak: 'Add More Break',
        allowedBreakDuration: 'Allowed Break Duration',
        title: 'Timing',
        timing: 'How many breaks do you have and add timing as well?',
        yes: 'Yes',
        no: 'No',
        saveTimeSettings: 'Save Time Settings',
        saveBreakSettings: 'Save Break Settings',
        formRules: {
          flexibility: {
            label: 'Do You have Time Flexibility?',
            startEnd: 'Start Time & End Time',
            errorMessage: 'Please select start & end time',
          },
          allowFlexibility: {
            label: 'Allow Flexibility',
            errorMessage: 'Please select flexibility',
          },
          requiredHours: {
            label: 'Required Hours',
            errorMessage: 'Please select required hours',
          },
          minHours: {
            label: 'Min Hours',
            errorMessage: 'Please select minimum hours',
          },
          breakTime: {
            label: 'Total Break Time',
            errorMessage: 'Please select total break duration',
          },
          specify: {
            day: 'Specify Working Hours for a day',
            fullDay: 'Working hours for full day',
            firstHalf: 'First Half Required Timing',
            firstHalf1: 'Working hours for first half',
            secondHalf: 'Second Half Required Timing',
            secondHalf1: 'Working hours for second half',
            breakTime: 'Do you have Flexible Break Timing?',
          },
        },
        successMessages: {
          action: 'Action in progress..',
          deactivate: 'Feature successfully deactivate',
          update: 'Punch setting successfully updated',
          break: 'Break successfully added',
          addHoliday: 'Holiday successfully added',
          updateHoliday: 'Holiday successfully updated',
          deleteHoliday: 'Holiday successfully deleted',
          active: 'Feature successfully activate',
        },
      },
      breaks: {
        yes: 'Yes',
        no: 'No',
        formRules: {
          specify: {
            breakTime: 'Do you have Flexible Break Timing?',
          },
        },
      },
    },
    people: {
      featureTitle: 'People',
      settingsHeader: 'People Settings',
    },
    checkIn: {
      workUpdate: "Include last day's update",
      checkInPlanning: 'Make Daily Goals mandatory',
      update: 'CheckInSetting successfully updated',
      dailyGoal: 'Daily Goals Settings successfully updated',
      action: 'Action in progress..',
      text: 'Daily Goals',
      label: 'Daily Goal reminder time',
      label1: 'Check in report local time',
      errorMessage: 'Please select check in time',
      dailyGoalReminder: 'Please select daily goal reminder time',
      dailyGoalReport: 'Please select daily goal report time',
      successMessages: {
        action: 'Action in progress..',
        add: 'Tasks and blocker successfully added',
        update: 'Tasks and blocker successfully updated',
        apply: 'CheckIn successfully applied',
        sure: 'Are you sure to delete this task?',
      },
      formRules: {
        name: {
          errorMessage: 'Please enter name',
        },
        hours: {
          errorMessage: 'Please enter hours',
          hoursPattern: 'Please enter proper hours',
        },
        groups: {
          errorMessage: 'Please select group',
        },
      },
    },
    checkOut: {
      checkOutUpdate: 'Enable Check-out Report',
      report: 'Make Check-out Report mandatory',
      adjustCheckOutTime: 'Allow to adjust check-out time',
      checkInPrevDayWorkUpdate: 'Allow to adjust check-out time',
      autoCheckOut: 'Enable Auto-checkout',
      auto: 'Auto checkout local time',
      text: 'Check-out',
      successMessages: {
        action: 'Action in progress..',
        apply: 'Checkout successfully applied',
      },
      localTime: 'Check-out report local time',
      errorLocalTime: 'Please select check out time',
      autoCheckout: 'Auto checkout local time',
      errorAutoCheckOut: 'Please select auto check out local time',
      errorHours: 'Please enter proper hours',
    },
    estimation: {
      hourEstimate: 'Make Estimates mandatory',
      estimatesMandatory: 'Requires minimum estimated hours',
      reqMinEstHours: 'Requires minimum estimated hours',
      text: 'Daily Goals Estimations',
      hours: 'Minimum Estimation Hours',
      errorHours: 'Please select minimum estimation hours',
    },
    assignments: {
      hourEstimate: 'Enable Hour Estimates',
      estimatesMandatory: 'Make Estimates mandatory',
      text: 'Blocker Assignments',
      allowAssignments: 'Allow Assignments',
      blockers: 'Departments Blockers Visibility',
    },
    leaves: {
      manageLeaves: {
        title: 'Manage Leaves',
        startdate: 'Year Start Date ',

        successMessages: {
          action: 'Action in progress..',
          addLeave: 'Leave successfully added',
          updateLeave: 'Leave successfully updated',
          deleteLeave: 'Leave successfully deleted',
          sure: 'Are you sure to delete this holiday?',
          leaveSure: 'Are you sure to delete this leave?',
        },
        formRules: {
          type: {
            errorMessage: 'Please enter type',
          },
          leave: {
            errorMessage: 'Please enter No of leave',
          },
          paid: {
            errorMessage: 'Please select leave type',
          },
        },
      },
      holidays: {
        title: 'Holidays',
        successMessages: {
          action: 'Action in progress..',
          addHoliday: 'Holiday successfully added',
          holidayExist: 'Holiday already exist',
          updateHoliday: 'Holiday successfully updated',
          deleteHoliday: 'Holiday successfully deleted',
        },
      },
      template: 'Select template',
      addManually: 'Add Manually',
      formRules: {
        name: {
          errorMessage: 'Please enter holiday name',
        },
        date: {
          errorMessage: 'Please enter holiday date',
        },
      },
    },
    danger: {
      action: 'Action in progress...',
      accountDeleted: 'Account deleted successfully!',
      notFound: 'No data found!',
    },
  },
  signUp: {
    account: 'Already have an account?',
    title: 'Sign Up',
    description:
      'Please enter your work email to sign up. We will send you an email to activate your account.',
    emailLabel: 'Enter Your work Email',
    confirm: 'Confirm',
    login: 'Login',
    startWith: 'Start With',
    formRules: {
      email: {
        errorMessage: 'Please add email!',
        emailPattern: 'The input is not valid Email!',
      },
    },
  },
  terms: {
    service: 'Terms of Service',
    privacy: 'Privacy Policy',
    help: 'Need Help?',
    copyright: '© 2020 undiffer',
  },
  features: {
    activateConfirmText: 'Are you sure want to activate this feature?',
    deactivateConfirmText: 'Are you sure want to deactivate this feature?',
  },
  sops: {
    title: 'Standard Procedures',
    sidebarTitle: 'SOPs',
    confirm: 'Standard Procedures',
    add: 'Add SOP',
    create: 'Create SOP',
    tabs: {
      active: 'Active',
      draft: 'Draft',
      archived: 'Archived',
    },
  },
  announcements: {
    title: 'Announcement',
    sidebarTitle: 'Announcements',
    confirm: 'Standard Procedures',
    add: 'Add Announcement',
    create: 'Create Announcements',
    sure: 'Are you sure want to delete this announcement?',
    discard: 'The changes you did will be discarded',
    tabs: {
      published: 'Published',
      draft: 'Draft',
    },
  },
  standUps: {
    title: 'Stand-Up',
    sidebarTitle: 'Stand-ups',
    settings: {
      questions: 'Stand-Up Questions',
      integrations: 'Integrations',
    },
    manager: {
      team: 'Team Check-In',
      my: 'My Check-in',
    },
    formLabel: {
      yesterday: 'Yesterday’s Updates',
      today: 'Today’s Goals',
      blocker: 'Blockers',
      Add: 'Add previous goals?',
      mood: 'Mood Selector',
      preview: 'Preview',
      progress: 'Is there anything blocking your progress?',
      feel: 'How do you feel today?',
      noBlocker: 'Yay! There  are no blockers.',
      noYesterdayGoal: 'You didn’t accomplish any tasks yesterday.',
    },
  },
  users: {
    users: 'Users',
    create: 'Create User',
    invite: 'Invite',
    update: 'Save changes',
    addUser: 'Add User',
    editUser: 'Edit User',
    sure: "You won't be able to revert this action!",
    projectDepartment: 'Departments & Projects',
    documents: 'Documents',
    add: 'Add',
    joining: 'Joining request',
    allUsers: 'All Users',
    successMessages: {
      action: 'Action in progress..',
      deleteUser: 'User successfully deleted',
      addUser: 'User successfully added',
      updateUser: 'User successfully updated',
      resend: 'Resend email successfully',
      addGroup: 'Group successfully added',
      deleteGroup: 'Group successfully deleted',
      deleteAnnouncement: 'Announcement successfully deleted',
      deleteFeedback: 'Feedback successfully deleted',
      addDocument: 'User document successfully added',
      updateDocument: 'User document successfully updated',
      delete: 'User document successfully deleted',
      invalid: 'Invalid attachment',
      sure: 'Are you sure, you want to delete the user from the group?',
      deleteDoc: 'Are you sure to delete this document?',
      activate: 'Are you sure, you want to activate the user?',
      deactivate: 'Are you sure, you want to deactivate the user?',
      reject: "Are you sure, you want to reject this user's request?",
      approve: "Are you sure, you want to approve this user's request?",
    },
    formRules: {
      fullName: {
        label: 'Full Name',
        errorMessage: 'Please enter name',
        namePattern: 'Invalid full name',
      },
      email: {
        label: 'Email',
        errorMessage: 'Please enter email',
        emailPattern: 'Not valid email',
      },
      role: {
        label: 'Role',
        errorMessage: 'Please select role',
      },
      group: {
        errorMessage: 'Please select group',
      },
      location: {
        label: 'Location',
        errorMessage: 'Please select Location',
      },
      title: {
        errorMessage: 'Please enter type',
      },
      number: {
        errorMessage: 'Please enter number',
      },
      attachment: {
        errorMessage: 'Please add attachment',
      },
    },
  },
  billings: {
    text: 'Billings',
  },
  leave: {
    leave: 'Leave',
    request: 'Request',
  },
  quotes: {
    active: 'Active',
    deactivated: 'Deactivated',
    addQuotes: 'Add Quotes',
    editQuotes: 'Edit Quotes',
    createQuotes: 'Create Quotes',
    updateQuotes: 'Update Quotes',
    text: 'Quotes',
    category: 'Select Categories',
    time: 'Time',
    successMessages: {
      action: 'Action in progress..',
      add: 'Quote successfully added',
      delete: 'Quote successfully deleted',
      update: 'Quote successfully updated',
      activate: 'Quote successfully activate',
      deactivate: 'Quote successfully deactivate',
    },
    formRules: {
      title: {
        label: 'Title',
        errorMessage: 'Please enter title',
      },
      author: {
        label: 'Author',
        errorMessage: 'Please enter author',
      },
      isActive: {
        label: 'Active?',
      },
    },
  },
  feedback: {
    title: 'Feedback',
    back: 'Back to Surveys',
    survey: 'Survey',
    SurveyTitle: 'Survey:',
    PollsTitle: 'Polls:',
    sure: 'Are your sure you want to delete this feedback?',
    summary: {
      surveyNotFound: 'No survey found.',
    },
    tab: {
      current: 'Current',
      completed: 'Completed',
      draft: 'Draft',
      summary: 'Summary',
      response: 'All Response',
    },
    emptyQuestion: {
      questionType: 'Select question type:',
      questionLabel: 'What would you like to ask?',
      mcqLabel: 'Multiple Choice',
      scaleLabel: 'Scale',
      AddChoice: 'Add Choice',
      AddOption: 'Add Option',
      PollType: 'Select your type of Poll:',
      Options: 'Options',
      Rate: 'Rate Us on the User-form as below',
      Comments: 'Like / Dislike on the User-form as below',
    },
    new: {
      surveyTitle: 'Create Survey',
      surveyEditTitle: 'Update Survey',
      pollTitle: 'Create Poll',
      pollEditTitle: 'Update Poll',
      titleLabel: 'Title',
      for: 'For',
      descriptionLabel: 'Description',
      departmentsLabel: 'Departments',
      audienceLabel: 'Audience',
      shareWithLabel: 'Share With',
      deliveryLabel: 'Delivery Option',
      deliveryOption: 'Send now',
      sendNowDesc:
        'Users will receive the poll according to their delivery preference',
      endTimeLabel: 'End Settings',
      endTimeOption: 'End Settings',
      ScheduleOn: 'Schedule On',
      Manually: 'Manually',
      EndOn: 'End On',
      Everyone: 'Everyone',
      Participants: 'Participants',
      Open: 'Open',
      Close: 'Close',
      Managers: ' Managers',
      permissions: 'Response Visibility',
      permission_open:
        'Any Team Manager in charge of a team targeted by this survey can view the results for their team. Those who have access to all of the teams within the audience can editand Archieve Survey',
      permission_close:
        'Allows Executive managers to block all access to Team managers. Only Executive Managers can view, edit and archive the poll',
    },
    add: {
      createSurvey: 'Create Survey',
      createPoll: 'Create Poll',
      text: 'Add',
      newSurvey: 'Start from Scratch',
      selectTemplateTitle: 'Select Template',
    },
  },
  notifications: {
    title: 'Notification',
    header: {
      title: 'Create Design System',
    },
    new: 'New',
    cleared: 'Cleared',
    clearAll: 'Clear all',
    noNotifications: 'Nothing to see here!',
  },
  locations: {
    title: 'Locations',
    add: 'Add Location',
    edit: 'Edit Location',
    update: 'Save Changes',
    sure: 'Are you sure?',
    successMessages: {
      action: 'Action in progress..',
      delete: 'User successfully deleted',
      sure: 'Are You Sure?',
      default: 'Location successfully made default',
      add: 'Location successfully added',
      update: 'Location successfully updated',
    },
    formRules: {
      name: {
        label: 'Name',
        errorMessage: 'Please enter location name',
      },
      shortName: {
        label: 'Code',
        errorMessage: 'Please enter location code',
      },
      admins: {
        label: 'HR Team',
        errorMessage: 'Select HR',
      },
      copyFrom: {
        label: 'Copy settings from',
        errorMessage: 'Select location',
      },
    },
  },
  team: {
    text: 'Teams',
    checkIn: 'Check Ins',
    tasks: 'Goals',
    blockers: 'Blockers',
    attendance: 'Attendance',
  },
  leaves: {
    text: 'Leaves',
    sickLeave: 'Sick Leave',
    casualLeave: 'Casual Leave',
    plannedLeave: 'Planned Leave',
    totalLeave: 'Total Leave',
  },
  update: {
    text: 'Updates',
    team: 'Take me to Team',
    update: 'Work update successfully updated',
    addUpdate: 'Add Update',
    generated: 'Generated on',
    tab: {
      new: 'New',
      cleared: 'Cleared',
    },
  },
};

export default Strings;
