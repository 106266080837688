import { Layout } from 'antd';
import styled from 'styled-components';
import Spacings from 'design/Spacings';
import Colors from 'design/Colors';
import Variables from 'design/Variables';
import { fonts } from 'design/Fonts';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  &.ant-layout-content {
    padding: ${Spacings.small.padding};
  }
  .ant-table {
    border: 1px solid ${Colors.Border};
  }
  .ant-tabs-nav {
    font: ${fonts.large.regular};
    color: ${Colors.Text};
  }
  .title {
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.medium};
    line-height: ${Variables.LineHeightSm};
  }
  .text {
    color: ${Colors.TextSecondary};
    font-size: 12px;
    line-height: ${Variables.LineHeightSm};
    word-break: break-all;
  }
  .main-icon {
    margin-right: ${Spacings.xxSmall.margin};
    margin-left: Calc(${Spacings.xxSmall.margin} - 2px);
    display: flex;
    align-items: center;
  }
  .empty-wrapper {
    min-height: 100%;
  }
  .goals {
    position: relative;
    height: fit-content;
    margin: 10px 0px 14px 12px;
    padding-left: 20px;
  }
  .border-goals {
    width: 4px;
    border-radius: 200px;
    background-color: ${Colors.TextGreen};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .ant-table-cell {
    background-color: ${Colors.TextLight};
  }
  .check-in-date {
    font-weight: 500;
    font: ${fonts.xSmall.medium};
  }
  .icon {
    width: 20px;
    color: ${Colors.PrimaryColor};
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    transform: translate(-115%);
  }
  .ant-table-row-expand-icon-cell {
    cursor: pointer;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .new-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .flex-box {
    display: flex;
    align-items: center;
  }
  .checkbox {
    position: relative;
    height: fit-content;
    margin: 10px 0px 12px 0px;
  }
  .checkbox-disabled {
    margin-right: 8px;
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: ${Colors.TextEmpty};
      border-color: ${Colors.TextEmpty} !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${Colors.TextEmpty};
      border-color: ${Colors.TextEmpty};
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #fff;
    }
  }
  .checkbox-disabled-primary {
    margin-right: 8px;
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: ${Colors.PrimaryColor};
      border-color: ${Colors.PrimaryColor} !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${Colors.PrimaryColor};
      border-color: ${Colors.PrimaryColor};
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #fff;
    }
  }
  .ant-checkbox-wrapper {
    display: flex;
    justify-content: center;
    border-radius: 3px;
    color: ${Colors.TextSecondary};
    font-size: 12px;
    line-height: ${Variables.LineHeightSm};
  }
  .ant-checkbox-disabled + span {
    color: ${Colors.TextSecondary};
    font-size: 12px;
    line-height: ${Variables.LineHeightSm};
  }
  .ant-checkbox {
    margin-top: 4px;
  }
  .ant-checkbox-inner {
    border-radius: 3px;
    border-width: 0.875px;
  }
  .ant-checkbox-inner {
    width: 14px;
    height: 14px;
    &::after {
      width: 4px;
      height: 8px;
      top: 44%;
      left: 25%;
    }
  }
  .ant-checkbox {
    margin-left: 7px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
  .icon-table {
    width: 16px;
    height: 16px;
  }
  .info {
    width: 300px;
  }
  .unchecked {
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: unset;
    }
  }
`;
export const Arrow = styled.img`
  width: 13px;
`;
export const Column = styled.div`
  font-weight: 600;
  &.goals-text {
    color: ${Colors.TextGreen};
    font: ${fonts.xSmall.bold};
  }
  &.goals-added-text {
    color: ${Colors.PrimaryColor};
    font: ${fonts.xSmall.bold};
  }
  &.blockers-text {
    color: ${Colors.TextDanger};
    font: ${fonts.xSmall.bold};
  }
`;
