import { gql } from '@apollo/client';
import { actionFragment, checkInFragment } from './fragments';

export const CHECK_IN = gql`
  mutation checkIn {
    checkIn {
      ...CheckInFragment
    }
  }
  ${checkInFragment}
`;
export const CHECK_IN_AGAIN = gql`
  mutation checkedInAgain {
    checkedInAgain {
      ...CheckInFragment
    }
  }
  ${checkInFragment}
`;
export const CREATE_ACTIONS = gql`
  mutation createActions($data: [ActionCreateInput]!, $yesterdayTask: Boolean) {
    createActions(data: $data, yesterdayTask: $yesterdayTask) {
      ...ActionFragment
    }
  }
  ${actionFragment}
`;

export const CHECKED_IN_ACTIONS = gql`
  mutation checkedIn($data: CreateCheckedInActionInput) {
    checkedIn(data: $data) {
      ...CheckInFragment
    }
  }
  ${checkInFragment}
`;

export const UPDATE_ACTIONS = gql`
  mutation updateActions($data: [ActionUpdateDataInput], $yesterdayTask: Boolean) {
    updateActions(data: $data, yesterdayTask: $yesterdayTask) {
      ...ActionFragment
    }
  }
  ${actionFragment}
`;

export const UPDATE_CHECK_IN_STATUS = gql`
  mutation updateCheckInStatus($data: UpdateCheckInStatusInput) {
    updateCheckInStatus(data: $data) {
      ...CheckInFragment
    }
  }
  ${checkInFragment}
`;

export const UPDATE_CHECK_IN = gql`
  mutation updateCheckIn($data: inputUpdateCheckIn! $where:CheckInWhereUniqueInput!){
    updateCheckIn(data:$data where:$where){
      id
    }
  }
`;
