import { Row as AntRow } from 'antd';
import styled from 'styled-components';

import Colors from 'design/Colors';
import Spacings from 'design/Spacings';

export const Content = styled.div`
  .ant-table table {
    border: ${({ border }) => (border ? `1px solid ${Colors.BorderLight}` : 0)};
    border-bottom: 0;
  }
  &.bordered {
    border: 1px solid ${Colors.Border};
    border-radius: 0px 0px 12px 12px;
  }
  .add-button {
    font-size: 18px;
  }
`;

export const InputRow = styled(AntRow)`
  padding: 8px 32px;
  background-color: ${Colors.Container};
  /* border: ${({ border }) => border ? `1px solid ${Colors.BorderLight}` : 0};
  border-top: 0; */

`;
