import { gql } from '@apollo/client';
import {
  groupFragment,
  groupUserFragment,
  userFragment,
  userDocumentsFragment,
} from './Fragments';

export const GET_USERS = gql`
  query users(
    $where: UserWhereInput
    $skip: Int
    $limit: Int
    $search: String
    $sortOn: usersSortOnInput
    $sortBy: usersSortByInput
  ) {
    users(
      where: $where
      skip: $skip
      limit: $limit
      search: $search
      sortOn: $sortOn
      sortBy: $sortBy
    ) {
      users {
        ...UserFragment
      }
      count
    }
  }
  ${userFragment}
`;
export const GET_USER = gql`
  query user($where: UserWhereUniqueInput!) {
    user(where: $where) {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const GET_GROUPS = gql`
  query groups {
    groups {
      groups {
        ...GroupFragment
      }
    }
  }
  ${groupFragment}
`;

export const GET_USER_DOCUMENTS = gql`
  query userDocuments($userId: Int!) {
    userDocuments(userId: $userId) {
      ...UserDocumentsFragment
    }
  }
  ${userDocumentsFragment}
`;

export const GET_GROUP_USERS = gql`
  query groupUsers($where: GroupUserWhereInput!) {
    groupUsers(where: $where) {
      ...GroupUserFragment
    }
  }
  ${groupUserFragment}
`;
export const GET_WORKDAY = gql`
  query getWorkSchedule($where: WorkDayWhereUniqueInput) {
    getWorkSchedule(where: $where) {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
`;
