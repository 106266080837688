import { gql } from '@apollo/client';
import { userFragment } from './Fragments';

export const GET_NOTIFICATIONS = gql`
  query notifications($where: NotificationWhereInput, $limit: Int, $skip: Int) {
    notifications(where: $where, limit: $limit, skip: $skip) {
      notifications {
        id
        title
        description
        data
        type
        notifiable {
          __typename
          ... on Feedback {
            feedbackId: id
            feedbacktitle: title
            feedbackendOn: endOn
            feedbacktype: type
            feedbackdescription: description
            feedbackgroups: groups {
              id
              name
              color
              type
            }
            feedbackquestions: questions {
              questions {
                questionType
                sequenceNo
                question
                choices
                scaleLabel
                id
              }
            }
          }
          ... on Announcement {
            announcementid: id
            announcementtitle: title
            announcementgroups: groups {
              id
              name
              color
              type
            }
            announcementcreatedBy: createdBy {
              email
              firstName
              lastName
              profileImage
            }
            announcementbody: body
          }
        }
        isRead
        user {
          ...UserFragment
        }
        generator {
          ...UserFragment
        }
        generatorType
        createdAt
        updatedAt
      }
      count
    }
  }
  ${userFragment}
`;
