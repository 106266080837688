import React from 'react';
import styled, { css } from 'styled-components';
import Colors from 'design/Colors';
import { Badge as AntBadge } from 'antd';

const StyledBadge = styled(AntBadge)`
  .ant-badge-count {
    background-color: ${Colors.ButtonPrimary};
    color: ${Colors.TextLight};
    box-shadow: 0 0 0 1px ${Colors.ButtonPrimary} inset;
    transform: translate(-40%, -50%);
    cursor: pointer;
  }
  ${(props) =>
    props?.emojiIcon &&
    css`
      .ant-badge-count {
        position: relative;
        background-color: ${Colors.ButtonPrimary};
        color: ${Colors.TextLight};
        box-shadow: 0 0 0 1px ${Colors.ButtonPrimary} inset;
        transform: translate(-40%, -50%);
        cursor: pointer;
        border: 1px solid #fff;
      }
    `}
`;

const Badge = ({ count, content, dot, onClick, emojiIcon }) => (
  <StyledBadge dot={dot} count={count} onClick={onClick} emojiIcon={emojiIcon}>
    {content}
  </StyledBadge>
);

export default Badge;
