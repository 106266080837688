import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Dropdown, Divider } from 'antd';
import Button from 'atoms/Button';
import { BUTTON_TYPE, PRIVATE_ROUTE } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import styled, { createGlobalStyle } from 'styled-components';
import { fileIcon, OptionIcon } from 'assets';
import Colors from 'design/Colors';
import * as eva from 'eva-icons';
import Spacings from 'design/Spacings';
import { fonts } from 'design/Fonts';

export const Wrapper = styled.div`
  .dropdown {
    .ant-dropdown-menu {
      border-radius: 8px;
    }
  }
  .icon {
    margin-left: 25px;
  }
  .ant-btn {
    z-index: 100;
  }
`;

export const StyledImg = styled.img`
  width: 16px;
  margin-right: 5px;
  padding-bottom: 4px;
`;

export const StyledDivider = styled(Divider)`
  margin: 0px;
`;
export const StyledTitle = styled.p`
  font: ${fonts.xSmall.medium};
  color: ${Colors.Text};
  display: inline;
`;
export const GlobalStyles = createGlobalStyle`
    .ant-dropdown-menu {
      border-radius: 8px;
      padding: 0px;
    }
    .ant-dropdown-menu-item:hover { 
      border-radius: 8px;
    }
    .ant-dropdown{
      z-index:0
    }
`;

export const IconContainer = styled.div`
  margin-left: ${Spacings.Small.margin};
  padding-top: 2px;
`;

function ButtonDropdown(props) {
  const { history, title } = props;
  const menu = (
    <Menu>
      <GlobalStyles />
      <Menu.Item key={0} onClick={() => history.push(PRIVATE_ROUTE.SURVEY_NEW)}>
        <StyledImg src={fileIcon} alt="icon" />
        <StyledTitle>
          <FormattedMessage id="feedback.add.createSurvey" />
        </StyledTitle>
      </Menu.Item>
      <StyledDivider />

      <Menu.Item key={1} onClick={() => history.push(PRIVATE_ROUTE.POLL_NEW)}>
        <StyledImg src={OptionIcon} alt="icon" />
        <StyledTitle>
          <FormattedMessage id="feedback.add.createPoll" />
        </StyledTitle>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    eva.replace();
  }, []);

  return (
    <Wrapper>
      <Dropdown
        className="dropdown"
        placement="bottomCenter"
        overlay={menu}
        trigger={['click']}
      >
        <Button
          name={(
            <span className="flex align-items-center">
              {title || 'Create'}
              <IconContainer className="flex align-items-center">
                <i
                  data-eva="arrow-ios-downward-outline"
                  data-eva-fill="#ffffff"
                  data-eva-height="18"
                  data-eva-width="18"
                >
                </i>
              </IconContainer>
            </span>
          )}
          type={BUTTON_TYPE.PRIMARY}
          focus={Colors.ButtonPrimaryHover}
        />
      </Dropdown>
    </Wrapper>
  );
}

export default withRouter(ButtonDropdown);
