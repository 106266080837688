import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Tag } from 'antd';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import * as eva from 'eva-icons';
import DateTime from 'atoms/DateTime';
import UpdatesModal from 'templates/Updates/UpdatesModal';
import { FEEDBACK_TYPE, NOTIFICATIONS, PRIVATE_ROUTE } from 'utils/constants';
import { toUpper, get } from 'lodash';
import { useMutation } from '@apollo/client';
import { UPDATE_NOTIFICATION } from 'graphql/Notifications/Mutations';
import { GET_NOTIFICATIONS } from 'graphql/Notifications/Queries';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import moment from 'moment';
import NotificationTodo from '../../Todos/widgets/NotificationTodo';
import NotificationDefault from '../NotificationDefault/NotificationDefault';
import { Wrapper, IconContainer, Container, Invite } from './AntStyled';

export default function (props) {
  const {
    title,
    group,
    createdAt,
    generator,
    newData,
    updatedAt,
    component: Widget,
    notificationData,
    icon,
    decreaseNotification,
    isRead,
  } = props;
  const [visible, setVisible] = useState(false);
  const handleCallback = () => {
    decreaseNotification(notificationData?.id);
    setVisible(false);
  };
  const { state } = useContext(AppContext);
  const [timeZone, setTimeZone] = useState('Asia/Kolkata');
  const userTimeZone = get(state, 'loginUser.location.timeZone', null);
  useEffect(() => {
    if (userTimeZone) setTimeZone(userTimeZone);
  }, [userTimeZone]);
  const history = useHistory();
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);

  useEffect(() => {
    eva.replace();
  }, []);

  let todo = {};

  switch (notificationData?.type) {
    case NOTIFICATIONS.ANNOUNCEMENT_PUBLISHED:
      todo = {
        type: toUpper(notificationData?.type),
        title: notificationData?.notifiable?.announcementtitle,
        data: {
          body: notificationData?.notifiable?.announcementbody,
        },
        updatableId: notificationData?.notifiable?.announcementid,
        groups: notificationData?.notifiable?.announcementgroups,
        createdBy: generator,
        updatableType: 'announcement',
      };
      break;
    case NOTIFICATIONS.FEEDBACK_REQUEST:
      todo = {
        type: notificationData?.type,
        title: notificationData?.description,
        data: {
          endOn: notificationData?.notifiable?.feedbackendOn,
          description: notificationData?.notifiable?.feedbackdescription,
          questions: notificationData?.notifiable?.feedbackquestions?.questions,
          type: notificationData?.notifiable?.feedbacktype,
        },
        groups: notificationData?.notifiable?.feedbackgroups,
        updatableId: notificationData?.notifiable?.feedbackId,
        createdBy: generator,
        updatableType: 'feedback',
      };
      break;
    default:
      break;
  }
  return (
    <Container>
      <Card
        padding={12}
        className="notification-card"
        title={
          <Wrapper>
            <Row justify="space-between" align="middle">
              <Col>
                <div className="content">
                  <IconContainer>
                    <i
                      data-eva={icon}
                      data-eva-fill="#5F3996"
                      data-eva-height="16"
                      data-eva-width="16"
                    ></i>
                  </IconContainer>
                  <div className="title">
                    {group && `${group.name} :`} {title}
                  </div>
                  {notificationData?.type === NOTIFICATIONS.FEEDBACK_REQUEST &&
                    moment(
                      moment(notificationData?.notifiable?.feedbackendOn).tz(
                        timeZone
                      )
                    ).isBefore(moment()) && (
                      <Invite>
                        <Tag color="error">Expired</Tag>
                      </Invite>
                    )}
                </div>
              </Col>
              <Col className="time">
                <DateTime date={isRead ? updatedAt : createdAt} fromNow />
              </Col>
            </Row>
          </Wrapper>
        }
      >
        <Widget data={notificationData} />
        <Row justify="end" align="bottom">
          <Col className="btn">
            {!isRead &&
              !(
                notificationData?.type === NOTIFICATIONS.FEEDBACK_REQUEST &&
                moment(
                  moment(notificationData?.notifiable?.feedbackendOn).tz(
                    timeZone
                  )
                ).isBefore(moment())
              ) && (
                <Button
                  tableButton
                  name={
                    notificationData?.type === NOTIFICATIONS.STANDUP_REMINDER
                      ? 'Check In'
                      : 'View'
                  }
                  onClick={() => {
                    updateNotification({
                      variables: {
                        data: { isRead: true },
                        where: { id: notificationData?.id },
                      },
                    }).catch((e) => {
                      console.log(e);
                    });
                    if (
                      notificationData?.type === NOTIFICATIONS.STANDUP_REMINDER
                    ) {
                      decreaseNotification(notificationData?.id);
                      history.push(PRIVATE_ROUTE.MY_CHECK_IN);
                    } else if (
                      notificationData?.type === NOTIFICATIONS.FEEDBACK_RESULT
                    ) {
                      decreaseNotification(notificationData?.id);
                      history.push(
                        `${
                          notificationData?.notifiable?.feedbacktype ===
                          FEEDBACK_TYPE.SURVEY
                            ? '/feedback/survey'
                            : '/feedback/poll'
                        }/${notificationData?.notifiable?.feedbackId}/summary`
                      );
                    } else {
                      setVisible(true);
                    }
                  }}
                />
              )}
          </Col>
        </Row>
      </Card>

      <UpdatesModal
        visible={visible}
        setVisible={setVisible}
        callback={handleCallback}
        updatesData={todo}
        afterClose={() => decreaseNotification(notificationData?.id)}
      />
    </Container>
  );
}
