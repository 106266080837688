import Card from 'atoms/Card';
import React, { useState } from 'react';
import { MarkAsRead } from 'assets';

import { Content } from './AntStyled';
import UpdatesModal from './UpdatesModal';

function UpdatesTemplate({
  workUpdate,
  component: Widget,
  markClear,
  addToTodo,
  callback,
  isViewed,
  createdAt,
  updatedAt,
  fadeOut,
  index,
}) {
  const [visible, setVisible] = useState(false);
  const handleCallback = () => {
    setVisible(false);
    callback();
  };
  return (
    <Content>
      <Card
        padding="0"
        key={index}
        className={fadeOut === index ? 'fadeout' : ''}
      >
        <Widget
          data={workUpdate}
          addToTodo={addToTodo}
          performTask={() => setVisible(true)}
          isViewed={isViewed}
          createdAt={createdAt}
          updatedAt={updatedAt}
        />
      </Card>
      {!isViewed && (
        <>
          <img
            src={MarkAsRead}
            className={`mark-as-read ${fadeOut === index ? 'fadeout' : ''}`}
            alt="mark as read"
            height={24}
            width={24}
            title="Clear update"
            onClick={() => markClear()}
          />
        </>
      )}
      <UpdatesModal
        visible={visible}
        setVisible={setVisible}
        callback={handleCallback}
        updatesData={workUpdate}
      />
    </Content>
  );
}

export default UpdatesTemplate;
