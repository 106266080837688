import React from 'react';
import styled from 'styled-components';
import { Avatar as AntAvatar, Badge } from 'antd';

import { DefaultUserProfile } from 'assets';
import Spacings from 'design/Spacings';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { trim } from 'lodash';

const colors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
  '#CB543F',
  '#AB567F',
  '#1F639C',
  '#173F5F',
  '#D63964',
];
const StyledAvatar = styled(AntAvatar)`
  & .ant-avatar-string {
    font-size: ${fonts.xSmall.size};
  }
  margin-right: ${({ right }) => right || Spacings.xxSmall.margin};
  background-color: ${({ name, color, src, icon }) =>
    src || icon
      ? 'transparent'
      : (name && colors[name.charAt(0).charCodeAt(0) % colors.length]) ||
        color};
`;

const StyledBadge = styled(({ badgeColor, ...props }) => <Badge {...props} />)`
  .ant-scroll-number.ant-badge-dot {
    transform: none;
    height: 11px;
    width: 11px;
    top: 0px;
    right: 5px;
    background-color: ${({ badgeColor }) => badgeColor || Colors.SuccessGreen};
    border: 2px solid ${Colors.SidebarText};
  }
`;

const Avatar = ({
  profile,
  size,
  shape,
  badge,
  name,
  icon,
  color,
  right,
  badgeColor,
}) => {
  const avatar = (
    <StyledAvatar
      right={right}
      icon={icon}
      size={size}
      shape={shape}
      color={color}
      src={profile || (!name && !icon && DefaultUserProfile)}
      name={name}
    >
      {name?.split(' ')?.length > 1
        ? `${name?.split(' ')[0]?.charAt(0)?.toUpperCase()}${name
            ?.split(' ')[1]
            ?.charAt(0)
            ?.toUpperCase()}`
        : name?.slice(0, 1)?.toUpperCase()}
    </StyledAvatar>
  );
  return badge ? (
    <StyledBadge dot badgeColor={badgeColor}>
      {avatar}
    </StyledBadge>
  ) : (
    avatar
  );
};

export default Avatar;
