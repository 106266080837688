/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Avatar from 'atoms/Avatar';
import Badge from 'atoms/Badge';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { withRouter } from 'react-router-dom';
import { FETCH_POLICY, EMOJIS, EMPTY_OBJECT } from 'utils/constants';
import Colors from 'design/Colors';
import { CloseCircle } from 'assets';
import UserTaskDrawer from 'organisms/UserTaskDrawer';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import {
  GET_TEAM_CHECKINS,
  GET_CURRENT_STANDUP,
} from 'features/standUp/graphql/Queries';
import { Empty } from 'antd';
import ability from 'utils/ability/ability';
import { Emoji } from 'emoji-mart';
import { isNumber } from 'lodash';

const StyledUserProfile = styled.div`
  width: fit-content;
  display: inline-flex;
  padding: ${({ bordered }) => bordered && '4px 6px 4px 4px'};
  border-radius: ${({ bordered }) => bordered && '20px'};
  background: ${({ stackedName, Background }) =>
    Background
      ? stackedName
        ? 'transparent'
        : Colors.IconColor
      : 'transparent'};
  align-items: center;
  box-sizing: border-box;
  flex-direction: ${({ orientation }) =>
    orientation === 'vertical' ? 'column' : 'row'};
  white-space: nowrap;
  border: ${({ bordered }) => (bordered ? '1px solid #CAD6E3' : 'none')};
  margin-right: 8px;
  font-size: ${({ orientation, size, dropDown }) =>
    dropDown
      ? orientation === 'vertical'
        ? size === 'small'
          ? fonts.xSmall.size
          : fonts.medium.size
        : fonts.small.size
      : fonts.small.size};
  & .stacked-name {
    color: ${Colors.TextPrimary};
    line-height: normal;
    .first-name {
      font-weight: 600;
      font-size: ${fonts.xSmall.size};
    }
    .last-name {
      font-size: ${fonts.xxSmall.size};
    }
    :hover {
      color: ${({ showLink }) =>
    showLink ? Colors.PrimaryColor : Colors.TextPrimary};
      cursor: ${({ showLink }) => (showLink ? 'pointer' : 'inherit')};
    }
  }
  &.ant-avatar {
    margin: 0;
  }
  & .status {
    background-color: green;
    height: 7px;
    width: 7px;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
    z-index: auto;
  }
  & .name {
    line-height: ${({ bordered }) => (bordered ? '1' : 'none')};
    display: ${({ orientation }) =>
    orientation === 'horizontal' ? 'inline-block' : 'initial'};
    margin-top: ${({ orientation }) =>
    orientation === 'vertical'
      ? `calc(${Spacings.xxSmall.margin} - 2px)`
      : '0'};
    margin-left: ${({ orientation }) => (orientation === 'vertical' ? 0 : 0)};
    font-weight: ${({ bold, medium }) => (medium ? (bold ? 600 : 500) : 400)};
    font-size: ${({ size, dropDown }) =>
    dropDown
      ? size === 'small'
        ? fonts.xSmall.size
        : fonts.medium.size
      : fonts.xSmall.size};
    cursor: ${({ showLink }) => (showLink ? 'pointer' : 'auto')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${Colors.TextEnable};
    flex: 0 0 auto;
    @media only screen and (max-width: 767px) {
      max-width: 70%;
    }

    @media (min-width: 768px) and (max-width: 1642px) {
      max-width: 90%;
    }
  }
  & .name:hover {
    cursor: pointer;
  }
  .designation {
    text-overflow: ellipsis;
    max-width: 238px;
  }
  .employee-name {
    color: ${Colors.TextDark};
    line-height: 17px;
    font-size: ${fonts.medium.size};
  }
  .employee-designation {
    color: ${Colors.TextSecondary};
    line-height: 15px;
    font: ${fonts.xxSmall.medium};
  }
  & .anticon {
    cursor: pointer;
    font-size: 18px;
    margin-left: calc(${Spacings.xxSmall.margin} + 2px);
    margin-top: calc(${Spacings.xxSmall.margin} + 2px);
  }
  .emoji-mart-emoji {
    position: absolute;
    right: ${({ large }) => (large ? '2px' : '4px')};
    top: ${({ large }) => (large ? '24px' : '16px')};
  }
  .emoji-mart-emoji > span {
    background-size: 6100% 6100% !important;
    border: 1px solid ${Colors.TextWhite};
    border-radius: 100%;
    image-rendering: crisp-edges;
  }
`;
const Close = styled.img`
  width: 13px;
  cursor: pointer;
  margin-left: calc(${Spacings.tiny.margin} + 1px);
`;
const UserProfile = ({
  profile,
  name,
  size = 'large',
  shape,
  orientation = 'horizontal',
  status = false,
  bold = false,
  medium = false,
  bordered = false,
  icon = false,
  dropDown = false,
  onIconClick,
  userId,
  onClick,
  showLink = false,
  history,
  className,
  onlyIcon,
  badgeColor,
  badge,
  Background,
  emoji,
  large,
  stackedName = true,
  designation = false,
  apostrophe = false,
  date,
  email,
}) => {
  const [visible, setVisible] = useState(false);
  const [standupEntry, setStandupEntry] = useState(EMPTY_OBJECT);
  const arr = name && name.split(' ');
  const firstName = arr && arr[0];
  const lastName = arr && arr.length > 1 && arr[1];

  const showDrawer = (record) => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const [standUpEntries, { loading, data }] = useLazyQuery(GET_TEAM_CHECKINS, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const [
    getStandUpEntry,
    { data: standUpData, loading: standupLoading },
  ] = useLazyQuery(GET_CURRENT_STANDUP, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });

  useEffect(() => {
    setStandupEntry(standUpData?.currentStandUpEntry || EMPTY_OBJECT);
  }, [standUpData]);
  useEffect(() => {
    if (visible && userId) {
      if (ability.can('use', 'standups_feature')) {
        standUpEntries({
          variables: {
            where: {
              userIds: [Number(userId)],
              from: moment(date).format('YYYY-MM-DD'),
              to: moment(date).format('YYYY-MM-DD'),
            },
          },
        });
        getStandUpEntry();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, userId]);
  return (
    <>
      <StyledUserProfile
        size={size}
        orientation={orientation}
        className={className}
        bold={bold}
        medium={medium}
        bordered={bordered}
        dropDown={dropDown}
        showLink={!dropDown && showLink}
        designation={designation}
        stackedName={stackedName}
        Background={Background}
        badge={badge}
        large={large}
        onClick={!dropDown && showLink && showDrawer}
      >
        {badge && emoji >= 0 ? (
          <Badge
            emojiIcon
            count={
              <Emoji set="apple" emoji={EMOJIS[emoji]} size={large ? 24 : 16} />
            }
            content={
              <Avatar
                size={typeof size === 'number' ? size : bordered ? 16 : 'small'}
                shape={shape}
                profile={profile}
                badge={status}
                name={name}
                badgeColor={badgeColor}
              />
            }
          />
        ) : (
          <Avatar
            size={typeof size === 'number' ? size : bordered ? 16 : 'small'}
            shape={shape}
            profile={profile}
            badge={status}
            name={name}
            badgeColor={badgeColor}
          />
        )}
        {!onlyIcon && (
          <>
            {stackedName ? (
              <div className="stacked-name">
                <div className="first-name">{firstName}</div>
                <div className="last-name">{lastName}</div>
              </div>
            ) : (
              <>
                {designation ? (
                  <div className="designation" title={name}>
                    <div className="employee-name">{name}</div>
                    <div className="employee-designation">{designation}</div>
                  </div>
                ) : (
                  <div className="name" title={name}>
                    {name}
                    {apostrophe && <span>'s</span>}
                  </div>
                )}
              </>
            )}
          </>
        )}
        {orientation === 'horizontal' && icon && (
          <Close src={CloseCircle} alt="close" onClick={onIconClick} />
        )}
      </StyledUserProfile>
      <UserTaskDrawer
        standUpLoading={loading}
        onClose={onClose}
        visible={visible}
        firstName={firstName}
        lastName={lastName}
        profileImage={profile}
        userId={userId}
        email={data?.standUpEntries?.[0]?.user?.originalEmail || data?.standUpEntries?.[0]?.user?.email || email}
        allowed={data?.standUpEntries?.[0]?.allowed}
        status={data?.standUpEntries?.[0]?.status}
        emoji={data?.standUpEntries?.[0]?.mood}
        time={data?.standUpEntries?.[0]?.updatedAt}
        emoji={
          isNumber(data?.standUpEntries?.[0]?.mood)
            ? data?.standUpEntries?.[0]?.mood
            : 3
        }
        yesterday={{
          ...data?.standUpEntries?.[0]?.yesterday,
          questionText:
            standupEntry?.standUp?.standUpQuestions?.[0]?.questionText,
        }}
        today={{
          ...data?.standUpEntries?.[0]?.today,
          questionText:
            standupEntry?.standUp?.standUpQuestions?.[1]?.questionText,
        }}
        blocker={{
          ...data?.standUpEntries?.[0]?.blocker,
          questionText:
            standupEntry?.standUp?.standUpQuestions?.[2]?.questionText,
        }}
        userRole={data?.standUpEntries?.[0]?.user?.title || '-'}
        hasStandupEntries={data?.standUpEntries?.length > 0}
      />
    </>
  );
};

export default withRouter(UserProfile);
