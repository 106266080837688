import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import Colors from 'design/Colors';

export const StyledModal = styled(AntModal)`
  ul {
    list-style-type: none;
    cursor: row-resize;
    padding: 0;
    margin-bottom: 0;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header,
  .ant-modal-footer {
  }
  .ant-modal-body {
    padding:16px;
  }
  .ant-modal-header {
    padding: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-color: ${Colors.Border};
  }
  .ant-modal-footer {
    text-align: center;
    padding: 16px;
  }
`;

export const Li = styled.li`
  padding: 5px;
  border: 1px solid ${Colors.Border};
  border-radius: 8px;
  margin-bottom: 16px;
  :last-child{
    margin-bottom: 0px;
  }
  .question {
    margin-bottom: 0;
  }
  .question b {
    margin-right: 10px;
    color: ${Colors.TextSecondary};
  }
`;
