import React, { useEffect, useState, useContext } from 'react';
import { Form, Layout, message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useQuery } from '@apollo/client';

import client from 'apollo';
import {
  EMPTY_OBJECT, FETCH_POLICY, ACTIONS_TYPE, FORM_NAME, SWITCH_TYPE,
} from 'utils/constants';
import { CHECK_IN_SETTING } from 'features/people/graphql/Settings/CheckInCheckOutSetting/Queries';
import { UPDATE_CHECK_IN_SETTING } from 'features/people/graphql/Settings/CheckInCheckOutSetting/Mutations';
import { AppContext } from 'AppContext';
import InfoIcon from 'atoms/InfoIcon';
import * as Sentry from '@sentry/react';
import Switch from 'atoms/Switch';
import GroupButtons from 'molecules/GroupButtons';
import { Clock } from 'assets';
import moment from 'moment';
import { FormItemDiv, TimePicker, Image } from './AntStyled';

const CheckInCheckOutSetting = ({ intl }) => {
  const [buttonActive, setButtonActive] = useState(true);
  const [autoCheckOut, setAutoCheckOut] = useState(false);
  const [checkoutReport, setCheckoutReport] = useState(false);
  const { dispatch } = useContext(AppContext);
  const [form] = Form.useForm();
  const { data: checkInSettingData } = useQuery(CHECK_IN_SETTING, { fetchPolicy: FETCH_POLICY.CACHE_FIRST });
  const [checkInCheckOutSetting, setCheckInCheckOutSetting] = useState(EMPTY_OBJECT);

  const Title = () => (
    <InfoIcon title={<FormattedMessage id="dailyGoalsSettings.text" />} />
  );

  useEffect(() => {
    if (checkInSettingData && checkInSettingData.checkInSetting) {
      setCheckInCheckOutSetting(checkInSettingData.checkInSetting);
    }
    dispatch({ type: ACTIONS_TYPE.SETTING_TITLE, data: <Title /> });
  }, [checkInSettingData, dispatch]);

  useEffect(() => {
    if (checkInCheckOutSetting) {
      const {
        enableCheckOutReport,
        checkOutReportLocalTime,
        enableAutoCheckOut,
        autoCheckOutTime,
        dailyGoalReportLocalTime,
      } = checkInCheckOutSetting;

      form.setFieldsValue({
        enableCheckOutReport,
        checkOutReportLocalTime:
          checkOutReportLocalTime
          && moment(checkOutReportLocalTime, moment.HTML5_FMT.TIME_MS).add(
            330,
            'minutes'
          ),
        enableAutoCheckOut,
        autoCheckOutTime:
          autoCheckOutTime
          && moment(autoCheckOutTime, moment.HTML5_FMT.TIME_MS).add(
            330,
            'minutes'
          ),
        dailyGoalReportLocalTime:
          dailyGoalReportLocalTime
          && moment(dailyGoalReportLocalTime, moment.HTML5_FMT.TIME_MS).add(
            330,
            'minutes'
          ),
      });
      setAutoCheckOut(enableAutoCheckOut);
      setCheckoutReport(enableCheckOutReport);
    }
  }, [checkInCheckOutSetting]);

  const discardChange = () => {
    if (checkInCheckOutSetting) {
      const {
        enableCheckOutReport,
        checkOutReportLocalTime,
        enableAutoCheckOut,
        autoCheckOutTime,
        dailyGoalReportLocalTime,
      } = checkInCheckOutSetting;
      form.setFieldsValue({
        enableCheckOutReport,
        checkOutReportLocalTime:
          checkOutReportLocalTime
          && moment(checkOutReportLocalTime, moment.HTML5_FMT.TIME_MS).add(
            330,
            'minutes'
          ),
        enableAutoCheckOut,
        autoCheckOutTime:
          autoCheckOutTime
          && moment(autoCheckOutTime, moment.HTML5_FMT.TIME_MS).add(
            330,
            'minutes'
          ),
        dailyGoalReportLocalTime:
          dailyGoalReportLocalTime
          && moment(dailyGoalReportLocalTime, moment.HTML5_FMT.TIME_MS).add(
            330,
            'minutes'
          ),
      });
      setAutoCheckOut(enableAutoCheckOut);
      setCheckoutReport(enableCheckOutReport);
    }
    setButtonActive(true);
  };

  const onFormLayoutChange = () => {
    setButtonActive(false);
  };

  const updateCheckInSetting = async (data) => {
    try {
      await client.mutate({
        mutation: UPDATE_CHECK_IN_SETTING,
        variables: {
          data,
          where: {
            id: checkInCheckOutSetting.id,
          },
        },
        refetchQueries: [{
          query: CHECK_IN_SETTING,
        }],
      });
      message.destroy();
      message.success(intl.formatMessage({ id: 'settings.checkIn.dailyGoal' }));
      // eslint-disable-next-line no-empty
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const onFinish = async (values) => {
    const update = {
      enableCheckOutReport: values.enableCheckOutReport,
      checkOutReportLocalTime: `${moment(values.checkOutReportLocalTime)
        .subtract(330, 'minutes')
        .format(moment.HTML5_FMT.TIME_MS)}Z`,
      enableAutoCheckOut: values.enableAutoCheckOut,
      autoCheckOutTime: `${moment(values.autoCheckOutTime)
        .subtract(330, 'minutes')
        .format(moment.HTML5_FMT.TIME_MS)}Z`,
      dailyGoalReportLocalTime: `${moment(values.dailyGoalReportLocalTime)
        .subtract(330, 'minutes')
        .format(moment.HTML5_FMT.TIME_MS)}Z`,
    };
    updateCheckInSetting({ ...update });
    setButtonActive(true);
  };

  return (
    <>
      <Form
        form={form}
        layout={FORM_NAME.VERTICAL}
        onFinish={onFinish}
        onValuesChange={onFormLayoutChange}
      >
        <Form.Item
          label={<FormattedMessage id="settings.checkIn.label1" />}
          name="dailyGoalReportLocalTime"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="settings.checkIn.dailyGoalReport" />
              ),
            },
          ]}
        >
          <TimePicker
            use12Hours
            format="h:mm A"
            suffixIcon={<Image src={Clock} />}
            allowClear={false}
          />
        </Form.Item>
        <FormItemDiv>
          <Form.Item name="enableCheckOutReport" valuePropName="checked">
            <Switch
              size={SWITCH_TYPE.SMALL}
              right="8px"
              onChange={checked => setCheckoutReport(checked)}
            />
          </Form.Item>
          <p className="text">
            <FormattedMessage id="settings.checkOut.checkOutUpdate" />
          </p>
        </FormItemDiv>
        {checkoutReport && (
          <Form.Item
            label={<FormattedMessage id="settings.checkOut.localTime" />}
            name="checkOutReportLocalTime"
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="settings.checkOut.errorLocalTime" />
                ),
              },
            ]}
          >
            <TimePicker
              use12Hours
              format="h:mm A"
              suffixIcon={<Image src={Clock} />}
              allowClear={false}
            />
          </Form.Item>
        )}
        <FormItemDiv>
          <Form.Item name="enableAutoCheckOut" valuePropName="checked">
            <Switch
              size={SWITCH_TYPE.SMALL}
              right="8px"
              onChange={checked => setAutoCheckOut(checked)}
            />
          </Form.Item>
          <p>
            <FormattedMessage id="settings.checkOut.autoCheckOut" />
          </p>
        </FormItemDiv>
        {autoCheckOut && (
          <Form.Item
            label={<FormattedMessage id="settings.checkOut.auto" />}
            name="autoCheckOutTime"
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="settings.checkOut.errorAutoCheckOut" />
                ),
              },
            ]}
          >
            <TimePicker
              use12Hours
              format="h:mm A"
              suffixIcon={<Image src={Clock} />}
              allowClear={false}
            />
          </Form.Item>
        )}
        <GroupButtons
          buttonActive={buttonActive}
          discardChange={discardChange}
        />
      </Form>
    </>
  );
};
export default injectIntl(CheckInCheckOutSetting);
