import { Layout } from 'antd';
import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  .ant-form-item textarea.ant-input {
    border-radius: 8px;
  }
  .title {
    font: ${fonts.small.regular};
    color: ${Colors.Text};
    line-height: 20px;
    margin: 0px;
  }
  .message {
    font: ${fonts.small.regular};
    color: ${Colors.TextDark};
    margin-bottom: calc(${Spacings.small.margin} - 2px);
    line-height: 22px;
  }
  .heading {
    color: ${Colors.TextDark};
    font: ${fonts.medium.bold};
    line-height: 24px;
    margin-bottom: calc(${Spacings.xSmall.margin} - 4px);
  }
  .Card-form {
    margin-top: ${Spacings.xSmall.margin};
  }
  .model-action {
    margin-top: ${Spacings.xSmall.margin};
  }
  .ant-form-item {
    margin-bottom: ${Spacings.xSmall.margin};
    font-size: ${fonts.medium.size};
    &:last-child {
      margin-bottom: ${Spacings.small.margin};
    }
  }
  .ant-row.ant-form-item:last-of-type {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
  .ant-radio-wrapper {
    color: ${Colors.Radio};
  }
  .ant-radio-wrapper-checked {
    color: ${Colors.PrimaryColor};
  }
  .ant-checkbox-group {
  }
  .ant-checkbox + span {
    color: ${Colors.TextSecondary};
  }
  .template-button {
    background: transparent;
    border: none;
    color: ${Colors.ButtonPrimary};
    box-shadow: none;
  }
`;
export const Title = styled.p`
  font: ${fonts.small.regular};
  line-height: 22px;
  color: ${Colors.TextDark};
  margin: 0px 0px calc(${Spacings.xSmall.margin} - 4px) 0px;
`;
