import { Layout } from 'antd';
import styled from 'styled-components';

import Spacings from 'design/Spacings';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { scrollbars } from 'design/Styled';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  display: flex;
  .no-widget {
    font: ${fonts.large.regular};
    color: ${Colors.Text};
    text-align: center;
  }
  .mark-as-read {
    margin-left: 12px;
    cursor: pointer;
  }
  .ant-divider-horizontal {
    margin: 0;
  }
  .template-button {
    background: transparent;
    border: none;
    color: ${Colors.PrimaryColor};
    box-shadow: none;
    margin-right: 12px;
  }
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
  .anticon-check-circle,
  .anticon-minus-circle {
    font-size: 26px;
    background: ${Colors.ButtonPrimary};
    color: ${Colors.TextLight};
    border-radius: 12px;
    margin-top: ${Spacings.small.margin};
    cursor: pointer;
  }
  .update-template-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${Spacings.xSmall.padding} ${Spacings.xSmall.padding}
      ${Spacings.small.padding};
    .title-row {
      margin-right: auto;
    }
    .title {
      font: ${fonts.small.medium};
      color: ${Colors.TextDark};
      margin-left: 8px;
    }
    .time {
      color: ${Colors.Text};
      font-size: ${fonts.xSmall.size};
    }
  }
  .update-template-body {
    padding: 0 ${Spacings.xSmall.padding} ${Spacings.xSmall.padding};
    font: ${fonts.medium.medium};
    color: ${Colors.Text};
  }
  .update-template-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${Spacings.xSmall.padding};
    .ant-tag:last-of-type {
      margin-right: 0;
    }
  }
  .ant-btn-primary {
    margin-top: ${Spacings.small.margin};
    margin-left: ${Spacings.large.padding};
  }
  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${Colors.Border};
    margin: 0 12px 0 2px;
  }
  .feedback-end-date {
    border: 1px solid ${Colors.Border};
    padding: 2px 8px;
    border-radius: 4px;
    margin-left: 8px;
    color: ${Colors.Text};
    font: ${fonts.xSmall.regular};
  }
  .count {
    padding: 10px;
    border: 1px solid ${Colors.Border};
    border-radius: 4px;
    margin-right: ${Spacings.xSmall.margin};
    font: ${fonts.xSmall.medium};
    span {
      margin-right: 10px;
      font-weight: 400;
    }
    .green {
      color: ${Colors.TextSuccess};
    }
    .red {
      color: ${Colors.TextDanger};
    }
    .purple {
      color: ${Colors.PrimaryColor};
    }
  }
  .result-stats {
    display: flex;
    align-items: center;
    font-size: ${fonts.xSmall.size};
    margin-top: ${Spacings.xSmall.margin};
    margin-bottom: ${Spacings.xSmall.margin};
    .percent {
      color: ${Colors.PrimaryColor};
      margin: 0 ${Spacings.xxSmall.margin};
    }
    .ant-progress {
      line-height: normal;
      font-size: ${fonts.small.size};
    }
    .head {
      display: none;
    }
  }

  @keyframes fadeout {
    0% {
      left: 0px;
      top: 0px;
    }

    100% {
      left: 400px;
      top: 0px;
      opacity: 0;
    }
  }
  .fadeout {
    animation-name: fadeout;
    animation-duration: 1s;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
  .ant-card {
    border-radius: 4px;
    border: 1px solid ${Colors.SecondaryColor};
    box-shadow: none;
  }
  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Header = styled.div`
  .header {
    flex-wrap: nowrap;
    margin-left: ${`calc( ${Spacings.small.margin} - 4px )`};
    margin-right: ${`calc( ${Spacings.small.margin} + 26px )`};
    margin-bottom: ${Spacings.small.margin};
    margin-top: ${Spacings.tiny.margin};
  }
  .group-col {
    font-size: 0.875em;
  }
  .header .ant-col {
    display: flex;
    /* align-items: center; */
  }

  .ant-col-4 {
    justify-content: flex-end;
  }

  .ant-col-24 {
    margin-top: ${Spacings.xxSmall.margin};
    padding-left: ${Spacings.large.padding};
  }
`;

export const Icon = styled.div`
  line-height:0;
  /* background: ${Colors.TextSuccess}; */
  margin-right: ${Spacings.xxSmall.margin};
  svg {
    fill: ${Colors.TextSecondary};

  }
`;

export const Title = styled.div`
  /* padding-top:2px; */
  font: ${fonts.large.bold};
  color: ${Colors.TextPrimary};
`;

export const Generated = styled.div`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  margin-top: 20px;
  margin-bottom: 5px;
`;

export const DateWrapper = styled.div`
  font: ${fonts.medium.bold};
  color: ${Colors.TextSecondary};
  margin-bottom: 19px;
`;
export const ContentWrapper = styled.div`
  width: 100%;
`;
export const CheckWrapper = styled.div`
  position: relative;
  bottom: 16px;
  left: 4px;
  cursor: pointer;
  svg {
    fill: ${Colors.TextSecondary};
  }
`;
export const StepsWrapper = styled.div`
  .steps-content {
    margin-bottom: -16px;
  }
`;
