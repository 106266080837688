import { Layout } from 'antd';
import styled from 'styled-components';
import Spacings from 'design/Spacings';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`

  .ant-table{
    border: 1px solid ${Colors.Border};
  }
  .anticon-check {
    color: ${Colors.ButtonPrimary};
    font: ${fonts.xxLarge.regular};
  }

  .anticon-info-circle {
    font-size: 20px;
    cursor: pointer;
  }
`;
export const TimeDiv = styled.div`
  font-size: calc(${fonts.xxSmall.size} - 1px);
  font-weight:500;
  color: ${Colors.TextEnable};
`;
export const Comments = styled.div`
  color: ${Colors.ButtonPrimary};
  font: ${fonts.medium.regular};
  white-space: nowrap;
  cursor: pointer;
`;

export const Title = styled.div`
  white-space: normal;
  word-break:break-all;
  min-width:200px;
`;

export const LoaderDiv = styled.div`
  padding-bottom: 35px;
  padding-top: 10px;
`;
