import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, message, Input, Layout } from 'antd';
import Card from 'atoms/Card';
import Button from 'atoms/Button';
import SettingsTemplate from 'atoms/SettingsTemplate';
import _get from 'lodash/get';
import {
  PRIVATE_ROUTE,
  FEEDBACK_TYPE,
  FETCH_POLICY,
  EMPTY_ARRAY,
  AMPLITUDE_EVENTS,
} from 'utils/constants';
import * as Sentry from '@sentry/react';
import { BackArrow, Trash } from 'assets';
import InfoIcon from 'atoms/InfoIcon';
import { FormattedMessage, injectIntl } from 'react-intl';
import GroupTag from 'atoms/GroupTag';
import client from 'apollo';
import Header from 'layout/Header';
import { Prompt, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_FEEDBACK_BYID } from 'features/feedbacks/graphql/Queries';
import { GET_GROUPS } from 'graphql/Groups/Queries';
import { filter } from 'lodash';
import GroupTagList from 'organisms/GroupTagList';
import moment from 'moment';
import DropdownButton from 'molecules/DropdownButton';
import { confirmationModal } from 'atoms/ConfirmationModal';
import _map from 'lodash/map';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import { createAmplitudeEvent } from 'utils/amplitude';
import ContentWrapper from 'atoms/ContentWrapper';
import {
  ContentTitle,
  Content,
  Form,
  Heading,
  Image,
  StyledImage,
} from './AntStyled';
import EmptyQuestion from './EmptyQuestion';
import {
  CREATE_FEEDBACK,
  DELETE_FEEDBACK,
  PUBLISH_FEEDBACK,
  UPDATE_FEEDBACK,
} from '../../../graphql/Mutations';
const { Paragraph } = Typography;

function PollQuestions(props) {
  const { history, intl } = props;
  const [initialValues, setInitialValues] = useState(EMPTY_ARRAY);
  const [publish, setPublish] = useState(false);
  const [form] = Form.useForm();
  const [isTemplate, setIsTemplate] = useState(false);
  const [isPromptRequired, setIsPromptRequired] = useState(true);
  const formValues = _get(history, 'location.state.values');
  const groupsIds = _get(history, 'location.state.values.groups', []);
  const id = _get(history, 'location.id.id');
  const question = _get(history, 'location.questions[0]');
  const endOn = _get(history, 'location.endOn');
  const scheduleOn = _get(history, 'location.scheduleOn');
  const [createFeedback] = useMutation(CREATE_FEEDBACK);
  const [updateFeedback] = useMutation(UPDATE_FEEDBACK);
  const [publishFeedback] = useMutation(PUBLISH_FEEDBACK);
  const [loading, setLoading] = useState(false);
  const { data: userGroups } = useQuery(GET_GROUPS);
  const Title = () => (
    <>
      <ContentTitle>
        <Image
          src={BackArrow}
          alt="arrow"
          onClick={() =>
            history.push({
              pathname: PRIVATE_ROUTE.POLL_CREATE,
              state: {
                templateData: formValues,
                fromStep: 'step2',
              },
            })
          }
        />
        <InfoIcon
          title={
            <FormattedMessage
              id={id ? 'feedback.new.pollEditTitle' : 'feedback.new.pollTitle'}
            />
          }
        />
      </ContentTitle>
    </>
  );

  const onFinish = async (values) => {
    setIsPromptRequired(false);
    const { questions } = values;
    const questionData = {
      ...questions,
      choices:
        questions?.questionType === 'LIKE'
          ? ['DISLIKE', 'LIKE']
          : questions?.choices,
    };
    const data = {
      title: formValues?.title,
      description: formValues?.description,
      type: FEEDBACK_TYPE.POLL,
      scheduleOn: moment.utc(
        formValues?.deliveryOption === 'NOW'
          ? new Date()
          : formValues?.deliveryValue
          ? formValues?.deliveryValue
          : scheduleOn
      ),
      endOn: moment.utc(
        formValues?.endTime === 'MANUAL'
          ? null
          : formValues?.endTimeValue
          ? formValues?.endTimeValue
          : endOn
      ),
      isTemplate,
      groups: formValues.groups,
      resultVisibility: formValues.permissions,
      questions: [{ ...questionData }],
    };
    const mutation = id ? updateFeedback : createFeedback;
    const where = id ? { where: { id } } : {};
    mutation({
      variables: {
        data,
        ...where,
      },
    })
      .then((res) => {
        const publishId =
          mutation === createFeedback
            ? res?.data?.createFeedback?.id
            : res?.data?.updateFeedback?.id;
        if (publish) {
          publishFeedback({
            variables: { where: { id: publishId } },
          });
        }
        const data = id ? res?.data?.updateFeedback : res?.data?.createFeedback;
        const eventProperties = {
          Id: data?.id,
          Title: data?.title,
          Type: data?.type,
          Description: data?.description,
          ScheduleOn: moment
            .utc(data?.scheduleOn)
            .local()
            .format(MomentFormat.dateTime),
          EndOn: moment.utc(data?.endOn).local().format(MomentFormat.dateTime),
          ResultVisibility: data?.resultVisibility,
          createdBy: `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`,
          Email: data?.createdBy?.email,
          UserId: data?.createdBy?.id,
          groups: _map(data?.groups, (data) => ({
            name: data?.name,
            id: data?.id,
            type: data?.type,
          })),
          IsTemplate: data?.isTemplate,
          questions: _map(data?.questions?.questions, (data) => ({
            id: data?.id,
            questionType: data?.questionType,
            question: data?.question,
            choices: data?.choices,
            scaleLabel: data?.scaleLabel,
          })),
        };
        createAmplitudeEvent(
          id
            ? AMPLITUDE_EVENTS.FEEDBACK_UPDATED
            : AMPLITUDE_EVENTS.FEEDBACK_CREATED,
          eventProperties
        );
        history.push(PRIVATE_ROUTE.FEED_BACK_CURRENT);
        message.success(`${id ? 'Updated' : 'Created'} successfully`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      client
        .query({
          query: GET_FEEDBACK_BYID,
          fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
          variables: { where: { id } },
        })
        .then(({ data }) => {
          const { feedback } = data;
          const initialValuesObject = {
            questions: feedback?.questions?.questions,
          };
          setInitialValues(initialValuesObject);
          setLoading(false);
          form.resetFields();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), [question]);

  const deleteFeedback = () => {
    setIsPromptRequired(false);
    const deleteConfirmation = async () => {
      try {
        await client
          .mutate({
            mutation: DELETE_FEEDBACK,
            variables: {
              id,
            },
          })
          .then(() => {
            const eventProperties = {
              Id: id,
              title: formValues?.title,
              description: formValues?.description,
              type: FEEDBACK_TYPE.POLL,
            };
            createAmplitudeEvent(
              AMPLITUDE_EVENTS.FEEDBACK_DELETED,
              eventProperties
            );
            message.destroy();
            message.success(
              intl.formatMessage({
                id: 'users.successMessages.deleteFeedback',
              })
            );
            history.push(PRIVATE_ROUTE.FEED_BACK_DRAFT);
          });
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.log(error);
      }
    };
    confirmationModal(
      intl.formatMessage({ id: 'feedback.sure' }),
      deleteConfirmation
    );
  };

  return (
    <Layout>
      <Header title={<Title />} />
      <ContentWrapper>
        <Prompt
          message="You have unsaved changes! Are you sure you want to leave this page"
          when={isPromptRequired}
        />
        <Content className="top">
          <Card padding="16" className="header">
            <Heading>{formValues?.title}</Heading>
            <Paragraph className="paragraph">
              {formValues?.description}
            </Paragraph>
            <GroupTagList
              departments={filter(userGroups?.groups?.groups, (group) =>
                groupsIds.includes(parseInt(group?.id))
              )}
              limit={5}
            />
          </Card>
        </Content>
        <Content id="drop">
          <Form
            name="questions"
            layout="vertical"
            onFinish={onFinish}
            form={form}
            initialValues={{
              questions: {
                ...question,
                choices: [null, null],
              },
            }}
            scrollToFirstError
            onValuesChange={() => setIsPromptRequired(true)}
          >
            <Card
              padding={16}
              className="card"
              title={
                <Row justify="space-between" align="center">
                  <Col>Question</Col>
                </Row>
              }
            >
              <div className="question-template">
                <SettingsTemplate>
                  <Row>
                    <Col span={24}>
                      <EmptyQuestion form={form} name="questions" />
                    </Col>
                  </Row>
                </SettingsTemplate>
              </div>
            </Card>
            <Row justify="space-between" className="row">
              <span>
                <Form.Item>
                  {id && (
                    <Button
                      type="text"
                      name="Delete Poll"
                      className="template-button delete-title"
                      icon={<StyledImage src={Trash} />}
                      onClick={() => {
                        deleteFeedback();
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Form.Item>
              </span>
              <Row justify="center">
                <Form.Item name="isTemplate" className="right">
                  <Button
                    htmlType="submit"
                    type="text"
                    className="template-button mark-as-read"
                    name="Save As template"
                    link
                    onClick={() => setIsTemplate(true)}
                  />
                </Form.Item>

                <Form.Item>
                  <DropdownButton
                    htmlType="submit"
                    handlePublished={() => {
                      setIsTemplate(false);
                      setPublish(true);
                    }}
                    handleSaveAsDraft={() => {
                      setIsTemplate(false);
                      form.submit();
                    }}
                  />
                </Form.Item>
              </Row>
            </Row>
          </Form>
        </Content>
      </ContentWrapper>
    </Layout>
  );
}
export default withRouter(injectIntl(PollQuestions));
