import { gql } from '@apollo/client';
import { leaveRequestFragment, userFragment } from './Fragments';

export const GET_LEAVE_REQUESTS = gql`
  query leaveRequests($where: LeaveRequestWhereInput){
    leaveRequests(where: $where){
      leaveRequest {
        id
        status
        description
        isCancelled
        leaveTypes
        from
        to
        createdAt
        updatedAt
        leaves {
          id
          dayType
          leaveType
          isCancelled
        }
        user{
          ...UserFragment
        }
        assignments{
          id
          firstName
          lastName
        }
      }
      count
    }
  }
  ${userFragment}
`;

export const GET_LEAVE_SUMMARY = gql`
  query leaveSummary($where: LeaveSummaryWhereInput, $skip: Int, $limit: Int){
    leaveSummary(where: $where, skip: $skip, limit: $limit){
      leaveSummary{
        analytics
        user{
        ...UserFragment
        }
      }
      count
    }
  }
  ${userFragment}
`;
