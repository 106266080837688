import React from 'react';
import { Router, Switch, Route, withRouter } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'utils/constants';
import ability from 'utils/ability/ability';
import Page404 from 'pages/404';
import { StandUpIcon } from 'assets';
import StandUpPage from './StandUpPage';

const StandUp = ({ history }) => (
  <Router history={history}>
    <Switch>
      {ability.can('use', 'standups_feature') && (
        <Route
          path={PRIVATE_ROUTE.MY_CHECK_IN}
          component={StandUpPage.component}
        />
      )}
      <Route exact path="*" render={() => <Page404 />} />
    </Switch>
  </Router>
);

export default {
  component: withRouter(StandUp),
  key: PRIVATE_ROUTE.MY_CHECK_IN,
  defaultPath: PRIVATE_ROUTE.MY_CHECK_IN,
  name: 'Stand-ups',
  type: 'user',
  icon: (
    <i
      data-eva="checkmark-circle-outline"
      data-eva-height="20"
      data-eva-width="20"
    ></i>
  ),
  abilitySubject: 'standups_feature',
  abilityAction: 'use',
};
