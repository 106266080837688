import React from 'react';

import Meta from 'atoms/Meta';
import { accessDenied } from 'assets';
import { META_TYPE } from 'utils/constants';
import {
  Image,
  ImageWrapper,
} from './AntStyled';

export default function () {
  return (
    <div>
      <Meta title={META_TYPE.ALREADY_REGISTERED} />
      <ImageWrapper><Image src={accessDenied} /></ImageWrapper>
    </div>
  );
}
