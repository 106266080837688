import styled from 'styled-components';
import { Form, Typography, Card, Row, Upload, Avatar } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { link, x, y } from 'design/Styled';
import { Loginbg } from 'assets';

export const Container = styled.div`
  ${x};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${Colors.SidebarText};
  .inputLogo2 {
    flex-grow: 1;
  }
  .continue {
    width: 25%;
  }
  .ant-avatar > img {
    object-fit: contain;
  }
  .ant-btn-primary {
    font: ${fonts.small.regular};
    line-height: 22px;
  }
`;
export const Section = styled.div`
  position: absolute;
  top: 50%;
  left: 55%;
  width: 100%;
  transform: translate(-50%, -50%);
`;
export const LogoIcon = styled.img`
  width: 35px !important;
  height: 35px !important;
  image-rendering: crisp-edges;
`;
export const UploadIcon = styled(Upload)`
  margin-right: 20px;
`;
export const AvatarIcon = styled(Avatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.SidebarText};
  border: 0.693333px solid ${Colors.Border};
`;
export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  font: ${fonts.small.bold};
  color: ${Colors.TextSecondary};
  .number-list {
    align-self: flex-start;
    font: ${fonts.xSmall.bold};
    padding-right: 20px;
    padding-top: 3px;
    line-height: 16px;
  }
  .text {
    font: ${fonts.medium.bold};
    line-height: 24px;
  }
`;
export const BasicCard = styled(Card)`
  .ant-card-body {
    padding: 16px;
  }
  text-align: left;
  box-shadow: 0px 10px 30px rgba(81, 89, 131, 0.3);
  border-radius: 8px;
  width: 65%;
  margin-bottom: ${Spacings.xSmall.margin};
`;
export const Heading = styled(Typography)`
  font: ${fonts.title.bold};
  color: ${Colors.SidebarText};
  text-align: left;
  margin-bottom: ${Spacings.small.margin};
`;
export const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${Loginbg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  .bottom-left {
    position: absolute;
    left: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
  .bottom-right {
    position: absolute;
    right: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
`;
export const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 180px;
  transform: translate(-50%, -50%);
  @media (max-width: 1120px) {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Title = styled.div`
  font: ${fonts.huge.bold};
  color: ${Colors.TextPrimary};
  line-height: 32px;
  width: 408px;
  margin-bottom: ${Spacings.medium.margin};
`;

export const Wrapper = styled.div`
  .ant-card {
    overflow: hidden;
    width: 100%;
    max-width: 408px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  & .no-mb {
    margin-bottom: 40px;
  }
  @media (max-width: 1120px) {
    position: absolute;
    top: 120%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;

export const InputIcon = styled.img``;

export const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 24px;
  }
  .ant-form-item:nth-child(4) {
    margin-bottom: 32px;
  }
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
`;
export const FullDivider = styled.hr`
  border: 1px solid ${Colors.Border};
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin-left: -16px;
  margin-right: -16px;
  overflow: hidden;
  margin-block-start: 0em;
  margin-block-end: 0em;
`;
export const ProfilePage = styled.div`
  padding-top: 32px;
`;
export const Terms = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 408px;
  margin-top: 10px;
  align-self: center;
`;
