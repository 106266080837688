/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import * as eva from 'eva-icons';
import GroupTagList from 'organisms/GroupTagList';
import UserProfile from 'molecules/UserProfile';
import DateTime from 'atoms/DateTime';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import { NOTIFICATIONS, SURVEYS_TYPE } from 'utils/constants';
import { capitalize } from 'lodash';
import Progress from 'atoms/Progress';
import { notificationData } from './MockData';
import { Content, StyledText, Heading, Date } from './AntStyled';

export default function (props) {
  const { data } = props;
  useEffect(() => {
    eva.replace();
  }, []);

  let icon = '';
  let title = '';
  let departments = '';

  switch (data?.type) {
    case NOTIFICATIONS.ANNOUNCEMENT_PUBLISHED:
      title = data?.notifiable?.announcementtitle;
      icon = notificationData?.title?.icon;
      departments = data?.notifiable?.announcementgroups;
      break;
    case NOTIFICATIONS.FEEDBACK_REQUEST:
    case NOTIFICATIONS.FEEDBACK_RESULT:
      title = data?.notifiable?.feedbacktitle;
      departments = data?.notifiable?.feedbackgroups;

      if (data?.notifiable?.feedbacktype === SURVEYS_TYPE.POLLS) {
        icon = notificationData?.feedback?.poll?.icon;
      } else if (data?.notifiable?.feedbacktype === SURVEYS_TYPE.SURVEYS) {
        icon = notificationData?.feedback?.survey?.icon;
      }
      break;
    default:
      break;
  }

  return (
    <Content>
      {data?.notifiable && (
        <>
          <Row justify="space-between">
            <Col>
              <Row align="middle">
                <Col className="icon">{icon}</Col>
                <Col>
                  <Heading>{title}</Heading>
                </Col>
              </Row>
            </Col>
            {data?.notifiable?.feedbackendOn ? (
              data.type === NOTIFICATIONS.FEEDBACK_RESULT ? (
                <div className="result-stats">
                  <Progress
                    width="20px"
                    percent={data?.data?.participation}
                    strokeWidth={14}
                  />
                  <div className="percent">{data?.data?.participation}%</div>
                  <div className="stats-description">
                    {capitalize(data?.notifiable?.feedbacktype)} Participation
                  </div>
                </div>
              ) : (
                <Col>
                  <Row align="middle">
                    <Col>
                      <Date>{notificationData?.due?.date}</Date>
                      <Date>
                        <DateTime
                          date={data?.notifiable?.feedbackendOn}
                          format={MomentFormat.MMM}
                        ></DateTime>
                      </Date>
                    </Col>
                    <Col>{notificationData?.due?.icon}</Col>
                  </Row>
                </Col>
              )
            ) : (
              ''
            )}
          </Row>
          <Row className="groups">
            <Col>
              <GroupTagList departments={departments} />
            </Col>
          </Row>
        </>
      )}
      {data?.generator && (
        <Col>
          <Row>
            <StyledText>from</StyledText>
          </Row>
          <Row>
            <UserProfile
              size={18}
              stackedName={false}
              profile={data?.generator?.profileImage}
              name={
                data?.generator?.firstName && data?.generator?.lastName
                  ? `${data?.generator?.firstName} ${data?.generator?.lastName}`
                  : data?.generator?.email
              }
            />
          </Row>
        </Col>
      )}
    </Content>
  );
}
