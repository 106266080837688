/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import { Form, Select as AntSelect } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import UserProfile from 'molecules/UserProfile';
import { EMPTY_STRING } from 'utils/constants';
import { fonts } from 'design/Fonts';
import _get from 'lodash/get';
import Colors from 'design/Colors';
import { ArrowIcon } from 'assets';
import { split } from 'lodash';

const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 3px;
  margin-top: -6px;
`;
const Select = styled(AntSelect)`
  font-size: 14px;
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 32px;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  .ant-select-selection-placeholder {
    font: ${fonts.xSmall.regular};
  }
  &.ant-select {
    background: white;
    & .ant-select-selector {
      padding-right: 20px;
      padding-left: 8px;
      border: none;
      max-height: 30px;
    }
  }

  &.ant-select-multiple .ant-select-selector {
    border: none;
    background-color: transparent;
  }
  &.ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 14px;
    left: 14px;
    font-size: 14px;
  }
  //! due to inline styling using important
  .ant-select-selection-search {
    width: 10% !important;
  }
  &.ant-select-multiple
    .ant-select-selection-search:first-child
    .ant-select-selection-search-input {
    margin-left: 0px;
    padding-left: 4px;
  }
  .multiple-user {
    .name {
      text-overflow: ellipsis;
      @media only screen and (max-width: 767px) {
        width: 70px;
      }
      @media (min-width: 768px) and (max-width: 1642px) {
        max-width: 90px;
      }
    }
  }
`;
const { Option } = Select;

export default function (props) {
  const {
    className,
    label,
    name,
    rules,
    mode,
    users,
    getPopupContainer,
    dep,
    handleChange,
  } = props;

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1050);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1050);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const children = _map(users, (items, index) => (
    <Option key={index} value={parseInt(items.id, 10)}>
      <UserProfile
        size="small"
        stackedName={false}
        orientation="horizontal"
        profile={items.profileImage}
        name={
          items.firstName && items.lastName
            ? `${items.firstName} ${items.lastName}`
            : items.email
        }
      />
    </Option>
  ));

  const tagRender = (prop) => {
    const { label: tagLabel, closable, onClose } = prop;
    return closable ? (
      <UserProfile
        Background
        profile={tagLabel.props && tagLabel.props.profile}
        name={tagLabel.props && tagLabel.props.name}
        closable={closable}
        onIconClick={onClose}
        stackedName={false}
        bordered
        icon
        className="multiple-user"
      />
    ) : (
      <span className="extra-tag">
        {`${split(tagLabel, ' ', 2).join(' ')} More`}
      </span>
    );
  };
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      className={className}
      dependencies={dep}
    >
      <Select
        mode={mode ? 'multiple' : EMPTY_STRING}
        placeholder="Please select"
        tagRender={tagRender}
        allowClear
        showArrow
        filterOption={(input, option) => {
          const user = _get(option, 'children.props', '');
          return user && user.name.toLowerCase().includes(input.toLowerCase());
        }}
        suffixIcon={<Arrow src={ArrowIcon} />}
        getPopupContainer={getPopupContainer}
        onChange={handleChange}
        showSearch
        maxTagCount={isDesktop ? 2 : 1}
      >
        {children}
      </Select>
    </Form.Item>
  );
}
