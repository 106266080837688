import React, { useEffect, useState } from 'react';
import { Skeleton, Layout, message } from 'antd';
import { EMPTY_ARRAY, PRIVATE_ROUTE } from 'utils/constants';
import { ArchiveIcon, Pencil } from 'assets';
import { Link, useHistory } from 'react-router-dom';
import CommentsPopover from 'organisms/CommentsPopover';
import Table from 'atoms/Table';
import UserProfile from 'molecules/UserProfile';
import GroupTag from 'atoms/GroupTag';
import DateTime from 'atoms/DateTime';
import ContentWrapper from 'atoms/ContentWrapper';
import { titleCase } from 'utils/stringUtilities';
import GroupTagList from 'organisms/GroupTagList';
import ability from 'utils/ability/ability';
import PopConfirm from 'atoms/PopConfirmation';
import { useMutation } from '@apollo/client';
import { UPDATE_SOP } from '../../graphql/Mutations';
import { GET_ALL_SOPS } from '../../graphql/Queries';
import { Content, Comments, Image } from './AntStyled';

export default function (props) {
  const {
    loading, data, isArchive,
  } = props;
  const [sops, setSops] = useState(EMPTY_ARRAY);
  const [updateSop] = useMutation(UPDATE_SOP);
  const history = useHistory();

  useEffect(() => {
    if (data && data.length) {
      setSops(data);
    } else {
      setSops(EMPTY_ARRAY);
    }
  }, [data]);

  const handleOnArchive = (id) => {
    setSops(sops.filter(sop => sop.id !== id));
  };

  const actionColumns = [
    {
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      className: 'no-padding',
      render: (row, rowData) => (
        <Link to={`${PRIVATE_ROUTE.SOPS}/edit/${rowData.id}`}>
          <Image src={Pencil} alt="icon"></Image>
        </Link>
      ),
    },
    {
      align: 'right',
      dataIndex: 'operation',
      width: '1%',
      className: 'padding-col',
      render: (row, rowData) => !isArchive && (
        <PopConfirm
          title="Are you sure want to archive this SOP?"
          placement="bottomLeft"
          icon={(
            <Image
              src={ArchiveIcon}
              className="icon-delete"
            />
          )}
          onConfirm={() => {
            updateSop({
              variables: {
                data: { status: 'ARCHIVED' },
                where: { id: rowData.id },
              },
              refetchQueries: [
                {
                  query: GET_ALL_SOPS,
                },
              ],
            }).then(() => {
              handleOnArchive(rowData.id);
              history.push(PRIVATE_ROUTE.SOP_ARCHIVE);
              message.success('Archived successfully');
            });
          }}
        >
        </PopConfirm>
      ),
    },
  ];

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
      render: (title, rowData) => <Link to={`${PRIVATE_ROUTE.SOPS}/view/${rowData.id}`}>{title}</Link>,
    },
    {
      title: 'Department',
      dataIndex: 'departments',
      key: 'departments',
      width: '15%',
      render: (departments, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active />
        : <GroupTagList departments={departments} />,
    },
    {
      title: 'Last updated on',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '12%',
      render: (date, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : (
        <DateTime date={date} />
      ),
    },
    {
      title: 'Published by',
      dataIndex: 'writtenBy',
      key: 'user',
      render: (user, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : user ? (
        <UserProfile
          profile={user.profileImage}
          name={
            user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : user.email
          }
          userId={user.id}
          showLink
        />
      ) : (
        '-'
      ),
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      width: '10%',
      render: (version, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : version ? (
        <div>{version}</div>
      ) : '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => titleCase(text),
    },
    {
      dataIndex: 'comments',
      key: 'comments',
      width: '75px',
      render: (row, rowData) => !rowData.loading && (
        <Comments>
          <CommentsPopover data={rowData.comments} actionId={rowData.id} commentableType="Sops" />
        </Comments>
      ),
    },
    ...ability.can('manage', 'sops') ? actionColumns : [],
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];

  return (
    <Layout>
      <ContentWrapper>
        <Content>
          <Table
            columns={columns}
            dataSource={sops}
            height="calc(100vh - 99px - 15px)"
            loading={loading}
            className="bb-0"
          />
        </Content>
      </ContentWrapper>
    </Layout>
  );
}
