import styled from 'styled-components';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { y, x, link } from 'design/Styled';
import Spacings from 'design/Spacings';
import { Loginbg } from 'assets';

export const Container = styled.div`
  ${x};
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${Colors.SidebarText};
  @media (max-width: 1120px) {
    flex-direction: column;
  }
  .center {
    margin-top: 20px;
  }
`;
export const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${Loginbg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  .bottom-left {
    position: absolute;
    left: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
  .bottom-right {
    position: absolute;
    right: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
`;
export const Token = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${fonts.xSmall.size};
`;
export const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 1120px) {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Title = styled.div`
  font: ${fonts.title.bold};
  color: ${Colors.TextPrimary};
  text-align: center;
  line-height: 41px;
  margin-bottom: ${Spacings.xxSmall.margin};
`;

export const Wrapper = styled.div`
  .ant-card {
    overflow: hidden;
    width: 100%;
    max-width: 408px;
  }
  .ant-card-body {
    padding: 50px 0px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  @media (max-width: 1120px) {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;

export const Description = styled.div`
  font-size: ${fonts.small.regular};
  line-height: 26px;
  text-align: center;
  padding: 0px 0px 5px;
  color: ${Colors.TextSecondary};
`;
export const ConfirmLink = styled.p`
  font-size: ${fonts.small.regular};
  line-height: 26px;
  text-align: center;
  padding: 0px 0px 10px;
  color: ${Colors.TextSecondary};
`;

export const ImageWrapper = styled.div`
  ${x};
  justify-content: center;
  padding: 0px 0px 50px;
`;
export const EmailPop = styled.img`
  width: 100px;
  height: 100px;
  image-rendering: crisp-edges;
`;
