import { Layout } from 'antd';
import styled from 'styled-components';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  .ant-tabs-nav-wrap {
    font: ${fonts.medium.regular};
  }
  .ant-table{
    border: 1px solid ${Colors.Border};
  }
  /* filter by today */
  .today {
    width: 358px;

    & > span {
      padding-right: 21px;
      padding-left: 0;
    }
  }

  .ResponsiveBarToday {
    height: 25px;
    width: 290px;
  }

  /* filter by week */
  .week {
    padding-left:${Spacings.xSmall.padding};
    & > span {
      padding: 0px 11px;
    }
    svg{
      path{
        fill:black;
      }
    }
  }

  .responsiveBarWeek {
    height: 25px;
    width: 249px;
    & > div > div > svg > g > g > g > rect {
      width: 25px;
    }
  }

  /* filter by month */
  .month {
    min-width:775px;
    & > div {
      display: inline-block;
      width: 24px;
      text-align: center;
    }
  }

  .twentyEightDays {
    width: 675px;
    height: 25px;
    & > div > div > svg > g > g > g > rect {
      width: 22px;
    }
  }

  .twentyNineDays {
    width: 700px;
    height: 25px;
    & > div > div > svg > g > g > g > rect {
      width: 22px;
    }
  }

  .thirtyDays {
    width: 725px;
    height: 25px;
    & > div > div > svg > g > g > g > rect {
      width: 22px;
    }
  }

  .thirtyOneDays {
    width: 750px;
    height: 25px;
    & > div > div > svg > g > g > g > rect {
      width: 22px;
    }
  }

  .name {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }
  span.ant-tag{
    margin-right:0;
  }
`;

export const ContentTitle = styled.div`
  font: ${fonts.large.bold};
  color: ${Colors.Text};
  margin-bottom: ${Spacings.xSmall.margin};
`;
