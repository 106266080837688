import { gql } from '@apollo/client';

export const USER_LOGIN = gql`
  mutation login($data: LoginCreateInput) {
    login(data: $data)
  }
`;

export const GOOGLE_AUTH_LOGIN = gql`
  mutation loginWithGoogle {
    loginWithGoogle
  }
`;

export const MICROSOFT_AUTH_LOGIN = gql`
  mutation loginWithMicrosoft {
    loginWithMicrosoft
  }
`;

export const SLACK_AUTH_LOGIN = gql`
  mutation loginWithSlack {
    loginWithSlack
  }
`;

export const GOOGLE_AUTH_SIGN_UP = gql`
  mutation signupWithGoogle {
    signupWithGoogle
  }
`;

export const SLACK_AUTH_SIGN_UP = gql`
  mutation signupWithSlack {
    signupWithSlack
  }
`;

export const MICROSOFT_AUTH_SIGN_UP = gql`
  mutation signupWithMicrosoft {
    signupWithMicrosoft
  }
`;

export const LOCAL_SIGN_UP = gql`
  mutation localSignUp($data: UserSignUpInput!) {
    localSignUp(data: $data) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      token
      isInvited
      isVerified
    }
  }
`;

export const SEND_RESET_PASSWORD = gql`
  query sendResetPasswordLink($email: String!) {
    sendResetPasswordLink(email: $email)
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword($newPassword: String!) {
    setPassword(newPassword: $newPassword)
  }
`;

export const INVITE_MEMBERS = gql`
  mutation inviteCompanyMembers(
    $data: CompanyInviteMemberInput!
    $where: CompanyWhereUniqueInput!
  ) {
    inviteCompanyMembers(data: $data, where: $where) {
      id
      name
      subDomain
      logo
      completedSteps
      nextStep
      createdAt
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany(
    $name: String!
    $logo: Upload
    $firstName: String!
    $lastName: String!
    $timeZone: String!
    $profileImage: Upload
    $workSpace: String
  ) {
    createCompany(
      data: {
        name: $name
        logo: $logo
        firstName: $firstName
        lastName: $lastName
        profileImage: $profileImage
        timeZone: $timeZone
        workSpace: $workSpace
      }
    ) {
      id
      name
      subDomain
      logo
      featureKeys
      locations {
        name
      }
      createdBy {
        firstName
        email
        username
      }
      workSpace
      companySubscriptionId
      createdAt
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $name: String
    $firstName: String
    $lastName: String
    $logo: Upload
    $profileImage: Upload
    $timeZone: String!
    $workSpace: String
    $where: CompanyWhereUniqueInput!
  ) {
    updateCompany(
      data: {
        name: $name
        firstName: $firstName
        lastName: $lastName
        logo: $logo
        profileImage: $profileImage
        timeZone: $timeZone
        workSpace: $workSpace
      }
      where: $where
    ) {
      id
      name
      logo
      completedSteps
      nextStep
      createdAt
    }
  }
`;

export const COMPLETE_COMPANY_STEP = gql`
  mutation completeCompanyStep($where: CompanyWhereUniqueInput!) {
    completeCompanyStep(where: $where) {
      id
      subDomain
      completedSteps
      nextStep
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: ResetPasswordInput) {
    resetPassword(data: $data)
  }
`;

export const REQUEST_COMPANY = gql`
  mutation requestCompany($workspaceName: String!) {
    requestCompany(data: { workspaceName: $workspaceName }) {
      id
      firstName
      lastName
      email
      company {
        id
        workSpace
      }
    }
  }
`;
export const CANCEL_REQUEST = gql`
  mutation {
    cancelRequest {
      id
    }
  }
`;
