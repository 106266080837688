import { gql } from '@apollo/client';

export const quoteFragment = gql`
  fragment QuoteFragment on Quote {
    id
    title
    author
    isActive
    createdAt
    updatedAt
  }
`;

export const quoteSettingFragment = gql`
  fragment QuoteSettingFragment on QuoteSetting {
    id
    triggerTime
    company {
      id
    }
    enabledQuoteCategories {
      id
      name
    }
  }
`;
