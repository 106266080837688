import styled from 'styled-components';
import { Form as AntForm, Divider } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { y, x } from 'design/Styled';
import Spacings from 'design/Spacings';
import { Loginbg, CheckedLight } from 'assets';

export const Container = styled.div`
  ${x};
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${Colors.SidebarText};
  .error {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    color: ${Colors.TableTd};
    background: rgba(239, 71, 71, 0.1);
    border-radius: 6px;
  }
  .company {
    display: inline-flex;
    flex-direction: column;
    color: ${Colors.TextSecondary};
  }
  .timezone {
    font: ${fonts.xSmall.regular};
    line-height: 20px;
  }
  .name {
    font: ${fonts.medium.regular};
    line-height: 26px;
  }
  .radioStyle {
    display: block;
    padding-top: 13px;
    :last-child {
      padding-top: 42px;
    }
  }
  .ant-radio-inner {
    border: 2px solid #dfe4ea;
    height: 20px;
    width: 20px;
    ::after {
      position: absolute;
      top: -2px;
      left: -2px;
      background-image: url(${CheckedLight});
      background-size: 10px 8px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: ${Colors.PrimaryColor};
      height: 20px;
      width: 20px;
      border-radius: 100px;
    }
  }
  .notification {
    margin-top: calc(${Spacings.small.margin} + 4px);
    color: ${Colors.TextSecondary};
  }
  .success {
    display: flex;
    align-items: center;
    padding: 12px;
    color: ${Colors.TableTd};
    background: rgba(33, 183, 135, 0.1);
    border-radius: 6px;
  }
  .ant-btn-primary {
    font: ${fonts.small.regular};
    line-height: 22px;
    width: 100%;
  }
  .ant-card {
    width: 100%;
    max-width: 408px;
  }
  .right {
    float: right;
  }
  .terms {
    margin-right: ${Spacings.Small.margin};
    color: ${Colors.TextSecondary};
    :last-child {
      margin-right: 0px;
    }
    @media (max-width: 1000px) {
      color: ${Colors.SidebarText};
    }
  }
`;

export const Text = styled.div`
  color: ${Colors.TextDanger};
  font: ${fonts.small.regular};
  padding-left: 12px;
  &.lineheight {
    line-height: 24px;
  }
`;
export const CompanyLogo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: calc(${Spacings.medium.margin} + 4px);
`;
export const Image = styled.img``;
export const Name = styled.div`
  font: ${fonts.xxLarge.bold};
  color: ${Colors.TextPrimary};
  margin-left: calc(${Spacings.xxSmall.margin} + 4px);
`;
export const Success = styled.div`
  color: ${Colors.SuccessText};
  font: ${fonts.small.regular};
  padding-left: 12px;
  &.lineheight {
    line-height: 24px;
  }
`;
export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
`;
export const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${Loginbg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;

  .bottom-left {
    position: absolute;
    left: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
  .bottom-right {
    position: absolute;
    right: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
`;
export const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 180px;
  transform: translate(-50%, -50%);
  @media (max-width: 1120px) {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const Icon = styled.img`
  filter: invert(50%) sepia(63%) saturate(4089%) hue-rotate(333deg)
    brightness(96%) contrast(96%);
`;
export const CkeckIcon = styled.img`
  filter: invert(58%) sepia(65%) saturate(467%) hue-rotate(110deg)
    brightness(89%) contrast(94%);
`;
export const StyledDivider = styled(Divider)`
  padding: 8px 0 8px;
  .ant-divider-inner-text {
    font: ${fonts.xxSmall.bold};
    color: ${Colors.TextSecondary};
  }
`;
export const Existing = styled.div``;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  @media (max-width: 1120px) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;
export const EmailVerified = styled.div`
  color: ${Colors.ButtonPrimary};
  font: ${fonts.medium.regular};
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin-bottom: ${Spacings.xSmall.margin};
`;

export const Title = styled.div`
  font: ${fonts.xxLarge.bold};
  color: ${Colors.TextDark};
  margin-bottom: ${Spacings.small.margin};
`;

export const Description = styled.div`
  font: ${fonts.small.regular};
  color: ${Colors.Text};
  margin-bottom: ${Spacings.small.margin};
`;

export const Terms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 410px;
  padding-top: 10px;
  align-self: center;
  font: ${fonts.xSmall.bold};
`;
