import styled from 'styled-components';
import { Form as AntForm, Layout, Modal as AntModal } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  .button {
    text-align: end;
  }
  .ant-btn-primary {
    margin-top: ${Spacings.small.margin};
  }
  overflow: 'none';
`;

export const Form = styled(AntForm)`
  .ant-row.ant-form-item:last-of-type {
    margin-bottom: 0;
  }
  .ant-form-item {
    margin-bottom: 16px;
    &:nth-last-child(2) {
      margin-bottom: 24px;
    }
  }
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }

  .button {
    margin-top: ${Spacings.medium.margin};
    text-align: right;
  }

  label {
    font: ${fonts.xSmall.regular};
    color: ${Colors.TextDark};
  }

  .ant-checkbox-group {
    width: 100%;

    .ant-checkbox-wrapper {
      width: 50%;
      margin-right: 0;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .ant-btn-primary {
    font: ${fonts.xSmall.regular};
  }
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  cursor:pointer;
`;

export const Modal = styled(AntModal)`
  .ant-modal-footer {
    border-top: none;
    padding:0px 16px 16px;
  }
  .ant-radio-wrapper {
    color: ${Colors.Radio};
  }
  .ant-radio-wrapper-checked {
    color: ${Colors.PrimaryColor};
  }
  .card {
    margin-bottom: ${Spacings.small.margin};
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .ant-card-head {
    border-bottom: 1px solid ${Colors.Border};
  }
  .ant-form-item textarea.ant-input {
    border-radius: 8px;
  }
  .ant-modal-content {
    border-radius: 12px;
    overflow: auto;
    max-height: calc(100vh - 120px);
  }
  .ant-modal-header {
    border-radius: 12px 12px 0 0;
    padding: 16px;
  }
  .ant-modal-body {
    padding: 16px;
  }
  .title {
    color: ${Colors.TextDark};
    font: ${fonts.small.bold};
  }
`;
