import styled from 'styled-components';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { scrollYContainer } from 'design/Styled';

export const PopoverContent = styled.div`
  font-size:${fonts.small.medium};
  margin:-12px -16px;

  .ant-input-suffix {
    display: flex;
    align-items: center
  }

  input.ant-input::placeholder {
    color:${Colors.TextMedium}
  }

  .popover-input:focus, .ant-input-affix-wrapper-focused {
    border:none;
    box-shadow:none;
  }

  input, .popover-input {
    background:${Colors.PopoverInput};
    border:none;
  }

  .popover-input{
    min-height: 45px;
  }

  .ant-input-prefix{
    align-self: center;
  }
`;

export const Member = styled.div`
  overflow: auto;
  ${scrollYContainer};
  max-height: 178px;
  font: ${fonts.small.regular};
  color: ${Colors.Text};
  padding:10px 0;
  position: relative;

  .row-member{
    height:41px;
    display: flex;
    align-items: center;
    padding:0 15px;
  }

  .row-member:hover{
    background-color:#eee;
  }

  div {
    cursor: pointer;
  }

  div:last-child {
    margin-bottom: 0;
  }

  img{
    border-radius:50%;
  }

  .delete-able img{
    border:2px solid ${Colors.BlueBorder};
  }

  .anticon.anticon-close-circle{
    display:none ;
    position: absolute;
    left: 30px;
    margin-top: -12px;
  }

  .delete-able:hover .anticon.anticon-close-circle{
    display: inline-block;
  }
`;
