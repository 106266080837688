/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useRef, useState } from 'react';
import _map from 'lodash/map';
import { Carousel, message } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { Router, Switch } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { UndifferLogo } from 'assets';
import Meta from 'atoms/Meta';
import {
  ONBOARDING,
  META_TYPE,
  PRIVATE_ROUTE,
  EMPTY_OBJECT,
} from 'utils/constants';
import { AppContext } from 'AppContext';
import Loader from 'atoms/Loader';
import Organization from './Screens/Organization';
import InviteTeamMembers from './Screens/InviteTeamMembers';
import GetStarted from './Screens/GetStarted';
import { subContexts } from './MockData';
import {
  Content,
  LeftSection,
  RightSection,
  SubContent,
  TitleWrapper,
  Text,
  Wrapper,
} from './AntStyled';
import {
  Container,
  ProfilePage,
  Title,
  Background,
  StyledInput,
  StyledForm,
  Section,
  Logo,
  Terms,
  Heading,
  BasicCard,
  CardInfo,
  UploadIcon,
  AvatarIcon,
} from './Screens/Organization/AntStyled';
export default function (props) {
  const [userCreatedCompanyData, setUserCreatedCompanyData] = useState(
    EMPTY_OBJECT
  );
  const [titleId, setTitleId] = useState('onboarding.trial');
  const { history } = props;
  const {
    state: { loginUser },
  } = useContext(AppContext);
  const { company, role } = loginUser;
  const slideNumber = {
    COMPANY: 0,
    FEATURES: 0,
    INVITED: 0,
    COMPLETED: 0,
    DASHBOARD: 0,
  };
  const [loading, setLoading] = useState(true);
  const slider = useRef();
  useEffect(() => {
    if (company && company.nextStep) {
      slider.current &&
        slider.current.slick.slickGoTo(slideNumber[company.nextStep]);
      setLoading(false);
    } else if (!isEmpty(loginUser) && isEmpty(company)) {
      setLoading(false);
    }
  }, [company, slideNumber, loginUser]);

  useEffect(() => {
    if (
      company &&
      !isEmpty(company) &&
      (company.nextStep === ONBOARDING.FEATURE ||
        company.nextStep === ONBOARDING.DASHBOARD)
    ) {
      history.push(PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.MY_PROFILE);
    }
  }, [company, history, role]);

  const onChange = (slide) => {
    switch (slide) {
      case 0:
        setTitleId('onboarding.trial');
        break;

      default:
        break;
    }
  };
  return (
    <Content>
      <Meta title={META_TYPE.ONBOARDING} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container>
            <Background>
              <Logo src={UndifferLogo} alt="logo"></Logo>
              <a className="bottom-right">Need Help?</a>
              <a className="bottom-left">© 2020 undiffer</a>
            </Background>
            <Wrapper>
              <Router history={history}>
                <Switch>
                  <Wrapper>
                    <Organization
                      slider={slider}
                      userCreatedCompanyData={userCreatedCompanyData}
                      setUserCreatedCompanyData={setUserCreatedCompanyData}
                    />
                  </Wrapper>
                  {/* kept for future */}
                  {/* <Wrapper>
                        <SelectFeatures slider={slider} />
                      </Wrapper> */}
                  {/* <Wrapper>
                        <InviteTeamMembers slider={slider} userCreatedCompanyData={userCreatedCompanyData} />
                      </Wrapper> */}
                  {/* <Wrapper>
                        <GetStarted slider={slider} userCreatedCompanyData={userCreatedCompanyData} />
                      </Wrapper> */}
                </Switch>
              </Router>
            </Wrapper>
          </Container>
        </>
      )}
    </Content>
  );
}
