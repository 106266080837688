/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Form, message, Row } from 'antd';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import client from 'apollo';
import Meta from 'atoms/Meta';
import {
  EmailVerified,
  UndifferLogo,
  ConfirmAvatar,
  Failed,
  BackArrow,
} from 'assets';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import {
  FORM_NAME,
  META_TYPE,
  PUBLIC_ROUTE,
  BUTTON_TYPE,
} from 'utils/constants';
import { VERIFY_EMAIL } from 'graphql/Onboarding/Mutations';
import * as Sentry from '@sentry/react';
import Loader from 'atoms/Loader';
import InfoIcon from 'atoms/InfoIcon';
import Header from 'layout/Header';
import jwtDecode from 'jwt-decode';
import ReactGA from 'react-ga';
import {
  Container,
  Description,
  EmailPop,
  Wrapper,
  Background,
  ImageWrapper,
  Title,
  Logo,
  Resend,
  ConfirmLink,
  FullDivider,
  Verified,
  Terms,
  ContentTitle,
  Arrow,
} from './AntStyled';
import TermsAndPolicy from '../TermsAndPolicy';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const {
    match: {
      params: { token },
    },
    location: { state },
    history,
  } = props;

  const [isError, setIsError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [isInvited, setIsInvited] = useState();

  useEffect(() => {
    if (token) {
      // eslint-disable-next-line no-inner-declarations
      async function fetchData() {
        setSpinner(true);
        try {
          await client
            .mutate({
              mutation: VERIFY_EMAIL,
              variables: {
                token,
              },
            })
            .then((data) => {
              if (data?.data?.verifyEmail?.isVerified) {
                localStorage.setItem('token', data?.data?.verifyEmail?.token);
                ReactGA.event({
                  category: 'New signup',
                  action: 'New email signup',
                  label: 'Signup with email',
                  value: jwtDecode(data?.data?.verifyEmail?.token)?.email,
                });
              } else if (!data?.data?.verifyEmail?.isVerified) {
                message.error('Token is expired');
              }
              setIsError(!data?.data?.verifyEmail?.isVerified);
              setIsInvited(data?.data?.verifyEmail?.isInvited);
              setSpinner(false);
            });
        } catch (error) {
          console.log(error);
          setIsError(true);
          setSpinner(false);
        }
      }
      fetchData();
    }
  }, [history, token]);

  const handleContinue = () => {
    window.location = PUBLIC_ROUTE.SET_PASSWORD;
  };

  const resendEmail = () => {
    history.push('/signup');
  };

  return (
    <Container>
      <Background>
        <Logo src={UndifferLogo} alt="logo"></Logo>
        <NavLink to="/help" className="bottom-right">
          <FormattedMessage id="confirmation.needHelp" />
        </NavLink>
        <NavLink to="/copyright" className="bottom-left">
          <FormattedMessage id="confirmation.copyright" />
        </NavLink>
      </Background>
      <Wrapper>
        <Card padding="32" className="auth">
          <Meta title={META_TYPE.CONFIRMATION} />
          {state?.email && (
            <ContentTitle onClick={() => history.goBack()}>
              <Arrow src={BackArrow} alt="arrow" />
              <InfoIcon title="Back" className="title" />
            </ContentTitle>
          )}

          <Form name={FORM_NAME.BASIC}>
            {token ? (
              spinner ? (
                <Verified>
                  <ImageWrapper>
                    <Loader />
                  </ImageWrapper>
                  <Title>
                    <FormattedMessage id="confirmation.verifying" />
                  </Title>
                  <Description>
                    <FormattedMessage id="confirmation.wait" />
                  </Description>
                </Verified>
              ) : !isError ? (
                <Verified>
                  <ImageWrapper>
                    <EmailPop src={EmailVerified} />
                  </ImageWrapper>
                  <Title>
                    <FormattedMessage id="confirmation.congratulations" />
                  </Title>
                  <Description>
                    <FormattedMessage id="confirmation.verifiedSuccessfully" />
                  </Description>
                  <Button
                    className="center"
                    primary
                    type={BUTTON_TYPE.PRIMARY}
                    htmlType={BUTTON_TYPE.SUBMIT}
                    name="Continue To Login"
                    loading={loading}
                    onClick={handleContinue}
                  />
                </Verified>
              ) : (
                <Verified>
                  <ImageWrapper>
                    <EmailPop src={Failed} />
                  </ImageWrapper>
                  <Title>
                    {' '}
                    <FormattedMessage id="confirmation.verificationFailed" />{' '}
                  </Title>
                  <Description>
                    {isInvited ? (
                      <FormattedMessage id="confirmation.requestToManager" />
                    ) : (
                      <FormattedMessage id="confirmation.newRequest" />
                    )}
                  </Description>
                  {!isInvited && (
                    <Button
                      className="center"
                      primary
                      type={BUTTON_TYPE.PRIMARY}
                      htmlType={BUTTON_TYPE.SUBMIT}
                      name="Try again"
                      loading={loading}
                      onClick={resendEmail}
                    />
                  )}
                </Verified>
              )
            ) : (
              <>
                <ImageWrapper>
                  <EmailPop src={ConfirmAvatar} />
                </ImageWrapper>
                <Title>
                  <FormattedMessage id="confirmation.title" />
                </Title>
                <Description>
                  {state?.route === PUBLIC_ROUTE.KEY.FORGOT_PASSWORD ? (
                    <FormattedMessage id="confirmation.sentEmail" />
                  ) : (
                    <FormattedMessage id="confirmation.description" />
                  )}
                  <span style={{ color: '#5F3996', fontWeight: '600' }}>
                    {' '}
                    {state ? state?.email : 'your email'}{' '}
                  </span>
                </Description>
                <ConfirmLink>
                  {state?.route === PUBLIC_ROUTE.KEY.FORGOT_PASSWORD ? (
                    <FormattedMessage id="confirmation.forgotConfirmationLink" />
                  ) : (
                    <FormattedMessage id="confirmation.confirmationLink" />
                  )}
                </ConfirmLink>
                {/* for future use */}
                {/* <Row justify="center">
                  <Button
                    accent
                    onClick={resendEmail}
                    type={BUTTON_TYPE.PRIMARY}
                    name={<FormattedMessage id="onboarding.changeEmail" />}
                  />
                </Row> */}
              </>
            )}
          </Form>
        </Card>
        <TermsAndPolicy />
      </Wrapper>
    </Container>
  );
}
