import React, { useState, useEffect } from 'react';
import _map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import { Col, Form, Row, Radio } from 'antd';
import Input from 'atoms/Input';
import { PlusOutlined } from '@ant-design/icons';
import { EMPTY_ARRAY, QUESTION_TYPE, EMPTY_OBJECT } from 'utils/constants';
import Button from 'atoms/Button';
import {
  ArrowIcon,
  DeleteIcon,
  MinusCircle,
  PlusAddOutline,
  PlusPrimary,
  Selected,
} from 'assets';
import { Delete, GlobalStyles, StyledImg, Select, Arrow } from './AntStyled';
import { surveyType } from '../MockData';

const { Option } = Select;
export default function EmptyQuestion({ name, size, form }) {
  const [selectedType, setSelectedType] = useState(QUESTION_TYPE.TEXT);

  useEffect(() => {
    setSelectedType(form.getFieldValue(['questionList', name, 'questionType']));
    const questionList = form.getFieldValue('questionList');
    if (questionList[name] === undefined) {
      questionList[name] = {};
    } else if (questionList[name].choices) {
      return;
    }
    questionList[name].choices = [null, null];
    form.setFieldsValue({ questionList });
  }, [form, name]);

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const surveyTypeChildren = _map(surveyType, (list, index) => (
    <Option key={index} value={list?.value}>
      <GlobalStyles />
      <Row justify="space-between" className="row max-width">
        <Col>
          <div className={`div-1 ${selectedType === list?.value && 'active'} `}>
            {list?.role}
          </div>
          <div className="div-2">{list?.desc}</div>
        </Col>
        <Col>
          {selectedType === list?.value && <StyledImg src={Selected} />}
        </Col>
      </Row>
    </Option>
  ));

  return (
    <div className="question">
      <Form.Item
        name={[name, 'questionType']}
        label={<FormattedMessage id="feedback.emptyQuestion.questionType" />}
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select
          showSearch
          showArrow
          placeholder="Select Question Type"
          onChange={(v) => handleTypeChange(v)}
          suffixIcon={<Arrow src={ArrowIcon} />}
          getPopupContainer={() => document.getElementById('drop')}
        >
          {surveyTypeChildren}
        </Select>
      </Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item
            name={[name, 'question']}
            label={
              <FormattedMessage id="feedback.emptyQuestion.questionLabel" />
            }
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input size={size} />
          </Form.Item>
        </Col>
      </Row>
      {
        // eslint-disable-next-line no-nested-ternary
        selectedType === QUESTION_TYPE.MCQ ? (
          <div>
            <div className="label-multiple">
              <FormattedMessage id="feedback.emptyQuestion.mcqLabel" />
            </div>
            <Form.List name={[name, 'choices']}>
              {(fields, { add, remove }) => (
                <div>
                  {_map(fields, (field, index) => (
                    <Row key={index} className="addChoice">
                      <Col span={14}>
                        <Form.Item
                          className="input-mcq"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input size={size} />
                        </Form.Item>
                      </Col>
                      <Col className="col-icon" span={1}>
                        {fields?.length > 2 && (
                          <Delete
                            src={MinusCircle}
                            onClick={() => remove(field?.name)}
                          />
                        )}
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      add
                      onClick={() => {
                        add();
                      }}
                      name={
                        <>
                          <img className="plus" src={PlusPrimary} alt="icon" />
                          <FormattedMessage id="feedback.emptyQuestion.AddChoice" />
                        </>
                      }
                    />
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </div>
        ) : selectedType === QUESTION_TYPE.OPINION_SCALE ? (
          <div>
            <div className="label">
              <FormattedMessage id="feedback.emptyQuestion.scaleLabel" />
            </div>
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item
                  label="“0” label"
                  name={[name, 'scaleLabel', 'minLabel']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input size={size} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label="“10” label"
                  name={[name, 'scaleLabel', 'maxLabel']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input size={size} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          selectedType === QUESTION_TYPE.TEXT && null
        )
      }
    </div>
  );
}
