import React from 'react';
import _map from 'lodash/map';
import moment from 'moment';
import { Bullet } from '@ant-design/charts';
import Hours from 'atoms/Hours';
import Colors from 'design/Colors';
import styled from 'styled-components';
import { fonts } from 'design/Fonts';

const Wrapper = styled.div`
  height:29px;
  min-width:250px;
  width:100%;
  max-width:400px;
  position:relative;
`;
const Background = styled.div`
  padding:4px;
  background:${Colors.GraphGreen};
  opacity:0.3;
  height:100%;
  width:100%;
  position:absolute;
  border-radius:14px;
`;
const LoggedHoursWrapper = styled.div`
 width:${({ seconds }) => `calc( ${(seconds * 100) / 36000}% - 4px)`};
 max-width:calc(100% - 8px);
 position:relative;
`;
const LoggedHours = styled.div`
  height:21px;
  /* width:${({ seconds }) => (seconds / 36000) * 400}px; */
  border-radius:14px;
  background:${Colors.WorkDone};
  color:#fff;
  font:${fonts.xxSmall.bold};
  display: flex;
  justify-content: center;
  align-items: center;
  position:absolute;
  top:4px;
  left:4px;
  -webkit-animation: animateThis 1s cubic-bezier(0.58, 0.07, 0.5, 1.16);
  -webkit-animation-fill-mode: forwards;
  @keyframes animateThis {
    0% {
      width: 0px;
    }
    100% {
      /* width:${({ seconds }) => (seconds / 36000) * 400}px; */
      width:100%;
    }
  }
`;
const Target = styled.div`
  height:100%;
  border-left:1px solid ${Colors.Text};
  position:absolute;
  left:80%;
`;


export const bulletChart = (punches) => {
  let totalTime = 0;
  _map(punches, (punch) => {
    if (!(punch.result.includes('BREAK') || punch.isBreak)) {
      if (punch.punchOut) {
        totalTime += moment(punch.punchOut).diff(punch.punchIn, 'seconds') || 0;
      } else {
        totalTime += moment().diff(punch.punchIn, 'seconds') || 0;
      }
    }
  });
  const data = [
    {
      title: '',
      ranges: [600],
      measures: [totalTime / 60],
      target: 480,
    },
  ];
  const config = {
    height: 30,
    data,
    measureField: 'measures',
    rangeField: 'ranges',
    targetField: 'target',
    xField: 'title',
    size: {
      target: 30,
      measure: 20,
      range: 30,
    },
    color: {
      range: ['#D9F7BE'],
      measure: [Colors.GraphGreen],
      target: Colors.Text,
    },
    label: {
      measure: {
        content: val => `${parseInt(val.measures / 60, 10) ? `${parseInt(val.measures / 60, 10)}${parseInt(val.measures / 60, 10) === 1 ? 'hr' : 'hrs'}` : ''}${val.measures % 60 ? ` ${parseInt(val.measures % 60, 10)}m` : ''}`,
        position: 'middle',
        style: { fill: Colors.Text },
      },
    },
    xAxis: { line: null },
    yAxis: false,
    tooltip: false,

  };
  const hours = `${parseInt(totalTime / 3600, 10)}`;
  const mins = `${parseInt((totalTime % 3600) / 60, 10)}`;
  return (
    <Wrapper>
      <Background />
      <LoggedHoursWrapper seconds={totalTime}>
        <LoggedHours>
          {hours > 0 && <>{hours.length === 1 ? 0 : ''}<Hours estTime={hours} /></>}<> </>
          {mins > 0 && <>{mins.length === 1 ? `0${mins}` : mins}m</>}
        </LoggedHours>
      </LoggedHoursWrapper>
      <Target />
    </Wrapper>
  );
};
