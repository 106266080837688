export const LeaveType = [
  { type: 'Sick Leave', value: 'SICK' },
  { type: 'Casual Leave', value: 'CASUAL' },
  { type: 'Other Leave', value: 'OTHER' },
];
export const LeaveRange = [
  { range: 'Full Day', value: 'FULLDAY' },
  { range: 'First Half', value: 'FIRSTHALF' },
  { range: 'Second Half', value: 'SECONDHALF' },
];
