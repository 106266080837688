/* eslint-disable no-bitwise */
import React from 'react';
import { Select as AntSelect, Form } from 'antd';
import _map from 'lodash/map';
import moment from 'moment';
import { ArrowIcon } from 'assets';
import styled from 'styled-components';
import { fonts } from 'design/Fonts';
import Colors from 'design/Colors';
import { times } from 'lodash';
import { TimezoneData } from './TimezoneData';
const Select = styled(AntSelect)`
  font: ${fonts.xSmall.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 12px;
    border: none;
  }
  .ant-select-dropdown {
    z-index: 999999 !important;
  }
`;
const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 2px;
  margin-top: -4px;
`;
const { Option } = Select;

const TimezoneSelector = ({
  formItemClassName,
  className,
  getPopupContainer,
  required = false,
  label,
}) => {
  const children = _map(TimezoneData, (val, index) => {
    const hr = `${(val?.min / 60) ^ 0}:${
      val?.min % 60 === 0 ? '00' : val?.min % 60
    }`;
    return (
      <Option key={index} value={val?.zone}>
        {`(GMT${hr.includes('-') ? hr : `+${hr}`}) ${val?.zone}`}
      </Option>
    );
  });
  return (
    <Form.Item
      className={formItemClassName}
      label={label || 'Timezone'}
      name="timeZone"
      rules={[{ required, message: 'Please enter timezone' }]}
    >
      <Select
        placeholder="Timezone"
        filterOption
        className={className}
        optionFilterProp="children"
        suffixIcon={<Arrow src={ArrowIcon} />}
        getPopupContainer={getPopupContainer}
        showSearch
        showArrow
      >
        {children}
      </Select>
    </Form.Item>
  );
};

export default TimezoneSelector;
