export const LeaveType = [
  { type: 'Sick Leave' },
  { type: 'Casual Leave' },
];
export const LeaveRange = [
  { range: 'Full Day' },
  { range: 'First Half' },
  { range: 'Second Half' },
];
export const LeaveStatus = [
  { type: 'Approve', value: 'APPROVED' },
  { type: 'Reject', value: 'REJECTED' },
];
