import { gql } from '@apollo/client';
import {
  companyFragment,
  holidayFragment,
  leaveSettingFragment,
  punchSettingFragment,
} from './Fragments';

export const UPDATE_FEATURE_AVAILABILITY = gql`
  mutation updateFeatureAvailability(
    $data: FeatureStatusChangeInput!
    $where: CompanyWhereUniqueInput!
  ) {
    updateFeatureAvailability(data: $data, where: $where) {
      ...CompanyFragment
    }
  }
  ${companyFragment}
`;

export const CREATE_LEAVE_SETTINGS = gql`
  mutation createLeaveSetting($data: LeaveSettingCreateInput!) {
    createLeaveSetting(data: $data) {
      ...LeaveSettingFragment
    }
  }
  ${leaveSettingFragment}
`;

export const UPDATE_LEAVE_SETTINGS = gql`
  mutation updateLeaveSetting(
    $data: LeaveSettingUpdateInput!
    $where: LeaveSettingWhereUniqueInput!
  ) {
    updateLeaveSetting(data: $data, where: $where) {
      ...LeaveSettingFragment
    }
  }
  ${leaveSettingFragment}
`;

export const DELETE_LEAVE_SETTINGS = gql`
  mutation deleteLeaveSetting($where: LeaveSettingWhereUniqueInput!) {
    deleteLeaveSetting(where: $where) {
      ...LeaveSettingFragment
    }
  }
  ${leaveSettingFragment}
`;

export const UPDATE_PUNCH_SETTING = gql`
  mutation updatePunchSetting(
    $data: PunchSettingUpdateInput!
    $where: PunchSettingWhereUniqueInput!
  ) {
    updatePunchSetting(data: $data, where: $where) {
      ...PunchSettingFragment
    }
  }
  ${punchSettingFragment}
`;

export const DELETE_PUNCH_SETTING = gql`
  mutation deleteBreak($where: BreakWhereUniqueInput!) {
    deleteBreak(where: $where) {
      id
    }
  }
`;

export const CREATE_HOLIDAY = gql`
  mutation createHolidays($datas: [HolidayCreateInput]!) {
    createHolidays(datas: $datas) {
      ...HolidayFragment
    }
  }
  ${holidayFragment}
`;

export const UPDATE_HOLIDAY = gql`
  mutation updateHoliday(
    $data: HolidayUpdateInput
    $where: HolidayWhereUniqueInput!
  ) {
    updateHoliday(data: $data, where: $where) {
      ...HolidayFragment
    }
  }
  ${holidayFragment}
`;

export const DELETE_HOLIDAY = gql`
  mutation deleteHoliday($where: HolidayWhereUniqueInput!) {
    deleteHoliday(where: $where) {
      ...HolidayFragment
    }
  }
  ${holidayFragment}
`;

export const DELETE_DEPARTMENT = gql`
  mutation deleteDepartment($id: ID!) {
    deleteDepartment(id: $id) {
      success
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation updateDepartment($where: UpdateDepartmentInput) {
    updateDepartment(where: $where) {
      success
    }
  }
`;

export const CREATE_DEPARTMENT = gql`
  mutation createDepartment($data: CreateDepartmentInput) {
    createDepartment(data: $data) {
      success
    }
  }
`;
