import styled from 'styled-components';
import Spacings from 'design/Spacings';
import { fonts } from 'design/Fonts';

export const Content = styled.div`
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 10px;
  }
  padding: 0px 16px 16px;
`;
