import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { useQuery } from '@apollo/client';
import _map from 'lodash/map';
import _get from 'lodash/get';
import moment from 'moment';

import Header from 'layout/Header';
import Meta from 'atoms/Meta';
import Filters from 'molecules/Filters';
import Table from 'atoms/Table';
import InfoIcon from 'atoms/InfoIcon';
import {
  META_TYPE,
  FETCH_POLICY,
  EMPTY_ARRAY,
  UNDEFINED,
  PAGE_LIMIT,
  PRIVATE_ROUTE,
} from 'utils/constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { GET_ATTENDANCE } from 'features/people/graphql/People/Queries';
import * as Sentry from '@sentry/react';
import _sortBy from 'lodash/sortBy';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'atoms/Loader';
import client from 'apollo';
import _concat from 'lodash/concat';
import ContentWrapper from 'atoms/ContentWrapper';
import { InfiniteScrollTableWrapper } from 'design/Styled';
import { LoaderDiv } from 'features/dailyGoals/templates/DailyGoals/AntStyled';
import { Content } from './AntStyled';
import { weekColumns, monthColumns, dayColumns } from './ColumnsData';

const People = ({ intl }) => {
  const [attendance, setAttendance] = useState([]);
  const [tempData, setTemp] = useState([]);
  const [groupId, setGroupId] = useState(EMPTY_ARRAY);
  const [locationId, setLocationId] = useState();
  const [date, setDate] = useState([moment(), moment()]);
  const [type, setType] = useState('day');
  const [userId, setUserId] = useState();
  const [columns, setColumns] = useState(dayColumns);
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [dataCount, setDataCount] = useState();
  const [tableLoader, setTableLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [sortData, setSortData] = useState({ field: 'firstName', type: 'ASC' });

  const filters = {
    day: 'TODAY',
    week: 'CURRENT_WEEK',
    month: 'CURRENT_MONTH',
  };

  const { data: attendanceData, loading: attendanceLoading } = useQuery(
    GET_ATTENDANCE,
    {
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      variables: {
        where: {
          filter: filters[type],
          locationId,
          groupIds: groupId.length !== 0 ? groupId : UNDEFINED,
          from:
            date && date.length > 0
              ? date[0].format('YYYY-MM-DD')
              : moment().startOf('today').format('YYYY-MM-DD'),
          to:
            date && date.length > 0
              ? date[1].format('YYYY-MM-DD')
              : moment().endOf('today').format('YYYY-MM-DD'),
          userId: userId && userId.length > 0 ? userId : UNDEFINED,
        },
        skip,
        limit: PAGE_LIMIT,
        sortBy: sortData.type,
        sortOn: sortData.field,
      },
    }
  );

  useEffect(() => {
    if (!attendanceLoading && attendanceData) {
      let attendanceTableData = [];
      if (type === 'day') {
        attendanceTableData = _map(
          _sortBy(attendanceData?.attendances?.attendances, 'user.firstName'),
          (data, key) => ({
            key: key.toString(),
            id: data.id,
            day: data.dateTime,
            user: data.user,
            inTime: _get(data, 'punches[0].punchIn') || null,
            outTime:
              _get(data, `punches[${data?.punches?.length - 1}].punchOut`) ||
              null,
            status: data.status,
            worked: data.work,
            punches: data.punches,
          })
        );
        setColumns(dayColumns);
      } else if (type === 'week') {
        attendanceTableData = _map(
          _sortBy(attendanceData?.attendances?.attendances, 'user.firstName'),
          (data, key) => ({
            key: key.toString(),
            user: data.user,
            worked: data.work,
            attendance: data.fullAttendance || [],
          })
        );
        setColumns(weekColumns);
      } else {
        attendanceTableData = _map(
          _sortBy(attendanceData?.attendances?.attendances, 'user.firstName'),
          (data, key) => ({
            key: key.toString(),
            user: data.user,
            worked: data.work,
            attendance: data.fullAttendance || [],
          })
        );
        setColumns(monthColumns);
      }
      if (attendanceData?.attendances?.attendances?.length === 0) {
        setHasMore(false);
        return;
      }
      const myData = [...attendance, ...attendanceTableData];
      setAttendance([...myData]);
      setDataCount(attendanceData?.attendances?.count);
      setLoader(false);
      setTableLoader(false);
      if (attendanceData.attendances.count <= PAGE_LIMIT) {
        setHasMore(false);
        setPage(1);
      }
    }
  }, [attendanceData, attendanceLoading]);

  const handleInfiniteOnLoad = () => {
    setLoader(true);
    if (dataCount <= attendance.length) {
      setHasMore(false);
      setLoader(false);
      setPage(1);
    } else {
      setPage((prev) => prev + 1);
      const count = page * PAGE_LIMIT;
      setSkip(count);
    }
  };

  const resetPeopleData = () => {
    setAttendance([]);
    setHasMore(true);
    setPage(1);
    setSkip(0);
  };

  const onClickData = (e) => {
    e && e.preventDefault();
    resetPeopleData();
  };

  const handleGroupOrUserFilterChange = () => {
    resetPeopleData();
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter && sorter.field && sorter.order) {
      if (dataCount > attendance.length) {
        if (sorter.order === 'ascend') {
          setSortData({ field: 'firstName', type: 'ASC' });
        } else {
          setSortData({ field: 'firstName', type: 'DESC' });
        }
        resetPeopleData();
      }
    }
  };

  return (
    <Sentry.ErrorBoundary fallback="Something went wrong">
      <Layout>
        <Meta title={META_TYPE.TIME} />
        <Header
          title={<InfoIcon title={<FormattedMessage id="time.attendance" />} />}
        />
        <Filters
          onClickData={onClickData}
          masterFilter
          locationFilter
          switchableDateFilter
          showDay
          typeValue={type}
          setType={setType}
          dateFilterValue={date}
          setDateFilterValue={setDate}
          groupsFilters={setGroupId}
          locationFilters={setLocationId}
          setUsersValue={setUserId}
          userValue={userId}
          showUsersFilter
          handleGroupOrUserFilterChange={handleGroupOrUserFilterChange}
        />
        <ContentWrapper>
          <Content>
            <InfiniteScrollTableWrapper>
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleInfiniteOnLoad}
                hasMore={!loader && hasMore}
                useWindow={false}
                threshold={20}
                loader={
                  attendance.length > 0 && (
                    <LoaderDiv>
                      <Loader size="medium" />
                    </LoaderDiv>
                  )
                }
              >
                <Table
                  columns={columns}
                  dataSource={attendance}
                  height="calc(100vh - 96px - 60px)"
                  loading={attendance.length ? tableLoader : attendanceLoading}
                  pagination={false}
                  marginTop="0px"
                  isInfinite
                  onChange={onChange}
                  className="bb-0"
                />
              </InfiniteScroll>
            </InfiniteScrollTableWrapper>
          </Content>
        </ContentWrapper>
      </Layout>
    </Sentry.ErrorBoundary>
  );
};

export default {
  key: PRIVATE_ROUTE.PEOPLE,
  component: injectIntl(People),
  name: 'Attendance',
  icon: (
    <i
      data-eva="bar-chart-outline"
      data-eva-height="20"
      data-eva-width="20"
    ></i>
  ),
  abilitySubject: 'attendance',
  abilityAction: 'manage',
};
