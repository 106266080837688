import styled, { css } from 'styled-components';
import { fonts } from 'design/Fonts';
import { COLORS } from 'utils/constants';
import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
import {
  Select as AntSelect,
  Modal as AntModal,
  Form as AntForm,
  DatePicker as AntPicker,
  Input,
} from 'antd';
import { scrollbars } from 'design/Styled';
const { TextArea } = Input;
export const Calendar = styled.img`

`;
export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
  .dropdown {
    border-radius: 0px 0px 10px 10px;
  }
  .ant-form-item {
    margin-bottom: 20px;
    &:nth-child(2) {
        margin-bottom: 0px;
    }
  }

`;
export const DatePicker = styled(AntPicker)`
  font: ${fonts.xSmall.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px 8px 0px 0px;
  border-bottom:'none';
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-picker-input > input{
    font: ${fonts.medium.regular};
    color: ${Colors.TextSecondary};
  }
  .ant-picker-clear {
    position: absolute;
    top: 50%;
    right: 7%;
  }
  padding: 4px 14px 4px;
`;
export const Modal = styled(AntModal)`
  .ant-modal-body {
    padding: 20px 20px 0px;
  }
  .ant-modal-header {
    padding: 20px;
    border-bottom: 1px solid ${Colors.Border};
  }
  .ant-modal-footer {
    padding: 0px 20px 20px;
    border-top: 0px;
  }

  .ant-modal-title {
    font: ${fonts.xLarge.bold};
    line-height: 24px;
    color: ${Colors.TextDark};
  }

  .ant-modal-content {
    max-height: calc(100vh - 120px);
    overflow: auto;
    ${scrollbars};
    border-radius: 12px;
    border: 1px solid ${Colors.Border};
  }
  .no-gap {
    &.ant-form-item {
    margin-bottom: 0px;
    }
  }
  .gap{
    margin-top: 20px;
  }

  .btn-request {
    float: right;
  }
`;
export const TextBox = styled(TextArea)`
  border: 1px solid ${Colors.Border};
  border-radius: 10px;
`;
export const Select = styled(AntSelect)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 12px;
    border: none;
    height: 30px;
    background-color: transparent;
  }
  .ant-select-selection-placeholder {
    opacity: 1;
  }
  .ant-select-item {
    padding: 0px;
    line-height: 13px !important;

  }
  .ant-select-item-option {
    border-bottom: 1px solid red !important;
  }
`;
export const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 2px;
  margin-top: -4px;
`;
