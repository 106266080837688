import styled from 'styled-components';
import { Layout, Divider as AntDivider, Typography } from 'antd';
import Colors from 'design/Colors';
import { fonts, defaultSize as defaultFontSize } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  .col-1 {
    width: 292px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 16px;
  }
  .col-2 {
    padding: 16px 16px;
  }
  .card-2 {
    padding: 16px;
  }
  .progress {
    margin-right: 0px;
  }
  .poll-content {
    font-size: ${defaultFontSize};
  }
  .poll-head {
    font: ${fonts.xxLarge.bold};
    color: ${Colors.ButtonPrimary};
  }
  .poll-percent {
    font-size: 16px;
    font-weight: 700;
  }
  .poll-description {
    font: ${fonts.xSmall.bold};
    color: ${Colors.TextSecondary};
    margin-top: 5px;
  }
  .poll-content-xs {
    font-size: ${defaultFontSize};
  }
  .poll-head-xs {
    font: ${fonts.xxSmall.bold};
    color: ${Colors.ButtonPrimary};
  }
  .poll-percent-xs {
    font: ${fonts.xxSmall.bold};
    font-weight: 700;
  }
  .departments-tag-row {
    margin-top: ${Spacings.xSmall.margin};
  }
  .timeline {
    align-items: center;
    color: ${Colors.TextSecondary};
    :last-child {
      margin-top: calc(${Spacings.xSmall.margin} - 4px);
    }
  }

  .profile-col {
    margin-top: calc(${Spacings.xSmall.margin} - 4px);
  }

  .ant-divider.ant-divider-vertical {
    background: rgba(0, 0, 0, 0.2);
  }
  .line {
    margin: 0 12px;
  }
  .col-statistics-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: ${Spacings.xSmall.margin};
    min-width: 180px;
  }
  .col-statistics {
    display: flex;
    margin-top: ${Spacings.xSmall.margin};
  }
  .ant-divider,
  .ant-divider-vertical {
    margin-right: 0px;
    margin-left: 0px;
  }
  .noFlex {
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 12px;
    min-width: 180px;
    max-width: 180px;
    .description {
      width: 55%;
      text-align: left;
      text-overflow: ellipsis;
    }
  }
  .stars {
    flex-direction: row;
    padding: 8px 8px;
  }
  .ant-divider.ant-divider-vertical {
    background: ${Colors.Border};
  }
  .ant-divider.ant-divider-horizontal {
    background: ${Colors.Border};
  }
  .normal {
    font-size: 12px;
    margin-right: ${Spacings.small.margin};
    line-height: 20px;
    color: ${Colors.Text};
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: #5f3996;
  }

  .result-progress {
    display: flex;
    align-items: center;
    .ant-progress-inner {
      width: 200px;
      display: flex;
      align-items: center;
      border-radius: 4px;
    }
    .ant-progress-show-info .ant-progress-outer {
      padding-right: ${Spacings.xxSmall.margin};
      width: unset;
    }
    .ant-progress-text {
      margin-left: 32px;
      width: unset;
      font-size: 12px;
    }
    .progress {
      margin-right: 0;
    }
  }
  .large-button {
    width: 260px;
  }
  .card-1 {
    width: 400px;
    margin-right: 15px;
  }
  .col-end {
    margin-left: 30px;
    color: ${Colors.TextSecondary};
  }
  .anticon svg {
    width: 18px;
  }
`;

export const Divider = styled(AntDivider)`
  height: 100%;
`;
export const HR = styled(AntDivider)`
  margin: 16px 0px;
`;
export const Title = styled(Typography)`
  font: ${fonts.medium.medium};
  color: ${Colors.TextDark};
  line-height: 24px;
  margin-bottom: ${Spacings.xSmall.margin};
`;
export const Text = styled(Typography)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  line-height: 22px;
  margin-right: 16px;
`;

export const Created = styled(Typography)`
  font: ${fonts.xSmall.bold};
  color: ${Colors.TextSecondary};
  line-height: 22px;
`;
export const TextBy = styled.span`
  font: ${fonts.xSmall.bold};
  color: ${Colors.TextSecondary};
  line-height: 22px;
  margin-right: ${Spacings.xxSmall.margin};
`;
export const UserDiv = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.TextSecondary};
  line-height: 22px;
  .text {
    font-size: 12px;
  }
`;
export const StyledDiv = styled.div`
  display: flex;
`;
