import React from 'react';
import { Row, Col } from 'antd';
import Card from 'atoms/Card';
import DateTime from 'atoms/DateTime';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import { StyledDiv, StyledDivider, Wrapper } from './AntStyled';

const ActivityCard = ({ activityDate, platformIcon, message }) => (
  <Row align="middle" className="no-wrap">
    <Col flex="100px">
      <StyledDiv bottom="16px">
        <DateTime date={activityDate} format={MomentFormat.time} />
      </StyledDiv>
    </Col>
    <Col flex="auto">
      <Card padding={16} bottom="16px">
        <Wrapper>
          <Row align="middle" justify="space-between" className="row no-wrap">
            <Col>
              <Row className="no-wrap">
                <Col className="flex">
                  <StyledDiv>
                    <img src={platformIcon} alt="logo" className="icon"></img>
                  </StyledDiv>
                </Col>
                <StyledDivider type="vertical" />
                <Col>
                  <StyledDiv>{message}</StyledDiv>
                </Col>
              </Row>
            </Col>
            <Col>
              <StyledDivider type="vertical" />
            </Col>
          </Row>
        </Wrapper>
      </Card>
    </Col>
  </Row>
);

export default ActivityCard;
