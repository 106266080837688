import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import SettingsTemplate from 'atoms/SettingsTemplate';
import Table from 'atoms/Table';
import Card from 'atoms/Card';
import Button from 'atoms/Button';
import {
  BUTTON_TYPE,
  PRIVATE_ROUTE,
  EMPTY_ARRAY,
  FETCH_POLICY,
} from 'utils/constants';
import { useQuery } from '@apollo/client';
import _has from 'lodash/has';
import InfoIcon from 'atoms/InfoIcon';
import { BackArrow } from 'assets';
import Header from 'layout/Header';
import styled from 'styled-components';
import { GET_ALL_SOPS } from '../../graphql/Queries';
import {
  Content, Text, ContentTitle, Arrow,
} from './AntStyled';

const Container = styled.div`
  width: 100%;
`;

export default function AddSop(props) {
  const { history } = props;
  const [templates, setTemplates] = useState(EMPTY_ARRAY);
  const { data: templatesData, loading: templatesDataLoading } = useQuery(
    GET_ALL_SOPS,
    {
      variables: {
        where: {
          isTemplate: true,
          status: 'DRAFT',
        },
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    }
  );
  useEffect(() => {
    if (_has(templatesData, 'getAllSops')) {
      setTemplates(templatesData.getAllSops);
    }
  }, [templatesData]);

  const Title = () => (
    <>
      <ContentTitle onClick={() => history.goBack()}>
        <Arrow src={BackArrow} alt="arrow" />
        <InfoIcon title={<FormattedMessage id="sops.create" />} />
      </ContentTitle>
    </>
  );

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      // width: '45%',
      ellipsis: true,
    },
    {
      dataIndex: 'select',
      key: 'select',
      align: 'right',
      // width: '16%',
      render: (text, rowData) => (
        <Button
          secondary
          ghost
          onClick={() => history.push({
            pathname: PRIVATE_ROUTE.SOP_NEW,
            state: {
              templateData: rowData,
            },
          })}
          name="select"
        />
      ),
    },
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];

  return (
    <Container>
      <Header title={<Title />} />
      <Content>
        <Card padding="16" className="card">
          <SettingsTemplate>
            <Row justify="center">
              <Button
                type={BUTTON_TYPE.PRIMARY}
                className="btn-no-shadow"
                onClick={() => history.push(PRIVATE_ROUTE.SOP_NEW)}
                name={<FormattedMessage id="feedback.add.newSurvey" />}
              >
              </Button>
            </Row>
            <Row justify="center" className="row-text">
              <Text>
                <FormattedMessage id="feedback.add.selectTemplateTitle" />
              </Text>
            </Row>
            <Row justify="center" className="row-table">
              <Card>
                <Table
                  className="bb-0"
                  dataSource={templates}
                  columns={columns}
                  height="100%"
                  loading={templatesDataLoading}
                />
              </Card>
            </Row>
          </SettingsTemplate>
        </Card>
      </Content>
    </Container>
  );
}
