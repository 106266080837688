import React, { useReducer, createContext } from 'react';
import produce from 'immer';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from 'utils/constants';
import ability from 'utils/ability/ability';

const initialState = {
  loginUser: EMPTY_OBJECT,
  notificationCount: 0,
  title: EMPTY_STRING,
  checkInStatus: EMPTY_OBJECT,
  sidebar: true,
  userAbility: ability,
  showCheckInDetails: false,
  searchData: EMPTY_ARRAY,
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case 'LOGIN_USER':
      draft.loginUser = action.data;
      break;
    case 'LOCAL_SIGN_UP':
      draft.localSignUp = action.data;
      break;
    case 'CHECK_IN_STATUS':
      draft.checkInStatus = action.data;
      break;
    case 'SETTING_TITLE':
      draft.title = action.data;
      break;
    case 'MAIN_SIDEBAR':
      draft.sidebar = action.data;
      break;
    case 'INCREASE_NOTIFICATION_COUNT':
      if (action.data && action.data.count !== undefined) {
        draft.notificationCount = action.data.count;
      } else {
        draft.notificationCount += 1;
      }
      break;
    case 'DECREASE_NOTIFICATION_COUNT':
      if (action.data && action.data.count !== undefined) {
        draft.notificationCount = action.data.count;
      } else {
        draft.notificationCount =
          draft.notificationCount > 0 ? draft.notificationCount - 1 : 0;
      }
      break;
    case 'SET_ABILITY':
      draft.userAbility = action.data;
      break;
    case 'SET_SEARCH_DATA':
      draft.searchData = action.data;
      break;
    case 'SHOW_USER_CHECKIN_DETAIL':
      draft.showCheckInDetails = action.data;
      break;
    default:
      break;
  }
});

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
