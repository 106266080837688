import React, { useContext } from 'react';
import _map from 'lodash/map';
import { MoreOutlined } from '@ant-design/icons';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Popover as AntPopover } from 'antd';

import { POPOVER_TYPE } from 'utils/constants';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { AppContext } from 'AppContext';

const GlobalStyles = createGlobalStyle`
  body{
    .user-actions-container .ant-popover-inner-content{
      padding:0px;
    }
    .user-actions-container .ant-popover-arrow{
      display:none;
    }
  }
`;

const Popover = styled(AntPopover)`
  .anticon {
    border: none;
    cursor: pointer;
    color: #515983;
    font-size: 22px;
  }
`;

const Action = styled.div`
  font: ${fonts.small.regular};
  color: ${Colors.Text};
  .user-action-button {
    padding: ${Spacings.xxSmall.padding} ${Spacings.xSmall.padding};
    cursor: pointer;
    :first-child {
      padding-top: 12px;
      border-radius: 8px 8px 0px 0px;
    }
    :last-child {
      padding-bottom: 12px;
      border-radius: 0px 0px 8px 8px;
    }
  }
  .user-action-button:hover {
    background-color: ${Colors.LightBackground};
    :only-child {
      border-radius: 8px;
    }
  }
  .user-action-button: last-child {
    margin-bottom: 0;
  }
  ${(props) =>
    props?.horizontal &&
    css`
      display: flex;
      align-items: center;
      padding: 5px;
      .user-action-button {
        padding: 0px;
        width: 120px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: ${Colors.IconColor};
        :first-child {
          padding-top: 0px;
          border-radius: 5px;
          margin-right: 6px;
          color: ${Colors.SuccessGreen};
        }
        :last-child {
          padding-bottom: 0px;
          border-radius: 5px;
          color: ${Colors.ButtonDanger};
        }
      }
    `}
`;

const UsersActions = ({ rowData, actions, onClickEvent, horizontal }) => {
  const {
    state: { loginUser },
  } = useContext(AppContext);

  return (
    <Popover
      placement={POPOVER_TYPE.BOTTOM_RIGHT}
      overlayClassName="user-actions-container"
      content={
        <Action className="styled-user-actions" horizontal={horizontal}>
          {_map(actions, (list, index) => (
            <React.Fragment key={index}>
              <div
                role="button"
                className="user-action-button"
                onClick={() => onClickEvent(rowData, list)}
              >
                {list}
              </div>
            </React.Fragment>
          ))}
        </Action>
      }
      trigger={POPOVER_TYPE.CLICK}
    >
      <GlobalStyles />
      {(rowData?.role !== 'OWNER' || loginUser?.role === 'OWNER') && (
        <MoreOutlined />
      )}
    </Popover>
  );
};

export default UsersActions;
