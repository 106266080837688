import React from 'react';

function SopWorkUpdate({ data }) {
  const { title } = data;
  return (
    <div>
      {title}
    </div>
  );
}

export default SopWorkUpdate;
