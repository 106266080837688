import styled from 'styled-components';
import { Popover, Timeline as AntTimeline, Typography, Spin } from 'antd';

import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
import { fonts } from 'design/Fonts';

export const StyledPopover = styled(Popover)``;

export const CommentContentWrapper = styled.p`
  font-size: ${fonts.xSmall.size};
  color: ${Colors.Text};
  margin: 0;
`;
export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: ${Colors.DefaultBackground};
  border: 1px solid ${Colors.Border};
  border-radius: 8px;
  margin-bottom: 8px;
  img {
    cursor: pointer;
  }
  .ant-divider,
  .ant-divider-vertical {
    height: 16px;
    background: ${Colors.Border};
  }
`;

export const CommentWrapper = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${Colors.Border};
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .name {
    font: ${fonts.xSmall.medium};
    color: ${Colors.TextDark};
    position: relative;
    bottom: 8px;
  }
  .row-info {
    position: relative;
    justify-content: space-between;
  }
  .col-time {
    font-size: ${fonts.xxSmall.size};
    color: rgba(81, 89, 131, 0.7);
    position: absolute;
    left: 40px;
    bottom: 5px;
  }
`;

export const PopoverContent = styled.div`
  min-height: ${({ isSection }) => (isSection ? 'unset' : '310px')};
  max-height: 500px;
  max-width: ${({ isSection }) => (isSection ? 'unset' : '330px')};
  overflow: auto;
  margin: 8px 4px;
  display: flex;
  flex-direction: column;
  .no-comments-wrapper {
    height: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    & .img-subtitle {
      font: ${fonts.small.medium};
      color: ${Colors.TextSecondary};
    }
  }
  .emoji-picker {
    align-self: flex-end;
    position: absolute;
    right: 0;
    bottom: 51px;
    line-height: 1;
    & .anticon {
      margin-right: 5px;
    }
  }
  input.ant-input::placeholder {
    color: ${Colors.TextMedium};
  }

  .input-comment:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  input,
  .input-comment {
    background: ${Colors.PopoverInput};
  }
  textarea {
    resize: none;
    border: 1px solid ${Colors.Border};
    padding-right: 20px;
    border-radius: 4px;
  }
  textarea::placeholder {
    color: ${Colors.SecondaryColor};
  }
  .input-comment {
    align-items: center;
  }
  & .ant-row.input-row {
    position: relative;
    & .ant-btn {
      margin-top: 10px;
      margin-left: auto;
    }
  }
  .comments-col {
    overflow: auto;
    margin-bottom: 20px;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
`;
export const Comments = styled.img``;
export const Timeline = styled(AntTimeline)`
  max-height: 200px;
  max-width: 400px;

  .no-actions {
    padding: 0 ${Spacings.xSmall.padding};
  }
  .ant-timeline-item.editing {
    padding-bottom: ${Spacings.xxSmall.padding};
  }

  .cancel {
    cursor: pointer;
  }

  .ant-timeline-item-head-custom .anticon {
    font-size: 24px;
  }

  .anticon-delete {
    padding-left: ${Spacings.xxSmall.padding};
    color: ${Colors.ButtonPrimary};
    cursor: pointer;
  }

  .ant-timeline-item-content {
    margin-left: ${Spacings.large.margin};
  }

  .ant-avatar {
    height: 20px;
    width: 20px;
  }

  .col-avatar {
    flex: 1;
    display: inline-flex;
  }
  .ant-avatar-string {
    line-height: 1.5715;
  }
  .ant-timeline-item-head-custom {
    padding: 0;
  }

  div.ant-typography,
  .ant-typography p {
    margin-bottom: 0;
  }

  .row-info {
    color: #999;
    font-size: 12px;
    position: relative;
  }

  .row-info > div {
    margin: 0 5px;
  }

  .emoji {
    color: black;
  }
`;

export const CommentParagraph = styled(Typography.Paragraph)`
  font-size: 13px;
  line-height: 1.4;
  padding-bottom: 4px;
`;

export const StyledSpin = styled(Spin)`
  padding-bottom: 0;
`;

export const Image = styled.img`
  width: 64px;
  height: 64px;
  align-self: center;
`;
