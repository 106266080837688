import NotificationDefault from 'Widgets/Notifications/NotificationDefault/NotificationDefault';
import NotificationTodo from 'Widgets/Todos/widgets';
import AddFeedbackWorkUpdate from './widgets/AddFeedbackWorkUpdate';
import FeedbackResultWorkUpdate from './widgets/FeedbackResultWorkUpdate';

export const workUpdateWidgets = {
  FEEDBACK_REQUEST: {
    title: () => 'Survey Title',
    component: AddFeedbackWorkUpdate,
  },
  FEEDBACK_RESULT: {
    title: () => 'Survey Title',
    component: FeedbackResultWorkUpdate,
  },
};

export const notificationWidgets = {
  FEEDBACK_REQUEST: {
    title: data => data.title,
    icon: () => 'person-done-outline',
    component: NotificationTodo,
  },
  FEEDBACK_RESULT: {
    title: data => data.title,
    icon: () => 'person-done-outline',
    component: NotificationTodo,
  },
};
