import styled from 'styled-components';
import {
  Layout,
  Form as AntForm,
  TimePicker as AntTimePicker,
  Select as AntSelect,
} from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import Variables from 'design/Variables';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  &.ant-layout-content {
    padding-bottom: 0px;
    overflow-y: hidden;
    .ant-btn-primary {
      line-height: ${Variables.LineHeightMd};
    }
  }
  .switch.ant-switch-small {
    min-width: 36px;
    height: 18px;
  }
  .integration-text {
    font: ${fonts.small.regular};
    line-height: ${Variables.LineHeightSm};
    color: ${Colors.TextSecondary};
  }
  .integration-icons {
    width: 18px;
  }
  .icon-container {
    background: ${Colors.IconColor};
    border: 1px solid ${Colors.Border};
    box-sizing: border-box;
    border-radius: 6px;
    padding: ${Spacings.xxSmall.padding};
    margin-right: ${Spacings.xxSmall.margin};
  }
  .row {
    margin-bottom: ${Spacings.xSmall.margin};
  }
  .back-icon {
    border-radius: 4px;
    background: linear-gradient(#6fbaff, rgba(0, 0, 0, 0.4));
    color: ${Colors.TextLight};
    cursor: pointer;
  }
  .emoji-card {
    margin-bottom: ${Spacings.small.margin};
  }
  .right {
    float: right;
    margin-bottom: 0;
    width: fit-content;
  }
  .card {
    max-width: 700px;
    margin-bottom: ${Spacings.small.margin};
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
  }
  .emojis {
    display: flex;
    justify-content: space-between;
  }
  .row-1 {
    margin-bottom: ${Spacings.small.margin};
  }

  .ant-checkbox + span {
    font: ${fonts.small.regular};
    line-height: ${Variables.LineHeightSm};
    color: ${Colors.TextBlack};
  }
  .timezone.ant-form-item {
    margin-bottom: ${Spacings.Small.margin};
  }
  .col-mood {
    display: flex;
  }
  .ant-switch-checked {
    background-color: ${Colors.PrimaryColor};
  }
`;

export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
  .ant-form-item {
    margin-bottom: ${Spacings.xSmall.margin};
  }
  .ant-btn {
    font: ${fonts.small.regular};
  }
  .ant-switch-checked::after {
    margin-right: 1px;
    width: 14px;
    height: 14px;
  }
  .ant-switch-small::after {
    width: 14px;
    height: 14px;
  }
`;
export const EmojiWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export const Title = styled.p`
  font: ${fonts.xSmall.regular};
  line-height: ${Variables.LineHeightSm};
  color: ${Colors.TextSecondary};
  margin: 0px;
  margin-left: 10px;
`;
export const TimePicker = styled(AntTimePicker)`
  width: 100%;
  padding: 5px 12px;
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: ${Variables.AtomRadius};
  display: flex;
  align-items: center;
  font: ${fonts.small.regular};

  .ant-picker-input > input {
    font: ${fonts.small.regular};
    color: ${Colors.TextSecondary};
  }
  @media screen and (max-width: 683px) {
    width: 100%;
  }
`;
export const Image = styled.img`
  width: 16px;
  height: 16px;
`;
export const Select = styled(AntSelect)`
  font: ${fonts.xSmall.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: ${Variables.AtomRadius};
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: Calc(${Spacings.xSmall.margin} - 4px);
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 Calc(${Spacings.xSmall.padding} - 2px);
    border: none;
  }
`;
export const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 2px;
  margin-top: -4px;
`;
export const StyledDiv = styled.div`
  font-weight: 300;
  font-size: ${fonts.xSmall.size};
  line-height: ${Variables.LineHeightSm};
  color: ${Colors.TextSecondary};
  margin: unset;
`;
