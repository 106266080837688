/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Col, Popover, Row } from 'antd';
import Tag from 'atoms/Tag';
import UserProfile from 'molecules/UserProfile';
import _map from 'lodash/map';
import { ActivityData } from '../MockData';
import ActivityCard from '../ActivityCard';
import { ProfileDropdownWrapper, ProfileWrapper } from '../AntStyled';

export const ChangedAssignee = (data, type = '') => (
  <Row align="middle" className="message">
    {type !== 'teams' && (
      <Col flex="auto" className="flex">
        <UserProfile
          size={20}
          stackedName={false}
          profile={data?.actor?.profilePic || data?.processedData?.actor_image}
          name={data?.actor?.name || data?.processedData?.actor_name}
          className="user-profile"
        />
      </Col>
    )}
    <Col>
      <span>
        changed&nbsp;
        <span
          className="heading"
          onClick={() => {
            window.open(data?.processedData?.task_url);
          }}
        >
          {data?.processedData?.task_name}
        </span>
        &nbsp; assignee from &nbsp;
      </span>
    </Col>
    <Col className="flex">
      <UserProfile
        size={20}
        stackedName={false}
        profile={data?.actor?.profilePic || data?.processedData?.actor_image}
        name={data?.actor?.name || data?.processedData?.actor_name}
      />
    </Col>
    <span className="text-regular">to</span> &nbsp;
    <Col className="flex">
      <UserProfile
        size={20}
        stackedName={false}
        profile={data?.actor?.profilePic || data?.processedData?.actor_image}
        name={data?.actor?.name || data?.processedData?.actor_name}
      />
    </Col>
    <Col>
      <span>
        in space &nbsp;
        <Tag className="tag-text" content={data?.processedData?.space_name} />
      </span>
    </Col>
  </Row>
);

export const AssignedMessage = (data, type = '') => (
  <Row align="middle" className="message">
    {type !== 'teams' && (
      <UserProfile
        size={20}
        stackedName={false}
        profile={data?.actor?.profilePic || data?.processedData?.actor_image}
        name={data?.actor?.name || data?.processedData?.actor_name}
        className="user-profile"
      />
    )}
    <span className="text-regular">assigned&nbsp;</span>
    <span
      className="heading"
      onClick={() => {
        window.open(data?.processedData?.task_url);
      }}
    >
      {data?.processedData?.task_name}
    </span>
    &nbsp;
    <span className="text-regular">to</span> &nbsp;
    <UserProfile
      size={type === 'teams' ? 18 : 20}
      stackedName={false}
      profile={data?.processedData?.after?.[0]?.profilePicture}
      name={data?.processedData?.after?.[0]?.username}
      className="user-profile"
    />
    {data?.processedData?.after?.length > 1 && (
      <ProfileDropdownWrapper>
        & &nbsp;
        <Popover
          content={_map(data?.processedData?.after, (data, index) => (
            <ProfileWrapper key={index}>
              <UserProfile
                size={type === 'teams' ? 14 : 18}
                profile={data?.profilePicture}
                name={data?.username}
                stackedName={false}
              />
            </ProfileWrapper>
          ))}
          placement="bottomRight"
        >
          {data?.processedData?.after?.length - 1}&nbsp;
          {data?.processedData?.after?.length - 1 > 1 ? 'others' : 'other'}
          &nbsp;
        </Popover>
      </ProfileDropdownWrapper>
    )}
    <span className="text-regular">in space &nbsp;</span>
    {type === 'teams' ? (
      <div className="bold-text">{data?.processedData?.space_name}</div>
    ) : (
      <Tag className="tag-text" content={data?.processedData?.space_name} />
    )}
  </Row>
);
const ClickupAssigneeCard = ({ data }) => (
  <ActivityCard
    activityDate={data?.addedAt}
    platformIcon={ActivityData?.clickup?.title?.icon}
    message={
      data?.processedData?.isUpdated
        ? ChangedAssignee(data)
        : AssignedMessage(data)
    }
  />
);

export default ClickupAssigneeCard;
