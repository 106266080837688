/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Row, Col, Checkbox, Typography, message, Layout } from 'antd';
import Card from 'atoms/Card';
import { map, toLower } from 'lodash';
import _map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import Input from 'atoms/Input';
import Switch from 'atoms/Switch';
import Button from 'atoms/Button';
import { Emoji } from 'emoji-mart';
import * as eva from 'eva-icons';
import moment from 'moment';
import {
  GoogleChat,
  SlackIcon,
  MicrosoftTeams,
  Clock,
  ArrowIcon,
} from 'assets';
import 'emoji-mart/css/emoji-mart.css';
import { useHistory } from 'react-router-dom';
import {
  EMPTY_OBJECT,
  FETCH_POLICY,
  PRIVATE_ROUTE,
  EMOJIS,
  BUTTON_TYPE,
  TimeZone,
} from 'utils/constants';
import { useMutation } from '@apollo/client';
import { GET_STANDUP_SETTINGS } from 'features/standUp/graphql/Queries';
import client from 'apollo';
import { useForm } from 'antd/lib/form/util';
import { UPDATE_STANDUP } from 'features/standUp/graphql/Mutations';

import ContentWrapper from 'atoms/ContentWrapper';
import {
  Content,
  Form,
  EmojiWrapper,
  Title,
  TimePicker,
  Image,
  Select,
  Arrow,
  StyledDiv,
} from './AntStyled';
const { Option } = Select;
const { Paragraph } = Typography;
const format = 'HH:mm';

function StandUpSettings() {
  const history = useHistory();
  const [form] = useForm();
  const [isChecked, setIsChecked] = useState(true);
  const [update, setUpdate] = useState(true);
  const [questions, setQuestions] = useState(EMPTY_OBJECT);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [notify, setNotify] = useState({
    reminderAt: true,
    summaryAt: true,
  });
  const [updateStandUp] = useMutation(UPDATE_STANDUP, {
    onCompleted() {
      message.success('Saved successfully');
    },
  });

  const onFormLayoutChange = () => {
    setUpdate(false);
  };

  useEffect(() => {
    client
      .query({
        query: GET_STANDUP_SETTINGS,
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      })
      .then(({ data }) => {
        const { standUp } = data;
        const temp = {};
        _map(standUp?.standUpQuestions, (val) => {
          temp[val?.type] = val?.questionText;
        });
        const initialValuesObject = {
          ...temp,
          reminderAt: moment.utc(standUp?.reminderAt, 'HH:mm:ss[Z]').local(),
          summaryAt: moment.utc(standUp?.summaryAt, 'HH:mm:ss[Z]').local(),
          moodSelector: standUp?.moodSelector,
          reminderTimezoneType: standUp?.reminderTimezoneType,
          summaryTimezoneType: standUp?.summaryTimezoneType,
        };
        setIsChecked(standUp?.moodSelector);
        setNotify({
          reminderAt: standUp?.reminder,
          summaryAt: standUp?.summary,
        });
        setQuestions(standUp?.standUpQuestions);
        form.setFieldsValue(initialValuesObject);
      });
    eva.replace();
  }, []);

  const children = _map(TimeZone, (list, index) => (
    <Option key={index} value={list?.value}>
      {list?.zone}
    </Option>
  ));

  const onFinish = (value) => {
    const data = {
      moodSelector: isChecked,
      reminderAt: `${moment
        .utc(value?.reminderAt)
        .format(moment.HTML5_FMT.TIME_MS)}Z`,
      summaryAt: `${moment
        .utc(value?.summaryAt)
        .format(moment.HTML5_FMT.TIME_MS)}Z`,
      reminder: notify?.reminderAt,
      summary: notify?.summaryAt,
      standUpQuestions: _map(questions, (val) => ({
        id: val?.id,
        questionText: value?.[val?.type],
        type: val?.type,
      })),
      reminderTimezoneType: value?.reminderTimezoneType,
      summaryTimezoneType: value?.summaryTimezoneType,
    };
    updateStandUp({ variables: { data } });
  };
  const handleEmoji = (value) => {
    if (!isChecked && value >= 0) {
      setSelectedEmoji(value);
      form.setFieldsValue({ mood: value });
    }
  };

  const handleSwitch = () => {
    setIsChecked(!isChecked);
    setUpdate(false);
  };

  return (
    <Layout>
      <Content id="drop">
        <Card
          padding="16"
          className="card"
          title={<FormattedMessage id="standUps.settings.questions" />}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={onFormLayoutChange}
            onKeyPress={(e) => e?.key === 'Enter' && e?.preventDefault()}
          >
            {_map(questions, (data) => (
              <Form.Item
                name={data?.type}
                label={
                  <FormattedMessage
                    id={`standUps.formLabel.${toLower(data?.type)}`}
                  />
                }
              >
                <Input allowClear />
              </Form.Item>
            ))}
            <Row className="row" justify="space-between">
              <Col className="col-mood">
                <Switch
                  checked={isChecked}
                  className="switch"
                  size="small"
                  onChange={handleSwitch}
                />
                <Title>Mood Selector</Title>
              </Col>
            </Row>
            {isChecked && (
              <StyledDiv>Get to know about your company’s mood</StyledDiv>
            )}
            {isChecked && (
              <Card padding="8px 22" className="emoji-card">
                <div className="emojis">
                  {map(EMOJIS, (emoji, index) => (
                    <EmojiWrapper
                      isDisabled={!isChecked}
                      key={index}
                      role="button"
                      aria-pressed="true"
                      onClick={() => handleEmoji(index)}
                    >
                      <Emoji set="apple" emoji={emoji} size={20} />
                    </EmojiWrapper>
                  ))}
                </div>
              </Card>
            )}

            <Row align="middle" className="row-1">
              <Col>
                <Checkbox
                  onChange={() => {
                    setNotify({
                      ...notify,
                      reminderAt: !notify?.reminderAt,
                    });
                    setUpdate(false);
                  }}
                  checked={notify?.reminderAt}
                >
                  Send reminder at
                </Checkbox>
              </Col>
              <Col span={5}>
                <Form.Item name="reminderAt" className="mb-0">
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    suffixIcon={<Image src={Clock} />}
                    allowClear={false}
                    minuteStep={30}
                    inputReadOnly
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item className="mb-0" name="reminderTimezoneType">
                  <Select
                    defaultActiveFirstOption
                    className="timezone"
                    placeholder="user timezone"
                    showSearch={false}
                    filterOption
                    optionFilterProp="children"
                    showArrow
                    suffixIcon={<Arrow src={ArrowIcon} />}
                    getPopupContainer={() => document.getElementById('drop')}
                  >
                    {children}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" className="row-1">
              <Col>
                <Checkbox
                  onChange={() => {
                    setNotify({
                      ...notify,
                      summaryAt: !notify?.summaryAt,
                    });
                    setUpdate(false);
                  }}
                  checked={notify?.summaryAt}
                >
                  Send daily insight summary at
                </Checkbox>
              </Col>
              <Col span={5}>
                <Form.Item name="summaryAt" className="mb-0">
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    suffixIcon={<Image src={Clock} />}
                    allowClear={false}
                    minuteStep={30}
                    inputReadOnly
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item className="mb-0" name="summaryTimezoneType">
                  <Select
                    placeholder="user timezone"
                    showSearch={false}
                    filterOption
                    className="timezone"
                    optionFilterProp="children"
                    showArrow
                    suffixIcon={<Arrow src={ArrowIcon} />}
                    getPopupContainer={() => document.getElementById('drop')}
                  >
                    {children}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Button
                  name="Save Changes"
                  primary
                  type={BUTTON_TYPE.PRIMARY}
                  htmlType={BUTTON_TYPE.SUBMIT}
                  disabled={update}
                />
              </Col>
            </Row>
          </Form>
        </Card>
        <Card
          padding="16"
          className="card"
          title={<FormattedMessage id="standUps.settings.integrations" />}
        >
          <Row>
            <Col>
              <Paragraph className="integration-text">
                {' '}
                Integrate various platforms to your workspace for a more
                seamless experience.
              </Paragraph>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col>
              <span className="icon-container">
                <img
                  className="integration-icons"
                  src={GoogleChat}
                  alt="icon"
                />
              </span>
              <span className="icon-container">
                <img
                  className="integration-icons"
                  src={MicrosoftTeams}
                  alt="icon"
                />
              </span>
              <span className="icon-container">
                <img className="integration-icons" src={SlackIcon} alt="icon" />
              </span>
            </Col>
            <Col>
              <Button
                name="Go To integrations"
                onClick={() => {
                  history.push(PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.INTEGRATIONS);
                }}
              />
            </Col>
          </Row>
        </Card>
      </Content>
    </Layout>
  );
}
export default StandUpSettings;
