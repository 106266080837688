import { gql } from '@apollo/client';
import { actionFragment } from './fragments';

export const CHECK_OUT = gql`
  mutation checkOut($data: CheckOutInput) {
    checkOut(data: $data) {
     id
    }
  }
`;

export const CREATE_ACTIONS = gql`
  mutation createActions($data: [ActionCreateInput]!) {
    createActions(data: $data) {
      ...ActionFragment
    }
  }
  ${actionFragment}
`;

export const UPDATE_TASK = gql`
mutation updateActions($data:[ActionUpdateDataInput]){
  updateActions(data:$data){
    id
  }
}
`;
