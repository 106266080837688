import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ACTIONS_TYPE, META_TYPE, PRIVATE_ROUTE } from 'utils/constants';
import ability from 'utils/ability/ability';
import { FeatureActivation } from 'features/components/FeatureActivation';
import { AppContext } from 'AppContext';
import InfoIcon from 'atoms/InfoIcon';
import { FormattedMessage } from 'react-intl';
import { FeatureDeactivation } from 'features/components/FeatureDeactivation';
import StandUpSettings from './StandUpSettings';

const StandUps = () => {
  const [activated, setActivated] = useState(
    ability.can('use', 'standups_feature')
  );
  const { dispatch } = useContext(AppContext);
  const Title = () => (
    <InfoIcon
      title={<FormattedMessage id="standUps.title" />}
      contentKey={META_TYPE?.STAND_UP}
    />
  );

  useEffect(() => {
    dispatch({ type: ACTIONS_TYPE.SETTING_TITLE, data: <Title /> });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!activated) {
    return (
      <div>
        <FeatureActivation
          featureKey="standups_feature"
          setActivated={setActivated}
        />
      </div>
    );
  }

  return (
    <div>
      <StandUpSettings />
      <FeatureDeactivation
        featureKey="standups_feature"
        setActivated={setActivated}
      />
    </div>
  );
};

export default {
  component: withRouter(StandUps),
  path: PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.STAND_UPS_SETTINGS,
  key: 'stand-ups',
  abilityAction: 'manage',
  abilitySubject: 'standups',
  icon: (
    <i
      data-eva="checkmark-circle-outline"
      data-eva-height="20"
      data-eva-width="20"
    ></i>
  ),
  text: <FormattedMessage id="standUps.sidebarTitle" />,
};
