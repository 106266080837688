//! in this file commenting sortable component for future use.
import React, { useEffect, useState } from 'react';
import { Col, Row, Checkbox, message, Input } from 'antd';
import Button from 'atoms/Button';
import { Emoji } from 'emoji-mart';
import Card from 'atoms/Card';
import DateTime from 'atoms/DateTime';
import {
  AMPLITUDE_EVENTS,
  EMOJIS,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  FETCH_POLICY,
  PRIVATE_ROUTE,
} from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import * as eva from 'eva-icons';
import {
  AlertDark,
  CalenderPrimary,
  PlusCircle,
  SmilingFace,
  YesterdayIcon,
  BlockerPrimary,
  ReOrder,
} from 'assets';
import 'emoji-mart/css/emoji-mart.css';
import { drop, filter, findIndex, isEmpty, map } from 'lodash';
import MultipleInput from 'organisms/MultipleInput';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import client from 'apollo';
import {
  ADD_STANDUP_ENTRY,
  CREATE_STANDUP_ENTRY,
} from 'features/standUp/graphql/Mutations';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CURRENT_STANDUP } from 'features/standUp/graphql/Queries';
import Loader from 'atoms/Loader';
import { createAmplitudeEvent } from 'utils/amplitude';
import moment from 'moment';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import _map from 'lodash/map';
import { Prompt, useHistory } from 'react-router-dom';
import { previewImage } from 'antd/lib/upload/utils';
import Colors from 'design/Colors';
import Modal from 'atoms/Modal';
import {
  Form,
  EmojiWrapper,
  Divider,
  ErrorText,
  Wrapper,
  TimeDiv,
} from './AntStyled';
const SortableContainer = sortableContainer(({ children }) => children);
const SortableItem = sortableElement(({ children }) => children);

const StandUpForm = ({ previousPath }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [date, setDate] = useState(moment());
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [addTask, setAddTask] = useState(true);
  const [addBlocker, setAddBlocker] = useState(true);
  const [isPromptRequired, setIsPromptRequired] = useState(false);
  const [standUpQuestions, setStandUpQuestions] = useState(EMPTY_ARRAY);
  const [showMoodSelector, setShowMoodSelector] = useState(true);
  const [isCheckedInToday, setIsCheckedInToday] = useState(true);
  const [todaysStandUpEntry, setTodaysStandUpEntry] = useState(EMPTY_OBJECT);
  const [yesterdaysData, setYesterdaysData] = useState(EMPTY_OBJECT);
  const [previousStandupEntry, setPreviousStandUpEntry] = useState(EMPTY_ARRAY);
  const [showError, setShowError] = useState(false);
  const [createStandUpEntry] = useMutation(CREATE_STANDUP_ENTRY);
  const [addStandUpEntry] = useMutation(ADD_STANDUP_ENTRY);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [canRecheckIn, setCanRecheckIn] = useState(false);
  const { data: todaysStandup, loading } = useQuery(GET_CURRENT_STANDUP, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  useEffect(() => {
    eva.replace();
  }, []);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (!loading) {
      const previousEntries = map(
        todaysStandup?.currentStandUpEntry?.previousStandUpEntry?.today
          ?.standUpAnswers,
        (ans) => ({
          content: { ...ans?.content, isPrevious: true, isChecked: true },
        })
      );
      setStandUpQuestions(
        todaysStandup?.currentStandUpEntry?.standUp?.standUpQuestions
      );
      setShowMoodSelector(
        todaysStandup?.currentStandUpEntry?.standUp?.moodSelector
      );
      setIsCheckedInToday(
        !isEmpty(todaysStandup?.currentStandUpEntry?.todayStandUpEntry)
      );
      setTodaysStandUpEntry(
        todaysStandup?.currentStandUpEntry?.todayStandUpEntry || EMPTY_OBJECT
      );
      setPreviousStandUpEntry(
        todaysStandup?.currentStandUpEntry?.previousStandUpEntry?.today
          ?.standUpAnswers
      );
      const oldData = form.getFieldValue(['yesterdays']);
      const standUpQuestionId = oldData?.[0]?.standUpQuestionId;
      const newData =
        previousEntries?.length > 0
          ? [
              ...map(previousEntries, (entry) => ({
                ...entry,
                standUpQuestionId,
              })),
            ]
          : [...oldData];
      form.setFieldsValue({ yesterdays: newData });
      setYesterdaysData(previousEntries);
    }
  }, [loading, todaysStandup]);

  useEffect(() => {
    if (!loading && isCheckedInToday && todaysStandUpEntry) {
      const questionIds = map(standUpQuestions, (question) => question?.id);
      const yesterdaysData = map(
        filter(
          todaysStandUpEntry?.yesterday?.standUpAnswers,
          (ans) => ans?.content?.isChecked
        ),
        (entry) => ({
          content: entry?.content,
          standUpQuestionId: questionIds[0],
        })
      );
      const todaysData = map(
        todaysStandUpEntry?.today?.standUpAnswers,
        (entry) => ({
          content: entry?.content,
          standUpQuestionId: questionIds[1],
        })
      );
      const blockersData = map(
        todaysStandUpEntry?.blocker?.standUpAnswers,
        (entry) => ({
          content: entry?.content,
          standUpQuestionId: questionIds[2],
        })
      );
      setSelectedEmoji(todaysStandUpEntry?.mood);
      form.setFieldsValue({
        todays: todaysData?.length
          ? todaysData
          : [{ content: { task: '' }, standUpQuestionId: questionIds[1] }],
        blockers: blockersData?.length
          ? blockersData
          : [{ content: { task: '' }, standUpQuestionId: questionIds[2] }],
        mood: todaysStandUpEntry?.mood,
      });
      if (yesterdaysData?.length > 0) {
        form.setFieldsValue({
          yesterdays: yesterdaysData,
          blockers: blockersData,
        });
      }
    }
  }, [loading, todaysStandUpEntry]);

  //! for future use
  // function onChange(e) {
  //   const oldData = form.getFieldValue(['yesterdays']);
  //   const standUpQuestionId = oldData?.[0]?.standUpQuestionId;
  //   if (e?.target?.checked) {
  //     const newData = [
  //       ...map(previousStandupEntry, (entry) => ({
  //         ...entry,
  //         standUpQuestionId,
  //       })),
  //       ...oldData,
  //     ];
  //     form.setFieldsValue({ yesterdays: newData });
  //   } else {
  //     const newData = filter(oldData, (answer) => !answer?.content?.isPrevious);
  //     form.setFieldsValue({
  //       yesterdays: newData?.length
  //         ? newData
  //         : [{ content: { task: '' }, standUpQuestionId }],
  //     });
  //   }
  // }

  const handleEmoji = (value) => {
    if (!isCheckedInToday && value >= 0) {
      setSelectedEmoji(value);
      form.setFieldsValue({ mood: value });
    }
  };

  const validate = () => {
    const formValues = form.getFieldValue('todays');
    const todaysTask = filter(formValues, (today) => today?.content?.task);
    if (!todaysTask?.length) {
      setShowError(true);
      return false;
    }
    setShowError(false);
    return true;
  };

  const handleForm = (values) => {
    setIsPromptRequired(false);
    if (validate() || isCheckedInToday) {
      setBtnLoading(true);
      const { blockers, todays, yesterdays, mood } = values;
      const todaysData = map(
        filter(todays, (today) => today?.content?.task),
        (val, index) => ({ ...val, sequenceNo: index + 1 })
      );
      const blockersData = map(
        filter(blockers, (blocker) => blocker?.content?.task),
        (val, index) => ({ ...val, sequenceNo: index + 1 })
      );
      const yesterdaysData = map(
        filter(yesterdays, (yesterday) => yesterday?.content?.task),
        (val, index) => ({ ...val, sequenceNo: index + 1 })
      );
      setIsModalVisible(false);
      const mutation = isCheckedInToday ? addStandUpEntry : createStandUpEntry;
      const data = isCheckedInToday
        ? {
            standUpAnswers: [
              ...drop(blockersData, todaysStandUpEntry?.blocker?.count),
              ...drop(todaysData, todaysStandUpEntry?.today?.count),
            ],
          }
        : {
            mood,
            standUpAnswers: [...blockersData, ...todaysData, ...yesterdaysData],
          };
      mutation({
        variables: {
          data,
        },
        refetchQueries: [
          {
            query: GET_CURRENT_STANDUP,
          },
        ],
        awaitRefetchQueries: true,
      })
        .then((res) => {
          setCanRecheckIn(false);
          const eventProperties = {
            Id: res?.data?.createStandUpEntry?.id,
            createdAt: moment
              .utc(res?.data?.createStandUpEntry?.createdAt)
              .local()
              .format(MomentFormat.dateTime),
            Mood: res?.data?.createStandUpEntry?.mood,
            createdBy: `${res?.data?.createStandUpEntry?.user?.firstName} ${res?.data?.createStandUpEntry?.user?.lastName}`,
            Email: res?.data?.createStandUpEntry?.user?.email,
            UserId: res?.data?.createStandUpEntry?.user?.id,
            StandUpQuestions: _map(
              res?.data?.createStandUpEntry?.standUp?.standUpQuestions,
              (data) => data?.questionText
            ),
            Yesterday: {
              count: res?.data?.createStandUpEntry?.yesterday?.count,
              Answers: _map(
                res?.data?.createStandUpEntry?.yesterday?.standUpAnswers,
                (data) => data?.content?.task
              ),
            },
            Today: {
              count: res?.data?.createStandUpEntry?.today?.count,
              Answers: _map(
                res?.data?.createStandUpEntry?.today?.standUpAnswers,
                (data) => data?.content?.task
              ),
            },
            Blockers: {
              count: res?.data?.createStandUpEntry?.blocker?.count,
              Answers: _map(
                res?.data?.createStandUpEntry?.blocker?.standUpAnswers,
                (data) => data?.content?.task
              ),
            },
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENTS.STANDUP_CHECKIN,
            eventProperties
          );
          message.success('Successfully checked in');
          if (previousPath === PRIVATE_ROUTE.DASHBOARD) {
            history.push(PRIVATE_ROUTE.DASHBOARD);
          }
        })
        .catch((e) => console.log('error =>', e))
        .finally(() => {
          setBtnLoading(false);
        });
    }
  };
  const onFormLayoutChange = (e, data) => {
    setIsPromptRequired(true);
    if (
      data?.todays?.[todaysStandUpEntry?.today?.count]?.content?.task ||
      data?.blockers?.[todaysStandUpEntry?.blocker?.count]?.content?.task
    ) {
      setCanRecheckIn(true);
    } else {
      setCanRecheckIn(false);
    }
  };
  return (
    <Wrapper className="card1">
      {loading ? (
        <Loader />
      ) : (
        <Form
          layout="vertical"
          name="standUpForm"
          form={form}
          onFinish={handleForm}
          initialValues={{
            yesterdays: [{ content: { task: '' } }],
            todays: [{ content: { task: '' } }],
            blockers: [{ content: { task: '' } }],
          }}
          onValuesChange={onFormLayoutChange}
        >
          <Card padding="16px">
            <Prompt
              message="You have unsaved changes! Are you sure you want to leave this page"
              when={isPromptRequired}
            />
            <Form.Item
              className="yesterday-wrapper"
              label={
                <div className="yesterday-goals">
                  <div className="label-text">
                    <div className="main-icon">
                      <img src={YesterdayIcon} alt="icon" />
                    </div>
                    <div>
                      {standUpQuestions?.[0]?.questionText || (
                        <FormattedMessage id="standUps.formLabel.yesterday" />
                      )}
                    </div>
                  </div>
                  {/* for future use */}
                  {/* <div>
                    {!(
                      todaysStandup?.currentStandUpEntry?.isFirstTimeCheckIn ||
                      isCheckedInToday
                    ) && (
                      <div className="label-text">
                         <div className="check-box">
                          <Checkbox onChange={onChange} />
                        </div>
                        <div className="main-icon add">
                          <FormattedMessage id="standUps.formLabel.Add" />
                        </div>
                      </div>
                    )}
                  </div> */}
                </div>
              }
            >
              {yesterdaysData?.length > 0 ? (
                <Card className="task-card">
                  <Form.List name="yesterdays">
                    {(fields, { add, remove, move }) => (
                      <SortableContainer
                        onSortEnd={({ oldIndex, newIndex }) =>
                          move(oldIndex, newIndex)
                        }
                        useDragHandle
                        helperClass="sortableHelper"
                      >
                        <div className="multiple-inputs">
                          {map(fields, (field, index) => (
                            <SortableItem key={`item-${index}`} index={index}>
                              <MultipleInput
                                field={field}
                                add={add}
                                remove={remove}
                                form={form}
                                listName="yesterdays"
                                tasksLength={fields.length}
                                questionId={standUpQuestions?.[0]?.id}
                                disabled
                                showCheck
                                color={Colors.TextGreen}
                                data={todaysStandUpEntry}
                                previousData={previousStandupEntry}
                                isCheckedInToday={isCheckedInToday}
                              />
                            </SortableItem>
                          ))}
                        </div>
                      </SortableContainer>
                    )}
                  </Form.List>
                </Card>
              ) : (
                <Input
                  disabled
                  prefix={
                    <Row>
                      <Col className="drag-icon">
                        <img src={ReOrder} alt="icon" />
                      </Col>
                    </Row>
                  }
                  className="yesterday-task-input"
                />
              )}
            </Form.Item>
            <Divider />
            <Form.Item
              className="todays-wrapper"
              label={
                <>
                  <Row justify="space-between" className="todays-row">
                    <Col>
                      {standUpQuestions?.[1]?.questionText || (
                        <FormattedMessage id="standUps.formLabel.today" />
                      )}
                    </Col>
                    {/* commented for future use */}
                    {/* <Col className="last-updated">
                      {isCheckedInToday && (
                        <TimeDiv>
                          Last checked-in :&nbsp;
                          <DateTime
                            className="time"
                            date={moment(todaysStandUpEntry?.updatedAt).local()}
                            format={MomentFormat.time}
                          />
                        </TimeDiv>
                      )}
                    </Col> */}
                  </Row>
                  <Row align="middle">
                    <Col className="main-icon">
                      <img
                        src={CalenderPrimary}
                        alt="icon"
                        height={20}
                        width={20}
                      />
                    </Col>
                  </Row>
                </>
              }
            >
              <Card className={`${showError ? 'has-error' : ''} task-card`}>
                <Form.List name="todays" placeholder="Add Goals">
                  {(fields, { add, remove, move }) => (
                    // <SortableContainer
                    //   onSortEnd={({ oldIndex, newIndex }) => move(oldIndex, newIndex)}
                    //   useDragHandle
                    //   helperClass="sortableHelper"
                    // >
                    <div className="multiple-inputs">
                      {map(fields, (field, index) => (
                        // <SortableItem key={`item-${index}`} index={index}>
                        <MultipleInput
                          field={field}
                          add={add}
                          remove={remove}
                          form={form}
                          listName="todays"
                          tasksLength={fields?.length}
                          showGroups
                          questionId={standUpQuestions?.[1]?.id}
                          disabled={index < todaysStandUpEntry?.today?.count}
                          required
                          validationFn={validate}
                          setShowError={setShowError}
                          color={Colors.PrimaryColor}
                          data={todaysStandUpEntry}
                          isCheckedInToday={isCheckedInToday}
                        />
                        // </SortableItem>
                      ))}
                      {isCheckedInToday &&
                        fields?.length === todaysStandUpEntry?.today?.count && (
                          <Button
                            add
                            className="add-btn"
                            onClick={() => {
                              add();
                              setAddTask((prev) => !prev);
                            }}
                            icon={
                              <img
                                className="add-btn-img"
                                src={PlusCircle}
                                alt="icon"
                                height={20}
                                width={20}
                              />
                            }
                            name="Add tasks"
                          />
                        )}
                    </div>
                    // </SortableContainer>
                  )}
                </Form.List>
              </Card>
              {showError && <ErrorText>This field is required!</ErrorText>}
            </Form.Item>
            <Divider />
            <Form.Item
              label={
                <>
                  <Row>
                    <Col>
                      {standUpQuestions?.[2]?.questionText || (
                        <FormattedMessage id="standUps.formLabel.progress" />
                      )}
                    </Col>
                  </Row>
                  <Row align="middle">
                    <Col className="main-icon">
                      <img
                        src={BlockerPrimary}
                        alt="icon"
                        height={20}
                        width={20}
                      />
                    </Col>
                  </Row>
                </>
              }
            >
              <Card className="task-card">
                <Form.List name="blockers">
                  {(fields, { add, remove, move }) => (
                    // <SortableContainer
                    //   onSortEnd={({ oldIndex, newIndex }) =>
                    //     move(oldIndex, newIndex)
                    //   }
                    //   useDragHandle
                    //   helperClass="sortableHelper"
                    // >
                    <div className="multiple-inputs">
                      {map(fields, (field, index) => (
                        // <SortableItem key={`item-${index}`} index={index}>
                        <MultipleInput
                          field={field}
                          add={add}
                          remove={remove}
                          form={form}
                          listName="blockers"
                          tasksLength={fields?.length}
                          showGroups
                          questionId={standUpQuestions?.[2]?.id}
                          disabled={index < todaysStandUpEntry?.blocker?.count}
                          data={todaysStandUpEntry}
                          color={Colors.TextDanger}
                          isCheckedInToday={isCheckedInToday}
                        />
                        // </SortableItem>
                      ))}
                      {isCheckedInToday &&
                        fields?.length ===
                          todaysStandUpEntry?.blocker?.count && (
                          <Button
                            add
                            type="text"
                            onClick={() => {
                              add();
                              setAddBlocker((prev) => !prev);
                            }}
                            className="add-btn"
                            icon={
                              <img
                                className="add-btn-img"
                                src={PlusCircle}
                                alt="icon"
                                height={20}
                                width={20}
                              />
                            }
                            name="Add more blockers"
                          />
                        )}
                    </div>
                    // </SortableContainer>
                  )}
                </Form.List>
              </Card>
            </Form.Item>
            {showMoodSelector && (
              <>
                <Divider />
                <Form.Item
                  label={
                    <>
                      <Row>
                        <Col>
                          <FormattedMessage id="standUps.formLabel.feel" />
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col className="main-icon">
                          <img src={SmilingFace} alt="icon" />
                        </Col>
                      </Row>
                    </>
                  }
                  rules={[
                    {
                      required: !isCheckedInToday,
                      message: 'This field is required!',
                    },
                  ]}
                  name="mood"
                  className="moods"
                >
                  <Card padding="8px 22" className="emoji-card">
                    <div className="emojis">
                      {map(EMOJIS, (emoji, index) => (
                        <EmojiWrapper
                          isDisabled={isCheckedInToday}
                          key={index}
                          className={
                            selectedEmoji === index ? 'active' : 'focus-btn'
                          }
                          onClick={() => handleEmoji(index)}
                          tabindex="0"
                          type="button"
                        >
                          <Emoji set="apple" emoji={emoji} size={20} />
                        </EmojiWrapper>
                      ))}
                    </div>
                  </Card>
                </Form.Item>
              </>
            )}
          </Card>
          {!isCheckedInToday ? (
            <Row className="btn">
              <Col>
                <Button
                  name="Check In"
                  htmlType="submit"
                  loading={btnLoading}
                />
              </Col>
            </Row>
          ) : (
            <Row className="btn">
              <Col>
                <Button
                  name="Re-Check In"
                  loading={btnLoading}
                  onClick={showModal}
                  disabled={!canRecheckIn}
                />
              </Col>
            </Row>
          )}
        </Form>
      )}
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        width="480px"
        bordered
        title={
          <Row gutter={8}>
            <Col>
              <img className="blocker-img" src={AlertDark} alt="icon" />
            </Col>
            <Col className="recheck-text">You’re Re-checking the Stand-up</Col>
          </Row>
        }
        footer={
          <>
            <Button secondary name="Cancel" onClick={() => handleCancel()} />
            <Button primary name="Re-checkin" onClick={() => form.submit()} />
          </>
        }
        content={
          <div className="modal-content">
            Your Managers will be notified with your added tasks. Are you sure?
          </div>
        }
      ></Modal>
    </Wrapper>
  );
};

export default StandUpForm;
