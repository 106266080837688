import React from 'react';
import { Popover, Skeleton } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  USER_ACTION,
} from 'utils/constants';
import CommentsPopover from 'organisms/CommentsPopover';
import Table from 'atoms/Table';
import UserProfile from 'molecules/UserProfile';
import UsersActions from 'atoms/UsersActions';
import GroupTag from 'atoms/GroupTag';
import DateTime from 'atoms/DateTime';
import Hours from 'atoms/Hours';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'atoms/Loader';
import ContentWrapper from 'atoms/ContentWrapper';
import { Checked } from 'assets';
import { InfiniteScrollTableWrapper } from 'design/Styled';
import {
  Content,
  Comments,
  Title,
  LoaderDiv,
} from './AntStyled';

export default function (props) {
  const {
    dailyGoals, loading, onActions, archive, showReason, setSortData, handleInfiniteOnLoad, loader, hasMore, loginUser, handleGroupOrUserFilterChange, dataCount,
  } = props;

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      className: 'check-mark no-padding',
      align: 'center',
      render: isCompleted => isCompleted && <img src={Checked} alt="check" />,
    },
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
      // width: '30%',
      // ellipsis: true,
      render: (text, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : <Title>{text}</Title>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Estimate',
      dataIndex: 'estimation',
      key: 'estimation',
      width: '9%',
      render: (estimation, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : estimation ? <Hours estTime={estimation} /> : '-',
    },
    {
      title: 'Spent',
      dataIndex: 'completionHours',
      key: 'completionHours',
      width: '9%',
      render: (completionHours, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : completionHours ? <Hours estTime={completionHours || ''} /> : '-',
    },
    {
      title: 'Created By',
      dataIndex: 'user',
      key: 'user',
      width: '15%',
      render: (user, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : user ? (
        <UserProfile
          profile={user.profileImage}
          name={user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email}
          userId={user.id}
          showLink
        />
      ) : '-',
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      width: '11%',
      render: (group, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : <GroupTag {...group} />,
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : <DateTime date={date} />,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: 'Completed on',
      dataIndex: 'completedOn',
      key: 'completedOn',
      render: (date, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : <DateTime date={date} />,
      // sorter: (a, b) => a.completedOn === null || b.completedOn === null ? a.completedOn - b.completedOn : a.completedOn.localeCompare(b.completedOn),
    },
    {
      dataIndex: 'reason',
      key: 'reason',
      width: showReason ? '4.5%' : '0%',
      className: !showReason ? 'no-padding' : '',
      render: (row, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : rowData.isArchive && (
        <Popover content={rowData.reason} title="Reason">
          <InfoCircleOutlined className="infoicon" />
        </Popover>
      ),
    },
    {
      dataIndex: 'comments',
      key: 'comments',
      width: '5.5%',
      render: (row, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : <Comments><CommentsPopover actionId={rowData.id} data={rowData.comments} groupId={rowData.group} /></Comments>,
    },
    {
      // align: 'center',
      dataIndex: 'action',
      width: '4%',
      key: 'action',
      render: (row, rowData) => (
        rowData.canArchive
        && (
          <UsersActions
            rowData={rowData}
            actions={archive ? [USER_ACTION.ARCHIVE_TASK] : [USER_ACTION.RESTORE_DAILY_GOAL]}
            onClickEvent={onActions}
          />
        )
      ),
    },
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];

  function onChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (dataCount > dailyGoals.length) {
        handleGroupOrUserFilterChange();
        if (sorter.order === 'ascend') {
          setSortData({ field: sorter.field, type: 'ASC' });
        } else {
          setSortData({ field: sorter.field, type: 'DESC' });
        }
      }
    }
  }

  return (
    <ContentWrapper>
      <Content>
        <InfiniteScrollTableWrapper>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={!loader && hasMore}
            useWindow={false}
            threshold={20}
            loader={dailyGoals.length && <LoaderDiv><Loader size="medium" /></LoaderDiv>}
          >
            <Table
              columns={columns}
              dataSource={dailyGoals}
              height="calc(100vh - 96px - 60px)"
              loading={loading}
              onChange={onChange}
              isInfinite
              className="bb-0"
            />
          </InfiniteScroll>
        </InfiniteScrollTableWrapper>
      </Content>
    </ContentWrapper>
  );
}
