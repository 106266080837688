import {
  MicrosoftTeams,
  GoogleChat,
  SlackIcon,
  GitHub,
  GitLab,
  BitBucket,
  Clickup,
  Zendesk,
  Jira,
  Asana,
} from 'assets';
export const IntegrationsData = {
  google_chat: {
    id: 1,
    key: 1,
    text: '#Daily Stand-Up Reports',
    title: {
      icon: GoogleChat,
      heading: 'Google Chat',
    },
    info:
      'Add undiffer bot to google chat channels and get reports & summary for selected features.',
    image:
      'https://images.unsplash.com/photo-1493119508027-2b584f234d6c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
    infoConnected:
      'Select features for which you would like to recieve updates/reports in corresponding channel.',
  },
  microsoft_teams: {
    id: 2,
    key: 2,
    text: '#Daily Stand-Up Reports',
    title: {
      icon: MicrosoftTeams,
      heading: 'Microsoft Teams',
    },
    info:
      'Add undiffer bot to google chat channels and get reports & summary for selected features.',
    image:
      'https://images.unsplash.com/photo-1493119508027-2b584f234d6c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
    infoConnected:
      'Select features for which you would like to recieve updates/reports in corresponding channel.',
  },
  slack: {
    id: 3,
    key: 3,
    text: '#Daily Stand-Up Reports',
    title: {
      icon: SlackIcon,
      heading: 'Slack',
    },
    info:
      'Add undiffer bot to slack channels and get reports & summary for selected features.',
    image:
      'https://images.unsplash.com/photo-1493119508027-2b584f234d6c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
    infoConnected:
      'Select features for which you would like to recieve updates/reports in corresponding channel.',
  },
};
export const ActivityData = {
  github: {
    id: 3,
    key: 3,
    text: '#Daily Stand-Up Reports',
    title: {
      icon: GitHub,
      heading: 'GitHub',
    },
    info:
      'Receive activity updates for your team’s GitHub push and pull requests.',
    image:
      'https://images.unsplash.com/photo-1493119508027-2b584f234d6c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
    infoConnected:
      'Select features for which you would like to recieve updates/reports in corresponding channel.',
    link: 'https://github.com',
    path: 'user  >  repo_name  >  Settings  >  Webhooks  >  Add Webhook',
  },
  gitlab: {
    id: 3,
    key: 3,
    text: '#Daily Stand-Up Reports',
    title: {
      icon: GitLab,
      heading: 'GitLab',
    },
    info:
      'Receive activity updates for your team’s Gitlab push and pull requests.',
    image:
      'https://images.unsplash.com/photo-1493119508027-2b584f234d6c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
    infoConnected:
      'Select features for which you would like to recieve updates/reports in corresponding channel.',
    link: 'https://gitlab.com',
    path: 'user  >  repo_name  >  Settings  >  Webhooks  >  Add Webhook',
  },
  bitbucket: {
    id: 3,
    key: 3,
    text: '#Daily Stand-Up Reports',
    title: {
      icon: BitBucket,
      heading: 'BitBucket',
    },
    info:
      'Receive activity updates for your team’s BitBucket push and pull requests.',
    image:
      'https://images.unsplash.com/photo-1493119508027-2b584f234d6c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
    infoConnected:
      'Select features for which you would like to recieve updates/reports in corresponding channel.',
    link: 'https://bitbucket.org',
    path: 'user  >  repo_name  >  Settings  >  Webhooks  >  Add Webhook',
  },
  clickup: {
    id: 3,
    key: 3,
    text: '#Daily Stand-Up Reports',
    title: {
      icon: Clickup,
      heading: 'ClickUp',
    },
  },
  zendesk: {
    id: 3,
    key: 3,
    text: '#Daily Stand-Up Reports',
    title: {
      icon: Zendesk,
      heading: 'Zendesk',
    },
  },
  asana: {
    id: 4,
    key: 4,
    text: '#Daily Stand-Up Reports',
    title: {
      icon: Asana,
      heading: 'Asana',
    },
  },
  jira: {
    id: 5,
    key: 5,
    text: '#Daily Stand-Up Reports',
    title: {
      icon: Jira,
      heading: 'Jira',
    },
  },
};
