import styled from 'styled-components';
import { Layout, Checkbox, Form, TimePicker as AntTimePicker } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  &.ant-layout-content {
    padding-bottom: 0;
    .ant-btn-primary {
      line-height: 22px;
    }
  }
  .heading {
    padding-bottom: ${Spacings.xSmall.padding};
  }
  .icon {
    display: flex;
    align-items: center;
    padding-right: ${Spacings.xxSmall.padding};
  }
  .back-icon {
    border-radius: 4px;
    background: linear-gradient(#6fbaff, rgba(0, 0, 0, 0.4));
    color: ${Colors.TextLight};
    cursor: pointer;
  }
  .ant-checkbox-group-item {
    margin-bottom: 8px;
    min-width: 170px;
  }
  .ant-checkbox + span {
    font: ${fonts.xSmall.regular};
    line-height: 20px;
    color: ${Colors.TextSecondary};
  }
  .right {
    float: right;
    margin-bottom: 0;
    width: fit-content;
  }
  .card {
    max-width: 700px;
    margin-bottom: 24px;
  }
  .checkbox-group {
    padding-left: 30px;
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
  }
`;

export const StyledForm = styled(Form)`
  .ant-form-item-label {
    padding: 0px;
  }
`;

export const Title = styled.div`
  font: ${fonts.xxLarge.bold};
  color: ${Colors.TextPrimary};
  text-align: center;
  line-height: 41px;
  margin-bottom: ${Spacings.xxSmall.margin};
`;

export const TimePicker = styled(AntTimePicker)`
  width: 100%;
  padding: 5px 16px;
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  display: flex;
  align-items: center;
  font: ${fonts.small.regular};
  .ant-picker-input > input {
    font: ${fonts.small.regular};
    color: ${Colors.TextSecondary};
  }
  @media screen and (max-width: 683px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  font-size: ${fonts.small.regular};
  line-height: 22px;
  text-align: center;
  padding: 0px 0px 0px;
  color: ${Colors.TableTh};
`;
export const Category = styled.p`
  font: ${fonts.medium.bold};
  line-height: 22px;
  padding-right: 24px;
  margin-bottom: 0;
  color: ${Colors.TextDark};
`;
export const Total = styled.p`
  font-size: ${fonts.small.regular};
  line-height: 16px;
  text-align: center;
  padding: 0px 0px 10px;
  margin-bottom: 0;
  color: ${Colors.ButtonSecondaryClicked};
`;
