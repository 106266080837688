import { gql } from '@apollo/client';
import {
  userFragment,
  standUpAnswerFragment,
  standUpFragment,
} from './Fragments';

export const GET_STANDUP = gql`
  query standUpEntry($where: standUpEntryWhereInput) {
    standUpEntry(where: $where) {
      ...StandUpFragment
    }
  }
  ${standUpFragment}
`;

export const GET_CURRENT_STANDUP = gql`
  query currentStandUpEntry {
    currentStandUpEntry {
      isFirstTimeCheckIn
      standUp {
        id
        moodSelector
        standUpQuestions {
          id
          questionText
          type
        }
      }
      todayStandUpEntry {
        ...StandUpFragment
      }
      previousStandUpEntry {
        id
        today {
          count
          standUpAnswers {
            id
            content {
              task
              isChecked
              isPrevious
            }
            checked
          }
        }
      }
    }
  }
  ${standUpFragment}
`;

export const GET_TEAM_CHECKINS = gql`
  query standUpEntries($where: standUpEntriesWhereInput!) {
    standUpEntries(where: $where) {
      id
      mood
      status
      createdAt
      updatedAt
      allowed
      yesterday {
        count
        standUpAnswers {
          ...StandUpAnswerFragment
        }
      }
      today {
        count
        standUpAnswers {
          ...StandUpAnswerFragment
        }
      }
      blocker {
        count
        standUpAnswers {
          ...StandUpAnswerFragment
        }
      }
      user {
        ...UserFragment
      }
    }
  }
  ${userFragment}
  ${standUpAnswerFragment}
`;

export const GET_STANDUP_SETTINGS = gql`
  query standUp {
    standUp {
      id
      moodSelector
      reminderAt
      standUpQuestions {
        id
        questionText
        type
      }
      summaryAt
      reminderTimezoneType
      reminderTimezone
      summaryTimezone
      summaryTimezoneType
      reminder
      summary
    }
  }
`;
