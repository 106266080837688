import { gql } from '@apollo/client';
export const ARCHIVE_TODOS = gql`
  mutation updateTodo($where:todoWhereUniqueInput,$data:updateTodoInput){
    updateTodo(where:$where, data:$data){
      id
    }
  }
`;

export const ADD_TO_TODOS = gql`
  mutation addTodo($data:addTodoInput){
    addTodo(data:$data){
      id
    }
  }
`;
