/* eslint-disable indent */
import React from 'react';
import {
  Router, Switch, Route, withRouter,
} from 'react-router-dom';
import { PRIVATE_ROUTE } from 'utils/constants';
import NewSurvey from './Survey/NewSurvey';
import AddSurvey from './Survey/AddSurvey';
import FeedBackPage from './FeedBackPage';
import Questions from './Survey/Questions';
import AddPoll from './Polls/AddPoll';
import NewPoll from './Polls/NewPoll';
import PollQuestions from './Polls/PollQuestions';
import Survey from './Survey';
import Polls from './Polls';
import Page404 from 'pages/404';
import ability from 'utils/ability/ability';

const FeedBack = ({ history }) => (
  <Router history={history}>
    <Switch>
      {ability.can('manage', 'feedbacks') &&
        <Route exact path={PRIVATE_ROUTE.SURVEY_NEW} component={NewSurvey} />}

      {ability.can('manage', 'feedbacks') &&
        <Route exact path={PRIVATE_ROUTE.SURVEY_CREATE} component={AddSurvey} />}

      {ability.can('manage', 'feedbacks') &&
        <Route exact path={PRIVATE_ROUTE.SURVEY_EDIT} component={AddSurvey} />}

      {ability.can('manage', 'feedbacks') &&
        <Route exact path={PRIVATE_ROUTE.SURVEY_QUESTIONS} component={Questions} />}

      {ability.can('manage', 'feedbacks') &&
        <Route exact path={PRIVATE_ROUTE.POLL_NEW} component={NewPoll} />}

      {ability.can('manage', 'feedbacks') &&
        <Route exact path={PRIVATE_ROUTE.POLL_CREATE} component={AddPoll} />}

      {ability.can('manage', 'feedbacks') &&
        <Route exact path={PRIVATE_ROUTE.POLL_EDIT} component={AddPoll} />}

      {ability.can('manage', 'feedbacks') &&
        <Route exact path={PRIVATE_ROUTE.POLL_QUESTIONS} component={PollQuestions} />}

      {ability.can('use', 'feedbacks_feature') &&
        <Route path={PRIVATE_ROUTE.SURVEY} component={Survey} />}

      {ability.can('use', 'feedbacks_feature') &&
        <Route path={PRIVATE_ROUTE.POLLS} component={Polls} />}

      <Route path={PRIVATE_ROUTE.FEED_BACK} component={FeedBackPage} />

      <Route exact path="*" render={() => <Page404 />} />
    </Switch>
  </Router>
);

export default {
  component: withRouter(FeedBack),
  key: PRIVATE_ROUTE.FEED_BACK,
  defaultPath: PRIVATE_ROUTE.FEED_BACK_CURRENT,
  name: 'Feedback',
  icon: (
    <i
      data-eva="person-done-outline"
      data-eva-height="20"
      data-eva-width="20"
    >
    </i>
  ),
  abilitySubject: 'feedbacks_feature',
  abilityAction: 'use',
};
