/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, createElement } from 'react';
import _map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import {
  Col, Form, Row, Comment, Tooltip, Rate,
} from 'antd';
import Input from 'atoms/Input';
import {
  DislikeOutlined,
  LikeOutlined,
  DislikeFilled,
  LikeFilled,
} from '@ant-design/icons';
import {
  ArrowIcon, Selected, PlusPrimary, MinusCircle,
} from 'assets';
import { QUESTION_TYPE } from 'utils/constants';
import Button from 'atoms/Button';
import {
  Select, Arrow, Delete, GlobalStyles, StyledImg,
} from './AntStyled';
import { pollType } from '../MockData';
const { Option } = Select;

export default function EmptyQuestion({ name, form }) {
  const [selectedType, setSelectedType] = useState(QUESTION_TYPE.SINGLE_CHOICE);
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [action, setAction] = useState(null);
  useEffect(() => {
    setSelectedType(form.getFieldValue(['questions', 'questionType']));
  }, [form, name]);

  const like = () => {
    setLikes(1);
    setDislikes(0);
    setAction('liked');
  };

  const dislike = () => {
    setLikes(0);
    setDislikes(1);
    setAction('disliked');
  };

  const description = {
    SINGLE_CHOICE: {
      val: 'Select one item from a list',
    },
    MULTIPLE_CHOICE: {
      val: 'Select one or more items from a list',
    },
    LIKE: {
      val: 'Give a thumbs up or thumbs down to each item',
    },
    RATE: {
      val: 'Rate each item 1 to 5 stars',
    },
  };

  const actions = [
    <Tooltip key="comment-basic-like" title="Like">
      <span onClick={like}>
        {createElement(action === 'liked' ? LikeFilled : LikeOutlined)}
        <span className="comment-action">{likes}</span>
      </span>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <span onClick={dislike}>
        {React.createElement(
          action === 'disliked' ? DislikeFilled : DislikeOutlined
        )}
        <span className="comment-action">{dislikes}</span>
      </span>
    </Tooltip>,
  ];

  const pollTypeChildren = _map(pollType, (list, index) => (
    <Option key={index} value={list?.value}>
      <GlobalStyles />
      <Row justify="space-between" className="row max-width">
        <Col>
          <div className="div-1">{list?.role}</div>
          <div className="div-2">{list?.desc}</div>
        </Col>
        <Col>
          {selectedType === list?.value && <StyledImg src={Selected} />}
        </Col>
      </Row>
    </Option>
  ));

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <div className="question">
      <Form.Item
        name={[name, 'questionType']}
        label={<FormattedMessage id="feedback.emptyQuestion.PollType" />}
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select
          showSearch
          showArrow
          placeholder="Select your type of Poll"
          onChange={v => setSelectedType(v)}
          suffixIcon={<Arrow src={ArrowIcon} />}
          getPopupContainer={() => document.getElementById('drop')}
        >
          {pollTypeChildren}
        </Select>
      </Form.Item>
      <p className="description">{description[selectedType]?.val}</p>
      <Row>
        <Col span={24}>
          <Form.Item
            name={[name, 'question']}
            label={
              <FormattedMessage id="feedback.emptyQuestion.questionLabel" />
            }
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input placeholder="Ask Question" />
          </Form.Item>
        </Col>
      </Row>
      {selectedType === QUESTION_TYPE.RATE ? (
        <div className="noEvent">
          <div className="label">
            <FormattedMessage id="feedback.emptyQuestion.Rate" />
          </div>
          <Rate defaultValue={3} />
        </div>
      ) : selectedType === QUESTION_TYPE.LIKE ? (
        <div className="icons noEvent">
          <div className="label">
            <FormattedMessage id="feedback.emptyQuestion.Comments" />
          </div>
          <Comment actions={actions} />
        </div>
      ) : selectedType === (QUESTION_TYPE.SINGLE_CHOICE || QUESTION_TYPE.MCQ) ? (
        <div>
          <div className="label">
            <FormattedMessage id="feedback.emptyQuestion.Options" />
          </div>
          <Form.List name={[name, 'choices']}>
            {(fields, { add, remove }) => (
              <div>
                {_map(fields, (field, index) => (
                  <Row key={index} className="addChoice">
                    <Col span={14}>
                      <Form.Item
                        className="input-mcq"
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className="col-icon" span={1}>
                      {fields?.length > 2 && (
                        <Delete
                          src={MinusCircle}
                          onClick={() => remove(field?.name)}
                        />
                      )}
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    add
                    onClick={() => {
                      add();
                    }}
                    name={(
                      <>
                        <img
                          className="plus"
                          src={PlusPrimary}
                          alt="icon-add"
                        />
                        <FormattedMessage id="feedback.emptyQuestion.AddChoice" />
                      </>
                    )}
                  />
                </Form.Item>
              </div>
            )}
          </Form.List>
        </div>
      ) : null}
    </div>
  );
}
