import React, { useEffect, useState } from 'react';
import { Modal as AntModal } from 'antd';
import styled from 'styled-components';
import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
import { fonts } from 'design/Fonts';
import { scrollbars } from 'design/Styled';
import Variables from 'design/Variables';

const Modal = styled(AntModal)`
  .ant-modal-body {
    width: ${({ width }) => width || '90vw'};
    max-width: 90vw;
    ${scrollbars};
    position: relative;
    padding: ${Spacings.xSmall.padding} ${Spacings.xSmall.padding} 0px;
    .modal-content {
      font: ${fonts.medium.regular};
      color: ${Colors.TextSecondary};
      line-height: 26px;
    }
  }
  .ant-modal-header {
    padding: ${({ footerPadding }) =>
      !footerPadding
        ? 0
        : `${Spacings.xSmall.margin} ${Spacings.xSmall.margin} 0`};
    border-bottom: ${({ bordered }) =>
      bordered ? '1px solid #CAD6E3' : '0px'};
    padding-bottom: ${({ bordered }) => (bordered ? '16px' : '0px')};
  }
  .ant-modal-footer {
    padding: ${({ footerPadding }) =>
      !footerPadding
        ? 0
        : `${Spacings.xSmall.margin} ${Spacings.xSmall.margin}`};
    border-top: 0px;
  }

  .ant-modal-title {
    font: ${fonts.medium.bold};
    color: ${Colors.TextDark};
    line-height: ${Variables.LineHeightL};
  }

  .ant-modal-content {
    max-height: calc(100vh - 120px);
    overflow: auto;
    border-radius: ${Variables.AtomRadius};
    background-clip: inherit;
  }
`;

export default function (props) {
  const {
    closeModal,
    closable = true,
    content,
    footer,
    height,
    maxHeight,
    title,
    width,
    onOk,
    className,
    bordered,
    visible = true,
    ...restProps
  } = props;
  const [open, setOpen] = useState(visible);

  useEffect(() => {
    if (visible) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [visible]);

  const handleCancel = () => {
    setOpen(false);
    setTimeout(() => {
      closeModal();
    }, 1000);
  };

  return (
    <Modal
      className={className}
      title={title}
      visible={open}
      onCancel={closable && handleCancel}
      closable={closable}
      onOk={onOk}
      width={width}
      height={height}
      maxHeight={maxHeight}
      bordered={bordered}
      footerPadding={!!footer}
      footer={footer === 'default' ? undefined : [footer]}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 0,
        left: 0,
        top: 0,
      }}
      {...restProps}
    >
      {content}
    </Modal>
  );
}
