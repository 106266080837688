import { Layout } from 'antd';
import Card from 'atoms/Card';
import React from 'react';
import { Content, Wrapper } from './AntStyled';

function SopSettings() {
  return (
    <Layout>
      <Content>
        <Wrapper>
          <Card padding="16" className="card">
          </Card>
        </Wrapper>
      </Content>
    </Layout>
  );
}

export default SopSettings;
