import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Col } from 'antd';
import _map from 'lodash/map';
import Table from 'atoms/Table';
import Card from 'atoms/Card';
import * as Sentry from '@sentry/react';
import { Content, InputRow } from './AntStyled';

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  updateData,
  inLineEditable,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      // eslint-disable-next-line no-unused-expressions
      inputRef?.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if (values[dataIndex] !== record[dataIndex]) {
        updateData({ ...record, ...values });
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      inLineEditable(dataIndex, inputRef, save, title)
    ) : (
      <div role="button" tabIndex={0} onClick={toggleEdit}>
        {children}
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <td {...restProps}>{childNode}</td>;
};

export default function (props) {
  const {
    columns,
    children,
    dataSource,
    updateData,
    inLineEditable,
    height,
    loading,
    border,
    editableData,
    scroll,
    onChange,
    className,
    noForm,
  } = props;

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const tableColumns = _map(columns, (col) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: editableData
          ? !record[editableData] && col?.editable
          : col?.editable,
        dataIndex: col?.dataIndex,
        title: col?.title,
        updateData,
        inLineEditable: (dataIndex, inputRef, save) =>
          inLineEditable(dataIndex, inputRef, save, record),
      }),
    };
  });

  return (
    <Content border={false}>
      <Table
        height={height}
        components={components}
        dataSource={dataSource}
        columns={tableColumns}
        loading={loading}
        scroll={scroll}
        onChange={onChange}
        className={className}
      />
      {!noForm && (
        <InputRow border={false}>
          <Col span={24}>{children}</Col>
        </InputRow>
      )}
    </Content>
  );
}
