import React, { useContext, useEffect, useState } from 'react';
import { Col, Layout, Row, Form, Checkbox, Spin, message } from 'antd';
import Card from 'atoms/Card';
import Button from 'atoms/Button';
import {
  BUTTON_TYPE,
  FETCH_POLICY,
  EMPTY_ARRAY,
  AMPLITUDE_EVENTS,
} from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'antd/lib/form/util';
import moment from 'moment';
import _get from 'lodash/get';
import { AppContext } from 'AppContext';
import * as eva from 'eva-icons';
import * as Sentry from '@sentry/react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { createAmplitudeEvent } from 'utils/amplitude';
import _map from 'lodash/map';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import ContentWrapper from 'atoms/ContentWrapper';
import {
  CREATE_QUOTE_SETTING,
  UPDATE_QUOTE_SETTING,
} from '../../graphql/Mutations';
import { GET_QUOTE_SETTING, GET_QUOTE_CATEGORIES } from '../../graphql/Queries';
import {
  Content,
  StyledForm,
  Description,
  Category,
  Total,
  TimePicker,
} from './AntStyled';
const format = 'HH:mm';

function QuotesSettings() {
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [initialSetting, setInitialSetting] = useState({});
  const [category, setCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [createQuoteSetting] = useMutation(CREATE_QUOTE_SETTING);
  const [updateQuoteSetting] = useMutation(UPDATE_QUOTE_SETTING);
  const [form] = useForm();
  const { state } = useContext(AppContext);
  const companyId = _get(state, 'loginUser.company.id', null);

  const { data: quoteSettingData, loading: quoteSettingLoading } = useQuery(
    GET_QUOTE_SETTING,
    {
      variables: {
        where: { companyId },
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    }
  );

  const { data: quoteCategoryData, loading: quoteCategoryLoading } = useQuery(
    GET_QUOTE_CATEGORIES,
    {
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    }
  );

  useEffect(() => {
    eva.replace();
  }, []);
  const onFinish = (e) => {
    const time = moment.utc(e?.time).format('HH:mm[:00.000Z]');
    const mutation = quoteSettingData?.quoteSetting
      ? updateQuoteSetting
      : createQuoteSetting;
    mutation({
      variables: {
        data: { enabledCategories: e?.categories, triggerTime: time },
      },
      refetchQueries: [
        {
          query: GET_QUOTE_SETTING,
          variables: { where: { companyId } },
          fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
        },
      ],
    })
      .then((res) => {
        const data = quoteSettingData?.quoteSetting
          ? res?.data?.updateQuoteSetting
          : res?.data?.createQuoteSetting;
        const eventProperties = {
          Id: data?.id,
          EnabledQuoteCategories: _map(
            data?.enabledQuoteCategories,
            (data) => ({
              id: data?.id,
              name: data?.name,
            })
          ),
          triggerTime: moment(data?.triggerTime, 'HH:mm').format(
            MomentFormat.time
          ),
        };
        createAmplitudeEvent(
          quoteSettingData?.quoteSetting
            ? AMPLITUDE_EVENTS.QUOTES_SETTING_UPDATED
            : AMPLITUDE_EVENTS.QUOTES_SETTING_CREATED,
          eventProperties
        );
        message.success(
          `${
            quoteSettingData?.quoteSetting ? 'Updated' : 'Created'
          } successfully`
        );
        setBtnDisabled(true);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.log(e);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  useEffect(() => {
    if (quoteSettingData && !quoteSettingLoading) {
      const triggerTime = quoteSettingData?.quoteSetting?.triggerTime;
      const time = triggerTime
        ? moment(triggerTime, 'HH:mm:00Z').utc().local().format('HH:mm')
        : null;
      const value = quoteSettingData?.quoteSetting?.enabledQuoteCategories.map(
        (enabledQuoteCategory) => enabledQuoteCategory.id
      );
      setInitialSetting({
        time: time ? moment(time, format) : null,
      });
      setCategory(value);
    }
  }, [quoteSettingData]);

  useEffect(() => {
    if (quoteCategoryData && !quoteCategoryLoading) {
      const category = quoteCategoryData?.quoteCategories.map(
        (quoteCategory) => ({
          label: quoteCategory.name,
          value: quoteCategory.id,
        })
      );
      setCategories(category);
    }
  }, [quoteCategoryData]);

  useEffect(() => form.resetFields(), [initialSetting]);
  return (
    <Layout>
      <Spin spinning={quoteSettingLoading || quoteCategoryLoading}>
        <Content>
          <Card padding="16" className="card">
            <Row>
              <Col>
                <Row>
                  <StyledForm
                    layout="vertical"
                    onFinish={onFinish}
                    form={form}
                    initialValues={initialSetting}
                    onFinishFailed={() => setBtnLoading(false)}
                    onValuesChange={() => setBtnDisabled(false)}
                    onKeyPress={(e) =>
                      e?.key === 'Enter' && e?.preventDefault()
                    }
                  >
                    <Row>
                      <Form.Item
                        name="time"
                        rules={[
                          {
                            required: true,
                            message: 'Please select time!',
                          },
                        ]}
                        label={<FormattedMessage id="quotes.time" />}
                      >
                        <TimePicker
                          minuteStep={30}
                          format={format}
                          inputReadOnly
                        />
                      </Form.Item>
                    </Row>
                    <Row align="middle" className="heading">
                      <Col className="icon">
                        <i
                          data-eva="layers-outline"
                          data-eva-height="22"
                          data-eva-width="22"
                          data-eva-fill="#5F3996"
                        ></i>
                      </Col>
                      <Col>
                        <Category>
                          <FormattedMessage id="quotes.category" />
                        </Category>
                      </Col>
                    </Row>
                    <Row className="checkbox-group">
                      <Form.Item name="categories" valuePropName="checked">
                        <Checkbox.Group
                          options={categories && categories}
                          defaultValue={category}
                        />
                      </Form.Item>
                    </Row>
                    <Row justify="end">
                      {quoteCategoryData && (
                        <Form.Item className="mb-0">
                          <Button
                            primary
                            type={BUTTON_TYPE.PRIMARY}
                            htmlType={BUTTON_TYPE.SUBMIT}
                            loading={btnLoading}
                            disabled={btnDisabled}
                            name={!initialSetting?.time ? 'Save' : 'Update'}
                            onClick={() => {
                              setBtnLoading(true);
                            }}
                          />
                        </Form.Item>
                      )}
                    </Row>
                  </StyledForm>
                </Row>
              </Col>
            </Row>
          </Card>
        </Content>
      </Spin>
    </Layout>
  );
}

export default QuotesSettings;
