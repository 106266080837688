import { MarkAsRead } from 'assets';
import Card from 'atoms/Card';
import React from 'react';

import {
  NoWidgetWrapper,
  Content,
} from './AntStyled';

function NoWidget({
  markClear, isViewed,
}) {
  return (
    <Content>
      <Card padding="16">
        <div className="no-widget">No widget found</div>
      </Card>
      {
        !isViewed
          && (
            <img
              src={MarkAsRead}
              className="mark-as-read"
              alt="mark as read"
              height={24}
              width={24}
              onClick={() => markClear()}
            />
          )
      }
    </Content>
  );
}

export default NoWidget;
