/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Form, Checkbox } from 'antd';
import Input from 'atoms/Input';
import {
  ReOrder,
  CheckMarkGreen,
  CheckMarkGrey,
  CheckboxGreenFilled,
  CheckboxGreenEmpty,
} from 'assets';
import { sortableHandle } from 'react-sortable-hoc';
import { REGEX } from 'utils/constants';
import { useMutation } from '@apollo/client';
import { CHECK_STANDUP_ENTRY } from 'features/standUp/graphql/Mutations';
import { GET_CURRENT_STANDUP } from 'features/standUp/graphql/Queries';
import { taskFormat } from 'utils/utils';
import { Wrapper, TextBox, TaskDetail } from './Antstyled';

const DragHandle = sortableHandle(() => (
  <Col className="drag-icon">
    <img className="drag-img" src={ReOrder} alt="icon" />
  </Col>
));

const MultipleInput = ({
  field,
  add,
  remove,
  form,
  listName,
  showGroups,
  tasksLength,
  questionId,
  disabled,
  showCheck,
  required,
  validationFn,
  setShowError,
  color,
  data,
  previousData,
  isCheckedInToday,
}) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isPrevious, setIsPrevious] = useState(false);
  const [checkStandUpEntry] = useMutation(CHECK_STANDUP_ENTRY);
  const inputRef = useRef();
  useEffect(() => {
    if (questionId) {
      const old = form.getFieldValue([listName]);
      if (old[field.name] === undefined) {
        old[field.name] = { standUpQuestionId: questionId };
      } else {
        old[field.name].standUpQuestionId = questionId;
      }
      form.setFieldsValue({ listName: { ...old } });
    }
  }, [questionId]);

  useEffect(() => {
    setIsCompleted(
      form.getFieldValue([listName, field.name, 'content', 'isChecked'])
    );
    setIsPrevious(
      form.getFieldValue([listName, field.name, 'content', 'isPrevious'])
    );
  }, [field]);

  useEffect(() => {
    if (listName === 'todays') {
      inputRef?.current?.focus();
    }
  }, []);

  const handleCheck = (v) => {
    if (!disabled) {
      setIsCompleted(v);
      const old = form.getFieldValue([listName]);
      if (old[field.name] === undefined) {
        old[field.name] = { content: {} };
      }
      old[field.name].content.isChecked = v;
      form.setFieldsValue({ listName: { ...old } });
    }
  };

  const handleInputChange = (e) => {
    if (!isPrevious) {
      if (e.target.value && !isCompleted) {
        handleCheck(true);
      } else if (!e.target.value && isCompleted) {
        handleCheck(false);
      }
    }
    if (required) {
      validationFn();
    }
  };

  const handleDelete = (e) => {
    if (tasksLength > 1 && e.target.value === '' && e.keyCode === 8) {
      remove(field.name);
    }
  };

  const handleEnter = (e) => {
    e.preventDefault();
    if (e?.target?.value) {
      add();
    }
  };
  const handleChange = (field, listName) => {
    const answerId = isCheckedInToday
      ? data?.[listName?.slice(0, listName?.length - 1)]?.standUpAnswers?.[
          field?.name
        ]?.id
      : previousData?.[field?.name]?.id;

    checkStandUpEntry({
      variables: {
        where: {
          id: parseInt(answerId, 10),
        },
      },
    });
  };
  return (
    <Wrapper>
      <Row align="top" className="row-input">
        <Col>
          <Row align="middle" gutter={8}>
            {!disabled && <DragHandle />}
            {showCheck && !disabled && (
              <Col className="icon-check">
                {isPrevious ? (
                  <img
                    className="pointer"
                    src={isCompleted ? CheckboxGreenFilled : CheckboxGreenEmpty}
                    alt="icon"
                    onClick={() => handleCheck(!isCompleted)}
                    key={`check-${listName}-${field.name}`}
                  />
                ) : (
                  <img
                    src={isCompleted ? CheckMarkGreen : CheckMarkGrey}
                    alt="icon"
                    key={`check-${listName}-${field.name}`}
                  />
                )}
              </Col>
            )}
          </Row>
        </Col>
        <Col flex="auto">
          <Form.Item
            name={[field.name, 'content', 'task']}
            // todo: (task validation) commented for future use
            // rules={[
            //   ({}) => ({
            //     validator(_, value) {
            //       if (!value) {
            //         return Promise?.resolve();
            //       }
            //       if (value?.length < 10) {
            //         // eslint-disable-next-line prefer-promise-reject-errors
            //         return Promise?.reject(
            //           'Minimum 2 words and 10 letters required'
            //         );
            //       }
            //       if (!REGEX.STANDUP_TASK.test(value)) {
            //         // eslint-disable-next-line prefer-promise-reject-errors
            //         return Promise?.reject(
            //           'Minimum 2 words and 10 letters required'
            //         );
            //       }
            //       return Promise?.resolve();
            //     },
            //   }),
            // ]}
          >
            {disabled ? (
              <TaskDetail className={listName !== 'yesterdays' && 'clickable'}>
                <div className="checkbox text flex-box">
                  <Checkbox
                    className="input-checkbox"
                    onChange={() => handleChange(field, listName)}
                    defaultChecked={
                      isCheckedInToday
                        ? data?.[listName?.slice(0, listName?.length - 1)]
                            ?.standUpAnswers?.[field?.name]?.checked
                        : previousData?.[field?.name]?.checked
                    }
                    disabled={isCheckedInToday && listName === 'yesterdays'}
                  >
                    {taskFormat(
                      isCheckedInToday
                        ? data?.[listName?.slice(0, listName?.length - 1)]
                            ?.standUpAnswers?.[field?.name]?.content?.task
                        : previousData?.[field?.name]?.content?.task
                    )}
                  </Checkbox>
                </div>
              </TaskDetail>
            ) : (
              <TextBox
                placeholder="Type your answer...."
                onKeyDown={handleDelete}
                onChange={handleInputChange}
                onPressEnter={handleEnter}
                bordered={false}
                autoFocus
                autoSize
                autoComplete="off"
                ref={inputRef}
                disabled={disabled}
              />
            )}
          </Form.Item>
        </Col>
        {/* Kept for future release */}
        {/* {
      showGroups &&
      <Col className="groups-widget">
        <GroupsWidgetPopover />
      </Col>
    } */}
      </Row>
    </Wrapper>
  );
};

export default MultipleInput;
