/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Col, Row } from 'antd';
import Tag from 'atoms/Tag';
import UserProfile from 'molecules/UserProfile';
import { ActivityData } from '../MockData';
import ActivityCard from '../ActivityCard';

export const ClickupMessage = (data, type = '') => (
  <Row align="middle" className="message">
    {type !== 'teams' && (
      <UserProfile
        size={20}
        stackedName={false}
        profile={data?.actor?.profilePic || data?.processedData?.actor_image}
        name={data?.actor?.name || data?.processedData?.actor_name}
        className="user-profile"
      />
    )}
    <span className="text-regular">updated the status of &nbsp;</span>
    <span
      className="heading"
      onClick={() => {
        window.open(data?.processedData?.task_url);
      }}
    >
      {data?.processedData?.task_name}
    </span>
    &nbsp;
    <span className="text-regular">to</span> &nbsp;
    {type === 'teams' ? (
      <div className="bold-text">{`"${data?.processedData?.status?.status}"`}</div>
    ) : (
      <Tag
        className="tag-text"
        color="#EFF2F6"
        content={data?.processedData?.status?.status}
      />
    )}
    &nbsp;
    <span className="bold-text">in space&nbsp;</span>
    {type === 'teams' ? (
      <div className="bold-text">{data?.processedData?.space_name}</div>
    ) : (
      <Tag className="tag-text" content={data?.processedData?.space_name} />
    )}
  </Row>
);

const ClickupCard = ({ data }) => (
  <ActivityCard
    activityDate={data?.addedAt}
    platformIcon={ActivityData?.clickup?.title?.icon}
    message={ClickupMessage(data)}
  />
);

export default ClickupCard;
