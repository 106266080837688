import React, { useEffect, useState } from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import Colors from 'design/Colors';
import locale from 'antd/es/locale/en_GB';
import { leftArrow, RightArrow, RightArrowDisabled } from 'assets';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & .ant-picker {
    padding: 0;
    .ant-picker-input > input {
      width: 210px;
      cursor: pointer;
      text-align: center;
      color: ${Colors.TextSecondary};
    }
  }
`;

const Arrow = styled.img``;

const SingleDatePicker = ({
  setSingleDatePicker,
  datePickerValue,
  disabledDate,
  showToday,
}) => {
  const todaysDate = moment().format('LL');
  const [value, setValue] = useState(datePickerValue || todaysDate);
  const [disabled, setDisabled] = useState();

  const onChange = (selectedValue) => {
    setValue(selectedValue);
    setSingleDatePicker(selectedValue);
  };

  const defaultDisabledDate = current => disabledDate
    ? disabledDate(current)
    : current && current > moment().endOf('day');

  useEffect(() => {
    setDisabled(defaultDisabledDate(moment(value).add(1, 'days')));
  }, [value]);

  return (
    <ConfigProvider locale={locale}>
      <Wrapper>
        <Arrow
          src={leftArrow}
          alt="icon"
          onClick={() => onChange(moment(value).subtract(1, 'day'))}
        />
        <DatePicker
          value={moment(value)}
          onChange={onChange}
          defaultValue={moment()}
          format={`LL [${
            moment(value).date() === moment().subtract(1, 'day').date()
              ? '(Yesterday)'
              : moment(value).date() === moment().date()
                ? '(Today)'
                : ''
          }]`}
          bordered={false}
          suffixIcon={false}
          allowClear={false}
          disabledDate={defaultDisabledDate}
          showToday={showToday}
          inputReadOnly
        />
        {disabled ? (
          <Arrow
            src={RightArrow}
            alt="icon"
            onClick={() => !disabled && onChange(moment(value).add(1, 'day'))}
          />
        ) : (
          <Arrow
            src={RightArrowDisabled}
            alt="icon"
            onClick={() => !disabled && onChange(moment(value).add(1, 'day'))}
          />
        )}
      </Wrapper>
    </ConfigProvider>
  );
};

export default SingleDatePicker;
