/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Row, Col } from 'antd';
import UserProfile from 'molecules/UserProfile';
import Tag from 'atoms/Tag';
import { ActivityData } from '../MockData';
import ActivityCard from '../ActivityCard';

export const CommitMessage = (data, type = '') => (
  <>
    <Row align="middle" className="message">
      {type !== 'teams' && (
        <Col className="flex">
          <UserProfile
            stackedName={false}
            className="user"
            size={20}
            profile={data?.processedData?.actor_image}
            name={data?.processedData?.actor_name}
            className="user-profile"
          />
        </Col>
      )}
      <span className="text-regular">committed&nbsp;</span>
      {type === 'teams' ? (
        <div className="bold-text">
          {data?.processedData?.commit_id?.slice(0, 7)}
        </div>
      ) : (
        <Tag
          className="tag-text"
          content={data?.processedData?.commit_id?.slice(0, 7)}
        />
      )}
      &nbsp;
      <span className="text-regular">to</span> &nbsp;
      {type === 'teams' ? (
        <div className="bold-text">{data?.processedData?.repository_name}</div>
      ) : (
        <Tag
          className="tag-text"
          content={data?.processedData?.repository_name}
        />
      )}
      &nbsp;
      <span className="text-regular">repository:</span> &nbsp;
      <span
        className="heading"
        onClick={() => {
          window.open(data?.processedData?.url);
        }}
      >
        “{data?.processedData?.commit_message}”
      </span>
    </Row>
  </>
);

const CommitCard = ({ data }) => (
  <ActivityCard
    activityDate={data?.addedAt}
    platformIcon={ActivityData[data?.platformKey]?.title?.icon}
    message={CommitMessage(data)}
  />
);

export default CommitCard;
