import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PRIVATE_ROUTE } from 'utils/constants';
import { LeaveIcon, RequestIcon, SummaryIcon, AttendanceMenu } from 'assets';
import { Expand, Icon } from './AntStyled';
export const sidebarMenuSection1 = [
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.DASHBOARD,
    action: 'manage',
    subject: 'teams',
    icon: (
      <i data-eva="grid-outline" data-eva-height="20" data-eva-width="20" />
    ),
    text: <FormattedMessage id="mainSidebar.dashboard" />,
  },
  //!  hiding this page because it is not researched fully or had it's correct purpose for
  // {
  //   abilityAction: 'access_team_detail_view',
  //   key: PRIVATE_ROUTE.TODOS,
  //   action: 'manage',
  //   subject: 'teams',
  //   icon: (
  //     <i
  //       data-eva="external-link-outline"
  //       data-eva-height="20"
  //       data-eva-width="20"
  //     ></i>
  //   ),
  //   text: <FormattedMessage id="mainSidebar.todos" />,
  // },
  {
    abilityAction: 'show_updates',
    key: PRIVATE_ROUTE.UPDATES,
    action: 'manage',
    subject: 'updates',
    icon: (
      <i data-eva="bulb-outline" data-eva-height="20" data-eva-width="20"></i>
    ),
    text: <FormattedMessage id="mainSidebar.updates" />,
  },
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.TEAMS,
    action: 'manage',
    subject: 'teams',
    icon: (
      <i data-eva="people-outline" data-eva-height="20" data-eva-width="20"></i>
    ),
    text: <FormattedMessage id="mainSidebar.teams" />,
  },
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.ACTIVITIES,
    action: 'manage',
    subject: 'activities',
    icon: (
      <i
        data-eva="activity-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    text: <FormattedMessage id="mainSidebar.activities" />,
  },
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.INSIGHTS,
    action: 'manage',
    subject: 'insight',
    icon: (
      <i
        data-eva="bar-chart-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    text: <FormattedMessage id="mainSidebar.insight" />,
  },
];

export const sidebarMenuSection2 = [
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.SOP_ACTIVE,
    action: 'use',
    subject: 'standard_procedures',
    icon: (
      <i
        data-eva="bar-chart-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    text: 'Sops',
  },
];
export const sidebarMenuSection3 = [
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.LEAVES,
    action: 'use',
    subject: 'people_feature',
    icon: <Icon src={LeaveIcon} alt="icon" />,
    expandIcon: (
      <Expand
        data-eva="arrow-down"
        data-eva-height="16"
        data-eva-width="16"
      ></Expand>
    ),
    text: <FormattedMessage id="mainSidebar.people" />,
  },
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.DAILY_GOALS,
    action: 'use',
    subject: 'daily_goals_feature',
    icon: (
      <i
        data-eva="checkmark-circle-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    expandIcon: (
      <Expand
        data-eva="arrow-down"
        data-eva-height="16"
        data-eva-width="16"
      ></Expand>
    ),
    text: <FormattedMessage id="mainSidebar.dailyGoals" />,
  },
];
export const sidebarSubMenu = [
  {
    abilityAction: 'show_people',
    key: PRIVATE_ROUTE.PEOPLE,
    parentKey: PRIVATE_ROUTE.LEAVES,
    action: 'manage',
    subject: 'people',
    icon: <Icon src={RequestIcon} alt="icon" />,
    text: <FormattedMessage id="mainSidebar.attendanceLeaves" />,
  },
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.LEAVES_REQUEST,
    parentKey: PRIVATE_ROUTE.LEAVES,
    action: 'manage',
    subject: 'leave request',
    icon: <Icon src={RequestIcon} alt="icon" />,
    text: <FormattedMessage id="mainSidebar.leaveRequest" />,
  },
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.LEAVES_SUMMARY,
    parentKey: PRIVATE_ROUTE.LEAVES,
    action: 'manage',
    subject: 'leave summary',
    icon: <Icon src={SummaryIcon} alt="icon" />,
    text: <FormattedMessage id="mainSidebar.leaveSummary" />,
  },
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.ME,
    parentKey: PRIVATE_ROUTE.DAILY_GOALS,
    action: 'manage',
    subject: 'me',
    icon: (
      <i data-eva="person-outline" data-eva-height="20" data-eva-width="20"></i>
    ),
    text: <FormattedMessage id="mainSidebar.me" />,
  },
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.DAILY_GOALS_ACTIVE,
    parentKey: PRIVATE_ROUTE.DAILY_GOALS,
    action: 'manage',
    subject: 'daily goals',
    icon: (
      <i
        data-eva="checkmark-circle-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    text: <FormattedMessage id="mainSidebar.teamGoals" />,
  },
  {
    abilityAction: 'access_team_detail_view',
    key: PRIVATE_ROUTE.BLOCKER_ACTIVE,
    parentKey: PRIVATE_ROUTE.DAILY_GOALS,
    action: 'manage',
    subject: 'blocker',
    icon: (
      <i
        data-eva="alert-triangle-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    text: <FormattedMessage id="mainSidebar.teamBlockers" />,
  },
];
