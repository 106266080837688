import { gql } from '@apollo/client';
import {
  userFragment,
  groupFragment,
  commentFragment,
  activityLogFragment,
} from './Fragments';

export const GET_ALL_SOPS = gql`
  query getAllSops($where: SOPWhereInput) {
    getAllSops(where: $where) {
      id
      title
      description
      departments {
        ...GroupFragment
      }
      comments {
        ...CommentFragment
      }
      body
      writtenBy {
        ...UserFragment
      }
      version
      status
      isTemplate
      updatedAt
      createdAt
    }
  }
  ${userFragment}
  ${groupFragment}
  ${commentFragment}
`;

export const GET_SOP_BY_ID = gql`
  query getAllSops($where: SOPWhereUniqueInput) {
    getSop(where: $where) {
      id
      title
      description
      departments {
        ...GroupFragment
      }
      comments {
        ...CommentFragment
      }
      activityLogs {
        ...ActivityLogFragment
      }
      body
      writtenBy {
        ...UserFragment
      }
      version
      status
      isTemplate
      updatedAt
      createdAt
    }
  }
  ${userFragment}
  ${groupFragment}
  ${commentFragment}
  ${activityLogFragment}
`;
