import { Layout } from 'antd';
import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  .ant-form-item textarea.ant-input {
    border-radius: 8px;
  }
  .title {
    font: ${fonts.xSmall.regular};
    color: ${Colors.Text};
    line-height: 20px;
    margin: 0px;
  }
  .message {
    font: ${fonts.small.regular};
    color: ${Colors.TextDark};
    margin-bottom: calc(${Spacings.Small.margin} - 2px);
    line-height: 22px;
  }
  .text-tag {
    &.ant-tag {
      border: 1px solid ${Colors.Border};
      box-sizing: border-box;
      border-radius: 4px;
      background: transparent;
      font: ${fonts.small.regular};
      color: ${Colors.Text};
      padding: 2px 8px;
    }
  }
  .heading {
    color: ${Colors.TextDark};
    font: ${fonts.medium.bold};
    line-height: 24px;
    margin-bottom: calc(${Spacings.xSmall.margin} - 4px);
  }
  .card {
    margin-bottom: ${Spacings.small.margin};
    .ant-card-head {
      background: ${Colors.DefaultBackground};
      border-bottom: 1px solid ${Colors.Border};
    }
  }
  .ant-form-item {
    margin-bottom: ${Spacings.xSmall.margin};
    font-size: ${fonts.medium.size};
    &:nth-last-child(2) {
      margin-bottom: ${Spacings.small.margin};
    }
  }
  .ant-row.ant-form-item:last-of-type {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
  .ant-radio-wrapper {
    color: ${Colors.Radio};
  }
  .ant-radio-wrapper-checked {
    color: ${Colors.PrimaryColor};
  }
  .ant-card-head-title{
    font: ${fonts.xSmall.bold};
  } 
`;
