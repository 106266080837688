/* eslint-disable indent */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import _get from 'lodash/get';
import Colors from 'design/Colors';
import Button from 'atoms/Button';
import UserActionHeader from 'atoms/UserActionHeader';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'AppContext';
import * as eva from 'eva-icons';
import { findIndex } from 'lodash';
import {
  POPOVER_TYPE,
  FETCH_POLICY,
  EMPTY_ARRAY,
  ACTIONS_TYPE,
} from 'utils/constants';
import client from 'apollo';
import { GET_NOTIFICATIONS } from 'graphql/Notifications/Queries';
import {
  UPDATE_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
} from 'graphql/Notifications/Mutations';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import * as Sentry from '@sentry/react';
import { NoNotification } from 'assets';
import InfiniteScroll from 'react-infinite-scroller';
import { LoaderDiv } from 'features/dailyGoals/templates/DailyGoals/AntStyled';
import Loader from 'atoms/Loader';
import New from './New';
import {
  Content,
  ContentTitle,
  Drawer,
  NoNotificationWrapper,
  SpinWrapper,
  ButtonWrapper,
  ImageWrapper,
  Image,
} from './AntStyled';

const Notifications = (props) => {
  const appContext = useContext(AppContext);
  const { closeNotificationModal } = props;
  const [notifications, setNotifications] = useState(EMPTY_ARRAY);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState('new');
  const [dataCount, setDataCount] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const { dispatch } = appContext;
  const PAGE_LIMIT = 10;

  const { data, loading: dataLoading } = useQuery(GET_NOTIFICATIONS, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    variables: { where: { isRead: active !== 'new' }, limit: PAGE_LIMIT, skip },
  });

  const onClose = () => {
    setVisible(false);
    setTimeout(() => {
      closeNotificationModal();
    }, 1000);
  };

  useEffect(() => {
    eva.replace();
  }, []);

  useEffect(() => {
    if (data && !dataLoading) {
      const notificationsValues = [
        ...notifications,
        ...data?.notifications?.notifications,
      ];
      setNotifications(notificationsValues);
      setDataCount(data?.notifications?.count);
    }
  }, [data, dataLoading]);

  const handleInfiniteOnLoad = () => {
    if (!dataLoading) {
      setLoading(true);
      if (dataCount <= notifications?.length) {
        setHasMore(false);
        setLoading(false);
        setPage(1);
      } else {
        setPage((prev) => prev + 1);
        const count = page * PAGE_LIMIT;
        setSkip(count);
      }
    }
  };

  const resetNotificationsData = () => {
    setHasMore(true);
    setNotifications([]);
    setSkip(0);
    setPage(1);
  };

  const decreaseNotification = (id) => {
    const oldData = [...notifications];
    const index = findIndex(oldData, (item) => item?.id === id);
    if (index !== -1) {
      oldData.splice(index, 1);
      setNotifications([...oldData]);
      dispatch({ type: ACTIONS_TYPE.DECREASE_NOTIFICATION_COUNT });
    }
  };

  const onCheck = (index) =>
    client
      .mutate({
        mutation: UPDATE_NOTIFICATION,
        variables: {
          data: { isRead: true },
          where: { id: parseInt(index, 10) },
        },
      })
      .then(() => {
        dispatch({ type: ACTIONS_TYPE.DECREASE_NOTIFICATION_COUNT });
        decreaseNotification(index);
      })
      .catch((e) => {
        console.log(e);
      });

  const clearAllNotifications = () => {
    setLoading(true);
    client
      .mutate({
        mutation: CLEAR_ALL_NOTIFICATIONS,
      })
      .then(() => {
        setLoading(false);
        resetNotificationsData();
        dispatch({
          type: ACTIONS_TYPE.INCREASE_NOTIFICATION_COUNT,
          data: { count: 0 },
        });
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const handleTabChange = (tab) => {
    if (active !== tab) {
      setNotifications(EMPTY_ARRAY);
      setActive(tab);
      resetNotificationsData();
    }
  };

  return (
    <Sentry.ErrorBoundary fallback="Something went wrong">
      <Drawer
        placement={POPOVER_TYPE.RIGHT}
        width={400}
        closable
        onClose={onClose}
        visible={visible}
      >
        <Content>
          <UserActionHeader
            content={
              <ContentTitle>
                <div className="title">
                  <FormattedMessage id="notifications.title" />
                </div>
                <div className="button">
                  {active === 'new' && notifications?.length > 0 && (
                    <Button
                      className="clear-btn"
                      background={Colors.IconColor}
                      border={Colors.Transparent}
                      color={Colors.TextSecondary}
                      focus={Colors.IconColor}
                      onClick={() => clearAllNotifications()}
                      name={<FormattedMessage id="notifications.clearAll" />}
                    />
                  )}
                </div>
              </ContentTitle>
            }
          />
          <ButtonWrapper>
            <Button
              rounded
              background={
                active === 'new' ? Colors.TextLight : Colors.Transparent
              }
              border={active === 'new' ? Colors.TextLight : Colors.Transparent}
              color={
                active === 'new' ? Colors.PrimaryColor : Colors.TextSecondary
              }
              onClick={() => handleTabChange('new')}
              name={<FormattedMessage id="notifications.new" />}
            />
            <Button
              rounded
              background={
                active === 'cleared' ? Colors.TextLight : Colors.Transparent
              }
              border={
                active === 'cleared' ? Colors.TextLight : Colors.Transparent
              }
              color={
                active === 'cleared'
                  ? Colors.PrimaryColor
                  : Colors.TextSecondary
              }
              onClick={() => handleTabChange('cleared')}
              name={<FormattedMessage id="notifications.cleared" />}
            />
          </ButtonWrapper>
          <div className="scroll">
            {notifications?.length > 0 ? (
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleInfiniteOnLoad}
                hasMore={hasMore}
                useWindow={false}
                threshold={10}
                loader={
                  notifications?.length &&
                  dataCount > 10 && (
                    <LoaderDiv key="loader">
                      <Loader size="medium" />
                    </LoaderDiv>
                  )
                }
              >
                <New
                  data={notifications}
                  clearAble={!loading && active === 'new'}
                  onCheck={onCheck}
                  decreaseNotification={decreaseNotification}
                />
              </InfiniteScroll>
            ) : (
              !loading && (
                <NoNotificationWrapper>
                  {' '}
                  <ImageWrapper>
                    <Image src={NoNotification} alt="na" />
                    <FormattedMessage id="notifications.noNotifications" />
                  </ImageWrapper>
                </NoNotificationWrapper>
              )
            )}
          </div>
        </Content>
      </Drawer>
    </Sentry.ErrorBoundary>
  );
};

export default withRouter(Notifications);
