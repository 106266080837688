import React from 'react';
import { Row, Col } from 'antd';
import Tag from 'atoms/Tag';
import UserProfile from 'molecules/UserProfile';
import { ActivityData } from '../MockData';
import ActivityCard from '../ActivityCard';

export const ZendeskMessage = (data, type = '') => (
  <Row align="middle" className="message">
    <Col className="flex">
      <UserProfile
        size={20}
        stackedName={false}
        profile={data?.actor?.profilePic || data?.processedData?.actor_image}
        name={data?.actor?.name || data?.processedData?.actor_name}
      />
      <span>
        <span>solved</span>&nbsp;&nbsp;
        <Tag className="tag-text" color="#EFF2F6" content="#ticket1" />
        &nbsp;&nbsp;
        <span className="heading">“Sample ticket: Meet the ticket”</span>
      </span>
    </Col>
  </Row>
);

const ZendeskCard = ({ data }) => (
  <ActivityCard
    activityDate={data?.addedAt}
    platformIcon={ActivityData?.zendesk?.title?.icon}
    message={ZendeskMessage(data)}
  />
);

export default ZendeskCard;
