import { gql } from '@apollo/client';
import { checkInSettingFragment } from './Fragments';

export const CHECK_IN_SETTING = gql`
  query checkInSetting {
    checkInSetting {
      ...CheckInSettingFragment
    }
  }
  ${checkInSettingFragment}
`;
