import Colors from 'design/Colors';

const { default: styled } = require('styled-components');

export const EditorTextArea = styled.div`
  position:sticky;
  top:80px;
  padding: 1rem;
  border-radius:8px;
  margin-bottom:1rem;
  border : 1px solid ${Colors.Border};
  .textarea{
    margin:1rem 0px;
  }
  .editor-toolbar{
    position:sticky;
    top:80px;
  }
`;
