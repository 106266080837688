import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import Variables from 'design/Variables';
import { Form as AntForm } from 'antd';

export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 calc(${Spacings.xSmall.padding} - 4px);
  }
  & > .ant-form-item-control {
    padding-left: calc(${Spacings.small.padding} + 4px);
  }
  .ant-form-item-label > label {
    height: 100%;
    color: ${Colors.TextSecondary};
    font: ${fonts.small.regular};
    line-height: ${Variables.LineHeightMd};
  }
  .last-updated {
    color: ${Colors.TextSecondary};
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .moods {
    .ant-form-item-label > label {
      color: #515983;
      font: 400 1em Inter, sans-serif;
      line-height: 22px;
      height: 0px;
    }
    .ant-form-item-label > label.ant-form-item-required::before {
      display: none;
    }
  }
  .yesterday-wrapper {
    .ant-form-item-label > label {
      display: block;
      width: 100%;
    }
  }
  .todays-wrapper {
    .ant-form-item-label > label {
      width: 100%;
    }
  }
  .todays-row {
    width: 100%;
  }
  .label-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .label-text {
    display: flex;
    align-items: center;
  }
  .check-box {
    margin-right: 8px;
  }
  .yesterday-goals {
    display: flex;
    justify-content: space-between;
  }
  .ant-form-item {
    margin-bottom: ${Spacings.xSmall.margin};
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .multiple-inputs {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .add-btn {
    font: ${fonts.small.regular};
    line-height: 20px;
    display: flex;
    align-items: center;
    padding: 8px 6px;
    :focus {
      outline: none;
      box-shadow: none;
    }
  }
  .add-btn-img {
    margin-right: ${Spacings.xxSmall.margin};
  }
`;

export const EmojiWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 0px;
  background: transparent;
  outline: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'initial' : 'pointer')};
  &:hover {
    background: ${({ isDisabled }) =>
      isDisabled ? 'initial' : Colors.IconColor};
    border-radius: ${({ isDisabled }) =>
      isDisabled ? 'initial' : Variables.RadiusL};
  }
  &.active {
    background: ${Colors.IconColor};
    border-radius: ${Variables.RadiusL};
    border: 1px solid ${Colors.SecondaryColor};
  }
  &.active:hover {
    background: ${Colors.IconColor};
    border-radius: ${Variables.RadiusL};
    cursor: pointer;
  }
  &.active:focus {
    background: ${Colors.IconColor};
    border-radius: ${Variables.RadiusL};
    cursor: pointer;
  }
  &.focus-btn:focus-visible {
    background: ${Colors.IconColor};
    border-radius: ${Variables.RadiusL};
    cursor: pointer;
    outline: none;
  }
`;
export const Divider = styled.hr`
  margin-left: -44px;
  margin-right: -16px;
  border: 1px solid ${Colors.Border};
  border-bottom: 0px;
  margin-bottom: ${Spacings.xSmall.margin};
  margin-top: ${Spacings.xSmall.margin};
`;
export const ErrorText = styled.div`
  color: ${Colors.TextDanger};
  margin-top: ${Spacings.tiny.margin};
`;
export const Wrapper = styled.div`
  .ant-modal-header {
    .blocker-img {
      filter: brightness(0) saturate(100%);
      margin-right: 8px;
    }
  }
  .recheck-info {
    font-size: 16px !important;
    line-height: 26px;
    color: #515983 !important;
  }
  .yesterday-task-input {
    border-radius: 8px;
  }
  .task-card {
    .ant-card-body {
      padding: 0px;
    }
  }
`;
export const TimeDiv = styled.div`
  font-size: ${fonts.xSmall.size};
  font-weight: 400;
  line-height: 20px;
  .time {
    color: ${Colors.TextSecondary};
  }
`;
