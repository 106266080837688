/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Typography, Radio } from 'antd';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import client from 'apollo';
import moment from 'moment';
import Meta from 'atoms/Meta';
import _map from 'lodash/map';
import { UndifferLogo, PlaceHolder, CheckedLight } from 'assets';
import { InfoCircleOutlined } from '@ant-design/icons';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import {
  FORM_NAME,
  META_TYPE,
  PUBLIC_ROUTE,
  BUTTON_TYPE,
} from 'utils/constants';
import { VERIFY_EMAIL } from 'graphql/Onboarding/Mutations';
import * as Sentry from '@sentry/react';
import Loader from 'atoms/Loader';
import {
  Container,
  Wrapper,
  Background,
  Title,
  Logo,
  Form,
  CompanyLogo,
  Image,
  Name,
} from '../AntStyled.js';
export default function (props) {
  const [loading, setLoading] = useState(false);
  const {
    match: {
      params: { token },
    },
    location: { state },
    history,
    zone,
    workspaceName,
  } = props;

  const [isError, setIsError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [button, setButton] = useState(true);
  const [location, setLocation] = useState(true);
  useEffect(() => {
    if (token) {
      // eslint-disable-next-line no-inner-declarations
      async function fetchData() {
        setSpinner(true);
        try {
          await client
            .mutate({
              mutation: VERIFY_EMAIL,
              variables: {
                token,
              },
            })
            .then((data) => {
              localStorage.setItem('token', data.data.verifyEmail);
              setIsError(false);
              setSpinner(false);
            });
        } catch (error) {
          console.log(error);
          setIsError(true);
          setSpinner(false);
        }
      }
      fetchData();
    }
  }, [history, token]);

  const handleContinue = () => {
    window.location = PUBLIC_ROUTE.SET_PASSWORD;
  };

  const resendEmail = () => {
    history.push('/signup');
  };
  const min = moment.tz(zone)._offset;
  const hr = `${(min / 60) ^ 0}:${min % 60 === 0 ? '00' : min % 60}`;

  return (
    <Container>
      <Background>
        <Logo src={UndifferLogo} alt="logo"></Logo>
        <NavLink to="/help" className="bottom-right">
          <FormattedMessage id="confirmation.needHelp" />
        </NavLink>
        <NavLink to="/copyright" className="bottom-left">
          <FormattedMessage id="confirmation.copyright" />
        </NavLink>
      </Background>
      <Wrapper>
        <Card padding="36px 36px 24" className="auth">
          <Meta title={META_TYPE.WORK_SPACE} />
          <CompanyLogo>
            <Image src={PlaceHolder} alt="logo" />
            <Name>Logicwind</Name>
          </CompanyLogo>
          <Form name={FORM_NAME?.BASIC} layout="vertical">
            <Form.Item label="Select Location">
              <Radio.Group
                defaultValue
                onChange={(e) => {
                  setLocation(e.target.value);
                  setButton(false);
                }}
              >
                <Radio className="radioStyle" value>
                  <span className="company">
                    <span className="name">Logicwind HQ</span>
                    <span className="timezone">
                      {`(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone}`}
                    </span>
                  </span>
                </Radio>
                <Radio className="radioStyle" value={false}>
                  <span className="company">
                    <span className="name">Logicwind Amd</span>
                    <span className="timezone">
                      {`(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone}`}
                    </span>
                  </span>
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Button
                className="right"
                accent
                loading={loading}
                htmlType={BUTTON_TYPE.SUBMIT}
                name={
                  <FormattedMessage id="onboarding.selection.location.submit" />
                }
              />
            </Form.Item>
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
}
