/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { DatePicker, ConfigProvider, Typography } from 'antd';
import moment from 'moment';
import locale from 'antd/es/locale/en_GB';
import { ArrowIcon, CalenderPrimary, CloseCircle } from 'assets';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import { isBoolean } from 'lodash';
import { StyledDiv, GlobalStyles } from './AntStyled';
const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function ({
  setDateFilterValue,
  dateFilterValue,
  onChangeDateFilter,
  disabledDate,
}) {
  const todaysDate = moment().format('LL');
  const [visible, setVisible] = useState(false);
  const startDate = moment().startOf('isoWeek');
  const endDate = moment().endOf('isoWeek');
  const [disabled, setDisabled] = useState();
  const [value, setValue] = useState(todaysDate);
  const [selectedDate, setSelectedDate] = useState(
    dateFilterValue || [startDate, endDate]
  );
  useEffect(() => setDateFilterValue && setDateFilterValue(selectedDate), [
    selectedDate,
    setDateFilterValue,
  ]);
  const defaultDisabledDate = (current) =>
    disabledDate
      ? disabledDate(current)
      : current && current > moment().endOf('day');

  useEffect(() => {
    setDisabled(defaultDisabledDate(moment(value).add(1, 'days')));
  }, [value]);

  const DatePickerContainer = (
    <StyledDiv onClick={() => handlePopup()}>
      <GlobalStyles />
      <RangePicker
        className="styled-ant-date-picker"
        dropdownClassName="styled-ant-date-picker-popup"
        autoFocus
        inputReadOnly
        open={visible}
        defaultValue={selectedDate}
        format={MomentFormat.longDate}
        suffixIcon={<img src={CalenderPrimary} onClick={() => handlePopup()} />}
        disabledDate={defaultDisabledDate}
        separator="-"
        onChange={(e) => {
          setSelectedDate(e || []);
          onChangeDateFilter && onChangeDateFilter();
          e !== null && setVisible(false);
        }}
        onOpenChange={(e) => handlePopup(e)}
        dateRender={(e) => (
          <Text className="ant-picker-cell-inner">
            {moment(e).format('DD')}
          </Text>
        )}
        ranges={{
          Today: [moment(), moment()],
          'This Week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
          'Last Week': [
            moment().subtract(1, 'week').startOf('isoWeek'),
            moment().subtract(1, 'week').endOf('isoWeek'),
          ],
          'This Month': [moment().startOf('month'), moment()],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
          ],
          'This Year': [moment().startOf('year'), moment()],
        }}
      />
    </StyledDiv>
  );
  const handlePopup = (val) => {
    if (!visible) {
      setVisible(true);
    }
    if (isBoolean(val) && !val) {
      setVisible(false);
    }
  };

  return (
    <ConfigProvider locale={locale}>
      {DatePickerContainer}
      {/* <StyledDiv id="popup-div" /> */}
      {/* <StyledPopover visible={visible} content={PopoverContent} trigger="click" placement="bottom" getPopupContainer={() => document.getElementById('popup-div')}>
        <DateFilter suffixIcon={<Arrow src={ArrowIcon} />} onClick={() => handlePopup()} hasValue={selectedDate && selectedDate.length === 2}>
          {selectedDate && selectedDate.length === 2
            ? `${selectedDate[0].format('MM/DD/YYYY')} - ${selectedDate[1].format('MM/DD/YYYY')}`
            : <>Select Time Frame<span className="arrow-right"><DownOutlined /></span></>}
          {selectedDate && selectedDate.length === 2 && <Close src={CloseCircle} alt="close" onClick={() => setSelectedDate([])} />}
        </DateFilter>
      </StyledPopover> */}
    </ConfigProvider>
  );
}
