/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Skeleton, Layout, message, Button, Row } from 'antd';
import {
  EMPTY_ARRAY,
  EMPTY_STATE_IMAGE_URL,
  PRIVATE_ROUTE,
} from 'utils/constants';
import { ArchiveIcon, Pencil, Trash } from 'assets';
import { Link, useHistory } from 'react-router-dom';
import CommentsPopover from 'organisms/CommentsPopover';
import Table from 'atoms/Table';
import UserProfile from 'molecules/UserProfile';
import GroupTag from 'atoms/GroupTag';
import DateTime from 'atoms/DateTime';
import ContentWrapper from 'atoms/ContentWrapper';
import { titleCase } from 'utils/stringUtilities';
import GroupTagList from 'organisms/GroupTagList';
import ability from 'utils/ability/ability';
import PopConfirm from 'atoms/PopConfirmation';
import { useMutation } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';
import { InfiniteScrollTableWrapper } from 'design/Styled';
import Loader from 'atoms/Loader';
import { LoaderDiv } from 'features/dailyGoals/templates/DailyGoals/AntStyled';
import EmptyState from 'pages/EmptyState';
import Search from 'antd/lib/transfer/search';
import { QueryData } from '@apollo/client/react/data/QueryData';
import { Content, Image } from './AntStyled';
import { GET_ALL_ANNOUNCEMENTS } from '../../graphql/Queries';
import { DELETE_ANNOUNCEMENT } from '../../graphql/Mutations';
export default function (props) {
  const {
    loading,
    data,
    isArchive,
    handleInfiniteOnLoad,
    hasMore,
    loader,
    onChange,
    isPublishedTab,
    queryCount,
    search,
  } = props;
  const [announcements, setAnnouncements] = useState(EMPTY_ARRAY);
  const [deleteAnnouncement] = useMutation(DELETE_ANNOUNCEMENT);
  const history = useHistory();
  useEffect(() => {
    if (data?.length) {
      setAnnouncements(data);
    } else {
      setAnnouncements(EMPTY_ARRAY);
    }
  }, [data]);
  const handleOnArchive = (id) => {
    setAnnouncements(
      announcements.filter((announcement) => announcement?.id !== id)
    );
  };
  const actionColumns = [
    {
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      width: '1%',
      className: 'no-padding',
      render: (row, rowData) => (
        <Button disabled={rowData?.status !== 'DRAFT'} type="link">
          <Link to={`${PRIVATE_ROUTE.ANNOUNCEMENTS}/edit/${rowData?.id}`}>
            <Image src={Pencil} alt="icon"></Image>
          </Link>
        </Button>
      ),
    },
    {
      align: 'right',
      dataIndex: 'operation',
      width: '1%',
      className: 'padding-col',
      render: (row, rowData) => (
        <PopConfirm
          disabled={rowData?.status !== 'DRAFT'}
          title="Are you sure want to delete this Announcement?"
          placement="bottomLeft"
          icon={<Image src={Trash} className="icon-delete" />}
          onConfirm={() => {
            deleteAnnouncement({
              variables: {
                where: { id: rowData?.id },
              },
              refetchQueries: [
                {
                  query: GET_ALL_ANNOUNCEMENTS,
                },
              ],
            }).then(() => {
              handleOnArchive(rowData?.id);
              message.success('Deleted Successfully');
            });
          }}
        ></PopConfirm>
      ),
    },
  ];

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    ...(isPublishedTab
      ? [
        {
          title: 'ID',
          dataIndex: 'sequenceNo',
          key: 'sequenceNo',
          width: '10%',
          render: (title, rowData) => (
            <div className="weight-500">{title}</div>
          ),
        },
      ]
      : []),
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
      render: (title, rowData) => (
        <div
          className={`weight-500 ${isPublishedTab && !rowData?.isRead && 'isNotRead'
            }`}
        >
          {title}
        </div>
      ),
    },
    {
      title: 'Groups',
      dataIndex: 'groups',
      key: 'departments',
      width: isPublishedTab ? '20%' : '30%',
      render: (departments, rowData) =>
        rowData?.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : (
          <GroupTagList departments={departments} />
        ),
    },
    {
      title: isPublishedTab ? 'Published By' : 'Drafted By',
      dataIndex: 'createdBy',
      width: isPublishedTab ? '20%' : '25%',
      key: 'user',
      render: (user, rowData) =>
        rowData?.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : user ? (
          <UserProfile
            profile={user?.profileImage}
            name={
              user?.firstName
                ? `${user?.firstName} ${user?.lastName || ''}`
                : user?.originalEmail ||user?.email
            }
            userId={user?.id}
            showLink
          />
        ) : (
          '-'
        ),
    },
    {
      title: isPublishedTab ? 'Sent On' : 'Created On',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '20%',
      render: (date, rowData) =>
        rowData?.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : (
          <DateTime date={date} />
        ),
    },

    ...(isPublishedTab
      ? [
        {
          title: 'Read By',
          dataIndex: 'totalReaders',
          key: 'totalReaders',
          width: '10%',
          render: (totalReaders, rowData) =>
            rowData?.loading ? (
              <Skeleton paragraph={{ rows: 1 }} title={false} active />
            ) : totalReaders ? (
              <div>
                {rowData?.readBy}/{totalReaders}
              </div>
            ) : (
              '-'
            ),
        },
      ]
      : []),
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];

  const onClickRow = (record) => ({
    onClick: () => {
      history.push({
        pathname:
          record?.status === 'DRAFT'
            ? `${PRIVATE_ROUTE.ANNOUNCEMENTS}/edit/${record?.id}`
            : `${PRIVATE_ROUTE.ANNOUNCEMENTS}/view/${record?.id}`,
      });
    },
  });
  return (
    <Layout>
      <Content>
        {loading && queryCount !== 1 ? (
          <LoaderDiv key="loader">
            <Loader />
          </LoaderDiv>
        ) : loading || announcements?.length > 0 ? (
          <ContentWrapper>
            <InfiniteScrollTableWrapper>
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleInfiniteOnLoad}
                hasMore={hasMore}
                useWindow={false}
                threshold={20}
                loader={
                  announcements?.length && (
                    <LoaderDiv key="loader">
                      <Loader size="medium" />
                    </LoaderDiv>
                  )
                }
              >
                <Table
                  columns={columns}
                  dataSource={announcements}
                  height="calc(100vh - 96px - 60px)"
                  loading={loading}
                  className="bb-0"
                  onChange={onChange}
                  isInfinite
                  onRow={onClickRow}
                  rowClassName={(rowData) =>
                    isPublishedTab ? (rowData?.isRead ? 'isRead' : '') : ''
                  }
                />
              </InfiniteScroll>
            </InfiniteScrollTableWrapper>
          </ContentWrapper>
        ) : ability.can('manage', 'announcements') ? (
          <EmptyState
            title={
              isPublishedTab ? 'Announce something!' : 'Nothing in Drafts!'
            }
            description={
              isPublishedTab
                ? 'Make an announcement and share it with your team.'
                : 'Start writing something fabulous'
            }
            image={EMPTY_STATE_IMAGE_URL.ANNOUNCEMENT}
            buttonTitle="Make an announcement"
            onClick={() => history.push(PRIVATE_ROUTE.ANNOUNCEMENT_NEW)}
          ></EmptyState>
        ) : (
          <EmptyState
            title="No announcements!"
            description="Your admin didn’t announce anything"
            image={EMPTY_STATE_IMAGE_URL.ANNOUNCEMENT}
          ></EmptyState>
        )}
      </Content>
    </Layout>
  );
}
