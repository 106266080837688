/* eslint-disable indent */
import React, { useState } from 'react';
import { Button, Layout, Popover, Skeleton } from 'antd';
import moment from 'moment';
import Meta from 'atoms/Meta';
import { Pencil, fileIcon, OptionIcon, Trash } from 'assets';
import DateTime from 'atoms/DateTime';
import { Link, Switch, useHistory } from 'react-router-dom';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import { useMutation } from '@apollo/client';
import GroupTagList from 'organisms/GroupTagList';
import ContentWrapper from 'atoms/ContentWrapper';
import Status from 'atoms/Status';
import InfiniteScroll from 'react-infinite-scroller';
import { titleCase } from 'utils/stringUtilities';
import * as Sentry from '@sentry/react';
import {
  SURVEYS_TYPE,
  TASK_TYPE,
  PRIVATE_ROUTE,
  GROUP_TYPE,
  META_TYPE,
  POP_CONFIRM_PLACEMENT,
  FEEDBACK_TYPE,
  FEEDBACK_STATUS_COLOR,
  TODO_TYPE,
  EMPTY_STRING,
  EMPTY_OBJECT,
  EMPTY_STATE_IMAGE_URL,
} from 'utils/constants';
import { capitalize, findIndex, startCase } from 'lodash';

import Table from 'atoms/Table';
import PopConfirm from 'atoms/PopConfirmation';
import { FormattedMessage } from 'react-intl';
import { Image } from 'features/announcement/templates/AnnouncementList/AntStyled';
import Loader from 'atoms/Loader';
import { LoaderDiv } from 'features/dailyGoals/templates/DailyGoals/AntStyled';
import { InfiniteScrollTableWrapper } from 'design/Styled';
import ability from 'utils/ability/ability';
import EmptyState from 'pages/EmptyState';
import UserProfile from 'molecules/UserProfile';
import UpdatesModal from 'templates/Updates/UpdatesModal';
import { DELETE_FEEDBACK } from '../../graphql/Mutations';
import UserForm from '../UserForm';
import { Content, Response, Icon } from './AntStyled';
export default function (props) {
  const {
    handleInfiniteOnLoad,
    loader,
    hasMore,
    feedbackList,
    feedbackLoading,
    refetchFeedbacks,
    dataCount,
    isCompleted,
    status,
    queryCount,
    search,
    setFeedbackList,
  } = props;
  const history = useHistory();
  const [deleteFeedback] = useMutation(DELETE_FEEDBACK);
  const [visible, setVisible] = useState(false);
  const [feedbackData, setFeedbackData] = useState(EMPTY_OBJECT);
  const profileTiltle = {
    0: 'Published by',
    1: 'Created by',
    2: 'Drafted by',
  };
  const emptyStates = {
    title: {
      0: 'No feedback forms published!',
      1: 'No feedbacks are completed yet!',
      2: 'Nothing in Draft!',
    },
    description: {
      0: 'Add survey or poll to collect feedback from your team',
      1: 'Add survey or poll to collect feedback from your team',
      2: '',
    },
    buttonTitle: {
      0: 'Add a feedback form',
      1: 'Go to active feedbacks',
      2: 'Create feedback',
    },
  };

  const surveyColumns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      dataIndex: 'type',
      key: 'type',
      width: '32px',
      render: (type) =>
        type === FEEDBACK_TYPE.SURVEY ? (
          <Icon src={fileIcon} alt="icon"></Icon>
        ) : (
          <Icon src={OptionIcon} alt="icon"></Icon>
        ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title) => <div className="weight-500">{title}</div>,
    },
    {
      title: 'Groups',
      dataIndex: 'groups',
      key: 'groups',
      render: (groups) => (
        <GroupTagList
          limit={2}
          type={GROUP_TYPE.DEPARTMENT}
          departments={groups}
        />
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'scheduleOn',
      key: 'scheduleOn',
      render: (scheduleOn) =>
        scheduleOn ? (
          <DateTime
            date={moment(scheduleOn)}
            format={MomentFormat.dayMonthYear}
            fromNow
          />
        ) : (
          '-'
        ),
    },
    {
      title: 'End Date',
      dataIndex: 'endOn',
      key: 'endOn',
      render: (endOn) =>
        endOn ? (
          <DateTime
            date={moment(endOn)}
            format={MomentFormat.dayMonthYear}
            fromNow
          />
        ) : (
          '-'
        ),
    },

    {
      title: profileTiltle[status],
      dataIndex: 'createdBy',
      key: 'user',
      render: (user, rowData) =>
        rowData?.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : user ? (
          <UserProfile
            profile={user?.profileImage}
            name={
              user?.firstName
                ? `${user?.firstName} ${user?.lastName || ''}`
                : user?.originalEmail || user?.email
            }
            userId={user?.id}
          />
        ) : (
          '-'
        ),
    },
    status !== 2
      ? {
        title: 'Response',
        dataIndex: 'responsePercent',
        key: 'responsePercent',
        render: (response) => (
          <Response>
            <p>{response}%</p>
          </Response>
        ),
      }
      : [],
    ...(ability.can('manage', 'feedbacks') && status === 0
      ? [
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (status, rowData) =>
            status ? (
              <Status
                name={startCase(capitalize(status))}
                color={FEEDBACK_STATUS_COLOR[status]}
              />
            ) : (
              '-'
            ),
        },
      ]
      : []),
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];

  const onClickRow = (record) => ({
    onClick: () => {
      setFeedbackData({
        type: TODO_TYPE.FEEDBACK_REQUEST,
        title: record?.title,
        data: {
          endOn: record?.endOn,
          description: record?.description,
          questions: record?.questions?.questions,
          type: record?.type,
        },
        groups: record?.groups,
        updatableId: record?.id,
        createdBy: record?.createdBy,
        updatableType: 'feedback',
      });
      switch (status) {
        case 0:
          setVisible(true);
          break;
        case 1:
          history.push({
            pathname:
              record?.type === SURVEYS_TYPE.POLLS
                ? `${`/feedback/poll/${record?.id}/summary`}`
                : `${`/feedback/survey/${record?.id}/summary`}`,
          });
          break;
        case 2:
          history.push({
            pathname: `${PRIVATE_ROUTE.FEED_BACK}/${record?.type === 'POLL' ? 'poll' : 'survey'
              }/edit/${record?.id}`,
          });
          break;
        default:
          break;
      }
    },
  });

  const filterTodo = (id) => {
    const oldData = [...feedbackList];
    const index = findIndex(oldData, (item) => item.id === id);
    if (index !== -1) {
      oldData.splice(index, 1);
      setFeedbackList([...oldData]);
    }
  };

  const handleCallback = (key) => {
    filterTodo(key);
    setVisible(false);
  };
  return (
    <Layout>
      <Content>
        {feedbackLoading && queryCount !== 1 ? (
          <LoaderDiv key="loader">
            <Loader />
          </LoaderDiv>
        ) : feedbackList?.length > 0 || feedbackLoading ? (
          <ContentWrapper>
            <InfiniteScrollTableWrapper>
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleInfiniteOnLoad}
                hasMore={!loader && hasMore}
                useWindow={false}
                threshold={20}
                loader={
                  feedbackList?.length &&
                  dataCount > 20 && (
                    <LoaderDiv key="loader">
                      <Loader size="medium" />
                    </LoaderDiv>
                  )
                }
              >
                <Table
                  className="bb-0"
                  columns={surveyColumns}
                  dataSource={feedbackList}
                  loading={feedbackLoading}
                  isInfinite
                  height="calc(100vh - 96px - 60px)"
                  onRow={onClickRow}
                />
              </InfiniteScroll>
            </InfiniteScrollTableWrapper>
          </ContentWrapper>
        ) : ability.can('manage', 'feedbacks') ? (
          <EmptyState
            title={emptyStates?.title[status]}
            description={emptyStates?.description[status]}
            image={EMPTY_STATE_IMAGE_URL.FEEDBACK}
            buttonTitle={emptyStates?.buttonTitle[status]}
            buttonDropdown={status !== 1}
            onClick={() => history.push(PRIVATE_ROUTE.FEED_BACK_CURRENT)}
          ></EmptyState>
        ) : (
          <EmptyState
            title="No feedback requests recieved"
            description="Feedback request will appear here when your admin
shares a feedback form."
            image={EMPTY_STATE_IMAGE_URL.FEEDBACK}
          ></EmptyState>
        )}

        <UpdatesModal
          visible={visible}
          setVisible={setVisible}
          callback={handleCallback}
          updatesData={feedbackData}
          viewSummary
        />
      </Content>
    </Layout>
  );
}
