import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';

import UserProfile from 'molecules/UserProfile';
import Tag from 'atoms/Tag';
import { COLORS, CHECKIN_STATUS, STATUS2 } from 'utils/constants';
import DateTime from 'atoms/DateTime';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import { calculateWorkedTime } from 'utils/calculateWorkedTime';
import Hours from 'atoms/Hours';
import Colors from 'design/Colors';
import styled from 'styled-components';
import { fonts } from 'design/Fonts';
import { bulletChart } from './Chart';

const StyledTag = styled.span`
  color:${({ textColor }) => textColor || 'inherit'};
  font-size:${fonts.small.size};
`;

const InlineWrapper = styled.div`
  white-space:nowrap;
`;

const Hyphen = styled.span`
  color:${Colors.GraphDarkGreen};
  padding:0 7px;
`;
const monthDays = moment().daysInMonth();
let classNameForMonth;
if (monthDays === 28) {
  classNameForMonth = 'twentyEightDays';
} else if (monthDays === 29) {
  classNameForMonth = 'twentyNineDays';
} else if (monthDays === 30) {
  classNameForMonth = 'thirtyDays';
} else if (monthDays === 31) {
  classNameForMonth = 'thirtyOneDays';
}

const formatWork = (work) => {
  if (work) {
    const worked = work.split('.');
    const hours = worked[0];
    const mins = worked[1] || '0';
    return `${hours.length === 1 ? `0${hours}` : hours}${hours === 1 ? 'hr' : 'hrs'} ${mins.length === 1 ? `0${mins}` : mins}m`;
  }
  return work;
};

const COLOR = {
  noData: '#E5E5E5',
  leave: Colors.GraphRed,
  work: Colors.GraphGreen,
};

export const dayColumns = [
  {
    dataIndex: 'spanone',
    key: 'spanone',
    className: 'no-padding mw-20',
  },
  {
    title: 'Name',
    dataIndex: 'user',
    key: 'user',
    width: '15%',
    render: (row, rowData) => (
      <UserProfile
        redirectURL={`/profile/${rowData.user.id}/attendance`}
        showLink
        userId={rowData.user.id}
        profile={rowData.user.profileImage}
        name={rowData.user.firstName && rowData.user.lastName ? `${rowData.user.firstName} ${rowData.user.lastName}` : rowData.user.email}
      />
    ),
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => a.user.firstName.localeCompare(b.user.firstName),
  },
  {
    title: 'In Time - Out Time',
    dataIndex: 'inTime',
    width: '20%',
    render: (inTime, rowData) => (
      <InlineWrapper>
        {rowData.inTime && <StyledTag textColor={Colors.GraphDarkGreen}><DateTime date={rowData.inTime && moment(rowData.inTime)} format={MomentFormat.time} /></StyledTag>}
        {rowData.outTime && rowData.status === CHECKIN_STATUS.DONE && <><Hyphen> - </Hyphen><StyledTag textColor={Colors.GraphDarkGreen}><DateTime date={rowData.outTime && moment(rowData.outTime)} format={MomentFormat.time} /></StyledTag></>}
      </InlineWrapper>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '12%',
    render: status => <StyledTag textColor={STATUS2[status].color}>{STATUS2[status].text}</StyledTag>,
  },
  {
    title: 'Worked',
    dataIndex: 'worked',
    key: 'worked',
    width: '10%',
    render: (worked, rowData) => <InlineWrapper>{calculateWorkedTime(rowData.punches)}</InlineWrapper>,
  },
  {
    title: 'Break',
    dataIndex: 'break',
    key: 'break',
    width: '10%',
    render: (worked, rowData) => <InlineWrapper>{calculateWorkedTime(rowData.punches, true)}</InlineWrapper>,
  },
  {
    render: (worked, rowData) => bulletChart(rowData.punches),
  },
  {
    dataIndex: 'spantwo',
    key: 'spantwo',
    className: 'no-padding mw-20',
  },
];


export const weekColumns = [
  {
    dataIndex: 'spanone',
    key: 'spanone',
    className: 'no-padding mw-20',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '200px',
    render: (row, rowData) => rowData.user && (
      <UserProfile
        redirectURL={`/profile/${rowData.user.id}/attendance`}
        showLink
        userId={rowData.user.id}
        profile={rowData.user.profileImage}
        name={rowData.user.firstName && rowData.user.lastName ? `${rowData.user.firstName} ${rowData.user.lastName}` : rowData.user.email}
      />
    ),
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => a.user.firstName.localeCompare(b.user.firstName),
  },
  {
    title: 'Worked',
    dataIndex: 'worked',
    key: 'worked',
    width: '100px',
    render: work => <InlineWrapper>{formatWork(work)}</InlineWrapper>,
  },
  {
    title: (
      <div className="week">
        {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, i) => (
          <span key={i}>{day}</span>
        ))}
      </div>
    ),
    dataIndex: 'attendance',
    key: 'attendance',
    render: data => (
      <div className="responsiveBarWeek">
        <ResponsiveBar
          data={data}
          keys={['work', 'leave', 'noData']}
          indexBy="day"
          maxValue={8}
          margin={{
            top: 0, right: 0, bottom: 0, left: 0,
          }}
          padding={0.55}
          colors={x => COLOR[x.id]}
          enableLabel={false}
          enableGridY={false}
          axisBottom={{ enable: false }}
          tickSize="0px"
          axisLeft={{ enable: false }}
          animate
          motionStiffness={90}
          motionDamping={15}
          theme={{
            tooltip: {
              container: {
                'min-width': 'max-content',
                padding: '8px',
                'font-weight': 'bold',
              },
            },
          }}
          tooltip={({ id, value }) => id === 'noData' ? 'Untracked'
            : (
              <>
                {id === 'work' && 'Worked'}{id === 'work' ? `: ${formatWork(value.toString())}` : formatWork(value.toString())}
              </>
            )}
        />
      </div>
    ),
  },
  {
    dataIndex: 'spantwo',
    key: 'spantwo',
    className: 'no-padding mw-20',
  },
];


export const monthColumns = [
  {
    dataIndex: 'spanone',
    key: 'spanone',
    className: 'no-padding mw-20',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '200px',
    render: (row, rowData) => rowData.user && (
      <UserProfile
        redirectURL={`/profile/${rowData.user.id}/attendance`}
        showLink
        userId={rowData.user.id}
        profile={rowData.user.profileImage}
        name={rowData.user.firstName && rowData.user.lastName ? `${rowData.user.firstName} ${rowData.user.lastName}` : rowData.user.email}
      />
    ),
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => a.user.firstName.localeCompare(b.user.firstName),
  },
  {
    title: 'Worked',
    dataIndex: 'worked',
    key: 'worked',
    width: '100px',
    render: worked => <InlineWrapper>{formatWork(worked)}</InlineWrapper>,
  },
  {
    title: (
      <div className="month">
        {new Array(monthDays).fill().map((_, i) => (
          <div key={i}>{i + 1}</div>
        ))}
      </div>
    ),
    dataIndex: 'attendance',
    key: 'attendance',
    render: data => (
      // <ResponsiveBarDiv className={classNameForMonth}>
      <div className={classNameForMonth}>
        <ResponsiveBar
          data={data}
          keys={['work', 'leave', 'noData']}
          indexBy="day"
          maxValue={8}
          margin={{
            top: 0, right: 0, bottom: 0, left: 0,
          }}
          padding={0}
          colors={x => COLOR[x.id]}
          enableLabel={false}
          enableGridY={false}
          axisBottom={{ enable: false }}
          tickSize="0px"
          axisLeft={{ enable: false }}
          animate
          motionStiffness={90}
          motionDamping={15}
          theme={{
            tooltip: {
              container: {
                'min-width': 'max-content',
                padding: '8px',
                'font-weight': 'bold',
              },
            },
          }}
          tooltip={({ id, value }) => id === 'noData' ? 'Untracked'
            : (
              <>
                {id === 'work' ? 'Worked' : 'Leave'}{id === 'work' && `: ${formatWork(value.toString())}`}
              </>
            )}
        />
      </div>
    ),
  },
  {
    dataIndex: 'spantwo',
    key: 'spantwo',
    className: 'no-padding mw-20',
  },
];
