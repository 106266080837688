/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Select as AntSelect } from 'antd';
import { fonts } from 'design/Fonts';
import Colors from 'design/Colors';
import { FETCH_POLICY, EMPTY_ARRAY } from 'utils/constants';
import { GET_LOCATIONS } from 'graphql/Settings/Location/Queries';
import { ArrowIcon } from 'assets';
import Variables from 'design/Variables';

const { Option } = AntSelect;

const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 3px;
  margin-top: -6px;
`;
const Select = styled(AntSelect)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: ${Variables.AtomRadius};
  height: 34px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  .ant-select-selection-placeholder {
    opacity: 1;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    padding: 0 14px;
    background-color: transparent;
    margin-top: 2px;
  }
`;

export default function (props) {
  const {
    locationFilters,
    setShowLocationFilter,
    showLocationFilter,
    handleGroupOrUserFilterChange,
  } = props;
  let debounceJob = null;

  const { data: locationData, loading } = useQuery(GET_LOCATIONS, {
    fetchPolicy: FETCH_POLICY.CACHE_FIRST,
  });
  useEffect(() => {
    if (
      !loading
      && locationData
      && locationData.locations
      && locationData.locations.length > 1
    ) {
      setShowLocationFilter(true);
    } else {
      setShowLocationFilter(false);
    }
  }, [loading, locationData, setShowLocationFilter]);
  const [location, setLocation] = useState(EMPTY_ARRAY);

  const children = _map(
    locationData && locationData.locations,
    (items, index) => (
      <Option key={index} value={parseInt(items.id, 10)}>
        {`${items.name} (${items.shortName})`}
      </Option>
    )
  );

  const handleChange = (value) => {
    setLocation(value);
    if (debounceJob) {
      debounceJob.cancel();
      debounceJob = null;
    }
    debounceJob = _debounce(async () => {
      locationFilters(value);
    }, 1000);
    debounceJob();
    handleGroupOrUserFilterChange && handleGroupOrUserFilterChange();
  };
  return (
    !loading
    && showLocationFilter && (
      <Select
        placeholder="Location"
        allowClear
        onChange={handleChange}
        value={location}
        showArrow
        suffixIcon={<Arrow src={ArrowIcon} />}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        {children}
      </Select>
    )
  );
}
