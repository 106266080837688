import { Layout } from 'antd';
import styled from 'styled-components';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { scrollbars, y } from 'design/Styled';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  display: flex;

  .slick-list {
    height: 100%;
  }

  .ant-carousel,
  .ant-carousel .slick-slider {
    height: 100%;
  }

  .ant-carousel .slick-slide {
    height: 100%;
    line-height: 160px;
    overflow: hidden;
  }

  .ant-carousel .slick-dots li.slick-active {
    width: 16px;
  }

  .ant-carousel .slick-slide h3 {
    color: black;
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: ${Colors.OnBoarding};
    opacity: 1;
  }

  .ant-carousel .slick-dots li button {
    background: transparent;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: 4px;
  }

  .ant-carousel .slick-dots {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  @media (max-width: 1120px) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;

export const LeftSection = styled.div`
  ${y};
  justify-content: center;
  align-items: center;
  width: 50%;
  /* @media (max-width: 1120px) {
    display:none;
  } */
`;

export const RightSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media (max-width: 1120px) {
    position: absolute;
    top: 20%;
    left: 50%;
  }
`;

export const TitleWrapper = styled.div`
  font: ${fonts.xxLarge.bold};
  margin-bottom: ${Spacings.xSmall.margin};
`;

export const SubContent = styled.div`
  font: ${fonts.xSmall.regular};
  line-height: ${Spacings.small.padding};

  .anticon {
    margin-right: ${Spacings.xxSmall.margin};
  }
`;

export const Text = styled.div``;

export const ImageWrapper = styled.div`
  margin-bottom: ${Spacings.medium.margin};
`;

export const Image = styled.img`
  height: 20em;
`;
