import React from 'react';
import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';

import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
import { x } from 'design/Styled';

const { Header: AntHeader } = AntLayout;

const Header = styled(AntHeader)`
  ${x};
  background: ${Colors.Container};
  padding: 0 ${Spacings.xSmall.padding};
  border-bottom: 1px solid ${Colors.Border};
`;

const UserActionHeader = ({ content }) => (
  <Header>
    {content}
  </Header>
);

export default UserActionHeader;
