import styled from 'styled-components';
import { Layout, Modal as AntModal } from 'antd';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  &.ant-layout-content {
    overflow: auto;
  }
`;

export const Modal = styled(AntModal)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-header {
    padding: 16px;
    border-radius: 12px 12px 0 0;
  }
  .row-question {
    padding: 20px 0 0 0px;
  }

  .q-details {
    border-bottom: 1px solid lightgray;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .row-question:first-child {
    padding-top: 0;
  }
  .q-question {
    margin-bottom: 10px;
  }
  .q-answer {
  }
  .q-number {
    padding-left: 10px;
  }
  .q-number,
  .q-question {
    color: ${Colors.TextMedium};
    font-size: ${fonts.medium.size};
    font-weight: bold;
  }
  .ant-row {
    margin: 10px !important;
  }
  .ant-card-body {
    padding: 10px;
    margin: 10px;
  }
  .ant-checkbox-group-item {
    min-width: 250px;
    margin: 4px 0px;
  }
`;

export const QuestionTitle = styled.div`
  font: ${fonts.small.bold};
  color: ${Colors.TextDark};
  margin-bottom: 12px;
  margin-top: 15px;
`;
