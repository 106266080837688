import { Divider, Layout, Popover } from 'antd';
import styled from 'styled-components';

import Spacings from 'design/Spacings';
import { scrollbars } from 'design/Styled';
import Colors from 'design/Colors';
import { fonts, defaultSize as defaultFontSize } from 'design/Fonts';
import Variables from 'design/Variables';

const { Content: AntContent } = Layout;

export const DateFilter = styled.div`
  display: inline-flex;
  background-color: ${Colors.ButtonPrimary};
  line-height: 1.5715;
  color: ${Colors.TextLight};
  height: 32px;
  padding: 4px;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid blue;
  cursor: pointer;
  margin-right: ${Spacings.xxSmall.margin};
  align-items: center;
  & .anticon.anticon-close-circle {
    margin-left: 6px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.TextSecondary};
  padding: ${Spacings.xxSmall.padding};
  :hover {
    background-color: ${Colors.DefaultBackground};
    border-radius: ${Variables.AtomRadius};
    padding: ${Spacings.xxSmall.padding};
  }
  &.active {
    background-color: ${Colors.DefaultBackground};
    border-radius: ${Variables.AtomRadius};
    padding: ${Spacings.xxSmall.padding};
  }
  .img-icon {
    width: 20px;
  }
`;

export const PopupContainer = styled.div`
  & .ant-picker.ant-picker-range {
    width: 100%;
  }
  & .ant-picker-panel-container {
    box-shadow: none;
  }
  & .ant-picker-range-arrow,
  & .ant-popover-arrow {
    display: none;
  }
  & .ant-popover-content {
    height: 100%;
  }
`;
export const EmptyWrapper = styled.div`
  height: Calc(100vh - 154px);
  display: flex;
  justify-content: center;
  align-items: center;
  .empty-card {
    max-width: 557px;
  }
  .title {
    color: ${Colors.TextSecondary};
    font: ${fonts.Big.bold};
    line-height: ${Variables.LineHeightSm};
    margin-bottom: 2px;
  }
  .description {
    color: ${Colors.TextSecondary};
    font: ${fonts.medium.regular};
    line-height: ${Variables.LineHeightSm};
  }
  .ant-empty-image {
    margin-bottom: 12px;
  }
  .title-platforms {
    color: ${Colors.TextSecondary};
    font: ${fonts.Big.bold};
    line-height: 36px;
    text-align: center;
    margin-bottom: ${Spacings.small.margin};
  }
  .source {
    margin-top: ${Spacings.small.margin};
  }
  .line {
    border-top: 0;
    border-color: ${Colors.Border};
  }
  .col-img {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .col-text-more {
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.medium};
    line-height: 22px;
    text-align: center;
  }
`;
export const Text = styled.div`
  cursor: pointer;
  color: ${Colors.TextSecondary};
  font: ${fonts.xSmall.medium};
  line-height: 14px;
  text-align: center;
  white-space: pre;
  &.active {
    color: ${Colors.PrimaryColor};
    font: ${fonts.xSmall.bold};
    line-height: 14px;
    text-align: center;
  }
  margin-top: 10px;
`;
export const Info = styled.div`
  font: ${fonts.xSmall.medium};
  color: ${Colors.TextSecondary};
  white-space: pre;
  text-align: center;
  padding: ${Spacings.xxSmall.padding} ${Spacings.xxSmall.padding};
  background-color: ${Colors.DefaultBackground};
  border-radius: ${Variables.AtomRadius};
  margin: ${Spacings.small.margin} 0px;
  line-height: 20px;
`;
export const StyledPopover = styled(Popover)``;
export const Wrapper = styled.div`
  display: flex;
  .row {
    width: 100%;
  }
`;
export const StyledDivider = styled.span`
  margin-right: ${Spacings.xSmall.margin};
  margin-left: ${Spacings.xSmall.margin};
  border: 1px solid ${Colors.Border};
  border-left: none;
  min-height: 100%;
`;

export const StyledDiv = styled.div`
  font: ${fonts.small.medium} !important;
  line-height: 22px !important;
  color: ${Colors.TextSecondary};
  font-weight: ${({ weight }) => weight};
  margin-bottom: ${({ bottom }) => bottom || 0};
  display: flex;
  align-items: center;
  .heading {
    cursor: pointer;
    color: ${Colors.PrimaryColor};
    word-break: break-all;
    min-height: 20px;
    height: 100%;
    padding: 2px 0px 0px 0px;
  }
  .bordered {
    border: 1px solid black;
  }
  .tag-text {
    color: ${Colors.TagDark};
    margin: 3px 0px 0px 0px;
  }
  .tag-text-green {
    color: #237804;
  }
  .tag-text-red {
    color: #a8071a;
  }
`;

export const Content = styled(AntContent)`
  .margin-15 {
    margin: 15px;
  }
  .tag-text {
    color: ${Colors.TagDark};
  }
  .name {
    font: ${fonts.small.regular} !important;
    line-height: 22px;
  }
  .text-regular {
    font: ${fonts.small.regular} !important;
    height: 16px;
  }
  .col-1 {
    width: 292px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 16px;
  }
  .platformimage {
    margin-left: 16px;
  }
  .margin-0 {
    margin: 0px;
  }
  .mb-16 {
    margin-bottom: 16px;
  }
  .col-2 {
    padding: 16px 16px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .card-2 {
    padding: 24px 16px;
  }
  .progress {
    margin-right: ${Spacings.small.margin};
  }
  .poll-content {
    font-size: ${defaultFontSize};
  }
  .poll-head {
    font: ${fonts.xxLarge.bold};
    color: ${Colors.ButtonPrimary};
  }
  .poll-percent {
    font-size: 16px;
    font-weight: 700;
  }
  .poll-description {
    font: ${fonts.xSmall.bold};
    color: ${Colors.ButtonPrimary};
    margin-top: 5px;
  }
  .poll-content-xs {
    font-size: ${defaultFontSize};
  }
  .poll-head-xs {
    font: ${fonts.xxSmall.bold};
    color: ${Colors.ButtonPrimary};
  }
  .poll-percent-xs {
    font: ${fonts.xxSmall.bold};
    font-weight: 700;
  }
  .departments-tag-row {
    margin-top: ${Spacings.xSmall.margin};
  }
  .timeline {
    align-items: center;
    color: ${Colors.TextSecondary};
    :last-child {
      margin-top: calc(${Spacings.xSmall.margin} - 4px);
    }
  }

  .profile-col {
    margin-top: calc(${Spacings.xSmall.margin} - 4px);
  }

  .ant-divider.ant-divider-vertical {
    background: rgba(0, 0, 0, 0.2);
  }
  .line {
    margin: 0 12px;
  }
  .col-statistics {
    display: flex;
    margin-top: ${Spacings.xSmall.margin};
  }
  .ant-divider,
  .ant-divider-vertical {
    margin-right: 0px;
    margin-left: 0px;
  }
  .noFlex {
    flex-direction: row;
    padding: 8px 12px;
    margin-right: ${Spacings.xSmall.margin};
  }
  .ant-divider.ant-divider-vertical {
    background: ${Colors.Border};
  }
  .ant-divider.ant-divider-horizontal {
    background: ${Colors.Border};
  }
  .normal {
    font: ${fonts.xSmall.regular};
    margin-right: ${Spacings.small.margin};
    line-height: 26px;
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: #5f3996;
  }

  .result-progress {
    .ant-progress-inner {
      width: 200px;
    }
    .ant-progress-show-info .ant-progress-outer {
      padding-right: ${Spacings.small.margin};
      width: unset;
    }
    .ant-progress-text {
      margin-left: 32px;
      width: unset;
    }
    .progress {
      margin-right: 0;
    }
  }
  .large-button {
    width: 260px;
  }
  .card-1 {
    width: 400px;
    margin-right: 15px;
  }
  .col-end {
    margin-left: 30px;
    color: ${Colors.TextSecondary};
  }
  .icon {
    height: 22px;
    width: 22px;
  }
  .message {
    line-height: 30px;
  }
  .user-profile {
    margin-right: 5px;
    @media (min-width: 768px) and (max-width: 1300px) {
      .name {
        margin: 2px 0px 0px 0px;
      }
    }
  }
`;

export const ProfileWrapper = styled.div`
  margin-bottom: ${Spacings.tiny.margin};
`;

export const ProfileDropdownWrapper = styled.span`
  height: 28px;
`;
