import {
  Form as AntForm,
  Layout,
  TimePicker as AntTimePicker,
} from 'antd';
import styled from 'styled-components';
import { fonts } from 'design/Fonts';
import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  &.ant-layout-content {
    overflow: auto;
    padding: 32px;
    .ant-btn-primary {
      font: ${fonts.small.medium};
      line-height: 22px;
    }
  }
  .ant-card-head{
    border-bottom:1px solid ${Colors.Border}
  }
  .card {
    max-width: 700px;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
  }
`;
export const Image = styled.img`
  width: 18px;
`;

export const Form = styled(AntForm)`
  .ant-row.ant-form-item:last-of-type {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
  .ant-btn {
    font: ${fonts.small.regular};
    line-height: 22px;
  }
  .ant-form-item-extra {
    font: ${fonts.xSmall.regular};
    color: ${Colors.TextEnable};
    margin-top: 4px;
  }

  .file-cursor {
    cursor: pointer;
  }
`;

export const FormItemDiv = styled.div`
  display: flex;
  align-items: baseline;
`;

export const TimePicker = styled(AntTimePicker)`
  width: 100%;
  padding: 5px 16px;
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  display: flex;
  align-items: center;
  font: ${fonts.small.regular};
  .ant-picker-input > input {
    font: ${fonts.small.regular};
    color: ${Colors.TextSecondary};
  }
  @media screen and (max-width: 683px) {
    width: 100%;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
