import { gql } from '@apollo/client';
import { standUpFragment } from './Fragments';

export const CREATE_STANDUP_ENTRY = gql`
  mutation createStandUpEntry($data: standUpEntryDataInput!) {
    createStandUpEntry(data: $data) {
      ...StandUpFragment
    }
  }
  ${standUpFragment}
`;

export const ADD_STANDUP_ENTRY = gql`
  mutation addStandUpEntry($data: addStandUpEntryDataInput!) {
    addStandUpEntry(data: $data) {
      ...StandUpFragment
    }
  }
  ${standUpFragment}
`;
export const UPDATE_STANDUP = gql`
  mutation updateStandUp($data: standUpUpdateInput!) {
    updateStandUp(data: $data) {
      id
    }
  }
`;
export const CHECK_STANDUP_ENTRY = gql`
  mutation checkStandUpEntry($where: checkStandUpInput!) {
    checkStandUpEntry(where: $where)
  }
`;
