import React from 'react';
import _map from 'lodash/map';
import styled from 'styled-components';
import { Popover, Tag as AntTag } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import Colors from 'design/Colors';
import GroupTag from 'atoms/GroupTag';
import Spacings from 'design/Spacings';

const StyledDiv = styled.div`
  width: 250px;
  & > span {
    margin-bottom: ${Spacings.xxSmall.margin};
  }
`;
const Icon = styled.img`
 
`;

const StyledOutLineTag = styled(AntTag)`
  background: ${Colors.IconColor};
  border: 1px solid ${({ border }) => border || Colors.SecondaryColor};
  color: ${({ background }) => background || Colors.ButtonPrimary};
  margin-right: 0;
  border-radius: 14.5px;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  .anticon svg {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
`;

const GroupTagPopover = ({ departments }) => (
  <Popover
    title="Groups"
    // eslint-disable-next-line react/jsx-props-no-spreading
    content={(
      <StyledDiv>
        {_map(departments, (department, index) => (
          <GroupTag key={index} {...department} type={department.type} />
        ))}
      </StyledDiv>
    )}
  >
    <StyledOutLineTag border={Colors.SecondaryColor}>
      <MoreOutlined rotate="90" />
    </StyledOutLineTag>
  </Popover>
);

export default GroupTagPopover;
