import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Menu, Dropdown, Divider, Row, Col,
} from 'antd';
import Button from 'atoms/Button';
import { DownOutlined } from '@ant-design/icons';
import { BUTTON_TYPE, PRIVATE_ROUTE } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import styled, { createGlobalStyle } from 'styled-components';
import { ArrowIcon } from 'assets';
import Colors from 'design/Colors';
import * as eva from 'eva-icons';
import Spacings from 'design/Spacings';
import { fonts } from 'design/Fonts';

export const Wrapper = styled.div`
  .dropdown {
    border-radius: 8px;
    .ant-btn {
      background: ${Colors.ButtonPrimary};
      color: white;
    }
  }
  .ant-btn-group {
    .ant-btn {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }
  .icon {
    margin-left: 25px;
  }
  .ant-btn {
    z-index: 100;
  }
  .buttton-right {
    border-radius: 8px 0px 0px 8px;
    height: 32px;
  }
  .buttton-left {
    border-radius: 0px 8px 8px 0px;
    padding: 5px;
    height: 32px;
  }
`;

export const StyledImg = styled.img`
  width: 16px;
  margin-right: 5px;
  padding-bottom: 4px;
`;

export const StyledDivider = styled(Divider)`
  margin: 0px;
`;
export const StyledTitle = styled.p`
  font: ${fonts.small.normal};
  color: ${Colors.TextSecondary};
  display: inline;
  font-weight: 500;
`;
export const GlobalStyles = createGlobalStyle`
    .ant-dropdown-menu {
      border-radius: 8px;
      padding: 0px;
    }
    .ant-dropdown{
      z-index:0
    }
`;

function DropdownButton({
  handlePublished,
  handleSaveAsDraft,
  htmlType,
  disabled,
}) {
  useEffect(() => {
    eva.replace();
  }, []);
  const menu = (
    <Menu>
      <GlobalStyles />
      <Menu.Item key={0} onClick={() => handleSaveAsDraft()}>
        <StyledTitle>
          <FormattedMessage id="dropdownButton.saveAsDraft" />
        </StyledTitle>
      </Menu.Item>
    </Menu>
  );
  return (
    <Wrapper>
      <Row>
        <Col>
          <Button
            disabled={disabled}
            name="Publish"
            onClick={() => handlePublished()}
            className="buttton-right"
            htmlType={htmlType}
            primary
          />
        </Col>
        <Col>
          <Dropdown trigger={['click']} placement="bottomRight" overlay={menu}>
            <Button
              className="buttton-left"
              primary
              name={(
                <div>
                  <i
                    data-eva="arrow-ios-downward-outline"
                    data-eva-fill="#ffffff"
                    data-eva-height="18"
                    data-eva-width="18"
                  />
                </div>
              )}
              focus={Colors.ButtonPrimary}
              disabled={disabled}
            />
          </Dropdown>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default withRouter(DropdownButton);
