import { gql } from '@apollo/client';

export const GET_USER_CREATED_COMPANY = gql`
  query companies($where: CompanyWhereInput) {
    companies(where: $where) {
      id
      name
      subDomain
      logo
      locations{
        id
        name
        shortName
        timeZone
        isDefault
      }
      createdBy {
        firstName
        lastName
        profileImage
      }
    }
  }
`;
