export const requiredHours = [
  { hours: '08:00:00Z', text: '08:00:00' },
  { hours: '09:00:00Z', text: '09:00:00' },
  { hours: '10:00:00Z', text: '10:00:00' },
];

export const minHours = [
  { hours: '07:00:00Z', text: '07:00:00' },
  { hours: '08:00:00Z', text: '08:00:00' },
  { hours: '09:00:00Z', text: '09:00:00' },
];

export const minHalfHours = [
  { hours: '03:00:00Z', text: '03:00:00' },
  { hours: '04:00:00Z', text: '04:00:00' },
  { hours: '05:00:00Z', text: '05:00:00' },
];

export const allowFlexibility = [
  { hours: '00:30:00Z', text: '00:30:00' },
  { hours: '01:00:00Z', text: '01:00:00' },
  { hours: '01:30:00Z', text: '01:30:00' },
  { hours: '02:00:00Z', text: '02:00:00' },
];
