import styled from 'styled-components';
import { Typography } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Text } = Typography;

export const Content = styled.div`
  .btn {
    margin-top: ${Spacings.xxSmall.margin};
  }
  .groups {
    margin-top: ${Spacings.tiny.margin};
  }
  .result-stats {
    display: flex;
    align-items: center;
    font-size: ${fonts.xSmall.size};
    margin-top: ${Spacings.xSmall.margin};
    margin-bottom: ${Spacings.xSmall.margin};
    .percent {
      color: ${Colors.PrimaryColor};
      margin: 0 ${Spacings.xxSmall.margin};
    }
    .ant-progress {
      line-height: normal;
      font-size: ${fonts.small.size};
    }
    .head {
      display: none;
    }
  }
  .icon {
    display: flex;
  }
`;

export const StyledText = styled(Text)`
  font: ${fonts.xSmall.regular};
  color: ${Colors.Text};
  margin-top: ${Spacings.xxSmall.margin};
  margin-bottom: ${Spacings.xxSmall.margin};
`;

export const Heading = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #5f3996;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  width: 100%;
  max-width: 150px;
  font: ${fonts.small.medium};
  color: ${Colors.TextPrimary};
  line-height: 22px;
  margin-bottom: 0px;
  margin-left: ${Spacings.tiny.margin};
`;
export const Date = styled.div`
  font: ${fonts.small.medium};
  color: ${Colors.TextPrimary};
  line-height: 22px;
  margin-bottom: 0px;
  margin-right: ${Spacings.tiny.margin};
`;
