import { gql } from '@apollo/client';
import { quoteFragment, quoteSettingFragment } from './Fragments';

export const CREATE_QUOTE = gql`
  mutation createQuote($data: QuoteCreateInput!) {
    createQuote(data: $data) {
      ...QuoteFragment
    }
  }
  ${quoteFragment}
`;

export const UPDATE_QUOTE = gql`
  mutation updateQuote(
    $data: QuoteUpdateInput!
    $where: QuoteWhereUniqueInput!
  ) {
    updateQuote(data: $data, where: $where) {
      ...QuoteFragment
    }
  }
  ${quoteFragment}
`;

export const DELETE_QUOTE = gql`
  mutation deleteQuote($where: QuoteWhereUniqueInput!) {
    deleteQuote(where: $where) {
      ...QuoteFragment
    }
  }
  ${quoteFragment}
`;

export const CREATE_QUOTE_SETTING = gql`
  mutation createQuoteSetting($data: QuoteSettingInput) {
    createQuoteSetting(data: $data) {
      ...QuoteSettingFragment
    }
  }
  ${quoteSettingFragment}
`;

export const UPDATE_QUOTE_SETTING = gql`
  mutation updateQuoteSetting($data: QuoteSettingInput) {
    updateQuoteSetting(data: $data) {
      ...QuoteSettingFragment
    }
  }
  ${quoteSettingFragment}
`;
