import { gql } from '@apollo/client';

export const GET_INTEGRATIONS = gql`
  query integrations {
    integrations {
      platformKey
      data
      status
      id
    }
  }
`;

export const GET_LISTCHANNELS = gql`
  query listChannels($where: platformKeyInput!) {
    listChannels(where: $where) {
      data
      integration {
        status
        data
        platformKey
      }
      spaceId
      data
      type
      displayName
      isActive
    }
  }
`;

export const GET_CONNECTED_CHANNELS = gql`
  query connectedChannels($where: platformKeyInput!) {
    connectedChannels(where: $where) {
      id
      platformKey
      data
      spaceId
      type
      isActive
      displayName
    }
  }
`;

export const GET_PLATFORMS = gql`
  query getPlatforms {
    getPlatforms {
      platformKey
      isActive
      name
      description
      status
      url
      redirect
      Module
    }
  }
`;

export const GET_NOTIFICATION_SETTINGS_GROUP = gql`
  query notificationSettingsGroup($where: groupIntegrationUnique!) {
    notificationSettingsGroup(where: $where) {
      key
      label
      isActive
    }
  }
`;
