import styled from 'styled-components';
import { Layout } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  &.ant-layout-content {
    padding: ${Spacings.medium.padding};
    overflow-y: hidden;
    .ant-btn-primary {
      line-height: 22px;
    }
  }

  .back-icon {
    border-radius: 4px;
    background: linear-gradient(#6fbaff, rgba(0, 0, 0, 0.4));
    color: ${Colors.TextLight};
    cursor: pointer;
  }

  .right {
    float: right;
    margin-bottom: 0;
    width: fit-content;
  }
  .card {
    max-width: 700px;
    height: fit-content;
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
  }
`;

export const Wrapper = styled.div`
  max-width: 700px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font: ${fonts.medium.regular};
  border-radius: 12px;
  background-color: ${Colors.Container};
  @media (max-width: 1300px) {
    max-width: 600px;
  }
  .ant-tabs {
    color: ${Colors.TextEnable};
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 0px 0px 16px 0px;
    margin: 0 30px 0 0;
    font: ${fonts.small.regular};
  }
  .ant-tabs-bar {
    border-bottom: 2px solid #cad6e3;
  }
  .ant-tabs-ink-bar {
    height: 4px;
  }
  .ant-tabs-nav-container {
    font: ${fonts.medium.regular};
  }
`;


export const Title = styled.div`
  font: ${fonts.xxLarge.bold};
  color: ${Colors.TextPrimary};
  text-align: center;
  line-height: 41px;
  margin-bottom: ${Spacings.xxSmall.margin};
`;

export const Description = styled.div`
  font-size: ${fonts.small.regular};
  line-height: 22px;
  text-align: center;
  padding: 0px 0px 5px;
  color: ${Colors.TextSecondary};
`;
export const ConfirmLink = styled.p`
  font-size: ${fonts.small.regular};
  line-height: 26px;
  text-align: center;
  padding: 0px 0px 10px;
  color: ${Colors.TextSecondary};
`;
