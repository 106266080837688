/* eslint-disable no-nested-ternary */
//! THIS IS A DEPRECATED FILE, MIGHT BE USED IN FUTURE
import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  Button as AntButton,
  // Checkbox,
  // Form,
  // Input,
  // Select,
  Popover,
  Skeleton,
  Row,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FiCheckSquare, FiSquare } from 'react-icons/fi';
import GroupTag from 'atoms/GroupTag';
import {
  ArrowIcon,
  UnCheck,
  CheckIcon,
  ArchiveIcon,
  AddIcon,
  Checked,
} from 'assets';
import InlineEditable from 'molecules/InlineEditable';
import Colors from 'design/Colors';
import Card from 'atoms/Card';
import Input from 'atoms/Input';
import CommentsPopover from 'organisms/CommentsPopover';
import Button from 'atoms/Button';
import {
  EMPTY_ARRAY,
  FETCH_POLICY,
  TASK_FILED,
  BUTTON_TYPE,
  TASK_TYPE,
  GROUP_TYPE,
} from 'utils/constants';
import GroupOrUser from 'molecules/GroupOrUser';
import { GET_GROUPS } from 'graphql/Groups/Queries';
import Table from 'atoms/Table';
import DateTime from 'atoms/DateTime';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import { getTrimValues } from 'utils';
import Hours from 'atoms/Hours';
import _get from 'lodash/get';
import TriStateSwitch from 'atoms/TriStateSwitch';
import _filter from 'lodash/filter';
import ReasonDeletion from '../ReasonDeletion';
import {
  ContentTitle,
  Comments,
  Title,
  TaskContent,
  Arrow,
  Select,
  Image,
  Form,
  TitleDiv,
  DailyGoalsCount,
} from './AntStyled';
import TaskCompletion from '../TaskCompletion';

const { Option } = Select;
const { Text } = Typography;

export default function ({
  data,
  onCreate,
  handleCheckbox,
  updateTask,
  archiveTask,
  allowModify = false,
  title,
  loading,
  groupLoading,
  setSortData,
  taskState,
  dailyGoalsCount,
}) {
  const [form] = Form.useForm();

  const { data: groupsData } = useQuery(GET_GROUPS, {
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
  });

  const [dataSource, setDataSource] = useState(data);
  const [deletionId, setDeletionId] = useState(null);
  const [completionId, setCompletionId] = useState(null);
  // const [count, setCount] = useState(dataSource.length);
  const [group, setGroup] = useState(EMPTY_ARRAY);
  const [selectedTaskType, setSelectedTaskType] = useState('all');

  useEffect(() => {
    if (data) {
      let taskTableData = [];
      taskTableData = _map(data, (row, key) => ({
        key: key.toString(),
        id: row.id,
        isCompleted: row.isCompleted,
        completedOn: row.completedOn,
        name: row.name,
        estimation: row.estimation,
        group: JSON.stringify(row.group),
        comments: row.comments,
        createdAt: row.createdAt,
        loading: row.loading,
        completionHours: row.completionHours,
      }));
      setDataSource(taskTableData);
    }
  }, [data]);

  useEffect(() => {
    if (groupsData) {
      const { groups } = groupsData;
      const groupChildren = _map(groups.groups, (items, index) => (
        <Option key={index} value={JSON.stringify(items)}>
          <GroupOrUser data={{ group: items }} />
        </Option>
      ));
      setGroup(groupChildren);
    }
  }, [groupsData]);

  const inLineEditable = (dataIndex, inputRef, save) => {
    let node;
    switch (dataIndex) {
      case TASK_FILED.NAME:
        node = (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                whitespace: true,
                message: (
                  <FormattedMessage id="me.formRules.name.errorMessage" />
                ),
              },
            ]}
          >
            <Input innerRef={inputRef} onPressEnter={save} onBlur={save} />
          </Form.Item>
        );
        break;
      case TASK_FILED.ESTIMATION:
        node = (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="me.formRules.estimation.errorMessage" />
                ),
              },
              {
                pattern: /^\d{1,2}(\.\d{1,2})?$/,
                message: (
                  <FormattedMessage id="me.formRules.estimation.hoursPattern" />
                ),
              },
            ]}
          >
            <Input innerRef={inputRef} onPressEnter={save} onBlur={save} />
          </Form.Item>
        );
        break;
      default:
        node = (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="me.formRules.groups.errorMessage" />
                ),
              },
            ]}
          >
            <Select
              ref={inputRef}
              onBlur={save}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {group}
            </Select>
          </Form.Item>
        );
        break;
    }
    return node;
  };
  // const tagRender = (props) => {
  //   const { items } = props;
  //   return (
  //     <GroupOrUser data={{ group: items }} />
  //   );
  // };

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      className: 'check-mark no-padding',
      align: 'center',
      render: (isCompleted) => isCompleted && <Image src={Checked} />,
      // sorter: (a, b) => a.isCompleted - b.isCompleted,
    },
    {
      title: 'Daily Goals',
      dataIndex: 'name',
      // width: allowModify ? '38%' : '47%',
      className: 'action-name',
      editable: true,
      render: (name, rowData) =>
        rowData.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : (
          <Title isCompleted={rowData.isCompleted}>{name} </Title>
        ),
      // sorter: (a, b) => a.name - b.name,
    },
    {
      title: 'Group',
      dataIndex: 'group',
      editable: true,
      width: '20%',
      render: (row, rowData) =>
        rowData.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : (
          <GroupOrUser data={rowData} />
        ),
      // sorter: (a, b) => JSON.parse(a.group).name.length - JSON.parse(b.group).name.length,
    },
    {
      title: 'Estimation',
      dataIndex: 'estimation',
      width: '16.5%',
      editable: true,
      render: (estimation, rowData) =>
        rowData.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : (
          <div>
            <Hours estTime={estimation} />
          </div>
        ),
      // sorter: (a, b) => a.estimation - b.estimation,
    },
    // {
    //   title: 'Spent',
    //   dataIndex: 'completionHours',
    //   width: '7%',
    //   editable: false,
    //   render: (spent, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : spent ? <div><Hours estTime={spent || ''} /></div> : '-',
    //   // sorter: (a, b) => a.estimation - b.estimation,
    // },
    // {
    //   title: 'Created On',
    //   dataIndex: 'createdAt',
    //   width: '10%',
    //   render: (row, rowData) => rowData.loading ? (
    //     <Skeleton paragraph={{ rows: 1 }} title={false} active />
    //   ) : (
    //     <DateTime date={row} format={MomentFormat.US} />
    //   ),
    //   // sorter: (a, b) => a.createdAt - b.createdAt,
    // },
    {
      // title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
      width: '75px',
      render: (row, rowData) =>
        !rowData.loading && (
          <Comments>
            <CommentsPopover data={rowData.comments} actionId={rowData.id} />
          </Comments>
        ),
    },
    {
      align: 'center',
      className: 'task-checkbox no-padding',
      width: '32px',
      render: (rowData) =>
        !rowData.loading ? (
          <div className={`task-status ${allowModify ? 'modify' : ''}`}>
            {allowModify ? (
              <Popover
                visible={rowData.id === completionId}
                onVisibleChange={(visible) =>
                  !visible && closeCompletionModal()
                }
                content={
                  <TaskCompletion
                    completeTask={(value) =>
                      updateTask(
                        {
                          ...rowData,
                          isCompleted: !rowData.isCompleted,
                          completionHours: value && parseFloat(value),
                        },
                        TASK_TYPE.PLAN
                      ).then(() => closeCompletionModal())
                    }
                    closeModal={closeCompletionModal}
                  />
                }
                overlayClassName="popover-no-padding"
                trigger="click"
                placement="left"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleComplete(!rowData.isCompleted, rowData)}
                >
                  {rowData.isCompleted ? (
                    <Image src={UnCheck} />
                  ) : (
                    <Image src={CheckIcon} />
                  )}
                </div>
              </Popover>
            ) : rowData.isCompleted ? (
              <Image src={UnCheck} />
            ) : (
              <Image src={CheckIcon} />
            )}
          </div>
        ) : null,
      // render: rowData => !rowData.loading ? allowModify ? <Checkbox key={rowData.id} checked={rowData.isCompleted} disabled={!allowModify} onChange={e => handleCheckbox(e.target.checked, rowData.id)} /> : rowData.isCompleted && <CheckOutlined twoToneColor /> : null,
    },
    {
      align: 'right',
      dataIndex: 'operation',
      width: '1%',
      className: 'padding-col',
      render: (row, rowData) =>
        !rowData.loading && (
          <Popover
            overlayStyle={{ borderRadius: '12px' }}
            visible={rowData.id === deletionId}
            onVisibleChange={(visible) =>
              !visible && closeReasonDeletionModal()
            }
            content={
              <ReasonDeletion
                deleteTask={(value) =>
                  archiveTask(value, deletionId, TASK_TYPE.PLAN)
                }
                closeModal={closeReasonDeletionModal}
              />
            }
            overlayClassName="popover-no-padding"
            trigger="click"
            placement="left"
          >
            <Image
              src={ArchiveIcon}
              className="icon-delete"
              onClick={() => setDeletionId(rowData.id)}
            />
          </Popover>
        ),
    },
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];
  if (!allowModify) {
    columns.slice(columns.length - 2);
  }

  const handleComplete = (value, rowData) => {
    if (value) {
      setCompletionId(rowData.id);
    } else {
      // handleCheckbox(value, id, TASK_TYPE.PLAN);
      updateTask(
        {
          ...rowData,
          isCompleted: !rowData.isCompleted,
          completionHours: value && parseFloat(value),
        },
        TASK_TYPE.PLAN
      );
    }
  };

  const createPlan = async (values) => {
    const trimValues = getTrimValues(values);
    onCreate(trimValues, 'PLAN').then(() => {
      form.resetFields();
    });
  };

  const closeReasonDeletionModal = () => {
    setDeletionId(null);
  };

  function onChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'ascend') {
        setSortData({ field: sorter.field, type: 'ASC' });
      } else {
        setSortData({ field: sorter.field, type: 'DESC' });
      }
    }
  }
  const closeCompletionModal = () => {
    setCompletionId(null);
  };

  const locale = {
    emptyText: data && data.length === 0 && dailyGoalsCount > 0 && (
      <Text type="secondary">{`${dailyGoalsCount} goals(s) in other group(s)`}</Text>
    ),
  };

  return (
    <Card padding="0" bottom="30px">
      <TaskContent>
        <TitleDiv>
          {/* <ContentTitle>{title || <FormattedMessage id="me.tasks" />}</ContentTitle> */}
          {/* <TriStateSwitch values={taskState} selected={selectedTaskType} setSelectedTaskType={setSelectedTaskType} /> */}
        </TitleDiv>
        {allowModify ? (
          <InlineEditable
            dataSource={
              selectedTaskType === 'all'
                ? dataSource
                : _filter(dataSource, {
                    isCompleted: selectedTaskType === 'completed',
                  })
            }
            columns={columns}
            updateData={(e) => updateTask(e, TASK_TYPE.PLAN)}
            inLineEditable={inLineEditable}
            loading={groupLoading === true ? groupLoading : loading}
            onChange={onChange}
          >
            <Form
              name="taskForm"
              layout="inline"
              onFinish={createPlan}
              form={form}
              // onValuesChange={onValuesChange}
            >
              <Col className="name" span={7}>
                {/* <Form.Item
                      name="isChecked"
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Form.Item> */}
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: (
                        <FormattedMessage id="me.formRules.name.errorMessage" />
                      ),
                    },
                  ]}
                >
                  <Input
                    className="title"
                    placeholder="Title"
                    allowClear
                    id="task-name"
                    autoFocus
                  />
                </Form.Item>
              </Col>
              <Col className="groups" span={3} offset={5}>
                <Form.Item
                  name="groups"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="me.formRules.groups.errorMessage" />
                      ),
                    },
                  ]}
                >
                  <Select
                    placeholder="Group"
                    showSearch
                    showArrow
                    suffixIcon={<Arrow src={ArrowIcon} />}
                    filterOption={(input, option) => {
                      const groupFilter = _get(
                        option,
                        'children.props.children.props.item',
                        ''
                      );
                      return groupFilter?.name
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase());
                    }}
                    getPopupContainer={(trigger) => trigger?.parentNode}
                  >
                    {group}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="estimation" span={2} offset={2}>
                <Form.Item
                  name="estimation"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="me.formRules.estimation.errorMessage" />
                      ),
                    },
                    {
                      pattern: /^\d{1,2}(\.\d{1,2})?$/,
                      message: (
                        <FormattedMessage id="me.formRules.estimation.hoursPattern" />
                      ),
                    },
                  ]}
                >
                  <Input placeholder="Hours" allowClear />
                </Form.Item>
              </Col>
              <Col className="submitBtn" span={3} offset={2}>
                <Form.Item className="text-right">
                  <Button
                    tableButton
                    htmlType={BUTTON_TYPE.SUBMIT}
                    type={BUTTON_TYPE.PRIMARY}
                    name="Save"
                  />
                </Form.Item>
              </Col>
            </Form>
          </InlineEditable>
        ) : (
          <>
            <Table
              className="taskTable bb-0"
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              onChange={onChange}
              locale={locale}
            />
            {data && data.length !== 0 && dailyGoalsCount > 0 && (
              <DailyGoalsCount>
                <Text type="secondary">{`+ ${dailyGoalsCount} goals(s) in other group(s)`}</Text>
              </DailyGoalsCount>
            )}
          </>
        )}
      </TaskContent>
    </Card>
  );
}
