import styled from 'styled-components';

import Colors from 'design/Colors';

export const Profile = styled.div`
  display: flex;
  /* align-items: center; */
  flex-grow: 1;
  cursor: pointer;
`;

export const Li = styled.li`
  white-space: nowrap;
  display: inline-block;
  width: 21px;
  z-index: ${({ index }) => index || 0};

  &:hover .ant-avatar {
    border: 2px solid #5f3996;
  }

  &:hover .delete-icon {
    display: inline-block;
  }

  .ant-btn-icon-only {
    width: 32px;
    height: 32px;
  }

  .tooltip {
    position: relative;
  }
  .delete-icon {
    z-index: 1;
    position: relative;
    top: -17px;
    display: none;
    right: 27px;
  }
`;
export const Close = styled.img``;
