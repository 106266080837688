import styled, { css } from 'styled-components';
import { fonts } from 'design/Fonts';
import { COLORS } from 'utils/constants';
import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
import {
  Select as AntSelect,
  Modal as AntModal,
  Form as AntForm,
  DatePicker as AntPicker,
  Input,
} from 'antd';
const { TextArea } = Input;
export const Calendar = styled.img`

`;

export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
  .dropdown {
    border-radius: 0px 0px 10px 10px;
  }
  .ant-form-item {
    margin-bottom: 20px;
    &:nth-child(3) {
        margin-bottom: 0px;
    }
  }
  .btn-reject-cancel {
    float: left;
  }
  .btn-reject-reason {
    float: right;
  }

`;

export const Modal = styled(AntModal)`
  .ant-modal-body {
    padding:20px;
  }
  .ant-modal-header {
    padding: 20px;
    border-bottom: 1px solid ${Colors.Border};
  }
  .ant-modal-footer {
    padding: 0px 20px 20px;
    border-top: 0px;
  }

  .ant-modal-title {
    font: ${fonts.xLarge.bold};
    line-height: 24px;
    color: ${Colors.TextDark};
  }

  .ant-modal-content {
    max-height: calc(100vh - 120px);
    overflow: auto;
    border-radius: 12px;
    border: 1px solid ${Colors.Border};
  }
  .ant-btn {
    width:48.5%;
    font: ${fonts.xSmall.regular};
    line-height: 22px;
  }
`;
export const TextBox = styled(TextArea)`
  border: 1px solid ${Colors.Border};
  border-radius: 10px;
  /* margin-bottom: ${Spacings.small.margin}; */
`;
export const Select = styled(AntSelect)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 12px;
    border: none;
    height: 30px;
    background-color: transparent;
  }
  .ant-select-selection-placeholder {
    opacity: 1;
  }
  .ant-select-item {
    padding:0px;
    line-height: 13px !important;

  }
  .ant-select-item-option {
    border-bottom: 1px solid red !important;
  }
`;
export const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 2px;
  margin-top: -4px;
`;
