import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';

export const QuestionTitle = styled.div`
  font:${fonts.small.bold};
  color:${Colors.TextDark};
  margin-bottom:12px;
`;
export const LikeDislikeWrapper = styled.div`
  .like,.dislike{
    border-radius:50%;
    padding:8px 10px;
    img{
      cursor: pointer;
    }
  }
  .like.active{
    background:${Colors.LikeBackground};
  }
  .dislike.active{
    background:${Colors.DislikeBackground};
  }
`;
