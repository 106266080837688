import { gql } from '@apollo/client';

export const companyFragment = gql`
  fragment CompanyFragment on Company {
    id
    name
    logo
    createdAt
    updatedAt
  }
`;
