import React, { useEffect, useContext, useState, useCallback } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import _filter from 'lodash/filter';
import kebabCase from 'lodash/kebabCase';
import { message, Form, Row, Col } from 'antd';
import moment from 'moment';
import client from 'apollo';
import { AppContext } from 'AppContext';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import Input from 'atoms/Input';
import { getBase64, getTrimValues } from 'utils';
import {
  EMPTY_STRING,
  FETCH_POLICY,
  FILE_TYPE,
  FORM_NAME,
  BUTTON_TYPE,
  PRIVATE_ROUTE,
  AMPLITUDE_EVENTS,
  REGEX,
} from 'utils/constants';
import { GET_USER_CREATED_COMPANY } from 'graphql/Onboarding/Queries';
import { CREATE_COMPANY, UPDATE_COMPANY } from 'graphql/Onboarding/Mutations';
import TimezoneSelector from 'atoms/TimezoneSelector';
import * as Sentry from '@sentry/react';
import { withRouter } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { LogoCompany, AddProfile } from 'assets';
import { GET_CURRENT_USER } from 'graphql/CurrentUser/queries';
import { createAmplitudeEvent } from 'utils/amplitude';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import ReactGA from 'react-ga';
import {
  Title,
  Container,
  ProfilePage,
  Wrapper,
  StyledForm,
  Terms,
  UploadIcon,
  AvatarIcon,
  LogoIcon,
  FullDivider,
} from './AntStyled';
const Organization = (props) => {
  const {
    slider,
    intl,
    userCreatedCompanyData,
    setUserCreatedCompanyData,
    history,
  } = props;

  const [form] = Form.useForm();
  const { state } = useContext(AppContext);
  const [logo, setLogo] = useState(EMPTY_STRING);
  const [userProfile, setUserProfile] = useState(EMPTY_STRING);
  const [count, setCount] = useState(0);
  const [domainChanged, setDomainChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const fetchData = useCallback(() => {
    client
      .query({
        query: GET_USER_CREATED_COMPANY,
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
        variables: { where: { createdById: { id: state?.loginUser?.id } } },
      })
      .then(({ data: userCreateCompanyData }) => {
        setUserCreatedCompanyData(userCreateCompanyData);
        if (userCreateCompanyData?.companies?.length > 0) {
          const company = userCreateCompanyData?.companies[0];
          if (count === 0) {
            const location = _filter(
              company?.locations,
              (loc) => loc?.isDefault
            );
            form.setFieldsValue({
              ...company,
              fullName: `${company?.createdBy?.firstName || EMPTY_STRING} ${
                company?.createdBy?.lastName || EMPTY_STRING
              }`,
              profileImage: company?.createdBy?.profileImage,
              timeZone: location[0]?.timeZone,
            });
            setLogo(company?.logo);
            setUserProfile(company?.createdBy?.profileImage);
          }
          setCount(1);
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.log(e);
      });
  }, [form, count, state.loginUser, setUserCreatedCompanyData]);

  useEffect(() => {
    if (state?.loginUser?.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.loginUser?.id, fetchData]);

  const onFinish = async (values) => {
    setLoading(true);
    const newVal = getTrimValues(values);

    const {
      fullName,
      name,
      workSpace,
      profileImage,
      logo: companyLogo,
      timeZone,
    } = newVal;
    // eslint-disable-next-line prefer-const
    let [firstName, ...lastName] = fullName.split(' ');
    lastName = lastName.join(' ');

    if (userCreatedCompanyData?.companies?.length > 0) {
      client
        .mutate({
          mutation: UPDATE_COMPANY,
          variables: {
            name,
            firstName,
            lastName,
            timeZone,
            workSpace,
            logo:
              companyLogo && typeof companyLogo === 'object'
                ? companyLogo?.file?.originFileObj
                : undefined,
            profileImage:
              profileImage && typeof profileImage === 'object'
                ? profileImage?.file?.originFileObj
                : undefined,
            where: {
              id: parseInt(userCreatedCompanyData?.companies[0]?.id, 10),
            },
          },
          refetchQueries: [
            {
              query: GET_USER_CREATED_COMPANY,
              variables: { createdById: { id: state?.loginUser?.id } },
            },
            {
              query: GET_CURRENT_USER,
              fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
            },
          ],
        })
        .then(() => {
          setLoading(false);
          message.destroy();
          history.push(PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.GROUPS);
          form.resetFields(['logo', 'profileImage']);
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
          setLoading(false);
        });
    } else {
      client
        .mutate({
          mutation: CREATE_COMPANY,
          variables: {
            name,
            firstName,
            lastName,
            workSpace,
            timeZone,
            logo: companyLogo ? companyLogo?.file?.originFileObj : undefined,
            profileImage: profileImage
              ? profileImage?.file?.originFileObj
              : undefined,
          },
          refetchQueries: [
            {
              query: GET_USER_CREATED_COMPANY,
              variables: { createdById: { id: state?.loginUser?.id } },
            },
            {
              query: GET_CURRENT_USER,
              fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
            },
          ],
        })
        .then((res) => {
          const eventProperties = {
            'Company Id': res?.data?.createCompany?.id,
            'Company Name': res?.data?.createCompany?.name,
            'Workspace Name': res?.data?.createCompany?.workSpace,
            'Workspace Logo': res?.data?.createCompany?.logo,
            createdAt: moment
              .utc(res?.data?.createCompany?.createdAt)
              .local()
              .format(MomentFormat.dateTime),
            updatedBy: res?.data?.createCompany?.updatedBy?.email,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENTS.COMPANY_CREATED,
            eventProperties
          );
          ReactGA.event({
            category: 'Tenant signups',
            action: 'New tenant signups',
            label: 'Today tenant signups',
          });
          fetchData();
          setLoading(false);
          message.destroy();
          history.push(PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.MY_PROFILE);
          form.resetFields(['logo', 'profileImage']);
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
          setLoading(false);
        });
    }
  };

  const changeLogo = (file) => {
    getBase64(file, (imageUrl) => setLogo(imageUrl));
  };

  const changeUserProfile = (file) => {
    getBase64(file, (imageUrl) => setUserProfile(imageUrl));
  };

  const uploadProps = {
    customRequest() {
      return false;
    },
  };

  const beforeUploadTest = (file, data) => {
    if (data === 'logo') {
      changeLogo(file);
    } else {
      changeUserProfile(file);
    }
    // eslint-disable-next-line consistent-return
    return true;
  };

  const beforeCropTest = (file, data) => {
    const isJpgOrPng =
      file?.type === FILE_TYPE.JPEG || file?.type === FILE_TYPE.PNG;
    if (!isJpgOrPng) {
      message.error(
        intl.formatMessage({ id: 'onboarding.errorMessage.fileUpload' })
      );
      if (data === 'logo') {
        form.resetFields(['logo']);
      } else {
        form.resetFields(['profileImage']);
      }
      return;
    }
    const isLt2M = file?.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(
        intl.formatMessage({ id: 'onboarding.errorMessage.fileSize' })
      );
      if (data === 'logo') {
        form.resetFields(['logo']);
      } else {
        form.resetFields(['profileImage']);
      }
      return;
    }

    // eslint-disable-next-line consistent-return
    return isJpgOrPng && isLt2M;
  };

  const onChange = (file, type) => {
    if (type === 'logo') {
      form.setFieldsValue({ logo: file });
    } else {
      form.setFieldsValue({ profileImage: file });
    }
  };

  const handleCompanyChange = (value) => {
    if (!domainChanged) {
      form.setFieldsValue({ workSpace: kebabCase(value) });
    }
  };

  return (
    <Container id="drop">
      <Wrapper>
        <Card padding="32" className="small">
          <StyledForm
            form={form}
            layout={FORM_NAME.VERTICAL}
            onFinish={onFinish}
            initialValues={{ timeZone: moment.tz.guess() }}
          >
            <Title className="title">
              <FormattedMessage id="onboarding.title" />
            </Title>
            <Row align="middle" gutter={24}>
              <Col span={5}>
                <Form.Item name="logo">
                  <ImgCrop
                    rotate
                    beforeCrop={(file) => beforeCropTest(file, 'logo')}
                  >
                    <UploadIcon
                      showUploadList={false}
                      beforeUpload={(file) => beforeUploadTest(file, 'logo')}
                      onChange={(e) => onChange(e, 'logo')}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...uploadProps}
                    >
                      <AvatarIcon
                        className="back-icon"
                        src={logo}
                        icon={<LogoIcon src={LogoCompany} alt="logo" />}
                        shape="circle"
                        size={52}
                      >
                        logo
                      </AvatarIcon>
                    </UploadIcon>
                  </ImgCrop>
                </Form.Item>
              </Col>
              <Col span={19}>
                <Form.Item
                  colon={false}
                  label="Company Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: (
                        <FormattedMessage id="onboarding.formRules.name.errorMessage" />
                      ),
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => handleCompanyChange(e?.target?.value)}
                    className="inputLogo2"
                    placeholder="Company Name"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              colon={false}
              label="Workspace Name"
              name="workSpace"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: (
                    <FormattedMessage id="onboarding.formRules.subDomain.errorMessage" />
                  ),
                },
              ]}
            >
              <Input
                placeholder="Workspace Name"
                allowClear
                onChange={() => setDomainChanged(true)}
              />
            </Form.Item>
            <TimezoneSelector
              getPopupContainer={() => document.getElementById('drop')}
              formItemClassName="no-mb"
              label="Location Timezone"
            />
            <FullDivider></FullDivider>
            <ProfilePage>
              <Row align="middle" gutter={24}>
                <Col span={5}>
                  <Form.Item name="profileImage" className="mb-0">
                    <ImgCrop
                      rotate
                      beforeCrop={(file) => beforeCropTest(file, 'logo')}
                    >
                      <UploadIcon
                        showUploadList={false}
                        beforeUpload={(file) =>
                          beforeUploadTest(file, 'userProfile')
                        }
                        onChange={(e) => onChange(e, 'profileImage')}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...uploadProps}
                      >
                        <AvatarIcon
                          className="back-icon"
                          src={userProfile}
                          shape="circle"
                          size={52}
                          icon={<LogoIcon src={AddProfile} alt="logo" />}
                        />
                      </UploadIcon>
                    </ImgCrop>
                  </Form.Item>
                </Col>
                <Col span={19}>
                  <Form.Item
                    label="Your Full Name"
                    name="fullName"
                    className="mb-0"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: (
                          <FormattedMessage id="myProfile.formRules.fullName.errorMessage" />
                        ),
                      },
                      {
                        pattern: REGEX.NAME,
                        message: (
                          <FormattedMessage id="myProfile.formRules.fullName.fullNamePattern" />
                        ),
                      },
                    ]}
                  >
                    <Input
                      className="inputLogo"
                      placeholder="Your full name"
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ProfilePage>
          </StyledForm>
        </Card>
        <StyledForm
          form={form}
          layout={FORM_NAME.VERTICAL}
          onFinish={onFinish}
          initialValues={{ timeZone: moment.tz.guess() }}
        >
          <Terms>
            <Button
              primary
              loading={loading}
              type={BUTTON_TYPE.PRIMARY}
              htmlType={BUTTON_TYPE.SUBMIT}
              name="Confirm"
            />
          </Terms>
        </StyledForm>
      </Wrapper>
    </Container>
  );
};

export default withRouter(injectIntl(Organization));
