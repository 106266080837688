import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';
import { BuildOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Spacings from 'design/Spacings';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { GROUP_TYPE } from 'utils/constants';
import Variables from 'design/Variables';
import { CloseCircle } from 'assets';

const ColorsBox = styled.div`
  width: ${({ width }) => width || 18}px;
  min-width: 10px;
  height: ${({ height }) => height || 18}px;
  background: ${({ color }) => color || Colors.ButtonPrimary};
  margin-right: ${({ right }) => right || Spacings.xxSmall.margin};
  border-radius: ${Variables.AtomRadius};
`;

const StyledTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  border-radius: 14.5px;
  cursor: inherit;
  background-color: ${({ color, type }) =>
    type === GROUP_TYPE.PROJECT
      ? Colors.SidebarText
      : color || Colors.SidebarText} !important;
  border: ${({ type }) =>
    type === GROUP_TYPE.PROJECT
      ? `1px solid ${Colors.SecondaryColor}`
      : `1px solid ${Colors.SecondaryColor}`};
  color: ${({ type }) =>
    type === GROUP_TYPE.PROJECT ? Colors.TextPrimary : Colors.TextPrimary};
  font: ${({ type }) =>
    type === GROUP_TYPE.PROJECT ? fonts.xSmall.regular : fonts.xSmall.regular};
  padding: ${({ type }) =>
    type === GROUP_TYPE.PROJECT ? '3px 7px 3px 5px' : '4px 8px 4px 8px'};
  & .anticon {
    margin-right: 0px;
    display: none;
    + span {
      margin-left: 0px;
    }
  }
  .close {
    margin-left: 5px;
    width: 12px;
    cursor: pointer;
  }
  & .anticon.anticon-close-circle {
    display: block;
    margin-left: 4px;
    color: ${({ type }) =>
      type === GROUP_TYPE.DEPARTMENT ? 'inherit' : Colors.SecondaryColor};
  }
  max-width: 100%;
  .group-value {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px;
  }
`;
const GroupTag = ({ color, name, type, closeable = false, closeOnClick }) => (
  <StyledTag type={type}>
    {/* for future use
     {type === GROUP_TYPE.DEPARTMENT ? (
      <BuildOutlined />
    ) : ( */}
    <ColorsBox color={color} width={12} height={12} right="4px" />
    {/* for future use */}
    {/* )} */}
    <span title={name} className="group-value">
      {name || 'unnamed'}
    </span>
    {closeable && (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <span onClick={() => closeOnClick !== undefined && closeOnClick()}>
        <img className="close" src={CloseCircle} alt="close" />
      </span>
    )}
  </StyledTag>
);

export default GroupTag;
