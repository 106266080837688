import { gql } from '@apollo/client';

export const companyFragment = gql`
  fragment CompanyFragment on Company {
    id
    name
    subDomain
    featureKeys
    logo
    createdAt
    updatedAt
  }
`;

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    role
    profileImage
    title
    type
    contact
    username
    isActive
    createdAt
    updatedAt
  }
`;

export const checkInSettingFragment = gql`
  fragment CheckInSettingFragment on CheckInSetting {
    id
    company {
      ...CompanyFragment
    }
    checkInPlanMandatory
    dailyGoalReminderTime
    dailyGoalReportLocalTime
    includeLastDayUpdate
    enableCheckOutSettings
    enableCheckOutUpdateMandatory
    enableCheckOutReport
    checkOutReportLocalTime
    adjustCheckOutTime
    enableAutoCheckOut
    autoCheckOutTime
    enableHourEstimate
    makeEstimateHourMandatory
    requireMinEstimateMandatory
    minimumEstimateHours
    enableAssignment
    assignmentMandatory
    assignmentIndividual
    assignmentDepartment
    assignmentProject
    DepartmentLeaderBlockersVisibility
    DepartmentManagerBlockersVisibility
    DepartmentMemberBlockersVisibility
    ProjectLeaderBlockersVisibility
    ProjectManagerBlockersVisibility
    ProjectMemberBlockersVisibility
    createdAt
    updatedAt
  }
  ${companyFragment}
`;
