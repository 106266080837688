/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { Layout, Typography, Card, message } from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import { accessDenied } from 'assets';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
const { Title, Text } = Typography;
const { Content: AntContent } = Layout;
export const BasicCard = styled(Card)`
  box-shadow: 0px 25px 50px rgba(81, 89, 131, 0.25);
`;
export const Content = styled(AntContent)`
  &.ant-layout-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1.ant-typography {
    margin-bottom: 0px;
  }
  .title {
    color: ${Colors.PrimaryColor};
    padding-top: 12px;
  }
  .text {
    font: ${fonts.medium.regular};
    line-height: 22px;
    text-align: center;
    color: #18214d;
    padding-top: 12px;
  }
  .dis {
    font: ${fonts.medium.regular};
    color: #6e738c;
  }
  .options > div {
    margin: 50px 0;
  }

  .options .wrap {
    padding: 16px 36px 36px 36px;
    text-align: center;
    width: 548px;
    background-color: #fff;
    border-radius: 12px;
  }

  .blockico {
    margin-top: -100px;
  }
  .links {
    padding-right: 20px;
    text-decoration: underline;
    text-underline-position: under;
    &:last-child {
      padding: 0;
    }
  }
`;
const StyledDiv = styled.div`
  height: 100%;
`;
const Wrapper = styled.div`
  padding: 1rem;
  text-align: center;
  z-index: 1;
`;
const Icon = styled.img``;
const Divider = styled.hr`
  border: 2px solid rgba(81, 89, 131, 0.2);
  border-bottom: 0;
  width: 20%;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const AccessDeniedPage = ({ history }) => {
  message.destroy();
  return (
    <Content className="options">
      <div className="options">
        <BasicCard className="wrap">
          <Icon className="blockico" src={accessDenied} />
          <StyledDiv>
            <Title className="title">Access denied...</Title>
            <p className="text">
              You don’t have permission to access this page
            </p>
          </StyledDiv>
          <Divider></Divider>
          <StyledDiv>
            <p className="dis">Here are some helpful links instead:</p>
          </StyledDiv>
          <div>
            <NavLink className="links" to="/dashboard">
              Dashboard
            </NavLink>
            <NavLink className="links" to="/updates/current">
              My Updates
            </NavLink>
            <NavLink className="links" to="/teams">
              Teams
            </NavLink>
            <NavLink className="links" to="/settings/my-profile">
              My Settings
            </NavLink>
          </div>
        </BasicCard>
      </div>
    </Content>
  );
};

export default withRouter(AccessDeniedPage);
