import React from 'react';
import { Input, message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import client from 'apollo';
import Button from 'atoms/Button';
import Colors from 'design/Colors';
import { BUTTON_TYPE } from 'utils/constants';
import { INVITE_MEMBERS } from 'graphql/Onboarding/Mutations';

import * as Sentry from '@sentry/react';
import {
  Content,
  Form,
  SunTitle,
  Title,
} from './AntStyled';

const InviteTeamMembers = (props) => {
  const { slider, userCreatedCompanyData } = props;

  const onFinish = (values) => {
    const filteredMembers = _filter(values, value => !!value && value.trim());
    client.mutate({
      mutation: INVITE_MEMBERS,
      variables: {
        data: {
          emails: _map(filteredMembers, filteredMember => filteredMember.trim()),
        },
        where: {
          id: parseInt(userCreatedCompanyData.companies[0].id, 10),
        },
      },
    }).then(() => {
      message.destroy();
      slider.current.goTo(2);
    }).catch((error) => {
      Sentry.captureException(error);
      console.log(error);
    });
  };


  return (
    <Content>
      <Title><div><FormattedMessage id="onboarding.invite" /></div><div role="button" tabIndex={0} aria-label="Skip" onClick={() => slider.current.goTo(2)}><FormattedMessage id="onboarding.skip" /></div></Title>
      <SunTitle><FormattedMessage id="onboarding.inviteToday" /></SunTitle>
      <Form
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="member1"
          rules={[{ required: true, message: <FormattedMessage id="onboarding.inviteRequired" /> }]}
        >
          <Input placeholder="Add member" allowClear />
        </Form.Item>
        <Form.Item
          name="member2"
        >
          <Input placeholder="Add member" allowClear />
        </Form.Item>
        <Form.Item
          name="member3"
        >
          <Input placeholder="Add member" allowClear />
        </Form.Item>
        <Form.Item className="button">
          <Button
            background={Colors.TextEnable}
            border={Colors.TextEnable}
            className="back-button"
            type={BUTTON_TYPE.PRIMARY}
            onClick={() => slider.current.goTo(0)}
            name={<FormattedMessage id="onboarding.back" />}
          />
          <Button
            className="continue-button"
            type={BUTTON_TYPE.PRIMARY}
            htmlType={BUTTON_TYPE.SUBMIT}
            name={<FormattedMessage id="onboarding.continue" />}
          />
        </Form.Item>
      </Form>
    </Content>
  );
};

export default injectIntl(InviteTeamMembers);
