const getTrimValues = (values) => {
  const newArr = {};
  Object.keys(values).map((key) => {
    // eslint-disable-next-line no-unused-expressions
    typeof values[key] === 'string' && key !== 'password'
      ? newArr[key] = values[key].trim()
      : newArr[key] = values[key];
  });

  return newArr;
};

export default getTrimValues;
