import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    profileImage
  }
`;

export const groupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    color
    type
    members{
      user{
        ...UserFragment
      }
    }
    managers{
      user{
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;

export const commentFragment = gql`
  fragment CommentFragment on Comment {
    id
    content
    createdBy {
      ...UserFragment
    }
    createdAt
    updatedAt
  }
  ${userFragment}
`;

export const activityLogFragment = gql`
  fragment ActivityLogFragment on ActivityLog {
    id
    title
    description
    createdAt
    updatedAt
    triggerBy { 
      ...UserFragment
    }
  }
  ${userFragment}
`;
