import React, { useState } from 'react';
import { Checkbox, Input, Radio } from 'antd';
import Card from 'atoms/Card';
import Button from 'atoms/Button';
import _map from 'lodash/map';
import Meta from 'atoms/Meta';
import { EMPTY_ARRAY, SURVEYS_TYPE, FEEDBACK_TYPE } from 'utils/constants';
import { Pencil } from 'assets';
import * as Sentry from '@sentry/react';
import client from 'apollo';
import { Content, Form, Icon, Modal } from './AntStyled';
import generateQuestion from './generateQuestion';
import { CREATE_FEEDBACK_ANSWER } from '../../graphql/Mutations';

const { TextArea } = Input;

export default function UserForm({ feedbackData }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { title, type } = feedbackData;
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const answer = _map(Object.keys(values), (key) => ({
          questionId: key,
          answer: { answer: values[key] },
        }));
        client
          .mutate({
            mutation: CREATE_FEEDBACK_ANSWER,
            variables: {
              data: answer,
              where: {
                feedbackId: feedbackData?.id,
              },
            },
          })
          .then((res) => {
            console.log(res);
            handleCancel();
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log('Validate Failed:', e);
      });
  };

  return (
    <Content>
      <Icon src={Pencil} alt="icon" onClick={showModal}></Icon>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        width={1000}
        title={
          <span className="title">
            {`${type === FEEDBACK_TYPE.SURVEY ? 'Survey' : 'Poll'}: ${title}`}
          </span>
        }
        footer={
          <>
            <Button
              secondary
              key="survey-button"
              onClick={handleCancel}
              name="Cancel"
            />
            <Button
              primary
              key="survey-button"
              onClick={onFinish}
              name="Submit"
            />
          </>
        }
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          {_map(feedbackData?.questions?.questions, (question) =>
            generateQuestion(question)
          )}
        </Form>
      </Modal>
    </Content>
  );
}
