import { Popover as AntPopover } from 'antd';
import styled from 'styled-components';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';

export const Popover = styled(AntPopover)`
  cursor: pointer;

  &.ant-popover-inner-content {
    padding: 0px !important;
    font: ${fonts.xSmall.regular};
  }

  &.anticon {
    width: 34px;
    cursor: pointer;
  }
`;

export const Li = styled.li`
  white-space: nowrap;
  display: inline-block;
  width: 25px;
  z-index: ${({ index }) => index || 0};

  .padding {
    border-radius: 100%;
    padding: 0px;
  }

  &:hover .ant-avatar {
    border: 2px solid ${Colors.BlueBorder};
  }

  &:hover .delete-icon {
    display: inline-block;
  }

  .ant-btn-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    width: ${({ size }) => size || 24}px;
    height: ${({ size }) => size || 24}px;
    & > * {
      font-size: ${fonts.xSmall.size};
    }
  }

  .delete-icon {
    z-index: 1;
    position: relative;
    left: -10px;
    top: -10px;
    display: none;
  }
`;

export const Labels = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size || 27}px;
  height: ${({ size }) => size || 27}px;
  background: ${Colors.MemberCircle};
  border-radius: 50%;
  font-style: normal;
  color: ${Colors.TextSecondary};
`;
