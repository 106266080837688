import { AvatarImage, AvatarImage2, AvatarImage3 } from 'assets';
export const teamData = [
  {
    key: '1',
    user: {
      firstName: 'Bruce',
      lastName: 'Wayne',
      profileImage: AvatarImage,
    },
    goals: '2',
    goalsAdded: '4',
    blockers: '1',
    CheckIn: '4',
    mood: 'heart_eyes',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '2',
    user: {
      firstName: 'Cameron',
      lastName: 'Williamson',
      profileImage: AvatarImage2,
    },
    goals: '2',
    goalsAdded: '4',
    blockers: '1',
    CheckIn: '4',
    mood: 'persevere',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '3',
    user: {
      firstName: 'Courtney',
      lastName: 'Henry',
      profileImage: AvatarImage3,
    },
    goals: '2',
    goalsAdded: '4',
    blockers: '1',
    CheckIn: '4',
    mood: 'confused',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '4',
    user: {
      firstName: 'Wade',
      lastName: 'Warren',
      profileImage: AvatarImage2,
    },
    goals: '2',
    goalsAdded: '4',
    blockers: '1',
    CheckIn: '4',
    mood: 'neutral_face',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '5',
    user: {
      firstName: 'Kathryn',
      lastName: 'Murphy',
      profileImage: AvatarImage3,
    },
    goals: '2',
    goalsAdded: '4',
    blockers: '1',
    CheckIn: '4',
    mood: 'slightly_smiling_face',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
  {
    key: '6',
    user: {
      firstName: 'Jacob',
      lastName: 'Jones',
      profileImage: AvatarImage2,
    },
    goals: '2',
    goalsAdded: '4',
    blockers: '1',
    CheckIn: '4',
    mood: 'tired_face',
    description:
      'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
  },
];
