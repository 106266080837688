import React from 'react';
import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import Variables from 'design/Variables';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${Colors.Text};
  background-color: transparent;
  border-radius: ${Variables.AtomRadius};
  border: 1px solid ${Colors.Border};
  width: ${({ width }) => width || '100'};
  padding: ${Spacings.xxSmall.margin};

  .number {
    font: ${fonts.Big.medium};
    color: ${({ color }) => color || Colors.Text};
  }
  .description {
    font: ${fonts.xSmall.medium};
    text-align: center;
    color: ${Colors.Text};
  }
`;

const Statistics = ({
  number = 0,
  description = 'description',
  color,
  size = 'small',
  width,
  className,
}) => (
  <StyledDiv color={color} size={size} width={width} className={className}>
    <div className="number">{number}</div>
    <div className="description">{description}</div>
  </StyledDiv>
);

export default Statistics;
