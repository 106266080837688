import React from 'react';
import { Router, Switch, Route, withRouter } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'utils/constants';
import ability from 'utils/ability/ability';
import Page404 from 'pages/404';
import AnnouncementDetails from './AnnouncementDetails';
import AnnouncementTabs from './AnnouncementTabs';
import NewAnnouncement from './NewAnnouncement';

const Announcements = ({ history }) => (
  <Router history={history}>
    <Switch>
      {ability.can('use', 'announcements_feature') &&
        ability.can('manage', 'announcements') && (
          <Route
            path={PRIVATE_ROUTE.ANNOUNCEMENT_EDIT}
            component={NewAnnouncement}
          />
        )}
      {ability.can('use', 'announcements_feature') &&
        ability.can('manage', 'announcements') && (
          <Route
            path={PRIVATE_ROUTE.ANNOUNCEMENT_NEW}
            component={NewAnnouncement}
          />
        )}
      {ability.can('use', 'announcements_feature') && (
        <Route
          path={PRIVATE_ROUTE.ANNOUNCEMENTS_VIEW}
          component={AnnouncementDetails}
        />
      )}
      {ability.can('use', 'announcements_feature') && (
        <Route
          path={PRIVATE_ROUTE.ANNOUNCEMENTS}
          component={AnnouncementTabs}
        />
      )}
      <Route exact path="*" render={() => <Page404 />} />
    </Switch>
  </Router>
);

export default {
  component: withRouter(Announcements),
  key: PRIVATE_ROUTE.ANNOUNCEMENTS,
  defaultPath: PRIVATE_ROUTE.ANNOUNCEMENT_ALL,
  name: 'Announcement',
  icon: (
    <i data-eva="volume-up-outline" data-eva-height="20" data-eva-width="20"></i>
  ),
  abilitySubject: 'announcements_feature',
  abilityAction: 'use',
};
