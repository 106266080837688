import { gql } from '@apollo/client';
import { workUpdateUserFragment } from './Fragments';

export const UPDATE_WORK_UPDATE_USER = gql`
  mutation updateWorkUpdateUser($data: WorkUpdateUpdateInput, $where: WorkUpdateUserWhereInput) {
    updateWorkUpdateUser(data: $data, where: $where){
      id
    }
  }

`;
