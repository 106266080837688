import { Layout } from 'antd';
import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { x } from 'design/Styled';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  &.ant-layout-content {
    overflow: auto;
  }
  .anticon-file-text,
  .anticon-control {
    font: ${fonts.medium.regular};
    ${x};
    justify-content: flex-end;
    align-items: self-end;
    padding-right: 0;
  }
`;
export const LoaderDiv = styled.div`
  padding-bottom: 35px;
  padding-top: 10px;
`;

export const Response = styled.div`
  ${x};
  width: auto;
  white-space: nowrap;

  p {
    margin-bottom: 0;
    margin-right: ${Spacings.xxSmall.margin};
  }
`;
export const Icon = styled.img`
  width: 18px;
  height: 18px;
  cursor:pointer;
  &.disabled{
    cursor:not-allowed;
  }
`;
