import { gql } from '@apollo/client';
import { leaveRequestFragment } from './Fragments';

export const UPDATE_LEAVE_REQUEST = gql`
  mutation updateLeaveRequest($data: LeaveRequestUpdateInput){
    updateLeaveRequest(data: $data){
      ...LeaveRequestFragment
    }
  }
  ${leaveRequestFragment}
`;
