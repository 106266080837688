/* eslint-disable no-console */
import React, { useState } from 'react';
import Card from 'atoms/Card';
import { Form, Radio, Input, Checkbox, Row, Col, Popover } from 'antd';
import {
  AMPLITUDE_EVENTS,
  EMPTY_ARRAY,
  FEEDBACK_TYPE,
  SURVEYS_TYPE,
} from 'utils/constants';
import TodoCard from 'templates/Todos/TodoCard/TodoCard';
import { ArchiveIcon } from 'assets';
import Button from 'atoms/Button';
import FeedbackQuestion from 'organisms/FeedbackQuestion';
import _map from 'lodash/map';
import client from 'apollo';
import * as Sentry from '@sentry/react';
import ReasonDeletion from 'templates/Todos/ReasonDeletion';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import { createAmplitudeEvent } from 'utils/amplitude';
import { Content, Title } from './AntStyled';
import { CREATE_FEEDBACK_ANSWER } from '../graphql/Mutations';

function SurveyTodo({
  todo,
  callback,
  showArchive = true,
  handleArchiveTodo,
  viewSummary,
}) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const questions = _map(
    todo?.todoable?.questions?.questions,
    (question, index) => ({
      ...question,
      fieldKey: index,
      key: index,
      name: index,
    })
  );

  const onFinish = (values) => {
    setLoading(true);
    const answer = _map(Object.keys(values), (key) => ({
      questionId: key,
      answer: { answer: values[key] },
    }));
    client
      .mutate({
        mutation: CREATE_FEEDBACK_ANSWER,
        variables: {
          data: answer,
          where: {
            feedbackId: todo?.todoable?.feedbackId,
          },
        },
        awaitRefetchQueries: true,
      })
      .then(async (res) => {
        const value = res?.data?.createFeedbackAnswers;
        const eventProperties = {
          answer: _map(value?.answers, (data) => ({
            Id: data?.id,
            answer: data?.answer?.answer,
            questions: {
              id: data?.question?.id,
              questionType: data?.question?.questionType,
              question: data?.question?.question,
              choices: data?.question?.choices,
              scaleLabel: data?.question?.scaleLabel,
            },
          })),
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENTS.FEEDBACK_ANSWERED,
          eventProperties
        );
        callback && callback(todo?.id || todo?.todoable?.feedbackId);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Content>
      <TodoCard todo={todo} />
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={{
          answerList: questions,
        }}
      >
        {_map(questions, (question, index) => (
          <Card className="Card-form" padding="16">
            <FeedbackQuestion
              questionData={question}
              questionIndex={index}
              key={index}
              isSurvey={todo?.todoable?.type === FEEDBACK_TYPE.SURVEY}
              form={form}
            />
          </Card>
        ))}
        <Row align="middle" justify="space-between" className="model-action">
          <Col>
            {showArchive && (
              <Popover
                content={
                  <ReasonDeletion
                    deleteTask={(values) => handleArchiveTodo(values, todo?.id)}
                  />
                }
                overlayClassName="popover-no-padding"
                trigger="click"
                placement="left"
              >
                <img src={ArchiveIcon} alt="icon" />
              </Popover>
            )}
          </Col>
          <Row>
            <Col>
              {viewSummary && (
                <Button
                  htmlType="submit"
                  type="text"
                  className="template-button mark-as-read"
                  name="View Summary"
                  link
                  onClick={() =>
                    history.push(
                      todo?.todoable?.type === SURVEYS_TYPE.POLLS
                        ? `${`/feedback/poll/${todo?.todoable?.feedbackId}/summary`}`
                        : `${`/feedback/survey/${todo?.todoable?.feedbackId}/summary`}`
                    )
                  }
                />
              )}
            </Col>
            <Col>
              <Button name="Submit" htmlType="submit" loading={loading} />
            </Col>
          </Row>
        </Row>
      </Form>
    </Content>
  );
}

export default SurveyTodo;
