import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
query locations($sortOn: sortLocationsOnGivenInput, $sortBy: sortByLocations){
  locations(sortOn: $sortOn, sortBy: $sortBy){
    id
    name
    shortName
    isActive
    isDefault
    timeZone
    admins{
      id
      firstName
      lastName
      profileImage
      email
    }
  }
}
`;
export const GET_LOCATION = gql`
query location($where:LocationWhereUniqueInput!){
  location(where : $where){
    id
    name
    shortName
    isActive
    isDefault
    timeZone
    yearStart
    admins{
      id
      firstName
      lastName
      profileImage
      email
    }
  }
}
`;

export const GET_LOCATION_YEAR_START = gql`
query locationYearStart($where:LocationWhereUniqueInput!){
  location(where : $where){
    id
    yearStart
  }
}
`;
