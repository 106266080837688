import styled from 'styled-components';
import { Select as AntSelect, Form as AntForm, Layout, Input } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;
const { TextArea } = Input;

export const TextBox = styled(TextArea)`
  border: 1px solid ${Colors.Border};
  border-radius: 10px;
`;
export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Text};
  cursor: default;
  .anticon {
    margin-right: ${Spacings.xSmall.margin};
  }
`;
export const Arrow = styled.img`
  cursor: pointer;
  margin-right: ${Spacings.xSmall.margin}!important;
`;
export const Image = styled.img`
  width: 18px;
  height: 18px;
  padding-bottom: 4px;
`;
export const Select = styled(AntSelect)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  .ant-select-selection-placeholder {
    font: ${fonts.xSmall.regular};
  }
  &.ant-select {
    background: white;
    min-width: 170px;
    max-width: 1000px;
    display: table-cell;
    word-wrap: break-word;
    word-break: break-word;
    & .ant-select-selector {
      padding-right: 12px;
      padding-left: 12px;
      border: none;
    }
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  &.ant-select-multiple .ant-select-selector {
    border: none;
    width: 100%;
    background-color: transparent;
  }
  &.ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 14px;
    left: 14px;
  }
  //! due to inline styling using important
  .ant-select-selection-search {
    width: 10% !important;
  }
  &.ant-select-multiple
    .ant-select-selection-search:first-child
    .ant-select-selection-search-input {
    margin-left: 0px;
    padding-left: 0px;
  }
`;
export const Content = styled(AntContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  &.ant-layout-content {
    padding: ${Spacings.small.padding};
    overflow-y: auto;
    .ant-btn-primary {
      line-height: 22px;
    }
  }
  color: ${Colors.TextEnable};
  .card {
    max-width: 700px;
    height: fit-content;
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
  }
  .ant-col .ant-form-item-label {
    padding: 0;
  }
  .datePicker {
    margin: 5px 0 0 ${Spacings.small.margin};
    border-radius: 8px;
  }
  .dropdown-button {
    .ant-row {
      margin: 0px;
    }
  }
  .active {
    color: ${Colors.PrimaryColor};
    font: ${fonts.small.medium};
  }
`;

export const Form = styled(AntForm)`
  .ant-form-item {
    margin-bottom: 16px;
    font-size: ${fonts.medium.size};
    &:last-child(5) {
      margin-bottom: 0px;
    }
  }
  .ant-row.ant-form-item:last-of-type {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
  }
  .item {
    display: inline-block;
    width: 100px;
  }
  .ant-select-disabled {
    background: ${Colors.LightDark};
  }
  .btn {
    margin-right: 12px;
    margin-left: 20px;
  }
  .template-button {
    background: transparent;
    border: none;
    color: ${Colors.ButtonDanger};
    box-shadow: none;
  }
  .delete-title {
    .name {
      color: red;
      margin-left: 5px;
    }
  }
`;
