import React, { useState, useEffect } from 'react';
import _map from 'lodash/map';
import {
  Col, Row, Input, message, Popover, Divider,
} from 'antd';
import { SmileTwoTone } from '@ant-design/icons';
import { NoNotification, Pencil, Trash } from 'assets';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import DateTime from 'atoms/DateTime';
import Button from 'atoms/Button';
import UserProfile from 'molecules/UserProfile';
import PopConfirm from 'atoms/PopConfirmation';
import {
  EMPTY_ARRAY,
  EMPTY_STRING,
  POPOVER_TYPE,
  COLORS,
} from 'utils/constants';
import {
  Timeline,
  StyledSpin,
  ActionWrapper,
  Image,
  CommentWrapper,
  CommentContentWrapper,
  PopoverContent,
} from './AntStyled';

const { TextArea } = Input;

const CommentsTimeLine = ({
  comments,
  createActionComment,
  updateActionComment,
  deleteActionComment,
  intl,
  loading,
  currentUserId,
  isSection,
}) => {
  const [data, setData] = useState(EMPTY_ARRAY);
  const [showEmoji, setShowEmoji] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [content, setContent] = useState(EMPTY_STRING);
  const [editIndex, setEditIndex] = useState(null);
  const { Item } = Timeline;

  useEffect(() => {
    if (comments) {
      setData(comments);
    }
  }, [comments]);

  const addComment = (e) => {
    if (!e.shiftKey) {
      if (content && content.trim().length !== 0) {
        setDisabled(true);
        createActionComment(content).then(() => {
          setDisabled(false);
          setContent(EMPTY_STRING);
        });
      } else {
        message.error(
          intl.formatMessage({ id: 'comments.successMessages.addComment' })
        );
        setContent(EMPTY_STRING);
      }
    }
  };

  const updateComment = (e) => {
    if (!e.shiftKey) {
      setDisabled(true);
      updateActionComment(content, editIndex).then(() => {
        setEditIndex(null);
        setContent(EMPTY_STRING);
        setDisabled(false);
      });
    }
  };

  return (
    <PopoverContent isSection={isSection}>
      <Col flex="auto" className="comments-col">
        {(data && data.length > 0) || loading ? (
          <StyledSpin spinning={loading}>
            {_map(data, (item, index) => (
              <CommentWrapper key={index}>
                <Row align="middle" className="row-info">
                  <Col className="col-avatar">
                    <UserProfile
                      stackedName={false}
                      size={32}
                      profile={item.createdBy.profileImage}
                      name={
                        item.createdBy.firstName && item.createdBy.lastName
                          ? `${item.createdBy.firstName} ${item.createdBy.lastName}`
                          : item.createdBy.email
                      }
                    />
                  </Col>
                  <Col className="col-time">
                    {' '}
                    <DateTime date={item.createdAt} fromNow />
                    {item.updatedBy && ' (edited)'}
                  </Col>
                  <Col
                    className={
                      currentUserId && currentUserId !== item.createdBy.id
                        ? 'no-actions'
                        : ''
                    }
                  >
                    {currentUserId && currentUserId === item.createdBy.id && (
                      <ActionWrapper>
                        <div
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            setEditIndex(item.id);
                            setContent(item.content);
                          }}
                        >
                          <img src={Pencil} alt="edit" />
                        </div>
                        <Divider type="vertical" />
                        <PopConfirm
                          placement="bottomRight"
                          title="Are you sure to delete this comment?"
                          onConfirm={() => deleteActionComment(item.id)}
                          icon={(
                            <img
                              src={Trash}
                              alt="edit"
                              className="icon-delete"
                            />
                          )}
                        />
                      </ActionWrapper>
                    )}
                  </Col>
                </Row>
                <CommentContentWrapper>{item.content}</CommentContentWrapper>
              </CommentWrapper>
            ))}
          </StyledSpin>
        ) : (
          !loading && (
            <div className="no-comments-wrapper">
              <Image src={NoNotification} alt="No comments" />
              <span className="img-subtitle">
                <FormattedMessage id="notifications.noNotifications" />
              </span>
            </div>
          )
        )}
      </Col>
      <Row align="middle" className="input-row">
        {/* <Can I={abilityActions.comment_on_own_daily_goals} a={abilitySubject.Comment} ability={ability}> */}
        <TextArea
          autoSize={{ maxRows: 6, minRows: 4 }}
          disabled={disabled}
          showCount
          bordered
          className="input-comment"
          placeholder="Type a message"
          autoFocus
          allowClear
          value={content}
          // onPressEnter={e => editIndex ? updateComment(e) : addComment(e)}
          onChange={e => setContent(e.target.value)}
        />
        {/* </Can> */}
        <div className="emoji-picker">
          <Popover
            content={(
              <Picker
                perLine={7}
                native
                autoFocus
                useButton={false}
                showPreview={false}
                showSkinTones={false}
                onSelect={e => setContent(`${content}${e.native}`)}
              />
            )}
            trigger={POPOVER_TYPE.CLICK}
            visible={showEmoji}
            onVisibleChange={e => setShowEmoji(e)}
            arrowPointAtCenter={false}
            placement={POPOVER_TYPE.LEFT_BOTTOM}
          >
            <SmileTwoTone
              twoToneColor={COLORS.GOLDENROD}
              style={{ fontSize: '20px' }}
              onClick={() => setShowEmoji(true)}
            />
          </Popover>
        </div>
        <Button
          name="Post"
          onClick={e => (editIndex ? updateComment(e) : addComment(e))}
        />
      </Row>
    </PopoverContent>
  );
};

export default injectIntl(CommentsTimeLine);
