/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Col, Row } from 'antd';
import Tag from 'atoms/Tag';
import UserProfile from 'molecules/UserProfile';
import { toLower } from 'lodash';
import { ActivityData } from '../MockData';
import ActivityCard from '../ActivityCard';

export const JiraSprintMessage = (data, type = '') => (
  <Row align="middle" className="message">
    {type !== 'teams' && (
      <UserProfile
        size={20}
        stackedName={false}
        profile={data?.actor?.profilePic || data?.processedData?.actor_image}
        name={data?.actor?.name || data?.processedData?.actor_name}
      />
    )}
    <span className="text-regular">
      {toLower(data?.processedData?.action)} a sprint&nbsp;
    </span>
    {type === 'teams' ? (
      <div className="bold-text">{`"${data?.processedData?.sprint_name}"`}</div>
    ) : (
      <Tag
        className="tag-text"
        color="#EFF2F6"
        content={data?.processedData?.sprint_name}
      />
    )}
    &nbsp;
    <span className="text-regular">in project&nbsp;</span>
    {type === 'teams' ? (
      <div className="bold-text">{data?.processedData?.project_name}</div>
    ) : (
      <Tag className="tag-text" content={data?.processedData?.project_name} />
    )}
  </Row>
);

const JiraSprint = ({ data }) => (
  <ActivityCard
    activityDate={data?.addedAt}
    platformIcon={ActivityData?.jira?.title?.icon}
    message={JiraSprintMessage(data)}
  />
);

export default JiraSprint;
