import React, { useEffect } from 'react';
import Card from 'atoms/Card';
import { Row, Col, Tag } from 'antd';
import styled from 'styled-components';
import Colors from 'design/Colors';
import UserProfile from 'molecules/UserProfile';
import { fonts } from 'design/Fonts';
import { CalendarIcon } from 'assets';
import Status from 'atoms/Status';
import GroupTagList from 'organisms/GroupTagList';
import * as eva from 'eva-icons';
import Spacings from 'design/Spacings';
import { TODO_ICONS, TODO_TYPE, TODO_STATUS_COLOR } from 'utils/constants';
import { capitalize, startCase } from 'lodash';
import DateTime from 'atoms/DateTime';

export const Content = styled.div`
  .card {
    .ant-card-body {
      background: ${Colors.IconColor};
    }
  }
  .row-2 {
    margin-top: calc(${Spacings.xSmall.margin} - 2px);
  }
  .group-tag {
    margin-left: calc(${Spacings.small.margin} - 2px);
  }
  .due-date {
    margin-left: calc(${Spacings.Small.margin} - 2px);
    display: flex;
    align-items: center;
    color: ${Colors.Text};
    img {
      margin-right: 5px;
    }
  }
  .icon {
    width: 12px;
  }
  .date {
    font: ${fonts.small.medium};
    line-height: 20px;
    color: ${Colors.Text};
    margin-left: calc(${Spacings.tiny.margin} - 2px);
  }
  .ant-tag-has-color {
    border-radius: 10px;
  }
`;
export const Text = styled.div`
  font: ${fonts.xSmall.regular};
  color: ${Colors.Text};
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: calc(${Spacings.xxSmall.margin} + 2px);
`;
export const Heading = styled.div`
  font: ${fonts.small.medium};
  color: ${Colors.TextPrimary};
  line-height: 20px;
  margin-bottom: 0px;
  margin-left: calc(${Spacings.xxSmall.margin} + 2px);
  margin-right: calc(${Spacings.tiny.margin} - 2px);
`;

function TodoCard({ todo = {} }) {
  const { title, description, dueDate, type, owner, groups, status } = todo;
  useEffect(() => {
    eva.replace();
  }, []);
  return (
    <Content>
      <Card padding={16} className="card">
        <Row className="title" justify="space-between" align="middle">
          <Col span={18}>
            <Row>
              <Col span={1}>
                {
                  TODO_ICONS[
                    type === TODO_TYPE.FEEDBACK_REQUEST
                      ? todo.todoable.type
                      : type
                  ]
                }
              </Col>
              <Col span={23} className>
                <Heading>
                  {type === TODO_TYPE.ANNOUNCEMENT_PUBLISHED && (
                    <Tag color="#9F9F9F">#{todo?.todoable?.sequenceNo}</Tag>
                  )}
                  {title}
                </Heading>
                <Text>{description}</Text>
              </Col>
            </Row>
          </Col>
          <Col>
            <UserProfile
              name={`${owner?.firstName} ${owner?.lastName}`}
              profile={owner?.profileImage}
            />
          </Col>
        </Row>
        <Row className="row-2" justify="space-between">
          <Col>
            <Row align="middle">
              <Col className="group-tag">
                <GroupTagList departments={groups} />
              </Col>
              {dueDate && (
                <Col className="due-date">
                  <img className="icon" src={CalendarIcon} alt="icon" />
                  <DateTime date={dueDate} />
                </Col>
              )}
            </Row>
          </Col>
          {status && (
            <Col>
              <Status
                name={startCase(capitalize(status))}
                color={TODO_STATUS_COLOR[status]}
              />
            </Col>
          )}
        </Row>
      </Card>
    </Content>
  );
}

export default TodoCard;
