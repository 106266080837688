import React, { useState } from 'react';
import {
  Checkbox, Input, Radio, Row,
} from 'antd';
import Spacings from 'design/Spacings';
import Card from 'atoms/Card';
import _map from 'lodash/map';
import Button from 'atoms/Button';
import Meta from 'atoms/Meta';
import { EMPTY_ARRAY } from 'utils/constants';
import { questions } from '../MockData';
import {
  Content, Modal,
} from '../Survey/Response/AntStyled';

const { TextArea } = Input;

export default function ResponseModal() {
  const [responseId, setResponseId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Content>
      <Modal
        title={`${responseId}`}
        footer={null}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        {_map(questions, question => (
          <Card title={question.question}>
            <Row className="q-answer">{question.answer.placeholder}</Row>
          </Card>
        ))}
      </Modal>
    </Content>
  );
}
