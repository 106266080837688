import { gql } from '@apollo/client';
import { quoteFragment, quoteSettingFragment } from './Fragments';

export const GET_QUOTE = gql`
  query quotes {
    quotes {
      ...QuoteFragment
    }
  }
  ${quoteFragment}
`;

export const GET_QUOTE_SETTING = gql`
  query quoteSetting($where: quoteSettingWhereInput!) {
    quoteSetting(where: $where) {
      ...QuoteSettingFragment
     }
  }
  ${quoteSettingFragment}
`;

export const GET_QUOTE_CATEGORIES = gql`
  query quoteCategories {
    quoteCategories {
      id
      name
    }
  }
`;
