import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import { Col, Row, Typography } from 'antd';
import { ReOrder } from 'assets';
import Button from 'atoms/Button';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { EMPTY_ARRAY, EMPTY_STRING } from 'utils/constants';
import { Li, StyledModal } from './AntStyled';

const { Paragraph } = Typography;

const SortableItem = SortableElement(({ value, number }) => (
  <Li key={number}>
    <Paragraph className="question" ellipsis>
      <Row justify="space-between">
        <Col className="question-text" span={23}>
          <b>{`Q${number + 1}:`}</b>
          {value}
        </Col>
        <Col span={1}>
          {' '}
          <img src={ReOrder} alt="reorder" />
        </Col>
      </Row>
    </Paragraph>
  </Li>
));

export default function ReorderModal({
  visible,
  handleCancel,
  move,
  form,
  setModalVisible,
}) {
  const [data, setData] = useState();
  const [indexQueue, setIndexQueue] = useState(EMPTY_ARRAY);

  function arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    setData([...arr]);
  }

  useEffect(() => {
    if (visible) {
      setData(
        _map(
          form.getFieldValue('questionList'),
          (question) => question?.question
        )
      );
    }
    return setIndexQueue(EMPTY_ARRAY);
  }, [visible]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setIndexQueue([...indexQueue, { old: oldIndex, new: newIndex }]);
    arrayMove(data, oldIndex, newIndex);
  };

  const handleApply = () => {
    _map(indexQueue, (val) => {
      move(val?.old, val?.new);
    });
    setModalVisible(false);
  };

  return (
    <StyledModal
      title="Reorder"
      visible={visible}
      onCancel={handleCancel}
      width={380}
      maskClosable={false}
      footer={
        <Row align="middle" justify="end" gutter={16}>
          <Col>
            <Button name="Cancel" secondary onClick={handleCancel} />
          </Col>
          <Col>
            <Button name="Apply Changes" primary onClick={handleApply} />
          </Col>
        </Row>
      }
    >
      <SortableList
        items={data}
        onSortEnd={onSortEnd}
        lockAxis="y"
        helperClass="sortableHelper"
      />
    </StyledModal>
  );
}

const SortableList = SortableContainer(({ items }) => (
  <ul>
    {_map(items, (value, index) => (
      <SortableItem
        key={`item-${index}`}
        number={index}
        value={value}
        index={index}
      />
    ))}
  </ul>
));
