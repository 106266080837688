import React, { useEffect, useState, useContext } from 'react';
import { Layout } from 'antd';
import {
  Router,
  Switch,
  Route,
  withRouter,
  useLocation,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import _map from 'lodash/map';
import _has from 'lodash/has';
import Colors from 'design/Colors';
import Meta from 'atoms/Meta';
import Button from 'atoms/Button';
import Spacings from 'design/Spacings';
import InfoIcon from 'atoms/InfoIcon';
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  PRIVATE_ROUTE,
  META_TYPE,
  ACTIONS_TYPE,
  FETCH_POLICY,
  PAGE_LIMIT,
} from 'utils/constants';
import * as Sentry from '@sentry/react';
import Filters from 'molecules/Filters';
import { AppContext } from 'AppContext';
import { fonts } from 'design/Fonts';
import { useQuery } from '@apollo/client';
import Header from 'layout/Header';
import ability from 'utils/ability/ability';
import useDebounce from 'utils/useDebounce';
import { GET_ALL_ANNOUNCEMENTS } from '../graphql/Queries';
import AnnouncementList from '../templates/AnnouncementList';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = styled.div`
  display: flex;
  margin-left: ${Spacings.xxSmall.margin};
  .tab {
    width: 128px;
    height: 34px;
    font: ${fonts.small.regular};
    padding: 0px ${Spacings.xxSmall.padding};
    box-sizing: border-box;
    border-radius: 6px 0px 0px 6px;
    &:nth-child(2) {
      border-radius: 0px 6px 6px 0px;
    }
    &:hover {
      color: ${Colors.TextPrimaryHover};
      border-color: ${Colors.TextPrimaryHover};
    }
  }
  & .tab.active {
    font-weight: 600;
  }
`;

const announcementStatus = ['PUBLISHED', 'DRAFT'];

const Announcement = (props) => {
  const { pathname } = useLocation();
  const { history } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [queryCount, setQueryCount] = useState(0);
  const [dataCount, setDataCount] = useState();
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [groupId, setGroupId] = useState(EMPTY_ARRAY);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const debounceSearchTerm = useDebounce(search, 500);
  const [announcements, setAnnouncements] = useState(EMPTY_ARRAY);
  const tabs = [
    <FormattedMessage id="announcements.tabs.published" />,
    <FormattedMessage id="announcements.tabs.draft" />,
  ];
  const { data: announcementData, loading: announcementLoading } = useQuery(
    GET_ALL_ANNOUNCEMENTS,
    {
      variables: {
        where: {
          skip,
          limit: PAGE_LIMIT,
          status: !ability.can('manage', 'announcements')
            ? 'PUBLISHED'
            : announcementStatus[activeTab],
          groups:
            search?.group?.length > 0
              ? _map(search?.group, (val) => parseInt(val?.slice(2), 10))
              : null,
          users:
            search?.user?.length > 0
              ? _map(search?.user, (val) => parseInt(val?.slice(2), 10))
              : null,
          searchByTitle: search?.text?.length > 0 ? search?.text[0] : '',
        },
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    }
  );
  useEffect(() => {
    if (_has(announcementData, 'announcements') && !announcementLoading) {
      const announcementsValues = [
        ...announcements,
        ...announcementData?.announcements?.announcements,
      ];

      setAnnouncements(announcementsValues);
      if (queryCount === 0) {
        setQueryCount(1);
      }
      setDataCount(announcementData?.announcements?.count);

      if (announcementData?.announcements?.count <= PAGE_LIMIT) {
        setHasMore(false);
      }
      setLoading(false);
    }
  }, [announcementData]);

  useEffect(() => {
    setLoading(true);
    resetAnnouncementData();
  }, [search]);

  useEffect(() => {
    if (pathname === PRIVATE_ROUTE.ANNOUNCEMENT_ALL) {
      setAnnouncements(EMPTY_ARRAY);
      setActiveTab(0);
      setSkip(0);
    } else if (pathname === PRIVATE_ROUTE.ANNOUNCEMENT_DRAFT) {
      setAnnouncements(EMPTY_ARRAY);
      setActiveTab(1);
      setSkip(0);
    }
  }, [pathname]);

  //* for future use
  // useEffect(() => {
  //   resetAnnouncementData();
  // }, [groupId, debounceSearchTerm]);

  const callback = (key) => {
    if (activeTab !== key) {
      setActiveTab(key);
      setQueryCount(0);
      resetAnnouncementData();
      if (key === 0) {
        history.push(PRIVATE_ROUTE.ANNOUNCEMENT_ALL);
      } else if (key === 1) {
        history.push(PRIVATE_ROUTE.ANNOUNCEMENT_DRAFT);
      }
    }
  };

  const resetAnnouncementData = () => {
    setHasMore(true);
    setAnnouncements([]);
    setSkip(0);
    setPage(1);
    setLoading(true);
  };

  const onChange = () => {
    resetAnnouncementData();
  };

  const Title = () => (
    <Wrapper>
      <InfoIcon
        title={<FormattedMessage id="announcements.title" />}
        contentKey={
          ability.can('manage', 'announcements')
            ? META_TYPE?.ANNOUNCEMENTS_SETTINGS
            : META_TYPE?.ANNOUNCEMENTS
        }
      />
      {ability.can('manage', 'announcements') && (
        <Tabs>
          {_map(tabs, (list, index) => (
            <Button
              key={index}
              className={`tab ${index === activeTab ? 'active' : ''}`}
              background={Colors.TextLight}
              border={
                index === activeTab ? Colors.PrimaryColor : Colors.TextDisable
              }
              color={
                index === activeTab ? Colors.PrimaryColor : Colors.TextSecondary
              }
              focus={Colors.TextLight}
              name={list}
              onClick={() => callback(index)}
            />
          ))}
        </Tabs>
      )}
    </Wrapper>
  );

  const handleInfiniteOnLoad = () => {
    if (!announcementLoading) {
      setLoader(true);
      if (dataCount <= announcements.length) {
        setHasMore(false);
        setLoader(false);
        setPage(1);
      } else {
        setPage((prev) => prev + 1);
        const count = page * PAGE_LIMIT;
        setSkip(count);
      }
    }
  };
  return (
    <Sentry.ErrorBoundary fallback="Something went wrong">
      <Layout>
        <Meta title={META_TYPE.ANNOUNCEMENTS} />
        <Header title={<Title />} />
        <Filters
          masterFilter
          doSearch
          onSearch={setSearch}
          groupValue={groupId}
          groupsFilters={setGroupId}
          addButton={ability.can('manage', 'announcements')}
          addButtonText={<FormattedMessage id="announcements.add" />}
          addButtonEvent={() => history.push(PRIVATE_ROUTE.ANNOUNCEMENT_NEW)}
        />
        <Router history={history}>
          <Switch>
            <Route
              exact
              path={PRIVATE_ROUTE.ANNOUNCEMENT_ALL}
              render={() => (
                <AnnouncementList
                  data={announcements}
                  loading={loading}
                  handleInfiniteOnLoad={handleInfiniteOnLoad}
                  hasMore={hasMore}
                  loader={loader}
                  onChange={onChange}
                  queryCount={queryCount}
                  dataCount={dataCount}
                  search={search}
                  isPublishedTab={activeTab === 0}
                />
              )}
            />
            <Route
              path={PRIVATE_ROUTE.ANNOUNCEMENT_DRAFT}
              render={() => (
                <AnnouncementList
                  data={announcements}
                  loading={loading}
                  handleInfiniteOnLoad={handleInfiniteOnLoad}
                  hasMore={hasMore}
                  loader={loader}
                  onChange={onChange}
                  queryCount={queryCount}
                  dataCount={dataCount}
                  search={search}
                  isPublishedTab={activeTab === 0}
                />
              )}
            />
          </Switch>
        </Router>
      </Layout>
    </Sentry.ErrorBoundary>
  );
};

export default withRouter(Announcement);
