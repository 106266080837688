import { gql } from '@apollo/client';

export const CREATE_LOCATION = gql`
mutation createLocation($data:LocationCreateInput!){
  createLocation(data:$data){
    id
}
}
`;

export const UPDATE_LOCATION = gql`
mutation updateLocation($data:LocationUpdateInput!, $where: LocationWhereUniqueInput!){
  updateLocation(data:$data,where:$where){
    id
}
}
`;

export const UPDATE_WORKDAY = gql`
  mutation updateSchedule($data:WorkDaysUpdateInput!, $where:WorkDayWhereUniqueInput!){
    updateSchedule(data:$data,where:$where)
    {
      id
    }
  }
`;
