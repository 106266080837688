import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LINKS } from 'utils/constants';
import { Container, Wrapper, Terms } from './AntStyled';

export default function () {
  return (
    <Terms>
      <a className="terms" href={LINKS.TERMS_OF_SERVICE} target="_blank">
        <FormattedMessage id="terms.service" />
      </a>
      <a href={LINKS.PRIVACY_POLICY} className="terms" target="_blank">
        <FormattedMessage id="terms.privacy" />
      </a>
    </Terms>
  );
}
