import React, { useContext, useEffect, useState } from 'react';
import './App.less';
import ability from 'utils/ability/ability';
import {
  Route,
  Switch,
  Router,
  useLocation,
  useParams,
} from 'react-router-dom';
import { PUBLIC_ROUTE } from 'utils/constants';
import LayoutComponent from 'pages/Layout';
import Logout from 'pages/Auth/Logout';
import GuardedRoute from 'onboarding/GuardedRoute';
import styled from 'styled-components';
import { Layout as LayoutAnt, Alert } from 'antd';
import Colors from 'design/Colors';
import { scrollbars } from 'design/Styled';
import { fontStyle } from 'design/Fonts';
import { createPageAmplitudeEvent } from 'utils/amplitude';
import OneSignal from 'react-onesignal';
import { AppContext } from 'AppContext';
import jwtDecode from 'jwt-decode';
import Routes from './Routes';
import history from './history';

const Layout = styled(LayoutAnt)`
  height: 100%;
  ${scrollbars};
  font-family: ${fontStyle};
  background: ${Colors.DefaultBackground};
  .main-sidebar-block-button {
    display: none;
  }

  .main-sidebar {
    background: ${Colors.SidebarBackground};
    border-right: 1px solid ${Colors.SidebarBorder};
    .ant-layout-sider-has-trigger {
      padding-bottom: 0px;
    }
  }

  @media (max-width: 1024px) {
    .main-sidebar-block-button {
      display: block;
      position: absolute;
      top: 46px;
      left: 0;
      z-index: 1;
      width: 40px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 99999;
    }

    .main-sidebar {
      display: none;
    }
  }
`;

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    createPageAmplitudeEvent(location.pathname);
  }, [location]);
}
const App = () => {
  const [alert, setAlert] = useState(false);
  function handleConnectionChange(event) {
    if (event.type === 'offline') {
      setAlert(true);
    }
    if (event.type === 'online') {
      setAlert(false);
    }
  }
  window.addEventListener('online', handleConnectionChange);
  window.addEventListener('offline', handleConnectionChange);

  return (
    <Router history={history}>
      {alert && <Alert type="error" message="No internet connection" banner />}
      <AppInner />
    </Router>
  );
};
const AppInner = () => {
  const idToken = localStorage.getItem('token');
  const loginUser = idToken && jwtDecode(idToken);
  usePageViews();

  useEffect(() => {
    if (loginUser?.id) {
      OneSignal.sendTag('userId', loginUser?.id);
    }
  }, [loginUser]);
  return (
    <Layout>
      {idToken ? (
        <Switch>
          <Route exact path={PUBLIC_ROUTE.LOGOUT} component={Logout} />
          <GuardedRoute
            ability={ability}
            path={PUBLIC_ROUTE.KEY.PATH}
            name="layout"
            component={LayoutComponent}
            idToken={idToken}
          />
        </Switch>
      ) : (
        <Routes />
      )}
    </Layout>
  );
};
export default App;
