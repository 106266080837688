import React, { useState } from 'react';
import { Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CloseOutlined } from '@ant-design/icons';

import Button from 'atoms/Button';
import Input from 'atoms/Input';
import InfoIcon from 'atoms/InfoIcon';
import {
  FORM_NAME,
} from 'utils/constants';
import Card from 'atoms/Card';
import { Form, CardWrapper } from './AntStyled';

export default function (props) {
  const { deleteTask, closeModal } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    try {
      deleteTask(values).then(() => {
        setLoading(false);
        form.resetFields();
      });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <CardWrapper>
      <Card
        padding="30px 20"
        title={(
          <Row justify="space-between" align="middle">
            <InfoIcon title={<FormattedMessage id="me.reason" />} className="small-fonts" />
            <CloseOutlined onClick={closeModal} />
          </Row>
        )}
      >
        <Form
          form={form}
          layout={FORM_NAME.VERTICAL}
          onFinish={onFinish}
        >
          <Form.Item
            label={<FormattedMessage id="me.formRules.reason.label" />}
            name="reason"
            rules={[{ required: true, whitespace: true, message: <FormattedMessage id="me.formRules.reason.errorMessage" /> }]}
          >
            <Input allowClear />
          </Form.Item>
          <Button
            htmlType="submit"
            key="leave-button"
            name={<FormattedMessage id="me.save" />}
            loading={loading}
            className="test"
          />
        </Form>
      </Card>
    </CardWrapper>

  );
}
