export const TimezoneData = [
  {
    min: -720,
    zone: 'Etc/GMT+12',
  },
  {
    min: -660,
    zone: 'Etc/GMT+11',
  },
  {
    min: -660,
    zone: 'Pacific/Midway',
  },
  {
    min: -660,
    zone: 'Pacific/Niue',
  },
  {
    min: -660,
    zone: 'Pacific/Pago_Pago',
  },
  {
    min: -660,
    zone: 'Pacific/Samoa',
  },
  {
    min: -660,
    zone: 'US/Samoa',
  },
  {
    min: -600,
    zone: 'America/Adak',
  },
  {
    min: -600,
    zone: 'America/Atka',
  },
  {
    min: -600,
    zone: 'Etc/GMT+10',
  },
  {
    min: -600,
    zone: 'HST',
  },
  {
    min: -600,
    zone: 'Pacific/Honolulu',
  },
  {
    min: -600,
    zone: 'Pacific/Johnston',
  },
  {
    min: -600,
    zone: 'Pacific/Rarotonga',
  },
  {
    min: -600,
    zone: 'Pacific/Tahiti',
  },
  {
    min: -600,
    zone: 'US/Aleutian',
  },
  {
    min: -600,
    zone: 'US/Hawaii',
  },
  {
    min: -570,
    zone: 'Pacific/Marquesas',
  },
  {
    min: -540,
    zone: 'America/Anchorage',
  },
  {
    min: -540,
    zone: 'America/Juneau',
  },
  {
    min: -540,
    zone: 'America/Metlakatla',
  },
  {
    min: -540,
    zone: 'America/Nome',
  },
  {
    min: -540,
    zone: 'America/Sitka',
  },
  {
    min: -540,
    zone: 'America/Yakutat',
  },
  {
    min: -540,
    zone: 'Etc/GMT+9',
  },
  {
    min: -540,
    zone: 'Pacific/Gambier',
  },
  {
    min: -540,
    zone: 'US/Alaska',
  },
  {
    min: -480,
    zone: 'America/Dawson',
  },
  {
    min: -480,
    zone: 'America/Ensenada',
  },
  {
    min: -480,
    zone: 'America/Los_Angeles',
  },
  {
    min: -480,
    zone: 'America/Santa_Isabel',
  },
  {
    min: -480,
    zone: 'America/Tijuana',
  },
  {
    min: -480,
    zone: 'America/Vancouver',
  },
  {
    min: -480,
    zone: 'America/Whitehorse',
  },
  {
    min: -480,
    zone: 'Canada/Pacific',
  },
  {
    min: -480,
    zone: 'Canada/Yukon',
  },
  {
    min: -480,
    zone: 'Etc/GMT+8',
  },
  {
    min: -480,
    zone: 'Mexico/BajaNorte',
  },
  {
    min: -480,
    zone: 'PST8PDT',
  },
  {
    min: -480,
    zone: 'Pacific/Pitcairn',
  },
  {
    min: -480,
    zone: 'US/Pacific',
  },
  {
    min: -480,
    zone: 'US/Pacific-New',
  },
  {
    min: -420,
    zone: 'America/Boise',
  },
  {
    min: -420,
    zone: 'America/Cambridge_Bay',
  },
  {
    min: -420,
    zone: 'America/Chihuahua',
  },
  {
    min: -420,
    zone: 'America/Creston',
  },
  {
    min: -420,
    zone: 'America/Dawson_Creek',
  },
  {
    min: -420,
    zone: 'America/Denver',
  },
  {
    min: -420,
    zone: 'America/Edmonton',
  },
  {
    min: -420,
    zone: 'America/Fort_Nelson',
  },
  {
    min: -420,
    zone: 'America/Hermosillo',
  },
  {
    min: -420,
    zone: 'America/Inuvik',
  },
  {
    min: -420,
    zone: 'America/Mazatlan',
  },
  {
    min: -420,
    zone: 'America/Ojinaga',
  },
  {
    min: -420,
    zone: 'America/Phoenix',
  },
  {
    min: -420,
    zone: 'America/Shiprock',
  },
  {
    min: -420,
    zone: 'America/Yellowknife',
  },
  {
    min: -420,
    zone: 'Canada/Mountain',
  },
  {
    min: -420,
    zone: 'Etc/GMT+7',
  },
  {
    min: -420,
    zone: 'MST',
  },
  {
    min: -420,
    zone: 'MST7MDT',
  },
  {
    min: -420,
    zone: 'Mexico/BajaSur',
  },
  {
    min: -420,
    zone: 'Navajo',
  },
  {
    min: -420,
    zone: 'US/Arizona',
  },
  {
    min: -420,
    zone: 'US/Mountain',
  },
  {
    min: -360,
    zone: 'America/Bahia_Banderas',
  },
  {
    min: -360,
    zone: 'America/Belize',
  },
  {
    min: -360,
    zone: 'America/Chicago',
  },
  {
    min: -360,
    zone: 'America/Costa_Rica',
  },
  {
    min: -360,
    zone: 'America/El_Salvador',
  },
  {
    min: -360,
    zone: 'America/Guatemala',
  },
  {
    min: -360,
    zone: 'America/Indiana/Knox',
  },
  {
    min: -360,
    zone: 'America/Indiana/Tell_City',
  },
  {
    min: -360,
    zone: 'America/Knox_IN',
  },
  {
    min: -360,
    zone: 'America/Managua',
  },
  {
    min: -360,
    zone: 'America/Matamoros',
  },
  {
    min: -360,
    zone: 'America/Menominee',
  },
  {
    min: -360,
    zone: 'America/Merida',
  },
  {
    min: -360,
    zone: 'America/Mexico_City',
  },
  {
    min: -360,
    zone: 'America/Monterrey',
  },
  {
    min: -360,
    zone: 'America/North_Dakota/Beulah',
  },
  {
    min: -360,
    zone: 'America/North_Dakota/Center',
  },
  {
    min: -360,
    zone: 'America/North_Dakota/New_Salem',
  },
  {
    min: -360,
    zone: 'America/Rainy_River',
  },
  {
    min: -360,
    zone: 'America/Rankin_Inlet',
  },
  {
    min: -360,
    zone: 'America/Regina',
  },
  {
    min: -360,
    zone: 'America/Resolute',
  },
  {
    min: -360,
    zone: 'America/Swift_Current',
  },
  {
    min: -360,
    zone: 'America/Tegucigalpa',
  },
  {
    min: -360,
    zone: 'America/Winnipeg',
  },
  {
    min: -360,
    zone: 'CST6CDT',
  },
  {
    min: -360,
    zone: 'Canada/Central',
  },
  {
    min: -360,
    zone: 'Canada/Saskatchewan',
  },
  {
    min: -360,
    zone: 'Etc/GMT+6',
  },
  {
    min: -360,
    zone: 'Mexico/General',
  },
  {
    min: -360,
    zone: 'Pacific/Galapagos',
  },
  {
    min: -360,
    zone: 'US/Central',
  },
  {
    min: -360,
    zone: 'US/Indiana-Starke',
  },
  {
    min: -300,
    zone: 'America/Atikokan',
  },
  {
    min: -300,
    zone: 'America/Bogota',
  },
  {
    min: -300,
    zone: 'America/Cancun',
  },
  {
    min: -300,
    zone: 'America/Cayman',
  },
  {
    min: -300,
    zone: 'America/Coral_Harbour',
  },
  {
    min: -300,
    zone: 'America/Detroit',
  },
  {
    min: -300,
    zone: 'America/Eirunepe',
  },
  {
    min: -300,
    zone: 'America/Fort_Wayne',
  },
  {
    min: -300,
    zone: 'America/Grand_Turk',
  },
  {
    min: -300,
    zone: 'America/Guayaquil',
  },
  {
    min: -300,
    zone: 'America/Havana',
  },
  {
    min: -300,
    zone: 'America/Indiana/Indianapolis',
  },
  {
    min: -300,
    zone: 'America/Indiana/Marengo',
  },
  {
    min: -300,
    zone: 'America/Indiana/Petersburg',
  },
  {
    min: -300,
    zone: 'America/Indiana/Vevay',
  },
  {
    min: -300,
    zone: 'America/Indiana/Vincennes',
  },
  {
    min: -300,
    zone: 'America/Indiana/Winamac',
  },
  {
    min: -300,
    zone: 'America/Indianapolis',
  },
  {
    min: -300,
    zone: 'America/Iqaluit',
  },
  {
    min: -300,
    zone: 'America/Jamaica',
  },
  {
    min: -300,
    zone: 'America/Kentucky/Louisville',
  },
  {
    min: -300,
    zone: 'America/Kentucky/Monticello',
  },
  {
    min: -300,
    zone: 'America/Lima',
  },
  {
    min: -300,
    zone: 'America/Louisville',
  },
  {
    min: -300,
    zone: 'America/Montreal',
  },
  {
    min: -300,
    zone: 'America/Nassau',
  },
  {
    min: -300,
    zone: 'America/New_York',
  },
  {
    min: -300,
    zone: 'America/Nipigon',
  },
  {
    min: -300,
    zone: 'America/Panama',
  },
  {
    min: -300,
    zone: 'America/Pangnirtung',
  },
  {
    min: -300,
    zone: 'America/Port-au-Prince',
  },
  {
    min: -300,
    zone: 'America/Porto_Acre',
  },
  {
    min: -300,
    zone: 'America/Rio_Branco',
  },
  {
    min: -300,
    zone: 'America/Thunder_Bay',
  },
  {
    min: -300,
    zone: 'America/Toronto',
  },
  {
    min: -300,
    zone: 'Brazil/Acre',
  },
  {
    min: -300,
    zone: 'Canada/Eastern',
  },
  {
    min: -300,
    zone: 'Chile/EasterIsland',
  },
  {
    min: -300,
    zone: 'Cuba',
  },
  {
    min: -300,
    zone: 'EST',
  },
  {
    min: -300,
    zone: 'EST5EDT',
  },
  {
    min: -300,
    zone: 'Etc/GMT+5',
  },
  {
    min: -300,
    zone: 'Jamaica',
  },
  {
    min: -300,
    zone: 'Pacific/Easter',
  },
  {
    min: -300,
    zone: 'US/East-Indiana',
  },
  {
    min: -300,
    zone: 'US/Eastern',
  },
  {
    min: -300,
    zone: 'US/Michigan',
  },
  {
    min: -240,
    zone: 'America/Anguilla',
  },
  {
    min: -240,
    zone: 'America/Antigua',
  },
  {
    min: -240,
    zone: 'America/Aruba',
  },
  {
    min: -240,
    zone: 'America/Barbados',
  },
  {
    min: -240,
    zone: 'America/Blanc-Sablon',
  },
  {
    min: -240,
    zone: 'America/Boa_Vista',
  },
  {
    min: -240,
    zone: 'America/Campo_Grande',
  },
  {
    min: -240,
    zone: 'America/Caracas',
  },
  {
    min: -240,
    zone: 'America/Cuiaba',
  },
  {
    min: -240,
    zone: 'America/Curacao',
  },
  {
    min: -240,
    zone: 'America/Dominica',
  },
  {
    min: -240,
    zone: 'America/Glace_Bay',
  },
  {
    min: -240,
    zone: 'America/Goose_Bay',
  },
  {
    min: -240,
    zone: 'America/Grenada',
  },
  {
    min: -240,
    zone: 'America/Guadeloupe',
  },
  {
    min: -240,
    zone: 'America/Guyana',
  },
  {
    min: -240,
    zone: 'America/Halifax',
  },
  {
    min: -240,
    zone: 'America/Kralendijk',
  },
  {
    min: -240,
    zone: 'America/La_Paz',
  },
  {
    min: -240,
    zone: 'America/Lower_Princes',
  },
  {
    min: -240,
    zone: 'America/Manaus',
  },
  {
    min: -240,
    zone: 'America/Marigot',
  },
  {
    min: -240,
    zone: 'America/Martinique',
  },
  {
    min: -240,
    zone: 'America/Moncton',
  },
  {
    min: -240,
    zone: 'America/Montserrat',
  },
  {
    min: -240,
    zone: 'America/Port_of_Spain',
  },
  {
    min: -240,
    zone: 'America/Porto_Velho',
  },
  {
    min: -240,
    zone: 'America/Puerto_Rico',
  },
  {
    min: -240,
    zone: 'America/Santo_Domingo',
  },
  {
    min: -240,
    zone: 'America/St_Barthelemy',
  },
  {
    min: -240,
    zone: 'America/St_Kitts',
  },
  {
    min: -240,
    zone: 'America/St_Lucia',
  },
  {
    min: -240,
    zone: 'America/St_Thomas',
  },
  {
    min: -240,
    zone: 'America/St_Vincent',
  },
  {
    min: -240,
    zone: 'America/Thule',
  },
  {
    min: -240,
    zone: 'America/Tortola',
  },
  {
    min: -240,
    zone: 'America/Virgin',
  },
  {
    min: -240,
    zone: 'Atlantic/Bermuda',
  },
  {
    min: -240,
    zone: 'Brazil/West',
  },
  {
    min: -240,
    zone: 'Canada/Atlantic',
  },
  {
    min: -240,
    zone: 'Etc/GMT+4',
  },
  {
    min: -210,
    zone: 'America/St_Johns',
  },
  {
    min: -210,
    zone: 'Canada/Newfoundland',
  },
  {
    min: -180,
    zone: 'America/Araguaina',
  },
  {
    min: -180,
    zone: 'America/Argentina/Buenos_Aires',
  },
  {
    min: -180,
    zone: 'America/Argentina/Catamarca',
  },
  {
    min: -180,
    zone: 'America/Argentina/ComodRivadavia',
  },
  {
    min: -180,
    zone: 'America/Argentina/Cordoba',
  },
  {
    min: -180,
    zone: 'America/Argentina/Jujuy',
  },
  {
    min: -180,
    zone: 'America/Argentina/La_Rioja',
  },
  {
    min: -180,
    zone: 'America/Argentina/Mendoza',
  },
  {
    min: -180,
    zone: 'America/Argentina/Rio_Gallegos',
  },
  {
    min: -180,
    zone: 'America/Argentina/Salta',
  },
  {
    min: -180,
    zone: 'America/Argentina/San_Juan',
  },
  {
    min: -180,
    zone: 'America/Argentina/San_Luis',
  },
  {
    min: -180,
    zone: 'America/Argentina/Tucuman',
  },
  {
    min: -180,
    zone: 'America/Argentina/Ushuaia',
  },
  {
    min: -180,
    zone: 'America/Asuncion',
  },
  {
    min: -180,
    zone: 'America/Bahia',
  },
  {
    min: -180,
    zone: 'America/Belem',
  },
  {
    min: -180,
    zone: 'America/Buenos_Aires',
  },
  {
    min: -180,
    zone: 'America/Catamarca',
  },
  {
    min: -180,
    zone: 'America/Cayenne',
  },
  {
    min: -180,
    zone: 'America/Cordoba',
  },
  {
    min: -180,
    zone: 'America/Fortaleza',
  },
  {
    min: -180,
    zone: 'America/Godthab',
  },
  {
    min: -180,
    zone: 'America/Jujuy',
  },
  {
    min: -180,
    zone: 'America/Maceio',
  },
  {
    min: -180,
    zone: 'America/Mendoza',
  },
  {
    min: -180,
    zone: 'America/Miquelon',
  },
  {
    min: -180,
    zone: 'America/Montevideo',
  },
  {
    min: -180,
    zone: 'America/Paramaribo',
  },
  {
    min: -180,
    zone: 'America/Punta_Arenas',
  },
  {
    min: -180,
    zone: 'America/Recife',
  },
  {
    min: -180,
    zone: 'America/Rosario',
  },
  {
    min: -180,
    zone: 'America/Santarem',
  },
  {
    min: -180,
    zone: 'America/Santiago',
  },
  {
    min: -180,
    zone: 'America/Sao_Paulo',
  },
  {
    min: -180,
    zone: 'Antarctica/Palmer',
  },
  {
    min: -180,
    zone: 'Antarctica/Rothera',
  },
  {
    min: -180,
    zone: 'Atlantic/Stanley',
  },
  {
    min: -180,
    zone: 'Brazil/East',
  },
  {
    min: -180,
    zone: 'Chile/Continental',
  },
  {
    min: -180,
    zone: 'Etc/GMT+3',
  },
  {
    min: -120,
    zone: 'America/Noronha',
  },
  {
    min: -120,
    zone: 'Atlantic/South_Georgia',
  },
  {
    min: -120,
    zone: 'Brazil/DeNoronha',
  },
  {
    min: -120,
    zone: 'Etc/GMT+2',
  },
  {
    min: -60,
    zone: 'America/Scoresbysund',
  },
  {
    min: -60,
    zone: 'Atlantic/Azores',
  },
  {
    min: -60,
    zone: 'Atlantic/Cape_Verde',
  },
  {
    min: -60,
    zone: 'Etc/GMT+1',
  },
  {
    min: 0,
    zone: 'Africa/Abidjan',
  },
  {
    min: 0,
    zone: 'Africa/Accra',
  },
  {
    min: 0,
    zone: 'Africa/Bamako',
  },
  {
    min: 0,
    zone: 'Africa/Banjul',
  },
  {
    min: 0,
    zone: 'Africa/Bissau',
  },
  {
    min: 0,
    zone: 'Africa/Conakry',
  },
  {
    min: 0,
    zone: 'Africa/Dakar',
  },
  {
    min: 0,
    zone: 'Africa/Freetown',
  },
  {
    min: 0,
    zone: 'Africa/Lome',
  },
  {
    min: 0,
    zone: 'Africa/Monrovia',
  },
  {
    min: 0,
    zone: 'Africa/Nouakchott',
  },
  {
    min: 0,
    zone: 'Africa/Ouagadougou',
  },
  {
    min: 0,
    zone: 'Africa/Sao_Tome',
  },
  {
    min: 0,
    zone: 'Africa/Timbuktu',
  },
  {
    min: 0,
    zone: 'America/Danmarkshavn',
  },
  {
    min: 0,
    zone: 'Antarctica/Troll',
  },
  {
    min: 0,
    zone: 'Atlantic/Canary',
  },
  {
    min: 0,
    zone: 'Atlantic/Faeroe',
  },
  {
    min: 0,
    zone: 'Atlantic/Faroe',
  },
  {
    min: 0,
    zone: 'Atlantic/Madeira',
  },
  {
    min: 0,
    zone: 'Atlantic/Reykjavik',
  },
  {
    min: 0,
    zone: 'Atlantic/St_Helena',
  },
  {
    min: 0,
    zone: 'Eire',
  },
  {
    min: 0,
    zone: 'Etc/GMT',
  },
  {
    min: 0,
    zone: 'Etc/GMT+0',
  },
  {
    min: 0,
    zone: 'Etc/GMT-0',
  },
  {
    min: 0,
    zone: 'Etc/GMT0',
  },
  {
    min: 0,
    zone: 'Etc/Greenwich',
  },
  {
    min: 0,
    zone: 'Etc/UCT',
  },
  {
    min: 0,
    zone: 'Etc/UTC',
  },
  {
    min: 0,
    zone: 'Etc/Universal',
  },
  {
    min: 0,
    zone: 'Etc/Zulu',
  },
  {
    min: 0,
    zone: 'Europe/Belfast',
  },
  {
    min: 0,
    zone: 'Europe/Dublin',
  },
  {
    min: 0,
    zone: 'Europe/Guernsey',
  },
  {
    min: 0,
    zone: 'Europe/Isle_of_Man',
  },
  {
    min: 0,
    zone: 'Europe/Jersey',
  },
  {
    min: 0,
    zone: 'Europe/Lisbon',
  },
  {
    min: 0,
    zone: 'Europe/London',
  },
  {
    min: 0,
    zone: 'GB',
  },
  {
    min: 0,
    zone: 'GB-Eire',
  },
  {
    min: 0,
    zone: 'GMT',
  },
  {
    min: 0,
    zone: 'GMT+0',
  },
  {
    min: 0,
    zone: 'GMT-0',
  },
  {
    min: 0,
    zone: 'GMT0',
  },
  {
    min: 0,
    zone: 'Greenwich',
  },
  {
    min: 0,
    zone: 'Iceland',
  },
  {
    min: 0,
    zone: 'Portugal',
  },
  {
    min: 0,
    zone: 'UCT',
  },
  {
    min: 0,
    zone: 'UTC',
  },
  {
    min: 0,
    zone: 'Universal',
  },
  {
    min: 0,
    zone: 'WET',
  },
  {
    min: 0,
    zone: 'Zulu',
  },
  {
    min: 60,
    zone: 'Africa/Algiers',
  },
  {
    min: 60,
    zone: 'Africa/Bangui',
  },
  {
    min: 60,
    zone: 'Africa/Brazzaville',
  },
  {
    min: 60,
    zone: 'Africa/Casablanca',
  },
  {
    min: 60,
    zone: 'Africa/Ceuta',
  },
  {
    min: 60,
    zone: 'Africa/Douala',
  },
  {
    min: 60,
    zone: 'Africa/El_Aaiun',
  },
  {
    min: 60,
    zone: 'Africa/Kinshasa',
  },
  {
    min: 60,
    zone: 'Africa/Lagos',
  },
  {
    min: 60,
    zone: 'Africa/Libreville',
  },
  {
    min: 60,
    zone: 'Africa/Luanda',
  },
  {
    min: 60,
    zone: 'Africa/Malabo',
  },
  {
    min: 60,
    zone: 'Africa/Ndjamena',
  },
  {
    min: 60,
    zone: 'Africa/Niamey',
  },
  {
    min: 60,
    zone: 'Africa/Porto-Novo',
  },
  {
    min: 60,
    zone: 'Africa/Tunis',
  },
  {
    min: 60,
    zone: 'Arctic/Longyearbyen',
  },
  {
    min: 60,
    zone: 'Atlantic/Jan_Mayen',
  },
  {
    min: 60,
    zone: 'CET',
  },
  {
    min: 60,
    zone: 'Etc/GMT-1',
  },
  {
    min: 60,
    zone: 'Europe/Amsterdam',
  },
  {
    min: 60,
    zone: 'Europe/Andorra',
  },
  {
    min: 60,
    zone: 'Europe/Belgrade',
  },
  {
    min: 60,
    zone: 'Europe/Berlin',
  },
  {
    min: 60,
    zone: 'Europe/Bratislava',
  },
  {
    min: 60,
    zone: 'Europe/Brussels',
  },
  {
    min: 60,
    zone: 'Europe/Budapest',
  },
  {
    min: 60,
    zone: 'Europe/Busingen',
  },
  {
    min: 60,
    zone: 'Europe/Copenhagen',
  },
  {
    min: 60,
    zone: 'Europe/Gibraltar',
  },
  {
    min: 60,
    zone: 'Europe/Ljubljana',
  },
  {
    min: 60,
    zone: 'Europe/Luxembourg',
  },
  {
    min: 60,
    zone: 'Europe/Madrid',
  },
  {
    min: 60,
    zone: 'Europe/Malta',
  },
  {
    min: 60,
    zone: 'Europe/Monaco',
  },
  {
    min: 60,
    zone: 'Europe/Oslo',
  },
  {
    min: 60,
    zone: 'Europe/Paris',
  },
  {
    min: 60,
    zone: 'Europe/Podgorica',
  },
  {
    min: 60,
    zone: 'Europe/Prague',
  },
  {
    min: 60,
    zone: 'Europe/Rome',
  },
  {
    min: 60,
    zone: 'Europe/San_Marino',
  },
  {
    min: 60,
    zone: 'Europe/Sarajevo',
  },
  {
    min: 60,
    zone: 'Europe/Skopje',
  },
  {
    min: 60,
    zone: 'Europe/Stockholm',
  },
  {
    min: 60,
    zone: 'Europe/Tirane',
  },
  {
    min: 60,
    zone: 'Europe/Vaduz',
  },
  {
    min: 60,
    zone: 'Europe/Vatican',
  },
  {
    min: 60,
    zone: 'Europe/Vienna',
  },
  {
    min: 60,
    zone: 'Europe/Warsaw',
  },
  {
    min: 60,
    zone: 'Europe/Zagreb',
  },
  {
    min: 60,
    zone: 'Europe/Zurich',
  },
  {
    min: 60,
    zone: 'MET',
  },
  {
    min: 60,
    zone: 'Poland',
  },
  {
    min: 120,
    zone: 'Africa/Blantyre',
  },
  {
    min: 120,
    zone: 'Africa/Bujumbura',
  },
  {
    min: 120,
    zone: 'Africa/Cairo',
  },
  {
    min: 120,
    zone: 'Africa/Gaborone',
  },
  {
    min: 120,
    zone: 'Africa/Harare',
  },
  {
    min: 120,
    zone: 'Africa/Johannesburg',
  },
  {
    min: 120,
    zone: 'Africa/Khartoum',
  },
  {
    min: 120,
    zone: 'Africa/Kigali',
  },
  {
    min: 120,
    zone: 'Africa/Lubumbashi',
  },
  {
    min: 120,
    zone: 'Africa/Lusaka',
  },
  {
    min: 120,
    zone: 'Africa/Maputo',
  },
  {
    min: 120,
    zone: 'Africa/Maseru',
  },
  {
    min: 120,
    zone: 'Africa/Mbabane',
  },
  {
    min: 120,
    zone: 'Africa/Tripoli',
  },
  {
    min: 120,
    zone: 'Africa/Windhoek',
  },
  {
    min: 120,
    zone: 'Asia/Amman',
  },
  {
    min: 120,
    zone: 'Asia/Beirut',
  },
  {
    min: 120,
    zone: 'Asia/Damascus',
  },
  {
    min: 120,
    zone: 'Asia/Famagusta',
  },
  {
    min: 120,
    zone: 'Asia/Gaza',
  },
  {
    min: 120,
    zone: 'Asia/Hebron',
  },
  {
    min: 120,
    zone: 'Asia/Jerusalem',
  },
  {
    min: 120,
    zone: 'Asia/Nicosia',
  },
  {
    min: 120,
    zone: 'Asia/Tel_Aviv',
  },
  {
    min: 120,
    zone: 'EET',
  },
  {
    min: 120,
    zone: 'Egypt',
  },
  {
    min: 120,
    zone: 'Etc/GMT-2',
  },
  {
    min: 120,
    zone: 'Europe/Athens',
  },
  {
    min: 120,
    zone: 'Europe/Bucharest',
  },
  {
    min: 120,
    zone: 'Europe/Chisinau',
  },
  {
    min: 120,
    zone: 'Europe/Helsinki',
  },
  {
    min: 120,
    zone: 'Europe/Kaliningrad',
  },
  {
    min: 120,
    zone: 'Europe/Kiev',
  },
  {
    min: 120,
    zone: 'Europe/Mariehamn',
  },
  {
    min: 120,
    zone: 'Europe/Nicosia',
  },
  {
    min: 120,
    zone: 'Europe/Riga',
  },
  {
    min: 120,
    zone: 'Europe/Sofia',
  },
  {
    min: 120,
    zone: 'Europe/Tallinn',
  },
  {
    min: 120,
    zone: 'Europe/Tiraspol',
  },
  {
    min: 120,
    zone: 'Europe/Uzhgorod',
  },
  {
    min: 120,
    zone: 'Europe/Vilnius',
  },
  {
    min: 120,
    zone: 'Europe/Zaporozhye',
  },
  {
    min: 120,
    zone: 'Israel',
  },
  {
    min: 120,
    zone: 'Libya',
  },
  {
    min: 180,
    zone: 'Africa/Addis_Ababa',
  },
  {
    min: 180,
    zone: 'Africa/Asmara',
  },
  {
    min: 180,
    zone: 'Africa/Asmera',
  },
  {
    min: 180,
    zone: 'Africa/Dar_es_Salaam',
  },
  {
    min: 180,
    zone: 'Africa/Djibouti',
  },
  {
    min: 180,
    zone: 'Africa/Juba',
  },
  {
    min: 180,
    zone: 'Africa/Kampala',
  },
  {
    min: 180,
    zone: 'Africa/Mogadishu',
  },
  {
    min: 180,
    zone: 'Africa/Nairobi',
  },
  {
    min: 180,
    zone: 'Antarctica/Syowa',
  },
  {
    min: 180,
    zone: 'Asia/Aden',
  },
  {
    min: 180,
    zone: 'Asia/Baghdad',
  },
  {
    min: 180,
    zone: 'Asia/Bahrain',
  },
  {
    min: 180,
    zone: 'Asia/Istanbul',
  },
  {
    min: 180,
    zone: 'Asia/Kuwait',
  },
  {
    min: 180,
    zone: 'Asia/Qatar',
  },
  {
    min: 180,
    zone: 'Asia/Riyadh',
  },
  {
    min: 180,
    zone: 'Etc/GMT-3',
  },
  {
    min: 180,
    zone: 'Europe/Istanbul',
  },
  {
    min: 180,
    zone: 'Europe/Kirov',
  },
  {
    min: 180,
    zone: 'Europe/Minsk',
  },
  {
    min: 180,
    zone: 'Europe/Moscow',
  },
  {
    min: 180,
    zone: 'Europe/Simferopol',
  },
  {
    min: 180,
    zone: 'Indian/Antananarivo',
  },
  {
    min: 180,
    zone: 'Indian/Comoro',
  },
  {
    min: 180,
    zone: 'Indian/Mayotte',
  },
  {
    min: 180,
    zone: 'Turkey',
  },
  {
    min: 180,
    zone: 'W-SU',
  },
  {
    min: 210,
    zone: 'Asia/Tehran',
  },
  {
    min: 210,
    zone: 'Iran',
  },
  {
    min: 240,
    zone: 'Asia/Baku',
  },
  {
    min: 240,
    zone: 'Asia/Dubai',
  },
  {
    min: 240,
    zone: 'Asia/Muscat',
  },
  {
    min: 240,
    zone: 'Asia/Tbilisi',
  },
  {
    min: 240,
    zone: 'Asia/Yerevan',
  },
  {
    min: 240,
    zone: 'Etc/GMT-4',
  },
  {
    min: 240,
    zone: 'Europe/Astrakhan',
  },
  {
    min: 240,
    zone: 'Europe/Samara',
  },
  {
    min: 240,
    zone: 'Europe/Saratov',
  },
  {
    min: 240,
    zone: 'Europe/Ulyanovsk',
  },
  {
    min: 240,
    zone: 'Europe/Volgograd',
  },
  {
    min: 240,
    zone: 'Indian/Mahe',
  },
  {
    min: 240,
    zone: 'Indian/Mauritius',
  },
  {
    min: 240,
    zone: 'Indian/Reunion',
  },
  {
    min: 270,
    zone: 'Asia/Kabul',
  },
  {
    min: 300,
    zone: 'Antarctica/Mawson',
  },
  {
    min: 300,
    zone: 'Asia/Aqtau',
  },
  {
    min: 300,
    zone: 'Asia/Aqtobe',
  },
  {
    min: 300,
    zone: 'Asia/Ashgabat',
  },
  {
    min: 300,
    zone: 'Asia/Ashkhabad',
  },
  {
    min: 300,
    zone: 'Asia/Atyrau',
  },
  {
    min: 300,
    zone: 'Asia/Dushanbe',
  },
  {
    min: 300,
    zone: 'Asia/Karachi',
  },
  {
    min: 300,
    zone: 'Asia/Oral',
  },
  {
    min: 300,
    zone: 'Asia/Qyzylorda',
  },
  {
    min: 300,
    zone: 'Asia/Samarkand',
  },
  {
    min: 300,
    zone: 'Asia/Tashkent',
  },
  {
    min: 300,
    zone: 'Asia/Yekaterinburg',
  },
  {
    min: 300,
    zone: 'Etc/GMT-5',
  },
  {
    min: 300,
    zone: 'Indian/Kerguelen',
  },
  {
    min: 300,
    zone: 'Indian/Maldives',
  },
  {
    min: 330,
    zone: 'Asia/Calcutta',
  },
  {
    min: 330,
    zone: 'Asia/Colombo',
  },
  {
    min: 330,
    zone: 'Asia/Kolkata',
  },
  {
    min: 345,
    zone: 'Asia/Kathmandu',
  },
  {
    min: 345,
    zone: 'Asia/Katmandu',
  },
  {
    min: 360,
    zone: 'Antarctica/Vostok',
  },
  {
    min: 360,
    zone: 'Asia/Almaty',
  },
  {
    min: 360,
    zone: 'Asia/Bishkek',
  },
  {
    min: 360,
    zone: 'Asia/Dacca',
  },
  {
    min: 360,
    zone: 'Asia/Dhaka',
  },
  {
    min: 360,
    zone: 'Asia/Kashgar',
  },
  {
    min: 360,
    zone: 'Asia/Omsk',
  },
  {
    min: 360,
    zone: 'Asia/Qostanay',
  },
  {
    min: 360,
    zone: 'Asia/Thimbu',
  },
  {
    min: 360,
    zone: 'Asia/Thimphu',
  },
  {
    min: 360,
    zone: 'Asia/Urumqi',
  },
  {
    min: 360,
    zone: 'Etc/GMT-6',
  },
  {
    min: 360,
    zone: 'Indian/Chagos',
  },
  {
    min: 390,
    zone: 'Asia/Rangoon',
  },
  {
    min: 390,
    zone: 'Asia/Yangon',
  },
  {
    min: 390,
    zone: 'Indian/Cocos',
  },
  {
    min: 420,
    zone: 'Antarctica/Davis',
  },
  {
    min: 420,
    zone: 'Asia/Bangkok',
  },
  {
    min: 420,
    zone: 'Asia/Barnaul',
  },
  {
    min: 420,
    zone: 'Asia/Ho_Chi_Minh',
  },
  {
    min: 420,
    zone: 'Asia/Hovd',
  },
  {
    min: 420,
    zone: 'Asia/Jakarta',
  },
  {
    min: 420,
    zone: 'Asia/Krasnoyarsk',
  },
  {
    min: 420,
    zone: 'Asia/Novokuznetsk',
  },
  {
    min: 420,
    zone: 'Asia/Novosibirsk',
  },
  {
    min: 420,
    zone: 'Asia/Phnom_Penh',
  },
  {
    min: 420,
    zone: 'Asia/Pontianak',
  },
  {
    min: 420,
    zone: 'Asia/Saigon',
  },
  {
    min: 420,
    zone: 'Asia/Tomsk',
  },
  {
    min: 420,
    zone: 'Asia/Vientiane',
  },
  {
    min: 420,
    zone: 'Etc/GMT-7',
  },
  {
    min: 420,
    zone: 'Indian/Christmas',
  },
  {
    min: 480,
    zone: 'Antarctica/Casey',
  },
  {
    min: 480,
    zone: 'Asia/Brunei',
  },
  {
    min: 480,
    zone: 'Asia/Choibalsan',
  },
  {
    min: 480,
    zone: 'Asia/Chongqing',
  },
  {
    min: 480,
    zone: 'Asia/Chungking',
  },
  {
    min: 480,
    zone: 'Asia/Harbin',
  },
  {
    min: 480,
    zone: 'Asia/Hong_Kong',
  },
  {
    min: 480,
    zone: 'Asia/Irkutsk',
  },
  {
    min: 480,
    zone: 'Asia/Kuala_Lumpur',
  },
  {
    min: 480,
    zone: 'Asia/Kuching',
  },
  {
    min: 480,
    zone: 'Asia/Macao',
  },
  {
    min: 480,
    zone: 'Asia/Macau',
  },
  {
    min: 480,
    zone: 'Asia/Makassar',
  },
  {
    min: 480,
    zone: 'Asia/Manila',
  },
  {
    min: 480,
    zone: 'Asia/Shanghai',
  },
  {
    min: 480,
    zone: 'Asia/Singapore',
  },
  {
    min: 480,
    zone: 'Asia/Taipei',
  },
  {
    min: 480,
    zone: 'Asia/Ujung_Pandang',
  },
  {
    min: 480,
    zone: 'Asia/Ulaanbaatar',
  },
  {
    min: 480,
    zone: 'Asia/Ulan_Bator',
  },
  {
    min: 480,
    zone: 'Australia/Perth',
  },
  {
    min: 480,
    zone: 'Australia/West',
  },
  {
    min: 480,
    zone: 'Etc/GMT-8',
  },
  {
    min: 480,
    zone: 'Hongkong',
  },
  {
    min: 480,
    zone: 'PRC',
  },
  {
    min: 480,
    zone: 'ROC',
  },
  {
    min: 480,
    zone: 'Singapore',
  },
  {
    min: 525,
    zone: 'Australia/Eucla',
  },
  {
    min: 540,
    zone: 'Asia/Chita',
  },
  {
    min: 540,
    zone: 'Asia/Dili',
  },
  {
    min: 540,
    zone: 'Asia/Jayapura',
  },
  {
    min: 540,
    zone: 'Asia/Khandyga',
  },
  {
    min: 540,
    zone: 'Asia/Pyongyang',
  },
  {
    min: 540,
    zone: 'Asia/Seoul',
  },
  {
    min: 540,
    zone: 'Asia/Tokyo',
  },
  {
    min: 540,
    zone: 'Asia/Yakutsk',
  },
  {
    min: 540,
    zone: 'Etc/GMT-9',
  },
  {
    min: 540,
    zone: 'Japan',
  },
  {
    min: 540,
    zone: 'Pacific/Palau',
  },
  {
    min: 540,
    zone: 'ROK',
  },
  {
    min: 570,
    zone: 'Australia/Darwin',
  },
  {
    min: 570,
    zone: 'Australia/North',
  },
  {
    min: 600,
    zone: 'Antarctica/DumontDUrville',
  },
  {
    min: 600,
    zone: 'Asia/Ust-Nera',
  },
  {
    min: 600,
    zone: 'Asia/Vladivostok',
  },
  {
    min: 600,
    zone: 'Australia/Brisbane',
  },
  {
    min: 600,
    zone: 'Australia/Lindeman',
  },
  {
    min: 600,
    zone: 'Australia/Queensland',
  },
  {
    min: 600,
    zone: 'Etc/GMT-10',
  },
  {
    min: 600,
    zone: 'Pacific/Chuuk',
  },
  {
    min: 600,
    zone: 'Pacific/Guam',
  },
  {
    min: 600,
    zone: 'Pacific/Port_Moresby',
  },
  {
    min: 600,
    zone: 'Pacific/Saipan',
  },
  {
    min: 600,
    zone: 'Pacific/Truk',
  },
  {
    min: 600,
    zone: 'Pacific/Yap',
  },
  {
    min: 630,
    zone: 'Australia/Adelaide',
  },
  {
    min: 630,
    zone: 'Australia/Broken_Hill',
  },
  {
    min: 630,
    zone: 'Australia/South',
  },
  {
    min: 630,
    zone: 'Australia/Yancowinna',
  },
  {
    min: 660,
    zone: 'Antarctica/Macquarie',
  },
  {
    min: 660,
    zone: 'Asia/Magadan',
  },
  {
    min: 660,
    zone: 'Asia/Sakhalin',
  },
  {
    min: 660,
    zone: 'Asia/Srednekolymsk',
  },
  {
    min: 660,
    zone: 'Australia/ACT',
  },
  {
    min: 660,
    zone: 'Australia/Canberra',
  },
  {
    min: 660,
    zone: 'Australia/Currie',
  },
  {
    min: 660,
    zone: 'Australia/Hobart',
  },
  {
    min: 660,
    zone: 'Australia/LHI',
  },
  {
    min: 660,
    zone: 'Australia/Lord_Howe',
  },
  {
    min: 660,
    zone: 'Australia/Melbourne',
  },
  {
    min: 660,
    zone: 'Australia/NSW',
  },
  {
    min: 660,
    zone: 'Australia/Sydney',
  },
  {
    min: 660,
    zone: 'Australia/Tasmania',
  },
  {
    min: 660,
    zone: 'Australia/Victoria',
  },
  {
    min: 660,
    zone: 'Etc/GMT-11',
  },
  {
    min: 660,
    zone: 'Pacific/Bougainville',
  },
  {
    min: 660,
    zone: 'Pacific/Efate',
  },
  {
    min: 660,
    zone: 'Pacific/Guadalcanal',
  },
  {
    min: 660,
    zone: 'Pacific/Kosrae',
  },
  {
    min: 660,
    zone: 'Pacific/Noumea',
  },
  {
    min: 660,
    zone: 'Pacific/Pohnpei',
  },
  {
    min: 660,
    zone: 'Pacific/Ponape',
  },
  {
    min: 720,
    zone: 'Asia/Anadyr',
  },
  {
    min: 720,
    zone: 'Asia/Kamchatka',
  },
  {
    min: 720,
    zone: 'Etc/GMT-12',
  },
  {
    min: 720,
    zone: 'Kwajalein',
  },
  {
    min: 720,
    zone: 'Pacific/Funafuti',
  },
  {
    min: 720,
    zone: 'Pacific/Kwajalein',
  },
  {
    min: 720,
    zone: 'Pacific/Majuro',
  },
  {
    min: 720,
    zone: 'Pacific/Nauru',
  },
  {
    min: 720,
    zone: 'Pacific/Norfolk',
  },
  {
    min: 720,
    zone: 'Pacific/Tarawa',
  },
  {
    min: 720,
    zone: 'Pacific/Wake',
  },
  {
    min: 720,
    zone: 'Pacific/Wallis',
  },
  {
    min: 780,
    zone: 'Antarctica/McMurdo',
  },
  {
    min: 780,
    zone: 'Antarctica/South_Pole',
  },
  {
    min: 780,
    zone: 'Etc/GMT-13',
  },
  {
    min: 780,
    zone: 'NZ',
  },
  {
    min: 780,
    zone: 'Pacific/Auckland',
  },
  {
    min: 780,
    zone: 'Pacific/Enderbury',
  },
  {
    min: 780,
    zone: 'Pacific/Fakaofo',
  },
  {
    min: 780,
    zone: 'Pacific/Fiji',
  },
  {
    min: 780,
    zone: 'Pacific/Tongatapu',
  },
  {
    min: 825,
    zone: 'NZ-CHAT',
  },
  {
    min: 825,
    zone: 'Pacific/Chatham',
  },
  {
    min: 840,
    zone: 'Etc/GMT-14',
  },
  {
    min: 840,
    zone: 'Pacific/Apia',
  },
  {
    min: 840,
    zone: 'Pacific/Kiritimati',
  },
];
