import React from 'react';
import { Layout } from 'antd';
import ContentWrapper from 'atoms/ContentWrapper';
import Card from 'atoms/Card';
import { Content, Wrapper, Description } from './AntStyled';

function AnnouncementSettings() {
  return (
    <Layout>
      <Content>
        <Wrapper>
          <Card padding="16" className="card">
            <Description>Settings here</Description>
          </Card>
        </Wrapper>
      </Content>
    </Layout>
  );
}

export default AnnouncementSettings;
