import { Input } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import styled, { css } from 'styled-components';
import Variables from 'design/Variables';
const { TextArea } = Input;

export const Wrapper = styled.div`
  .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
    color: initial;
    background-color: initial;
    border-color: initial;
    cursor: unset;
    input,
    .icon-check,
    img {
      cursor: unset;
    }
  }
  .row-input {
    padding: 0 6px 0 8px;
    flex-flow: row nowrap;
  }
  .drag-img {
    margin-right: 4px;
  }
  .ant-checkbox {
    margin-top: 8px;
  }
  .drag-icon {
    margin-top: 4px;
  }
  .ant-input[disabled] {
    font-size: ${({ large }) => (large ? '16px' : '14px')};
    line-height: ${Variables.LineHeightMd};
    color: ${Colors.TextSecondary};
    background-color: inherit;
    cursor: not-allowed;
    opacity: 1;
  }
  .border-wrapper {
    position: relative;
    height: fit-content;
    margin: 4px 0px 0px 6px;
    padding-left: Calc(${Spacings.xxSmall.padding} + 4px);
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    font: ${fonts.xSmall.regular};
    margin-left: 10px;
  }
  .input-checkbox {
    margin-right: 8px;
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }
  .ant-checkbox-wrapper .ant-checkbox {
    :hover {
      border-color: ${Colors.TextGreen};
    }
  }
  .ant-checkbox-inner {
    border-radius: 3px;
    border-width: 0.875px;
  }
  .ant-checkbox-wrapper:hover + .ant-checkbox-inner:hover {
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox-checked::after {
    border-color: ${Colors.TextGreen};
    border-radius: 3px;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox-input:hover {
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Colors.TextGreen};
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox-inner {
    border-color: ${Colors.TextSecondary};
  }
  .ant-checkbox-inner {
    border-color: ${Colors.TextSecondary};
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${Colors.CardShadow};
  }
`;
export const Span = styled.span`
  width: 4px;
  border-radius: 200px;
  background: ${({ color }) => color || 'inherit'};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const sharedStyle = css`
  border: none;
  padding: ${({ large }) => (large ? '14px' : '4px 4px')};
  display: flex;
  align-items: center;
  font-size: ${({ large }) => (large ? '16px' : '14px')};
  line-height: ${Variables.LineHeightMd};
  color: ${Colors.TextSecondary};
  resize: none;
`;
export const TextBox = styled(TextArea)`
  ${sharedStyle}
`;

export const TaskDetail = styled.div`
  .checkbox {
    position: relative;
    height: fit-content;
    margin: 4px 0px 8px 2px;
  }
  .ant-checkbox {
    margin-top: 4px;
    align-self: flex-start;
  }
  .ant-checkbox-wrapper {
    display: flex;
    justify-content: center;
    border-radius: 3px;
    color: ${Colors.TextSecondary};
    font: ${fonts.small.regular};
    line-height: ${Variables.LineHeightMd};
  }
  .ant-checkbox-disabled + span {
    color: ${Colors.TextSecondary};
    font: ${fonts.small.regular};
    line-height: ${Variables.LineHeightMd};
  }
  .ant-checkbox-inner {
    border-radius: 3px;
    border-width: 0.875px;
  }
  .ant-checkbox-wrapper:hover + .ant-checkbox-inner:hover {
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox-checked::after {
    border-color: transparent;
    border-radius: 3px;
  }
  .ant-checkbox:hover {
    border-color: ${Colors.TextGreen};
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox-input:hover {
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Colors.TextGreen};
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${Colors.TextGreen};
  }
  .ant-checkbox-inner {
    border-color: ${Colors.TextSecondary};
  }
  .ant-checkbox-inner {
    border-color: ${Colors.TextSecondary};
  }
  .text {
    color: ${Colors.TextSecondary};
    font: ${fonts.small.regular};
    line-height: ${Variables.LineHeightMd};
    word-break: break-all;
  }
  .flex-box {
    display: flex;
    align-items: center;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${Colors.CardShadow};
  }
`;
