import React, { useState, useEffect } from 'react';
import _map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import {
  Form,
  Radio,
  Space,
  Checkbox,
  Input,
  Rate,
  Slider,
  Row,
  Col,
} from 'antd';
import { LIKE_TYPE, QUESTION_TYPE } from 'utils/constants';
import { LikeIcon, DislikeIcon } from 'assets';
import { QuestionTitle, LikeDislikeWrapper } from './AntStyled';

const { TextArea } = Input;

export default function FeedbackQuestion({
  questionData,
  questionIndex,
  isSurvey,
  form,
}) {
  const LikeDislike = () => {
    const [selectedValue, setSelectedValue] = useState(null);
    const handleChange = (value) => {
      form.setFieldsValue({ [questionData.id]: value });
      setSelectedValue(value);
    };
    useEffect(() => {
      setSelectedValue(form.getFieldValue(questionData.id));
    });
    return (
      <LikeDislikeWrapper>
        <Space>
          <div
            className={`like ${selectedValue === LIKE_TYPE.LIKE ? 'active' : ''
              }`}
          >
            <img
              src={LikeIcon}
              height={20}
              width={20}
              onClick={() => handleChange(LIKE_TYPE.LIKE)}
            />
          </div>
          <div
            className={`dislike ${selectedValue === LIKE_TYPE.DISLIKE ? 'active' : ''
              }`}
          >
            <img
              src={DislikeIcon}
              height={20}
              width={20}
              onClick={() => handleChange(LIKE_TYPE.DISLIKE)}
            />
          </div>
        </Space>
      </LikeDislikeWrapper>
    );
  };

  const OpinionScale = () => {
    const [selectedValue, setSelectedValue] = useState(0);
    const handleChange = (value) => {
      form.setFieldsValue({ [questionData.id]: value });
      setSelectedValue(value);
    };
    useEffect(() => {
      setSelectedValue(form.getFieldValue(questionData.id));
    });

    return (
      <Row gutter={[16, 16]} className="no-wrap">
        <Col className="flex align-items-center">
          {questionData?.scaleLabel?.minLabel}
        </Col>
        <Col span={12}>
          {' '}
          <Slider
            min={0}
            max={10}
            onChange={handleChange}
            value={selectedValue}
          />
        </Col>
        <Col className="flex align-items-center">
          {questionData?.scaleLabel?.maxLabel}
        </Col>
      </Row>
    );
  };

  return (
    <div className="question">
      <QuestionTitle level={4}>
        {isSurvey && <span>{questionIndex + 1}.</span>} {questionData?.question}
      </QuestionTitle>
      <Form.Item
        name={questionData?.id}
        valuePropName={
          questionData?.questionType === QUESTION_TYPE.MCQ ? 'checked' : 'value'
        }
        rules={[
          questionData?.questionType === QUESTION_TYPE.TEXT
            ? {
              required: true,
              whitespace: true,
              message: 'This field is required',
            }
            : {
              required: true,
              message: 'This field is required',
            },
        ]}
      >
        {questionData?.questionType === QUESTION_TYPE.MCQ ? (
          <Checkbox.Group options={questionData?.choices} />
        ) : questionData?.questionType === QUESTION_TYPE.RATE ? (
          <Rate />
        ) : questionData?.questionType === QUESTION_TYPE.LIKE ? (
          <LikeDislike />
        ) : questionData?.questionType === QUESTION_TYPE.SINGLE_CHOICE ? (
          <Radio.Group>
            <Space direction="vertical">
              {_map(questionData?.choices, choice => (
                <Radio value={choice}>{choice}</Radio>
              ))}
            </Space>
          </Radio.Group>
        ) : questionData?.questionType === QUESTION_TYPE.OPINION_SCALE ? (
          <OpinionScale />
        ) : (
          questionData?.questionType === QUESTION_TYPE.TEXT && (
            <TextArea rows={4} allowClear />
          )
        )}
      </Form.Item>
    </div>
  );
}
