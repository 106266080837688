import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createGlobalStyle } from 'styled-components';
import Spacings from 'design/Spacings';
import Colors from 'design/Colors';
import Variables from 'design/Variables';

export const ConfirmationModalStyle = createGlobalStyle`
 body{
  .ant-confirm-modal-styled .ant-modal-content{
    border-radius: ${Variables.AtomRadius};
    color:red;
    .ant-confirm-modal-ok-button{
      border-color: ${Colors.ButtonPrimary};
      background: ${Colors.ButtonPrimary};
      color: ${Colors.TextLight};
      text-align: center;
      height:auto;
      padding:Calc(${Spacings.xxSmall.padding} - 2px) Calc(${Spacings.xSmall.padding} - 1px);  
      border-radius: ${Variables.AtomRadius};
      cursor:pointer;
      &:hover {
        border-color: ${Colors.ButtonPrimaryHover};
        background: ${Colors.ButtonPrimaryHover};
        color: ${Colors.TextLight};
      }
      &:active {
        border-color: ${Colors.ButtonPrimaryClicked};
        background: ${Colors.ButtonPrimaryClicked};
        color: ${Colors.TextLight};
      }
    }

    .ant-confirm-modal-cancel-button{
      border-color: ${Colors.ButtonSecondary};
      background: ${Colors.ButtonSecondary};
      color: ${Colors.TextDark};
      text-align: center;
      height:auto;
      padding:Calc(${Spacings.xxSmall.padding} - 2px) Calc(${Spacings.xSmall.padding} - 1px);
      border-radius: ${Variables.AtomRadius};
      cursor:pointer;
      &:hover {
        border-color: ${Colors.ButtonSecondaryHover};
        background: ${Colors.ButtonSecondaryHover};
        color: ${Colors.TextDark};
      }
      &:active {
        border-color: ${Colors.ButtonSecondaryClicked};
        background: ${Colors.ButtonSecondaryClicked};
        color: ${Colors.TextLight};
      }
    }
    }
  }
`;

const confirmationModal = (
  content,
  callback,
  setLoading,
  okText = '',
  cancelText = ''
) => {
  Modal.confirm({
    title: 'Are you sure?',
    icon: <ExclamationCircleOutlined />,
    content: (
      <>
        <ConfirmationModalStyle />
        {content}
      </>
    ),
    okText: okText || 'Yes, delete it!',
    cancelText: cancelText || 'No, cancel!',
    okType: 'danger',
    okButtonProps: { className: 'ant-confirm-modal-ok-button' },
    cancelButtonProps: { className: 'ant-confirm-modal-cancel-button' },
    className: 'ant-confirm-modal-styled',
    onOk() {
      callback();
    },
    onCancel() {
      setLoading && setLoading(false);
    },
  });
};
export default confirmationModal;
