/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Popover } from 'antd';
import TodoCard from 'templates/Todos/TodoCard/TodoCard';
import { ArchiveIcon } from 'assets';
import Button from 'atoms/Button';
import { serialize } from 'organisms/TextEditor';
import { useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import ReasonDeletion from 'templates/Todos/ReasonDeletion';
import { FETCH_POLICY } from 'utils/constants';
import client from 'apollo';
import { MARK_AS_READ_ANNOUNCEMENT } from '../graphql/Mutations';
import { Content, StyledBody } from './AntStyled';
import { IS_ANNOUNCEMENT_READ } from '../graphql/Queries';

function AnnouncementTodo({
  todo,
  callback,
  handleArchiveTodo,
  showArchive = true,
}) {
  const [readAnnouncement] = useMutation(MARK_AS_READ_ANNOUNCEMENT);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isRead, setIsRead] = useState(false);

  useEffect(() => {
    if (todo?.todoable?.announcementId) {
      client
        .query({
          query: IS_ANNOUNCEMENT_READ,
          fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
          variables: {
            where: { announcementId: todo?.todoable?.announcementId },
          },
        })
        .then(({ data }) => {
          setIsRead(data);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    setBtnLoading(true);
    readAnnouncement({
      variables: {
        where: {
          announcementId: todo?.todoable?.announcementId,
        },
      },
    })
      .then((res) => {
        callback && callback(todo?.id);
      })
      .catch((e) => {
        Sentry.captureException(e);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  return (
    <Content>
      <TodoCard todo={todo} />

      <StyledBody>
        <div
          dangerouslySetInnerHTML={{ __html: serialize(todo?.todoable?.body) }}
          id="announcement-content"
        />
      </StyledBody>
      <Row align="middle" justify="space-between">
        <Col>
          {showArchive && (
            <Popover
              content={
                <ReasonDeletion
                  deleteTask={(values) => handleArchiveTodo(values, todo?.id)}
                />
              }
              overlayClassName="popover-no-padding"
              trigger="click"
              placement="left"
            >
              <img src={ArchiveIcon} alt="icon" />
            </Popover>
          )}
        </Col>
        <Col>
          <Button
            disabled={isRead?.isAnnouncementRead}
            name={
              isRead?.isAnnouncementRead ? 'Marked as Read' : 'Mark as Read'
            }
            primary
            onClick={() => handleSubmit()}
            loading={btnLoading}
          />
        </Col>
      </Row>
    </Content>
  );
}

export default AnnouncementTodo;
