import React from 'react';
import { QUESTION_TYPE } from 'utils/constants';
import { Checkbox, Input, Radio } from 'antd';
import Card from 'atoms/Card';
import _map from 'lodash/map';
import { Form } from './AntStyled';
const { TextArea } = Input;

export default function generateQuestion(question) {
  const {
    questionType, question: title, id, choices, scaleLabel,
  } = question;
  let node;
  switch (questionType) {
    case QUESTION_TYPE.TEXT:
      node = <TextArea rows={4} allowClear />;
      break;
    case QUESTION_TYPE.OPINION_SCALE:
      node = (
        <Radio.Group>
          <Radio value={0}>{scaleLabel?.minLabel}</Radio>
          <Radio value={1}>{scaleLabel?.maxLabel}</Radio>
        </Radio.Group>
      );
      break;
    case QUESTION_TYPE.SINGLE_CHOICE:
      node = (
        <Radio.Group>
          {_map(choices, choice => (
            <Radio value={choice}>{choice}</Radio>
          ))}
        </Radio.Group>
      );
      break;
    case QUESTION_TYPE.MCQ || QUESTION_TYPE.PICK_MORE:
      node = <Checkbox.Group options={choices} />;
      break;
    default:
      break;
  }
  return (
    <Card padding="16" title={title} className="card" bordered={false}>
      <Form.Item
        name={id}
        rules={[
          questionType === QUESTION_TYPE.TEXT
            ? {
              required: true,
              whitespace: true,
              message: 'This field is required!',
            }
            : {
              required: true,
              message: 'This field is required!',
            },
        ]}
      >
        {node}
      </Form.Item>
    </Card>
  );
}
