import React, { useState, useEffect } from 'react';
import _map from 'lodash/map';
import { Row, Col, Typography, message, Layout } from 'antd';
import * as Sentry from '@sentry/react';
import _get from 'lodash/get';
import Card from 'atoms/Card';
import Button from 'atoms/Button';
import SettingsTemplate from 'atoms/SettingsTemplate';
import {
  PRIVATE_ROUTE,
  FEEDBACK_TYPE,
  AMPLITUDE_EVENTS,
} from 'utils/constants';
import { BackArrow, Trash, TrashSecondary } from 'assets';
import InfoIcon from 'atoms/InfoIcon';
import { FormattedMessage, injectIntl } from 'react-intl';
import GroupTag from 'atoms/GroupTag';
import Header from 'layout/Header';
import { useMutation, useQuery } from '@apollo/client';
import { Prompt, withRouter } from 'react-router-dom';
import { filter, omit } from 'lodash';
import { GET_GROUPS } from 'graphql/Groups/Queries';
import GroupTagList from 'organisms/GroupTagList';
import moment from 'moment';
import client from 'apollo';
import * as eva from 'eva-icons';
import DropdownButton from 'molecules/DropdownButton';
import { confirmationModal } from 'atoms/ConfirmationModal';
import { createAmplitudeEvent } from 'utils/amplitude';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import ContentWrapper from 'atoms/ContentWrapper';
import {
  CREATE_FEEDBACK,
  DELETE_FEEDBACK,
  PUBLISH_FEEDBACK,
  UPDATE_FEEDBACK,
} from '../../../graphql/Mutations';
import EmptyQuestion from './EmptyQuestion';
import ReorderModal from '../ReorderModal';
import {
  ContentTitle,
  Content,
  Form,
  Heading,
  Image,
  StyledImage,
} from './AntStyled';

const { Paragraph } = Typography;

function Questions(props) {
  const { history, intl } = props;
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [isPromptRequired, setIsPromptRequired] = useState(true);
  const [isTemplate, setIsTemplate] = useState(false);
  const [publish, setPublish] = useState(false);
  const [createFeedback] = useMutation(CREATE_FEEDBACK);
  const [updateFeedback] = useMutation(UPDATE_FEEDBACK);
  const [publishFeedback] = useMutation(PUBLISH_FEEDBACK);
  const { data: userGroups } = useQuery(GET_GROUPS);

  const groupsIds = _get(history, 'location.state.values.groups', []);
  const formValues = _get(history, 'location.state.values');
  const id = _get(history, 'location.id.id');
  const question = _get(history, 'location.questions');
  const endOn = _get(history, 'location.endOn');
  const scheduleOn = _get(history, 'location.scheduleOn');
  const Title = () => (
    <>
      <ContentTitle>
        <Image
          src={BackArrow}
          alt="arrow"
          onClick={() =>
            history.push({
              state: { templateData: formValues, fromStep: 'step2' },
              pathname: PRIVATE_ROUTE.SURVEY_CREATE,
            })
          }
        />
        <InfoIcon
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <FormattedMessage
              id={
                id ? 'feedback.new.surveyEditTitle' : 'feedback.new.surveyTitle'
              }
            />
          }
        />
      </ContentTitle>
    </>
  );

  const onFinish = (values) => {
    setIsPromptRequired(false);
    const questionListData = JSON.parse(JSON.stringify(values));
    const questionData = questionListData?.questionList.map((obj) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      omit(obj, ['fieldKey', 'key', 'name', 'id'])
    );

    const datas = questionData.map((obj, index) => ({
      ...obj,
      sequenceNo: index,
    }));

    const data = {
      title: formValues?.title,
      description: formValues?.description,
      type: FEEDBACK_TYPE.SURVEY,
      scheduleOn: moment.utc(
        formValues?.deliveryOption === 'NOW'
          ? new Date()
          : formValues?.deliveryValue
          ? formValues?.deliveryValue
          : scheduleOn
      ),
      endOn: moment.utc(
        formValues?.endTime === 'MANUAL'
          ? null
          : formValues?.endTimeValue
          ? formValues?.endTimeValue
          : endOn
      ),
      isTemplate,
      groups: formValues?.groups,
      resultVisibility: formValues?.resultVisibility,
      questions: datas,
    };
    const mutation = id ? updateFeedback : createFeedback;
    const where = id ? { where: { id } } : {};
    mutation({
      variables: {
        data,
        ...where,
      },
    })
      .then((res) => {
        const publishId =
          mutation === createFeedback
            ? res?.data?.createFeedback?.id
            : res?.data?.updateFeedback?.id;
        if (publish) {
          publishFeedback({
            variables: { where: { id: publishId } },
          });
        }
        const data = id ? res?.data?.updateFeedback : res?.data?.createFeedback;
        const eventProperties = {
          Id: data?.id,
          Title: data?.title,
          Type: data?.type,
          Description: data?.description,
          ScheduleOn: moment
            .utc(data?.scheduleOn)
            .local()
            .format(MomentFormat.dateTime),
          EndOn: moment.utc(data?.endOn).local().format(MomentFormat.dateTime),
          ResultVisibility: data?.resultVisibility,
          createdBy: `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`,
          Email: data?.createdBy?.email,
          UserId: data?.createdBy?.id,
          groups: _map(data?.groups, (data) => ({
            name: data?.name,
            id: data?.id,
            type: data?.type,
          })),
          IsTemplate: data?.isTemplate,
          questions: _map(data?.questions?.questions, (data) => ({
            id: data?.id,
            questionType: data?.questionType,
            question: data?.question,
            choices: data?.choices,
            scaleLabel: data?.scaleLabel,
          })),
        };
        createAmplitudeEvent(
          id
            ? AMPLITUDE_EVENTS.FEEDBACK_UPDATED
            : AMPLITUDE_EVENTS.FEEDBACK_CREATED,
          eventProperties
        );
        history.push(PRIVATE_ROUTE.FEED_BACK_CURRENT);
        message.success(`${id ? 'Updated' : 'Created'} successfully`);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const handleReorder = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const deleteFeedback = () => {
    setIsPromptRequired(false);
    const deleteConfirmation = async () => {
      try {
        await client
          .mutate({
            mutation: DELETE_FEEDBACK,
            variables: {
              id,
            },
          })
          .then(() => {
            const eventProperties = {
              Id: id,
              title: formValues?.title,
              description: formValues?.description,
              type: FEEDBACK_TYPE.SURVEY,
            };
            createAmplitudeEvent(
              AMPLITUDE_EVENTS.FEEDBACK_DELETED,
              eventProperties
            );
            message.destroy();
            message.success(
              intl.formatMessage({
                id: 'users.successMessages.deleteFeedback',
              })
            );
            history.push(PRIVATE_ROUTE.FEED_BACK_DRAFT);
          });
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.log(error);
      }
    };
    confirmationModal(
      intl.formatMessage({ id: 'feedback.sure' }),
      deleteConfirmation
    );
  };

  return (
    <Layout>
      <Header title={<Title />} />
      <ContentWrapper>
        <Prompt
          message="You have unsaved changes! Are you sure you want to leave this page"
          when={isPromptRequired}
        />
        <Content className="top">
          <Card padding={16} className="header">
            <Heading>{formValues?.title}</Heading>
            <Paragraph className="paragraph">
              {formValues?.description}
            </Paragraph>
            <GroupTagList
              departments={filter(userGroups?.groups?.groups, (group) =>
                groupsIds.includes(parseInt(group?.id))
              )}
              limit={5}
            />
          </Card>
        </Content>
        <Content id="drop">
          <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              questionList: question || [{ fieldKey: 0, key: 0, name: 0 }],
            }}
            form={form}
            scrollToFirstError
            onValuesChange={() => setIsPromptRequired(true)}
          >
            <Form.List name="questionList">
              {(fields, { add, remove, move }) => (
                <div>
                  {_map(fields, (field, index) => (
                    <Card
                      padding="16"
                      className="card"
                      title={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Row justify="space-between" align="center">
                          <Col className="flex align-items-center">
                            {`Question ${index + 1}`}
                          </Col>
                          {fields?.length > 1 && (
                            <Col>
                              <span
                                role="button"
                                tabIndex={0}
                                className="reorder"
                                onClick={() => handleReorder(move)}
                              >
                                <Button
                                  secondary
                                  type="primary"
                                  name="Reorder"
                                  className="line-height-16"
                                />
                              </span>
                              <span
                                role="button"
                                tabIndex={0}
                                className="delete"
                                title="Remove question"
                                onClick={() => {
                                  remove(field?.name);
                                }}
                              >
                                <img src={TrashSecondary} alt="icon" />
                              </span>
                            </Col>
                          )}
                        </Row>
                      }
                    >
                      <div className="question-template" key={field?.key}>
                        <SettingsTemplate>
                          <Row>
                            <Col span={24}>
                              <EmptyQuestion name={field?.name} form={form} />
                            </Col>
                          </Row>
                        </SettingsTemplate>
                      </div>
                    </Card>
                  ))}
                  <Card
                    padding="16"
                    className="header add-question card "
                    onCardClickEvent={() => {
                      add();
                    }}
                  >
                    <Row justify="center" align="middle" className="add-new">
                      + Add New Question
                    </Row>
                  </Card>
                  <Row justify="space-between" className="row">
                    <span>
                      <Form.Item>
                        {id && (
                          <Button
                            type="text"
                            name="Delete Survey"
                            className="template-button delete-title"
                            icon={<StyledImage src={Trash} />}
                            onClick={() => {
                              deleteFeedback();
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </Form.Item>
                    </span>
                    <Row justify="center">
                      <Form.Item name="isTemplate" className="right mb-0">
                        <Button
                          htmlType="submit"
                          type="text"
                          className="template-button mark-as-read"
                          name="Save As template"
                          link
                          onClick={() => setIsTemplate(true)}
                        />
                      </Form.Item>
                      <Form.Item>
                        <DropdownButton
                          htmlType="submit"
                          handlePublished={() => {
                            setIsTemplate(false);
                            setPublish(true);
                          }}
                          handleSaveAsDraft={() => {
                            setIsTemplate(false);
                            form.submit();
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Row>
                  <ReorderModal
                    visible={modalVisible}
                    handleCancel={handleCancel}
                    move={move}
                    form={form}
                    setModalVisible={setModalVisible}
                  />
                </div>
              )}
            </Form.List>
          </Form>
        </Content>
      </ContentWrapper>
    </Layout>
  );
}
export default withRouter(injectIntl(Questions));
