import { gql } from '@apollo/client';
import { actionFragment, commentFragment } from './fragments';

export const ACTION_COMMENT = gql`
  query actions($where: ActionWhereInput) {
    actions(where: $where) {
      ...ActionFragment
    }
  }
  ${actionFragment}
`;

export const GET_COMMENTS = gql`
  query getComments($commentableType: String, $commentableId: Int) {
    comments(where: {commentableType: $commentableType, commentableId: $commentableId }){
      ...CommentFragment
    }
  }
  ${commentFragment}
`;
