const Variables = {
  AtomRadius: '8px',
  RadiusXs: '4px',
  RadiusMd: '10px',
  RadiusL: '12px',
  RadiusXl: '16px',
  RadiusXxl: '20px',
  LineHeightXs: '16px',
  LineHeightSm: '20px',
  LineHeightMd: '22px',
  LineHeightL: '24px',
  LineHeightXl: '28px',
  LineHeightXxl: '32px',
};
export default Variables;
