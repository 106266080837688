import styled from 'styled-components';
import {
  Row as AntRow,
  Layout,
  Select as AntSelect,
  Modal as AntModal,
  Form as AntForm,
  Input,
} from 'antd';
import { fonts } from 'design/Fonts';
import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
import { x } from 'design/Styled';
const { TextArea } = Input;

const { Header: AntHeader } = Layout;
export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
`;
export const Modal = styled(AntModal)`
  .ant-modal-body {
    padding: 20px 20px 0px;
  }
  .ant-modal-header {
    padding: 20px;
    border-bottom: 1px solid ${Colors.Border};
  }
  .ant-modal-footer {
    padding: 0px 20px 20px;
    border-top: 0px;
  }

  .ant-modal-title {
    font: ${fonts.xLarge.bold};
    line-height: 24px;
    color: ${Colors.TextDark};
  }

  .ant-modal-content {
    max-height: calc(100vh - 120px);
    overflow: auto;
    border-radius: 12px;
    border: 1px solid ${Colors.Border};
  }
`;
export const TextBox = styled(TextArea)`
  border: 1px solid ${Colors.Border};
  border-radius: 10px;
`;
const Select = styled(AntSelect)`
  font: ${fonts.medium.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 10px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 14px;
    border: none;
    height: 30px;
    background-color: transparent;
  }
  .ant-select-selection-placeholder {
    opacity: 1;
  }
  .ant-select-item {
    padding:0px;
    line-height: 13px !important;
    
  }
  .ant-select-item-option {
    border-bottom: 1px solid red !important;
  }
`;
export const Header = styled(AntHeader)`
  ${x};
  background: ${Colors.Container};
  padding: 0 24px;
  border-bottom: 1px solid ${Colors.BorderLight};
  height: 48px;
  & .ant-divider {
    background: ${Colors.Divider};
    height: 1.3em;
  }
  & .ant-form-item{
    margin-bottom:0;
    line-height:inherit;
  }
  /* & .universal-filter {
    display:flex;
    & .ant-select:first-child{
      margin-right:${Spacings.xSmall.margin};
    }
    & .user-filter .ant-select-selector{
      background-color: transparent;
      border: 1px solid ${Colors.SecondaryColor};
      &:hover, &:active, &:focus {
      border-color: ${Colors.BorderHover};
      }
    }
    & .ant-select-clear{
      background:rgb(240 242 245);
    }
  } */
  @media screen and (min-width: 1100px) {
    padding: 0 24px;
  }
}
`;

export const Row = styled(AntRow)`
  width: 100%;
  flex-wrap: nowrap;
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .ant-btn {
    margin-left: ${Spacings.xSmall.margin};
  }
  .add-button {
    margin-left: ${Spacings.xSmall.margin};
    font: ${fonts.xSmall.normal};
    height: 34px;
  }
  .plus-icon {
    margin-right: ${Spacings.xxSmall.margin};
    line-height: 22px;
  }
`;
