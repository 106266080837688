import styled from 'styled-components';
import { Form as AntForm } from 'antd';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { link } from 'design/Styled';

export const Content = styled.div``;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font: ${fonts.large.medium};
  color: ${Colors.TextDark};
  margin-bottom: ${Spacings.xxSmall.margin};

  div:last-child {
    ${link};
  }
`;

export const SunTitle = styled.div`
  font: ${fonts.xxSmall.regular};
  color: ${Colors.TextEnable};
  margin-bottom: ${Spacings.small.margin};
`;

export const Form = styled(AntForm)`
  .ant-form-item-label{
    padding: 0;
    color: ${Colors.TextDark};
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }

  .ant-form-item {
    margin-bottom: ${Spacings.xSmall.margin};
  }

  .button {
    margin-top: ${Spacings.medium.margin};
  }

  label {
    font: ${fonts.xSmall.regular};
    color: ${Colors.TextDark};
  }
`;
