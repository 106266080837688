import React, { useContext } from 'react';
import { message } from 'antd';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { AMPLITUDE_EVENTS, BUTTON_TYPE } from 'utils/constants';
import { useMutation } from '@apollo/client';
import { AppContext } from 'AppContext';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import { TOGGLE_FEATURE } from 'graphql/Settings/Features/Mutations';
import ability from 'utils/ability/ability';
import _get from 'lodash/get';
import { unpackRules } from '@casl/ability/extra';
import { confirmationModal } from 'atoms/ConfirmationModal';
import { createAmplitudeEvent } from 'utils/amplitude';
import { Description, Content } from './AntStyled';
import ContentWrapper from 'atoms/ContentWrapper';

const Activation = ({ setActivated, featureKey, intl }) => {
  const { state } = useContext(AppContext);
  const { loginUser } = state;
  const [toggleFeature, { loading }] = useMutation(TOGGLE_FEATURE, {
    variables: {
      where: {
        key: featureKey,
        state: 'deactivate',
      },
    },
    onCompleted: (res) => {
      const rules = _get(state, 'loginUser.groupRules', []);
      ability.update(unpackRules([...rules, ['use', featureKey]]));
      const eventProperties = {
        'Feature key': res?.setFeature?.key,
        DeactivatedBy: `${loginUser?.firstName} ${loginUser?.lastName}`,
        'User id': loginUser?.id,
        Email: loginUser?.email,
      };
      createAmplitudeEvent(
        AMPLITUDE_EVENTS.FEATURE_DEACTIVATED,
        eventProperties
      );
    },
  });

  const handleDeactivate = () => {
    const deactivateConfirmation = () => {
      toggleFeature().then(() => {
        setActivated(false);
        message.success('Feature deactivated successfully!');
      });
    };
    confirmationModal(
      intl.formatMessage({ id: 'features.deactivateConfirmText' }),
      deactivateConfirmation,
      null,
      'Yes'
    );
  };

  return (
    <Content>
      <Card padding="16" className="card">
        <Description>
          Deactivating will disable this feature from this workspace.
          <br />
          All users will lose access to this feature.
          <br />
          However you can reactivate this feature again.
        </Description>
        <Button
          primary
          loading={loading}
          type={BUTTON_TYPE.PRIMARY}
          onClick={handleDeactivate}
          name="Deactivate"
        />
      </Card>
    </Content>
  );
};
export default withRouter(injectIntl(Activation));
