import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';
import Spacings from 'design/Spacings';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';

const ColorsBox = styled.div`
  width: ${({ width }) => width || 8}px;
  min-width: 8px;
  height: ${({ height }) => height || 6}px;
  background: ${({ color }) => color || Colors.ButtonPrimary};
  margin-right: ${({ right }) => right || Spacings.xxSmall.margin};
  border-radius: 8px;
`;

const StyledTag = styled(Tag)`
  display: inline-flex;
  align-items: baseline;
  cursor: inherit;
  border: none;
  color: ${({ color }) => color || Colors.ButtonPrimary};
  font: ${fonts.small.regular};
  line-height: 22px;
  background-color: transparent !important; //because antd is applying inline style.
`;

const Status = ({ color, name }) => (
  <StyledTag color={color} name={name}>
    <ColorsBox color={color} width={8} height={8} right="4px" />
    <span color={color}>{name || 'unnamed'}</span>
  </StyledTag>
);

export default Status;
