import { gql } from '@apollo/client';
import { checkInSettingFragment } from './Fragments';

export const UPDATE_CHECK_IN_SETTING = gql`
  mutation updateCheckInSetting($data: CheckInSettingUpdateInput, $where: CheckInSettingWhereUniqueInput!) {
    updateCheckInSetting(data: $data, where: $where) {
      ...CheckInSettingFragment
    }
  }
  ${checkInSettingFragment}
`;
