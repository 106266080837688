import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import { message } from 'antd';
import { CommentsIcon } from 'assets';
import client from 'apollo';
import 'emoji-mart/css/emoji-mart.css';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import CommentsTimeLine from 'molecules/CommentsTimeLine';
import Popover from 'features/people/pages/ProfileTabs/LeavesProfile/Popover';
import { EMPTY_ARRAY, FETCH_POLICY, POPOVER_TYPE } from 'utils/constants';
import { injectIntl } from 'react-intl';
import * as Sentry from '@sentry/react';
import { GET_COMMENTS } from './graphql/queries';
import {
  CREATE_ACTION_COMMENT,
  DELETE_ACTION_COMMENT,
  UPDATE_ACTION_COMMENT,
} from './graphql/mutations';
import {
  StyledPopover, Wrapper, IconWrapper, SmallPopover,
} from './AntStyled';
const Comments = styled.img`

`;
const CommentsPopover = (props) => {
  const {
    actionId, data, intl, groupId, simple, commentableType = 'Action', isSection,
  } = props;
  const { state: { loginUser: { id } } } = useContext(AppContext);
  const [comments, setComments] = useState(EMPTY_ARRAY);
  const [loading, setLoading] = useState(false);

  const getComments = useCallback(() => {
    if (actionId) {
      return client.query({
        query: GET_COMMENTS,
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
        variables: {
          commentableType, commentableId: parseInt(actionId, 10),
        },
      }).then(({ data: commentsData }) => {
        if (commentsData.comments) {
          setComments(commentsData.comments || EMPTY_ARRAY);
        }
      }).catch((e) => {
        Sentry.captureException(e);
        // eslint-disable-next-line no-console
        console.log(e);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId]);

  useEffect(() => {
    if (data) {
      setComments(data);
    } else {
      getComments();
    }
  }, [data, actionId, getComments]);

  const createActionComment = async (content) => {
    setLoading(true);
    return client.mutate({
      mutation: CREATE_ACTION_COMMENT,
      variables: {
        data: {
          content: content.trim(),
          commentableId: parseInt(actionId, 10),
          commentableType,
        },
      },
    }).then(() => {
      getComments().then(() => {
        setLoading(false);
      });
    }).catch((error) => {
      message.destroy();
      message.error(intl.formatMessage({ id: 'error.somethingWentWrong' }));
      Sentry.captureException(error);
      console.log(error);
      setLoading(false);
    });
  };

  const updateActionComment = async (content, id) => {
    setLoading(true);
    return client.mutate({
      mutation: UPDATE_ACTION_COMMENT,
      variables: {
        data: {
          content: content.trim(),
        },
        where: {
          id,
        },
      },
    }).then(() => {
      getComments().then(() => {
        setLoading(false);
      });
    }).catch((error) => {
      message.destroy();
      message.error(intl.formatMessage({ id: 'error.somethingWentWrong' }));
      Sentry.captureException(error);
      console.log(error);
      setLoading(false);
    });
  };

  const deleteActionComment = async (id) => {
    setLoading(true);
    await client.mutate({
      mutation: DELETE_ACTION_COMMENT,
      variables: {
        where: {
          id,
        },
      },
    }).then(() => {
      getComments().then(() => {
        setLoading(false);
      });
    }).catch((error) => {
      message.destroy();
      message.error(intl.formatMessage({ id: 'error.somethingWentWrong' }));
      Sentry.captureException(error);
      console.log(error);
      setLoading(false);
    });
  };
  const reasons = simple && [data];

  if (isSection) {
    return (
      <CommentsTimeLine
        actionId={actionId}
        comments={comments}
        groupId={groupId}
        createActionComment={createActionComment}
        updateActionComment={updateActionComment}
        deleteActionComment={deleteActionComment}
        loading={loading}
        currentUserId={id}
        isSection
      />
    );
  }
  return (
    <Wrapper>
      {simple ? (
        <SmallPopover
          overlayStyle={{ minWidth: 370 }}
          content={(
            <Popover reason={data} />
          )}
          placement={POPOVER_TYPE.BOTTOM_RIGHT}
          trigger={POPOVER_TYPE.CLICK}
        >
          <IconWrapper>
            <Comments src={CommentsIcon} alt=""></Comments><span className="count">{reasons.length}</span>
          </IconWrapper>
        </SmallPopover>
      ) : (
        <StyledPopover
          overlayStyle={{ minWidth: 370 }}
          content={(
            <CommentsTimeLine
              actionId={actionId}
              comments={comments}
              groupId={groupId}
              createActionComment={createActionComment}
              updateActionComment={updateActionComment}
              deleteActionComment={deleteActionComment}
              loading={loading}
              currentUserId={id}
            />
          )}
          placement={POPOVER_TYPE.LEFT}
          trigger={POPOVER_TYPE.CLICK}
        >
          <IconWrapper>
            <Comments src={CommentsIcon} alt=""></Comments><span className="count">{comments.length}</span>
          </IconWrapper>
        </StyledPopover>
      )}

    </Wrapper>
  );
};

export default injectIntl(CommentsPopover);
