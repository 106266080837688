import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Done } from 'assets';
import Button from 'atoms/Button';
import client from 'apollo';
import {
  BUTTON_TYPE, PRIVATE_ROUTE,
} from 'utils/constants';
import { COMPLETE_COMPANY_STEP } from 'graphql/Onboarding/Mutations';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  Content,
  Image,
  ImageWrapper,
  Title,
  SubTitle,
} from './AntStyled';

const GetStarted = ({ history, userCreatedCompanyData }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Content>
      <Title><FormattedMessage id="onboarding.allSet" /></Title>
      <ImageWrapper><Image src={Done} /></ImageWrapper>
      <SubTitle>
        <div><FormattedMessage id="onboarding.together" /></div>
        <Button
          loading={loading}
          type={BUTTON_TYPE.PRIMARY}
          onClick={() => {
            setLoading(true);
            client.mutate({ mutation: COMPLETE_COMPANY_STEP, variables: { where: { id: userCreatedCompanyData.companies[0].id } } })
              .then(() => {
                setLoading(true);
                history.push(PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.GROUPS);
              }).catch((error) => {
                Sentry.captureException(error);
                setLoading(false);
              });
          }}
          name={<FormattedMessage id="onboarding.getStarted" />}
        />
      </SubTitle>
    </Content>
  );
};

export default withRouter(GetStarted);
