import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import _findKey from 'lodash/findKey';
import { FormattedMessage } from 'react-intl';
import Avatar from 'atoms/Avatar';
import ability, { verifyAbility } from 'utils/ability/ability';
import { PRIVATE_ROUTE, PUBLIC_ROUTE, AVATAR_TYPE } from 'utils/constants';
import { AppContext } from 'AppContext';
import {
  DropDownContent,
  Menu,
  NameWrapper,
  CompanyWrapper,
  ProfileWrapper,
  MenuWrapper,
} from './AntStyled';

export default function ({ company, userName }) {
  const { state } = useContext(AppContext);
  function getAbilityAction(value) {
    const key = _findKey(ability.rules, ['action.0', value]); // Return the index if value exists in ability(ex:- value = manage)
    if (key !== undefined) {
      return value; // if index found then return the value i.e 'manage' which means ability is true
    }
  }

  function getAbilitySubject(value) {
    const key = _findKey(ability.rules, ['subject.0', value]); // Return the index if value exists in ability(ex:- value = me)
    if (key !== undefined) {
      return value; // if index found then return the value i.e 'me' which means ability is true
    }
  }
  return (
    <DropDownContent>
      {/* <div className="user">
        <UserProfile
          profile={state.loginUser.profileImage}
          name={state.loginUser.firstName && state.loginUser.lastName ? `${state.loginUser.firstName} ${state.loginUser.lastName}` : state.loginUser.email}
          size={25}
        />
      </div> */}
      <ProfileWrapper>
        <NameWrapper>
          {userName}
        </NameWrapper>
        <CompanyWrapper>
          <Avatar
            shape={AVATAR_TYPE.SQUARE}
            profile={company.logo}
            name={company.name}
            size={19}
          />
          {company && company.name}
        </CompanyWrapper>
      </ProfileWrapper>
      <MenuWrapper>
        {/* Commented for future use */}
        {/* <Menu>
          <NavLink to={{ pathname: PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.MY_PROFILE_DETAIL }}><FormattedMessage id="mainSidebar.myProfile" /></NavLink>
        </Menu> */}
        <Menu>
          <NavLink to={PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.MY_PROFILE}><FormattedMessage id="mainSidebar.profileSettings" /></NavLink>
        </Menu>

        {/* {
          verifyAbility(getAbilityAction('manage'), getAbilitySubject('general setting') || getAbilitySubject('users'))
        && (
          <>
            <Divider />
          </>
        )
        } */}
        {
          verifyAbility(getAbilityAction('manage'), getAbilitySubject('general setting'))
          && (
            <Menu>
              <NavLink to={PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.GENERAL_SETTINGS}>
                <FormattedMessage id="mainSidebar.workspaceSettings" />
              </NavLink>
            </Menu>
          )
        }
        {
          verifyAbility(getAbilityAction('manage'), getAbilitySubject('users'))
          && (
            <Menu>
              <NavLink to={PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.ADD_USER}>
                <FormattedMessage id="mainSidebar.inviteTeammates" />
              </NavLink>
            </Menu>
          )
        }
        <Menu className="logout">
          <NavLink to={PUBLIC_ROUTE.LOGOUT}>
            <FormattedMessage id="mainSidebar.signOut" />
          </NavLink>
        </Menu>
        {/* <Menu>
        <NavLink to={PRIVATE_ROUTE.ME}>
          <BranchesOutlined /><FormattedMessage id="mainSidebar.integrations" />
        </NavLink>
      </Menu> */}
      </MenuWrapper>
    </DropDownContent>
  );
}
