import styled from 'styled-components';

import { x } from 'design/Styled';

export const ImageWrapper = styled.div`
  ${x};
  height: 100%;
  justify-content: center;
  padding: 1em;
  padding-top: 12em;
  padding-bottom: 12em;
`;

export const Image = styled.img`
  height: 20em;
`;
