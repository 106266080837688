import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      firstName
      lastName
      email
      role
      isActive
      isInvited
      isDisabled
      hasRequested
      profileImage
      rules
      groupRules
      authenticationType
      title
      timeZone
      hasSetPassword
      requestRejected
      lastLoggedIn
      department {
        id
        name
      }
      dateOfBirth
      userLocation
      contact
      location {
        id
        name
        shortName
        timeZone
        yearStart
      }
      company {
        id
        name
        subDomain
        logo
        featureKeys
        completedSteps
        nextStep
        workSpace
        isTourCompleted
      }
    }
  }
`;
