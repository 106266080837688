import styled from 'styled-components';
import { Drawer as AntDrawer } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { scrollbars, x } from 'design/Styled';

export const Drawer = styled(AntDrawer)`
  .ant-drawer-body {
    overflow: hidden;
    padding: 0;
    background: ${Colors.Container};
    font: ${fonts.small.regular};
  }
  .scroll {
    height: calc(100vh - 96px);
    overflow: auto;
    ${scrollbars};
    @media (max-width: 850px) {
      height: calc(100vh - 160px);
    }
  }
  .ant-drawer-close {
    padding: 16px 10px;
  }
`;
export const SpinWrapper = styled.div`
  padding: ${Spacings.small.padding};
  display: flex;
  justify-content: center;
`;
export const Image = styled.img`
  width: 64px;
  height: 64px;
  align-self: center;
`;
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const ButtonWrapper = styled.div`
  .ant-btn {
    border-radius: 15.5px;
  }
  margin-top: 16px;
  width: 175px;
  height: 30px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15.5px;
  background-color: ${Colors.IconColor};
  &.ant-btn:active {
    box-shadow: 0px 4px 10px rgb(81 89 131 / 30%) !important;
  }
  &.ant-btn:not([disabled]):active {
    box-shadow: 0px 4px 10px rgb(81 89 131 / 30%) !important;
  }
`;
export const ContentTitle = styled.div`
  ${x};
  color: ${Colors.Text};
  .ant-btn > .anticon {
    line-height: 0;
  }
  .anticon-setting {
    color: ${Colors.ButtonPrimary};
  }

  .setting-icon {
    margin-right: ${Spacings.xxSmall.margin};
  }
  .navigation {
    ${x};
  }
  .title {
    ${x};
    font: ${fonts.medium.bold};
    color: ${Colors.TextPrimary};
    justify-content: space-between;
    line-height: 20px;
  }

  .button {
    ${x};
    justify-content: flex-end;
  }

  .anticon-question-circle {
    margin-right: ${Spacings.xSmall.margin};
  }

  .buttons {
    height: 28px;
    padding: 0px 8px;
  }

  .new,
  .cleared {
    width: 77px;
    height: 25px;
    border-radius: 12.5px;
    font: ${fonts.xxSmall.regular};
    padding: 0;
  }
  .clear-btn.ant-btn {
    padding: 1px 8px !important;
    border-radius: 4px;
    border: none;
    font-size: 12px;
    margin-right: 42px;
    &:hover {
      border: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  ${scrollbars};
  .ant-btn > .anticon {
    line-height: 0;
  }
  .ant-layout-header {
    height: 48px;
  }
`;

export const LoaderWrapper = styled.div``;

export const NoNotificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-self: center;
  margin-top: ${Spacings.small.margin};
  font: ${fonts.medium.medium};
  color: ${Colors.TextSecondary};
`;
