import {
  Layout,
  Row as AntRow,
  Select as AntSelect,
  Form as AntForm,
  TimePicker as Antpicker,
} from 'antd';
import styled from 'styled-components';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { scrollbars, x } from 'design/Styled';
import { RangeArrow } from 'assets';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  &.ant-layout-content {
    padding: ${Spacings.medium.padding};
    overflow: auto;
    height: calc(100vh - 99px - 64px - 30px);
    ${scrollbars};
  }
  &.half {
    border-radius: 0px 10px 10px 0px;
  }
  .ant-btn-primary {
    font: ${fonts.large.medium};
    line-height: 22px;
  }
`;
export const Form = styled(AntForm)`
 .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
  .ant-form-item-extra {
    font: ${fonts.xSmall.regular};
    color: ${Colors.TextEnable};
    margin-top: 4px;
  }

  .file-cursor {
    cursor: pointer;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }
  .ant-select-multiple .ant-select-selector {
    border: none;
  }
`;
export const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 2px;
  margin-top: -4px;
`;
export const Image = styled.img`
  width: 18px;
`;
export const Delete = styled.img`
  width: 18px;
  height: 18px;
  margin-top: 10px;
`;

export const Select = styled(AntSelect)`
  font: ${fonts.xSmall.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 12px;
    font: ${fonts.medium.regular};
  }
`;
export const ContentTitle = styled.div`
  ${x};
  justify-content: space-between;

  .attendance {
    height: 28px;
    padding: 0px ${Spacings.xxSmall.padding};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .leave {
    height: 28px;
    padding: 0px ${Spacings.xxSmall.padding};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const WorkDayContent = styled(AntRow)`
  .content-heading{
    font: ${fonts.small.regular};
    color: ${Colors.TextEnable};
    padding: 24px 0 12px 0;
  }
  .ant-btn{
      font : ${fonts.small.regular};
      line-height:22px;
  }
  .col{
    align-items:center;
    justify-content:center;
    padding:6px 12px 6px 12px;
    color: ${Colors.TableTd};
    background-color:${Colors.ternaryColor};
    border-radius: 6px;
    margin: calc(${Spacings.small.margin}) 0;
  }
  .ant-radio-wrapper{
    color: ${Colors.Radio};
  }
  .ant-radio-wrapper-checked{
    color: ${Colors.PrimaryColor};
  }
  .info {
    color: ${Colors.Note};
    line-height:22px;
    font: ${fonts.small.regular};
  }
  .content-title {
    font: ${fonts.small.regular};
    color: ${Colors.Text};
  }

  .halfday .label{
    margin-bottom: ${Spacings.xxSmall.margin};
    height: 32px;
  }

  table {
    border-collapse: separate;
    border-spacing: 10px 10px;
  }

  td{
    padding: calc(${Spacings.xSmall.padding} + 2px);
    border: 1px solid ${Colors.Border};
    border-radius:6px;
    cursor: pointer;
  }

  th{
    text-align:center;
    color:${Colors.PrimaryColor};
  }

  td.full-day-leave{
    background:${Colors.Border};
  }

  td.first-half-leave{
    background:${`linear-gradient(${Colors.Border} 0 50%, transparent 50% 100%)`};
  }

  td.second-half-leave{
    background:${`linear-gradient(transparent 0 50%, ${Colors.Border} 50% 100%)`};
  }
  .ant-radio-wrapper{
    margin-right: 35px;
  }
  .anticon-info-circle {
    font-size: 20px;
    margin-right:13px;
    color:${Colors.Note};
  }
  .ant-form-item-label > label{
    height:0px;
  }
`;

export const WorkTimeContent = styled(AntRow)`
  .ant-btn-primary{
    font:${fonts.small.regular};
    line-height:22px;
  }
  .content-heading {
    font-size: 14px;
    color: #515983;
    padding-bottom: 8px;
  }
  .space {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-spin-nested-loading {
    width: 100%;
  }
  .ant-radio-wrapper {
    color: ${Colors.Radio};
  }
  .ant-radio-wrapper-checked {
    color: ${Colors.PrimaryColor};
  }
  .drop-down {
    border-radius: 0px 10px 10px 0px;
    border-left: 0px;
    color: ${Colors.TextSecondary};
    :hover{
      border-left: 1px solid ${Colors.BorderHover};
    }
  }
  .anticon-swap-right {
    background-image: url(${RangeArrow});
    background-repeat: no-repeat;
    background-size: 100%;
    &.anticon {
      svg {
        path {
          fill: transparent;
        }
      }
    }
  }
  .content-title {
    font: ${fonts.small.regular};
    color: ${Colors.Text};
  }
  .col-icon {
    margin-right: 3px;
  }
  .ant-radio-wrapper {
    margin-right: 35px;
  }
  label {
    color: ${Colors.Text};
  }
  .ant-picker-range {
    border-radius: 10px 0px 0px 10px;
    padding: 5.2px;
    width: 100%;
    border-color: ${Colors.Border};
    &:hover {
      border: 1px solid ${Colors.BorderHover};
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  .range {
    border-radius: 10px;
    margin-bottom: 12px;
  }
  .ant-picker-input > input {
    font: ${fonts.small.regular};
    color: ${Colors.TextSecondary};
  }
  .ant-picker-input > input:placeholder-shown {
    font: ${fonts.medium.regular};
    color: ${Colors.TextSecondary};
  }
  .extra-field-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .deleteicon {
    align-self: center;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .step-inside {
    font: ${fonts.small.regular};
    color: ${Colors.TextEnable};
    padding: 30px 0 30px 0;
  }

  .ant-btn-dashed {
    margin-top: ${Spacings.xxSmall.margin};
  }
  .ant-select-selection-placeholder {
    opacity: 1;
  }
  .ant-picker-range-separator {
    padding: 0 20px;
  }
`;

export const Title = styled.div`
  color: ${Colors.TextDark};
  font: ${fonts.medium.medium};
  line-height: 19px;
  margin: 30px 0 10px 0;
  &:first-child {
    margin: 0px 0 10px 0;
  }
`;
