/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import GroupTag from 'atoms/GroupTag';
import { Select as AntSelect, Tag } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import UserProfile from 'molecules/UserProfile';
import { CloseCircle, SearchIcon } from 'assets';
import { useQuery } from '@apollo/client';
import { GET_GROUPS, GET_USERS } from 'graphql/Settings/Users/Queries';
import {
  EMPTY_ARRAY,
  EMPTY_STRING,
  FETCH_POLICY,
  PRIVATE_ROUTE,
} from 'utils/constants';
import { flattenDeep, isEqual, map, reverse, split, trim } from 'lodash';
import { AppContext } from 'AppContext';
import { useHistory } from 'react-router-dom';

const Select = styled(AntSelect)`
  font-size: 14px;
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  .ant-select-selector {
    height: 32px;
  }
  .ant-select-selection-placeholder {
    font: ${fonts.xSmall.regular};
  }
  &.ant-select {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    background: white;
    min-width: 344px;
    width: 100%;
    max-width: 1000px;
    display: table-cell;
    word-wrap: break-word;
    word-break: break-word;
    & .ant-select-selector {
      padding-right: 20px;
      padding-left: 8px;
      border: none;
    }
    @media (max-width: 840px) {
      min-width: 244px;
    }
  }
  &.ant-select-multiple .ant-select-selector {
    border: none;
    width: 100%;
    background-color: transparent;
  }
  &.ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 14px;
    left: 14px;
    font-size: 14px;
  }
  .ant-select-item-group {
    display: none !important;
  }
  .ant-select-item-option-content {
    margin: 8px 8px;
  }
  .ant-select-item {
    border-bottom: 1px solid #eaeff4;
    margin: 0px 8px;
  }
  &.ant-select-multiple
    .ant-select-selection-search:first-child
    .ant-select-selection-search-input {
    margin-left: 3px;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }
  /*need to use !important as antd uses inline CSS */
  .ant-select-selection-search {
    width: unset !important;
  }
  &.ant-select-multiple
    .ant-select-selection-search:first-child
    .ant-select-selection-search-input {
    @media (max-width: 850px) {
      margin-left: 0px;
    }
  }
`;
const Search = styled.img`
  position: absolute;
  top: 50%;
  right: 2px;
  margin-top: -7px;
`;
const StyledTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  border-radius: 14.5px;
  cursor: inherit;
  background-color: ${Colors.SidebarText};
  border: 1px solid ${Colors.SecondaryColor};
  color: ${Colors.TextSecondary};
  font: ${fonts.xSmall.regular};
  padding: 4px 6px;
  .close {
    margin-left: 5px;
    width: 12px;
    cursor: pointer;
  }
  max-width: 100%;
  .group-value {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px;
  }
  .extra-tag {
    margin-left: 5px;
    margin-right: 20px;
  }
`;
const { Option, OptGroup } = Select;

function SearchAnything({ onSearch }) {
  const { state } = useContext(AppContext);
  const history = useHistory();
  const [selected, setSelected] = useState(EMPTY_ARRAY);
  const [searchText, setSearchText] = useState(EMPTY_STRING);
  const { data: userGroups, loading: groupsLoading } = useQuery(GET_GROUPS, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const { data: userData, loading: userLoading } = useQuery(GET_USERS, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  useEffect(() => {
    if (groupsLoading || userLoading) {
      setSelected(EMPTY_ARRAY);
    } else if (history?.location?.pathname === PRIVATE_ROUTE.TEAMS) {
      setSelected(
        flattenDeep(map(Object.values(state?.searchData), (val) => val))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsLoading, userLoading]);
  const [isDesktop, setDesktop] = useState(window.innerWidth);
  const updateMedia = () => {
    setDesktop(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  const tagRender = (prop) => {
    const {
      label: tagLabel,
      closable,
      onClose,
      name,
      closeable = false,
      closeOnClick,
      item,
      value,
    } = prop;
    const data = trim(value);
    const type = value?.slice(0, 2);
    return closable ? (
      <>
        {type === 'G-' && (
          <GroupTag
            closeable={closable}
            closeOnClick={onClose}
            color={tagLabel?.props?.color}
            name={tagLabel?.props?.name}
            type={tagLabel?.props?.type}
          />
        )}
        {type === 'U-' && (
          <UserProfile
            bordered
            Background
            profile={tagLabel?.props?.profile}
            name={tagLabel?.props?.name}
            closable={closable}
            onIconClick={onClose}
            stackedName={false}
            icon
          />
        )}
        {type !== 'U-' && type !== 'G-' && data?.length > 0 && (
          <StyledTag>
            <span title={value} className="group-value">
              {value || 'unnamed'}
            </span>
            <span onClick={() => onClose()}>
              <img className="close" src={CloseCircle} alt="close" />
            </span>
          </StyledTag>
        )}
      </>
    ) : (
      <span className="extra-tag">
        {`${split(tagLabel, ' ', 2).join(' ')} More`}
      </span>
    );
  };

  const handleChange = (data) => {
    setSearchText(EMPTY_STRING);
    const newData = map(reverse(data), (val) => trim(val)).filter((val) =>
      Boolean(val)
    );
    const value = newData?.length > 0 ? newData : [];
    setSelected(newData?.length > 0 ? newData : []);
    if (!isEqual(selected, value)) {
      const group = _filter(value, (val) => val?.includes('G-'));
      const user = _filter(value, (val) => val?.includes('U-'));
      const text = _filter(
        value,
        (val) => !(val?.includes('G-') || val?.includes('U-'))
      );
      onSearch({ group, user, text });
    }
  };

  return (
    <Select
      placeholder="Search"
      getPopupContainer={(trigger) => trigger?.parentNode}
      mode="tags"
      maxTagCount={isDesktop > 1350 ? 3 : isDesktop > 1180 ? 2 : 1}
      suffixIcon={<Search src={SearchIcon} alt="search-icon" />}
      showSearch
      tagRender={tagRender}
      showArrow
      onChange={handleChange}
      dropdownClassName={searchText?.length < 2 && 'd-none'}
      onSearch={(e) => setSearchText(e)}
      value={selected}
      notFoundContent={null}
      loading={userLoading || groupsLoading}
      filterOption={(input, option) => {
        const user = _get(option, 'children.props', '');
        return user?.name?.toLowerCase().includes(input?.toLowerCase());
      }}
      defaultActiveFirstOption={false}
    >
      <OptGroup key="groups">
        {_map(userGroups?.groups?.groups, (items) => (
          <Option key={`G-${items?.id}`} value={`G-${items?.id}`}>
            <GroupTag name={items?.name} color={items?.color} />
          </Option>
        ))}
      </OptGroup>
      <OptGroup key="users">
        {_map(userData?.users?.users, (items) => (
          <Option
            key={`U-${parseInt(items?.id, 10)}`}
            value={`U-${parseInt(items?.id, 10)}`}
          >
            <UserProfile
              stackedName={false}
              profile={items?.profileImage}
              name={
                items?.firstName && items?.lastName
                  ? `${items?.firstName} ${items?.lastName}`
                  : items?.email
              }
            />
          </Option>
        ))}
      </OptGroup>
    </Select>
  );
}

export default SearchAnything;
