import { Col, Divider, Row, Space } from 'antd';
import Button from 'atoms/Button';
import DateTime from 'atoms/DateTime';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import Progress from 'atoms/Progress';
import { capitalize } from 'lodash';
import UserProfile from 'molecules/UserProfile';
import moment from 'moment';
import GroupTagList from 'organisms/GroupTagList';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  FEEDBACK_TYPE,
  PRIVATE_ROUTE,
  TODO_ICONS,
  TODO_TYPE,
  UPDATES_TYPE,
} from 'utils/constants';

function FeedbackResultWorkUpdate({
  data,
  history,
  isViewed,
  createdAt,
  updatedAt,
}) {
  const {
    data: {
      feedback: { title: feedbackTitle, type: feedbackType, id: feedbackId },
      participation,
      participants,
      responded,
    },
    groups,
    title,
    createdBy: user,
    type,
  } = data;
  return (
    <div>
      <div className="update-template-header">
        <Row className="title-row" align="middle">
          <Col>
            {
              TODO_ICONS[
                type === UPDATES_TYPE.FEEDBACK_REQUEST ||
                type === UPDATES_TYPE.FEEDBACK_RESULT
                  ? feedbackType
                  : feedbackType
              ]
            }
          </Col>
          <Col className="title">{title}</Col>
        </Row>
        {user && (
          <>
            <UserProfile
              name={`${user?.firstName} ${user?.lastName}`}
              stackedName={false}
              userId={user?.id}
              size={16}
              profile={user?.profileImage}
              showLink
            />
            <div className="dot" />
          </>
        )}
        <div className="time">
          <DateTime date={isViewed ? updatedAt : createdAt} fromNow />
        </div>
      </div>
      <div className="update-template-body">
        <div className="title">{feedbackTitle}</div>
        <div className="result-stats">
          <Progress width="20px" percent={participation} strokeWidth={14} />
          <div className="percent">{participation}%</div>
          <div className="stats-description">
            {capitalize(feedbackType)} Completion
          </div>
        </div>
        <div className="flex">
          <div className="count">
            <span className="green">{participants}</span>Total participants
          </div>
          <div className="count">
            <span className="purple">{responded}</span>Responded
          </div>
          <div className="count">
            <span className="red">{participants - responded}</span>Not Responded
          </div>
        </div>
      </div>
      <div className="update-template-footer">
        <div>
          <GroupTagList departments={groups} limit={3} />
        </div>
        <div>
          <Button
            name={<FormattedMessage id="workUpdate.viewResult" />}
            onClick={() =>
              history.push(
                `${
                  feedbackType === FEEDBACK_TYPE.SURVEY
                    ? '/feedback/survey'
                    : '/feedback/poll'
                }/${feedbackId}/summary`
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(FeedbackResultWorkUpdate);
