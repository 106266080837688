import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ACTIONS_TYPE, META_TYPE, PRIVATE_ROUTE } from 'utils/constants';
import ability from 'utils/ability/ability';
import { FeatureActivation } from 'features/components/FeatureActivation';
import { AppContext } from 'AppContext';
import InfoIcon from 'atoms/InfoIcon';
import { FormattedMessage } from 'react-intl';
import { FeatureDeactivation } from 'features/components/FeatureDeactivation';

const Feedbacks = () => {
  const [activated, setActivated] = useState(
    ability.can('use', 'feedbacks_feature')
  );
  const { dispatch } = useContext(AppContext);
  const Title = () => (
    <InfoIcon
      title={<FormattedMessage id="feedback.title" />}
      contentKey={META_TYPE?.FEED_BACK_SETTINGS}
    />
  );

  useEffect(() => {
    dispatch({ type: ACTIONS_TYPE.SETTING_TITLE, data: <Title /> });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!activated) {
    return (
      <FeatureActivation
        featureKey="feedbacks_feature"
        setActivated={setActivated}
      />
    );
  }

  return (
    <div>
      {/* <SopSettings /> //todo: add settings for SOP */}
      <FeatureDeactivation
        featureKey="feedbacks_feature"
        setActivated={setActivated}
      />
    </div>
  );
};

export default {
  component: withRouter(Feedbacks),
  path: PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.FEEDBACKS,
  key: 'feedbacks',
  abilityAction: 'manage',
  abilitySubject: 'feedbacks',
  icon: (
    <i
      data-eva="person-done-outline"
      data-eva-height="20"
      data-eva-width="20"
    ></i>
  ),
  text: <FormattedMessage id="feedback.title" />,
};
