import React from 'react';
export const notificationData = {
  title: {
    icon: (
      <i
        data-eva="speaker-outline"
        data-eva-height="16"
        data-eva-width="16"
      >
      </i>
    ),
    heading: 'Impact of remote work',
  },
  groups: 'FrontEnd',
  due: {
    date: 'Due Date: ',
    icon: (
      <i
        data-eva="calendar-outline"
        data-eva-height="16"
        data-eva-width="16"
      >
      </i>
    ),
  },
  feedback: {
    poll: {
      icon: (
        <i
          data-eva="options-outline"
          data-eva-height="16"
          data-eva-width="16"
        >
        </i>
      ),
    },
    survey: {
      icon: (
        <i
          data-eva="file-text-outline"
          data-eva-height="16"
          data-eva-width="16"
        >
        </i>
      ),
    },
  },
};
