import styled from 'styled-components';
import {
  Popover,
  Timeline as AntTimeline,
  Typography,
} from 'antd';

import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
import { fonts } from 'design/Fonts';

export const StyledPopover = styled(Popover)`
.ant-popover-inner-content{
  padding:20px;
}
.ant-popover-content{

  }
  .ant-popover-inner{
    border:1px solid red;
  }
`;
export const SmallPopover = styled(Popover)`
  border-radius: 8px;
  .ant-popover-inner-content{
    padding: 20px;
  }
  .ant-popover-inner{
    border: 1px solid red;
  }
`;
export const PopoverContent = styled.div`
  min-height:415px;
  height:415px;
  min-width:360px;
  width:360px;
  overflow:auto;

  input.ant-input::placeholder{
   color:${Colors.TextMedium}
  }

  .input-comment:focus, .ant-input-affix-wrapper-focused{
    border:none;
    box-shadow:none;
  }

  input, .input-comment{
    background:${Colors.PopoverInput};
    border:none;
  }

  .input-comment{
    min-height: 45px;
    align-items: center;
  }
`;

export const Timeline = styled(AntTimeline)`
  padding:25px 40px 5px 40px;
  max-height: 200px;

  .ant-timeline-item.editing{
    padding-bottom: ${Spacings.xxSmall.padding};
  }

  .cancel {
    cursor: pointer;
  }

  .ant-timeline-item-head-custom .anticon{
    font-size:24px;
  }

  .anticon-delete {
    padding-left: ${Spacings.xxSmall.padding};
    color: ${Colors.ButtonPrimary};
    cursor: pointer;
  }

  .ant-timeline-item-content{
    margin-left: ${Spacings.large.margin};
  }

  .ant-avatar{
    height:20px;
    width:20px;
  }

  .col-avatar {
    flex: 1;
  }

  .ant-timeline-item-head-custom{
    padding:0;
  }

  div.ant-typography, .ant-typography p{
    margin-bottom:0;
  }

  .row-info{
    color:#999;
    font-size:12px;
  }

  .row-info > div{
    margin: 0 5px;
  }

  .emoji{
    color:black;
  }
`;

export const CommentParagraph = styled(Typography.Paragraph)`
  font-size: 13px;
  line-height: 1.4;
  padding-bottom: 4px;
`;

export const Wrapper = styled.div`
  color: #515983;
  &:hover{
  color:${Colors.PrimaryColor};
  }
  & .messageIcon{
    font-size:${fonts.small.size}
  }
`;
export const IconWrapper = styled.div`
  display:flex;
  span.count{
    margin-left:${Spacings.tiny.margin}
  }
`;
