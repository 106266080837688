import React, { useState } from 'react';
import { Form, Divider } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import client from 'apollo';
import Colors from 'design/Colors';
import Meta from 'atoms/Meta';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import Input from 'atoms/Input';
import {
  BUTTON_TYPE,
  USER_ROLE,
  PUBLIC_ROUTE,
  FORM_NAME,
  META_TYPE,
  AMPLITUDE_EVENTS,
  REGEX,
  LINKS,
} from 'utils/constants';
import {
  GoogleIcon,
  MicrosoftIcon,
  SlackIcon,
  UndifferLogo,
  UserIcon,
} from 'assets';
import {
  GOOGLE_AUTH_SIGN_UP,
  LOCAL_SIGN_UP,
  MICROSOFT_AUTH_SIGN_UP,
  SLACK_AUTH_SIGN_UP,
} from 'graphql/Onboarding/Mutations';
import * as Sentry from '@sentry/react';
import { getTrimValues } from 'utils';
import { createAmplitudeEvent } from 'utils/amplitude';
import {
  Container,
  Description,
  GoogleLogin,
  LoginPage,
  Or,
  SignupPage,
  Title,
  Wrapper,
  SIcon,
  Background,
  StyledInput,
  StyledForm,
  BtnWrapper,
  Logo,
  InputIcon,
  StyledDivider,
  Terms,
  FullDivider,
} from './AntStyled';
import firebase from '../../../analytics';
import TermsAndPolicy from '../TermsAndPolicy';

const Signup = (props) => {
  const { history, intl } = props;
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const trimValues = getTrimValues(values);
    const { email } = trimValues;
    try {
      setLoading(true);
      await client
        .mutate({
          mutation: LOCAL_SIGN_UP,
          variables: {
            data: {
              email: email.toLowerCase(),
              role: USER_ROLE.OWNER,
            },
          },
        })
        .then((res) => {
          setLoading(false);
          firebase.analytics().logEvent('signup_users');
          const eventProperties = {
            'User Id': res?.data?.localSignUp?.id,
            'User Email': res?.data?.localSignUp?.email,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENTS.NEW_USER_SIGN_UP,
            eventProperties
          );
          history.push({
            pathname: PUBLIC_ROUTE.CONFIRM,
            state: {
              route: PUBLIC_ROUTE.KEY.SIGNUP,
              email,
            },
          });
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Background>
        <Logo src={UndifferLogo} alt="logo"></Logo>
        <NavLink to="/help" className="bottom-right">
          <FormattedMessage id="terms.help" />
        </NavLink>
        <NavLink to="/copyright" className="bottom-left">
          <FormattedMessage id="terms.copyright" />
        </NavLink>
      </Background>
      <Wrapper>
        <Card padding="32" className="small">
          <Meta title={META_TYPE.SIGNUP} />
          <StyledForm
            name={FORM_NAME.BASIC}
            onFinish={onFinish}
            layout="vertical"
            centered
            initialValues={{ remember: true }}
          >
            <Title className="title">
              <FormattedMessage id="signUp.title" />
            </Title>
            <Form.Item
              required={false}
              colon={false}
              label="Work Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="signUp.formRules.email.errorMessage" />
                  ),
                },
                {
                  pattern: REGEX.EMAIL,
                  message: (
                    <FormattedMessage id="signUp.formRules.email.emailPattern" />
                  ),
                },
              ]}
            >
              <Input
                suffix={<InputIcon src={UserIcon} />}
                placeholder="Johndoe@abc.xyz"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                accent
                type={BUTTON_TYPE.PRIMARY}
                htmlType={BUTTON_TYPE.SUBMIT}
                className="right"
                name="Confirm"
                loading={loading}
              />
              <StyledDivider>OR</StyledDivider>
              <Or>
                <FormattedMessage id="signUp.startWith" />
              </Or>
              <GoogleLogin>
                <Button
                  background={Colors.Icons}
                  border={Colors.Icons}
                  focus={Colors.Icons}
                  icon={<SIcon src={GoogleIcon} />}
                  iconbtn
                  onClick={() => {
                    client
                      .mutate({ mutation: GOOGLE_AUTH_SIGN_UP })
                      .then((response) => {
                        firebase.analytics().logEvent('signup_users');
                        window.location = response.data.signupWithGoogle;
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }}
                />
                <Button
                  disabled
                  background={Colors.Icons}
                  border={Colors.Icons}
                  focus={Colors.Icons}
                  icon={<SIcon src={MicrosoftIcon} />}
                  iconbtn
                  onClick={() => {
                    client
                      .mutate({ mutation: MICROSOFT_AUTH_SIGN_UP })
                      .then((response) => {
                        window.location = response.data.signupWithMicrosoft;
                      });
                  }}
                />
                <Button
                  disabled
                  background={Colors.Icons}
                  border={Colors.Icons}
                  focus={Colors.Icons}
                  icon={<SIcon src={SlackIcon} />}
                  iconbtn
                  onClick={() => {
                    client
                      .mutate({ mutation: SLACK_AUTH_SIGN_UP })
                      .then((response) => {
                        window.location = response.data.signupWithSlack;
                      });
                  }}
                />
              </GoogleLogin>
            </Form.Item>
          </StyledForm>
          <FullDivider></FullDivider>
          <LoginPage>
            <FormattedMessage id="signUp.account" />
            &nbsp;
            <NavLink to={PUBLIC_ROUTE.LOGIN} className="signup">
              <FormattedMessage id="signUp.login" />
            </NavLink>
          </LoginPage>
        </Card>
        <TermsAndPolicy />
      </Wrapper>
    </Container>
  );
};

export default injectIntl(Signup);
