import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import { Col, message, Row, Radio, TimePicker, Spin } from 'antd';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { FormattedMessage, injectIntl } from 'react-intl';

import client from 'apollo';
import Card from 'atoms/Card';
import Button from 'atoms/Button';
import { PlusOutlined } from '@ant-design/icons';
import {
  EMPTY_OBJECT,
  EMPTY_ARRAY,
  BUTTON_TYPE,
  FETCH_POLICY,
} from 'utils/constants';
import { GET_PUNCH_SETTING } from 'features/people/graphql/Settings/People/Queries';
import {
  DELETE_PUNCH_SETTING,
  UPDATE_PUNCH_SETTING,
} from 'features/people/graphql/Settings/People/Mutations';
import * as Sentry from '@sentry/react';
import Loader from 'atoms/Loader';
import { ArrowIcon, Clock, DeleteIcon } from 'assets';
import { allowFlexibility } from './MockData';
import {
  Title,
  WorkTimeContent,
  Select,
  Form,
  Arrow,
  Delete,
  Image,
} from './AntStyled';
const { RangePicker } = TimePicker;
const { Option } = Select;
const TimeFormat = 'h:mm A';

const Break = ({ intl, locationId }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { data: punchSettingData, loading: punchSettingLoading } = useQuery(
    GET_PUNCH_SETTING,
    {
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
      variables: { where: { locationId: parseInt(locationId, 10) } },
    }
  );

  const [buttonActive, setButtonActive] = useState(true);
  const [punchSetting, setPunchSetting] = useState(EMPTY_OBJECT);
  const [flexibleBreak, setFlexibleBreak] = useState(true);
  const [flexibleBreakValue, setFlexibleBreakValue] = useState(1);

  const allowFlexibilityChildren = _map(allowFlexibility, (items, index) => (
    <Option key={index} value={items.hours}>
      {moment(items.text, 'h:mm').format('HH:mm')}
    </Option>
  ));

  useEffect(() => {
    if (punchSettingData && punchSettingData.getPunchSetting) {
      const { breaks, totalBreakDuration } = punchSettingData.getPunchSetting;

      let breakData = EMPTY_ARRAY;
      breakData = _map(breaks, (data) => [
        moment(data.startTime, moment.HTML5_FMT.TIME_MS),
        moment(data.endTime, moment.HTML5_FMT.TIME_MS),
        data.id,
      ]);

      form.setFieldsValue({
        breaks: breakData,
        totalBreakDuration,
      });
      setPunchSetting(punchSettingData.getPunchSetting);
      setFlexibleBreak(!punchSettingData.getPunchSetting.fixedBreakTiming);
      setFlexibleBreakValue(
        punchSettingData.getPunchSetting.fixedBreakTiming ? 2 : 1
      );
    }
  }, [form, punchSetting.id, punchSettingData]);

  const onFinish = async (values) => {
    setLoading(true);
    const { breaks = [], totalBreakDuration } = values;
    const createBreaks = [];
    const updateBreaks = [];
    _map(breaks, (data, key) => {
      if (data[2]) {
        updateBreaks.push({
          data: {
            startTime: `${moment(data[0]).format(moment.HTML5_FMT.TIME_MS)}Z`,
            endTime: `${moment(data[1]).format(moment.HTML5_FMT.TIME_MS)}Z`,
          },
          where: {
            id: data[2],
          },
        });
      } else {
        createBreaks.push({
          punchSettingId: punchSetting && parseInt(punchSetting.id, 10),
          startTime: `${moment(data[0]).format(moment.HTML5_FMT.TIME_MS)}Z`,
          endTime: `${moment(data[1]).format(moment.HTML5_FMT.TIME_MS)}Z`,
        });
      }
      return data;
    });

    await client
      .mutate({
        mutation: UPDATE_PUNCH_SETTING,
        variables: {
          data: {
            fixedBreakTiming: flexibleBreakValue !== 1,
            totalBreakDuration: totalBreakDuration && totalBreakDuration,
            createBreaks: createBreaks || undefined,
            updateBreakDatas: updateBreaks || undefined,
          },
          where: {
            id: punchSetting && parseInt(punchSetting.id, 10),
          },
        },
        refetchQueries: [
          {
            query: GET_PUNCH_SETTING,
            variables: { where: { locationId: parseInt(locationId, 10) } },
            fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
          },
        ],
      })
      .then(() => {
        message.destroy();
        message.success(
          intl.formatMessage({
            id: 'settings.attendance.successMessages.updateBreak',
          })
        );
        setButtonActive(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Sentry.captureException(error);
        console.log(error);
      });
  };

  const deleteBreak = async (key) => {
    const row = punchSetting && punchSetting.breaks && punchSetting.breaks[key];
    if (row) {
      try {
        await client.mutate({
          mutation: DELETE_PUNCH_SETTING,
          variables: {
            where: {
              id: row.id,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_PUNCH_SETTING,
              variables: { where: { locationId: parseInt(locationId, 10) } },
              fetchPolicy: FETCH_POLICY.CACHE_FIRST,
            },
          ],
        });
        message.destroy();
        message.success(
          intl.formatMessage({
            id: 'settings.attendance.successMessages.deleteBreak',
          }),
          300
        );
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };

  const onFormLayoutChange = () => {
    setButtonActive(false);
  };

  return (
    <WorkTimeContent gutter={[0, 0]}>
      <Spin spinning={punchSettingLoading}>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={onFormLayoutChange}
            initialValues={{ breakFlexibility: flexibleBreak }}
          >
            <Form.Item
              label={
                <div className="content-heading">
                  <FormattedMessage id="settings.attendance.breaks.formRules.specify.breakTime" />
                </div>
              }
            >
              <Radio.Group
                onChange={(e) => {
                  setFlexibleBreakValue(e.target.value);
                  setFlexibleBreak(e.target.value === 1);
                  setButtonActive(false);
                }}
                value={flexibleBreakValue}
              >
                <Radio value={1}>
                  <FormattedMessage id="settings.attendance.breaks.yes" />
                </Radio>
                <Radio value={2}>
                  <FormattedMessage id="settings.attendance.breaks.no" />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <div className="step-inside">
              {flexibleBreak ? (
                <>
                  <Title>
                    <FormattedMessage id="settings.attendance.workTimeSchedule.allowedBreakDuration" />
                  </Title>
                  <Form.Item
                    name="totalBreakDuration"
                    label={
                      <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.breakTime.label" />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.breakTime.errorMessage" />
                        ),
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={<Arrow src={ArrowIcon} />}
                      placeholder="Total Break Time"
                      getPopupContainer={(trigger) => trigger?.parentNode}
                    >
                      {allowFlexibilityChildren}
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Title>
                    <FormattedMessage id="settings.attendance.workTimeSchedule.timing" />
                  </Title>
                  <Form.List name="breaks">
                    {(fields, { add, remove }) => (
                      <div className="new">
                        {_map(fields, (field) => (
                          <Form.Item required={false} key={field.key}>
                            <Row className="extra-field-row">
                              <Col span={22}>
                                <Form.Item
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  label="Start Time & End Time"
                                  rules={[
                                    {
                                      required: true,
                                      message: (
                                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.flexibility.errorMessage" />
                                      ),
                                    },
                                  ]}
                                >
                                  <RangePicker
                                    className="range"
                                    defaultValue={[
                                      moment('10:00 AM', TimeFormat),
                                      moment('07:00 PM', TimeFormat),
                                    ]}
                                    allowClear={false}
                                    format={TimeFormat}
                                    suffixIcon={<Image src={Clock} />}
                                  />
                                </Form.Item>
                              </Col>
                              <Col className="col-icon" span={1}>
                                <Delete
                                  src={DeleteIcon}
                                  className="deleteicon"
                                  onClick={() => {
                                    remove(field.name);
                                    deleteBreak(field.key);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            add
                            onClick={() => {
                              add();
                            }}
                            name={
                              <>
                                <PlusOutlined style={{ marginRight: '5px' }} />
                                <FormattedMessage id="settings.attendance.workTimeSchedule.addBreak" />
                              </>
                            }
                          />
                        </Form.Item>
                      </div>
                    )}
                  </Form.List>
                </>
              )}
            </div>
            <Form.Item className="mb-0">
              <Col span={24} className="text-right" align="middle">
                <Button
                  primary
                  loading={loading}
                  disabled={buttonActive}
                  type={BUTTON_TYPE.PRIMARY}
                  htmlType={BUTTON_TYPE.SUBMIT}
                  name={
                    <FormattedMessage id="settings.attendance.workTimeSchedule.saveBreakSettings" />
                  }
                />
              </Col>
            </Form.Item>
          </Form>
        </Col>
      </Spin>
    </WorkTimeContent>
  );
};

export default injectIntl(Break);
