import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{`${title} | undiffer`}</title>
    {description && <meta name="description" content={description} />}
  </Helmet>
);

export default Meta;
