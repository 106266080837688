import React from 'react';
import { Layout } from 'antd';
import {
  Switch, Route, Router, withRouter,
} from 'react-router-dom';
import Meta from 'atoms/Meta';
import LeaveRequest from 'features/people/templates/Leaves/LeaveRequest';
import LeaveSummary from 'features/people/templates/Leaves/LeaveSummary';
import * as Sentry from '@sentry/react';
import Page404 from 'pages/404';
import { PRIVATE_ROUTE, META_TYPE } from 'utils/constants';
import ability from 'utils/ability/ability';
import Attendance from './Attendance';

const People = ({ history }) => (
  <Sentry.ErrorBoundary fallback="Something went wrong">
    <Layout>
      <Meta title={META_TYPE.LEAVES} />
      <Router history={history}>
        <Switch>
          {ability.can(Attendance.abilityAction, Attendance.abilitySubject) && (
            <Route
              exact
              path={PRIVATE_ROUTE.PEOPLE}
              component={Attendance.component}
            />
          )}
          {ability.can(
            LeaveSummary.abilityAction,
            LeaveSummary.abilitySubject
          ) && (
            <Route
              path={PRIVATE_ROUTE.LEAVES_SUMMARY}
              component={LeaveSummary.component}
            />
          )}
          {ability.can(
            LeaveRequest.abilityAction,
            LeaveRequest.abilitySubject
          ) && (
            <Route
              path={PRIVATE_ROUTE.LEAVES_REQUEST}
              component={LeaveRequest.component}
            />
          )}
          <Route component={Page404} />
        </Switch>
      </Router>
    </Layout>
  </Sentry.ErrorBoundary>
);
export default {
  key: PRIVATE_ROUTE.PEOPLE,
  component: withRouter(People),
  name: 'People',
  icon: (
    <i
      fill="white"
      data-eva="bar-chart-outline"
      data-eva-height="20"
      data-eva-width="20"
    >
    </i>
  ),
  expandIcon: (
    <i
      fill="white"
      data-eva="arrow-down"
      data-eva-height="16"
      data-eva-width="16"
    >
    </i>
  ),
  subPages: [Attendance, LeaveRequest, LeaveSummary],
  abilitySubject: 'people_feature',
  abilityAction: 'use',
};
