import styled, { createGlobalStyle } from 'styled-components';
import { Layout, Form as AntForm, Typography, Select as AntSelect } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const Heading = styled(Typography)`
  font: ${fonts.xLarge.bold};
  color: ${Colors.TextPrimary};
  line-height: 28px;
  margin-bottom: ${Spacings.xxSmall.margin};
`;
export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  font: ${fonts.large.bold};
  color: ${Colors.TextPrimary};
  cursor: default;
  .anticon {
    margin-right: ${Spacings.xSmall.margin};
  }
`;

export const Content = styled(AntContent)`
  display: flex;
  justify-content: center;
  position: relative;
  &.top {
    padding-bottom: ${Spacings.small.padding};
  }
  .header {
    max-width: 700px;
    height: fit-content;
    margin-bottom: 0px;
  }
  .card {
    max-width: 700px;
    height: fit-content;
    margin-bottom: ${Spacings.xSmall.padding};
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
    .header {
      max-width: 600px;
    }
  }
  .ant-card-head {
    border-bottom: 1px solid ${Colors.Border};
  }
  .question-template {
    margin-bottom: 24px;
    h3.section-heading {
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }
  .row {
    width: 700px;
  }
  @media (max-width: 1300px) {
    .row {
      width: 500px;
    }
  }
  .right {
    margin-right: 8px;
  }
  .reorder {
    color: ${Colors.ButtonPrimary};
    border-radius: 8px;
    font: ${fonts.xSmall.bold};
  }

  .delete {
    color: ${Colors.ButtonDanger};
    font: ${fonts.xSmall.bold};
  }

  .reorder,
  .delete {
    margin: 0 0 0 ${Spacings.xSmall.margin};
    font-weight: 400;
    cursor: pointer;
  }

  .btn-no-shadow {
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .btn-add-mcq {
    margin-top: ${Spacings.xSmall.margin};
  }

  .btn-add-question {
    margin-left: ${Spacings.small.margin};
  }
  .question {
    margin-bottom: -24px;
  }
  .addChoice {
    margin-bottom: 16px;
  }
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-radio-wrapper {
    color: ${Colors.Radio};
  }
  .ant-radio-wrapper-checked {
    color: ${Colors.PrimaryColor};
  }
  .ant-card-head-title {
    .line-height-16 {
      line-height: 13px;
      border-radius: 4px;
      font-size: 12px !important;
      color: ${Colors.Text};
    }
  }
  .add-new {
    font: ${fonts.small.regular};
    color: ${Colors.Text};
    line-height: 22px;
  }
  .plus {
    width: 10px;
    height: 10px;
    margin-right: 6px;
    line-height: 22px;
  }
  .paragraph.ant-typography {
    color: ${Colors.Text};
  }
`;
export const Form = styled(AntForm)`
  .ant-form-item {
    margin-bottom: 16px;
    font-size: ${fonts.medium.size};
  }
  .ant-row.ant-form-item:last-of-type {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font-size: 12px;
  }
  .item {
    display: inline-block;
    width: 100px;
  }
  .ant-select-disabled {
    background: ${Colors.LightDark};
  }
  .ant-form-item-control-input {
    min-height: 0px;
  }
  .label {
    margin: 16px 0px 4px;
    color: ${Colors.TextDark};
    font: ${fonts.small.medium};
  }
  .label-multiple {
    margin: 16px 0px 8px;
    color: ${Colors.TextDark};
    font: ${fonts.small.medium};
  }
  .template-button {
    background: transparent;
    border: none;
    color: ${Colors.ButtonPrimary};
    box-shadow: none;
    font-weight: 500;
  }
  .delete-title {
    .name {
      color: red;
      margin-left: 5px;
    }
  }
  .add-question {
    .ant-card-body {
      padding: 5px;
    }
  }
`;
export const Image = styled.img`
  cursor: pointer;
  margin-right: ${Spacings.xSmall.padding};
`;
export const StyledImage = styled.img`
  color: ${Colors.ButtonSecondary};
  width: 18px;
  height: 18px;
  padding-bottom: 4px;
`;
export const Delete = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-left: 8px;
  align-self: center;
  cursor: pointer;
`;
export const Select = styled(AntSelect)`
  font: ${fonts.xSmall.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  font-weight: 400;
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 12px;
    border: none;
    height: 30px;
    background-color: transparent;
  }
  &.ant-select-selection-placeholder {
    font-size: 14px;
  }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 0px;
    margin-right: 20px;
  }
`;
export const StyledImg = styled.img`
  width: 18px;
  height: 18px;
  margin: 10px 0px;
`;
export const GlobalStyles = createGlobalStyle`
  .div-1{
    font-weight: 500;
    font-size: 14px;
  }
  .div-2{
    font-weight: 400;
    font-size: 12px;
  }
  .max-width{
    width: 100%
  }
  .checked{
  height:20px;
  width:20px;
  }
  .active {
  color:#515983;
  }
`;
export const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 2px;
  margin-top: -4px;
`;
