/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Col, Row, Empty } from 'antd';
import Card from 'atoms/Card';
import { FormattedMessage } from 'react-intl';
import SingleDatePicker from 'atoms/SingleDatePicker';
import {
  BlockerPrimary,
  CalenderPrimary,
  CornerDown,
  Standup,
  YesterdayIcon,
} from 'assets';
import moment from 'moment';
import 'emoji-mart/css/emoji-mart.css';
import { useQuery } from '@apollo/client';
import { GET_STANDUP } from 'features/standUp/graphql/Queries';
import Loader from 'atoms/Loader';
import { EMPTY_ARRAY, EMPTY_OBJECT, FETCH_POLICY } from 'utils/constants';
import { filter, map } from 'lodash';
import { taskFormat } from 'utils/utils';
import { GET_HOLIDAYS } from 'features/people/graphql/Settings/People/Queries';
import {
  Divider,
  StyledDiv,
  EmptyWrapper,
  WrapperDiv,
  WrapperBody,
  LeavesCol,
} from './AntStyled';
import { standupData } from '../../pages/MockData';

const PreviousStandup = () => {
  const [datePickerValue, setSingleDatePicker] = useState(
    moment().subtract(1, 'days')
  );
  const [holidays, setHolidays] = useState(EMPTY_ARRAY);
  const [standupEntry, setStandupEntry] = useState(EMPTY_OBJECT);
  const { data: standUpData, loading } = useQuery(GET_STANDUP, {
    variables: {
      where: { date: moment(datePickerValue).format('YYYY-MM-DD') },
    },
  });
  useEffect(() => {
    setStandupEntry(standUpData?.standUpEntry || EMPTY_OBJECT);
  }, [standUpData]);
  const { data: holidaysData, loading: holidaysDataLoading } = useQuery(
    GET_HOLIDAYS,
    {
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      variables: {
        where: {
          fromDate: moment(datePickerValue).format('YYYY-MM-DD'),
          toDate: moment(datePickerValue).add(1, 'day').format('YYYY-MM-DD'),
        },
      },
    }
  );
  useEffect(() => {
    if (holidaysData?.holidays?.length > 0) {
      if (map(holidaysData?.holidays, 'type').includes('PUBLIC')) {
        setHolidays(
          filter(holidaysData?.holidays, (val) => val?.type === 'PUBLIC')
        );
      } else {
        setHolidays(holidaysData?.holidays);
      }
    } else {
      setHolidays(EMPTY_ARRAY);
    }
  }, [holidaysData]);

  const LeaveTitle = (val) => {
    switch (val) {
      case 'FULLDAY':
        return (
          <StyledDiv>
            <FormattedMessage id="dashboard.leavesEmpty.weekend" />{' '}
            <span>{moment(datePickerValue).format('dddd')}</span>
          </StyledDiv>
        );
      case 'PUBLIC':
        return (
          <StyledDiv>
            <FormattedMessage
              id={
                moment(datePickerValue).date() ===
                moment().subtract(1, 'day').date()
                  ? 'dashboard.leavesEmpty.yesterdayHoliday'
                  : 'dashboard.leavesEmpty.pastHoliday'
              }
            />{' '}
            {map(holidays, (val, index) => (
              <>
                <span>{val?.name}</span>{' '}
                {val?.country && `(${val?.country} calendar)`}
                {holidays?.length > 1 &&
                  index < holidays?.length - 1 &&
                  ','}{' '}
              </>
            ))}
            <FormattedMessage id="dashboard.leavesEmpty.celebration" />
          </StyledDiv>
        );
      default:
        return (
          <StyledDiv>
            {moment(datePickerValue).date() ===
            moment().subtract(1, 'day').date() ? (
              <FormattedMessage id="dashboard.leavesEmpty.checkInYesterday" />
            ) : (
              <FormattedMessage id="dashboard.leavesEmpty.checkInPast" />
            )}
          </StyledDiv>
        );
    }
  };
  return (
    <Card
      className="card2"
      title={
        <Row justify="center">
          <Col>
            <SingleDatePicker
              setSingleDatePicker={setSingleDatePicker}
              datePickerValue={datePickerValue}
              disabledDate={(current) =>
                current && current > moment().subtract(1, 'days')
              }
              showToday={false}
            />
          </Col>
        </Row>
      }
      padding={16}
    >
      {loading || holidaysDataLoading ? (
        <Loader />
      ) : !standUpData?.standUpEntry ? (
        <EmptyWrapper>
          <Empty
            description={LeaveTitle(
              map(holidays, 'type').includes('PUBLIC')
                ? 'PUBLIC'
                : holidays?.[0]?.dayType
            )}
            image={Standup}
            imageStyle={{
              height: 48,
            }}
          />
        </EmptyWrapper>
      ) : (
        <>
          <div>
            {(holidays?.[0]?.dayType === 'FIRSTHALF' ||
              holidays?.[0]?.dayType === 'SECONDHALF') && (
              <LeavesCol>
                <FormattedMessage id="dashboard.leavesEmpty.halfDay" />{' '}
                <span>
                  {holidays?.[0]?.dayType === 'FIRSTHALF'
                    ? 'First Half'
                    : 'Second Half'}
                </span>{' '}
                <FormattedMessage id="dashboard.leavesEmpty.leave" />
              </LeavesCol>
            )}
            <Row align="middle">
              <Col className="main-icon">
                <img src={YesterdayIcon} alt="icon" />
                <Col className="title ml-8">
                  {standupEntry?.yesterday?.count > 0 ? (
                    <div>
                      {standupEntry?.standUp?.standUpQuestions[0]?.questionText}
                    </div>
                  ) : (
                    <FormattedMessage id="standUps.formLabel.noYesterdayGoal" />
                  )}
                </Col>
              </Col>
            </Row>
            {map(
              filter(
                standupEntry?.yesterday?.standUpAnswers,
                (answer) => answer?.content?.isChecked
              ),
              (answer) => (
                <div key="yesterday-goals" className="goals text">
                  <span className="border-goals">&nbsp;</span>
                  {taskFormat(answer?.content?.task)}
                </div>
              )
            )}
          </div>
          <Divider />
          <div>
            <Row align="middle">
              <Col className="main-icon">
                <img src={CalenderPrimary} alt="icon" height={20} width={20} />
                <Col className="title ml-8">
                  {standupEntry?.standUp?.standUpQuestions?.[1] && (
                    <div>
                      {standupEntry?.standUp?.standUpQuestions[1]?.questionText}
                    </div>
                  )}
                </Col>
              </Col>
            </Row>
            {map(standupEntry?.today?.standUpAnswers, (answer) => (
              <div key="today-goals" className="today text">
                <span className="border-today">&nbsp;</span>
                {taskFormat(answer?.content?.task, answer?.addedLater)}
              </div>
            ))}
          </div>
          <Divider />
          <div>
            <Row align="middle">
              <Col className="main-icon">
                <img src={BlockerPrimary} alt="icon" height={20} width={20} />
                <Col className="title ml-8">
                  {standupEntry?.blocker?.count > 0 ? (
                    <div>
                      {standupEntry?.standUp?.standUpQuestions[2]?.questionText}
                    </div>
                  ) : (
                    <FormattedMessage id="standUps.formLabel.noBlocker" />
                  )}
                </Col>
              </Col>
            </Row>
            {map(standupEntry?.blocker?.standUpAnswers, (answer) => (
              <div key="blockers" className="blocker text">
                <span className="border-blocker">&nbsp;</span>
                {taskFormat(answer?.content?.task, answer?.addedLater)}
              </div>
            ))}
          </div>
        </>
      )}
    </Card>
  );
};

export default PreviousStandup;
