import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { injectIntl } from 'react-intl';
import { PopoverContent } from './AntStyled';

const Popover = ({ reason }) => (
  <PopoverContent>
    <div className="title">Reason</div>
    <div className="text">{reason}</div>
  </PopoverContent>
);

export default injectIntl(Popover);
