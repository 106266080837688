import React from 'react';
import { Table as AntTable } from 'antd';
import styled from 'styled-components';
import Variables from 'design/Variables';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { scrollbars } from 'design/Styled';
import Spacings from 'design/Spacings';

const StyledTable = styled(AntTable)`
  .ant-table {
    overflow: auto;
    ${scrollbars};
    max-height: ${({ height, isInfinite }) =>
      height || isInfinite ? '' : 'calc(100vh - 99px - 64px - 16px)'};
    margin-top: ${({ marginTop }) => marginTop || 0}px;
    font: ${fonts.small.regular};
    border-radius: ${Variables.AtomRadius};
  }
  .ant-table-column-sorters {
    padding: 0;
  }
  .ant-table thead > tr > th {
    padding: 14px;
    font: ${fonts.small.medium};
    color: ${Colors.TextPrimary};
    background: transparent;
    border-bottom: 2px solid ${Colors.SecondaryColor};
    &:first-child {
      border-bottom: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
    &.action-name {
      padding-left: ${Spacings.xxSmall.padding};
    }
  }
  && tbody > tr:hover > td {
    background: #eff2f6;
    &:first-child {
      background: transparent;
    }
    &:last-child {
      background: transparent;
    }
  }
  .ant-table tbody > tr > td {
    font: ${fonts.small.regular};
    color: ${Colors.TextSecondary};
    padding: 8px 14px;
    border-bottom: 1px solid ${Colors.SecondaryColor};
    &:first-child {
      border-bottom: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
    &.action-name {
      padding-left: ${Spacings.xxSmall.padding};
    }
    &.ant-table-column-sort {
      background: transparent;
    }
  }
  &.bb-0.ant-table-wrapper tbody > tr:last-child > td {
    border-bottom: none;
  }
  .ant-table-wrapper {
    display: none;
  }
  .ant-empty-normal {
    display: ${({ showEmptyState }) => (showEmptyState ? 'unset' : 'none')};
  }
`;

const Table = ({
  bordered,
  columns,
  components,
  dataSource,
  height,
  loading,
  marginTop,
  onRow,
  scroll,
  isInfinite,
  onChange,
  locale,
  className,
  expandable,
  showEmptyState = false,
  ...rest
}) => (
  <StyledTable
    onRow={onRow}
    components={components}
    columns={columns}
    dataSource={dataSource}
    pagination={false}
    loading={loading}
    height={height}
    marginTop={marginTop}
    bordered={bordered}
    scroll={scroll}
    onChange={onChange}
    isInfinite={isInfinite}
    locale={locale}
    className={className}
    expandable={expandable}
    showEmptyState={showEmptyState}
    {...rest}
  />
);

export default Table;
