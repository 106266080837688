import styled from 'styled-components';
import { Select as AntSelect, Form as AntForm, Layout } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  font: ${fonts.large.bold};
  color: ${Colors.Text};
  cursor: default;
  .anticon {
    margin-right: ${Spacings.xSmall.margin};
  }
`;

export const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 2px;
  margin-top: -4px;
`;

export const Image = styled.img`
  cursor: pointer;
  margin-right: ${Spacings.xSmall.margin}!important;
`;

export const Select = styled(AntSelect)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  .ant-select-selection-placeholder {
    font: ${fonts.xSmall.regular};
  }
  &.ant-select {
    background: white;
    min-width: 170px;
    max-width: 1000px;
    display: table-cell;
    word-wrap: break-word;
    word-break: break-word;
    & .ant-select-selector {
      padding-right: 20px;
      padding-left: 6px;
      border: none;
    }
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  &.ant-select-multiple .ant-select-selector {
    border: none;
    width: 100%;
    background-color: transparent;
  }
  &.ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 14px;
    left: 14px;
  }
  // //! due to inline styling using important
  .ant-select-selection-search {
    width: 10% !important;
  }
  &.ant-select-multiple
    .ant-select-selection-search:first-child
    .ant-select-selection-search-input {
    @media (max-width: 850px) {
      margin-left: 0px;
    }
  }
`;
export const Content = styled(AntContent)`
  display: flex;
  justify-content: center;
  color: ${Colors.TextEnable};
  &.ant-layout-content {
    .ant-btn-primary {
      line-height: 22px;
    }
  }
  .card {
    max-width: 700px;
    height: fit-content;
    margin-bottom: 24px;
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
  }
  .ant-col .ant-form-item-label {
    padding: 0;
  }
  .datePicker {
    margin: 0;
    border-radius: 8px;
    max-width: 108px;
  }
  .timePicker {
    margin: 0px;
    border-radius: 8px;
    max-width: 108px;
  }
  .p-tag {
    margin-left: 24px;
    margin-bottom: 12px;
    color: ${Colors.TextSecondary};
    :last-child {
      margin-bottom: 0px;
    }
  }
  .btn {
    display: flex;
    justify-content: flex-end;
  }
  .radio-label {
    color: ${Colors.TextForm};
  }
  .ant-radio-inner {
    border: 2px solid ${Colors.PrimaryColor};
  }
  .ant-radio-inner::after {
    position: absolute;
    top: 2px;
    left: 2px;
  }
  .ant-radio-wrapper {
    color: ${Colors.Radio};
  }
  .ant-radio-group {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0px;
    }
  }
  .ant-radio-wrapper-checked {
    color: ${Colors.PrimaryColor};
    font-weight: 500;
  }
  .ant-picker.ant-picker-disabled {
    .icon-date-time {
      opacity: 0.3;
    }
  }
  .mb-10 {
    margin-bottom: 10px;
  }
`;

export const Form = styled(AntForm)`
  .ant-form-item {
    margin-bottom: 16px;
    font-size: ${fonts.medium.size};
    &:nth-last-child(2) {
      margin-bottom: 24px;
    }
  }
  .ant-row.ant-form-item:last-of-type {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
  .item {
    display: inline-block;
    width: 100px;
  }
  .ant-select-disabled {
    background: ${Colors.LightDark};
  }
  .template-button {
    background: transparent;
    border: none;
    color: ${Colors.ButtonPrimary};
    box-shadow: none;
  }
  .delete-title {
    .name {
      color: red;
      margin-left: 5px;
    }
  }
`;
export const StyledImage = styled.img`
  color: ${Colors.ButtonSecondary};
  width: 18px;
  height: 18px;
  padding-bottom: 4px;
`;
