import React, { useState } from 'react';
import _map from 'lodash/map';
import Button from 'atoms/Button';
import { USER_ACTION, BUTTON_TYPE } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import { ArrowIcon } from 'assets';
import UsersActions from 'atoms/UsersActions';
import { useForm } from 'antd/lib/form/util';
import { LeaveStatus } from './MockData';
import { Modal, Form, Select, TextBox, Arrow } from './AntStyled';

const { Option } = Select;

export default function ({
  approveRejectLeave,
  setIsModalVisible,
  isModalVisible,
  rowData,
  setRowId,
}) {
  const [form] = useForm();
  const [loading, setLoading] = useState();
  const LeaveTypeChildren = _map(LeaveStatus, (items, index) => (
    <Option key={index} value={items.value}>
      {items.type}
    </Option>
  ));

  const showModal = (id) => {
    if (id !== undefined) {
      setRowId(id);
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onActions = (data, action) => {
    switch (action) {
      case 'Approve':
        form.setFieldsValue({ status: 'APPROVED', rejectReason: '' });
        showModal(data.leaveId);
        break;
      case 'Reject':
        form.setFieldsValue({ status: 'REJECTED', rejectReason: '' });
        showModal(data.leaveId);
        break;
      default:
        break;
    }
  };

  return (
    <span>
      <UsersActions
        horizontal
        className="leave"
        rowData={rowData}
        actions={[USER_ACTION.APPROVE, USER_ACTION.REJECT]}
        onClickEvent={onActions}
      />
      <Modal
        width="360px"
        title="Approve / Reject Leave"
        visible={isModalVisible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <span>
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => approveRejectLeave(values, setLoading)}
          >
            <Form.Item
              required
              colon={false}
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="myProfile.formRules.reasonDescription.errorMessage" />
                  ),
                },
              ]}
            >
              <Select
                placeholder="Select Status"
                allowClear
                showSearch
                suffixIcon={<Arrow src={ArrowIcon} />}
                getPopupContainer={(trigger) => trigger?.parentNode}
              >
                {LeaveTypeChildren}
              </Select>
            </Form.Item>
            <Form.Item
              colon={false}
              label="Reason Description"
              name="rejectReason"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="myProfile.formRules.reasonDescription.errorMessage" />
                  ),
                },
              ]}
            >
              <TextBox rows={6} />
            </Form.Item>
            <Form.Item>
              <Button
                disabled={loading}
                secondary
                name="Cancel"
                className="btn-reject-cancel"
                onClick={handleCancel}
              />
              <Button
                loading={loading}
                primary
                name="Save"
                className="btn-reject-reason"
                htmlType={BUTTON_TYPE.SUBMIT}
              />
            </Form.Item>
          </Form>
        </span>
      </Modal>
    </span>
  );
}
