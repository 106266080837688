/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Progress } from 'antd';
import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';

const StyledProgress = styled(({ showPointer, ...rest }) => (
  <Progress {...rest} />
))`
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : 'initial')};
  & .head {
    color: ${Colors.TextSecondary};
  }
  &.font-tiny .extra {
    font-size: ${fonts.tiny.size};
  }
  &.font-inherit .extra {
    font-size: inherit;
    color: ${Colors.TextSecondary};
  }
  & .description {
    font-size: ${fonts.xTiny.size};
    max-width: 75%;
    margin: auto;
    margin-top: ${Spacings.xxSmall.margin};
    font-weight: bold;
  }
`;

export default function ({
  width = 75,
  strokeColor = Colors.ButtonPrimary,
  percent = 0,
  description = null,
  extra = '',
  completed,
  subExtra = false,
  number = 0,
  trailColor = '#E8E8E8',
  strokeWidth = 8,
  strokeLinecap = 'square',
  onClick,
}) {
  return (
    <StyledProgress
      className={subExtra ? 'font-tiny' : 'font-inherit'}
      width={width}
      type="circle"
      strokeLinecap={strokeLinecap}
      strokeColor={strokeColor}
      trailColor={trailColor}
      percent={completed || percent}
      strokeWidth={strokeWidth}
      showPointer={onClick && typeof onClick === 'function'}
      format={(percentage) => (
        <div
          className="content"
          role="textbox"
          tabIndex="-1"
          onClick={() => onClick && typeof onClick === 'function' && onClick()}
        >
          <div className="head">
            {(number !== 0 && number) || Math.round(percentage)}
            <span className="extra">{extra}</span>
          </div>
          {description && <div className="description">{description}</div>}
        </div>
      )}
    />
  );
}
