import styled from 'styled-components';
import { Form as AntForm } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';

export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0;
  }
  .ant-form-item-label > label {
    color: ${Colors.TextDark};
  }
  min-width: 400px;
`;

export const CardWrapper = styled.div`
  & button.ant-btn {
    float: right;
  }
  & .small-fonts {
    font-size: ${fonts.small.size};
  }
  .ant-card,
  .ant-card-bordered {
    border-radius: 8px;
  }
`;
