import { Layout } from 'antd';
import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  .ant-form-item textarea.ant-input {
    border-radius: 8px;
  }
  .ant-form-item {
    margin-bottom: ${Spacings.xSmall.margin};
    font-size: ${fonts.medium.size};
    &:nth-last-child(2) {
      margin-bottom: ${Spacings.small.margin};
    }
  }
  .text-area {
    margin-top: ${Spacings.xSmall.margin};
  }
  .ant-row.ant-form-item:last-of-type {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
`;

export const StyledBody = styled.div`
  margin:${Spacings.xSmall.margin} 0;
  border:1px solid ${Colors.Border};
  padding:${Spacings.xSmall.padding};
  border-radius:8px;
  max-height: calc(100vh - 115px - 56px - 250px);
  overflow: auto;
  #announcement-content  p:last-of-type{
    margin:0;
  }
`;
