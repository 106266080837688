export const MomentFormat = {
  dateTime: 'MM/DD/YYYY, h:mm A',
  time: 'LT',
  longTime: 'h:mm:ss A',
  shortTime: 'h:mm A',
  date: 'L',
  month: 'LL',
  lll: 'lll',
  min: 'hh[h] mm[m]',
  dayMonthYear: 'DD/MM/YYYY',
  at: 'LL [at] h:mm A',
  US: 'MM/DD/YYYY',
  monthDate: 'MMM DD',
  MMM: 'MMM Do',
  MMMY: 'MMM Do[,] YYYY',
  longDate: 'Do MMM YYYY',
  yearMonthDay: 'YYYY-MM-DD',
};
