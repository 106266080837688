import styled from 'styled-components';
import { Form, Divider } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { x } from 'design/Styled';
import Spacings from 'design/Spacings';
import { Loginbg } from 'assets';

export const Container = styled.div`
  ${x};
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${Colors.SidebarText};
  .ant-divider-horizontal.ant-divider-with-text-center::before,
  .ant-divider-horizontal.ant-divider-with-text-center::after {
    border-top: 1px solid ${Colors.SecondaryColor};
  }
  .terms {
    margin-right: ${Spacings.Small.margin};
    color: ${Colors.TextSecondary};
    @media (max-width: 1120px) {
      color: ${Colors.SidebarText};
    }
  }
  .anchor {
    font: ${fonts.small.regular};
    color: ${Colors.TextSecondary};
  }
  .ant-btn-primary {
    font: ${fonts.small.medium};
    line-height: 22px;
  }
`;

export const Wrapper = styled.div`
  .ant-card {
    overflow: hidden;
    width: 100%;
    max-width: 408px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  @media (max-width: 1120px) {
    position: absolute;
    top: 55%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;

export const Terms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 480px;
  padding-top: 10px;
  align-self: center;
  font: ${fonts.xSmall.bold};
`;
