import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    profileImage
    originalEmail
  }
`;

export const groupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    color
    type
    members {
      user {
        ...UserFragment
      }
    }
    managers {
      user {
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;

export const activityLogFragment = gql`
  fragment ActivityLogFragment on ActivityLog {
    id
    title
    description
    createdAt
    updatedAt
    triggerBy {
      ...UserFragment
    }
  }
  ${userFragment}
`;
