import { gql } from '@apollo/client';

export const CREATE_ANNOUNCEMENT = gql`
  mutation createAnnouncement($data: createAnnouncementInput!) {
    createAnnouncement(data: $data) {
      id
      title
      groups {
        id
      }
      body
      createdBy {
        id
      }
      company {
        id
      }
      status
      updatedAt
    }
  }
`;

export const PUBLISH_ANNOUNCEMENT = gql`
  mutation publishAnnouncement($where: publishAnnouncementWhere!) {
    publishAnnouncement(where: $where) {
      id
    }
  }
`;

export const UPDATE_ANNOUNCEMENT = gql`
  mutation updateAnnouncement(
    $where: announcementWhereUniqueInput!
    $data: createAnnouncementInput!
  ) {
    updateAnnouncement(data: $data, where: $where) {
      id
      title
      groups {
        id
      }
      body
      createdBy {
        id
      }
      company {
        id
      }
      status
      updatedAt
    }
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncement($where: announcementWhereUniqueInput!) {
    deleteAnnouncement(where: $where)
  }
`;

export const MARK_AS_READ_ANNOUNCEMENT = gql`
  mutation readAnnouncement($where: readAnnouncementInput!) {
    readAnnouncement(where: $where) {
      announcement {
        id
        title
      }
    }
  }
`;
