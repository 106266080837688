import React from 'react';
import Hours from 'atoms/Hours';
import _map from 'lodash/map';
import moment from 'moment';

export const calculateWorkedTime = (punches, calculateBreak) => {
  let totalTime = 0;
  _map(punches, (punch) => {
    // if (punch.status === 'PUNCHOUT') {
    const diff = punch.status === 'PUNCHOUT'
      ? moment(punch.punchOut).diff(punch.punchIn, 'seconds') : moment().diff(punch.punchIn, 'seconds');
      // const diff = moment(punch.punchOut).diff(punch.punchIn, 'seconds');
    if (calculateBreak) {
      totalTime += punch.result.includes('BREAK') || punch.isBreak ? diff : 0;
    } else {
      totalTime += punch.result.includes('BREAK') || punch.isBreak ? 0 : diff;
    }
    // }
  });
  const hours = `${parseInt(totalTime / 3600, 10)}`;
  const mins = `${parseInt((totalTime % 3600) / 60, 10)}`;
  return <>{hours.length === 1 ? 0 : ''}<Hours estTime={hours} /> {mins.length === 1 ? `0${mins}` : mins}m</>;
};
