/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Switch as AntSwitch } from 'antd';
import Variables from 'design/Variables';

const StyledSwitch = styled(AntSwitch)`
  border-radius: ${Variables.RadiusXl};
  margin-right: ${({ right }) => right || 0};
`;

const Switch = props => <StyledSwitch {...props} />;

export default Switch;
