import React, { useEffect, useState } from 'react';
import { Tabs, Layout, Spin, Row, Col, message, Tag } from 'antd';
import { AMPLITUDE_EVENTS, EMPTY_OBJECT, FETCH_POLICY } from 'utils/constants';
import Card from 'atoms/Card';
import InfoIcon from 'atoms/InfoIcon';
import Button from 'atoms/Button';
import { BackArrow, MarkAsRead, DoneAllOutline, Trash } from 'assets';
import { useParams, withRouter } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _has from 'lodash/has';
import GroupTagList from 'organisms/GroupTagList';
import Header from 'layout/Header';
import { serialize } from 'organisms/TextEditor';
import { injectIntl } from 'react-intl';
import {
  MARK_AS_READ_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
} from 'features/announcement/graphql/Mutations';
import { useHistory } from 'react-router-dom';
import client from 'apollo';
import * as Sentry from '@sentry/react';
import { confirmationModal } from 'atoms/ConfirmationModal';
import ability from 'utils/ability/ability';
import { createAmplitudeEvent } from 'utils/amplitude';
import { Content, ContentTitle, Arrow, StyledBody, Image } from './AntStyled';
import {
  GET_ANNOUNCEMENT_BY_ID,
  IS_ANNOUNCEMENT_READ,
} from '../../graphql/Queries';

const { TabPane } = Tabs;

function AnnouncementDetails(props) {
  const location = useHistory();
  const { history, intl } = props;
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState(EMPTY_OBJECT);
  const [showReadMore, setShowReadMore] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [readAnnouncement, { loading: MarkAsReadLoading }] = useMutation(
    MARK_AS_READ_ANNOUNCEMENT,
    {
      onCompleted(res) {
        location.goBack();
        const eventProperties = {
          'announcement id': res?.readAnnouncement?.announcement?.id,
          'announcement title': res?.readAnnouncement?.announcement?.title,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENTS.ANNOUNCEMENT_READ,
          eventProperties
        );
      },
    }
  );
  const { data: announcementData, loading: announcementLoading } = useQuery(
    GET_ANNOUNCEMENT_BY_ID,
    {
      variables: { where: { id } },
    }
  );

  const deleteAnnouncement = () => {
    const deleteConfirmation = async () => {
      try {
        await client
          .mutate({
            mutation: DELETE_ANNOUNCEMENT,
            variables: {
              where: { id },
            },
          })
          .then((res) => {
            message.destroy();
            message.success(
              intl.formatMessage({
                id: 'users.successMessages.deleteAnnouncement',
              })
            );
            const eventProperties = {
              Id: id,
              Title: announcement?.title,
            };
            createAmplitudeEvent(
              AMPLITUDE_EVENTS.ANNOUNCEMENT_DELETED,
              eventProperties
            );
            history.goBack();
          });
        // eslint-disable-next-line no-empty
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    };
    confirmationModal(
      intl.formatMessage({ id: 'announcements.sure' }),
      deleteConfirmation
    );
  };

  useEffect(() => {
    if (_has(announcementData, 'announcement')) {
      setAnnouncement(announcementData.announcement);
    }
  }, [announcementData]);

  useEffect(() => {
    if (announcement?.status === 'PUBLISHED') {
      client
        .query({
          query: IS_ANNOUNCEMENT_READ,
          fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
          variables: {
            where: { announcementId: id },
          },
        })
        .then(({ data }) => {
          setIsRead(data);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcement]);

  useEffect(() => {
    const node = document.getElementById('sop-content');
    if (node && node.clientHeight > 500) {
      setShowReadMore(true);
    }
  }, [announcement]);

  const handleMarkAsRead = () => {
    readAnnouncement({ variables: { where: { announcementId: id } } });
  };

  return (
    <Layout>
      <Header
        title={
          <ContentTitle>
            <Arrow
              src={BackArrow}
              alt="arrow"
              onClick={() => history.goBack()}
            />
            <InfoIcon title={announcement?.title} />
          </ContentTitle>
        }
      />
      <Spin spinning={isLoading}>
        <Content>
          <Card
            padding="16"
            className="card"
            title={
              <>
                <div className="title">
                  <Tag color="#9F9F9F">#{announcement?.sequenceNo}</Tag>
                  {announcement?.title}
                </div>
                <div className="description">{announcement?.description}</div>
                <div>
                  <GroupTagList limit={3} departments={announcement?.groups} />
                </div>
              </>
            }
            actions={[
              <Row justify="space-between" align="center">
                <Col>
                  {announcement?.status === 'PUBLISHED' &&
                    ability.can('manage', 'announcements') && (
                      <Button
                        type="text"
                        name="Delete"
                        className="template-button delete-title"
                        icon={<Image src={Trash} />}
                        onClick={() => {
                          deleteAnnouncement();
                        }}
                      >
                        Delete
                      </Button>
                    )}
                </Col>
                <Col>
                  {announcement?.status === 'PUBLISHED' &&
                    !isLoading &&
                    (isRead?.isAnnouncementRead ? (
                      <>
                        <Button
                          type="text"
                          icon={
                            <Image src={DoneAllOutline} className="done-all" />
                          }
                          className="template-button"
                          name="Read"
                          loading={MarkAsReadLoading}
                        />
                      </>
                    ) : (
                      <Button
                        type="text"
                        className="template-button mark-as-read"
                        link
                        name="Mark as Read"
                        loading={MarkAsReadLoading}
                        onClick={handleMarkAsRead}
                      />
                    ))}
                </Col>
              </Row>,
            ]}
          >
            <StyledBody showReadMore={showReadMore}>
              <div
                dangerouslySetInnerHTML={{
                  __html: serialize(announcement?.body),
                }}
                id="sop-content"
                className="announcement-p"
              />
              {showReadMore && (
                <div className="show-more-btn">
                  <Button
                    type="primary"
                    name="Read More"
                    onClick={() => setShowReadMore(false)}
                  />
                </div>
              )}
            </StyledBody>
          </Card>
        </Content>
      </Spin>
    </Layout>
  );
}
export default withRouter(injectIntl(AnnouncementDetails));
