/* eslint-disable indent */
import React from 'react';
import _map from 'lodash/map';
// import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import styled from 'styled-components';
import { Select as AntSelect, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ArrowIcon } from 'assets';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import UserProfile from 'molecules/UserProfile';
import { CloseCircleOutlined } from '@ant-design/icons';
import Spacings from 'design/Spacings';
const { Option } = AntSelect;
const Select = styled(AntSelect)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 34px;
  display: flex;
  min-width: 152px;
  max-width: 1000px;
  display: table-cell;
  word-wrap: break-word;
  word-break: break-word;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 14px;
    border: none;
    background-color: transparent;
  }
  .ant-select-selection-placeholder {
    opacity: 1;
  }
`;
export const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 2px;
  margin-top: -4px;
`;
export const Wrapper = styled.span`
  .ant-avatar {
    margin-top: calc(${Spacings.tiny.margin} - 1px);
  }
`;
export default function (props) {
  // const debounceJob = null;
  const {
    className,
    ref,
    onBlur,
    users,
    children,
    formItemName,
    required,
  } = props;
  // const userOptions = users
  //   && _map(users, (items, index) => (
  //     <Option key={index} value={parseInt(items.id, 10)}></Option>
  //   ));
  return (
    <Wrapper>
      <Form.Item
        className="form"
        name={formItemName}
        rules={[
          {
            required,
            message: <FormattedMessage id="me.formRules.groups.errorMessage" />,
          },
        ]}
      >
        <Select
          ref={ref && ref}
          onBlur={onBlur && onBlur}
          placeholder="Assign to"
          showArrow
          allowClear
          clearIcon={<CloseCircleOutlined />}
          suffixIcon={<Arrow src={ArrowIcon} />}
          dropdownStyle={{ minWidth: '247px', height: '216px' }}
          // showSearch
          className={className}
          filterOption={(input, option) => {
            const user = _get(option, 'children.props.data.user', '');
            return (
              user?.firstName?.toLowerCase().includes(input.toLowerCase()) ||
              user?.lastName?.toLowerCase().includes(input.toLowerCase()) ||
              user?.email?.toLowerCase().includes(input.toLowerCase())
            );
          }}
          getPopupContainer={(trigger) => trigger?.parentNode}
        >
          {children}
        </Select>
      </Form.Item>
    </Wrapper>
  );
}
