import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'atoms/Button';
import Spacings from 'design/Spacings';
import Colors from 'design/Colors';
import styled from 'styled-components';
import { Form } from 'antd';
import { BUTTON_TYPE } from 'utils/constants';
import { fonts } from 'design/Fonts';

export const StyledDiv = styled.div`
  text-align: right;
  margin-top: ${({ top }) => top || 0};
  .ant-btn-primary {
    margin-left: ${Spacings.xxSmall.margin};
  }
  .ant-btn {
    width: fit-content;
    font: ${fonts.xSmall.regular};
    line-height: 22px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const GroupButtons = ({
  buttonActive, discardChange, top, loading,
}) => (
  <StyledDiv top={top}>
    <Form.Item>
      <Button
        secondary
        disabled={buttonActive}
        onClick={discardChange}
        name={<FormattedMessage id="settings.discard" />}
      />
      <Button
        primary
        className="small"
        loading={loading}
        disabled={buttonActive}
        type={BUTTON_TYPE.PRIMARY}
        htmlType={BUTTON_TYPE.SUBMIT}
        name={<FormattedMessage id="settings.saveChanges" />}
      />
    </Form.Item>
  </StyledDiv>
);

export default GroupButtons;
