import React, { useEffect, useState } from 'react';
import { Tabs, Layout, Spin } from 'antd';
import { EMPTY_OBJECT } from 'utils/constants';
import Card from 'atoms/Card';
import InfoIcon from 'atoms/InfoIcon';
import Button from 'atoms/Button';
import { BackArrow } from 'assets';
import { useParams, withRouter } from 'react-router';
import { useQuery } from '@apollo/client';
import _has from 'lodash/has';
import GroupTagList from 'organisms/GroupTagList';
import Header from 'layout/Header';
import CommentsPopover from 'organisms/CommentsPopover';
import { serialize } from 'organisms/TextEditor';
import {
  Content, ContentTitle, Arrow, StyledBody,
} from './AntStyled';
import ActivityLog from './ActivityLog';
import { GET_SOP_BY_ID } from '../../graphql/Queries';

const { TabPane } = Tabs;

function SopDetails(props) {
  const { history } = props;
  const { id } = useParams();
  const [sop, setSop] = useState(EMPTY_OBJECT);
  const [showReadMore, setShowReadMore] = useState(false);
  const { data: sopData, loading: sopLoading } = useQuery(GET_SOP_BY_ID, {
    variables: { where: { id } },
  });

  useEffect(() => {
    if (_has(sopData, 'getSop')) {
      setSop(sopData.getSop);
    }
  }, [sopData]);

  useEffect(() => {
    const node = document.getElementById('sop-content');
    if (node && node.clientHeight > 500) {
      setShowReadMore(true);
    }
  }, [sop]);

  return (
    <Layout>
      <Header
        title={(
          <ContentTitle onClick={() => history.goBack()}>
            <Arrow src={BackArrow} alt="arrow" />
            <InfoIcon title={sop?.title} />
          </ContentTitle>
        )}
      />
      <Spin spinning={sopLoading}>
        <Content>
          <Card
            padding="16"
            className="card"
            title={(
              <div>
                <div className="title">
                  {sop.title}
                </div>
                <div className="description">
                  {sop.description}
                </div>
                <div>
                  <GroupTagList limit={5} departments={sop.departments} />
                </div>
              </div>
            )}
          >
            <StyledBody showReadMore={showReadMore}>
              <div dangerouslySetInnerHTML={{ __html: serialize(sop.body) }} id="sop-content" />
              {showReadMore && <div className="show-more-btn"><Button type="primary" name="Read More" onClick={() => setShowReadMore(false)} /></div>}
            </StyledBody>
          </Card>
          <Card padding="0" className="card tab">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Comments" key="1" className="comments">
                <CommentsPopover data={sop.comments} actionId={sop.id} commentableType="Sops" isSection />
              </TabPane>
              <TabPane tab="Activity log" key="2" className="log">
                <ActivityLog activityLogs={sop.activityLogs} />
              </TabPane>
            </Tabs>
          </Card>
        </Content>
      </Spin>
    </Layout>
  );
}

export default withRouter(SopDetails);
