import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ACTIONS_TYPE, META_TYPE, PRIVATE_ROUTE } from 'utils/constants';
import ability from 'utils/ability/ability';
import { FeatureActivation } from 'features/components/FeatureActivation';
import { AppContext } from 'AppContext';
import InfoIcon from 'atoms/InfoIcon';
import { FormattedMessage } from 'react-intl';
import { FeatureDeactivation } from 'features/components/FeatureDeactivation';
import AnnouncementSettings from './AnnouncementSettings';

const Announcement = () => {
  const [activated, setActivated] = useState(
    ability.can('use', 'announcements_feature')
  );
  const { dispatch } = useContext(AppContext);
  const Title = () => (
    <InfoIcon
      title={<FormattedMessage id="announcements.title" />}
      contentKey={META_TYPE.ANNOUNCEMENTS_SETTINGS}
    />
  );

  useEffect(() => {
    dispatch({ type: ACTIONS_TYPE.SETTING_TITLE, data: <Title /> });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!activated) {
    return (
      <div>
        <FeatureActivation
          featureKey="announcements_feature"
          setActivated={setActivated}
        />
      </div>
    );
  }

  return (
    <div>
      <FeatureDeactivation
        featureKey="announcements_feature"
        setActivated={setActivated}
      />
    </div>
  );
};

export default {
  component: withRouter(Announcement),
  path: PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.ANNOUNCEMENT_SETTINGS,
  key: 'announcements',
  abilityAction: 'manage',
  abilitySubject: 'announcements',
  icon: (
    <i data-eva="volume-up-outline" data-eva-height="20" data-eva-width="20"></i>
  ),
  text: <FormattedMessage id="announcements.sidebarTitle" />,
};
