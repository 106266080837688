import gql from 'graphql-tag';

export const GET_HELP = gql`
query helpBlocks($where: JSON){
  helpBlocks(where:$where){
    id
    key
    created_at
   name
    description
    help_category{
      id
      name
    }
    help_items{
      id
      title
      order
      description
      helpLink{
        ext
        url
      }
    }
  }
}
`;
