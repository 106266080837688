import React from 'react';
import styled from 'styled-components';
import { Tag as AntTag } from 'antd';
import Colors from 'design/Colors';

const StyledTag = styled(AntTag)`
  border-radius:4px;
  &.ant-tag {
    margin-right: ${({ right }) => right || 0}px;
  }
  .ant-tag {
    border-color: ${Colors.Border};
  }
`;

const Tag = ({
  color,
  className,
  content,
  onClick,
  right,
  text,
}) => (
  <StyledTag
    className={className}
    color={color}
    onClick={onClick}
    right={right}
    text={text}
  >
    {content}
  </StyledTag>
);

export default Tag;
