import { Typography } from 'antd';
import DateTime from 'atoms/DateTime';
import Colors from 'design/Colors';
import UserProfile from 'molecules/UserProfile';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .user-profile{
    display: flex;
    align-items: center;
  }
  padding: 16px 0;
  border-bottom: 1px solid ${Colors.BorderBottom};
  :first-child{
    padding-top: unset;
  }
  :last-child{
    padding-bottom: unset;
    border-bottom: none;
  }
  .activity{
   
  }
`;

export default function ActivityLog({ activityLogs }) {
  return (
    <div className="activity">
      {
        activityLogs.map(log => (
          <Wrapper>
            <div className="user-profile">
              <UserProfile
                name={`${log.triggerBy.firstName} ${log.triggerBy.lastName}`}
                stackedName={false}
              />
              <Typography.Text>
                {log.description}
              </Typography.Text>
            </div>
            <DateTime
              size="medium"
              date={log.createdAt}
              format="DD/MM/YYYY hh:mm:ss"
            />
          </Wrapper>
        ))
      }
    </div>
  );
}
