import { Layout } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import Spacings from 'design/Spacings';
import { scrollbars } from 'design/Styled';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Variables from 'design/Variables';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  &.ant-layout-content {
    padding: ${Spacings.medium.padding};
    overflow: auto;
    ${scrollbars};
  }
  & > div:not(:last-child) {
    margin-bottom: ${Spacings.medium.margin};
  }
`;

export const Close = styled.img`
  cursor: pointer;
  margin-left: calc(${Spacings.xSmall.margin} + 2px);
  display: flex;
  align-items: center;
`;
export const Arrow = styled.div``;

export const GlobalStyles = createGlobalStyle`
  body {
    .styled-ant-date-picker-popup .ant-picker-panel-container {
      border-radius: ${Variables.AtomRadius};
    }
    .styled-ant-date-picker-popup .ant-picker-cell-inner {
      border-radius: ${Variables.AtomRadius};
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-radius: ${Variables.AtomRadius};
    }
    .styled-ant-date-picker-popup .ant-tag {
      background: transparent;
      border: none;
      font: ${fonts.small.medium};
      color: ${Colors.PrimaryColor};
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner{
      border-radius: 6px;
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
      border-radius: 6px;
    }
    .ant-picker-cell .ant-picker-cell-inner {
      color: #595959;
    }
    .ant-picker-cell {
      font: ${fonts.xSmall.regular};
    }
    .ant-picker-header button {
      font: ${fonts.xSmall.bold};
    }
  }
`;

export const StyledDiv = styled.div`
  .styled-ant-date-picker {
    border-radius: ${Variables.AtomRadius};
    height: 34px;
    border: 1px solid ${Colors.Border};
  }
  .styled-ant-date-picker .ant-picker-input input {
    width: 90px;
    font: ${fonts.small.regular};
    color: ${Colors.Text};
  }

  .styled-ant-date-picker .styled-ant-date-picker-popup {
    border-radius: ${Variables.RadiusMd};
  }
  .close-icon {
    height: 12px;
    path {
      fill: ${Colors.Text};
    }
  }
`;
