import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { Layout, message } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import {
  Router, Switch, Route, withRouter,
} from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import client from 'apollo';
import styled from 'styled-components';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';

import Colors from 'design/Colors';
import Meta from 'atoms/Meta';
import Button from 'atoms/Button';
import Filters from 'molecules/Filters';
import Header from 'layout/Header';
import Spacings from 'design/Spacings';
import InfoIcon from 'atoms/InfoIcon';
import {
  PRIVATE_ROUTE,
  META_TYPE,
  FETCH_POLICY,
  EMPTY_ARRAY,
  UNDEFINED,
  TASK_TYPE,
  USER_ACTION,
  PAGE_LIMIT,
} from 'utils/constants';
import { DAILY_GOALS_AND_BLOCKERS } from 'features/dailyGoals/graphql/DailyGoalsAndBlockers/Queries';
import { UPDATE_TASK } from 'features/dailyGoals/graphql/Me/Mutations';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { fonts } from 'design/Fonts';
import _findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import { AppContext } from 'AppContext';
import Page404 from 'pages/404';
import DailyGoals from '../../templates/DailyGoals';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = styled.div`
  margin-left: ${Spacings.xxSmall.margin};
  .tab {
    width: 100px;
    height: 34px;
    font-size: ${fonts.small.size};
    padding: 0px ${Spacings.xxSmall.padding};
    box-sizing: border-box;
    border-radius: 6px 0px 0px 6px;

    &:nth-child(2) {
      border-radius: 0px 6px 6px 0px;
    }
    &:hover {
      color: ${Colors.TextPrimaryHover};
      border-color: ${Colors.TextPrimaryHover};
    }
    @media screen and (min-width: 1100px) {
      width: 128px;
    }
  }
  & .tab.active {
    font-weight: 600;
  }
`;

const DailyGoalsPage = (props) => {
  const { history, intl } = props;
  const { id, d } = queryString.parse(history.location.search);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [activeDailyGoals, setActiveDailyGoals] = useState(EMPTY_ARRAY);
  const [archivedDailyGoals, setArchivedDailyGoals] = useState(EMPTY_ARRAY);
  const [groupId, setGroupId] = useState(id ? [parseInt(id, 10)] : EMPTY_ARRAY);
  const [userId, setUserId] = useState(UNDEFINED);
  const [locationId, setLocationId] = useState();
  const [date, setDate] = useState(
    d
      ? [moment(d, 'MM-DD-YYYY'), moment(d, 'MM-DD-YYYY')]
      : [moment().startOf('isoWeek'), moment().endOf('isoWeek')]
  );
  const [sortData, setSortData] = useState({
    field: 'createdAt',
    type: 'DESC',
  });
  const [isTaskCompleted, setIsTaskCompleted] = useState();
  const [selectedGoalsType, setSelectedGoalsType] = useState('all');
  const [tableLoader, setTableLoader] = useState(false);
  const [dataCount, setDataCount] = useState();
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const {
    state: { loginUser },
  } = useContext(AppContext);

  const { data: dailyGoalsData, loading: dailyGoalsLoading } = useQuery(
    DAILY_GOALS_AND_BLOCKERS,
    {
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      variables: {
        where: {
          locationId,
          groupId: groupId.length !== 0 ? groupId : UNDEFINED,
          ownerUserId: userId && userId.length > 0 ? userId : UNDEFINED,
          type: TASK_TYPE.PLAN,
          from:
            date && date.length > 0
              ? date[0].format('YYYY-MM-DD')
              : moment().startOf('isoWeek').format('YYYY-MM-DD'),
          to:
            date && date.length > 0
              ? date[1].format('YYYY-MM-DD')
              : moment().endOf('isoWeek').format('YYYY-MM-DD'),
          isCompleted: isTaskCompleted,
          isArchive: activeTab !== 0,
        },
        sortBy: sortData.type,
        sortOn: sortData.field,
        skip,
        limit: PAGE_LIMIT,
      },
    }
  );

  useEffect(() => {
    if (!dailyGoalsLoading && dailyGoalsData) {
      const dailyGoalsTableData = _map(
        dailyGoalsData.dailyGoalsAndBlockers.actions,
        (data, key) => ({
          key: key.toString(),
          id: data.id,
          isCompleted: data.isCompleted,
          completedOn: data.completedOn,
          name: data.name,
          estimation: data.estimation,
          completionHours: data.completionHours,
          group: data.group,
          user: data.owner,
          comments: data.comments ? data.comments : [],
          isArchive: data.isArchive,
          reason: data.reason,
          createdAt: data.createdAt,
          canArchive: data.canArchive,
        })
      );
      if (dailyGoalsData.dailyGoalsAndBlockers.actions.length === 0) {
        setHasMore(false);
        return;
      }
      const archiveFilters = _groupBy(dailyGoalsTableData, 'isArchive');
      setActiveDailyGoals([
        ...activeDailyGoals,
        ...(archiveFilters.false || EMPTY_ARRAY),
      ]);
      setArchivedDailyGoals([
        ...archivedDailyGoals,
        ...(archiveFilters.true || EMPTY_ARRAY),
      ]);
      setDataCount(dailyGoalsData.dailyGoalsAndBlockers.count);
      setLoader(false);
      setTableLoader(false);
      if (dailyGoalsData.dailyGoalsAndBlockers.count <= PAGE_LIMIT) {
        setHasMore(false);
      }
    }
  }, [dailyGoalsData, dailyGoalsLoading]);

  const tabs = [
    <FormattedMessage id="dailyGoals.active" />,
    <FormattedMessage id="dailyGoals.archived" />,
  ];

  const Title = () => (
    <Wrapper>
      <InfoIcon title={<FormattedMessage id="dailyGoals.text" />} />
      <Tabs>
        {_map(tabs, (list, index) => (
          <Button
            key={index}
            keyProp={index}
            className={`tab ${index === activeTab ? 'active' : ''}`}
            background={Colors.TextLight}
            border={
              index === activeTab ? Colors.PrimaryColor : Colors.SecondaryColor
            }
            color={
              index === activeTab ? Colors.PrimaryColor : Colors.TextSecondary
            }
            focus={Colors.TextLight}
            name={list}
            onClick={() => callback(index)}
          />
        ))}
      </Tabs>
    </Wrapper>
  );

  useEffect(() => {
    const { pathname } = history.location;
    if (pathname.split('/')[2] === PRIVATE_ROUTE.KEY.ACTIVE) {
      setActiveTab(0);
    } else if (pathname.split('/')[2] === PRIVATE_ROUTE.KEY.ARCHIVED) {
      setActiveTab(1);
    }
  }, [history, history.location]);

  const callback = (key) => {
    setActiveTab(key);
    setTableLoader(true);
    resetDailyGoalsData();
    if (key === 0) {
      history.push(PRIVATE_ROUTE.DAILY_GOALS_ACTIVE);
    } else {
      history.push(PRIVATE_ROUTE.DAILY_GOALS_ARCHIVED);
    }
    setHasMore(true);
  };

  const onActions = async (rowData, list) => {
    setLoading(true);
    const goalIndex = _findIndex(
      list === USER_ACTION.ARCHIVE_TASK ? activeDailyGoals : archivedDailyGoals,
      { id: rowData.id }
    );
    const tempDailyGoals = cloneDeep(
      list === USER_ACTION.ARCHIVE_TASK ? activeDailyGoals : archivedDailyGoals
    );
    tempDailyGoals[goalIndex] = { loading: true, id: rowData.id };
    list === USER_ACTION.ARCHIVE_TASK
      ? setActiveDailyGoals([...tempDailyGoals])
      : setArchivedDailyGoals([...tempDailyGoals]);
    try {
      client
        .mutate({
          mutation: UPDATE_TASK,
          variables: {
            data: [
              {
                where: {
                  id: parseInt(rowData.id, 10),
                },
                input: {
                  isArchive: list === USER_ACTION.ARCHIVE_TASK,
                },
              },
            ],
          },
        })
        .then(() => {
          tempDailyGoals.splice(goalIndex, 1);
          list === USER_ACTION.ARCHIVE_TASK
            ? setActiveDailyGoals([...tempDailyGoals])
            : setArchivedDailyGoals([...tempDailyGoals]);
          setLoading(false);
        });
    } catch (e) {
      message.destroy();
      message.error(intl.formatMessage({ id: 'error.somethingWentWrong' }));
      setLoading(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const resetDailyGoalsData = () => {
    setActiveDailyGoals([]);
    setArchivedDailyGoals([]);
    setHasMore(true);
    setPage(1);
    setSkip(0);
  };

  const onChangeDateFilter = (e) => {
    e && e.preventDefault();
    resetDailyGoalsData();
  };

  const handleTaskTypeChange = () => {
    resetDailyGoalsData();
  };

  const handleGroupOrUserFilterChange = () => {
    resetDailyGoalsData();
  };

  const handleInfiniteOnLoad = () => {
    setLoader(true);
    const dataLength = activeTab === 0 ? activeDailyGoals.length : archivedDailyGoals.length;
    if (dataCount <= dataLength) {
      setHasMore(false);
      setLoader(false);
      setPage(1);
    } else {
      setPage(prev => prev + 1);
      const count = page * PAGE_LIMIT;
      setSkip(count);
    }
  };

  return (
    <Sentry.ErrorBoundary fallback="Something went wrong">
      <Layout>
        <Router history={history}>
          <Meta title={META_TYPE.PROFILE} />
          {activeTab !== null && (
            <>
              <Header title={<Title />} />
              <Filters
                masterFilter
                daysFilter
                groupsFilters={setGroupId}
                locationFilters={setLocationId}
                setDateFilterValue={setDate}
                dateFilterValue={date}
                groupValue={groupId}
                setUsersValue={setUserId}
                showUsersFilter
                usersValue={userId}
                showTaskFilter
                setSelectedGoalsType={setSelectedGoalsType}
                selectedGoalsType={selectedGoalsType}
                onChangeDateFilter={onChangeDateFilter}
                handleTaskTypeChange={handleTaskTypeChange}
                handleGroupOrUserFilterChange={handleGroupOrUserFilterChange}
              />
            </>
          )}
          <Switch>
            <Route
              exact
              path={PRIVATE_ROUTE.DAILY_GOALS_ACTIVE}
              render={() => (
                <DailyGoals
                  loading={
                    activeDailyGoals.length ? tableLoader : dailyGoalsLoading
                  }
                  dailyGoals={activeDailyGoals}
                  onActions={onActions}
                  archive={activeTab === 0}
                  setSortData={setSortData}
                  hasMore={hasMore}
                  loader={loader}
                  handleInfiniteOnLoad={handleInfiniteOnLoad}
                  loginUser={loginUser}
                  handleGroupOrUserFilterChange={handleGroupOrUserFilterChange}
                  dataCount={dataCount}
                />
              )}
            />
            <Route

              path={PRIVATE_ROUTE.DAILY_GOALS_ARCHIVED}
              render={() => (
                <DailyGoals
                  loading={
                    archivedDailyGoals.length ? tableLoader : dailyGoalsLoading
                  }
                  dailyGoals={archivedDailyGoals}
                  onActions={onActions}
                  archive={activeTab === 0}
                  showReason
                  setSortData={setSortData}
                  hasMore={hasMore}
                  loader={loader}
                  handleInfiniteOnLoad={handleInfiniteOnLoad}
                  loginUser={loginUser}
                  handleGroupOrUserFilterChange={handleGroupOrUserFilterChange}
                  dataCount={dataCount}
                />
              )}
            />
            <Route component={Page404} />
          </Switch>
        </Router>
      </Layout>
    </Sentry.ErrorBoundary>
  );
};

export default {
  key: PRIVATE_ROUTE.DAILY_GOALS_ACTIVE,
  component: withRouter(DailyGoalsPage),
  name: 'Team Goals',
  icon: <i data-eva="bar-chart-outline" data-eva-height="20" data-eva-width="20"></i>,
};
