import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import client from 'apollo';
import Meta from 'atoms/Meta';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import Input from 'atoms/Input';
import {
  FORM_NAME,
  BUTTON_TYPE,
  PUBLIC_ROUTE,
  META_TYPE,
} from 'utils/constants';
import { UserIcon, UndifferLogo } from 'assets';
import { SEND_RESET_PASSWORD } from 'graphql/Onboarding/Mutations';
import * as Sentry from '@sentry/react';
import { getTrimValues } from 'utils';
import {
  Container,
  Description,
  Title,
  InputIcon,
  Logo,
  Background,
  Wrapper,
  Form,
} from './AntStyled';

export default function (props) {
  const { history } = props;

  const onFinish = async (values) => {
    const trimValues = getTrimValues(values);
    const { email } = trimValues;
    try {
      await client.query({
        query: SEND_RESET_PASSWORD,
        variables: {
          email: email.toLowerCase(),
        },
      });
      history.push({
        pathname: PUBLIC_ROUTE.CONFIRM,
        state: {
          route: PUBLIC_ROUTE.KEY.FORGOT_PASSWORD,
          email: email.toLowerCase(),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Background>
        <Logo src={UndifferLogo} alt="logo"></Logo>
        <NavLink to="/help" className="bottom-right">
          <FormattedMessage id="terms.help" />
        </NavLink>
        <NavLink to="/copyright" className="bottom-left">
          <FormattedMessage id="terms.copyright" />
        </NavLink>
      </Background>
      <Wrapper>
        <Card padding="36px 36px 24" className="small">
          <Meta title={META_TYPE.FORGOT_PASSWORD} />
          <Form
            name={FORM_NAME.BASIC}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Title>
              <FormattedMessage id="forgotPassword.title" />
            </Title>
            <Form.Item
              className="email"
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="forgotPassword.formRules.email.errorMessage" />
                  ),
                },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/g,
                  message: (
                    <FormattedMessage id="forgotPassword.formRules.email.emailPattern" />
                  ),
                },
              ]}
            >
              <Input
                suffix={<InputIcon src={UserIcon} />}
                placeholder="Email"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                accent
                className="right"
                type={BUTTON_TYPE.PRIMARY}
                htmlType={BUTTON_TYPE.SUBMIT}
                name={<FormattedMessage id="forgotPassword.send" />}
              />
            </Form.Item>
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
}
