import { Layout as AntLayout, Popover } from 'antd';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { x } from 'design/Styled';
import Spacings from 'design/Spacings';
const { Header: AntHeader } = AntLayout;

export const HeaderWrapper = styled(AntHeader)`
  ${x};
  height: 48px;
  justify-content: space-between;
  align-items: center;
  background: ${Colors.Container};
  padding: 8px 24px;
  border-bottom: 1px solid ${Colors.SidebarBorder};
  z-index: 9;
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  .check-in-clock {
    font: ${fonts.xSmall.regular};
  }
  .ant-checkbox-group-item {
    margin-bottom: ${Spacings.xSmall.margin};
    color: ${Colors.Text};
    font: ${fonts.xSmall.regular};
  }
  .ant-checkbox-group-item:last-child {
    margin-bottom: 0;
  }
  .ant-divider-horizontal {
    margin: 12px 0px;
  }
  .icon-bell {
    margin: 0 ${Spacings.xSmall.margin};
    margin-right: 6px;
    font: ${fonts.xxLarge.regular};
  }
  .title-content {
    max-width: 50%;
  }
`;
export const Arrow = styled(SVG)`
  width: 9px;
  & path {
    fill: ${({ color }) => color};
  }
`;
export const Bell = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: ${({ color }) => color || Colors.SuccessGreen};
    border: 0;
    align-items: center;
    justify-self: flex-end;
  }
  &.ant-select-single .ant-select-selection-item {
    border: 1px solid ${Colors.Text};
    background: ${Colors.Transparent};
  }
`;

export const TagContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ color }) =>
    color
      ? `1px solid ${Colors.SecondaryColor}`
      : `1px solid ${Colors.SecondaryColor}`};
  height: 32px;
  font: ${fonts.small.regular};
  border-radius: 6px;
`;

export const Clock = styled.div`
  display: flex;
  align-items: center;
  color: ${({ color }) => color || Colors.SuccessGreen};
  border-right: 1px solid ${Colors.SecondaryColor};
  padding: 0 10px;
  height: 100%;
  .anticon {
    margin-right: 6px;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ color }) => color || 'initial'};
  padding: 0 10px;
  & .status {
    margin-right: 8px;
  }
`;

export const StyledPopover = styled(Popover)`
  .ant-popover-inner {
    filter: drop-shadow(0px 25px 50px rgba(81, 89, 131, 0.25));
    border: 1px solid ${Colors.Border};
  }
  .ant-popover-arrow {
    border-top-color: ${Colors.Border}!important;
    border-left-color: ${Colors.Border}!important;
    border-width: 1px;
    z-index: 1;
    background: #fff;
  }
  .ant-popover-inner-content {
    padding: 10px;
  }
  .ant-menu-inline {
    border: none;
  }
  .ant-menu-inline .ant-menu-item::after {
    border: none;
  }
  .ant-menu li {
    margin: 0 !important;
    padding: 0px 10px !important;
    min-width: 180px;
    border-radius: 4px;
    color: ${Colors.Text};
    display: flex;
    align-items: center;
    justify-content: space-between;
    & img {
      display: none;
    }
  }
  .ant-menu li.ant-menu-item-selected {
    font-weight: 600;
    & img {
      display: inline-block;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${Colors.DefaultBackground};
  }
`;
