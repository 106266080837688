/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import { Layout, message, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import _get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { AppContext } from 'AppContext';

import Meta from 'atoms/Meta';
import Header from 'layout/Header';
import {
  META_TYPE,
  FETCH_POLICY,
  EMPTY_ARRAY,
  TASK_TYPE,
  TASK_BLOCKER_TYPE,
  PRIVATE_ROUTE,
} from 'utils/constants';
import InfoIcon from 'atoms/InfoIcon';
import Tasks from 'features/dailyGoals/templates/TasksDetails/Tasks';
import MyBlockers from 'features/dailyGoals/templates/TasksDetails/MyBlockers';
import {
  GET_MY_TASKS,
  GET_MY_BLOCKERS_TO_REMOVE,
  GET_MY_BLOCKERS,
  GET_MY_TASKS_AND_BLOCKERS,
} from 'features/dailyGoals/graphql/Me/Queries';
import {
  CREATE_TASK,
  UPDATE_TASK,
} from 'features/dailyGoals/graphql/Me/Mutations';
import Blockers from 'features/dailyGoals/templates/TasksDetails/Blockers';

import client from 'apollo';
import * as Sentry from '@sentry/react';
import { getTrimValues } from 'utils';
import _findIndex from 'lodash/findIndex';
import ContentWrapper from 'atoms/ContentWrapper';
import { withRouter } from 'react-router';
import { Content } from './AntStyled';

const Me = ({ intl }) => {
  const { state } = useContext(AppContext);
  const userId = _get(state, 'loginUser.id', null);
  const [sortTaskData, setSortTaskData] = useState({
    field: 'createdAt',
    type: 'DESC',
  });
  const [sortBlockerData, setSortBlockerData] = useState({
    field: 'createdAt',
    type: 'DESC',
    blockerType: '',
  });

  const {
    data: myTasksAndBlockers,
    refetch: refetchMyTasksAndBlockers,
    loading: tasksAndBlockersLoading,
  } = useQuery(GET_MY_TASKS_AND_BLOCKERS, {
    variables: {
      where: { createdAt: moment().format('YYYY-MM-DD') },
      sortBy: sortTaskData.type,
      sortOn: sortTaskData.field,
    },
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
  });

  const [dailyGoals, setDailyGoals] = useState(EMPTY_ARRAY);
  const [blockers, setBlockers] = useState(EMPTY_ARRAY);
  const [blockersToRemove, setBlockersToRemove] = useState(EMPTY_ARRAY);
  const dailyGoalsRef = useRef(dailyGoals);
  const blockersRef = useRef(blockers);
  const blockersRemoveRef = useRef(blockersToRemove);
  const [updateTaskLoading, setUpdateTaskLoading] = useState(false);
  const [updateBlockerLoading, setUpdateBlockerLoading] = useState(false);
  const [updateBlockerAssignLoading, setUpdateBlockerAssignLoading] = useState(
    false
  );

  useEffect(() => {
    if (myTasksAndBlockers && myTasksAndBlockers.myProfileActions) {
      setDailyGoals(
        myTasksAndBlockers.myProfileActions.dailyGoals || EMPTY_ARRAY
      );
      if (
        sortBlockerData.blockerType === ''
        || sortBlockerData.blockerType === 'blocker'
      ) {
        setBlockers(
          myTasksAndBlockers.myProfileActions.blockers || EMPTY_ARRAY
        );
      }
      if (
        sortBlockerData.blockerType === ''
        || sortBlockerData.blockerType === 'assigned'
      ) {
        setBlockersToRemove(
          myTasksAndBlockers.myProfileActions.blockersToRemove || EMPTY_ARRAY
        );
      }
    }
  }, [
    tasksAndBlockersLoading,
    myTasksAndBlockers,
    sortBlockerData.blockerType,
  ]);

  useEffect(() => {
    dailyGoalsRef.current = dailyGoals;
  }, [dailyGoals]);

  useEffect(() => {
    blockersRef.current = blockers;
  }, [blockers]);

  useEffect(() => {
    blockersRemoveRef.current = blockersToRemove;
  }, [blockersToRemove]);

  const createNew = async (values, type) => {
    const timeStamp = new Date().getTime();
    const isPlan = type === 'PLAN';
    // eslint-disable-next-line no-unused-expressions
    isPlan
      ? setDailyGoals(goals => [...goals, { loading: true, timeStamp }])
      : setBlockers(blocker => [...blocker, { loading: true, timeStamp }]);

    const {
      name, estimation, groups, isChecked, user,
    } = values;
    const data = {
      name,
      type,
      groupId: parseInt(JSON.parse(groups).id, 10),
    };
    data.estimation = parseFloat(estimation);
    if (isPlan) {
      data.userId = userId && parseInt(userId, 10);
    } else {
      data.isCompleted = isChecked;
      data.userId = user && parseInt(JSON.parse(user).id, 10);
    }
    client
      .mutate({
        mutation: CREATE_TASK,
        variables: {
          data: [data],
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_MY_TASKS_AND_BLOCKERS,
            variables: {
              where: { createdAt: moment().format('YYYY-MM-DD') },
              sortBy: sortBlockerData.type,
              sortOn: sortBlockerData.field,
            },
            fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
          },
        ],
      })
      .then((res) => {
        const temp = cloneDeep(
          isPlan ? dailyGoalsRef.current : blockersRef.current
        );
        const index = temp.findIndex(row => row.timeStamp === timeStamp);
        temp[index] = _get(res, 'data.createActions[0]');
        // eslint-disable-next-line no-unused-expressions
        isPlan ? setDailyGoals([...temp]) : setBlockers([...temp]);
      })
      .catch((e) => {
        const temp = cloneDeep(
          isPlan ? dailyGoalsRef.current : blockersRef.current
        );
        const index = temp.findIndex(row => row.timeStamp === timeStamp);
        temp.splice(index, 1);
        // eslint-disable-next-line no-unused-expressions
        isPlan ? setDailyGoals([...temp]) : setBlockers([...temp]);
        message.destroy();
        message.error(intl.formatMessage({ id: 'me.failed' }));
        Sentry.captureException(e);
        console.log(e);
      });
  };

  const handleCheckbox = async (value, id, type = '') => {
    const isPlan = type === 'PLAN';
    const temp = cloneDeep(
      isPlan ? dailyGoalsRef.current : blockersRef.current
    );
    const index = _findIndex(temp, { id });
    const oldData = temp[index];
    temp[index] = {
      ...oldData,
      isCompleted: value,
      completedOn: value ? new Date().toISOString() : null,
      completionHours: !value && null,
    };
    isPlan ? setDailyGoals([...temp]) : setBlockers([...temp]);
    client
      .mutate({
        mutation: UPDATE_TASK,
        variables: {
          data: [
            {
              where: {
                id: parseInt(id, 10),
              },
              input: {
                isCompleted: value,
                completedOn: value ? new Date().toISOString() : null,
                completionHours: !value && null,
              },
            },
          ],
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_MY_TASKS_AND_BLOCKERS,
            variables: {
              where: { createdAt: moment().format('YYYY-MM-DD') },
              sortBy: sortBlockerData.type,
              sortOn: sortBlockerData.field,
            },
            fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
          },
        ],
      })
      .then(() => {
        if (!isPlan) {
          refetchBlockersToRemove();
        }
      })
      .catch((e) => {
        const currentData = cloneDeep(
          isPlan ? dailyGoalsRef.current : blockersRef.current
        );
        const oldIndex = _findIndex(currentData, { id });
        currentData[oldIndex] = oldData;
        isPlan
          ? setDailyGoals([...currentData])
          : setBlockers([...currentData]);
        Sentry.captureException(e);
        console.log(e);
      });
  };

  const updateTask = async (data, type) => {
    const isPlan = type;
    const temp = cloneDeep(
      isPlan === 'PLAN'
        ? dailyGoalsRef.current
        : isPlan === 'BLOCKER'
          ? blockersRef.current
          : blockersRemoveRef.current
    );
    const index = _findIndex(temp, { id: data.id });
    const oldData = temp[index];
    const trimValues = getTrimValues(data);
    const {
      name,
      estimation,
      group,
      id,
      user,
      completionHours,
      isCompleted,
      completedOn,
      from,
    } = trimValues;

    if (isPlan === 'PLAN') {
      const tempObj = {
        ...temp[index],
        name,
        estimation: parseFloat(estimation),
        group: group && JSON.parse(group),
        completionHours,
        isCompleted,
        completedOn,
      };
      temp[index] = tempObj;
    } else if (isPlan === 'BLOCKER') {
      const tempObj = {
        ...temp[index],
        name,
        estimation: estimation && parseFloat(estimation),
        user: user && JSON.parse(user),
        group: group && JSON.parse(group),
        completionHours,
        isCompleted,
        completedOn,
      };
      temp[index] = tempObj;
    } else {
      const tempObj = {
        ...temp[index],
        name,
        estimation: estimation && parseFloat(estimation),
        from: from && JSON.parse(from),
        group: group && JSON.parse(group),
        completionHours,
        isCompleted,
      };
      temp[index] = tempObj;
    }

    isPlan === 'PLAN'
      ? setDailyGoals([...temp])
      : isPlan === 'BLOCKER'
        ? setBlockers([...temp])
        : setBlockersToRemove([...temp]);
    const input = {
      name,
      estimation: estimation && parseFloat(estimation),
      isCompleted,
      completedOn: !completedOn && isCompleted ? new Date() : null,
    };
    if (group && group !== 'null') {
      input.groupId = parseInt(JSON.parse(group).id, 10);
    }
    if (user && user !== 'null') {
      input.userId = parseInt(JSON.parse(user).id, 10);
    }
    if (completionHours) {
      input.completionHours = completionHours;
    }
    return client
      .mutate({
        mutation: UPDATE_TASK,
        variables: {
          data: [
            {
              where: {
                id: parseInt(id, 10),
              },
              input,
            },
          ],
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_MY_TASKS_AND_BLOCKERS,
            variables: {
              where: { createdAt: moment().format('YYYY-MM-DD') },
              sortBy: sortBlockerData.type,
              sortOn: sortBlockerData.field,
            },
            fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
          },
        ],
      })
      .then(() => {})
      .catch((e) => {
        const currentData = cloneDeep(
          isPlan === 'PLAN'
            ? dailyGoalsRef.current
            : isPlan === 'BLOCKER'
              ? blockersRef.current
              : blockersRemoveRef.current
        );
        const oldIndex = _findIndex(currentData, { id: data.id });
        currentData[oldIndex] = oldData;
        isPlan === 'PLAN'
          ? setDailyGoals([...temp])
          : isPlan === 'BLOCKER'
            ? setBlockers([...temp])
            : setBlockersToRemove([...temp]);
        Sentry.captureException(e);
        console.log(e);
      });
  };

  const archiveTask = async ({ reason }, id, type = '') => {
    try {
      return client
        .mutate({
          mutation: UPDATE_TASK,
          variables: {
            data: [
              {
                where: {
                  id: parseInt(id, 10),
                },
                input: {
                  isArchive: true,
                  reason,
                },
              },
            ],
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_MY_TASKS_AND_BLOCKERS,
              variables: {
                where: {
                  createdAt: moment().format('YYYY-MM-DD'),
                },
                sortBy:
                  type === TASK_TYPE.PLAN
                    ? sortTaskData.type
                    : sortBlockerData.type,
                sortOn:
                  type === TASK_TYPE.PLAN
                    ? sortTaskData.field
                    : sortBlockerData.field,
              },
              fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
            },
          ],
        })
        .then((data) => {
          const action = _get(data, 'data.updateActions[0]');
          const isPlan = action.type === TASK_TYPE.PLAN;
          const temp = cloneDeep(
            isPlan ? dailyGoalsRef.current : blockersRef.current
          );
          const index = temp.findIndex(row => row.id === action.id);
          temp.splice(index, 1);
          // eslint-disable-next-line no-unused-expressions
          isPlan ? setDailyGoals([...temp]) : setBlockers([...temp]);
          if (!isPlan) {
            refetchBlockersToRemove();
          }
        });
    } catch (e) {
      message.destroy();
      message.error(intl.formatMessage({ id: 'me.failed' }));
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const refetchBlockersToRemove = () => {
    client
      .query({
        query: GET_MY_BLOCKERS_TO_REMOVE,
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
        variables: {
          where: { createdAt: moment().format('YYYY-MM-DD') },
        },
      })
      .then((data) => {
        if (data && data.data && data.data.myProfileActions) {
          setBlockersToRemove(data.data.myProfileActions.blockersToRemove);
        }
      })
      .catch((e) => {
        message.destroy();
        message.error(intl.formatMessage({ id: 'me.failed' }));
        Sentry.captureException(e);
        console.log(e);
      });
  };

  const refetchBlockersAndBlockersAssigned = () => {
    client
      .query({
        query: GET_MY_TASKS_AND_BLOCKERS,
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
        variables: {
          where: { createdAt: moment().format('YYYY-MM-DD') },
          sortBy: sortBlockerData.type,
          sortOn: sortBlockerData.field,
        },
      })
      .then((data) => {
        if (data && data.data && data.data.myProfileActions) {
          if (
            sortBlockerData.blockerType === ''
            || sortBlockerData.blockerType === 'blocker'
          ) {
            setBlockers(data.data.myProfileActions.blockers);
          }
          if (
            sortBlockerData.blockerType === ''
            || sortBlockerData.blockerType === 'assigned'
          ) {
            setBlockersToRemove(data.data.myProfileActions.blockersToRemove);
          }
        }
      })
      .catch((e) => {
        message.destroy();
        message.error(intl.formatMessage({ id: 'me.failed' }));
        Sentry.captureException(e);
        console.log(e);
      });
  };

  return (
    <Sentry.ErrorBoundary fallback="Something went wrong">
      <Layout>
        <Meta title={META_TYPE.ME} />
        <Header
          refetchMyTasks={refetchMyTasksAndBlockers}
          refetchMyBlockers={refetchMyTasksAndBlockers}
          title={
            <InfoIcon helpKey="ME" title={<FormattedMessage id="me.text" />} />
          }
        />
        <ContentWrapper>
          <Content>
            <Tasks
              groupLoading={updateTaskLoading}
              loading={!dailyGoals.length && tasksAndBlockersLoading}
              title={<FormattedMessage id="me.dailyGoals" />}
              data={dailyGoals}
              onCreate={createNew}
              handleCheckbox={handleCheckbox}
              updateTask={updateTask}
              archiveTask={archiveTask}
              allowModify
              setSortData={setSortTaskData}
              taskState={TASK_BLOCKER_TYPE}
            />
            <MyBlockers
              groupLoading={updateBlockerLoading}
              userId={userId}
              loading={!blockers.length && tasksAndBlockersLoading}
              data={blockers}
              onCreate={createNew}
              handleCheckbox={handleCheckbox}
              updateTask={updateTask}
              archiveTask={archiveTask}
              allowModify
              setSortData={setSortBlockerData}
              blockerState={TASK_BLOCKER_TYPE}
            />
            <Blockers
              groupLoading={updateBlockerAssignLoading}
              loading={!blockersToRemove.length && tasksAndBlockersLoading}
              data={blockersToRemove}
              handleCheckbox={handleCheckbox}
              updateTask={updateTask}
              allowModify
              allowAssign
              setSortData={setSortBlockerData}
              blockerAssignedState={TASK_BLOCKER_TYPE}
            />
          </Content>
        </ContentWrapper>
      </Layout>
    </Sentry.ErrorBoundary>
  );
};

export default {
  key: PRIVATE_ROUTE.ME,
  component: withRouter(Me),
  name: 'Me',
  icon: (
    <i
      data-eva="bar-chart-outline"
      data-eva-height="20"
      data-eva-width="20"
    >
    </i>
  ),
};
