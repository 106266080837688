/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Header from 'layout/Header';
import { Layout, Col, Row, Checkbox } from 'antd';
import UserProfile from 'molecules/UserProfile';
import DateTime from 'atoms/DateTime';
import Filters from 'molecules/Filters';
import Table from 'atoms/Table';
import _map from 'lodash/map';
import * as eva from 'eva-icons';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import {
  EMOJIS,
  EMPTY_ARRAY,
  FETCH_POLICY,
  META_TYPE,
  PRIVATE_ROUTE,
} from 'utils/constants';
import InfoIcon from 'atoms/InfoIcon';
import { FormattedMessage } from 'react-intl';
import { Emoji } from 'emoji-mart';
import Button from 'atoms/Button';
import {
  YesterdayIcon,
  CalenderPrimary,
  BlockerPrimary,
  ArrowIcon,
  ArrowUp,
  ArrowCircleDownOutline,
  ArrowCircleUpOutline,
  CornerDown,
} from 'assets';
import { taskFormat } from 'utils/utils';
import { useQuery } from '@apollo/client';
import { GET_TEAM_CHECKINS } from 'features/standUp/graphql/Queries';
import moment from 'moment';
import { filter, map } from 'lodash';
import { teamData as team } from './MockData';
import { Content, Wrapper, Arrow, Column } from './AntStyled';

const TeamCheckin = () => {
  const [date, setDate] = useState([moment(), moment()]);
  const [isReCheckedInToday, setIsReCheckedInToday] = useState(true);
  const [isTrue, setIsTrue] = useState(false);
  const [users, setUsers] = useState(EMPTY_ARRAY);
  const { data: teamData, loading } = useQuery(GET_TEAM_CHECKINS, {
    variables: {
      where: {
        from:
          date && date.length > 0
            ? date[0].format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
        to:
          date && date.length > 0
            ? date[1].format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
        userIds: [...users],
      },
    },
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });

  useEffect(() => {
    eva.replace();
  }, []);

  const Expandable = {
    expandIconColumnIndex: '7',
    expandedRowKeys: isTrue
      ? _map(teamData?.standUpEntries, (val) => val?.id)
      : '',
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <img
          onClick={(e) => onExpand(record, e)}
          className="icon-table"
          src={ArrowUp}
          alt="icon"
        />
      ) : (
        <Arrow
          onClick={(e) => onExpand(record, e)}
          src={ArrowIcon}
          alt="icon"
        />
      ),
    expandedRowRender: (record) => (
      <Wrapper>
        <div className="info">
          <Row align="middle">
            <Col className="main-icon">
              <img className="icon-table" src={YesterdayIcon} alt="icon" />
            </Col>
            <Col className="title">
              {record?.yesterday?.count > 0 ? (
                <FormattedMessage id="standUps.formLabel.yesterday" />
              ) : (
                <FormattedMessage id="standUps.formLabel.noYesterdayGoal" />
              )}
            </Col>
          </Row>
          {map(
            filter(
              record?.yesterday?.standUpAnswers,
              (ans) => ans?.content?.isChecked
            ),
            (answer) => (
              <div className="checkbox text flex-box">
                <Checkbox
                  disabled
                  checked={answer?.checked}
                  className={`checkbox-disabled ${!answer?.checked && 'unchecked'
                    }`}
                >
                  {taskFormat(answer?.content?.task)}
                </Checkbox>
              </div>
            )
          )}
        </div>
        <div className="info">
          <Row align="middle">
            <Col className="main-icon">
              <img className="icon-table" src={CalenderPrimary} alt="icon" />
            </Col>
            <Col className="title">
              <FormattedMessage id="standUps.formLabel.today" />
            </Col>
          </Row>
          {map(record?.today?.standUpAnswers, (answer) => (
            <div className="checkbox text flex-box">
              <Checkbox
                disabled
                checked={answer?.checked}
                className={`checkbox-disabled-primary ${!answer?.checked && 'unchecked'
                  }`}
              >
                {taskFormat(answer?.content?.task, answer?.addedLater, 14)}
              </Checkbox>
            </div>
          ))}
        </div>
        <div className="info">
          <Row align="middle">
            <Col className="main-icon">
              <img className="icon-table" src={BlockerPrimary} alt="icon" />
            </Col>
            <Col className="title">
              {record?.blocker?.count > 0 ? (
                <FormattedMessage id="standUps.formLabel.progress" />
              ) : (
                <FormattedMessage id="standUps.formLabel.noBlocker" />
              )}
            </Col>
          </Row>
          {map(record?.blocker?.standUpAnswers, (answer) => (
            <div className="checkbox text flex-box">
              <Checkbox
                disabled
                checked={answer?.checked}
                className={`checkbox-disabled-primary ${!answer?.checked && 'unchecked'
                  }`}
              >
                {taskFormat(answer?.content?.task, answer?.addedLater, 14)}
              </Checkbox>
            </div>
          ))}
        </div>
      </Wrapper>
    ),
  };

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      title: 'Name',
      dataIndex: 'user',
      key: 'name',
      render: (user, rowData) => (
        <UserProfile
          stackedName={false}
          profile={user?.profileImage}
          name={
            user?.firstName
              ? `${user?.firstName} ${user?.lastName || ''}`
              : user?.originalEmail || user?.email
          }
          userId={user.id}
          email={user?.originalEmail || user?.email}
          showLink
          bold
          medium
        />
      ),
      sorter: (a, b) => {
        const toCompareA = a?.user?.firstName || a?.user?.email;
        const toCompareB = b?.user?.firstName || b?.user?.email;
        return toCompareA.localeCompare(toCompareB);
      },
    },
    {
      title: 'Check-in',
      dataIndex: 'updatedAt',
      key: 'CheckIn',
      align: 'center',
      width: '15%',
      render: (time) => {
        const isToday = moment(time).isSame(moment(), 'day');
        return (
          <DateTime
            date={time}
            format={isToday ? MomentFormat.longTime : MomentFormat.monthDate}
            className="check-in-date"
          />
        );
      },
    },
    {
      title: 'Goals Achieved',
      dataIndex: 'goals',
      key: 'goalsAchieved',
      align: 'center',
      width: '15%',
      render: (text, rowData) => (
        <Column className="goals-text">
          {filter(rowData?.today?.standUpAnswers, (ans) => ans?.checked)
            ?.length || '-'}
        </Column>
      ),
    },
    {
      title: 'Goals Added',
      dataIndex: 'goalsAdded',
      key: 'goalsAdded',
      align: 'center',
      width: '15%',
      render: (goals, rowData) => (
        <Column className="goals-added-text">
          {rowData?.today?.count || '-'}
        </Column>
      ),
    },
    {
      title: 'Blockers',
      dataIndex: 'blockers',
      key: 'blockers',
      align: 'center',
      width: '15%',
      render: (blockers, rowData) => (
        <Column className="blockers-text">
          {rowData?.blocker?.count || '-'}
        </Column>
      ),
    },
    {
      title: 'Mood',
      dataIndex: 'mood',
      key: 'mood',
      align: 'center',
      render: (mood) =>
        mood || mood === 0 ? (
          <div className="emojis">
            <Emoji set="apple" emoji={EMOJIS[mood]} size={20} />
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: isTrue ? (
        <Button
          type="link"
          icon={<Arrow className="icon" src={ArrowCircleUpOutline} />}
          onClick={() => setIsTrue(false)}
        />
      ) : (
        <Button
          type="link"
          icon={<Arrow className="icon" src={ArrowCircleDownOutline} />}
          onClick={() => setIsTrue(true)}
        />
      ),
      align: 'center',
      key: '7',
      dataIndex: '7',
      width: '2%',
      className: 'padding-col',
    },
  ];

  return (
    <Layout>
      <Header
        title={
          <InfoIcon
            title={<FormattedMessage id="standUps.manager.team" />}
            contentKey={META_TYPE?.TEAM_CHECK_IN}
          />
        }
      />
      <Filters
        daysFilter
        masterFilter
        showGroupUserFilter
        setDateFilterValue={setDate}
        dateFilterValue={date}
        setUsersValue={setUsers}
        usersValue={users}
      />
      <Content>
        <Table
          expandable={Expandable}
          columns={columns}
          dataSource={teamData?.standUpEntries}
          className="bb-0"
          loading={loading}
          rowKey={(record) => record?.id}
          showEmptyState
        />
      </Content>
    </Layout>
  );
};
export default {
  key: PRIVATE_ROUTE.TEAM_CHECK_IN,
  component: withRouter(TeamCheckin),
  name: 'Team Check-In',
};
