import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    role
    profileImage
  }
`;

export const groupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    color
    type
    members{
      user{
        ...UserFragment
      }
    }
    managers{
      user{
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;
export const commentFragment = gql`
  fragment CommentFragment on Comment {
    id
    content
    createdBy {
      ...UserFragment
    }
    createdAt
    updatedAt
  }
  ${userFragment}
`;
export const actionFragment = gql`
  fragment ActionFragment on Action {
    id
    name
    estimation
    completionHours
    isCompleted
    completedOn
    createdAt
    user {
      ...UserFragment
    }
    group {
      ...GroupFragment
    }
    comments {
      ...CommentFragment
     }
    status
    isArchive
    type
  }
  ${userFragment}
  ${groupFragment}
  ${commentFragment}
`;

export const checkInFragment = gql`
  fragment CheckInFragment on CheckIn {
    id
    checkIn
    checkOut
    user {
      ...UserFragment
    }
    status
    createdAt
  }
  ${userFragment}
`;
