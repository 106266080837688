import { EMPTY_OBJECT, QUESTION_TYPE } from 'utils/constants';
export const data = [
  {
    id: 0,
    key: 0,
    title: 'Impact of change',
    description:
      'Follow-up on a recent change/process/event to evaluate and adapt.',
    numOfQues: 2,
  },
  {
    id: 1,
    key: 1,
    title: 'Impact of change',
    description:
      'Follow-up on a recent change/process/event to evaluate and adapt.',
    numOfQues: 2,
  },
  {
    id: 2,
    key: 2,
    title: 'Impact of change',
    description:
      'Follow-up on a recent change/process/event to evaluate and adapt.',
    numOfQues: 2,
  },
  {
    id: 3,
    key: 3,
    title: 'Impact of change',
    description:
      'Follow-up on a recent change/process/event to evaluate and adapt.',
    numOfQues: 2,
  },
  {
    id: 4,
    key: 4,
    title: 'Impact of change',
    description:
      'Follow-up on a recent change/process/event to evaluate and adapt.',
    numOfQues: 2,
  },
];

export const departmentData = [
  {
    title: 'All',
    value: 'all',
    key: 'all',
    children: [
      {
        title: 'Front End',
        value: 'FE',
        key: 'FE',
        children: [
          {
            title: 'ReactJs',
            value: 'react',
            key: 'react',
          },
        ],
      },
      {
        title: 'Back End',
        value: 'BE',
        key: 'BE',
        children: [
          {
            title: 'MongoDB',
            value: 'mongo',
            key: 'mongo',
          },
          {
            title: 'MySQL',
            value: 'mysql',
            key: 'mysql',
          },
          {
            title: 'Postgres',
            value: 'postgres',
            key: 'postgres',
          },
        ],
      },
    ],
  },
];

export const questions = [
  {
    type: 'text',
    question: 'Using one word, how do you feel right now?',
    answer: {
      placeholder: 'Enter answer',
    },
  },
  {
    type: 'opinionScale',
    question: 'Using one word, how do you feel right now?',
    answer: {
      minLabel: 'Bad',
      maxLabel: 'Good',
    },
  },
  {
    type: 'multipleChoice',
    question:
      'Should one word not be enough, please feel free to share more here!',
    answer: ['Sharing results with team', 'Sharing feedback with team'],
  },
];

export const defaultQuestion = [EMPTY_OBJECT];

export const surveyData = [
  {
    key: 1,
    id: 1,
    title: 'Impact of Work',
    type: 'Survey',
    response: {
      number: '50',
      progress: '50',
    },
    startedOn: '22/02/2020',
    endedOn: '27/06/2020',
    noOfQues: 5,
    status: true,
    associatedGroups: [
      { color: '#722ED1', name: 'Front-End' },
      { color: '#096DD9', name: 'Design' },
    ],
  },
  {
    key: 2,
    id: 2,
    title: 'Impact of Work',
    type: 'Poll',
    response: {
      number: '10',
      progress: '10',
    },
    startedOn: '22/02/2020',
    endedOn: '27/02/2020',
    noOfQues: 5,
    status: true,
    associatedGroups: [
      { color: '#13C2C2', name: 'QA' },
      { color: '#52C41A', name: 'Marketing' },
    ],
  },
  {
    key: 3,
    id: 3,
    title: 'Impact of Work',
    type: 'Survey',
    response: {
      number: '90',
      progress: '90',
    },
    startedOn: '22/02/2020',
    endedOn: '27/02/2020',
    noOfQues: 5,
    status: true,
    associatedGroups: [
      { color: '#722ED1', name: 'Front-End' },
      { color: '#096DD9', name: 'Design' },
      { color: '#D48806', name: 'Back-End' },
      { color: '#EB2F96', name: 'Admin' },
      { color: '#13C2C2', name: 'QA' },
      { color: '#52C41A', name: 'Marketing' },
    ],
  },
  {
    key: 4,
    id: 4,
    title: 'Impact of Work',
    type: 'Poll',
    response: {
      number: '40',
      progress: '40',
    },
    startedOn: '22/02/2020',
    endedOn: '27/02/2020',
    noOfQues: 5,
    status: true,
    associatedGroups: [{ color: '#52C41A', name: 'Marketing' }],
  },
  {
    key: 5,
    id: 5,
    title: 'Impact of Work',
    type: 'Survey',
    response: {
      number: '80',
      progress: '80',
    },
    startedOn: '22/02/2020',
    endedOn: '27/02/2020',
    noOfQues: 5,
    status: true,
    associatedGroups: [
      { color: '#52C41A', name: 'Marketing' },
      { color: '#096DD9', name: 'Design' },
      { color: '#D48806', name: 'Back-End' },
      { color: '#722ED1', name: 'Front-End' },
      { color: '#EB2F96', name: 'Admin' },
      { color: '#13C2C2', name: 'QA' },
    ],
  },
];

export const responseData = [
  {
    responseId: 1,
    date: '22/02/2020',
    name: 'Jack Ryan',
  },
];
export const pollType = [
  {
    role: 'Pick one',
    value: QUESTION_TYPE.SINGLE_CHOICE,
    desc: 'Select one item from a list',
  },
  {
    role: 'Pick one or more',
    value: QUESTION_TYPE.MCQ,
    desc: 'Select one or more items from a list',
  },
  {
    role: 'Like',
    value: QUESTION_TYPE.LIKE,
    desc: 'Give a thumbs up or thumbs down to each item',
  },
  {
    role: 'Rate',
    value: QUESTION_TYPE.RATE,
    desc: 'Rate each item 1 to 5 stars',
  },
];
