/* eslint-disable no-nested-ternary */
//! THIS IS A DEPRECATED FILE, MIGHT BE USED IN FUTURE
import React, { useEffect, useState, useRef } from 'react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  Popover,
  Skeleton,
  Row,
  Typography,
  Button as AntButton,
} from 'antd';
import _concat from 'lodash/concat';
import { PlusOutlined } from '@ant-design/icons';
import { FiCheckSquare, FiSquare } from 'react-icons/fi';
import InlineEditable from 'molecules/InlineEditable';
import Colors from 'design/Colors';
import Card from 'atoms/Card';
import Input from 'atoms/Input';
import CommentsPopover from 'organisms/CommentsPopover';
import Button from 'atoms/Button';
import {
  EMPTY_ARRAY,
  FETCH_POLICY,
  BUTTON_TYPE,
  TASK_FILED,
  FORM_NAME,
  TASK_TYPE,
} from 'utils/constants';
import { GET_GROUPS, GET_USERS } from 'graphql/Groups/Queries';
import GroupOrUser from 'molecules/GroupOrUser';
import Table from 'atoms/Table';
import UserDropdown from 'organisms/UserDropdown';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import DateTime from 'atoms/DateTime';
import _sortby from 'lodash/sortBy';
import { getTrimValues } from 'utils';
import _get from 'lodash/get';
import {
  ArrowIcon,
  UnCheck,
  CheckIcon,
  ArchiveIcon,
  AddIcon,
  Checked,
} from 'assets';
import Hours from 'atoms/Hours';
import TriStateSwitch from 'atoms/TriStateSwitch';
import ReasonDeletion from '../ReasonDeletion';
import {
  Form,
  Comments,
  Title,
  TaskContent,
  Select,
  Arrow,
  Image,
  BlockersCount,
} from './AntStyled';
import TaskCompletion from '../TaskCompletion';
import { TitleDiv } from '../Tasks/AntStyled';

const { Option } = Select;
const { Text } = Typography;

export default function ({
  data,
  handleCheckbox,
  onCreate,
  updateTask,
  archiveTask,
  allowModify = false,
  loading,
  groupLoading,
  userId,
  setSortData,
  blockerState,
  blockersCount,
}) {
  const [form] = Form.useForm();
  const blockerRef = useRef();
  const { data: groupsData } = useQuery(GET_GROUPS, {
    fetchPolicy: FETCH_POLICY.CACHE_FIRST,
  });
  const { data: usersData } = useQuery(GET_USERS, {
    fetchPolicy: FETCH_POLICY.CACHE_FIRST,
  });
  const [completionId, setCompletionId] = useState(null);

  const [dataSource, setDataSource] = useState(data);
  const [deletionId, setDeletionId] = useState(null);
  const [group, setGroup] = useState(EMPTY_ARRAY);
  const [user, setUser] = useState(EMPTY_ARRAY);
  const [selectedBlockerType, setSelectedBlockerType] = useState('all');

  useEffect(() => {
    if (data) {
      let taskTableData = [];
      taskTableData = _map(data, (row, key) => ({
        key: key.toString(),
        id: row.id,
        isCompleted: row.isCompleted,
        name: row.name,
        estimation: row.estimation,
        group: JSON.stringify(row.group),
        user: JSON.stringify(row.user),
        comments: row.comments,
        createdAt: row.createdAt,
        allUsers:
          !row.loading &&
          row.group &&
          _filter(
            _concat(row.group.members, row.group.managers),
            (item) =>
              (item && item.user.id !== userId) ||
              (item && row.user && item.user.id === row.user.id)
          ),
        loading: row.loading,
        completionHours: row.completionHours,
      }));
      setDataSource(taskTableData);
    }
  }, [data, userId]);
  useEffect(() => {
    if (groupsData) {
      const { groups } = groupsData;
      const groupChildrens = _map(groups.groups, (items, index) => (
        <Option key={index} value={JSON.stringify(items)}>
          <GroupOrUser data={{ group: items }} />
        </Option>
      ));
      setGroup(groupChildrens);
    }
  }, [groupsData]);

  useEffect(() => {
    if (usersData) {
      const { users } = usersData;
      const filteredUsers = _filter(users.users, (user) => user.id !== userId);
      const usersChildren = _map(filteredUsers, (items, index) => (
        <Option key={index} value={JSON.stringify(items)}>
          <GroupOrUser
            stackedName={false}
            size="small"
            medium
            data={{ user: items }}
            dropDown
          />
        </Option>
      ));
      setUser(usersChildren);
    }
  }, [usersData, userId]);

  const handleGroupChange = (groupData) => {
    form.resetFields(['user']);
    if (groupData) {
      const group = JSON.parse(groupData);
      const userChildren = _map(
        _filter(
          _concat(group.members, group.managers),
          (item) => item.user.id !== userId
        ),
        (items, index) => (
          <Option key={index} value={JSON.stringify(items.user)}>
            <GroupOrUser data={{ user: items.user }} dropDown />
          </Option>
        )
      );
      setUser(userChildren);
    }
  };

  const inLineEditable = (dataIndex, inputRef, save, rowData) => {
    let node;
    switch (dataIndex) {
      case TASK_FILED.NAME:
        node = (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                whitespace: true,
                message: (
                  <FormattedMessage id="me.formRules.name.errorMessage" />
                ),
              },
            ]}
          >
            <Input innerRef={inputRef} onPressEnter={save} onBlur={save} />
          </Form.Item>
        );
        break;
      case TASK_FILED.ESTIMATION:
        node = (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                pattern: /^\d{1,2}(\.\d{1,2})?$/,
                message: (
                  <FormattedMessage id="me.formRules.estimation.hoursPattern" />
                ),
              },
            ]}
          >
            <Input innerRef={inputRef} onPressEnter={save} onBlur={save} />
          </Form.Item>
        );
        break;
      case TASK_FILED.GROUP:
        node = (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="me.formRules.groups.errorMessage" />
                ),
              },
            ]}
          >
            <Select
              ref={inputRef}
              onBlur={save}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {group}
            </Select>
          </Form.Item>
        );
        break;
      default:
        node = (
          <Form.Item name={dataIndex}>
            <Select
              ref={inputRef}
              onBlur={save}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {_map(
                _sortby(rowData.allUsers, 'user.firstName'),
                (items, index) => (
                  <Option key={index} value={JSON.stringify(items.user)}>
                    <GroupOrUser data={{ user: items.user }} dropDown />
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        );
        break;
    }
    return node;
  };

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      className: 'check-mark no-padding',
      align: 'center',
      render: (isCompleted) => isCompleted && <Image src={Checked} />,
    },
    {
      title: 'Blockers',
      dataIndex: 'name',
      className: 'action-name',
      editable: true,
      render: (name, rowData) =>
        rowData.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : (
          <Title isCompleted={rowData.isCompleted}>{name}</Title>
        ),
    },
    {
      title: 'Group',
      dataIndex: 'group',
      editable: true,
      width: '16.5%',
      render: (row, rowData) =>
        rowData.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : (
          <GroupOrUser data={{ group: rowData.group }} />
        ),
    },
    {
      title: 'Estimation',
      dataIndex: 'estimation',
      width: '12.5%',
      editable: true,
      render: (estimation, rowData) =>
        rowData.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : estimation === null ? (
          '-'
        ) : (
          <Hours estTime={estimation} />
        ),
    },
    {
      title: 'Assign to',
      dataIndex: 'user',
      editable: true,
      width: '20%',
      render: (row, rowData) =>
        rowData.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : (
          <GroupOrUser
            dropdown
            stackedName
            showLink={false}
            data={{ user: rowData.user }}
          />
        ),
    },

    {
      dataIndex: 'comments',
      key: 'comments',
      width: '75px',
      render: (row, rowData) =>
        !rowData.loading && (
          <Comments>
            <CommentsPopover actionId={rowData.id} data={rowData.comments} />
          </Comments>
        ),
    },
    {
      align: 'center',
      width: '32px',
      className: 'no-padding',
      render: (rowData) =>
        !rowData.loading ? (
          <div className={`task-status ${allowModify ? 'modify' : ''}`}>
            {allowModify ? (
              <Popover
                visible={rowData.id === completionId}
                onVisibleChange={(visible) =>
                  !visible && closeCompletionModal()
                }
                content={
                  <TaskCompletion
                    completeTask={(value) =>
                      updateTask(
                        {
                          ...rowData,
                          isCompleted: !rowData.isCompleted,
                          completionHours: value && parseFloat(value),
                        },
                        TASK_TYPE.BLOCKER
                      ).then(() => closeCompletionModal())
                    }
                    closeModal={closeCompletionModal}
                  />
                }
                overlayClassName="popover-no-padding"
                trigger="click"
                placement="left"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() =>
                    handleComplete(!rowData.isCompleted, rowData.id)
                  }
                >
                  {rowData.isCompleted ? (
                    <Image src={UnCheck} />
                  ) : (
                    <Image src={CheckIcon} />
                  )}
                </div>
              </Popover>
            ) : rowData.isCompleted ? (
              <Image src={UnCheck} />
            ) : (
              <Image src={CheckIcon} />
            )}
          </div>
        ) : null,
    },
    {
      align: 'right',
      dataIndex: 'operation',
      width: '1%',
      className: 'padding-col',
      render: (text, rowData) =>
        !rowData.loading && (
          <Popover
            visible={rowData.id === deletionId}
            onVisibleChange={(visible) =>
              !visible && closeReasonDeletionModal()
            }
            content={
              <ReasonDeletion
                deleteTask={(value) =>
                  archiveTask(value, deletionId, TASK_TYPE.BLOCKER)
                }
                closeModal={closeReasonDeletionModal}
              />
            }
            overlayClassName="popover-no-padding"
            trigger="click"
            placement="left"
          >
            <Image
              src={ArchiveIcon}
              className="icon-delete"
              onClick={() => setDeletionId(rowData.id)}
            />
          </Popover>
        ),
    },
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];
  if (!allowModify) {
    columns.slice(columns.length - 2);
  }

  const handleComplete = (value, id) => {
    if (value) {
      setCompletionId(id);
    } else {
      handleCheckbox(value, id, TASK_TYPE.BLOCKER);
    }
  };

  const closeCompletionModal = () => {
    setCompletionId(null);
  };

  const createBlocker = async (values) => {
    const trimValues = getTrimValues(values);
    onCreate(trimValues, 'BLOCKER').then(() => {
      form.resetFields();
      blockerRef.current.focus();
    });
  };

  const closeReasonDeletionModal = () => {
    setDeletionId(null);
  };

  function onChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'ascend') {
        setSortData({
          field: sorter.field,
          type: 'ASC',
          blockerType: 'blocker',
        });
      } else {
        setSortData({
          field: sorter.field,
          type: 'DESC',
          blockerType: 'blocker',
        });
      }
    }
  }

  const locale = {
    emptyText: data && data.length === 0 && blockersCount > 0 && (
      <Text type="secondary">{`${blockersCount} blocker(s) in other group(s)`}</Text>
    ),
  };

  return (
    <Card className="rounded" padding="0" bottom="30px">
      <TaskContent>
        <TitleDiv> </TitleDiv>
        {allowModify ? (
          <InlineEditable
            dataSource={
              selectedBlockerType === 'all'
                ? dataSource
                : _filter(dataSource, {
                    isCompleted: selectedBlockerType === 'completed',
                  })
            }
            columns={columns}
            updateData={(e) => updateTask(e, TASK_TYPE.BLOCKER)}
            inLineEditable={inLineEditable}
            loading={groupLoading === true ? groupLoading : loading}
            onChange={onChange}
          >
            <Form
              layout={FORM_NAME.INLINE}
              onFinish={createBlocker}
              form={form}
            >
              <Col span={7}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: (
                        <FormattedMessage id="me.formRules.name.errorMessage" />
                      ),
                    },
                  ]}
                >
                  <Input
                    className="title"
                    placeholder="Title"
                    allowClear
                    innerRef={blockerRef}
                  />
                </Form.Item>
              </Col>

              <Col span={3} offset={2}>
                <Form.Item
                  name="groups"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="me.formRules.groups.errorMessage" />
                      ),
                    },
                  ]}
                >
                  <Select
                    placeholder="Group"
                    onChange={(e) => handleGroupChange(e)}
                    showSearch
                    showArrow
                    suffixIcon={<Arrow src={ArrowIcon} />}
                    filterOption={(input, option) => {
                      const groupFilter = _get(
                        option,
                        'children.props.children.props.item',
                        ''
                      );
                      return groupFilter?.name
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase());
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {group}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="estimation" span={2} offset={1}>
                <Form.Item
                  name="estimation"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="me.formRules.estimation.errorMessage" />
                      ),
                    },
                    {
                      pattern: /^\d{1,2}(\.\d{1,2})?$/,
                      message: (
                        <FormattedMessage id="me.formRules.estimation.hoursPattern" />
                      ),
                    },
                  ]}
                >
                  <Input placeholder="Hours" allowClear />
                </Form.Item>
              </Col>
              <Col span={3} offset={1}>
                <UserDropdown className="bb-user" formItemName="user">
                  {user}
                </UserDropdown>
              </Col>
              <Col span={3} offset={2}>
                <Form.Item className="text-right">
                  <Button
                    tableButton
                    htmlType={BUTTON_TYPE.SUBMIT}
                    type={BUTTON_TYPE.PRIMARY}
                    name="Save"
                  />
                </Form.Item>
              </Col>
            </Form>
          </InlineEditable>
        ) : (
          <>
            <Table
              className="taskTable bb-0"
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              onChange={onChange}
              locale={locale}
            />
            {data && data.length !== 0 && blockersCount > 0 && (
              <BlockersCount>
                <Text type="secondary">{`+ ${blockersCount} blockers(s) in other group(s)`}</Text>
              </BlockersCount>
            )}
          </>
        )}
      </TaskContent>
    </Card>
  );
}
