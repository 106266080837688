import React from 'react';
import { Layout, Row, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import SettingsTemplate from 'atoms/SettingsTemplate';
import Table from 'atoms/Table';
import Card from 'atoms/Card';
import Button from 'atoms/Button';
import { BUTTON_TYPE, FETCH_POLICY, PRIVATE_ROUTE } from 'utils/constants';
import { BackArrow } from 'assets';
import InfoIcon from 'atoms/InfoIcon';
import Header from 'layout/Header';
import { useQuery } from '@apollo/client';
import { GET_FEEDBACK_TEMPLATES } from 'features/feedbacks/graphql/Queries';
import { withRouter } from 'react-router-dom';
import { ContentTitle, Content, Text, Arrow } from './AntStyled';

function NewPoll(props) {
  const { history } = props;
  const { data, loading } = useQuery(GET_FEEDBACK_TEMPLATES, {
    variables: { where: { type: 'POLL' } },
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });

  const Title = () => (
    <>
      <ContentTitle>
        <Arrow
          src={BackArrow}
          alt="arrow"
          onClick={() => history.push(PRIVATE_ROUTE.FEED_BACK_CURRENT)}
        />
        <InfoIcon title={<FormattedMessage id="feedback.new.pollTitle" />} />
      </ContentTitle>
    </>
  );

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '45%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '55%',
      ellipsis: true,
    },
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];
  const onClickRow = (record) => ({
    onClick: () => {
      history.push({
        pathname: PRIVATE_ROUTE.POLL_CREATE,
        state: {
          templateData: record,
        },
      });
    },
  });

  return (
    <Layout>
      <Header title={<Title />} />
      <Content>
        <Spin spinning={loading}>
          <Row justify="center">
            <Button
              type={BUTTON_TYPE.PRIMARY}
              className="btn-no-shadow"
              onClick={() => history.push(PRIVATE_ROUTE.POLL_CREATE)}
              name={<FormattedMessage id="feedback.add.newSurvey" />}
            />
          </Row>
          <Row justify="center" className="or">
            OR
          </Row>
          <Card padding="16" className="card">
            <SettingsTemplate>
              <Row justify="center" className="row-text">
                <Text>
                  <FormattedMessage id="feedback.add.selectTemplateTitle" />
                </Text>
              </Row>
              <Row justify="center" className="row-table">
                <Card>
                  <Table
                    className="bb-0"
                    dataSource={data?.templateFeedbacks}
                    columns={columns}
                    height="100%"
                    onRow={onClickRow}
                  />
                </Card>
              </Row>
            </SettingsTemplate>
          </Card>
        </Spin>
      </Content>
    </Layout>
  );
}

export default withRouter(NewPoll);
