import React, { useContext, useEffect, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { unpackRules } from '@casl/ability/extra';
import { GET_CURRENT_USER } from 'graphql/CurrentUser/queries';
import {
  ONBOARDING,
  PUBLIC_ROUTE,
  USER_ROLE,
  ACTIONS_TYPE,
  FETCH_POLICY,
} from 'utils/constants';
import { AppContext } from 'AppContext';
import client from 'apollo';
import Loader from 'atoms/Loader';
import * as Sentry from '@sentry/react';
import { companySetting } from 'templates/Settings/Sidebar/MockData';
import history from '../history';

function GuardedRoute(props) {
  const { component: Component, path, idToken, ability } = props;
  const allowedNextStep = [ONBOARDING?.FEATURE, ONBOARDING?.DASHBOARD];
  const { dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    try {
      const response = await client.query({
        query: GET_CURRENT_USER,
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      });
      const {
        data: { currentUser },
      } = response;
      if (!currentUser?.isDisabled) {
        const { pathname } = history.location;
        const selectionAllowedPaths = [
          ONBOARDING?.WORKSPACE_RESULT,
          ONBOARDING?.SELECTION,
          PUBLIC_ROUTE?.SET_PASSWORD,
          ONBOARDING?.ROOT,
        ];

        if (currentUser?.groupRules) {
          ability.update(unpackRules(currentUser?.groupRules));
          dispatch({
            type: ACTIONS_TYPE?.SET_ABILITY,
            data: unpackRules(currentUser?.groupRules),
          });
        }
        dispatch({ type: ACTIONS_TYPE?.LOGIN_USER, data: currentUser });
        if (
          !currentUser?.hasSetPassword &&
          currentUser?.authenticationType === 'LOCAL' &&
          pathname !== PUBLIC_ROUTE?.SET_PASSWORD
        ) {
          history.push(PUBLIC_ROUTE?.SET_PASSWORD);
        } else if (currentUser?.role === USER_ROLE?.OWNER) {
          if (
            (currentUser?.company === null ||
              !allowedNextStep?.includes(currentUser?.company?.nextStep)) &&
            pathname !== ONBOARDING?.ROOT &&
            pathname !== PUBLIC_ROUTE?.SET_PASSWORD
          ) {
            history.push(ONBOARDING?.ROOT);
          }
        } else if (
          currentUser?.role === USER_ROLE?.USER &&
          currentUser?.hasRequested &&
          !currentUser?.isActive &&
          !selectionAllowedPaths?.includes(pathname)
        ) {
          history.push(ONBOARDING?.WORKSPACE_RESULT);
        } else if (
          currentUser?.role === USER_ROLE?.USER &&
          !currentUser?.company &&
          !selectionAllowedPaths?.includes(pathname)
        ) {
          history.push(ONBOARDING?.SELECTION);
        }
      } else {
        window.location.href = PUBLIC_ROUTE.LOGOUT;
      }
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      Sentry.captureException(error);
    }
  }
  useEffect(
    () => {
      if (idToken) {
        fetchData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idToken, props]
  );
  return loading ? (
    <Loader />
  ) : (
    <Route
      path={path}
      render={(propsData) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...propsData} />
      )}
    />
  );
}

export default withRouter(GuardedRoute);
