import { gql } from '@apollo/client';
import { feedbackFragment, questionFragment } from './Fragments';

export const CREATE_FEEDBACK = gql`
  mutation createFeedback($data: FeedbackInput) {
    createFeedback(data: $data) {
      ...FeedbackFragment
    }
  }
  ${feedbackFragment}
`;

export const DELETE_FEEDBACK = gql`
  mutation deleteFeedback($id: ID!) {
    deleteFeedback(where: { id: $id })
  }
`;

export const CREATE_FEEDBACK_ANSWER = gql`
  mutation createFeedbackAnswers(
    $data: [FeedbackAnswerInput!]!
    $where: createFeedbackAnswersWhere!
  ) {
    createFeedbackAnswers(data: $data, where: $where) {
      answers {
        id
        answer
        question {
          ...QuestionFragment
        }
      }
      count
    }
  }
  ${questionFragment}
`;

export const UPDATE_FEEDBACK = gql`
  mutation updateFeedback($where: feedbackUniqueInput!, $data: FeedbackInput) {
    updateFeedback(data: $data, where: $where) {
      ...FeedbackFragment
    }
  }
  ${feedbackFragment}
`;

export const PUBLISH_FEEDBACK = gql`
  mutation publishFeedback($where: feedbackUniqueInput!) {
    publishFeedback(where: $where) {
      id
    }
  }
`;

export const END_FEEDBACK = gql`
  mutation endFeedback($where: feedbackUniqueInput) {
    endFeedback(where: $where) {
      id
    }
  }
`;
