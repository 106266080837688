import styled from 'styled-components';
import { Form, Divider, Input } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { link, x, y } from 'design/Styled';
import Spacings from 'design/Spacings';
import { Eye, Loginbg } from 'assets';

export const Container = styled.div`
  ${x};
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${Colors.SidebarText};
  @media (max-width: 1120px) {
    flex-direction: column;
  }
  .right {
    float: right;
  }
  .ant-divider-horizontal.ant-divider-with-text-center::before,
  .ant-divider-horizontal.ant-divider-with-text-center::after {
    border-top: 1px solid ${Colors.SecondaryColor};
  }
  .signup {
    text-decoration: underline;
    text-underline-position: under;
    color: ${Colors.PrimaryColor};
  }
  .terms {
    color: ${Colors.TextSecondary};
    margin-right: ${Spacings.Small.margin};
    :last-child {
      margin-right: 0px;
    }
    @media (max-width: 1000px) {
      color: ${Colors.SidebarText};
    }
  }
  .ant-btn-primary {
    font: ${fonts.small.medium};
    line-height: 22px;
  }
`;

export const Or = styled.div`
  color: ${Colors.TextSecondary};
  text-align: center;
  line-height: 22px;
  font: ${fonts.small.regular};
`;

export const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${Loginbg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  .bottom-left {
    position: absolute;
    left: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
  .bottom-right {
    position: absolute;
    right: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
`;
export const Logo = styled.img`
  position: absolute;
  max-width: 180px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 1120px) {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Title = styled.div`
  font: ${fonts.xxLarge.bold} !important;
  color: ${Colors.TextPrimary};
  text-align: left;
  line-height: 41px;
  margin-bottom: 24px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: ${fonts.small.regular};
  width: 100%;
`;
export const Wrapper = styled.div`
  .ant-card {
    overflow: hidden;
    width: 100%;
    max-width: 408px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  @media (max-width: 1120px) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;

export const GoogleButton = styled.div`
  ${x};
  justify-content: center;
`;

export const SIcon = styled.img`
  width: 20px;
`;
export const InputIcon = styled.img`
  width: 18px;
`;

export const StyledForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
  .ant-form-item-explain {
    font-size: 12px;
  }
`;
export const StyledDivider = styled(Divider)`
  padding: 8px 0 8px;
  .ant-divider-inner-text {
    font: ${fonts.xxSmall.bold};
    color: ${Colors.TextSecondary};
  }
`;
export const GoogleLogin = styled.div`
  text-align: center;
  margin-top: ${Spacings.small.margin};
  margin-bottom: ${Spacings.xSmall.margin};
`;
export const FullDivider = styled.hr`
  border: 1px solid ${Colors.SecondaryColor};
  margin-left: -50px;
  margin-right: -50px;
  overflow: hidden;
  border-bottom: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
`;
export const LoginPage = styled.div`
  text-align: center;
  color: ${Colors.TextPrimary};
  font: ${fonts.small.regular};
  line-height: 22px;
  padding-top: 24px;
  padding-bottom: 0px;
  width: 100%;
  background-color: ${Colors.Container};
  align-self: center;
`;

export const Terms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 410px;
  padding-top: 10px;
  align-self: center;
  font: ${fonts.xSmall.bold};
`;
