/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import Modal from 'atoms/Modal';
import React, { useState, useEffect } from 'react';
import { Row, Col, Popover, Divider } from 'antd';
import { capitalize, map } from 'lodash';
import LeavesTodo from 'features/people/widgets/LeavesTodo';
import SurveyTodo from 'features/feedbacks/widgets/SurveyTodo';
import PollTodo from 'features/feedbacks/widgets/PollTodo';
import AnnouncementTodo from 'features/announcement/widgets/AnnouncementTodo';
import * as eva from 'eva-icons';
import { TODO_TYPE, FEEDBACK_TYPE, NOTIFICATIONS } from 'utils/constants';
import Page404 from 'pages/404';
import { Wrapper, StepsWrapper } from './AntStyled';

function UpdatesModal({
  visible,
  setVisible,
  updatesData,
  callback,
  afterClose,
  viewSummary,
}) {
  useEffect(() => {
    eva.replace();
  }, []);
  const Component = () => {
    const { type } = updatesData;
    let todo = {};
    switch (type) {
      case TODO_TYPE.ANNOUNCEMENT_PUBLISHED:
        todo = {
          type: updatesData?.type,
          title: updatesData?.title,
          todoable: {
            ...updatesData?.data,
            announcementId: updatesData?.updatableId,
            sequenceNo: updatesData?.data?.sequenceNo,
          },
          groups: updatesData?.groups,
          owner: updatesData?.createdBy,
        };
        return (
          <AnnouncementTodo
            todo={todo}
            callback={callback}
            showArchive={false}
          />
        );
      case TODO_TYPE.FEEDBACK_REQUEST:
        todo = {
          type: updatesData?.type,
          title: updatesData?.title,
          dueDate: updatesData?.data?.endOn,
          description: updatesData?.data?.description,
          todoable: {
            ...updatesData?.data,
            feedbackId: updatesData?.updatableId,
            questions: { questions: updatesData?.data?.questions },
          },
          groups: updatesData?.groups,
          owner: updatesData?.createdBy,
        };
        return (
          <SurveyTodo
            todo={todo}
            callback={callback}
            viewSummary={viewSummary}
            showArchive={false}
          />
        );
      case TODO_TYPE.LEAVE_REQUEST:
        return (
          <LeavesTodo
            todo={updatesData?.data}
            callback={callback}
            showArchive={false}
          />
        );
      default:
        return <Page404 />;
    }
  };

  return (
    <Modal
      width="680px"
      key={updatesData.id}
      title={
        <Wrapper>
          <Row>
            <Col>{capitalize(updatesData?.updatableType)}</Col>
          </Row>
        </Wrapper>
      }
      visible={visible}
      closeModal={() => {
        setVisible(false);
      }}
      afterClose={afterClose}
      content={
        <StepsWrapper>
          <div className="steps-content">
            <Component />
          </div>
        </StepsWrapper>
      }
      footer={<></>}
    />
  );
}

export default UpdatesModal;
