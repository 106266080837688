import styled from 'styled-components';
import { Form as AntForm, Layout } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { y, x, link } from 'design/Styled';
import Spacings from 'design/Spacings';
import { Loginbg } from 'assets';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  display: flex;
  justify-content: center;

  &.ant-layout-content {
    overflow-y: hidden;
    .ant-btn-primary {
      line-height: 22px;
    }
  }

  .back-icon {
    border-radius: 4px;
    background: linear-gradient(#6fbaff, rgba(0, 0, 0, 0.4));
    color: ${Colors.TextLight};
    cursor: pointer;
  }

  .right {
    float: right;
    margin-bottom: 0;
    width: fit-content;
  }
  .card {
    max-width: 700px;
    height: fit-content;
    text-align:center;
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
  }
`;

export const Heading = styled.div`
  font: ${fonts.xxLarge.bold};
  color: ${Colors.TextPrimary};
  text-align: center;
  line-height: 41px;
  margin-bottom: ${Spacings.xxSmall.margin};
`;

export const Description = styled.div`
  font-size: ${fonts.small.regular};
  line-height: 22px;
  text-align: center;
  padding: 0px 0px 5px;
  color: ${Colors.TextSecondary};
`;
export const ConfirmLink = styled.p`
  font-size: ${fonts.small.regular};
  line-height: 26px;
  text-align: center;
  padding: 0px 0px 10px;
  color: ${Colors.TextSecondary};
`;
