import React from 'react';
import { Spin } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import UsersWidgetDisplay from 'atoms/UsersWidgetDisplay';
import UsersWidgetAddRemove from 'atoms/UsersWidgetAddRemove';
import { BUTTON_TYPE, POPOVER_TYPE } from 'utils/constants';
import Colors from 'design/Colors';
import Button from 'atoms/Button';
import { Li, Labels, Popover } from './AntStyled';

export default function UsersWidgetPopover({
  skip = 2,
  placement,
  data = [],
  createGroupUser,
  deleteGroupUser,
  user = [],
  modify = true,
  spinLoader,
  profileSize,
  setIsPopoverOpen,
  button,
}) {
  return (
    <>
      <Popover
        content={
          <UsersWidgetAddRemove
            showSearch
            alreadyMembers={data && data}
            addUsers={user}
            modify={modify}
            button={button}
            createGroupUser={(id) => createGroupUser(id)}
            deleteGroupUser={(id) => deleteGroupUser(id)}
            spinLoader={spinLoader}
            setIsPopoverOpen={setIsPopoverOpen}
          />
        }
        placement={placement}
        trigger={POPOVER_TYPE.HOVER}
      >
        {data && data.length > skip && (
          <Li index={9} size={profileSize}>
            <Labels size={profileSize}>{`+${data.length - skip}`}</Labels>
          </Li>
        )}
      </Popover>
      {modify && (
        <Li index={1} size={profileSize}>
          <Popover
            content={
              <UsersWidgetAddRemove
                showSearch
                alreadyMembers={data && data}
                addUsers={user}
                modify={modify}
                createGroupUser={(id) => createGroupUser(id)}
                deleteGroupUser={(id) => deleteGroupUser(id)}
                spinLoader={spinLoader}
                profileSize={profileSize}
                setIsPopoverOpen={setIsPopoverOpen}
              />
            }
            placement={placement}
            trigger={POPOVER_TYPE.CLICK}
          >
            {button ? (
              <Button tableButton type={BUTTON_TYPE.PRIMARY} name="Assign" />
            ) : (
              <Button
                outline
                className="padding"
                type={BUTTON_TYPE.DASHED}
                shape={BUTTON_TYPE.CIRCLE}
                icon={<UserAddOutlined />}
                style={{ color: Colors.IconColor }}
              />
            )}
          </Popover>
        </Li>
      )}
    </>
  );
}
