import React from 'react';

export const todosData = {
  title: {
    icon: (
      <i data-eva="person-done-outline" data-eva-height="18" data-eva-width="18"></i>
    ),
    heading: 'From Aug 05 to Aug 10(First Half)',
  },
  user: {
    firstName: 'Camer',
    lastName: 'Williamson',
  },
  content: {
    title: 'Message',
    message: 'Hello Managers, Need to take a day off today as I’m not feeling well',
    duration: 'Duration: 1 Day',
    date: 'On: Thu, 20 May, 2021',
    type: 'Paid Leave',
  },
  form: {
    title: 'Revert on request',
  },
};
