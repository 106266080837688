import { Col, Divider, Row, Space } from 'antd';
import Button from 'atoms/Button';
import DateTime from 'atoms/DateTime';
import UserProfile from 'molecules/UserProfile';
import moment from 'moment';
import GroupTagList from 'organisms/GroupTagList';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FEEDBACK_TYPE, TODO_ICONS, TODO_TYPE } from 'utils/constants';

function AnnouncementWorkUpdate({
  data,
  addToTodo,
  performTask,
  isViewed,
  createdAt,
  updatedAt,
}) {
  const {
    data: { title: feedbackTitle, type: feedbackType, hasCompleted },
    groups,
    title,
    createdBy: user,
    type,
  } = data;
  return (
    <div>
      <div className="update-template-header">
        <Row className="title-row" align="middle">
          <Col>{TODO_ICONS.ANNOUNCEMENT_PUBLISHED}</Col>
          <Col className="title">{title}</Col>
        </Row>
        {user && (
          <UserProfile
            name={`${user?.firstName} ${user?.lastName}`}
            stackedName={false}
            userId={user?.id}
            profile={user?.profileImage}
            size={16}
            showLink
          />
        )}
        <div className="dot" />
        <div className="time">
          <DateTime date={isViewed ? updatedAt : createdAt} fromNow />
        </div>
      </div>
      <div className="update-template-body">{feedbackTitle}</div>
      <div className="update-template-footer">
        <div>
          <GroupTagList departments={groups} limit={3} />
        </div>
        <div>
          {/* hiding this because it is not researched fully or had it's correct purpose for  */}
          {/* {!isViewed && !hasCompleted && (
            <Button
              className="template-button"
              name={<FormattedMessage id="workUpdate.addTodo" />}
              onClick={addToTodo}
            />
          )} */}
          <Button
            name={<FormattedMessage id="workUpdate.readAnnouncement" />}
            onClick={performTask}
          />
        </div>
      </div>
    </div>
  );
}

export default AnnouncementWorkUpdate;
