import styled from 'styled-components';
import { Layout, Input } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { TextArea } = Input;
const { Content: AntContent } = Layout;

export const TextBox = styled(TextArea)`
  border: 1px solid ${Colors.Border};
  border-radius: 10px;
`;
export const Content = styled(AntContent)`
  display: flex;
  align-items: center;
  flex-direction: column;

  &.ant-layout-content {
    padding: ${Spacings.medium.padding};
    overflow-y: auto;
    .ant-btn-primary {
      line-height: 22px;
    }
  }
  color: ${Colors.TextEnable};
  .ant-comment-inner {
    padding: 0px;
  }
  .card {
    max-width: 700px;
    .ant-card-head-title {
      white-space: unset;
    }
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
  }
  .ant-col .ant-form-item-label {
    padding: 0;
  }
  .title {
    color: ${Colors.TemplateHeading};
    font: ${fonts.small.bold};
    margin-bottom: calc(${Spacings.xxSmall.margin} + 2px);
  }

  .tab {
    margin-top: calc(${Spacings.xSmall.margin} + 2px);
    .comments{
      padding:0px 16px 16px;
    }
    .log {
      padding:0px 16px 16px;
      max-height: 400px;
      overflow: auto;
    }
    .ant-tabs-nav-scroll{
      padding:16px 16px 0px;
    }
  }
  .description {
    color: ${Colors.TextEnable};
    font: ${fonts.xxSmall.regular};
    margin-bottom: ${Spacings.xxSmall.margin};
    line-height: 20px;
  }
  .ant-tabs {
    color: ${Colors.TextEnable};
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 0px 0px 16px 0px;
    margin: 0 30px 0 0;
    font: ${fonts.small.regular};
  }
  .ant-tabs-bar {
    border-bottom: 2px solid #cad6e3;
  }
  .ant-tabs-ink-bar {
    height: 4px;
  }
  .ant-tabs-nav-container {
    font: ${fonts.medium.regular};
  }
`;
export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Text};
  cursor: pointer;
  .anticon {
    margin-right: ${Spacings.xSmall.margin};
  }
`;
export const Arrow = styled.img`
  margin-right: ${Spacings.xSmall.margin};
`;

export const StyledBody = styled.div`
  max-height: ${({ showReadMore }) => showReadMore ? '400px' : 'unset'};
  overflow: hidden;
  position: relative;
  .show-more-btn{
    position: absolute;
    bottom:0;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100px;
    background: linear-gradient(to bottom, #ffffffa1, #FFFFFF);
    box-shadow:1px 1px 20px 6px white;
  }
`;
