import styled from 'styled-components';
import { Form as AntForm } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { y, x } from 'design/Styled';
import Spacings from 'design/Spacings';
import { Loginbg } from 'assets';

export const Container = styled.div`
  ${x};
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${Colors.SidebarText};

  .ant-btn-primary {
    font: ${fonts.small.regular};
    line-height: 22px;
  }
  .ant-card {
    width: 100%;
    max-width: 500px;
  }
  .right {
    float: right;
  }
  .terms {
    color: ${Colors.TextSecondary};
    margin-right: ${Spacings.Small.margin};
    :last-child {
      margin-right: 0px;
    }
    @media (max-width: 1000px) {
      color: ${Colors.SidebarText};
    }
  }
`;
export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
`;
export const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${Loginbg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;

  .bottom-left {
    position: absolute;
    left: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
  .bottom-right {
    position: absolute;
    right: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
`;
export const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 180px;
  transform: translate(-50%, -50%);
  @media (max-width: 1120px) {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const Wrapper = styled.div`
  .ant-card {
    overflow: hidden;
    width: 100%;
    max-width: 408px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  @media (max-width: 1120px) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;
export const EmailVerified = styled.div`
  color: ${Colors.ButtonPrimary};
  font: ${fonts.medium.regular};
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin-bottom: ${Spacings.xSmall.margin};
`;

export const Title = styled.div`
  font: ${fonts.xxLarge.bold};
  color: ${Colors.TextDark};
  margin-bottom: ${Spacings.small.margin};
`;

export const Description = styled.div`
  font: ${fonts.small.regular};
  margin-bottom: ${Spacings.small.margin};
`;

export const Terms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 410px;
  padding-top: 10px;
  align-self: center;
  font: ${fonts.xSmall.bold};
`;
