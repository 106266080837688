import React, { useEffect, useState, useContext } from 'react';
import { Layout, message } from 'antd';
import queryString from 'query-string';
import moment from 'moment';
import {
  Router, Switch, Route, withRouter,
} from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import client from 'apollo';
import styled from 'styled-components';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';

import Colors from 'design/Colors';
import { useQuery } from '@apollo/client';

import Meta from 'atoms/Meta';
import Button from 'atoms/Button';
import Filters from 'molecules/Filters';
import Header from 'layout/Header';
import Spacings from 'design/Spacings';
import Blockers from 'features/dailyGoals/templates/Blockers';
import InfoIcon from 'atoms/InfoIcon';
import {
  PRIVATE_ROUTE,
  META_TYPE,
  FETCH_POLICY,
  EMPTY_ARRAY,
  UNDEFINED,
  TASK_TYPE,
  USER_ACTION,
  PAGE_LIMIT,
} from 'utils/constants';
import { DAILY_GOALS_AND_BLOCKERS } from 'features/dailyGoals/graphql/DailyGoalsAndBlockers/Queries';
import { UPDATE_TASK } from 'features/dailyGoals/graphql/Me/Mutations';
import * as Sentry from '@sentry/react';
import { fonts } from 'design/Fonts';
import { AppContext } from 'AppContext';
import Page404 from 'pages/404';
import _findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = styled.div`
  margin-left: ${Spacings.xxSmall.margin};
  .tab {
    width: 100px;
    height: 34px;
    font: ${fonts.small.regular};
    padding: 0px ${Spacings.xxSmall.padding};
    box-sizing: border-box;
    border-radius: 6px 0px 0px 6px;
    &:nth-child(2) {
      border-radius: 0px 6px 6px 0px;
    }
    &:hover {
      color: ${Colors.TextPrimaryHover};
      border-color: ${Colors.TextPrimaryHover};
    }
    @media screen and (min-width: 1100px) {
      width: 128px;
    }
  }
  & .tab.active {
    font-weight: 600;
  }
`;

const BlockersPage = (props) => {
  const { history, intl } = props;
  const { id, d } = queryString.parse(history.location.search);

  const [activeTab, setActiveTab] = useState(null);
  const [activeBlockers, setActiveBlockers] = useState(EMPTY_ARRAY);
  const [archivedBlockers, setArchivedBlockers] = useState(EMPTY_ARRAY);
  const [groupId, setGroupId] = useState(id ? [parseInt(id, 10)] : EMPTY_ARRAY);
  const [locationId, setLocationId] = useState();
  const [userId, setUserId] = useState();
  const [date, setDate] = useState(
    d
      ? [moment(d, 'MM-DD-YYYY'), moment(d, 'MM-DD-YYYY')]
      : [moment().startOf('isoWeek'), moment().endOf('isoWeek')]
  );
  const [sortData, setSortData] = useState({
    field: 'createdAt',
    type: 'DESC',
  });
  const [isBlockerCompleted, setIsBlockerCompleted] = useState();
  const [selectedBlockersType, setSelectedBlockersType] = useState('all');
  const [tableLoader, setTableLoader] = useState(false);
  const [dataCount, setDataCount] = useState();
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const {
    state: { loginUser },
  } = useContext(AppContext);

  const { data: blockersData, loading: blockersLoading } = useQuery(
    DAILY_GOALS_AND_BLOCKERS,
    {
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      variables: {
        where: {
          locationId,
          groupId: groupId.length !== 0 ? groupId : UNDEFINED,
          type: TASK_TYPE.BLOCKER,
          ownerUserId: userId && userId.length > 0 ? userId : UNDEFINED,
          from:
            date && date.length > 0
              ? date[0].format('YYYY-MM-DD')
              : moment().startOf('isoWeek').format('YYYY-MM-DD'),
          to:
            date && date.length > 0
              ? date[1].format('YYYY-MM-DD')
              : moment().endOf('isoWeek').format('YYYY-MM-DD'),
          isCompleted: isBlockerCompleted,
          isArchive: activeTab !== 0,
        },
        sortBy: sortData.type,
        sortOn: sortData.field,
        skip,
        limit: PAGE_LIMIT,
      },
    }
  );

  useEffect(() => {
    if (!blockersLoading && blockersData) {
      let blockersTableData = EMPTY_ARRAY;
      blockersTableData = _map(
        blockersData.dailyGoalsAndBlockers.actions,
        (data, key) => ({
          key: key.toString(),
          id: data.id,
          isCompleted: data.isCompleted,
          completedOn: data.completedOn,
          createdAt: data.createdAt,
          name: data.name,
          estimation: data.estimation,
          completionHours: data.completionHours,
          group: data.group,
          ownerUser: data.owner,
          user: data.user,
          comments: data.comments ? data.comments : [],
          isArchive: data.isArchive,
          reason: data.reason,
          canArchive: data.canArchive,
        })
      );

      if (blockersData.dailyGoalsAndBlockers.actions.length === 0) {
        setHasMore(false);
        return;
      }
      const archiveFilters = _groupBy(blockersTableData, 'isArchive');
      setActiveBlockers([
        ...activeBlockers,
        ...(archiveFilters.false || EMPTY_ARRAY),
      ]);
      setArchivedBlockers([
        ...archivedBlockers,
        ...(archiveFilters.true || EMPTY_ARRAY),
      ]);
      setDataCount(blockersData.dailyGoalsAndBlockers.count);
      setLoader(false);
      setTableLoader(false);
      if (blockersData.dailyGoalsAndBlockers.count <= PAGE_LIMIT) {
        setHasMore(false);
      }
    }
  }, [blockersData, blockersLoading]);

  const tabs = [
    <FormattedMessage id="dailyGoals.active" />,
    <FormattedMessage id="dailyGoals.archived" />,
  ];

  const Title = () => (
    <Wrapper>
      <InfoIcon title={<FormattedMessage id="blockers.text" />} />
      <Tabs>
        {_map(tabs, (list, index) => (
          <Button
            key={index}
            className={`tab ${index === activeTab ? 'active' : ''}`}
            background={Colors.TextLight}
            border={
              index === activeTab ? Colors.PrimaryColor : Colors.SecondaryColor
            }
            color={
              index === activeTab ? Colors.PrimaryColor : Colors.TextSecondary
            }
            focus={Colors.TextLight}
            name={list}
            onClick={() => callback(index)}
          />
        ))}
      </Tabs>
    </Wrapper>
  );

  useEffect(() => {
    const { pathname } = history.location;
    if (pathname.split('/')[3] === PRIVATE_ROUTE.KEY.ACTIVE) {
      setActiveTab(0);
    } else if (pathname.split('/')[3] === PRIVATE_ROUTE.KEY.ARCHIVED) {
      setActiveTab(1);
    }
  }, [history, history.location]);

  const callback = (key) => {
    setActiveTab(key);
    setTableLoader(true);
    resetBlockersData();
    if (key === 0) {
      history.push(PRIVATE_ROUTE.BLOCKER_ACTIVE);
    } else {
      history.push(PRIVATE_ROUTE.BLOCKER_ARCHIVED);
    }
    setHasMore(true);
  };

  const onActions = async (rowData, list) => {
    const goalIndex = _findIndex(
      list === USER_ACTION.ARCHIVE_BLOCKER ? activeBlockers : archivedBlockers,
      { id: rowData.id }
    );
    const tempDailyBlockers = cloneDeep(
      list === USER_ACTION.ARCHIVE_BLOCKER ? activeBlockers : archivedBlockers
    );
    tempDailyBlockers[goalIndex] = { loading: true, id: rowData.id };
    list === USER_ACTION.ARCHIVE_BLOCKER
      ? setActiveBlockers([...tempDailyBlockers])
      : setArchivedBlockers([...tempDailyBlockers]);
    try {
      client
        .mutate({
          mutation: UPDATE_TASK,
          variables: {
            data: [
              {
                where: {
                  id: parseInt(rowData.id, 10),
                },
                input: {
                  isArchive: list === USER_ACTION.ARCHIVE_BLOCKER,
                },
              },
            ],
          },
        })
        .then(() => {
          tempDailyBlockers.splice(goalIndex, 1);
          list === USER_ACTION.ARCHIVE_BLOCKER
            ? setActiveBlockers([...tempDailyBlockers])
            : setArchivedBlockers([...tempDailyBlockers]);
        });
    } catch (e) {
      message.destroy();
      message.error(intl.formatMessage({ id: 'error.somethingWentWrong' }));
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const handleInfiniteOnLoad = () => {
    setLoader(true);
    const dataLength = activeTab === 0 ? activeBlockers.length : archivedBlockers.length;
    if (dataCount <= dataLength) {
      setHasMore(false);
      setLoader(false);
      setPage(1);
    } else {
      setPage(prev => prev + 1);
      const count = page * PAGE_LIMIT;
      setSkip(count);
    }
  };

  const resetBlockersData = () => {
    setActiveBlockers([]);
    setArchivedBlockers([]);
    setHasMore(true);
    setPage(1);
    setSkip(0);
  };

  const onChangeDateFilter = (e) => {
    e && e.preventDefault();
    resetBlockersData();
  };

  const handleTaskTypeChange = () => {
    resetBlockersData();
  };

  const handleGroupOrUserFilterChange = () => {
    resetBlockersData();
  };

  return (
    <Sentry.ErrorBoundary fallback="Something went wrong">
      <Layout>
        <Router history={history}>
          <Meta title={META_TYPE.PROFILE} />
          {activeTab !== null && (
            <>
              <Header title={<Title />} />
              <Filters
                masterFilter
                daysFilter
                groupsFilters={setGroupId}
                locationFilters={setLocationId}
                setDateFilterValue={setDate}
                dateFilterValue={date}
                groupValue={groupId}
                setUsersValue={setUserId}
                showUsersFilter
                usersValue={userId}
                showTaskFilter
                setSelectedGoalsType={setSelectedBlockersType}
                selectedGoalsType={selectedBlockersType}
                onChangeDateFilter={onChangeDateFilter}
                handleTaskTypeChange={handleTaskTypeChange}
                handleGroupOrUserFilterChange={handleGroupOrUserFilterChange}
                dataCount={dataCount}
              />
            </>
          )}
          <Switch>
            <Route
              exact
              path={PRIVATE_ROUTE.BLOCKER_ACTIVE}
              render={() => (
                <Blockers
                  loading={
                    activeBlockers.length ? tableLoader : blockersLoading
                  }
                  blockers={activeBlockers}
                  onActions={onActions}
                  archive={activeTab === 0}
                  setSortData={setSortData}
                  hasMore={hasMore}
                  loader={loader}
                  handleInfiniteOnLoad={handleInfiniteOnLoad}
                  loginUser={loginUser}
                  handleGroupOrUserFilterChange={handleGroupOrUserFilterChange}
                  dataCount={dataCount}
                />
              )}
            />
            <Route
              path={PRIVATE_ROUTE.BLOCKER_ARCHIVED}
              render={() => (
                <Blockers
                  loading={
                    archivedBlockers.length ? tableLoader : blockersLoading
                  }
                  blockers={archivedBlockers}
                  onActions={onActions}
                  archive={activeTab === 0}
                  showReason
                  setSortData={setSortData}
                  hasMore={hasMore}
                  loader={loader}
                  handleInfiniteOnLoad={handleInfiniteOnLoad}
                  loginUser={loginUser}
                  handleGroupOrUserFilterChange={handleGroupOrUserFilterChange}
                />
              )}
            />
            <Route component={Page404} />
          </Switch>
        </Router>
      </Layout>
    </Sentry.ErrorBoundary>
  );
};

export default {
  key: PRIVATE_ROUTE.BLOCKER_ACTIVE,
  component: withRouter(BlockersPage),
  name: 'Team Blockers',
  icon: <i data-eva="bar-chart-outline" data-eva-height="20" data-eva-width="20"></i>,
};
