import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { IntlProvider } from 'react-intl';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ConfirmationModalStyle } from 'atoms/ConfirmationModal/ConfirmationModal';
import * as Sentry from '@sentry/react';
import moment from 'moment';

import amplitude from 'amplitude-js';
import App from './App';
import { AppContextProvider } from './AppContext';
import client from './apollo';
import * as serviceWorker from './serviceWorker';
import './index.less';
import { AbilityContext } from './utils/ability/ability-context';
import ability from './utils/ability/ability';
import Strings from './design/Strings';
import flatten from './utils/flatten';
import jwt_decode from 'jwt-decode';
import ReactGA from 'react-ga';

const { confirm } = Modal;

const token = localStorage.getItem('token');
const userData = token && jwt_decode(token);

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
amplitude
  .getInstance('New Instance')
  .init(process.env.REACT_APP_AMPLITUDE_API_KEY, userData?.id);

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
  });
}

if (process.env.REACT_APP_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

moment.defineLocale('en-gb', {
  week: {
    dow: 1,
  },
});
ReactDOM.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <AbilityContext.Provider value={ability}>
        <IntlProvider locale="en" messages={flatten(Strings)}>
          <App />
        </IntlProvider>
      </AbilityContext.Provider>
    </AppContextProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      const handleReload = () => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      };

      confirm({
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            <ConfirmationModalStyle /> New version available! Click Reload to
            get the latest version.
          </>
        ),
        okText: 'Reload',
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { className: 'ant-confirm-modal-ok-button' },
        className: 'ant-confirm-modal-styled',
        onOk: handleReload,
      });
    }
  },
};
serviceWorker.register(configuration);
