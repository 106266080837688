import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import _slice from 'lodash/slice';
import { useQuery } from '@apollo/client';
import { Tooltip } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';

import UsersWidgetPopover from 'molecules/UsersWidgetPopover';
import { EMPTY_ARRAY, FETCH_POLICY } from 'utils/constants';
import UserProfile from 'molecules/UserProfile';
import Colors from 'design/Colors';
import { Li, Profile } from './AntStyled';
import { GET_USERS } from './graphql/queries';

export default function UsersListWidgetAsync({
  skip,
  placement,
  data = [],
  alreadyMembers = EMPTY_ARRAY,
  createGroupUser,
  deleteGroupUser,
  allowDelete = true,
  groupId,
  spinLoader,
  profileSize,
  setIsPopoverOpen,
  button,
}) {
  const { data: userData } = useQuery(GET_USERS, {
    variables: groupId
      ? { where: { groupIds: [parseInt(groupId, 10)] } }
      : null,
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
  });

  const [user, setUsers] = useState(EMPTY_ARRAY);

  useEffect(() => {
    if (data && userData) {
      let alreadyMembersId = EMPTY_ARRAY;
      alreadyMembersId = _map(alreadyMembers, (items) => items?.user?.id);
      const filter = userData?.users?.users?.filter((list) => {
        if (!alreadyMembersId) {
          return true;
        }
        return !alreadyMembersId?.includes(list?.id);
      });
      setUsers(filter);
    }
  }, [alreadyMembers, data, userData]);
  return (
    <Profile>
      {data[0]?.user ? (
        <>
          {_map(_slice(data, 0, skip), (items, index) => (
            <Li key={index} index={index === 0 ? 999 : 99}>
              <Tooltip
                className="tooltip"
                placement="bottom"
                title={
                  items?.user?.firstName && items?.user?.lastName
                    ? `${items?.user?.firstName} ${items?.user?.lastName}`
                    : items?.user?.email
                }
              >
                <UserProfile
                  right="0"
                  name={
                    items?.user?.firstName && items?.user?.lastName
                      ? `${items?.user?.firstName} ${items?.user?.lastName}`
                      : items?.user?.email
                  }
                  profile={items?.user?.profileImage}
                  onlyIcon
                  size={profileSize}
                />
                {allowDelete && (
                  <CloseCircleTwoTone
                    twoToneColor={Colors.PrimaryColor}
                    className="delete-icon"
                    onClick={() => deleteGroupUser(items?.user?.id)}
                  />
                )}
              </Tooltip>
            </Li>
          ))}
          <UsersWidgetPopover
            skip={skip}
            placement={placement}
            data={data}
            user={user}
            alreadyMembers={alreadyMembers}
            createGroupUser={createGroupUser}
            deleteGroupUser={deleteGroupUser}
            spinLoader={spinLoader}
            profileSize={profileSize}
            setIsPopoverOpen={setIsPopoverOpen}
            button={button}
            modify={false}
          />
        </>
      ) : (
        _map(_slice(data, 0, skip), (items, index) => (
          <Li key={index} index={index === 0 ? 999 : 99}>
            <Tooltip
              className="tooltip"
              placement="bottom"
              title={
                items?.firstName && items?.lastName
                  ? `${items?.firstName} ${items?.lastName}`
                  : items?.email
              }
            >
              <UserProfile
                right="0"
                name={
                  items?.firstName && items?.lastName
                    ? `${items?.firstName} ${items?.lastName}`
                    : items?.email
                }
                profile={items?.profileImage}
                onlyIcon
                size={profileSize}
              />
            </Tooltip>
          </Li>
        ))
      )}
    </Profile>
  );
}
