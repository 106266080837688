import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Progress,
  Spin,
  Checkbox,
  Slider,
  Input,
  Tooltip,
} from 'antd';
import Card from 'atoms/Card';
import GroupTagList from 'organisms/GroupTagList';
import UserProfile from 'molecules/UserProfile';
import Statistics from 'atoms/Statistics';
import Colors from 'design/Colors';
import ContentWrapper from 'atoms/ContentWrapper';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_FEEDBACK_RESPONSE,
  GET_FEEDBACK_SUMMARY,
} from 'features/feedbacks/graphql/Queries';
import { FETCH_POLICY, QUESTION_TYPE } from 'utils/constants';
import PercentFormat from 'utils/PercentFormat';
import DateTime from 'atoms/DateTime';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import Button from 'atoms/Button';
import { AppContext } from 'AppContext';
import _get from 'lodash/get';
import _map from 'lodash/map';
import ability from 'utils/ability/ability';
import _isNumber from 'lodash/isNumber';
import { END_FEEDBACK } from 'features/feedbacks/graphql/Mutations';
import moment from 'moment';
import {
  Content,
  Divider,
  Title,
  Text,
  HR,
  Created,
  TextBy,
  UserDiv,
  StyledDiv,
} from './AntStyled';
import { Modal, QuestionTitle } from '../Response/AntStyled';
const { TextArea } = Input;

export default function Report() {
  const history = useHistory();
  const { id } = useParams();
  const { state } = useContext(AppContext);
  const loginUserId = _get(state, 'loginUser.id', null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [timeZone, setTimeZone] = useState('Asia/Kolkata');
  const userTimeZone = _get(state, 'loginUser.location.timeZone', null);
  useEffect(() => {
    if (userTimeZone) setTimeZone(userTimeZone);
  }, [userTimeZone]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [endFeedback] = useMutation(END_FEEDBACK, {
    onCompleted() {
      history.goBack();
    },
  });
  const { data = {}, loading } = useQuery(GET_FEEDBACK_SUMMARY, {
    variables: { where: { id } },
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const { data: responseData = {}, loading: responseLoading } = useQuery(
    GET_FEEDBACK_RESPONSE,
    {
      variables: { where: { feedbackId: id, userIds: loginUserId } },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    }
  );
  const { feedbackResponse = [] } = responseData;

  const { feedbackSummary: { feedback = {} } = {} } = data;
  return (
    <ContentWrapper>
      <Content>
        <Spin spinning={loading || responseLoading}>
          <StyledDiv id="drop">
            <Row
              gutter={16}
              className="width-100 flex no-wrap"
              justify="center"
            >
              <Col flex="300px">
                <Card>
                  <Row>
                    <Col className="col-1">
                      <Progress
                        width={170}
                        type="circle"
                        trailColor="lightgrey"
                        strokeLinecap="square"
                        percent={PercentFormat(
                          data?.feedbackSummary?.participants,
                          data?.feedbackSummary?.responded
                        )}
                        strokeWidth={7}
                        format={(percent) => (
                          <div className="poll-content">
                            <div className="poll-head">
                              {percent}
                              <span className="poll-percent">%</span>
                            </div>
                            <div className="poll-description">
                              Survey Completion
                            </div>
                          </div>
                        )}
                      />
                      <Col className="col-statistics">
                        <Statistics
                          width="260px"
                          number={feedback?.questions?.count}
                          description="Questions"
                          color={Colors.ButtonPrimary}
                        />
                      </Col>
                      <Col className="col-statistics">
                        <Statistics
                          width="260px"
                          number={data?.feedbackSummary?.participants}
                          description="Total Participants"
                          color={Colors.ButtonPrimary}
                        />
                      </Col>
                      <Row gutter={16}>
                        <Col className="col-statistics">
                          <Statistics
                            width="122px"
                            number={data?.feedbackSummary?.responded}
                            description="Responded"
                            color={Colors.TextSuccess}
                          />
                        </Col>
                        <Col className="col-statistics">
                          <Statistics
                            width="122px"
                            number={
                              _isNumber(
                                data?.feedbackSummary?.participants ||
                                  data?.feedbackSummary?.responded
                              )
                                ? data?.feedbackSummary?.participants -
                                  data?.feedbackSummary?.responded
                                : 0
                            }
                            description="Not Responded"
                            color={Colors.TextDanger}
                          />
                        </Col>
                      </Row>
                      <Tooltip
                        position="bottom"
                        getPopupContainer={() =>
                          document.getElementById('drop')
                        }
                        title={
                          feedbackResponse?.length === 0
                            ? 'You haven’t answered this feedback'
                            : ''
                        }
                      >
                        <Col className="col-statistics">
                          <Button
                            className="large-button"
                            name="View my response"
                            onClick={() => setIsModalVisible(true)}
                            disabled={feedbackResponse?.length === 0}
                            primary
                          />
                        </Col>
                      </Tooltip>
                      {ability.can('manage', 'feedbacks') &&
                      (moment(moment(feedback?.endOn).tz(timeZone)).isAfter(
                        moment()
                      ) ||
                        feedback?.endOn === null) ? (
                        <Col className="col-statistics">
                          <Button
                            className="large-button"
                            name="End this Survey"
                            onClick={() =>
                              endFeedback({ variables: { where: { id } } })
                            }
                            secondary
                          />
                        </Col>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col flex="auto">
                <Card className="card-2">
                  <Col className="no-padding">
                    <Title>Survey: {feedback?.title}</Title>
                    <Text type="secondary">{feedback?.description}</Text>
                    <div className="departments-tag-row">
                      <GroupTagList
                        type="department"
                        departments={feedback?.groups}
                      />
                    </div>
                    <HR type="horizontal" />
                    <Row justify="start">
                      <Col className="timeline">
                        <Created>Created on&nbsp;</Created>
                        <DateTime
                          date={feedback?.scheduleOn}
                          format={MomentFormat.at}
                        />
                      </Col>
                      <Col className="col-end">
                        {feedback?.endOn ? (
                          <>
                            <Created>Ends on &nbsp;</Created>
                            <DateTime
                              date={feedback?.endOn}
                              format={MomentFormat.at}
                            />
                          </>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                    <Col className="timeline text no-padding">
                      <UserDiv>
                        <TextBy className="text">Created By:&nbsp;</TextBy>
                        <UserProfile
                          size={20}
                          stackedName={false}
                          name={`${feedback?.createdBy?.firstName} ${feedback?.createdBy?.lastName}`}
                          profile={feedback?.createdBy?.profileImage}
                          userId={feedback?.createdBy?.id}
                          showLink
                        />
                      </UserDiv>
                    </Col>
                  </Col>
                </Card>
              </Col>
            </Row>
          </StyledDiv>

          {_map(feedbackResponse, (response) => (
            <Modal
              title={
                <UserProfile
                  profile={response?.answeredBy?.profileImage}
                  name={`${response?.answeredBy?.firstName} ${response?.answeredBy?.lastName}`}
                  userId={response?.answeredBy?.id}
                  showLink
                />
              }
              footer={null}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              width={1000}
            >
              {_map(response?.answers, (question, index) => (
                <>
                  <QuestionTitle level={4}>
                    {index + 1}. {question?.question?.question}
                  </QuestionTitle>
                  {question?.question?.questionType ===
                  QUESTION_TYPE.OPINION_SCALE ? (
                    <Card>
                      <Row gutter={[16, 16]} className="no-wrap">
                        <Col className="flex align-items-center">
                          {question?.question?.scaleLabel?.minLabel}
                        </Col>
                        <Col span={12}>
                          {' '}
                          <Slider
                            min={0}
                            max={10}
                            value={question?.answer?.answer}
                          />
                        </Col>
                        <Col className="flex align-items-center">
                          {question?.question?.scaleLabel?.maxLabel}
                        </Col>
                      </Row>
                    </Card>
                  ) : question?.question?.questionType === QUESTION_TYPE.MCQ ? (
                    <Card>
                      <Checkbox.Group
                        options={question?.question?.choices}
                        value={question?.answer?.answer}
                      ></Checkbox.Group>
                    </Card>
                  ) : question?.question?.questionType ===
                    QUESTION_TYPE.TEXT ? (
                    <TextArea
                      value={question?.answer?.answer}
                      rows={4}
                    ></TextArea>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </Modal>
          ))}
        </Spin>
      </Content>
    </ContentWrapper>
  );
}
