import { gql } from '@apollo/client';
import { actionFragment } from './Fragments';

export const CREATE_TASK = gql`
mutation createActions($data:[ActionCreateInput]!){
  createActions(data:$data){
    ...ActionFragment
  }
}
${actionFragment}
`;

export const UPDATE_TASK = gql`
mutation updateActions($data:[ActionUpdateDataInput]){
  updateActions(data:$data){
    id
    type
  }
}
`;
