import { gql } from '@apollo/client';
import { groupFragment, actionFragment, userFragment } from './fragments';

export const GET_GROUPS = gql`
  query groups {
    groups {
      groups {
        ...GroupFragment
      }
    }
  }
  ${groupFragment}
`;

export const GET_USERS = gql`
  query users {
    users {
      users {
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;

export const GET_TODAY_CHECK_IN = gql`
  query todaysCheckIn {
    todaysCheckIn {
    id
    checkIn
    checkOut
    status
    createdAt
    }
  }
`;

export const PREVIOUS_DAY_ACTIONS = gql`
  query previousDayActions {
    previousDayActions {
      ...ActionFragment
    }
  }
  ${actionFragment}
`;

export const PREVIOUS_CHECK_IN_DETAILS = gql`
  query previousCheckedInDetails{
    previousCheckedInDetails{
    isFirstTimeCheckedIn
    showYesterdayUpdate
    checkedInDetails
    }
  }
`;

export const GET_TODAY_PUNCH_IN = gql`
  query todaysPunches {
    todaysPunches {
      id
      punchIn
      punchOut
      result
      status
      isBreak
    }
  }
`;
