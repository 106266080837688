/* eslint-disable no-console */
import React, { useEffect } from 'react';
import Header from 'layout/Header';
import { Layout } from 'antd';
import InfoIcon from 'atoms/InfoIcon';
import { META_TYPE, PRIVATE_ROUTE } from 'utils/constants';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import * as eva from 'eva-icons';
import 'emoji-mart/css/emoji-mart.css';
import Meta from 'atoms/Meta';
import moment from 'moment';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import ContentWrapper from 'atoms/ContentWrapper';
import PreviousStandUp from '../templates/PreviousStandUp';
import StandUpForm from '../templates/StandUpForm';
import { Content, DateWrapper } from './AntStyled';

const StandUpPage = (props) => {
  const { location } = props;
  useEffect(() => {
    eva.replace();
  }, []);

  return (
    <Layout>
      <Header
        title={
          <InfoIcon
            title={<FormattedMessage id="standUps.title" />}
            contentKey={META_TYPE?.STAND_UP}
          />
        }
      />
      <Meta title={META_TYPE.MY_CHECK_IN} />
      <DateWrapper>{moment().format(MomentFormat.MMMY)}</DateWrapper>
      <ContentWrapper>
        <Content>
          <div className="cards">
            <StandUpForm previousPath={location?.state?.from} />
            <PreviousStandUp />
          </div>
        </Content>
      </ContentWrapper>
    </Layout>
  );
};
export default {
  key: PRIVATE_ROUTE.MY_CHECK_IN,
  component: withRouter(StandUpPage),
  name: 'My Check-In',
};
