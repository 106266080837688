import styled from 'styled-components';
import { Typography } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Text } = Typography;

export const Content = styled.div`
  &.notification.ant-row {
    display: flex;
    justify-content: space-between;
    color: ${Colors.TextDark};
    background-color: ${Colors.Container};
  }
  & .column {
    flex-direction: column;
  }

  & .users {
    display: flex;
    align-items: center;
  }
  .btn {
    margin-top: ${Spacings.xxSmall.margin};
  }
  .text-row {
    margin-left: Calc(${Spacings.small.margin} + 2px);
  }
`;

export const StyledText = styled(Text)`
  font: ${fonts.xSmall.regular};
  color: ${Colors.Text};
`;
