import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    __typename
    id
    firstName
    lastName
    email
    profileImage
  }
`;

export const commentFragment = gql`
  fragment CommentFragment on Comment {
    id
    content
    createdBy {
      ...UserFragment
    }
    updatedBy {
      ...UserFragment
    }
    createdAt
    updatedAt
  }
  ${userFragment}
`;

export const groupFragment = gql`
  fragment GroupFragment on Group{
    __typename
    id
    name
    color
    type
    members{
    __typename
    user{
        ...UserFragment
      }
    }
    managers{
    __typename
    user{
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;

export const actionFragment = gql`
  fragment ActionFragment on Action {
    id
    name
    estimation
    completionHours
    isCompleted
    status
    createdAt
    user{
      ...UserFragment
    }
    ownerUser{
      ...UserFragment
    }
    comments {
     ...CommentFragment
    }
    group{
      ...GroupFragment
    }
  }
  ${groupFragment}
  ${userFragment}
  ${commentFragment}
`;
