/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Variables from 'design/Variables';

const StyledInput = styled(AntInput)`
  padding: ${({ large }) => (large ? '14px' : '4px 12px')};
  border-radius: ${Variables.AtomRadius};
  display: flex;
  align-items: center;
  border: ${({ bordered }) => (bordered === false ? '0px' : '1px solid')};
  border-color:${Colors.SecondaryColor};
  .ant-input {
    font-size: ${({ large }) => (large ? '16px' : '14px')};
    line-height: ${Variables.LineHeightMd};
    color: ${Colors.TextSecondary};
  }
  .ant-input-affix-wrapper > input.ant-input {
    font-size: ${({ large }) => large ? fonts.medium.regular : fonts.small.regular};
  }
  .ant-input-suffix {
    display: flex;
    align-items: center;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
  .ant-input-suffix .anticon-eye-invisible,
  .ant-input-suffix .anticon-eye {
    font-size: 14px;
    color: ${Colors.Text};
  }
  &:focus {
    box-shadow: none;
    border: ${({ bordered }) => (bordered === false ? '0px' : '1px solid')};
    border-color: ${Colors.BorderHover};
  }
  &:hover {
    border: ${({ bordered }) => (bordered === false ? '0px' : '1px solid')};
    border-color: ${Colors.BorderHover};
  }
  &.basic {
    border: 1px solid ${Colors.SecondaryColor};
  }
  &.active {
    border: 1px solid ${Colors.BorderHover};
  }
  &.Warning {
    border: 1px solid ${Colors.TextWarning};
  }
  &.success {
    border: 1px solid ${Colors.TextSuccess};
  }
`;

const Input = ({
  placeholder,
  className,
  children,
  allowClear,
  bordered,
  defaultValue,
  disabled,
  id,
  maxLength,
  prefix,
  size,
  suffix,
  type,
  value,
  onChange,
  onPressEnter,
  onBlur,
  focus,
  large,
  innerRef,
  autoFocus,
  addonBefore,
  ...restProps
}) => {
  const [currentType, setCurrentType] = useState(false);
  return (
    <StyledInput
      className={className}
      allowClear={allowClear}
      bordered={bordered}
      defaultValue={defaultValue}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      prefix={prefix}
      size={size}
      suffix={
        type === 'password' ? (
          currentType ? (
            <EyeOutlined onClick={() => setCurrentType(false)} />
          ) : (
            <EyeInvisibleOutlined onClick={() => setCurrentType(true)} />
          )
        ) : (
          suffix
        )
      }
      type={type === 'password' ? (currentType ? 'text' : 'password') : type}
      value={value}
      onChange={onChange}
      onPressEnter={onPressEnter}
      onBlur={onBlur}
      focus={focus}
      placeholder={placeholder}
      ref={innerRef}
      autoFocus={autoFocus}
      large={large}
      addonBefore={addonBefore}
      {...restProps}
    >
      {children}
    </StyledInput>
  );
};

export default Input;
