/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Spin, message, Checkbox, Radio } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ArrowIcon, BackArrow, Trash } from 'assets';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import InfoIcon from 'atoms/InfoIcon';
import Input from 'atoms/Input';
import * as eva from 'eva-icons';
import {
  FORM_NAME,
  PRIVATE_ROUTE,
  EMPTY_OBJECT,
  FETCH_POLICY,
  AMPLITUDE_EVENTS,
} from 'utils/constants';
import { useMutation, useQuery } from '@apollo/client';
import { GET_GROUPS } from 'graphql/Groups/Queries';
import { cloneDeep, get, map, has } from 'lodash';
import * as Sentry from '@sentry/react';
import { useForm } from 'antd/lib/form/util';
import { useParams } from 'react-router';
import _map from 'lodash/map';
import client from 'apollo';
import GroupTag from 'atoms/GroupTag';
import TextEditor, { initialValue } from 'organisms/TextEditor';
import Header from 'layout/Header';
import PopConfirm from 'atoms/PopConfirmation';
import {
  BrowserRouter,
  Prompt,
  useHistory,
  withRouter,
} from 'react-router-dom';
import { confirmationModal } from 'atoms/ConfirmationModal';
import DropdownButton from 'molecules/DropdownButton';
import Modal from 'atoms/Modal';
import moment from 'moment';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import { createAmplitudeEvent } from 'utils/amplitude';
import {
  GET_ALL_ANNOUNCEMENTS,
  GET_ANNOUNCEMENT_BY_ID,
} from '../../graphql/Queries';
import {
  CREATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  PUBLISH_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
} from '../../graphql/Mutations';
import {
  Content,
  ContentTitle,
  Form,
  Arrow,
  TextBox,
  Select,
  Image,
} from './AntStyled';

const { Option } = Select;

const NewAnnouncement = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const {
    location: { state },
    intl,
  } = props;
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1050);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1050);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  const [createAnnouncement] = useMutation(CREATE_ANNOUNCEMENT);
  const [publishAnnouncement] = useMutation(PUBLISH_ANNOUNCEMENT);
  const [updateAnnouncement] = useMutation(UPDATE_ANNOUNCEMENT);

  const [selected, setSelected] = useState('group');
  const [isUpdate, setIsUpdate] = useState(false);
  const [groups, setGroups] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isPromptRequired, setIsPromptRequired] = useState(false);
  const { data: userGroups, loading: groupsLoading } = useQuery(GET_GROUPS, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const [body, setBody] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = useForm();
  const [initialValues, setInitialValues] = useState({ for: 'group' });
  const FilterOption = ({ item, closable = false, onClose }) => (
    <GroupTag
      closeable={closable}
      closeOnClick={onClose}
      color={item.color}
      name={item.name}
      type={item.type}
    />
  );
  useEffect(() => {
    eva.replace();
  }, []);
  const onFinish = (values) => {
    setIsPromptRequired(false);
    setLoading(true);
    const bodyData = cloneDeep(body);
    const mutation = id ? updateAnnouncement : createAnnouncement;
    const where = id ? { where: { id } } : {};
    mutation({
      variables: {
        data: {
          title: values?.title,
          groups:
            selected === 'group'
              ? values?.groups
              : _map(userGroups?.groups?.groups, (val) => val?.id),
          body: { children: bodyData },
          assignTodos: isChecked,
        },
        ...where,
      },
      refetchQueries: [
        {
          query: GET_ALL_ANNOUNCEMENTS,
        },
      ],
    })
      .then((res) => {
        let announcementId = id;
        if (!announcementId) {
          announcementId = res?.data?.createAnnouncement?.id;
        }
        if (btnLoading === 'PUBLISHED') {
          publishAnnouncement({
            variables: {
              where: { id: announcementId },
            },
          })
            .then(() => {
              history.push(PRIVATE_ROUTE.ANNOUNCEMENT_ALL);
              message.success(
                `Announcement ${id ? 'Updated' : 'Created'} successfully`
              );
              setLoading(false);
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.log(e);
            });
        }
        const data = id
          ? res?.data?.updateAnnouncement
          : res?.data?.createAnnouncement;
        const eventProperties = {
          Id: data?.id,
          Title: data?.title,
          Body: data?.body,
          createdAt: moment
            .utc(data?.updatedAt)
            .local()
            .format(MomentFormat.dateTime),
          updatedBy: res?.data?.createCompany?.updatedBy?.email,
        };
        createAmplitudeEvent(
          id
            ? AMPLITUDE_EVENTS.ANNOUNCEMENT_UPDATED
            : AMPLITUDE_EVENTS.ANNOUNCEMENT_CREATED,
          eventProperties
        );
        if (btnLoading === 'DRAFT') {
          history.push(PRIVATE_ROUTE.ANNOUNCEMENT_DRAFT);
          message.success(
            `Announcement ${id ? 'Updated' : 'Created'} successfully`
          );
          setLoading(false);
        }
        setBtnLoading(false);
      })
      .catch((e) => {
        setBtnLoading(false);
        Sentry.captureException(e);
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const Title = () => (
    <>
      <ContentTitle>
        <Arrow src={BackArrow} alt="arrow" onClick={() => history.goBack()} />
        <InfoIcon
          title={
            id ? (
              initialValues?.title
            ) : (
              <FormattedMessage id="announcements.create" />
            )
          }
        />
      </ContentTitle>
    </>
  );

  const deleteAnnouncement = () => {
    setIsPromptRequired(false);
    const deleteConfirmation = async () => {
      try {
        await client
          .mutate({
            mutation: DELETE_ANNOUNCEMENT,
            variables: {
              where: { id },
            },
          })
          .then(() => {
            message.destroy();
            message.success(
              intl.formatMessage({
                id: 'users.successMessages.deleteAnnouncement',
              })
            );
            history.goBack();
          });
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.log(error);
      }
    };
    confirmationModal(
      intl.formatMessage({ id: 'announcements.sure' }),
      deleteConfirmation
    );
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      client
        .query({
          query: GET_ANNOUNCEMENT_BY_ID,
          variables: {
            where: { id },
          },
        })
        .then(({ data }) => {
          const { announcement: announcementData } = data;
          const initialValuesObject = {
            ...announcementData,
            for:
              userGroups?.groups?.groups?.length ===
              announcementData?.groups?.length
                ? 'company'
                : 'group',
            groups: announcementData?.groups?.map((dept) =>
              parseInt(dept.id, 10)
            ),
          };
          if (
            userGroups?.groups?.groups?.length ===
            announcementData?.groups?.length
          ) {
            setSelected('company');
          }
          setInitialValues(initialValuesObject);
          setIsChecked(initialValuesObject.assignTodos);
          announcementData?.body?.children &&
            setBody(announcementData?.body?.children);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          message.error('Something went wrong');
          history.push(PRIVATE_ROUTE.ANNOUNCEMENT_ALL);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, id, userGroups]);

  useEffect(() => {
    if (has(userGroups, 'groups.groups')) {
      // eslint-disable-next-line no-shadow
      const { groups } = userGroups;
      const groupWithUsersChildren = map(groups?.groups, (items, index) => (
        <Option key={index} value={parseInt(items.id, 10)}>
          <Row justify="space-between">
            <FilterOption item={items} />
          </Row>
        </Option>
      ));
      setGroups(groupWithUsersChildren);
    }
  }, [userGroups]);

  const onFormLayoutChange = () => {
    setIsPromptRequired(true);
    setIsUpdate(true);
  };
  // commented for future use
  // useEffect(
  //   () => () => {
  //     let currentPathname = '';
  //     let currentSearch = '';
  //     history.listen((newLocation, action) => {
  //       if (action === 'PUSH') {
  //         if (
  //           newLocation.pathname !== currentPathname ||
  //           newLocation.search !== currentSearch
  //         ) {
  //           currentPathname = newLocation.pathname;
  //           currentSearch = newLocation.search;
  //           history.push({
  //             pathname: newLocation.pathname,
  //             search: newLocation.search,
  //           });
  //           console.log('in1');
  //         }
  //       } else {
  //         console.log('else1');
  //         history.go(1);
  //         const deleteConfirmation = () => {
  //           // isConfirm = true;
  //           // history.push(tx?.pathname);
  //           console.log('yes', form.submit());
  //         };
  //         // !isConfirm &&
  //         confirmationModal(
  //           intl.formatMessage({ id: 'announcements.discard' }),
  //           deleteConfirmation,
  //           '',
  //           'save as Draft',
  //           'discard'
  //         );
  //       }
  //     });
  //     // if (history.action === 'POP') {
  //     //   const deleteConfirmation = () => {
  //     //     // isConfirm = true;
  //     //     // history.push(tx?.pathname);
  //     //     console.log('yes', form.submit());
  //     //   };
  //     //   // !isConfirm &&
  //     //   confirmationModal(
  //     //     intl.formatMessage({ id: 'announcements.discard' }),
  //     //     deleteConfirmation,
  //     //     '',
  //     //     'save as Draft',
  //     //     'discard'
  //     //   );
  //     //   history.replace(history.location.pathname /* the new state */);
  //     // }
  //   },
  //   [history]
  // );

  // useEffect(
  //   () => () => {
  //     let isConfirm = false;
  //     const unblock = history.block((tx) => {
  //       const deleteConfirmation = () => {
  //         isConfirm = true;
  //         // history.push(tx?.pathname);
  //       };
  //       !isConfirm &&
  //         confirmationModal(
  //           intl.formatMessage({ id: 'announcements.discard' }),
  //           deleteConfirmation,
  //           '',
  //           'Yes'
  //         );
  //       return isConfirm;
  //     });

  //     return () => {
  //       unblock();
  //     };
  //   },
  //   []
  // );

  const groupTagRender = (prop) => {
    const { label: tagLabel, onClose } = prop;
    return typeof tagLabel === 'object' ? (
      <FilterOption
        item={tagLabel?.props?.children?.props?.item}
        closable
        onClose={onClose}
      />
    ) : (
      <span className="extra-tag">
        {form.getFieldValue('groups').length > (isDesktop ? 2 : 1) &&
          `+${form.getFieldValue('groups').length - (isDesktop ? 2 : 1)} More`}
      </span>
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), [initialValues]);

  const resetValues = () => {
    form.resetFields();
    history.goBack();
  };
  return (
    <Layout>
      <Header title={<Title />} />
      <Spin spinning={loading || groupsLoading}>
        <Content id="drop">
          <Prompt
            message="You have unsaved changes! Are you sure you want to leave this page"
            when={isPromptRequired}
          />
          <Card padding="16" className="card">
            <Form
              form={form}
              layout={FORM_NAME.VERTICAL}
              onFinish={onFinish}
              initialValues={initialValues}
              onFinishFailed={() => setBtnLoading(false)}
              onValuesChange={onFormLayoutChange}
            >
              <Form.Item
                name="title"
                label={<FormattedMessage id="feedback.new.titleLabel" />}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <Input placeholder="Title of Announcement" />
              </Form.Item>
              <Form.Item
                name="for"
                label={<FormattedMessage id="feedback.new.for" />}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <Radio.Group onChange={(e) => setSelected(e?.target?.value)}>
                  <Radio
                    value="company"
                    className={selected === 'company' && 'active'}
                  >
                    Company
                  </Radio>
                  <Radio
                    value="group"
                    className={selected === 'group' && 'active'}
                  >
                    Group
                  </Radio>
                </Radio.Group>
              </Form.Item>
              {selected === 'group' && (
                <Form.Item
                  name="groups"
                  label="Group"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="projects.formRules.group.errorMessage" />
                      ),
                    },
                  ]}
                >
                  <Select
                    filterOption={(input, option) => {
                      const groupFilter = get(
                        option,
                        'children.props.children.props.item',
                        ''
                      );
                      return groupFilter?.name
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase());
                    }}
                    mode="multiple"
                    placeholder="Group"
                    tagRender={groupTagRender}
                    maxTagCount={isDesktop ? 2 : 1}
                    allowClear
                    showArrow
                    suffixIcon={<Arrow src={ArrowIcon} />}
                    getPopupContainer={() => document.getElementById('drop')}
                  >
                    {groups}
                  </Select>
                </Form.Item>
              )}

              <TextEditor value={body} setValue={setBody} />

              <Row justify="space-between" align="middle">
                <Col>
                  {/* hiding this because it is not researched fully or had it's correct purpose for  */}
                  {/* <Checkbox
                    onChange={() => setIsChecked(!isChecked)}
                    checked={isChecked}
                  >
                    Add to everyone’s Todos
                  </Checkbox> */}
                </Col>
                <Col>
                  <Row justify="center" align="middle">
                    <Col>
                      {id && (
                        <Form.Item>
                          <Button
                            type="text"
                            name="Delete"
                            className="template-button delete-title"
                            icon={<Image src={Trash} />}
                            onClick={() => {
                              id ? deleteAnnouncement() : resetValues();
                            }}
                          >
                            Delete
                          </Button>
                        </Form.Item>
                      )}
                    </Col>
                    <Col className="dropdown-button">
                      <Form.Item className="btn">
                        <DropdownButton
                          disabled={loading}
                          htmlType="submit"
                          handlePublished={() => {
                            setBtnLoading('PUBLISHED');
                          }}
                          handleSaveAsDraft={() => {
                            setBtnLoading('DRAFT');
                            form.submit();
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
      </Spin>
    </Layout>
  );
};
export default withRouter(injectIntl(NewAnnouncement));
