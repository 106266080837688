import { gql } from '@apollo/client';
import { userFragment, standUpFragment } from './Fragments';
export const GET_TEAM_VIEW = gql`
  query teamView($where: teamViewWhereInput) {
    teamView(where: $where) {
      checkedIn {
        ...StandUpFragment
      }
      pending {
        ...UserFragment
      }
    }
  }
  ${userFragment}
  ${standUpFragment}
`;

export const GET_TEAM_ACTIVITY = gql`
  query getActivitiesByPlatform($where: ActivityByPlatformInput) {
    getActivitiesByPlatform(where: $where) {
      platformKey
      activities {
        id
        companyId
        platformKey
        message
        actor {
          userId
          name
          email
          title
          profilePic
        }
        eventType
        module
        rawData
        processedData
        addedAt
      }
      count
    }
  }
`;
