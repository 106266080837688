import React from 'react';
import styled from 'styled-components';
import { Card as AntCard } from 'antd';
import Variables from 'design/Variables';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { scrollbars } from 'design/Styled';
import Spacings from 'design/Spacings';

const StyledCard = styled(AntCard)`
  font-size: ${fonts.small.size};
  overflow: auto;
  width: 100%;
  max-height: ${({ height }) => height || 'auto'};
  border: ${({ bordered }) =>
    bordered === false ? 'none' : `1px solid ${Colors.Border}`};
  box-shadow: none;
  margin-bottom: ${({ bottom }) => bottom || 0};
  border-radius: ${Variables.AtomRadius};
  ${scrollbars};
  .ant-card-head {
    padding: 0 ${Spacings.xSmall.padding};
    border-bottom: 2px solid ${Colors.Border};
  }
  .ant-card-body {
    padding: ${({ padding }) => padding || 0}px;
  }
  .ant-card-head-title {
    padding: ${Spacings.xSmall.padding} 0px;
    color: ${Colors.TextPrimary};
    font: ${fonts.small.bold};
  }
  &.small {
    max-width: 480px;
  }
  &.auth {
    max-width: 520px;
  }
  &.nopage {
    max-width: 548px;
  }
`;

const Card = ({
  bordered,
  bottom,
  children,
  height,
  padding,
  size,
  style,
  title,
  className,
  onCardClickEvent,
  big,
  actions,
}) => (
  <StyledCard
    size={size}
    title={title}
    bordered={bordered}
    height={height}
    style={style}
    padding={padding}
    onClick={onCardClickEvent}
    bottom={bottom}
    className={className}
    big={big}
    actions={actions}
  >
    {children}
  </StyledCard>
);

export default Card;
