import { Row as AntRow, Select as AntSelect } from 'antd';
import styled, { css } from 'styled-components';
import { fonts } from 'design/Fonts';
import { COLORS } from 'utils/constants';
import Colors from 'design/Colors';
import Spacings from 'design/Spacings';

export const LeavesFormRow = styled(AntRow)`
  .ant-input-number {
    width: 100%;
  }
`;

export const CheckBoxDiv = styled.div`
  .ant-checkbox-wrapper {
    margin: 0 5px 0 20px;
  }

  .ant-checkbox-wrapper:first-child {
    margin-left: 0;
  }
`;
export const Image = styled.img`
  width: 18px;
`;
export const TableWrapper = styled.div`
  padding: 16px 0px;
`;

export const ManageLeavesSection = styled.div`
  .icon-delete {
    font-size: ${fonts.xxLarge.size};
  }

  .row {
    display: flex;
    align-items: center;
    margin-bottom: calc(${Spacings.medium.margin} - 2px);
  }
  .ant-form-item-label > label {
    color: ${Colors.TextSecondary};
  }
  .input {
    border-radius: 8px 0px 0px 8px;
    color: ${Colors.TextSecondary};
  }
  .year-update {
    margin-left: 8px;
  }
  .drop-down {
    border-radius: 0px 8px 8px 0px;
    border-left: 0px;
    color: ${Colors.TextSecondary};
    &.ant-select-disabled {
      background: ${Colors.LightDark};
      color: ${Colors.Text};
    }
  }

  .ant-form-inline .ant-form-item {
    margin-right: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const HolidaySection = styled.div`
  .icon-delete {
    color: ${Colors.IconColor};
    font-size: ${fonts.xxLarge.size};

    &:hover {
      color: ${Colors.TextDanger};
      cursor: pointer;
    }
  }
  &.ant-modal-footer {
    .selected-leaves {
      background: #eff2f6;
      border-radius: 100px;
      width: 24px;
      height: 24px;
    }
  }
 
  .ant-col-offset-9 {
    margin-left: 39.5%;
  }
  .ant-card-head {
    border-bottom: none;
  }
  .in-line-inputs {
    margin: 0;
  }
  .ant-row-end {
    margin-bottom: 16px;
  }
  .add-button {
    font: ${fonts.xSmall.regular};
  }

  .ant-form-inline .ant-form-item {
    margin-right: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-picker {
    border-radius: 8px;
    padding: 5px 12px;
  }
  .or {
    margin: Calc(${Spacings.xSmall.margin} - 4px) 0px;
    font: ${fonts.medium.regular};
    color: ${Colors.Text};
    line-height: 26px;
  }
  .card {
    padding: 16px;
  }
`;
export const Select = styled(AntSelect)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 12px;
    border: none;
  }
  .ant-select-selection-placeholder {
    font: ${fonts.medium.regular};
  }
`;
export const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 2px;
  margin-top: -4px;
`;

export const Text = styled.div`
  font: ${fonts.medium.medium};
  color: ${Colors.Text};
  line-height: 26px;
  margin-bottom: ${Spacings.xSmall.margin};
`;
