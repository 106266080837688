import NotificationDefault from 'Widgets/Notifications/NotificationDefault/NotificationDefault';
import StandupSummaryWorkUpdate from './widgets/StanupSummaryWorkUpdate';

export const notificationWidgets = {
  STANDUP_REMINDER: {
    title: (data) => data.title,
    icon: () => 'checkmark-circle-outline',
    component: NotificationDefault,
  },
};

export const workUpdateWidgets = {
  STANDUP_SUMMARY: {
    title: () => 'Daily Standup Summary',
    component: StandupSummaryWorkUpdate,
  },
};
