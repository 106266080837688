import styled from 'styled-components';
import { Layout, message, Typography } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { scrollbars } from 'design/Styled';

const { Content: AntContent } = Layout;
const { Title } = Typography;

export const Text = styled(Title)`
 font-size:14px !important;
 color: ${Colors.Text}!important;
 margin-top: ${Spacings.xSmall.margin};
 line-height: 26px;
 margin-bottom: ${Spacings.xSmall.margin}!important;
`;
export const Arrow = styled.img`
 margin-right: ${Spacings.xSmall.margin}!important;
`;

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  font: ${fonts.large.bold};
  color: ${Colors.Text};
  cursor: pointer;
  .anticon {
    margin-right: ${Spacings.xSmall.margin};
  }
`;

export const Content = styled(AntContent)`
  display: flex;
  justify-content: center;
 
  &.ant-layout-content {
    padding: ${Spacings.medium.padding};
    overflow-y: hidden;
    .ant-btn-primary {
      line-height: 22px;
    }
  }
  .ant-row{
    padding:'none';
  }
  .card {
    max-width: 700px;
    height: fit-content;
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
  }
  
`;
