import React from 'react';
import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';
import { scrollbars } from 'design/Styled';
import Colors from 'design/Colors';
const { Content: AntContent } = AntLayout;

const StyledWrapper = styled(AntContent)`
  background-color: ${Colors.SidebarText};
  padding: ${({ padding }) => (padding ? 0 : '24px 24px')};
  &.ant-layout-content {
    overflow: ${({ overflow }) => (overflow ? 'none' : 'overlay')};
    ${scrollbars};
  }
`;
const Container = styled.div`
  max-width: ${({ isFullScreen }) => (isFullScreen ? '1440px' : '100%')};
  margin: 0 auto;
`;

const ContentWrapper = ({
  children,
  isFullScreen = true,
  padding,
  overflow,
}) => (
  <StyledWrapper padding={padding} overflow={overflow}>
    <Container isFullScreen={isFullScreen}>{children}</Container>
  </StyledWrapper>
);

export default ContentWrapper;
