import React, { useContext, useState } from 'react';
import { Form, message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useHistory, withRouter } from 'react-router-dom';
import {
  FORM_NAME,
  BUTTON_TYPE,
  AMPLITUDE_EVENTS,
  PUBLIC_ROUTE,
  PRIVATE_ROUTE,
} from 'utils/constants';
import { useMutation } from '@apollo/client';
import { AppContext } from 'AppContext';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import { TOGGLE_FEATURE } from 'graphql/Settings/Features/Mutations';
import ability from 'utils/ability/ability';
import _get from 'lodash/get';
import { unpackRules } from '@casl/ability/extra';
import { createAmplitudeEvent } from 'utils/amplitude';
import { ContentWrapper } from 'templates/Updates/AntStyled';
import { Heading, Description, ConfirmLink, Content } from './AntStyled';

const Activation = ({ setActivated, featureKey }) => {
  const { state } = useContext(AppContext);
  const history = useHistory();
  const { loginUser } = state;
  let title = null;
  const [toggleFeature, { loading }] = useMutation(TOGGLE_FEATURE, {
    variables: {
      where: {
        key: featureKey,
        state: 'activate',
      },
    },
    onCompleted: (res) => {
      const rules = _get(state, 'loginUser.groupRules', []);
      ability.update(unpackRules([...rules, ['use', featureKey]]));
      const eventProperties = {
        'Feature key': res?.setFeature?.key,
        ActivatedBy: `${loginUser?.firstName} ${loginUser?.lastName}`,
        'User id': loginUser?.id,
        Email: loginUser?.email,
      };
      createAmplitudeEvent(AMPLITUDE_EVENTS.FEATURE_ACTIVATED, eventProperties);
      switch (res?.setFeature?.key) {
        case 'announcements_feature':
          history.push(PRIVATE_ROUTE.ANNOUNCEMENT_ALL);
          break;
        case 'quotes_feature':
          history.push(PRIVATE_ROUTE.DASHBOARD);
          break;
        case 'feedbacks_feature':
          history.push(PRIVATE_ROUTE.FEED_BACK_CURRENT);
          break;
        case 'standups_feature':
          history.push(PRIVATE_ROUTE.MY_CHECK_IN);
          break;

        default:
          break;
      }
    },
  });

  if (featureKey) {
    if (featureKey === 'standard_procedures') {
      title = 'sops.title';
    } else if (featureKey === 'people_feature') {
      title = 'settings.people.featureTitle';
    } else if (featureKey === 'announcements_feature') {
      title = 'announcements.title';
    } else if (featureKey === 'quotes_feature') {
      title = 'settings.quotes';
    } else if (featureKey === 'daily_goals_feature') {
      title = 'dailyGoalsSettings.text';
    } else if (featureKey === 'feedbacks_feature') {
      title = 'feedback.title';
    } else {
      title = 'standUps.title';
    }
  }
  const handleActivate = () => {
    toggleFeature().then(() => {
      setActivated(true);
      message.success('Feature activated successfully!');
    });
  };

  return (
    <Content>
      <Card padding="16" className="card">
        <Form name={FORM_NAME.BASIC}>
          <Heading>
            <FormattedMessage id={title} />
          </Heading>
          <Description>
            <FormattedMessage id="confirmation.description" />
            <span style={{ color: '#5F3996', fontWeight: '600' }}>
              {state ? state.email : 'your email'}
            </span>
          </Description>
          <ConfirmLink>
            <FormattedMessage id="confirmation.confirmationLink" />
          </ConfirmLink>
          <Form.Item>
            <Button
              primary
              loading={loading}
              type={BUTTON_TYPE.PRIMARY}
              onClick={handleActivate}
              htmlType={BUTTON_TYPE.SUBMIT}
              name="Activate"
            />
          </Form.Item>
        </Form>
      </Card>
    </Content>
  );
};
export default withRouter(injectIntl(Activation));
