/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { Skeleton } from 'antd';
import { titleCase } from 'utils/stringUtilities';
import moment from 'moment';
import Table from 'atoms/Table';
import UserProfile from 'molecules/UserProfile';
import DateTime from 'atoms/DateTime';
import ContentWrapper from 'atoms/ContentWrapper';
import Header from 'layout/Header';
import InfoIcon from 'atoms/InfoIcon';
import { FormattedMessage } from 'react-intl';
import Filters from 'molecules/Filters';
import _map from 'lodash/map';
import _has from 'lodash/has';
import {
  EMPTY_ARRAY,
  UNDEFINED,
  FETCH_POLICY,
  PAGE_LIMIT,
  PRIVATE_ROUTE,
} from 'utils/constants';
import { GET_LEAVE_SUMMARY } from 'features/people/graphql/Leaves/Queries';
import { useQuery } from '@apollo/client';
import GroupTagList from 'organisms/GroupTagList';
import { GET_LEAVE_SETTINGS } from 'features/people/graphql/Settings/People/Queries';
import { InfiniteScrollTableWrapper } from 'design/Styled';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'atoms/Loader';
import { AppContext } from 'AppContext';
import { Content, LoaderDiv } from './AntStyled';

const LeaveSummary = (props) => {
  const [groupId, setGroupId] = useState(EMPTY_ARRAY);
  const [userId, setUserId] = useState(UNDEFINED);
  const [leaveSummary, setLeaveSummary] = useState(EMPTY_ARRAY);
  const [tableLoader, setTableLoader] = useState(false);
  const [dataCount, setDataCount] = useState();
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const { state: { loginUser } } = useContext(AppContext);
  const initial = _has(loginUser, 'location.yearStart')
    ? moment().isBefore(moment(`${moment().year()}-${loginUser.location.yearStart}`))
      ? [moment().subtract(1, 'year')]
      : [moment()]
    : [moment()];
  const [date, setDate] = useState(initial);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const { data: leaveSummaryData, loading: leaveLoading } = useQuery(GET_LEAVE_SUMMARY, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    variables: {
      where: {
        userId: userId ? parseInt(userId, 10) : UNDEFINED,
        groupId: groupId.length !== 0 ? groupId : UNDEFINED,
        year: parseInt(date[0].format('YYYY'), 10),
      },
      skip,
      limit: PAGE_LIMIT,
    },
  });
  const { data: leaveSettingsData } = useQuery(GET_LEAVE_SETTINGS, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });

  useEffect(() => {
    if (!leaveLoading && leaveSummaryData) {
      const leaveTableData = _map(leaveSummaryData.leaveSummary.leaveSummary, (leaveData, key) => ({
        key: key.toString(),
        user: leaveData.user,
        leaveSummary: leaveData.analytics,
        joiningDate: leaveData.user.createdAt,
        role: leaveData.user.role,
        department: leaveData.user.userGroups,
      }));
      const summaryData = [...leaveSummary, ...leaveTableData];
      setLeaveSummary(summaryData);
      setDataCount(leaveSummaryData.leaveSummary.count);
      setLoader(false);
      setTableLoader(false);
      if (leaveSummaryData.leaveSummary.count <= PAGE_LIMIT) {
        setHasMore(false);
      }
    }
  }, [leaveSummaryData, leaveLoading]);

  useEffect(() => {
    if (leaveSettingsData && leaveSettingsData.leaveSettings) {
      const leaveTypesArray = [];
      leaveSettingsData.leaveSettings.forEach((settings) => {
        leaveTypesArray.push({
          title: settings.type,
          dataIndex: ['leaveSummary', settings.type],
          key: settings.type,
          render: leaves => (
            <div className="listGroup" title={`Claimed ${leaves?.claimed} out of ${leaves?.available + leaves?.claimed}`}>
              <span className="number">{leaves?.claimed}</span>
              <span>/</span>
              <span className="number">{leaves?.available + leaves?.claimed}</span>
            </div>
          ),
        });
      });
      setLeaveTypes(leaveTypesArray);
    }
  }, [leaveSettingsData]);

  const resetLeaveSummaryData = () => {
    setHasMore(true);
    setLeaveSummary(EMPTY_ARRAY);
    setSkip(0);
    setPage(1);
  };

  const handleGroupOrUserFilterChange = () => {
    resetLeaveSummaryData();
  };

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      title: 'Name',
      dataIndex: 'user',
      key: 'user',
      render: (user, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : user ? (
        <UserProfile
          profile={user.profileImage}
          name={
            user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : user.email
          }
          userId={user.id}
          showLink
        />
      ) : (
        '-'
      ),
    },
    {
      title: 'Group',
      dataIndex: 'department',
      key: 'department',
      width: '12%',
      render: (userGroups) => {
        const group = [];
        _map(userGroups, items => group.push(items.group));
        return <GroupTagList limit={3} departments={group} />;
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: text => titleCase(text),
    },
    {
      title: 'Joining Date',
      dataIndex: 'joiningDate',
      key: 'JoiningDate',
      width: '12%',
      render: (date, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : (
        <DateTime date={date} />
      ),
    },
    ...leaveTypes,
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];

  const handleInfiniteOnLoad = () => {
    setLoader(true);
    if (dataCount <= leaveSummary.length) {
      setHasMore(false);
      setLoader(false);
      setPage(1);
    } else {
      setPage(prev => prev + 1);
      const count = page * PAGE_LIMIT;
      setSkip(count);
    }
  };

  return (
    <Content>
      <Header
        title={(
          <InfoIcon
            title={<FormattedMessage id="Leaves.summary" />}
          />
        )}
      />
      <Filters
        masterFilter
        onClickData={resetLeaveSummaryData}
        switchableDateFilter
        groupsFilters={setGroupId}
        setDateFilterValue={setDate}
        dateFilterValue={date}
        groupValue={groupId}
        setUsersValue={setUserId}
        showUsersFilter
        usersValue={userId}
        handleGroupOrUserFilterChange={handleGroupOrUserFilterChange}
        typeValue="year"
        yearOnly
      />
      <ContentWrapper>
        <InfiniteScrollTableWrapper>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={!loader && hasMore}
            useWindow={false}
            threshold={20}
            loader={leaveSummary.length && <LoaderDiv><Loader size="medium" /></LoaderDiv>}
          >
            <Table
              columns={columns}
              dataSource={leaveSummary}
              height="calc(100vh - 96px - 60px)"
              loading={leaveSummary.length ? tableLoader : leaveLoading}
              isInfinite
              className="bb-0"
            />
          </InfiniteScroll>
        </InfiniteScrollTableWrapper>
      </ContentWrapper>
    </Content>

  );
};
export default {
  key: PRIVATE_ROUTE.LEAVES_SUMMARY,
  component: LeaveSummary,
  name: 'Leave Summary',
  icon: <i data-eva="bar-chart-outline" data-eva-height="20" data-eva-width="20"></i>,
  abilitySubject: 'leave summary',
  abilityAction: 'manage',
};
