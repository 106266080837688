import React from 'react';
import {
  Router, Switch, Route, withRouter,
} from 'react-router-dom';
import { PRIVATE_ROUTE } from 'utils/constants';
import ability from 'utils/ability/ability';
import Page404 from 'pages/404';
import SopDetails from 'features/sop/pages/SopDetails';
import SopTabs from './SopTabs';
import NewSop from './NewSop';
import AddSop from './AddSop';

const Sops = ({ history }) => (
  <Router history={history}>
    <Switch>
      {ability.can('use', 'standard_procedures') && ability.can('manage', 'sops') && (
        <Route path={PRIVATE_ROUTE.SOP_ADD} component={AddSop} />
      )}
      {ability.can('use', 'standard_procedures') && ability.can('manage', 'sops') && (
        <Route path={PRIVATE_ROUTE.SOP_EDIT} component={NewSop} />
      )}
      {ability.can('use', 'standard_procedures') && ability.can('manage', 'sops') && (
        <Route path={PRIVATE_ROUTE.SOP_NEW} component={NewSop} />
      )}
      {ability.can('use', 'standard_procedures') && (
        <Route path={PRIVATE_ROUTE.SOPS_VIEW} component={SopDetails} />
      )}
      {ability.can('use', 'standard_procedures') && (
        <Route path={PRIVATE_ROUTE.SOPS} component={SopTabs} />
      )}
      <Route exact path="*" render={() => (<Page404 />)} />
    </Switch>
  </Router>
);

export default {
  component: withRouter(Sops),
  key: PRIVATE_ROUTE.SOPS,
  defaultPath: PRIVATE_ROUTE.SOP_ACTIVE,
  name: 'Sop',
  icon: <i data-eva="list-outline" data-eva-height="20" data-eva-width="20"></i>,
  abilitySubject: 'standard_procedures',
  abilityAction: 'use',
};
