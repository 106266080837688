import NotificationDefault from 'Widgets/Notifications/NotificationDefault/NotificationDefault';
import NotificationTodo from 'Widgets/Todos/widgets';

import AnnouncementWorkUpdate from './widgets/AnnouncementWorkUpdate';

export const announcementWorkUpdateWidgets = {
  ANNOUNCEMENT_PUBLISHED: {
    title: () => 'Announcement Title',
    component: AnnouncementWorkUpdate,
  },
};

export const notificationWidgets = {
  ANNOUNCEMENT_PUBLISHED: {
    title: data => data.title,
    icon: () => 'speaker-outline',
    component: NotificationTodo,
  },
};
