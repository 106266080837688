/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { Form, Skeleton, Spin, Typography } from 'antd';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import client, { subscriptionClient } from 'apollo';
import Meta from 'atoms/Meta';
import { UndifferLogo, CloseCircle, CheckIcon } from 'assets';
import { InfoCircleOutlined } from '@ant-design/icons';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import {
  FORM_NAME,
  META_TYPE,
  PUBLIC_ROUTE,
  BUTTON_TYPE,
  ONBOARDING,
  PRIVATE_ROUTE,
  FETCH_POLICY,
  ACTIONS_TYPE,
} from 'utils/constants';
import { CANCEL_REQUEST, VERIFY_EMAIL } from 'graphql/Onboarding/Mutations';
import * as Sentry from '@sentry/react';
import Loader from 'atoms/Loader';
import { AppContext } from 'AppContext.js';
import { GET_CURRENT_USER } from 'graphql/CurrentUser/queries.js';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_USER_SUBSCRIPTION } from 'graphql/CurrentUser/subscriptions.js';
import {
  Container,
  Wrapper,
  Background,
  Title,
  Logo,
  Icon,
  Text,
  Success,
  CkeckIcon,
} from '../AntStyled.js';
export default function (props) {
  const history = useHistory();
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [workspaceName, setWorkspaceName] = useState('');
  const [isBackButtonClicked, setBackButtonPress] = useState(false);
  const { data, loading: userLoading } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const {
    state: { loginUser },
    dispatch,
  } = useContext(AppContext);
  const { email, id: loginUserId } = loginUser;

  useEffect(() => {
    if (data?.currentUser?.hasRequested) {
      setSuccess(true);
      setWorkspaceName(data?.currentUser?.company?.name);
      if (data?.currentUser?.isActive) {
        history.push(PRIVATE_ROUTE.DASHBOARD);
      }
    } else if (
      data?.currentUser?.requestRejected &&
      !location?.state?.workspaceName
    ) {
      setSuccess(false);
      setWorkspaceName('');
    } else if (!location?.state?.workspaceName && !loading) {
      history.push(ONBOARDING.SELECTION);
    } else {
      setWorkspaceName(location?.state?.workspaceName);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (email) {
      subscriptionClient.subscribe({ query: GET_USER_SUBSCRIPTION }).subscribe({
        next({ data }) {
          if (data) {
            dispatch({
              type: ACTIONS_TYPE.LOGIN_USER,
              data,
            });
          }
        },
        error(err) {
          console.error(err);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, email, loginUserId]);

  useEffect(() => {
    window.history.pushState(null, null, window?.location?.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      setBackButtonPress(true);
      history.replace(ONBOARDING.SELECTION);
    }
  };
  return (
    <Container>
      <Background>
        <Logo src={UndifferLogo} alt="logo"></Logo>
        <NavLink to="/help" className="bottom-right">
          <FormattedMessage id="confirmation.needHelp" />
        </NavLink>
        <NavLink to="/copyright" className="bottom-left">
          <FormattedMessage id="confirmation.copyright" />
        </NavLink>
      </Background>
      <Wrapper>
        <Card padding="36px 36px 24" className="auth">
          {userLoading ? (
            <Loader />
          ) : (
            <Skeleton loading={loading} active={loading}>
              <Meta title={META_TYPE.WORK_SPACE} />
              <Title>
                {success ? (
                  workspaceName
                ) : workspaceName ? (
                  <FormattedMessage id="onboarding.workspaceNotFound" />
                ) : (
                  <FormattedMessage id="onboarding.workspaceRequestRejected" />
                )}
              </Title>
              <Form name={FORM_NAME.BASIC} layout="vertical">
                {success ? (
                  <>
                    <div className="success">
                      <CkeckIcon src={CheckIcon} alt="icon" />
                      <Success className="lineheight">
                        <FormattedMessage id="onboarding.selection.results.successMessages" />
                        <br />
                        <FormattedMessage id="onboarding.admin" />
                      </Success>
                    </div>
                    <div className="notification">
                      <FormattedMessage id="onboarding.selection.results.notification" />
                    </div>
                    <Button
                      accent
                      className="right"
                      htmlType={BUTTON_TYPE.SUBMIT}
                      onClick={() => {
                        client
                          .mutate({ mutation: CANCEL_REQUEST })
                          .then(() => {
                            history.push(ONBOARDING.SELECTION);
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                      }}
                      name={
                        <FormattedMessage id="onboarding.selection.cancelRequest" />
                      }
                    />
                  </>
                ) : (
                  <>
                    <Form.Item>
                      <div className="error">
                        <Icon src={CloseCircle} alt="icon" />
                        <Text className="lineheight">
                          {workspaceName ? (
                            <>
                              <FormattedMessage id="onboarding.selection.results.errorMessage" />
                              <span> "{workspaceName}"</span>
                            </>
                          ) : (
                            <FormattedMessage id="onboarding.selection.results.requestRejected" />
                          )}
                        </Text>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        accent
                        className="right"
                        onClick={() => {
                          history.push(ONBOARDING.SELECTION);
                        }}
                        htmlType={BUTTON_TYPE.SUBMIT}
                        name={
                          <FormattedMessage id="onboarding.selection.results.tryagain" />
                        }
                      />
                    </Form.Item>
                  </>
                )}
              </Form>
            </Skeleton>
          )}
        </Card>
      </Wrapper>
    </Container>
  );
}
