import styled from 'styled-components';
import { Layout } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  .back-icon {
    border-radius: 4px;
    background: linear-gradient(#6fbaff, rgba(0, 0, 0, 0.4));
    color: ${Colors.TextLight};
    cursor: pointer;
  }
  .right {
    float: right;
    margin-bottom: 0;
    width: fit-content;
  }

  .isRead {
    font-weight: 500;
    background: #eff2f6;
  }
  .isNotRead {
    font-weight: 600;
    color: #000000;
  }
`;

export const Image = styled.img`
  width: 18px;
  height: 18px;
`;
export const LoaderDiv = styled.div`
  padding-bottom: 35px;
  padding-top: 10px;
`;
export const Title = styled.div`
  font: ${fonts.xxLarge.bold};
  color: ${Colors.TextPrimary};
  text-align: center;
  line-height: 41px;
  margin-bottom: ${Spacings.xxSmall.margin};
`;

export const Description = styled.div`
  font-size: ${fonts.small.regular};
  line-height: 22px;
  text-align: center;
  padding: 0px 0px 5px;
  color: ${Colors.TextSecondary};
`;
export const ConfirmLink = styled.p`
  font-size: ${fonts.small.regular};
  line-height: 26px;
  text-align: center;
  padding: 0px 0px 10px;
  color: ${Colors.TextSecondary};
`;
export const LongNameWrapper = styled.div`
  white-space: normal;
  word-break: break-all;
`;
