import React from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';

import { Security } from 'assets';
import Meta from 'atoms/Meta';
import Card from 'atoms/Card';
import { FORM_NAME, META_TYPE } from 'utils/constants';
import {
  Container,
  Description,
  Image,
  ImageWrapper,
  Title,
} from './AntStyled';

export default function () {
  return (
    <Container>
      <Card padding="50px 50px 38">
        <Meta title={META_TYPE.ALREADY_REGISTERED} />
        <Form name={FORM_NAME.BASIC}>
          <ImageWrapper><Image src={Security} /></ImageWrapper>
          <Title><FormattedMessage id="alreadyRegistered.title" /></Title>
          <Description><FormattedMessage id="alreadyRegistered.description" /></Description>
        </Form>
      </Card>
    </Container>
  );
}
