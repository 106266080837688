/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import {
  Radio,
  DatePicker,
  Row,
  Layout,
  Spin,
  Col,
  message,
  TimePicker,
} from 'antd';
import { useForm } from 'antd/lib/form/util';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  ArrowIcon,
  BackArrow,
  CalenderPrimary,
  ClockPrimary,
  Trash,
} from 'assets';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import InfoIcon from 'atoms/InfoIcon';
import Input from 'atoms/Input';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _map from 'lodash/map';
import Spacings from 'design/Spacings';
import * as Sentry from '@sentry/react';
import {
  EMPTY_ARRAY,
  FORM_NAME,
  FETCH_POLICY,
  PRIVATE_ROUTE,
  FEEDBACK_TYPE,
  AMPLITUDE_EVENTS,
} from 'utils/constants';
import { useQuery } from '@apollo/client';
import { GET_GROUPS } from 'graphql/Settings/Users/Queries';
import GroupTag from 'atoms/GroupTag';
import Header from 'layout/Header';
import { Prompt, useParams, withRouter } from 'react-router-dom';
import client from 'apollo';
import { GET_FEEDBACK_BYID } from 'features/feedbacks/graphql/Queries';
import moment from 'moment';
import ContentWrapper from 'atoms/ContentWrapper';
import { confirmationModal } from 'atoms/ConfirmationModal';
import { DELETE_FEEDBACK } from 'features/feedbacks/graphql/Mutations';
import { createAmplitudeEvent } from 'utils/amplitude';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import {
  Content,
  ContentTitle,
  Form,
  Select,
  Arrow,
  Image,
  StyledImage,
} from './AntStyled';

const { Option } = Select;

function AddPoll(props) {
  const { history, intl } = props;
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1050);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1050);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  const { location: { state: { templateData, fromStep } = {} } = {} } = history;
  const [showDeliveryPicker, setShowDeliveryPicker] = useState(
    templateData?.scheduleOn ||
      templateData?.deliveryOption === 'SCHEDULED' ||
      false
  );
  const [showEndPicker, setShowEndPicker] = useState(
    templateData?.endOn || templateData?.endTime === 'END_ON' || false
  );
  const [group, setGroups] = useState(EMPTY_ARRAY);
  const [isPromptRequired, setIsPromptRequired] = useState(
    fromStep === 'step2'
  );
  const [initialValues, setInitialValues] = useState(EMPTY_ARRAY);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const { id } = useParams();
  const { data: userGroups } = useQuery(GET_GROUPS, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const onFinish = (values) => {
    setIsPromptRequired(false);
    history.push({
      state: { values },
      pathname: PRIVATE_ROUTE.POLL_QUESTIONS,
      id: { id },
      questions: initialValues?.questions?.questions,
      endOn: initialValues?.endOn,
      scheduleOn: initialValues?.scheduleOn,
    });
  };
  const templateDataObj = {
    title: templateData?.title,
    description: templateData?.description,
    type: FEEDBACK_TYPE.POLL,
    deliveryOption: templateData?.isTemplate
      ? templateData?.scheduleOn
        ? 'SCHEDULED'
        : 'NOW'
      : templateData?.deliveryOption,
    scheduleOn: templateData?.scheduleOn,
    endTime: templateData?.isTemplate
      ? templateData?.endOn
        ? 'END_ON'
        : 'MANUAL'
      : templateData?.endTime,
    endTimeValue:
      templateData?.endTime === 'END_ON'
        ? moment(templateData?.endTimeValue)
        : null,
    deliveryValue:
      templateData?.deliveryOption === 'SCHEDULED'
        ? moment(templateData?.deliveryValue)
        : null,
    groups: templateData?.isTemplate
      ? templateData?.groups?.map((dept) => parseInt(dept?.id, 10))
      : templateData?.groups,
    endOn: templateData?.endOn,
    permissions: templateData?.permissions || 'EVERYONE',
    questions: templateData?.questions,
  };

  const Title = () => (
    <>
      <ContentTitle>
        <Image
          src={BackArrow}
          alt="arrow"
          onClick={() => history.push(PRIVATE_ROUTE.FEED_BACK_CURRENT)}
        />
        <InfoIcon
          title={
            <FormattedMessage
              id={id ? 'feedback.new.pollEditTitle' : 'feedback.new.pollTitle'}
            />
          }
        />
      </ContentTitle>
    </>
  );

  const FilterOption = ({ item, closable = false, onClose }) => (
    <GroupTag
      closeable={closable}
      closeOnClick={onClose}
      color={item?.color}
      name={item?.name}
      type={item?.type}
    />
  );

  useEffect(() => {
    if (id) {
      setLoading(true);
      client
        .query({
          query: GET_FEEDBACK_BYID,
          fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
          variables: { where: { id } },
        })
        .then(({ data }) => {
          const { feedback } = data;
          const initialValuesObject = {
            title: feedback?.title,
            description: feedback?.description,
            type: FEEDBACK_TYPE.POLL,
            deliveryOption: feedback?.scheduleOn ? 'SCHEDULED' : 'NOW',
            deliveryValue: feedback?.scheduleOn
              ? moment.utc(feedback?.scheduleOn).local()
              : moment.utc().add(1, 'day').local(),
            endTime: 'END_ON',
            // commented for future use
            // endTime:
            //   // eslint-disable-next-line no-nested-ternary
            //   feedback?.endOn === null
            //     ? 'MANUAL'
            //     : feedback?.endOn
            //       ? 'END_ON'
            //       : '',
            permissions: feedback?.resultVisibility,
            endOn: feedback?.endOn,
            endTimeValue: feedback?.endOn
              ? moment.utc(feedback?.endOn).local()
              : moment.utc().add(1, 'day').local(),
            questions: feedback?.questions,
            groups: feedback?.groups?.map((dept) => parseInt(dept?.id, 10)),
          };
          setShowDeliveryPicker(feedback?.scheduleOn);
          setInitialValues(initialValuesObject);
          form.resetFields();
          setLoading(false);
        });
    } else if (templateDataObj) {
      setInitialValues(templateDataObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (_has(userGroups, 'groups.groups')) {
      // eslint-disable-next-line no-shadow
      const { groups } = userGroups;
      const groupWithUsersChildren = _map(groups?.groups, (items, index) => (
        <Option key={index} value={parseInt(items?.id, 10)}>
          <Row justify="space-between">
            <FilterOption item={items} />
          </Row>
        </Option>
      ));
      setGroups(groupWithUsersChildren);
    }
  }, [userGroups]);

  const groupTagRender = (prop) => {
    const { label: tagLabel, onClose } = prop;
    return typeof tagLabel === 'object' ? (
      <FilterOption
        item={tagLabel?.props?.children?.props?.item}
        closable
        onClose={onClose}
      />
    ) : (
      <span className="extra-tag">
        +{form.getFieldValue('groups').length - (isDesktop ? 2 : 1)} More
      </span>
    );
  };

  const radioStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    lineHeight: '45px',
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), [templateData, initialValues]);

  const deleteFeedback = () => {
    setIsPromptRequired(false);
    const deleteConfirmation = async () => {
      try {
        await client
          .mutate({
            mutation: DELETE_FEEDBACK,
            variables: {
              id,
            },
          })
          .then(() => {
            const eventProperties = {
              Id: id,
              title: initialValues?.title,
              description: initialValues?.description,
              type: FEEDBACK_TYPE.POLL,
            };
            createAmplitudeEvent(
              AMPLITUDE_EVENTS.FEEDBACK_DELETED,
              eventProperties
            );
            message.destroy();
            message.success(
              intl.formatMessage({
                id: 'users.successMessages.deleteFeedback',
              })
            );
            history.goBack();
          });
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.log(error);
      }
    };
    confirmationModal(
      intl.formatMessage({ id: 'feedback.sure' }),
      deleteConfirmation
    );
  };

  return (
    // eslint-disable-next-line indent
    <Layout>
      <Header title={<Title />} />
      <ContentWrapper>
        <Content>
          <Prompt
            message="You have unsaved changes! Are you sure you want to leave this page"
            when={isPromptRequired}
          />
          <Spin spinning={loading}>
            <Form
              form={form}
              layout={FORM_NAME.VERTICAL}
              onFinish={onFinish}
              initialValues={initialValues}
              onValuesChange={() => setIsPromptRequired(true)}
            >
              <Card padding="16" className="card">
                <Form.Item
                  name="title"
                  label={<FormattedMessage id="feedback.new.titleLabel" />}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'This field is required!',
                    },
                  ]}
                >
                  <Input placeholder="Title of Poll" />
                </Form.Item>
                <Form.Item
                  name="description"
                  label={
                    <FormattedMessage id="feedback.new.descriptionLabel" />
                  }
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'This field is required!',
                    },
                  ]}
                >
                  <Input placeholder="Description of Poll" />
                </Form.Item>
                <Form.Item
                  name="groups"
                  label={<FormattedMessage id="feedback.new.shareWithLabel" />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="projects.formRules.group.errorMessage" />
                      ),
                    },
                  ]}
                >
                  <Select
                    filterOption={(input, option) => {
                      const groupFilter = _get(
                        option,
                        'children.props.children.props.item',
                        ''
                      );
                      return groupFilter?.name
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase());
                    }}
                    mode="multiple"
                    placeholder="Groups"
                    tagRender={groupTagRender}
                    maxTagCount={isDesktop ? 2 : 1}
                    allowClear
                    showArrow
                    suffixIcon={<Arrow src={ArrowIcon} />}
                    getPopupContainer={(trigger) => trigger?.parentNode}
                  >
                    {group}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="deliveryOption"
                  label={<FormattedMessage id="feedback.new.deliveryLabel" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required!',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio
                      style={radioStyle}
                      value="NOW"
                      className="radio-label mb-10"
                      onClick={() => setShowDeliveryPicker(false)}
                    >
                      <FormattedMessage id="feedback.new.deliveryOption" />
                    </Radio>
                    <Radio
                      style={radioStyle}
                      value="SCHEDULED"
                      className="radio-label"
                      onClick={() => setShowDeliveryPicker(true)}
                    >
                      <Row gutter={10}>
                        <Col>
                          <FormattedMessage id="feedback.new.ScheduleOn" />
                        </Col>
                        <Col>
                          <Form.Item
                            name="deliveryValue"
                            rules={[
                              {
                                required: showDeliveryPicker,
                                message: 'This field is required!',
                              },
                            ]}
                          >
                            <DatePicker
                              disabledDate={(current) =>
                                current &&
                                current < moment().subtract(1, 'days')
                              }
                              format={MomentFormat.monthDate}
                              className="datePicker"
                              disabled={!showDeliveryPicker}
                              suffixIcon={
                                <img
                                  className="icon-date-time"
                                  src={CalenderPrimary}
                                  alt="icon"
                                />
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            name="deliveryValue"
                            rules={[
                              {
                                required: showDeliveryPicker,
                                message: 'This field is required!',
                              },
                            ]}
                          >
                            <TimePicker
                              format={MomentFormat.shortTime}
                              className="timePicker"
                              showTime={{ format: 'hh:mm' }}
                              disabled={!showDeliveryPicker}
                              suffixIcon={
                                <img
                                  className="icon-date-time"
                                  src={ClockPrimary}
                                  alt="icon"
                                />
                              }
                              inputReadOnly
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="endTime"
                  label={<FormattedMessage id="feedback.new.endTimeLabel" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required!',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio
                      style={radioStyle}
                      value="MANUAL"
                      className="radio-label mb-10"
                      onClick={() => setShowEndPicker(false)}
                    >
                      <FormattedMessage id="feedback.new.Manually" />
                    </Radio>
                    <Radio
                      style={radioStyle}
                      value="END_ON"
                      className="radio-label"
                      onClick={() => setShowEndPicker(true)}
                    >
                      <Row gutter={10}>
                        <Col>
                          <FormattedMessage id="feedback.new.EndOn" />
                        </Col>
                        <Col>
                          <Form.Item
                            name="endTimeValue"
                            rules={[
                              {
                                required: showEndPicker,
                                message: 'This field is required!',
                              },
                            ]}
                          >
                            <DatePicker
                              disabledDate={(current) =>
                                current < form.getFieldValue('deliveryValue') ||
                                current < moment().subtract(1, 'days')
                              }
                              format={MomentFormat.monthDate}
                              required
                              className="datePicker"
                              disabled={!showEndPicker}
                              suffixIcon={
                                <img
                                  className="icon-date-time"
                                  src={CalenderPrimary}
                                  alt="icon"
                                />
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            name="endTimeValue"
                            rules={[
                              {
                                required: showEndPicker,
                                message: 'This field is required!',
                              },
                            ]}
                          >
                            <TimePicker
                              format={MomentFormat.shortTime}
                              className="timePicker"
                              showTime={{ format: 'hh:mm' }}
                              disabled={!showEndPicker}
                              suffixIcon={
                                <img
                                  className="icon-date-time"
                                  src={ClockPrimary}
                                  alt="icon"
                                />
                              }
                              inputReadOnly
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="permissions"
                  label={<FormattedMessage id="feedback.new.permissions" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required!',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio
                      style={radioStyle}
                      value="EVERYONE"
                      className="radio-label"
                    >
                      <FormattedMessage id="feedback.new.Everyone" />
                    </Radio>
                    <p className="p-tag">
                      <FormattedMessage id="feedback.new.permission_open" />
                    </p>
                    {/* for future use */}
                    {/* <Radio
                      style={radioStyle}
                      value="PARTICIPANTS"
                      className="radio-label"
                    >
                      <FormattedMessage id="feedback.new.Participants" />
                    </Radio>
                    <p className="p-tag">
                      <FormattedMessage id="feedback.new.permission_close" />
                    </p> */}
                    <Radio
                      style={radioStyle}
                      value="MANAGERS"
                      className="radio-label"
                    >
                      <FormattedMessage id="feedback.new.Managers" />
                    </Radio>
                    <p className="p-tag">
                      <FormattedMessage id="feedback.new.permission_close" />
                    </p>
                  </Radio.Group>
                </Form.Item>
              </Card>
              <Row justify="space-between">
                <Col>
                  {id && (
                    <Button
                      type="text"
                      name="Delete Poll"
                      className="template-button delete-title"
                      icon={<StyledImage src={Trash} />}
                      onClick={() => {
                        deleteFeedback();
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Col>
                <Col>
                  <Form.Item className="mb-0">
                    <div className="btn">
                      <Button htmlType="submit" name="Next" />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Content>
      </ContentWrapper>
    </Layout>
  );
}

export default withRouter(injectIntl(AddPoll));
