import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CHECKIN_STATUS } from 'utils/constants';
import Colors from 'design/Colors';

export const statusOption = [
  {
    value: CHECKIN_STATUS.ON_BREAK,
    text: <FormattedMessage id="checkIn.onBreak" />,
  },
  {
    value: CHECKIN_STATUS.CHECKED_IN,
    text: <FormattedMessage id="checkIn.working" />,
  },
  {
    value: CHECKIN_STATUS.DONE,
    text: <FormattedMessage id="checkIn.workDone" />,
  },
];

export const statusOptionAfterCheckedIn = [
  {
    value: CHECKIN_STATUS.CHECKED_IN,
    text: <FormattedMessage id="checkIn.working" />,
  },
  {
    value: CHECKIN_STATUS.ON_BREAK,
    text: <FormattedMessage id="checkIn.onBreak" />,
  },
  {
    value: CHECKIN_STATUS.DONE,
    text: <FormattedMessage id="checkIn.workDone" />,
  },
];

export const statusOptionCheckedOut = [
  {
    value: CHECKIN_STATUS.CHECKED_IN,
    text: <FormattedMessage id="checkIn.working" />,
  },
  {
    value: CHECKIN_STATUS.DONE,
    text: <FormattedMessage id="checkIn.workDone" />,
  },
];

export const statusColor = {
  DONE: Colors.WorkDone,
  CHECKEDIN: Colors.TextSecondary,
  ONBREAK: Colors.OnBreak,
};

export const statusText = {
  DONE: <FormattedMessage id="checkIn.workDone" />,
  CHECKEDIN: <FormattedMessage id="checkIn.working" />,
  ONBREAK: <FormattedMessage id="checkIn.onBreak" />,
};
