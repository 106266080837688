import styled from 'styled-components';
import { Form as AntForm, Layout } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import Variables from 'design/Variables';
import { x } from 'design/Styled';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  .drag-icon {
    cursor: grab;
    &:active {
      cursor: grabbing !important;
    }
  }
  .label-1 {
    width: 100% !important;
    .ant-checkbox-inner {
      border-radius: ${Variables.RadiusXs};
      border: 1px solid ${Colors.Border};
    }
  }
  .cards {
    display: flex;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .card1 {
    width: 100%;
    height: fit-content;
    margin-right: ${Spacings.small.margin};
    .has-error {
      border-color: ${Colors.TextDanger};
    }
  }
  .title {
    color: ${Colors.TextSecondary};
    font: ${fonts.small.medium};
    line-height: ${Variables.LineHeightSm};
  }
  .text {
    color: ${Colors.TextSecondary};
    font: ${fonts.small.regular};
    line-height: ${Variables.LineHeightMd};
    word-break: break-word;
  }
  .empty-wrapper {
    min-height: 100%;
  }
  .goals {
    position: relative;
    height: fit-content;
    margin: 10px 0px 14px 8px;
    padding-left: ${Spacings.Small.padding};
  }
  .today {
    position: relative;
    height: fit-content;
    margin: 10px 0px 12px 8px;
    padding-left: ${Spacings.Small.padding};
  }
  .blocker {
    position: relative;
    height: fit-content;
    margin: 10px 0px 12px 8px;
    padding-left: ${Spacings.Small.padding};
  }
  .border-blocker {
    width: 4px;
    border-radius: 200px;
    background-color: ${Colors.TextDanger};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .border-goals {
    width: 4px;
    border-radius: 200px;
    background-color: ${Colors.TextGreen};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .border-today {
    width: 4px;
    border-radius: 200px;
    background-color: ${Colors.PrimaryColor};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .card2 {
    min-width: 380px;
    max-width: 380px;
    order: 2;
    overflow: hidden;
    height: 100%;
    .ant-card-head-title {
      padding: Calc(${Spacings.xSmall.padding} - 4px) 0px;
    }
    .ant-card-body {
      overflow: overlay;
      height: 70vh;
    }
    .ant-empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .ant-empty-image {
      height: 48px;
      width: 48px;
      border-radius: 300px;
    }
    .ant-empty-description {
      font: ${fonts.small.medium};
      line-height: ${Variables.LineHeightSm};
      color: ${Colors.TextEmpty};
    }
  }
  .icon-check {
    display: flex;
    align-items: center;
    img {
      height: 16px;
      width: 16px;
      &.pointer {
        cursor: pointer;
      }
    }
  }
  .btn {
    margin-top: ${Spacings.small.margin};
  }
  .main-icon {
    margin-right: ${Spacings.xxSmall.margin};
    display: flex;
    align-items: center;
  }
  .add {
    font: ${fonts.xSmall.regular};
  }
  .emojis {
    display: flex;
    justify-content: space-between;
  }
  .text-print {
    padding-left: Calc(${Spacings.xSmall.padding} - 4px);
    padding-top: Calc(${Spacings.xSmall.padding} - 6px);
  }
  .green-box {
    padding-right: ${Spacings.tiny.padding};
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${Colors.TextGreen};
    }
    .ant-checkbox-inner {
      border-color: ${Colors.TextGreen};
    }
    .ant-checkbox-checked::after {
      border: 1px solid ${Colors.TextGreen};
    }
  }
  .icon-green {
    padding-right: ${Spacings.xxSmall.padding};
  }
  .icon-space {
    padding-right: ${Spacings.tiny.padding};
  }
  .groups-widget {
    padding-right: Calc(${Spacings.xSmall.padding} - 4px);
  }
`;
export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 calc(${Spacings.xSmall.padding} - 4px);
  }
  & > .ant-form-item-control {
    padding-left: calc(${Spacings.small.padding} + 4px);
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.small.regular};
    width: 100%;
    line-height: ${Variables.LineHeightMd};
  }
  .ant-form-item {
    margin-bottom: ${Spacings.xSmall.margin};
  }
  .multiple-inputs {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;

export const DateWrapper = styled.div`
  ${x};
  background: ${Colors.Container};
  padding: 0 20px;
  border-bottom: 1px solid ${Colors.BorderLight};
  height: 48px;
  color: ${Colors.Text};
  @media screen and (min-width: 1100px) {
    padding: 0 20px;
  }
`;
