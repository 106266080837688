import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Variables from 'design/Variables';

export const EmptyWrapper = styled.div`
  height: Calc(100vh - 50%);
  @media (max-width: 850px) {
    margin-top: 15vh;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  .empty-card {
    max-width: 557px;
  }
  .title {
    color: ${Colors.TextSecondary};
    font: ${fonts.Big.medium};
    line-height: ${Variables.LineHeightXxl};
    margin-bottom: 2px;
  }
  .description {
    color: ${Colors.TextSecondary};
    font: ${fonts.medium.regular};
    line-height: ${Variables.LineHeightSm};
  }
`;
