const Spacings = {
  tiny: {
    padding: '4px',
    margin: '4px',
  },
  // 8px
  xxSmall: {
    padding: '8px',
    margin: '8px',
  },
  // 16px
  xSmall: {
    padding: '16px',
    margin: '16px',
  },
  // 20px
  Small: {
    padding: '20px',
    margin: '20px',
  },
  // 24px
  small: {
    padding: '24px',
    margin: '24px',
  },
  // 32px
  medium: {
    padding: '32px',
    margin: '32px',
  },
  // 40px
  large: {
    padding: '40px',
    margin: '40px',
  },
  // 48px
  xLarge: {
    padding: '48px',
    margin: '48px',
  },
  // 56px
  xxLarge: {
    padding: '56px',
    margin: '56px',
  },
  huge: {
    padding: '60px',
    margin: '60px',
  },
  xHuge: {
    padding: '125px',
    margin: '125px',
  },
};

export default Spacings;
