import React from 'react';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _slice from 'lodash/slice';
import styled from 'styled-components';
import GroupTag from 'atoms/GroupTag';
import GroupTagPopover from 'molecules/GroupTagPopover';
import { GROUP_TYPE } from 'utils/constants';

const FlexWrapper = styled.div`
  display: flex;
`;

const GroupTagList = ({ departments: unsortedDept, limit = 2 }) => {
  const departments = _sortBy(
    unsortedDept,
    (d) => d?.type === GROUP_TYPE.DEPARTMENT
  );
  return (
    <FlexWrapper>
      {_map(_slice(departments, 0, limit), (department, index) => (
        <GroupTag key={index} {...department} type={department?.type} />
      ))}
      {departments?.length > limit && (
        <GroupTagPopover departments={departments} />
      )}
    </FlexWrapper>
  );
};

export default GroupTagList;
