import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled, { createGlobalStyle } from 'styled-components';
import _get from 'lodash/get';
import Spacings from 'design/Spacings';
import { POPOVER_TYPE, EMPTY_ARRAY, HELP_CONTENT } from 'utils/constants';
import { helpClient } from 'apollo';
import { GET_HELP } from 'graphql/Help/Queries';
import Colors from 'design/Colors';
import Variables from 'design/Variables';
import Button from 'atoms/Button';
import { fonts } from 'design/Fonts';
const Content = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  max-width: 100%;
  line-height: ${Variables.LineHeightXl};
  color: ${Colors.TextPrimary};
  margin-right: ${Spacings.xSmall.padding};
  .infoicon {
    margin-left: Calc(${Spacings.xxSmall.padding} + 2px);
    font-size: 20px;
    color: ${Colors.TextSecondary};
  }
  .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const StyledDiv = styled.div`
  font: ${fonts.small.medium};
  line-height: ${Variables.LineHeightMd};
  width: 300px;
  color: ${Colors.TextSecondary};
`;

export const GlobalStyles = createGlobalStyle`
   .ant-popover-inner ,
   .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow  {
     margin-top: 4px;
  }
`;

const HelpWrapper = styled.div`
  .slick-dots {
    width: inherit;
  }
  .slick-dots li.slick-active {
    width: 16px;
  }
  .slick-arrow:before {
    color: black;
  }
  .slick-prev {
    left: -5px;
  }
  .slick-next {
    right: -5px;
  }
  .button {
    margin-top: 10px;
  }
`;
const InfoIcon = ({ title, className, link, contentKey }) => {
  const HelpContent = () => (
    <HelpWrapper arrows>
      <StyledDiv>{HELP_CONTENT[contentKey]}</StyledDiv>
      {link && (
        <Button
          className="button"
          name="Learn More"
          onClick={() => {
            window.open(link);
          }}
          lineHeight="15px"
        ></Button>
      )}
    </HelpWrapper>
  );

  return (
    <Content className={className}>
      <div className="title">{title}</div>
      {contentKey && (
        <Popover
          placement={POPOVER_TYPE.BOTTOM}
          content={
            <>
              <GlobalStyles />
              <HelpContent />
            </>
          }
        >
          <InfoCircleOutlined className="infoicon" />
        </Popover>
      )}
    </Content>
  );
};

export default InfoIcon;
