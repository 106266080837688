/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Tooltip } from 'antd';
import _get from 'lodash/get';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { fonts } from 'design/Fonts';
import { MomentFormat } from './MomentFormat';

const StyledDate = styled.span`
  font-size: ${({ size }) =>
    size === 'large'
      ? fonts.small.size
      : size === 'medium'
      ? fonts.xxSmall.size
      : fonts.xTiny.size};
`;

export default function (props) {
  const { state } = useContext(AppContext);
  const {
    date,
    format = MomentFormat.date,
    fromNow,
    size = 'large',
    className,
  } = props;
  const [timeZone, setTimeZone] = useState('Asia/Kolkata');
  const userTimeZone = _get(state, 'loginUser.location.timeZone', null);
  useEffect(() => {
    if (userTimeZone) setTimeZone(userTimeZone);
  }, [userTimeZone]);

  return date ? (
    //  Might need for future
    // <Tooltip title={moment(date).tz(timeZone).format(MomentFormat.dateTime)}>
    <StyledDate size={size} className={className}>
      {fromNow
        ? moment(date).tz(timeZone).fromNow()
        : moment(date).tz(timeZone).format(format)}
    </StyledDate>
  ) : (
    // </Tooltip>
    <div>-</div>
  );
}
