/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import {
  Popover, Form, Typography,
} from 'antd';
import { FormattedMessage } from 'react-intl';

import Table from 'atoms/Table';
import Input from 'atoms/Input';
import CommentsPopover from 'organisms/CommentsPopover';
import Card from 'atoms/Card';
import GroupOrUser from 'molecules/GroupOrUser';
import UsersListWidgetAsync from 'organisms/UsersListWidgetAsync';
import { TASK_FILED, TASK_TYPE } from 'utils/constants';
import Hours from 'atoms/Hours';
import InlineEditable from 'molecules/InlineEditable';
import _filter from 'lodash/filter';
import {
  UnCheck,
  CheckIcon,
  Checked,
} from 'assets';
import {
  Content,
  ContentTitle,
  Comments,
  Title,
  Image,
  BlockersRemoveCount,
} from './AntStyled';
import TaskCompletion from '../TaskCompletion';
import { TitleDiv } from '../Tasks/AntStyled';

const { Text } = Typography;

export default function ({
  data, handleCheckbox, allowModify = false, allowAssign = false, updateTask, loading, setSortData, groupLoading, blockerAssignedState, blockersToRemoveCount,
}) {
  const [dataSource, setDataSource] = useState(data);
  const [completionId, setCompletionId] = useState(null);
  const [selectedBlockerAssignedType, setSelectedBlockerAssignedType] = useState('all');

  useEffect(() => {
    if (data) {
      let taskTableData = [];
      taskTableData = _map(data, (row, key) => ({
        key: key.toString(),
        id: row.id,
        isCompleted: row.isCompleted,
        estimation: row.estimation,
        name: row.name,
        group: JSON.stringify(row.group),
        assignTo: row.user && JSON.stringify(row.user),
        from: JSON.stringify(row.ownerUser ? row.ownerUser : row.owner),
        comments: row.comments,
        groupData: row.group,
        completionHours: row.completionHours,
      }));
      setDataSource(taskTableData);
    }
  }, [data]);

  const inLineEditable = (dataIndex, inputRef, save, rowData) => {
    let node;
    switch (dataIndex) {
      case TASK_FILED.ESTIMATION:
        node = (
          <Form.Item
            name={dataIndex}
            rules={[
              // { required: true, message: <FormattedMessage id="me.formRules.estimation.errorMessage" /> },
              { pattern: /^\d{1,2}(\.\d{1,2})?$/, message: <FormattedMessage id="me.formRules.estimation.hoursPattern" /> },
            ]}
          >
            <Input innerRef={inputRef} onPressEnter={save} onBlur={save} />
          </Form.Item>
        );
        break;
      default:
        break;
    }
    return node;
  };

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      className: 'no-padding check-mark',
      align: 'center',
      render: isCompleted => isCompleted && <Image src={Checked} />,
      // sorter: (a, b) => a.isCompleted - b.isCompleted,
    },
    {
      title: 'Blockers to Resolve',
      dataIndex: 'name',
      key: 'title',
      className: 'action-name',
      // width: '35%',
      render: (name, rowData) => <Title isCompleted={rowData.isCompleted}> {name}</Title>,
      // sorter: (a, b) => a.name - b.name,
    },
    {
      title: 'Group',
      dataIndex: 'group',
      // editable: true,
      width: '16.5%',
      render: (row, rowData) => <GroupOrUser data={rowData} />,
      // sorter: (a, b) => JSON.parse(a.group).name.length - JSON.parse(b.group).name.length,
    },
    {
      title: 'Estimation',
      dataIndex: 'estimation',
      width: '12.5%',
      editable: true,
      render: (estimation, rowData) => rowData.estimation ? <Hours estTime={estimation || ''} /> : '-',
    },
    // {
    //   title: 'Spent',
    //   dataIndex: 'completionHours',
    //   width: '7%',
    //   editable: false,
    //   render: (spent, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : spent ? <div><Hours estTime={spent || ''} /></div> : '-',
    //   // sorter: (a, b) => a.estimation - b.estimation,
    // },
    {
      title: 'Blocking',
      dataIndex: 'from',
      key: 'from',
      width: '12%',
      render: (row, rowData) => rowData.from && <GroupOrUser dropdown stackedName data={{ user: JSON.parse(rowData.from) }} />,
      // sorter: (a, b) => a.from.length - b.from.length,
    },
    {
      title: 'Assign To',
      dataIndex: 'assignTo',
      key: 'assignTo',
      width: '10%',
      // eslint-disable-next-line no-nested-ternary
      render: (row, rowData) => rowData.assignTo ? <GroupOrUser dropdown stackedName data={{ user: JSON.parse(rowData.assignTo) }} /> : (
        allowAssign ? (
          <UsersListWidgetAsync
            skip={2}
            button
            placement="bottom"
            data={[]}
            groupId={rowData.groupData.id}
            // data={_concat(rowData.groupData.members, rowData.groupData.managers)}
            alreadyMembers={[]}
            createGroupUser={userId => updateTask({
              name: rowData.name, group: rowData.group, id: rowData.id, user: JSON.stringify({ id: userId }),
            }, TASK_TYPE.BLOCKER_ASSIGNED)}
            allowDelete={false}
            profileSize={25}
          />
        )
          : 'Not assigned'
      ),
      // sorter: (a, b) => a.assignTo && b.assignTo && JSON.parse(a.assignTo).firstName.length - JSON.parse(b.assignTo).firstName.length,
    },
    {
      // title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
      width: '75px',
      render: (row, rowData) => <Comments><CommentsPopover actionId={rowData.id} data={rowData.comments} /></Comments>,
    },
    {
      align: 'center',
      className: 'task-checkbox',
      width: '32px',
      className: 'padding-col',
      // eslint-disable-next-line no-nested-ternary
      render: rowData => !rowData.loading
        ? (
          <div className={`task-status ${allowModify ? 'modify' : ''}`}>{
            allowModify
              ? (
                <Popover
                  visible={rowData.id === completionId}
                  onVisibleChange={visible => !visible && closeCompletionModal()}
                  content={(
                    <TaskCompletion
                      completeTask={value => updateTask({ ...rowData, isCompleted: !rowData.isCompleted, completionHours: value && parseFloat(value) }, TASK_TYPE.BLOCKER_ASSIGNED).then(() => closeCompletionModal())}
                      closeModal={closeCompletionModal}
                    />
                  )}
                  overlayClassName="popover-no-padding"
                  trigger="click"
                  placement="left"
                >
                  <div role="button" tabIndex={0} onClick={() => handleComplete(!rowData.isCompleted, rowData.id)}>
                    {
                      rowData.isCompleted
                        ? <Image src={UnCheck} />
                        : <Image src={CheckIcon} />
                    }
                  </div>
                </Popover>

              )
              : rowData.isCompleted
                ? <Image src={UnCheck} />
                : <Image src={CheckIcon} />
          }
          </div>
        )
        : null,
      // render: rowData => !rowData.loading ? allowModify ? <Checkbox key={rowData.id} checked={rowData.isCompleted} disabled={!allowModify} onChange={e => handleCheckbox(e.target.checked, rowData.id)} /> : rowData.isCompleted && <CheckOutlined twoToneColor /> : null,
    },
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];
  const handleComplete = (value, id) => {
    if (value) {
      setCompletionId(id);
    } else {
      handleCheckbox(value, id, TASK_TYPE.BLOCKER);
      // updateTask({ ...rowData, isCompleted: !rowData.isCompleted, completionHours: value && parseFloat(value) }, TASK_TYPE.BLOCKER);
    }
  };

  const closeCompletionModal = () => {
    setCompletionId(null);
  };

  function onChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'ascend') {
        setSortData({ field: sorter.field, type: 'ASC', blockerType: 'assigned' });
      } else {
        setSortData({ field: sorter.field, type: 'DESC', blockerType: 'assigned' });
      }
    }
  }

  const locale = {
    emptyText: data && data.length === 0 && blockersToRemoveCount > 0 && <Text type="secondary">{`${blockersToRemoveCount} blocker(s) to remove in other group(s)`}</Text>,
  };

  return (
    <Content>
      <Card className="rounded" padding="0">
        <TitleDiv>
          {/* <ContentTitle><FormattedMessage id="me.Blockers" /></ContentTitle> */}
          {/* <TriStateSwitch values={blockerAssignedState} selected={selectedBlockerAssignedType} setSelectedTaskType={setSelectedBlockerAssignedType} /> */}
        </TitleDiv>
        {
          allowModify ? (
            <InlineEditable
              dataSource={selectedBlockerAssignedType === 'all' ? dataSource : _filter(dataSource, { isCompleted: selectedBlockerAssignedType === 'completed' })}
              columns={columns}
              updateData={e => updateTask(e, TASK_TYPE.BLOCKER_ASSIGNED)}
              inLineEditable={inLineEditable}
              loading={groupLoading === true ? groupLoading : loading}
              onChange={onChange}
              className="bb-0"
              noForm
            />
          )
            : (
              <>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  loading={groupLoading === true ? groupLoading : loading}
                  height="calc(100vh - 99px - 64px - 30px)"
                  onChange={onChange}
                  locale={locale}
                  className="bb-0"
                />
                { data && data.length !== 0 && blockersToRemoveCount > 0
                && (
                  <BlockersRemoveCount>
                    <Text type="secondary">{`+ ${blockersToRemoveCount} blocker(s) to remove in other group(s)`}</Text>
                  </BlockersRemoveCount>
                )}
              </>
            )
        }
      </Card>
    </Content>
  );
}
