import {
  DailyGoalsIcon,
  UserAbsent,
  SpeakerIcon,
  PantoneIcon,
  OptionsTodoIcon,
  PersonDeleteOutline,
  MessageOutline,
  SoundIcon,
} from 'assets';
import React from 'react';
import Colors from 'design/Colors';

export const EMPTY_STRING = '';
export const EMPTY_ARRAY = Object.freeze([]);
export const EMPTY_OBJECT = Object.freeze({});
export const UNDEFINED = Object.freeze(undefined);
export const NULL = Object.freeze(null);
export const TASK_BLOCKER_TYPE = ['all', 'completed', 'pending'];
export const GROUP_TYPE_FIELD = ['all', 'project', 'department'];
export const PAGE_LIMIT = 20;

export const GROUP_TYPE = {
  DEPARTMENT: 'DEPARTMENT',
  PROJECT: 'PROJECT',
  USER: 'USER',
};
export const FILTER_TYPE = {
  DEPARTMENT: 'DEPARTMENT',
  PROJECT: 'PROJECT',
  USER: 'USER',
  LOCATION: 'LOCATION',
};
export const GROUP_USER_TYPE = {
  LEADER: 'LEADER',
  MANAGER: 'MANAGER',
  MEMBER: 'MEMBER',
};

export const ACTIVITY_STATUS = {
  TASK: 'TASK',
};

export const USER_ACTION = {
  REMOVE: 'Remove',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DISABLE: 'Disable',
  ENABLE: 'Enable',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  MAKE_DEFAULT: 'Make Default',
  CANCEL_REQUEST: 'Cancel Request',
  DEACTIVATE: 'Deactivate',
  ACTIVATE: 'Activate',
  ARCHIVED: 'Archived',
  RESEND: 'Resend Invitation',
  RESTORE_DAILY_GOAL: 'Restore Daily Goal',
  RESTORE_BLOCKER: 'Restore Blocker',
  ARCHIVE_TASK: 'Archive Task',
  ARCHIVE_BLOCKER: 'Archive Blocker',
};

export const CHECK_IN_FILED = {
  NAME: 'name',
  ASSIGN_TO: 'assignTo',
  ESTIMATION: 'estimation',
  SPENT: 'spent',
  COMPLETED: 'completed',
};

export const LEAVE_TYPE = {
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
};

export const MY_PROFILE_TAB = {
  DETAILS: 'details',
  ATTENDANCE: 'attendance',
  LEAVES: 'leaves',
  HISTORY: 'history',
};

export const DIVIDER_TYPE = {
  VERTICAL: 'vertical',
};

export const USER_ROLE = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  OWNER: 'OWNER',
};

export const COL_TYPE = {
  RIGHT: 'right',
  AUTO: 'auto',
};

export const COLORS = {
  GREEN: 'green',
  GOLD: 'gold',
  BLUE: 'blue',
  RED: 'red',
  GOLDENROD: 'goldenrod',
};

export const FETCH_POLICY = {
  NETWORK_ONLY: 'network-only',
  NO_CACHE: 'no-cache',
  CACHE_FIRST: 'cache-first',
  CACHE_ONLY: 'cache-only',
  CACHE_AND_NETWORK: 'cache-and-network',
};

export const CHECKIN_STATUS = {
  CHECKED_IN: 'CHECKEDIN',
  ON_BREAK: 'ONBREAK',
  END_BREAK: 'ENDBREAK',
  DONE: 'DONE',
  ABSENT: 'ABSENT',
};

export const META_TYPE = {
  ACTION_LIST: 'ActionList',
  ATTENDANCE_LEAVES: 'Attendance & Leaves',
  ALREADY_REGISTERED: 'Already Registered',
  CONFIRMATION: 'Confirmation',
  FORGOT_PASSWORD: 'Forgot Password',
  LOGIN: 'Login',
  ONBOARDING: 'Onboarding',
  RESET_PASSWORD: 'Reset Password',
  SET_PASSWORD: 'Set Password',
  SIGNUP: 'Signup',
  DASHBOARD: 'Dashboard',
  DAILY_GOALS: 'DailyGoals',
  GUIDE: 'Guide',
  INSIGHTS: 'Insights',
  ACTIVITIES: 'Activities',
  LEAVES: 'Leaves',
  ME: 'Me',
  TIME: 'Time',
  MEETINGS: 'Meetings',
  PROFILE: 'Profile',
  TEAMS: 'Teams',
  UPDATES: 'Updates',
  FEED_BACK: 'Feedback',
  FEED_BACK_SETTINGS: 'Feedback settings',
  QUOTES: 'Quotes',
  SURVEYS: 'Survey',
  POLLS: 'Poll',
  SETTINGS: 'Settings',
  GENERAL_SETTINGS: 'General Settings',
  NOT_FOUND: 'Page not found',
  WORK_SPACE: 'Workspace',
  SOPS: 'SOPs',
  ANNOUNCEMENTS: 'Announcements',
  ANNOUNCEMENTS_SETTINGS: 'Announcements settings',
  STAND_UP: 'Stand-up',
  STAND_UPS_SETTINGS: 'Stand-up setting',
  MY_CHECK_IN: 'My Check-In',
  TODOS: 'Todos',
  TOKEN_EXPIRED: 'Token Expired',
  NOTIFICATIONS: 'Notification',
  GROUPS: 'Groups',
  USERS: 'Users',
  INTEGRATIONS: 'Integrations',
  TEAM_CHECK_IN: 'Team check-in',
};

export const HELP_CONTENT = {
  [META_TYPE?.STAND_UP]: 'Stand-Ups are your daily checkins with your goals for the day and updates from your previous workday.',
  [META_TYPE?.FEED_BACK]: 'Give feedback for daily polls and surveys added.',
  [META_TYPE?.FEED_BACK_SETTINGS]: 'Feedback helps you collect responses from your team in the form of Surveys and Polls.',
  [META_TYPE?.ANNOUNCEMENTS]: 'Check out Announcements published by Manager/Admin in the workspace. ',
  [META_TYPE?.ANNOUNCEMENTS_SETTINGS]: ' Announcements helps you announce something and let your team know easily and efficiently.',
  [META_TYPE?.QUOTES]: 'Motivate your team daily with Quotes.',
  [META_TYPE?.TEAMS]: ' Keep track of your team’s details and their stand-ups.',
  [META_TYPE?.TODOS]: 'ToDos help you prioritize your work. Never miss an update with ToDos',
  [META_TYPE?.UPDATES]: "Get the overview of what's happening in Undiffer with Updates.",
  [META_TYPE?.ACTIVITIES]: 'Activities helps you keep a record of events happening in the integrated softwares and repos.',
  [META_TYPE?.INSIGHTS]: 'Get insights on all activities with real-time analytics and numbers  in undiffer.',
  [META_TYPE?.DASHBOARD]: 'Check out your new goals, Team summaries, and trends.',
  [META_TYPE?.SETTINGS]: 'Update your user profile and password.',
  [META_TYPE?.NOTIFICATIONS]: 'Activate / Deactivate email notification for features, Gchat and Slack.',
  [META_TYPE?.GENERAL_SETTINGS]: 'manage workspace settings, Holidays, Departments, and workday of your organisation.',
  [META_TYPE?.GROUPS]: 'Create groups and add members in it to share things efficiently.',
  [META_TYPE?.USERS]: 'Invite and add new users to the workspace',
  [META_TYPE?.INTEGRATIONS]: 'Integrate Communication Platforms and Activity tools used throughout the organization.',
  [META_TYPE?.TEAM_CHECK_IN]: 'Keep note of check-in details and activities of your team',
};

export const ONBOARDING = {
  SELECTION: '/onboarding/selection',
  ROOT: '/onboarding',
  WORKSPACE_RESULT: '/onboarding/workspace',
  COMPANY_STEP: 'COMPANY',
  FEATURE: 'FEATURES',
  INVITED: 'INVITED',
  DASHBOARD: 'DASHBOARD',
  COMPLETED: 'COMPLETED',
  ONBOARDING: '/onboarding',
  COMPANY: '/onboarding/company',
  TEAM: '/onboarding/team-members',
  SUCCESS: '/onboarding/success',
  FEATURES: '/onboarding/features',
};

export const PUBLIC_ROUTE = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  AUTH_ERROR: '/auth-error',
  CONFIRM: '/confirm',
  CONFIRM_TOKEN: '/confirm/:token',
  TOKEN_EXPIRED: '/token-expired',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_TOKEN: '/reset-password/:token',
  SET_PASSWORD: '/set-password',
  ALREADY_REGISTERED: '/already-registered',
  KEY: {
    FORGOT_PASSWORD: 'forgot-password',
    LOGOUT: 'layout',
    SIGNUP: 'signup',
    PATH: '/',
  },
};
export const STATUS = {
  ONLINE: { text: 'Online', color: Colors.GraphGreen },
  CHECKEDIN: { text: 'Checked In', color: Colors.Working },
  ONBREAK: { text: 'On Break', color: Colors.OnBreak },
  DONE: { text: 'Checked Out', color: Colors.WorkDone },
  ABSENT: { text: 'Absent', color: Colors.TextWarning },
};
export const STATUS2 = {
  ONLINE: { text: 'Online', color: Colors.GraphGreen },
  CHECKEDIN: { text: 'Working', color: Colors.Working },
  ONBREAK: { text: 'On Break', color: Colors.OnBreak },
  DONE: { text: 'Work Done', color: Colors.WorkDone },
  ABSENT: { text: 'On Leave', color: Colors.TextWarning },
};
export const PRIVATE_ROUTE = {
  DASHBOARD: '/dashboard',
  ATTENDANCE_LEAVES: '/attendance-leaves',
  ME: '/daily-goals/me',
  PEOPLE: '/people',
  INSIGHTS: '/insights',
  ACTIVITIES: '/activities',
  LEAVES: '/leaves',
  LEAVES_REQUEST: '/people/leaverequest',
  LEAVES_SUMMARY: '/people/leavesummary',
  CHECK_IN: '/check-in',
  TEAMS: '/teams',
  MEETINGS: '/meetings',
  PROFILE: '/profile/:id/:page',
  PROFILE_DETAIL: '/profile/:id/details',
  PROFILE_ANALYTICS: '/profile/:id/analytics',
  PROFILE_ATTENDANCE: '/profile/:id/attendance',
  PROFILE_DAILY_GOALS: '/profile/:id/daily-goals',
  PROFILE_LEAVES: '/profile/:id/leaves',
  DAILY_GOALS: '/daily-goals',
  DAILY_GOALS_ACTIVE: '/daily-goals/active',
  DAILY_GOALS_ARCHIVED: '/daily-goals/archived',
  BLOCKER: '/daily-goals/blockers',
  BLOCKER_ACTIVE: '/daily-goals/blockers/active',
  BLOCKER_ARCHIVED: '/daily-goals/blockers/archived',
  MY_PROFILE: '/my-profile/:page',
  // MY_PROFILE_DETAIL: '/my-profile/details',
  // MY_PROFILE_ANALYTICS: '/my-profile/analytics',
  // MY_PROFILE_ATTENDANCE: '/my-profile/attendance',
  // MY_PROFILE_DAILY_GOALS: '/my-profile/daily-goals',
  QUOTE_ACTIVE: '/settings/quotes/active',
  QUOTE_DEACTIVATED: '/settings/quotes/deactivated',
  QUOTES: '/quotes',
  QUOTES_VIEW: '/quotes/view/:id',
  QUOTE_ALL: '/quotes/all',
  QUOTE_EDIT: '/quotes/edit/:id',
  QUOTE_NEW: '/quotes/new',
  UPDATES: '/updates',
  UPDATES_CURRENT: '/updates/current',
  UPDATES_CLEARED: '/updates/cleared',
  TODOS: '/todos',
  TODOS_CURRENT: '/todos/current',
  TODOS_HISTORY: '/todos/history',
  GUIDE: '/guide',
  FEED_BACK: '/feedback',
  SOPS: '/sops',
  SOPS_VIEW: '/sops/view/:id',
  SOP_ACTIVE: '/sops/active',
  SOP_DRAFT: '/sops/draft',
  SOP_ARCHIVE: '/sops/archive',
  SOP_ADD: '/sops/add',
  SOP_EDIT: '/sops/edit/:id',
  SOP_NEW: '/sops/new',
  ANNOUNCEMENTS: '/announcements',
  ANNOUNCEMENTS_VIEW: '/announcements/view/:id',
  ANNOUNCEMENT_ALL: '/announcements/all',
  ANNOUNCEMENT_DRAFT: '/announcements/draft',
  ANNOUNCEMENT_EDIT: '/announcements/edit/:id',
  ANNOUNCEMENT_NEW: '/announcements/new',
  STAND_UP: '/stand-up',
  STAND_UPS_ALL: '/stand-up/all',
  MY_CHECK_IN: '/stand-up/my-check-in',
  TEAM_CHECK_IN: '/stand-up/team-check-in',
  FEED_BACK_CURRENT: '/feedback/current',
  FEED_BACK_COMPLETED: '/feedback/completed',
  FEED_BACK_DRAFT: '/feedback/draft',
  SURVEY_NEW: '/feedback/survey-new',
  SURVEY_CREATE: '/feedback/survey-create/step1',
  SURVEY_QUESTIONS: '/feedback/survey-create/step2',
  SURVEY_EDIT: '/feedback/survey/edit/:id',
  POLL_EDIT: '/feedback/poll/edit/:id',
  POLL_NEW: '/feedback/poll-new',
  POLL_CREATE: '/feedback/poll-create/step1',
  POLL_QUESTIONS: '/feedback/poll-create/step2',
  SURVEY: '/feedback/survey/:id',
  SURVEY_SUMMARY: '/feedback/survey/:id/summary',
  SURVEY_RESPONSE: '/feedback/survey/:id/response',
  POLLS: '/feedback/poll/:id',
  POLLS_SUMMARY: '/feedback/poll/:id/summary',
  POLLS_RESPONSE: '/feedback/poll/:id/response',
  SETTINGS: {
    MAIN_ROUTE: {
      PAGE: '/settings/:page',
      MY_PROFILE_DETAIL: 'my-profile',
      QUOTE_ACTIVE: 'quotes/active',
      PROFILE: 'profile/details',
      SOPS: 'sops/activate',
    },
    SUB_ROUTE: {
      PAGE: '/settings/:page/:page',
      GENERAL_SETTINGS: '/settings/general-settings',
      USERS: '/settings/users',
      ADD_USER: '/settings/users/add',
      EDIT_USER: '/settings/users/edit/:id',
      LOCATION: '/settings/locations',
      ADD_LOCATION: '/settings/locations/add',
      EDIT_LOCATION: '/settings/locations/edit/:id',
      GROUPS: '/settings/groups',
      ADD_GROUPS: '/settings/groups/add',
      EDIT_GROUPS: '/settings/groups/edit/:id',
      PROJECTS: '/settings/projects',
      ADD_PROJECTS: '/settings/projects/add',
      EDIT_PROJECTS: '/settings/projects/edit/:id',
      DEPARTMENTS: '/settings/departments',
      ADD_DEPARTMENTS: '/settings/departments/add',
      EDIT_DEPARTMENTS: '/settings/departments/edit/:id',
      BILLINGS: '/settings/billings',
      QUOTES: '/settings/quotes/:page',
      ADD_QUOTES: '/settings/quotes/add',
      EDIT_QUOTES: '/settings/quotes/edit',
      MY_PROFILE: '/settings/my-profile',
      NOTIFICATION_SETTINGS: '/settings/notification-settings',
      GROUP_ACCESS_SETTINGS: '/settings/group-access-settings',
      ATTENDANCE_LEAVES: '/settings/attendance',
      PEOPLE_SETTINGS: '/settings/people',
      OKR: '/settings/okr',
      DAILY_GOAL_SETTINGS: '/settings/daily-goals-settings',
      DANGER_AREA: '/settings/danger-area',
      MY_PROFILE_PAGE: '/settings/profile/:page',
      MY_PROFILE_DETAIL: '/settings/profile/details',
      MY_PROFILE_ANALYTICS: '/settings/profile/analytics',
      MY_PROFILE_ATTENDANCE: '/settings/profile/attendance',
      MY_PROFILE_DAILY_GOALS: '/settings/profile/daily-goals',
      SOPS: '/settings/sops',
      FEEDBACKS: '/settings/feedbacks',
      MY_PROFILE_LEAVES: '/settings/profile/leaves',
      ANNOUNCEMENT_SETTINGS: '/settings/announcements',
      QUOTES_SETTINGS: '/settings/quotes',
      STAND_UPS_SETTINGS: '/settings/stand-ups',
      INTEGRATIONS: '/settings/integrations',
    },
  },
  KEY: {
    DETAILS: 'details',
    ANALYTICS: 'analytics',
    ATTENDANCE: 'attendance',
    LEAVES: 'leaves',
    NEW: 'new',
    ACTIVE: 'active',
    ARCHIVED: 'archived',
    CURRENT: 'current',
    COMPLETED: 'completed',
    DRAFT: 'draft',
    UPDATES: 'updates',
    TODOS: 'todos',
    DAILY_GOALS: 'daily-goals',
    BLOCKER: 'blockers',
    PROFILE: 'profile',
    MY_PROFILE: 'my-profile',
    QUOTES: 'quotes',
    GENERAL_SETTINGS: 'general-settings',
    USERS: 'users',
    LOCATIONS: 'locations',
    PROJECTS: 'projects',
    DEPARTMENTS: 'departments',
    BILLINGS: 'billings',
    NOTIFICATION_SETTINGS: 'notification-settings',
    GROUP_ACCESS_SETTINGS: 'group-access-settings',
    ATTENDANCE_LEAVES: 'attendance-leaves',
    OKR: 'okr',
    CHECK_IN_SETTING: 'daily-goals-settings',
    DANGER_AREA: 'danger-area',
    SOPS: 'sops',
    HISTORY: 'history',
  },
};
export const ISSUEMESSAGE = {
  UNEXPECTEDENTRY: 'Unexpected entries',
  BREAKSTARTEARLY: 'Early Break Starts',
  BREAKSTARTLATE: 'Late Break Starts',
  BREAKENDEARLY: 'Early Break Ends',
  BREAKENDLATE: 'Late Break Ends',
  PUNCHINEARLY: 'Early Punch-ins',
  PUNCHINLATE: 'Late Punch-ins',
  PUNCHOUTEARLY: 'Early Punch-Outs',
  PUNCHOUTLATE: 'Late Punch-Outs',
};

export const SURVEYS_TYPE = {
  ADD_SURVEY: 'Add Survey',
  ADD_POLL: 'Add Poll',
  SURVEYS: 'SURVEY',
  POLLS: 'POLL',
};

export const PLATFORM_TYPE = {
  GITHUB: 'github',
  GITLAB: 'gitlab',
  CLICKUP: 'clickup',
  BITBUCKET: 'bitbucket',
  ZENDESK: 'zendesk',
  ASANA: 'asana',
  JIRA: 'jira',
};

export const ACTIVITY_EVENT_TYPE = {
  PUSH: 'PUSH',
  MERGE_REQUEST: 'MERGE_REQUEST',
  TASK_CREATED: 'TASK CREATED',
  TASK_ADDED: 'TASK ADDED',
  TASK_CHANGED: 'TASK CHANGED',
  TASK_REMOVED: 'TASK REMOVED',
  TASK_DELETED: 'TASK DELETED',
  TASK_UNDELETED: 'TASK UNDELETED',
  TASK_STATUS_UPDATED: 'TASK STATUS UPDATED',
  TASK_ASSIGNEE_UPDATED: 'TASK ASSIGNEE UPDATED',
  STORY_ADDED: 'STORY ADDED',
  STORY_CREATED: 'STORY CREATED',
  STORY_REMOVED: 'STORY REMOVED',
  STORY_UNDELETED: 'STORY UNDELETED',
  ATTACHMENT_DELETED: 'ATTACHMENT DELETED',
  ATTACHMENT_UNDELETED: 'ATTACHMENT UNDELETED',
  ATTACHMENT_UPDATED: 'ATTACHMENT UPDATED',
  ATTACHMENT_CREATED: 'ATTACHMENT CREATED',
  COMMENT_ADDED: 'COMMENT ADDED',
  COMMENT_CREATED: 'COMMENT CREATED',
  COMMENT_UPDATED: 'COMMENT UPDATED',
  COMMENT_DELETED: 'COMMENT DELETED',
  ISSUE_CREATED: 'ISSUE CREATED',
  ISSUE_UPDATED: 'ISSUE UPDATED',
  ISSUE_DELETED: 'ISSUE DELETED',
  SPRINT_STARTED: 'SPRINT STARTED',
  SPRINT_CLOSED: 'SPRINT CLOSED',
};

export const TASK_TYPE = {
  PLAN: 'PLAN',
  BLOCKER: 'BLOCKER',
  ESTIMATION: 'estimation',
  COMPLETED: 'isCompleted',
  TYPE: 'type',
  BLOCKER_ASSIGNED: 'BLOCKER_ASSIGNED',
};

export const POP_CONFIRM_PLACEMENT = {
  BOTTOM_RIGHT: 'bottomRight',
  RIGHT_TOP: 'rightTop',
};

export const events = {
  github: {
    eventType: ACTIVITY_EVENT_TYPE.PUSH,
    title: 'Commits',
  },
  gitlab: { eventType: ACTIVITY_EVENT_TYPE.PUSH, title: 'Commits' },
  bitbucket: { eventType: ACTIVITY_EVENT_TYPE.PUSH, title: 'Commits' },
  clickup: {
    eventType: [
      ACTIVITY_EVENT_TYPE.TASK_CREATED,
      ACTIVITY_EVENT_TYPE.TASK_STATUS_UPDATED,
      ACTIVITY_EVENT_TYPE.TASK_ASSIGNEE_UPDATED,
    ],
    title: {
      [ACTIVITY_EVENT_TYPE.TASK_CREATED]: 'Tasks added',
      [ACTIVITY_EVENT_TYPE.TASK_STATUS_UPDATED]: 'Status updated',
      [ACTIVITY_EVENT_TYPE.TASK_ASSIGNEE_UPDATED]: 'Assignee updated',
    },
  },
  zendesk: {
    eventType: ACTIVITY_EVENT_TYPE.TASK_CREATED,
    title: 'Tickets solved',
  },
  asana: {
    eventType: ACTIVITY_EVENT_TYPE.TASK_CREATED,
    title: 'Tasks added',
  },
  jira: {
    eventType: ACTIVITY_EVENT_TYPE.TASK_CREATED,
    title: 'Tasks added',
  },
};

export const POPOVER_TYPE = {
  BOTTOM: 'bottom',
  CLICK: 'click',
  LEFT_BOTTOM: 'leftBottom',
  BOTTOM_RIGHT: 'bottomRight',
  BOTTOM_LEFT: 'bottomLeft',
  HOVER: 'hover',
  RIGHT: 'right',
  LEFT: 'left',
};

export const DAY_TYPE = {
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const MENU_MODE = {
  IN_LINE: 'inline',
};

export const QUESTION_TYPE = {
  MCQ: 'MULTIPLE_CHOICE',
  OPINION_SCALE: 'SCALE',
  TEXT: 'TEXT',
  SINGLE_CHOICE: 'SINGLE_CHOICE',
  PICK_MORE: 'PICK_MORE',
  LIKE: 'LIKE',
  RATE: 'RATE',
};

export const FORM_NAME = {
  BASIC: 'basic',
  VERTICAL: 'vertical',
  INLINE: 'inline',
};

export const SWITCH_TYPE = {
  SMALL: 'small',
};

export const USER_TYPE = {
  LEADER: 'Leaders',
  MANAGER: 'Managers',
  MEMBER: 'Members',
};

export const CHECK_IN_SETTING_GROUP = {
  INDIVIDUAL: 'Individuals',
  DEPARTMENT: 'Departments',
  PROJECT: 'Projects',
};

export const COUNTRY = {
  INDIAN: 'INDIAN',
  USA: 'USA',
  CANADIAN: 'CANADIAN',
};

export const ALIGN = {
  MIDDLE: 'middle',
};

export const AVATAR_TYPE = {
  LARGE: 'large',
  SQUARE: 'square',
  SMALL: 'small',
};

export const ATTENDANCE_STATUS = {
  DEACTIVATE: 'DEACTIVATE',
  ACTIVATE: 'ACTIVATE',
};

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SUBMIT: 'submit',
  SMALL: 'small',
  CIRCLE: 'circle',
  DASHED: 'dashed',
  TEXT: 'text',
};

export const CARD_TYPE = {
  SMALL: 'small',
};

export const FILE_TYPE = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  JPG: 'image/jpg',
  PDF: 'application/pdf',
  DOC: 'application/msword',
};

export const IMAGE_TYPE = {
  IMAGE: 'IMAGE',
  PDF: 'PDF',
  DOC: 'DOC',
};

export const DATE_FILTER = {
  TODAY: 'TODAY',
  CURRENT_WEEK: 'CURRENT_WEEK',
  CURRENT_MONTH: 'CURRENT_MONTH',
};

export const DAILY_STATUS = {
  WORKING: 'WORKING',
  ON_BREAK: 'ON BREAK',
  ON_LEAVE: 'ON LEAVE',
  WORK_DONE: 'WORK DONE',
};

export const USER_STATUS = {
  ONLINE: 'ONLINE',
  NOT_STARTED: 'NOT STARTED',
  INVITED: 'INVITED',
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  PENDING: 'PENDING',
  DEACTIVATE: 'INACTIVE',
};
export const LEAVE_STATUS = {
  CONFIRM: 'CONFIRM',
  CANCEL: 'CANCEL',
  HOLD: 'HOLD',
  DELETED: 'DELETED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};

export const ACTIONS_TYPE = {
  INCREASE_NOTIFICATION_COUNT: 'INCREASE_NOTIFICATION_COUNT',
  DECREASE_NOTIFICATION_COUNT: 'DECREASE_NOTIFICATION_COUNT',
  LOGIN_USER: 'LOGIN_USER',
  CHECK_IN_STATUS: 'CHECK_IN_STATUS',
  LOCAL_SIGN_UP: 'LOCAL_SIGN_UP',
  SETTING_TITLE: 'SETTING_TITLE',
  MAIN_SIDEBAR: 'MAIN_SIDEBAR',
  SET_ABILITY: 'SET_ABILITY',
};

export const GROUP_ACCESS_TYPE = {
  COMMENT_ME: 'Comment assigned to @me',
  COMMENT_IN: "Comment I'm @mentioned in",
  WORK_UPDATE: "Work Update I'm @mentioned in",
  TASK: 'Task assigned to @me',
};

export const GROUP_ACCESS_USER = {
  DEPARTMENT_MANAGER: 'departmentManager',
  DEPARTMENT_MEMBER: 'departmentMember',
  PROJECT_MANAGER: 'projectManager',
  PROJECT_MEMBER: 'projectMember',
};

export const GROUP_ACCESS_LABEL = {
  HEADER: 'Header',
  COMMENTS: 'Comments',
  ATTENDANCE: 'Attendance',
  GENERAL: 'GeneralNotification',
  TASKS: 'Tasks',
};

export const NOTIFICATION_TYPE = {
  EMAIL: 'email',
  WEBAPP: 'webApp',
  BROWSER: 'browser',
  STAND_UP: 'STAND_UP',
  BLOCKER: 'BLOCKER',
  FEEDBACK: 'FEEDBACK',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  UPDATES: 'UPDATES',
  TODOS: 'TODOS',
  DEFAULT: 'DEFAULT',
};

export const NOTIFICATION_LABEL = {
  HEADER: 'Header',
  COMMENTS: 'Comments',
  WORK: 'Work Updates',
  ATTENDANCE: 'Attendance',
  SURVEYS: 'Surveys',
  AWARDS: 'Awards',
  GENERAL: 'GeneralNotification',
  TASKS: 'Tasks',
};

export const DOCUMENT_FILED = {
  TITLE: 'title',
  NUMBER: 'number',
  OTHER: 'other',
};

export const TASK_FILED = {
  NAME: 'name',
  ESTIMATION: 'estimation',
  GROUP: 'group',
};

export const UPDATE_PROGRESS = {
  ATTENDANCE: 'Attendance',
  GOAL_COMPLETED: 'Goals Completed',
  BLOCKER_RESOLVED: 'Blockers Resolved',
};

export const LEAVE_TYPE_STATUS = {
  APPROVE: 'Approve',
  REJECT: 'Reject',
};

export const FEEDBACK_TYPE = {
  SURVEY: 'SURVEY',
  POLL: 'POLL',
};

export const FEATURES_LOGO = {
  announcements_feature: SoundIcon,
  quotes_feature: MessageOutline,
  feedbacks_feature: UserAbsent,
  standups_feature: DailyGoalsIcon,
};
export const EMOJIS = [
  'tired_face',
  'persevere',
  'confused',
  'neutral_face',
  'slightly_smiling_face',
  'smiley',
  'heart_eyes',
];
export const INTEGRATIONS = {
  DAILY_STANDUP_SUMMARY: 'Daily stand-up summary',
  GOOGLE_CHAT: 'google_chat',
  SLACK: 'slack',
  MICROSOFT_TEAMS: 'microsoft_teams',
};

export const NOTIFICATIONS = {
  ANNOUNCEMENT_PUBLISHED: 'ANNOUNCEMENT_PUBLISHED',
  FEEDBACK_RESULT: 'FEEDBACK_RESULT',
  FEEDBACK_REQUEST: 'FEEDBACK_REQUEST',
  STANDUP_REMINDER: 'STANDUP_REMINDER',
};
export const TODO_TYPE = {
  ANNOUNCEMENT_PUBLISHED: 'ANNOUNCEMENT_PUBLISHED',
  FEEDBACK_REQUEST: 'FEEDBACK_REQUEST',
  LEAVE_REQUEST: 'LEAVE_REQUEST',
};

export const TODO_ICONS = {
  ANNOUNCEMENT_PUBLISHED: (
    <img src={SpeakerIcon} alt="icon" height="20px" width="20px" />
  ),
  SURVEY: <img src={PantoneIcon} alt="icon" height="20px" width="20px" />,
  POLL: <img src={OptionsTodoIcon} alt="icon" height="20px" width="20px" />,
  STANDUP_SUMMARY: (
    <img src={PersonDeleteOutline} alt="icon" height="20px" width="20px" />
  ),
};

export const TODO_STATUS_COLOR = {
  PENDING: '#FA8C16',
  SCHEDULED: '#FA8C16',
  IN_PROGRESS: '#5F3996',
  ARCHIVED: '#EF4747',
  DRAFT: '#EF4747',
  COMPLETED: '#1EBC67',
  ACTIVE: '#1EBC67',
  PAST_DUE: '#515983',
};
export const FEEDBACK_STATUS_COLOR = {
  SCHEDULED: '#FA8C16',
  DRAFT: '#EF4747',
  ACTIVE: '#1EBC67',
};

export const UPDATES_TYPE = {
  ANNOUNCEMENT_PUBLISHED: 'ANNOUNCEMENT_PUBLISHED',
  FEEDBACK_REQUEST: 'FEEDBACK_REQUEST',
  LEAVE_REQUEST: 'LEAVE_REQUEST',
  FEEDBACK_RESULT: 'FEEDBACK_RESULT',
};

export const LIKE_TYPE = {
  DISLIKE: 'DISLIKE',
  LIKE: 'LIKE',
};

export const TimeZone = [
  {
    zone: 'default timezone',
    value: 'DEFAULT',
  },
  {
    zone: 'user timezone',
    value: 'USER',
  },
];

export const EMPTY_STATE_IMAGE_URL = {
  ANNOUNCEMENT:
    'https://undiffer-local.ams3.digitaloceanspaces.com/email_templates/announcement.png',
  FEEDBACK:
    'https://undiffer-local.ams3.digitaloceanspaces.com/email_templates/feedback.png',
};

export const AMPLITUDE_EVENTS = {
  ANNOUNCEMENT_READ: 'Announcement read',
  ANNOUNCEMENT_DELETED: 'Announcement Deleted',
  ANNOUNCEMENT_CREATED: 'Announcement Created',
  ANNOUNCEMENT_UPDATED: 'Announcement Updated',
  FEEDBACK_CREATED: 'Feedback Created',
  FEEDBACK_UPDATED: 'Feedback Updated',
  FEEDBACK_DELETED: 'Feedback Deleted',
  FEEDBACK_ANSWERED: 'Feedback Answered',
  NEW_USER_SIGN_UP: 'New User SignUp',
  COMPANY_CREATED: 'Company Created',
  COMPANY_UPDATED: 'Company Updated',
  FEATURE_ACTIVATED: 'Feature Activated',
  FEATURE_DEACTIVATED: 'Feature Deactivated',
  GROUP_CREATED: 'Group Created',
  GROUP_UPDATED: 'Group Updated',
  GROUP_DELETED: 'Group Deleted',
  USER_ADDED: 'User Added',
  USER_UPDATED: 'User Updated',
  USER_DELETED: 'User Deleted',
  STANDUP_CHECKIN: 'StandUp CheckIn',
  QUOTES_SETTING_UPDATED: 'Quotes Setting Updated',
  QUOTES_SETTING_CREATED: 'Quotes Setting Created',
  USER_LOGOUT: 'User Logout',
  USER_LOGIN: 'User Login',
};

export const LINKS = {
  PRIVACY_POLICY: 'https://www.undiffer.com/privacy-policy',
  TERMS_OF_SERVICE: 'https://www.undiffer.com/terms-and-conditions',
};

export const REGEX = {
  NAME: /^[a-zA-Z ]*$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*\d)(?=.*[!@#$%^&*_])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  STANDUP_TASK: /^[0-9a-zA-Z#@:./_-]+[ {1,}#$@:./_-][0-9a-zA-Z#@:./_-]+([0-9a-zA-Z#@:./_-]+)*/,
};

export const integrationKeyList = {
  ANNOUNCEMENT_PUBLISHED: 'New Announcements',
  FEEDBACK_REQUEST: 'Feedbacks Published',
  FEEDBACK_RESULT: 'Feedback Result',
  DAILY_QUOTES: 'Quotes',
  STANDUP_SUMMARY: 'Standup Summary',
};
