import moment from 'moment';

export const leaveStatus = [
  { status: 'Paid' },
  { status: 'Unpaid' },
];
export const monthsData = [
  { month: 'January', value: '01' },
  { month: 'February', value: '02' },
  { month: 'March', value: '03' },
  { month: 'April', value: '04' },
  { month: 'May', value: '05' },
  { month: 'June', value: '06' },
  { month: 'July', value: '07' },
  { month: 'August ', value: '08' },
  { month: 'September', value: '09' },
  { month: 'October', value: '10' },
  { month: 'November', value: '11' },
  { month: 'December', value: '12' },

];
export const manageLeavesData = [
  {
    key: '0',
    leave: 'Planned',
    status: 'Paid',
    noOfLeave: 4,
  },
  {
    key: '1',
    leave: 'Sick',
    status: 'Paid',
    noOfLeave: 12,
  },
  {
    key: '2',
    leave: 'Casual',
    status: 'Unpaid',
    noOfLeave: 3,
  },
];


export const holidayData = [
  {
    key: '0',
    name: 'Christmas',
    date: moment(), // '12/12/2019',
  },
  {
    key: '1',
    name: 'Uttrayan',
    date: moment('01/01/2020', 'DD/MM/YYYY'), // '01/01/2020',
  },
];
