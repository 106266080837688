/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select as AntSelect, Tag } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import { ArrowIcon } from 'assets';
import { useQuery } from '@apollo/client';
import { EMPTY_ARRAY, FETCH_POLICY } from 'utils/constants';
import { GET_PLATFORMS } from 'graphql/Settings/Integrations/Queries';
import { Row } from 'molecules/Filters/AntStyled';
import Avatar from 'atoms/Avatar';
import { ActivityData } from 'pages/Activities/MockData';
import { Arrow } from 'layout/Header/AntStyled';

const Select = styled(AntSelect)`
  font-size: 14px;
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  .ant-select-selector {
    height: 32px;
  }
  .ant-select-selection-placeholder {
    font: ${fonts.xSmall.regular};
  }
  &.ant-select {
    background: white;
    min-width: 200px;
    max-width: 1000px;

    display: table-cell;
    word-wrap: break-word;
    word-break: break-word;
    & .ant-select-selector {
      padding-right: 20px;
      padding-left: 8px;
      border: none;
    }
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  &.ant-select-multiple .ant-select-selector {
    border: none;
    width: 100%;
    background-color: transparent;
  }
  &.ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 14px;
    left: 14px;
    font-size: 14px;
  }
  .ant-select-item-group {
    display: none !important;
  }
  .ant-select-item-option-content {
    margin: 8px 8px;
  }
  .ant-select-item {
    border-bottom: 1px solid #eaeff4;
    margin: 0px 8px;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }
`;

const { Option } = Select;
function PlatformFilter({ onPlatformChange }) {
  const [connectedPlatform, setConnectedPlatform] = useState(EMPTY_ARRAY);
  const {
    data: platformData,
    loading: platformLoading,
  } = useQuery(GET_PLATFORMS, { fetchPolicy: FETCH_POLICY.NETWORK_ONLY });
  useEffect(() => {
    setConnectedPlatform(
      _filter(platformData?.getPlatforms, (val) => val?.status === 'CONNECTED')
    );
  }, [platformData]);

  const children = _map(connectedPlatform, (items, index) => (
    <Option key={index} value={items?.platformKey}>
      <Row>
        <div>
          {items?.value !== 'all' && (
            <Avatar
              size={14}
              profile={ActivityData?.[items?.platformKey]?.title?.icon}
            ></Avatar>
          )}
          {ActivityData?.[items?.platformKey]?.title?.heading}
        </div>
      </Row>
    </Option>
  ));
  children.unshift(
    <Option key="all" value={null}>
      <Row>
        <div>All</div>
      </Row>
    </Option>
  );

  return (
    <Select
      className="z-index"
      placeholder="All"
      suffixIcon={<Arrow src={ArrowIcon} />}
      onChange={(e) => onPlatformChange(e)}
      disabled={connectedPlatform?.length < 1}
    >
      {children}
    </Select>
  );
}

export default PlatformFilter;
