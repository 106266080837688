import React from 'react';
import { Popconfirm } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { fonts } from 'design/Fonts';
import Colors from 'design/Colors';
import Variables from 'design/Variables';

const GlobalStyle = createGlobalStyle`
  body {
    .ant-popover-message-title{
      color:${Colors.Text}
    }
    .ant-pop-confirm-styled{
      max-width:400px;
    }
    .ant-popover-ok-button {
      font: ${fonts.small.regular};
      background: ${Colors.ButtonPrimary};
      border-color: ${Colors.ButtonPrimary};
      color: ${Colors.TextLight};
      font: ${fonts.xxSmall.medium};
      border-radius: ${Variables.AtomRadius};
      text-align: center;
      border: none;
      line-height: ${Variables.LineHeightMd};
      margin: 2px;
      padding : 0px 15px;
      &:hover {
        border-color: ${Colors.ButtonPrimaryHover};
        background: ${Colors.ButtonPrimaryHover};
        color: ${Colors.TextLight};
      }
      cursor: pointer;
      &:focus {
        border-color: ${Colors.ButtonPrimaryClicked};
        background: ${Colors.ButtonPrimaryClicked};
        color: ${Colors.TextLight};
      }
      .ant-btn-loading-icon .anticon {
        padding: 2px 0 0 0;
      }
      span.name {
        // padding-left: ${({ loading }) => (loading ? '8px' : 0)};
        transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        color: inherit;
        font: inherit;
      }
    }
    .display-none{
      display:none;
    }
    .ant-popover-cancel-button{
      font: ${fonts.small.regular};
      background: ${Colors.ButtonSecondary};
      border-color: ${Colors.ButtonSecondary};
      color: ${Colors.TextDark};
      font: ${fonts.xxSmall.medium};
      border-radius: 8px;
      text-align: center;
      border: none;
      line-height: ${Variables.LineHeightMd};
      margin: 2px;
      padding : 0px 15px;
      &:hover {
        border-color: ${Colors.ButtonSecondaryHover};
        background: ${Colors.ButtonSecondaryHover};
        color: ${Colors.TextDark};
      }
      cursor: pointer;
      :focus {
        background: ${Colors.ButtonSecondaryClicked};
        border-color: ${Colors.ButtonSecondaryClicked};
        color: ${Colors.TextLight};
      }
      .ant-btn-loading-icon .anticon {
        padding: 2px 0 0 0;
      }
      span.name {
        // padding-left: ${({ loading }) => (loading ? '8px' : 0)};
        transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        color: inherit;
        font: inherit;
      }
    }
  }
`;

const PopConfirm = ({
  icon,
  onConfirm,
  placement,
  title,
  Styled,
  disabled,
  okText,
  showCancel = true,
}) => (
  <Popconfirm
    placement={placement}
    title={title}
    onConfirm={() => onConfirm()}
    okText={okText || 'Yes'}
    cancelText="No"
    overlayClassName="ant-pop-confirm-styled"
    okType="danger"
    disabled={disabled}
    okButtonProps={{ className: 'ant-popover-ok-button' }}
    cancelButtonProps={{
      className: showCancel ? 'ant-popover-cancel-button' : 'display-none',
    }}
  >
    <GlobalStyle />
    {icon}
  </Popconfirm>
);

export default PopConfirm;
