const Colors = {
  AvatarBorder: '#B7EB8F',
  AttendanceStatus: '#EB2F96',
  BankIconBorder: '#6FBAFF',
  // Buttons
  ButtonDanger: '#ED5B09',
  ButtonDangerHover: '#d85e4d',
  ButtonDisable: '#dfdfdf',
  ButtonHighlight: '#8ac440',
  ButtonHighlightHover: '#79ad36',
  ButtonPrimary: '#5F3996',
  ButtonPrimaryHover: '#744CAF',
  ButtonPrimaryClicked: '#2C2255',
  ButtonSecondary: '#CAD6E3',
  ButtonSecondaryHover: '#DEE9F4',
  ButtonSecondaryClicked: '#515983',
  ButtonAccent: '#FEE895',
  ButtonAccentHover: '#F8DE7B',
  ButtonAccentClicked: '#F0D058',

  Border: '#CAD6E3',
  BorderHover: '#5F3996',
  BorderBottom: '#a9a9a94f',
  BorderLight: '#DEE9F4',
  BorderDisable: '#EEEEEE',

  CardShadow: '#d4d4d5',
  Container: '#fff',
  ContainerHighlight: '#e5ecfe',
  DefaultBackground: '#F2F4F7',
  Divider: '#BFBFBF',
  HeaderHighlight: '#f9f9f9',
  ProjectFilters: '#E6F7FF',
  FeatureActive: ' #DFF0FF',
  FeatureLight: '#1890FF',
  ContainerShadow: '#444',
  ContainerSidebar: '#fbfcff',
  DropZoneContainer: 'rgba(255, 255, 255, 0.8)',
  ButtonShadow: '0px 4px 10px rgba(81, 89, 131, 0.3);',
  DropZoneHighlight: '#8ac440',
  NotificationCreate: '#FFC53D',
  EmphasisContainer: '#f2f7f8',
  EmphasisLine: '#bdbdbd',
  ListEven: '#f1f4fe',
  ListOdd: '#fbfcff',
  Line: '#f0f0f0',
  LoadingStripeEven: '#f2f7f8',
  LoadingStripeOdd: '#8ac440',
  MarkupLabelFill: '#246efe',
  MarkupLabelText: '#FFF',
  MarkupOverlay: 'rgba(36, 110, 254, 0.3)',
  ModalShadow: 'rgba(0,0,0,0.75)',
  StatusActive: '#8ac440',
  StatusInactive: '#dfdfdf',
  StatusPending: '#ec9b36',
  LoginIcon: '#1890FF',
  HelpIcon: '#8c8c8c',
  IconWrapper: '#DFE4EA',
  Text: '#515983',
  LightDark: '#F5F5F5',
  Leave: '#3C3C84',
  TextEnable: '#515983',
  EmailVerified: '#C4C4C4',
  MemberCircle: '#DDDDDD',
  Menus: '#e6f7ff',
  OnBoarding: '#1890FF',
  TextDanger: '#EF4747',
  SuccessText: '#21B787',
  SidebarBorder: '#D9D9D9',
  SidebarBackground: '#2C2255',
  SidebarText: '#F2F4F7',
  SidebarTriggerBlock: '#101326',
  SidebarActiveItem: '#5F3996',
  InputBackground: '#f6f6f6',
  // text
  TextDark: '#101326',
  TextBlack: '#000000',
  TableTd: '#666666',
  TextDisable: '#c8c8c8',
  TextForm: '#595959',
  TextError: '#ef705f',
  TextHighlight: '#8ac440',
  TextHighlightHover: '#79ad36',
  TextLight: '#FFFFFF',
  TextLink: '#246efe',
  TextEmpty: '#b9bdcd',
  TextLinkHover: '#2a61cc',
  TextUrl: '#0068fff5',
  TextWarning: '#EF4747',
  TextMedium: '#999999',
  TextPrimary: '#101326',
  TextPrimaryHover: '#744CAF',
  TextSecondary: '#515983',
  TextSecondaryHover: '#909294',
  TextAccent: '#FEE895',
  TextAccentHover: '#F8DE7B',
  TextSuccess: '#39C479',
  TextTag: ' #065F46',
  TextWhite: ' #ffffff',
  ToggleOff: '#9a9a9a',
  ToggleOn: '#4e5f80',
  ToolTip: '#1b1c1d',
  GroupTagBackground: '#F5F5F5',
  Transparent: 'transparent',
  BlueBorder: '#31C2F0',
  LightBackground: '#eee',
  TemplateBackground: '#FFF',
  TemplateHeading: '#5F3996',
  PrimaryColor: '#5F3996',
  PrimaryDark: '#2C2255',
  SecondaryColor: '#CAD6E3',
  ternaryColor: '#FFF7CA',
  TagGreen: '#d1fae5',
  TagPurple: '#5F3996',
  TagYellow: '#FFEFB2',
  TextGreen: '#1ebc67',
  TextYellow: '#866A00',
  TagDark: '#3E4462',
  SuccessGreen: '#52C41A',
  OpacityGreen: '#F6FFED',
  OpacityYellow: '#FFFBE6',
  OpacityRed: '#FFF1F0',
  FullDayLeave: 'rgba(24, 144, 255, 0.2)',
  FirstHalfLeave:
    'linear-gradient(rgba(24, 144, 255, 0.2) 0%,rgba(24, 144, 255, 0.2) 50%,transparent 50%, transparent 100%)',
  SecondHalfLeave:
    'linear-gradient(transparent 0%, transparent 50%, rgba(24, 144, 255, 0.2) 50%,rgba(24, 144, 255, 0.2) 100%)',
  GraphGreen: '#4CDF90',
  GraphDarkGreen: '#27AF66',
  GraphRed: '#EF4747',
  GraphBlue: '#1890FF',
  GraphYellow: '#FFD166',
  GraphPurple: '#9276BB',
  LineThrough: '#515983',
  IconColor: '#F2F4F7',
  Radio: '#18214D',
  Note: '#7B6A0B',
  LikeBackground: '#39C47925',
  DislikeBackground: '#EF474725',
  /* Social */
  Facebook: '#3C5A99',
  LinkedIn: '#0077B5',
  Twitter: '#1DA1F2',
  OnBreak: '#D7A42F',
  WorkDone: '#27AF66',
  Working: '#515983',
  Progress: '#B03CE6',
};

export default Colors;
