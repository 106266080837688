import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import { Col, message, Row, Radio, TimePicker, Spin } from 'antd';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { FormattedMessage, injectIntl } from 'react-intl';

import client from 'apollo';
import Card from 'atoms/Card';
import Button from 'atoms/Button';
import { EMPTY_OBJECT, BUTTON_TYPE, FETCH_POLICY } from 'utils/constants';
import { GET_PUNCH_SETTING } from 'features/people/graphql/Settings/People/Queries';
import { UPDATE_PUNCH_SETTING } from 'features/people/graphql/Settings/People/Mutations';
import * as Sentry from '@sentry/react';
import Loader from 'atoms/Loader';
import { ArrowIcon, Clock } from 'assets';
import {
  allowFlexibility,
  requiredHours,
  minHours,
  minHalfHours,
} from './MockData';
import {
  Title,
  WorkTimeContent,
  Select,
  Form,
  Arrow,
  Image,
} from './AntStyled';
const { RangePicker } = TimePicker;
const { Option } = Select;
const TimeFormat = 'h:mm A';

const WorkTimeSchedule = ({ locationId, intl }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { data: punchSettingData, loading: punchSettingLoading } = useQuery(
    GET_PUNCH_SETTING,
    {
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
      variables: { where: { locationId: parseInt(locationId, 10) } },
    }
  );

  const [buttonActive, setButtonActive] = useState(true);
  const [punchSetting, setPunchSetting] = useState(EMPTY_OBJECT);
  const [showFlexibility, setShowFlexibility] = useState(true);
  const [flexibilityValue, setFlexibilityValue] = useState(1);

  const requiredHoursChildren = _map(requiredHours, (items, index) => (
    <Option key={index} value={items.hours}>
      {moment(items.text, 'h:mm').format('HH:mm')}
    </Option>
  ));

  const minHoursChildren = _map(minHours, (items, index) => (
    <Option key={index} value={items.hours}>
      {moment(items.text, 'h:mm').format('HH:mm')}
    </Option>
  ));

  const minHalfHoursChildren = _map(minHalfHours, (items, index) => (
    <Option key={index} value={items.hours}>
      {moment(items.text, 'h:mm').format('HH:mm')}
    </Option>
  ));

  const allowFlexibilityChildren = _map(allowFlexibility, (items, index) => (
    <Option key={index} value={items.hours}>
      {moment(items.text, 'h:mm').format('HH:mm')}
    </Option>
  ));

  useEffect(() => {
    if (punchSettingData && punchSettingData.getPunchSetting) {
      const {
        requiredDuration,
        minDuration,
        firstHalfRequiredDuration,
        firstHalfMinDuration,
        secondHalfRequiredDuration,
        secondHalfMinDuration,
        startTime,
        endTime,
        allowedTimeFlexibility,
        firstHalfStartTime,
        firstHalfEndTime,
        firstHalfAllowedTimeFlexibility,
        secondHalfStartTime,
        secondHalfEndTime,
        secondHalfAllowedTimeFlexibility,
      } = punchSettingData.getPunchSetting;

      form.setFieldsValue({
        requiredDuration,
        minDuration,
        firstHalfRequiredDuration,
        firstHalfMinDuration,
        secondHalfRequiredDuration,
        secondHalfMinDuration,
        fullDayTime: startTime &&
          endTime && [
            startTime && moment(startTime, moment.HTML5_FMT.TIME_MS),
            endTime && moment(endTime, moment.HTML5_FMT.TIME_MS),
          ],
        allowedTimeFlexibility,
        firstHalfTime: firstHalfStartTime &&
          firstHalfEndTime && [
            firstHalfStartTime &&
              moment(firstHalfStartTime, moment.HTML5_FMT.TIME_MS),
            firstHalfEndTime &&
              moment(firstHalfEndTime, moment.HTML5_FMT.TIME_MS),
          ],
        firstHalfAllowedTimeFlexibility,
        secondHalfTime: secondHalfStartTime &&
          secondHalfEndTime && [
            secondHalfStartTime &&
              moment(secondHalfStartTime, moment.HTML5_FMT.TIME_MS),
            secondHalfEndTime &&
              moment(secondHalfEndTime, moment.HTML5_FMT.TIME_MS),
          ],
        secondHalfAllowedTimeFlexibility,
      });
      setPunchSetting(punchSettingData.getPunchSetting);
      setShowFlexibility(!punchSettingData.getPunchSetting.fixedTiming);
      setFlexibilityValue(punchSettingData.getPunchSetting.fixedTiming ? 2 : 1);
    }
  }, [form, punchSetting.id, punchSettingData]);

  const onFinish = async (values) => {
    setLoading(true);
    const {
      requiredDuration,
      minDuration,
      firstHalfRequiredDuration,
      firstHalfMinDuration,
      secondHalfRequiredDuration,
      secondHalfMinDuration,
      fullDayTime,
      allowedTimeFlexibility,
      firstHalfTime,
      firstHalfAllowedTimeFlexibility,
      secondHalfTime,
      secondHalfAllowedTimeFlexibility,
    } = values;

    await client
      .mutate({
        mutation: UPDATE_PUNCH_SETTING,
        variables: {
          where: { id: parseInt(punchSetting.id, 10) },
          data: {
            fixedTiming: flexibilityValue !== 1,
            requiredDuration: requiredDuration && requiredDuration,
            minDuration: minDuration && minDuration,
            firstHalfRequiredDuration:
              firstHalfRequiredDuration && firstHalfRequiredDuration,
            firstHalfMinDuration: firstHalfMinDuration && firstHalfMinDuration,
            secondHalfRequiredDuration:
              secondHalfRequiredDuration && secondHalfRequiredDuration,
            secondHalfMinDuration:
              secondHalfMinDuration && secondHalfMinDuration,
            startTime:
              fullDayTime &&
              `${moment(fullDayTime[0]).format(moment.HTML5_FMT.TIME_MS)}Z`,
            endTime:
              fullDayTime &&
              `${moment(fullDayTime[1]).format(moment.HTML5_FMT.TIME_MS)}Z`,
            allowedTimeFlexibility:
              allowedTimeFlexibility && allowedTimeFlexibility,
            firstHalfStartTime:
              firstHalfTime &&
              `${moment(firstHalfTime[0]).format(moment.HTML5_FMT.TIME_MS)}Z`,
            firstHalfEndTime:
              firstHalfTime &&
              `${moment(firstHalfTime[1]).format(moment.HTML5_FMT.TIME_MS)}Z`,
            firstHalfAllowedTimeFlexibility:
              firstHalfAllowedTimeFlexibility &&
              firstHalfAllowedTimeFlexibility,
            secondHalfStartTime:
              secondHalfTime &&
              `${moment(secondHalfTime[0]).format(moment.HTML5_FMT.TIME_MS)}Z`,
            secondHalfEndTime:
              secondHalfTime &&
              `${moment(secondHalfTime[1]).format(moment.HTML5_FMT.TIME_MS)}Z`,
            secondHalfAllowedTimeFlexibility:
              secondHalfAllowedTimeFlexibility &&
              secondHalfAllowedTimeFlexibility,
          },
        },
        refetchQueries: [
          {
            query: GET_PUNCH_SETTING,
            variables: { where: { locationId: parseInt(locationId, 10) } },
            fetchPolicy: FETCH_POLICY.CACHE_FIRST,
          },
        ],
      })
      .then(() => {
        message.destroy();
        message.success(
          intl.formatMessage({
            id: 'settings.attendance.successMessages.update',
          })
        );
        setButtonActive(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Sentry.captureException(error);
        console.log(error);
      });
  };

  const onFormLayoutChange = () => {
    setButtonActive(false);
  };

  return (
    <WorkTimeContent gutter={[0, 0]}>
      <Spin spinning={punchSettingLoading}>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={onFormLayoutChange}
            initialValues={{ timeFlexibility: showFlexibility }}
          >
            <Form.Item
              label={
                <div className="content-heading">
                  <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.flexibility.label" />
                </div>
              }
            >
              <Radio.Group
                onChange={(e) => {
                  setFlexibilityValue(e.target.value);
                  setShowFlexibility(e.target.value === 1);
                  setButtonActive(false);
                }}
                value={flexibilityValue}
              >
                <Radio value={1}>
                  <FormattedMessage id="settings.attendance.workTimeSchedule.yes" />
                </Radio>
                <Radio value={2}>
                  <FormattedMessage id="settings.attendance.workTimeSchedule.no" />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <div className="step-inside">
              <Title>
                {!showFlexibility ? (
                  <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.specify.day" />
                ) : (
                  <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.specify.fullDay" />
                )}
              </Title>
              {!showFlexibility ? (
                <Row>
                  <Col span={16}>
                    <Form.Item
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.flexibility.startEnd" />
                      }
                      rules={[
                        {
                          type: 'array',
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.flexibility.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <RangePicker
                        defaultValue={[
                          moment('10:00 AM', TimeFormat),
                          moment('07:00 PM', TimeFormat),
                        ]}
                        format={TimeFormat}
                        suffixIcon={<Image src={Clock} />}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.allowFlexibility.label" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.allowFlexibility.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<Arrow src={ArrowIcon} />}
                        placeholder="30 Minutes"
                        className="drop-down"
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      >
                        {allowFlexibilityChildren}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row gutter={8} className="space">
                  <Col span={12}>
                    <Form.Item
                      name="requiredDuration"
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.requiredHours.label" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.requiredHours.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<Arrow src={ArrowIcon} />}
                        placeholder="Required Hours"
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      >
                        {requiredHoursChildren}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="minDuration"
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.minHours.label" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.minHours.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<Arrow src={ArrowIcon} />}
                        placeholder="Min Hours"
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      >
                        {minHoursChildren}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Title>
                {!showFlexibility ? (
                  <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.specify.firstHalf" />
                ) : (
                  <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.specify.firstHalf1" />
                )}
              </Title>
              {!showFlexibility ? (
                <Row>
                  <Col span={16}>
                    <Form.Item
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.flexibility.startEnd" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.flexibility.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <RangePicker
                        defaultValue={[
                          moment('10:00 AM', TimeFormat),
                          moment('02:00 PM', TimeFormat),
                        ]}
                        format={TimeFormat}
                        suffixIcon={<Image src={Clock} />}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.allowFlexibility.label" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.allowFlexibility.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<Arrow src={ArrowIcon} />}
                        placeholder="30 Minutes"
                        className="drop-down"
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      >
                        {allowFlexibilityChildren}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row gutter={8} className="space">
                  <Col span={12}>
                    <Form.Item
                      name="firstHalfRequiredDuration"
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.requiredHours.label" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.requiredHours.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<Arrow src={ArrowIcon} />}
                        placeholder="Required Hours"
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      >
                        {minHalfHoursChildren}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="firstHalfMinDuration"
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.minHours.label" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.minHours.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<Arrow src={ArrowIcon} />}
                        placeholder="Min Hours"
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      >
                        {minHalfHoursChildren}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Title>
                {!showFlexibility ? (
                  <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.specify.secondHalf" />
                ) : (
                  <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.specify.secondHalf1" />
                )}
              </Title>
              {!showFlexibility ? (
                <Row>
                  <Col span={16}>
                    <Form.Item
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.flexibility.startEnd" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.flexibility.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <RangePicker
                        defaultValue={[
                          moment('03:00 AM', TimeFormat),
                          moment('07:00 PM', TimeFormat),
                        ]}
                        format={TimeFormat}
                        suffixIcon={<Image src={Clock} />}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.allowFlexibility.label" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.allowFlexibility.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<Arrow src={ArrowIcon} />}
                        placeholder="30 Minutes"
                        className="drop-down"
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      >
                        {allowFlexibilityChildren}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row gutter={8} className="space">
                  <Col span={12}>
                    <Form.Item
                      name="secondHalfRequiredDuration"
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.requiredHours.label" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.requiredHours.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<Arrow src={ArrowIcon} />}
                        placeholder="Required Hours"
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      >
                        {minHalfHoursChildren}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="secondHalfMinDuration"
                      label={
                        <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.minHours.label" />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="settings.attendance.workTimeSchedule.formRules.minHours.errorMessage" />
                          ),
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<Arrow src={ArrowIcon} />}
                        placeholder="Min Hours"
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      >
                        {minHalfHoursChildren}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>

            <Form.Item className="mb-0">
              <Col span={24} className="text-right" align="middle">
                <Button
                  primary
                  loading={loading}
                  disabled={buttonActive}
                  type={BUTTON_TYPE.PRIMARY}
                  htmlType={BUTTON_TYPE.SUBMIT}
                  name={
                    <FormattedMessage id="settings.attendance.workTimeSchedule.saveTimeSettings" />
                  }
                />
              </Col>
            </Form.Item>
          </Form>
        </Col>
      </Spin>
    </WorkTimeContent>
  );
};

export default injectIntl(WorkTimeSchedule);
