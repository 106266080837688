import React from 'react';
import { Router, Switch, Route, withRouter } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'utils/constants';
import Page404 from 'pages/404';
import { StandUpIcon } from 'assets';
import StandUpPage from './StandUpPage';
import TeamCheckin from './Managers/TeamCheckin';

const StandUpManager = ({ history }) => (
  <Router history={history}>
    <Switch>
      <Route
        path={PRIVATE_ROUTE.MY_CHECK_IN}
        component={StandUpPage.component}
      />
      <Route
        path={PRIVATE_ROUTE.TEAM_CHECK_IN}
        component={TeamCheckin.component}
      />
      <Route component={Page404} />
    </Switch>
  </Router>
);

export default {
  key: PRIVATE_ROUTE.STAND_UPS,
  component: withRouter(StandUpManager),
  name: 'Stand-ups',
  icon: <img src={StandUpIcon} alt="standup-icon" height={18} width={18} />,
  type: 'admin',
  expandIcon: (
    <i
      fill="white"
      data-eva="arrow-down"
      data-eva-height="16"
      data-eva-width="16"
    ></i>
  ),
  subPages: [StandUpPage, TeamCheckin],
  abilitySubject: 'standups_feature',
  abilityAction: 'use',
};
