import Sop from './sop/pages';
import People from './people/pages';
import Feedback from './feedbacks/pages';
import DailyGoals from './dailyGoals/pages';
import Announcement from './announcement/pages';
import StandUp from './standUp/pages';
import StandUpManager from './standUp/pages/StandUpManager';
import SopSettings from './sop/pages/Settings';
import PeopleSettings from './people/pages/Settings';
import DailyGoalsSettings from './dailyGoals/pages/Settings';
import AnnouncementSettings from './announcement/pages/Settings';
import StandUpSettings from './standUp/pages/Settings';
import QuoteSettings from './quotes/pages/Settings';
import FeedbackSettings from './feedbacks/pages/Settings';
import { workUpdateWidgets as sopWorkUpdateWidgets } from './sop';
import { LeavesTodoWidgets as LeavesTodo } from './people';
import {
  notificationWidgets as announcementNotificationWidgets,
  announcementWorkUpdateWidgets as announcementWidgets,
} from './announcement';
import {
  workUpdateWidgets as feedbackWorkUpdateWidgets,
  notificationWidgets as feedbackNotificationWidgets,
} from './feedbacks';
import { notificationWidgets as blockerNotificationWidgets } from './dailyGoals';
import {
  notificationWidgets as standupNotificationWidgets,
  workUpdateWidgets as standupSummaryWorkUpdateWidgets,
} from './standUp';

export const mainPages = [
  // Sop,
  // People,
  // DailyGoals,
  Announcement,
  Feedback,
  StandUp,
  StandUpManager,
];
// commented for future use
export const settingsPages = [
  // DailyGoalsSettings,
  // SopSettings,
  // PeopleSettings,
  AnnouncementSettings,
  QuoteSettings,
  FeedbackSettings,
  StandUpSettings,
];

// commented for future use
export const workUpdateWidgets = {
  // ...sopWorkUpdateWidgets,
  ...feedbackWorkUpdateWidgets,
  ...announcementWidgets,
  ...standupSummaryWorkUpdateWidgets,
};

export const notificationWidgets = {
  // ...blockerNotificationWidgets,
  ...feedbackNotificationWidgets,
  ...announcementNotificationWidgets,
  ...standupNotificationWidgets,
};
