import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Spin, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ArrowIcon, BackArrow } from 'assets';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import InfoIcon from 'atoms/InfoIcon';
import Input from 'atoms/Input';
import {
  FORM_NAME,
  PRIVATE_ROUTE,
  EMPTY_OBJECT,
  FETCH_POLICY,
} from 'utils/constants';
import { useMutation, useQuery } from '@apollo/client';
import { GET_GROUPS } from 'graphql/Groups/Queries';
import { cloneDeep, get, has, map } from 'lodash';
import * as Sentry from '@sentry/react';
import { useForm } from 'antd/lib/form/util';
import { useParams } from 'react-router';
import client from 'apollo';
import GroupTag from 'atoms/GroupTag';
import TextEditor, { initialValue } from 'organisms/TextEditor';
import Header from 'layout/Header';
import { GET_ALL_SOPS, GET_SOP_BY_ID } from '../../graphql/Queries';
import { CREATE_SOP, PUBLISH_SOP, UPDATE_SOP } from '../../graphql/Mutations';
import {
  Content,
  ContentTitle,
  Form,
  Arrow,
  TextBox,
  Select,
} from './AntStyled';

const { Option } = Select;

export default function NewSop(props) {
  const { id } = useParams();
  const {
    history,
    location: { state },
  } = props;
  const [createSop] = useMutation(CREATE_SOP);
  const [updateSop] = useMutation(UPDATE_SOP);
  const [publishSop] = useMutation(PUBLISH_SOP);
  const [groups, setGroups] = useState([]);
  const { data: userGroups } = useQuery(GET_GROUPS, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const [body, setBody] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = useForm();
  const [initialValues, setInitialValues] = useState(EMPTY_OBJECT);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1050);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1050);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  const FilterOption = ({ item, closable = false, onClose }) => (
    <GroupTag
      closeable={closable}
      closeOnClick={onClose}
      color={item.color}
      name={item.name}
      type={item.type}
    />
  );
  const onFinish = (formData) => {
    const values = formData;
    const bodyData = cloneDeep(body);
    const mutation = id ? updateSop : createSop;
    const where = id ? { where: { id } } : {};
    const { status } = values;
    if (!id) {
      delete values.status;
    }
    mutation({
      variables: {
        data: { ...values, body: { children: bodyData } },
        ...where,
      },
      refetchQueries: [
        {
          query: GET_ALL_SOPS,
        },
      ],
    })
      .then((res) => {
        let sopId = id;
        if (!sopId) {
          sopId = res?.data?.createSOP?.id;
        }
        history.push(PRIVATE_ROUTE.SOP_ACTIVE);
        message.success(`${id ? 'Updated' : 'Created'} successfully`);
        if (status === 'PUBLISHED') {
          publishSop({ variables: { where: { id: sopId } } }).catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          });
        }
        setBtnLoading(false);
      })
      .catch((e) => {
        setBtnLoading(false);
        Sentry.captureException(e);
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const Title = () => (
    <>
      <ContentTitle onClick={() => history.goBack()}>
        <Arrow src={BackArrow} alt="arrow" />
        <InfoIcon
          title={
            id ? initialValues?.title : <FormattedMessage id="sops.create" />
          }
        />
      </ContentTitle>
    </>
  );

  useEffect(() => {
    if (state?.templateData) {
      const initialValuesObject = {
        ...state?.templateData,
        departments: state?.templateData?.departments?.map((dept) =>
          parseInt(dept?.id, 10)
        ),
      };
      setInitialValues(initialValuesObject);
      setBody(state?.templateData?.body?.children);
    } else if (id) {
      setLoading(true);
      client
        .query({
          query: GET_SOP_BY_ID,
          variables: {
            where: { id },
          },
        })
        .then(({ data }) => {
          const { getSop: sopData } = data;
          const initialValuesObject = {
            ...sopData,
            departments: sopData?.departments?.map((dept) =>
              parseInt(dept?.id, 10)
            ),
          };
          setInitialValues(initialValuesObject);
          setBody(sopData?.body?.children);
          setLoading(false);
        })
        .catch(() => {
          message.error('Something went wrong');
          history.push(PRIVATE_ROUTE.SOP_ACTIVE);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, id]);

  useEffect(() => {
    if (has(userGroups, 'groups.groups')) {
      // eslint-disable-next-line no-shadow
      const { groups } = userGroups;
      const groupWithUsersChildren = map(groups?.groups, (items, index) => (
        <Option key={index} value={parseInt(items?.id, 10)}>
          <Row justify="space-between">
            <FilterOption item={items} />
          </Row>
        </Option>
      ));
      setGroups(groupWithUsersChildren);
    }
  }, [userGroups]);

  const groupTagRender = (prop) => {
    const { label: tagLabel, onClose } = prop;
    return typeof tagLabel === 'object' ? (
      <FilterOption
        item={tagLabel?.props?.children?.props?.item}
        closable
        onClose={onClose}
      />
    ) : (
      <span className="extra-tag">
        +{form.getFieldValue('departments').length - 3}More
      </span>
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), [initialValues]);

  return (
    <Layout>
      <Header title={<Title />} />
      <Spin spinning={loading}>
        <Content>
          <Card padding="16" className="card">
            <Form
              form={form}
              layout={FORM_NAME.VERTICAL}
              onFinish={onFinish}
              initialValues={initialValues}
              onFinishFailed={() => setBtnLoading(false)}
            >
              <Form.Item
                name="title"
                label={<FormattedMessage id="feedback.new.titleLabel" />}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <Input placeholder="Title of SOP" />
              </Form.Item>
              <Form.Item
                name="departments"
                label="Departments"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="projects.formRules.group.errorMessage" />
                    ),
                  },
                ]}
              >
                <Select
                  filterOption={(input, option) => {
                    const groupFilter = get(
                      option,
                      'children.props.children.props.item',
                      ''
                    );
                    return groupFilter?.name
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  mode="multiple"
                  placeholder="Department"
                  tagRender={groupTagRender}
                  maxTagCount={isDesktop ? 2 : 1}
                  allowClear
                  showArrow
                  suffixIcon={<Arrow src={ArrowIcon} />}
                  getPopupContainer={(trigger) => trigger?.parentNode}
                >
                  {groups}
                </Select>
              </Form.Item>
              <Form.Item
                name="description"
                label={<FormattedMessage id="feedback.new.descriptionLabel" />}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <TextBox rows={4} />
              </Form.Item>
              <TextEditor value={body} setValue={setBody} />
              <Row justify="end" align="middle">
                {!id && (
                  <Col>
                    <Form.Item name="isTemplate">
                      <Button
                        loading={btnLoading && btnLoading === 'TEMPLATE'}
                        htmlType="submit"
                        className="template-button"
                        onClick={() => {
                          setBtnLoading('TEMPLATE');
                          form.setFieldsValue({
                            status: 'DRAFT',
                            isTemplate: true,
                          });
                        }}
                        name="Save as Template"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col>
                  <Row justify="center" align="middle">
                    <Col>
                      <Form.Item className="btn" name="status">
                        <Button
                          loading={btnLoading && btnLoading === 'DRAFT'}
                          htmlType="submit"
                          name={id ? 'Move to Draft' : 'Save as Draft'}
                          secondary
                          onClick={() => {
                            setBtnLoading('DRAFT');
                            form.setFieldsValue({
                              status: 'DRAFT',
                              isTemplate: false,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button
                          loading={btnLoading && btnLoading === 'PUBLISHED'}
                          htmlType="submit"
                          name={id ? 'Update and publish' : 'Publish'}
                          onClick={() => {
                            setBtnLoading('PUBLISHED');
                            form.setFieldsValue({
                              status: 'PUBLISHED',
                              isTemplate: false,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
      </Spin>
    </Layout>
  );
}
