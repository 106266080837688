import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    originalEmail
    hasRequested
    role
    profileImage
    title
    type
    contact
    username
    userGroups {
      group {
        id
        name
        color
        type
      }
      role
    }
    isActive
    isDisabled
    location {
      id
      name
      shortName
      timeZone
      isActive
      isDefault
    }
    createdAt
    updatedAt
    userLocation
    department {
      id
      name
    }
  }
`;

export const attachmentFragment = gql`
  fragment AttachmentFragment on Attachment {
    id
    type
    key
    url
    attachableId
    attachableType
    createdBy {
      ...UserFragment
    }
    createdAt
    updatedAt
  }
  ${userFragment}
`;

export const userDocumentsFragment = gql`
  fragment UserDocumentsFragment on UserDocuments {
    id
    title
    number
    other
    attachment {
      ...AttachmentFragment
    }
    user {
      ...UserFragment
    }
    createdBy {
      ...UserFragment
    }
    updatedBy {
      ...UserFragment
    }
  }
  ${attachmentFragment}
  ${userFragment}
`;

export const groupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    color
    type
  }
`;

export const groupUserFragment = gql`
  fragment GroupUserFragment on GroupUser {
    id
    user {
      ...UserFragment
    }
    group {
      ...GroupFragment
    }
    role
    createdAt
    updatedAt
  }
  ${userFragment}
  ${groupFragment}
`;
