/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _concat from 'lodash/concat';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import { useLazyQuery } from '@apollo/client';
import GroupTag from 'atoms/GroupTag';
import styled from 'styled-components';
import { fonts } from 'design/Fonts';

import { Select as AntSelect, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Input from 'atoms/Input';
import Colors from 'design/Colors';
import { GET_GROUPS } from 'graphql/Groups/Queries';
import {
  FETCH_POLICY,
  EMPTY_ARRAY,
  GROUP_TYPE,
  TASK_BLOCKER_TYPE,
  GROUP_TYPE_FIELD,
} from 'utils/constants';
import UserProfile from 'molecules/UserProfile';
import Spacings from 'design/Spacings';
import { ArrowIcon } from 'assets';
import TriStateSwitch from 'atoms/TriStateSwitch';
import SearchAnything from 'atoms/SearchAnything';
import PlatformFilter from 'atoms/PlatformFilter';
import { GET_USERS } from 'graphql/Settings/Users/Queries';

const { Option } = AntSelect;

const Select = styled(AntSelect)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  .ant-select-selection-placeholder {
    opacity: 1;
  }
  &.ant-select {
    background: white;
    min-width: 170px;
    max-width: 1000px;
    display: table-cell;
    word-wrap: break-word;
    word-break: break-word;
    & .ant-select-selector {
      padding-right: 20px;
      padding-left: 8px;
      border: none;
    }
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  &.ant-select-multiple .ant-select-selector {
    border: none;
    width: 100%;
    background-color: transparent;
  }
  &.ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 14px;
    left: 14px;
  }
  //! due to inline styling using important
  .ant-select-selection-search {
    width: unset !important;
  }
  &.ant-select-multiple
    .ant-select-selection-search:first-child
    .ant-select-selection-search-input {
    margin-left: 4px;
    @media (max-width: 850px) {
      margin-left: 0px;
    }
  }
`;

const ProfileWrapper = styled.div`
  display: flex;
  & .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }
`;
const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 3px;
  margin-top: -4px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    margin-right: ${Spacings.xxSmall.margin};
  }

  .ant-input {
    line-height: 0px;
  }
  .ant-input-placeholder {
    opacity: 1;
  }
  & .group-select {
  }
  & .user-select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .ant-select-selector {
      padding-right: 20px;
    }
    .anticon {
      color: ${Colors.TextSecondary};
    }
  }
  & .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }
  & .extra-tag {
    margin-left: 5px;
    margin-right: 20px;
  }
`;

export default function (props) {
  const {
    groupsFilters,
    className,
    multipleMode = true,
    groupValue,
    setUsersValue,
    showUsersFilter,
    usersValue,
    showGroupUserFilter,
    showTaskFilter,
    taskFilters,
    taskType,
    showGroupTypeFilter,
    groupTypeFilters,
    groupType,
    setSelectedGoalsType,
    selectedGoalsType,
    setSelectedGroupsType,
    selectedGroupsType,
    handleTaskTypeChange,
    handleGroupOrUserFilterChange,
    doSearch,
    onSearch,
    platFormFilter,
    onPlatformChange,
  } = props;
  const [groupRef, setGroupRef] = useState();
  const [userRef, setUserRef] = useState();
  const [taskRef, setTaskRef] = useState();
  const [groupTypeRef, setGroupTypeRef] = useState();
  const [groups, setGroups] = useState(groupValue || EMPTY_ARRAY);
  const [users, setUsers] = useState(usersValue || EMPTY_ARRAY);
  const [taskTypes, setTaskTypes] = useState(['Completed', 'Pending']);
  const [groupTypes, setGroupTypes] = useState(['Project', 'Department']);
  const [filteredUsersOptions, setFilteredUsersOptions] = useState([]);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1050);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1050);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  let usersData = [];
  const [getGroups, { data: groupsData }] = useLazyQuery(GET_GROUPS, {
    fetchPolicy: FETCH_POLICY?.NETWORK_ONLY,
  });

  const [getUsers, { data }] = useLazyQuery(GET_USERS, {
    fetchPolicy: FETCH_POLICY?.NETWORK_ONLY,
    onCompleted(res) {
      setFilteredUsersOptions(createUsersOptions(res?.users?.users));
    },
  });

  useEffect(() => {
    if (showUsersFilter || showGroupTypeFilter || showGroupUserFilter) {
      getGroups();
      getUsers();
    }
  }, []);

  const FilterOption = ({ item, closable = false, onClose }) => (
    <GroupTag
      closeable={closable}
      closeOnClick={onClose}
      color={item?.color}
      name={item?.name}
      type={item?.type}
    />
  );

  useEffect(() => {
    if (groups?.length > 0) {
      let temp = [];
      _map(
        _filter(groupsData?.groups?.groups, (group) =>
          groups?.includes(parseInt(group?.id, 10))
        ),
        (item) => {
          temp = _concat(temp, item?.managers, item?.members);
        }
      );
    }
  }, [groups]);

  const filteredChildren = _map(
    _sortBy(
      groupsData?.groups?.groups,
      (group) => group?.type === GROUP_TYPE.DEPARTMENT
    ),
    (item, index) => {
      usersData = _sortBy(
        _concat(usersData, item?.managers, item?.members),
        'user.firstName'
      );
      return (
        <Option key={index} value={parseInt(item?.id, 10)}>
          <Row justify="space-between">
            <FilterOption item={item} />
          </Row>
        </Option>
      );
    }
  );

  const createUsersOptions = (userArr) =>
    _map(_uniqBy(userArr, 'id'), (user, index) => (
      <Option key={index} value={parseInt(user?.id, 10)}>
        <ProfileWrapper>
          <UserProfile
            dropDown
            stackedName={false}
            size="small"
            medium
            profile={user?.profileImage}
            name={
              user?.firstName
                ? `${user?.firstName} ${user?.lastName}`
                : user?.email
            }
          />
        </ProfileWrapper>
      </Option>
    ));

  const taskTypeOptions = _map(taskTypes, (type, index) => (
    <Option key={index} value={type === 'Completed'}>
      {type}
    </Option>
  ));

  const groupTypeOptions = _map(groupTypes, (type, index) => (
    <Option key={index} value={type === 'Project' ? 'PROJECT' : 'DEPARTMENT'}>
      {type}
    </Option>
  ));

  const groupTagRender = (prop) => {
    const { label: tagLabel, onClose } = prop;
    return typeof tagLabel === 'object' ? (
      <FilterOption
        item={tagLabel?.props?.children?.props?.item}
        closable
        onClose={onClose}
      />
    ) : (
      <span className="extra-tag">
        {groups?.length - 2 > 0 && `+${groups?.length - 2}More`}
      </span>
    );
  };

  const handleGroupChange = (value) => {
    setGroups(value);
    groupRef.blur();
    groupsFilters(value);
    showUsersFilter && handleUserChange([]);
    handleTaskTypeChange && handleTaskTypeChange();
    handleGroupOrUserFilterChange && handleGroupOrUserFilterChange();
  };

  const handleUserChange = (value) => {
    setUsers(value);
    userRef.blur();
    setUsersValue(value);
    handleGroupOrUserFilterChange && handleGroupOrUserFilterChange();
  };
  const tagRender = (prop) => {
    const { label: tagLabel, closable, onClose } = prop;
    return typeof tagLabel === 'string' ? (
      <span className="extra-tag">
        {users?.length - 1 > 0 && `+${users?.length - 1}`}
      </span>
    ) : (
      <UserProfile
        profile={_get(tagLabel, 'props.children.props.profile')}
        name={_get(tagLabel, 'props.children.props.name')}
        closable={closable}
        onIconClick={onClose}
        bordered
        Background
        stackedName={false}
        icon
      />
    );
  };

  const usersOptions = createUsersOptions(data?.users?.users);

  const handleGroupTypeChange = (value) => {
    groupTypeFilters(value);
    groupTypeRef.blur();
  };

  return (
    <Wrapper>
      {showGroupTypeFilter && (
        <Col className="group-select">
          <Select
            ref={(ref) => setGroupRef(ref)}
            filterOption={(input, option) => {
              const groupFilter = _get(
                option,
                'children.props.children.props.item',
                ''
              );
              return groupFilter?.name
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            mode={multipleMode && 'multiple'}
            placeholder="Select Group"
            onChange={handleGroupChange}
            tagRender={groupTagRender}
            className={className}
            maxTagCount={isDesktop ? 2 : 1}
            allowClear
            value={groups}
            menuItemSelectedIcon={null}
            showArrow
            showSearch={false}
            suffixIcon={<Arrow src={ArrowIcon} />}
            getPopupContainer={(trigger) => trigger?.parentNode}
          >
            {filteredChildren}
          </Select>
        </Col>
      )}
      {showUsersFilter && (
        <div className="user-select">
          <Select
            ref={(ref) => setUserRef(ref)}
            mode="multiple"
            placeholder="Select User"
            onChange={handleUserChange}
            dropdownStyle={{ minWidth: '247px', height: '216px' }}
            filterOption={(input, option) =>
              _get(option, 'children.props.children.props.name', '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            allowClear
            value={users}
            maxTagCount={1}
            stackedName={false}
            tagRender={tagRender}
            showArrow
            showSearch={false}
            suffixIcon={<Arrow src={ArrowIcon} />}
            getPopupContainer={(trigger) => trigger?.parentNode}
          >
            {groups?.length > 0 ? filteredUsersOptions : usersOptions}
          </Select>
        </div>
      )}
      {doSearch && (
        <>
          <SearchAnything onSearch={onSearch} />
          {/* old search */}
          {/* <Input
            type="search"
            onSearch={onSearch}
            placeholder="Search anything..."
            allowClear
            onChange={(e) => {
              handleGroupOrUserFilterChange && handleGroupOrUserFilterChange();
              onSearch(e.target.value);
            }}
            suffix={<SearchOutlined />}
          /> */}
        </>
      )}
      {platFormFilter && <PlatformFilter onPlatformChange={onPlatformChange} />}
      {showGroupUserFilter && (
        <Select
          ref={(ref) => setUserRef(ref)}
          mode="multiple"
          placeholder="Select User"
          onChange={handleUserChange}
          dropdownStyle={{ minWidth: '247px', height: '216px' }}
          filterOption={(input, option) =>
            _get(option, 'children.props.children.props.name', '')
              .toLowerCase()
              .includes(input?.toLowerCase())
          }
          allowClear
          value={users}
          maxTagCount={1}
          stackedName={false}
          tagRender={tagRender}
          showArrow
          suffixIcon={<Arrow src={ArrowIcon} />}
          getPopupContainer={(trigger) => trigger?.parentNode}
        >
          {groups?.length > 0 ? filteredUsersOptions : usersOptions}
        </Select>
      )}
    </Wrapper>
  );
}
