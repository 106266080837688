import { gql } from '@apollo/client';
import { companyFragment } from './Fragments';

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $name: String
    $logo: Upload
    $timeZone: String
    $workSpace: String
    $where: CompanyWhereUniqueInput!
    $isTourCompleted: Boolean
  ) {
    updateCompany(
      data: {
        name: $name
        timeZone: $timeZone
        logo: $logo
        workSpace: $workSpace
        isTourCompleted: $isTourCompleted
      }
      where: $where
    ) {
      id
      name
      logo
      completedSteps
      nextStep
      featureKeys
      locations {
        name
      }
      createdBy {
        firstName
        email
        username
      }
      workSpace
      companySubscriptionId
      createdAt
      updatedAt
      updatedBy {
        email
      }
    }
  }
`;
