/* eslint-disable no-console */
import React, { useContext, useState, useEffect, lazy, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Drawer, notification } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { GET_NOTIFICATIONS } from 'graphql/Notifications/Queries';
import { NOTIFICATION_SUBSCRIPTION } from 'graphql/CurrentUser/subscriptions';
import {
  ONBOARDING,
  ACTIONS_TYPE,
  BUTTON_TYPE,
  PUBLIC_ROUTE,
  PRIVATE_ROUTE,
  USER_ROLE,
  FETCH_POLICY,
} from 'utils/constants';
import Onboarding from 'onboarding';
import Sidebar from 'layout/Sidebar';
import { AppContext } from 'AppContext';
import client, { subscriptionClient } from 'apollo';
import Loader from 'atoms/Loader';
import Button from 'atoms/Button';
import SetPassword from 'pages/Auth/SetPassword';
import * as Sentry from '@sentry/react';
import { UPDATE_COMPANY } from 'graphql/Settings/GeneralSetting/Mutations';
import { GET_CURRENT_USER } from 'graphql/CurrentUser/queries';
import isEmpty from 'lodash/isEmpty';
import Selection from 'onboarding/Screens/Selection';
import Results from 'onboarding/Screens/Selection/Results';
import { mainPages as featurePages } from 'features';
import ability from 'utils/ability/ability';
import { findIndex } from 'lodash';
import { createGlobalStyle } from 'styled-components';
import Page404 from './404';
import AccessDenied from './AccessDenied';

const Logout = lazy(() => import('pages/Auth/Logout'));
const Todos = lazy(() => import('pages/Todos'));
const Insights = lazy(() => import('pages/Insights'));
const Activities = lazy(() => import('pages/Activities'));
const Teams = lazy(() => import('pages/Teams'));
const Updates = lazy(() => import('pages/Updates'));
const Settings = lazy(() => import('pages/Settings'));
const Profile = lazy(() => import('pages/Settings/Profile'));
const Dashboard = lazy(() => import('pages/Dashboard'));
export const GlobalStyles = createGlobalStyle`
    .ant-drawer-wrapper-body{
      flex-direction: unset ; 
  }`;
function App({ history, loading }) {
  const appContext = useContext(AppContext);
  const {
    state: { loginUser, sidebar },
    dispatch,
  } = appContext;
  const { email, company, id: loginUserId, role } = loginUser;
  const [visible, setVisible] = useState(false);
  const publicRoutes = Object.values(PUBLIC_ROUTE);
  const onboardingRoutes = Object.values(ONBOARDING);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [run, setRun] = useState(false);
  const [currStep, setCurrStep] = useState(0);
  const [btnText, setBtnText] = useState('Continue');
  const { pathname } = history.location;

  useEffect(() => {
    if (company && !company.isTourCompleted && role === USER_ROLE.OWNER) {
      setRun(!company.isTourCompleted);
    }
  }, [company, role]);

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV === 'dev' &&
      !isEmpty(loginUser) &&
      window &&
      window.pendo
    ) {
      window.pendo.initialize({
        visitor: {
          id: loginUser.id,
          full_name: `${loginUser.firstName} ${loginUser.lastName}`,
          role: loginUser.role,
          email: loginUser.email,
        },
      });
    }
  }, [loginUser]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else {
      Notification.requestPermission();
    }
  });

  useEffect(() => {
    const { pathname } = history.location;
    if (
      company &&
      (company.completedSteps === ONBOARDING.COMPANY_STEP ||
        company.completedSteps === ONBOARDING.COMPLETED) &&
      (company.nextStep === ONBOARDING.FEATURE ||
        company.nextStep === ONBOARDING.DASHBOARD) &&
      pathname &&
      (pathname === PUBLIC_ROUTE.KEY.PATH ||
        publicRoutes.includes(pathname) ||
        onboardingRoutes.includes(pathname)) &&
      pathname !== PUBLIC_ROUTE.SET_PASSWORD &&
      pathname !== ONBOARDING.WORKSPACE_RESULT &&
      pathname !== ONBOARDING.SELECTION
    ) {
      history.push(PRIVATE_ROUTE.DASHBOARD);
    }
  }, [company, history, publicRoutes, onboardingRoutes]);

  useEffect(() => {
    client
      .query({
        query: GET_NOTIFICATIONS,
        variables: { where: { isRead: false } },
      })
      .then((response) => {
        if (response.data) {
          const {
            notifications: { notifications },
          } = response.data;
          dispatch({
            type: ACTIONS_TYPE.INCREASE_NOTIFICATION_COUNT,
            data: { count: (notifications && notifications.length) || 0 },
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [dispatch]);

  useEffect(() => {
    if (email) {
      const key = 'notification';
      subscriptionClient
        .subscribe({ query: NOTIFICATION_SUBSCRIPTION })
        .subscribe({
          next({ data }) {
            const {
              notification: { userId },
            } = data;
            if (parseInt(loginUserId, 10) === parseInt(userId, 10)) {
              dispatch({ type: ACTIONS_TYPE.INCREASE_NOTIFICATION_COUNT });
            }
          },
          error(err) {
            console.error(err);
          },
        });
    }
  }, [dispatch, email, loginUserId]);

  const updateTour = () => {
    setIsTourOpen(true);
  };

  useEffect(() => {
    if (pathname.includes('/settings') && currStep === 0) {
      setTimeout(() => {
        setIsTourOpen(true);
      }, 3000);
    } else if (
      pathname === PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.GROUPS &&
      currStep === 1
    ) {
      updateTour();
    } else if (
      pathname === PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.ADD_GROUPS &&
      currStep === 2
    ) {
      updateTour();
    } else if (
      pathname === PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.GROUPS &&
      currStep === 3
    ) {
      updateTour();
    } else if (
      pathname === PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.USERS &&
      currStep === 4
    ) {
      updateTour();
    } else if (
      pathname === PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.ADD_USER &&
      currStep === 5
    ) {
      updateTour();
    } else if (
      pathname.includes(`${PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.USERS}/edit`) &&
      currStep === 6
    ) {
      updateTour();
    } else if (!pathname.includes('/settings/') && currStep === 7) {
      updateTour();
    }
  }, [pathname, currStep]);

  const tourCompleted = () => {
    client
      .mutate({
        mutation: UPDATE_COMPANY,
        variables: {
          isTourCompleted: true,
          where: {
            id: parseInt(company.id, 10),
          },
        },
        refetchQueries: [
          {
            query: GET_CURRENT_USER,
            fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
          },
        ],
      })
      .then((response) => {
        setRun(false);
        const {
          data: { currentUser },
        } = response;
        if (currentUser) {
          dispatch({ type: ACTIONS_TYPE.LOGIN_USER, data: currentUser });
        }
      })
      .catch((e) => {
        console.log(e);
        Sentry.captureException(e);
      });
  };

  const checkExactPath = (route = '/') => {
    if (pathname.toString() === route) {
      return <Route component={AccessDenied} />;
    }
  };

  useEffect(() => {
    if (ability.can('use', 'standups_feature')) {
      if (!ability.can('manage', 'standups')) {
        const index = findIndex(
          featurePages,
          (page) => page.name === 'Stand-ups' && page.subPages
        );
        if (index !== -1) {
          featurePages.splice(index, 1);
        }
      } else {
        const index = findIndex(
          featurePages,
          (page) => page.name === 'Stand-ups' && !page.subPages
        );
        if (index !== -1) {
          featurePages.splice(index, 1);
        }
      }
    }
  }, [featurePages]);

  return (
    <>
      <Switch>
        <Route exact path={PUBLIC_ROUTE.SET_PASSWORD} component={SetPassword} />
        <Route exact path={ONBOARDING.ROOT} component={Onboarding} />
        <Route path={ONBOARDING.SELECTION} component={Selection} />
        <Route path={ONBOARDING.WORKSPACE_RESULT} component={Results} />
        <Route exact path={PUBLIC_ROUTE.LOGOUT} component={Logout} />
        <>
          {sidebar && email && (
            <>
              {!visible && (
                <Button
                  type={BUTTON_TYPE.PRIMARY}
                  className="main-sidebar-block-button"
                  icon={<MenuUnfoldOutlined />}
                  onClick={showDrawer}
                />
              )}
              <Drawer
                className="main-sidebar-block-drawer"
                placement="left"
                closable={false}
                onClose={onClose}
                visible={visible}
                width="auto"
              >
                <GlobalStyles />
                <Sidebar />
              </Drawer>
              <div className="main-sidebar ">
                <Sidebar />
              </div>
            </>
          )}
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                exact
                path={PRIVATE_ROUTE.DASHBOARD}
                component={Dashboard}
              />
              {/* commented for future use */}
              {/* {(ability.can('use', 'people_feature') ||
                ability.can('use', 'daily_goals_feature')) && ( */}
              <Route
                exact
                path={PRIVATE_ROUTE.ACTIVITIES}
                component={Activities}
              />
              <Route exact path={PRIVATE_ROUTE.INSIGHTS} component={Insights} />
              <Route exact path={PRIVATE_ROUTE.TEAMS} component={Teams} />
              <Route path={PRIVATE_ROUTE.UPDATES} component={Updates} />
              {/* hiding this page because it is not researched fully or had
              it's correct purpose for */}
              {/* <Route path={PRIVATE_ROUTE.TODOS} component={Todos} /> */}
              <Route
                exact
                path={PRIVATE_ROUTE.SETTINGS.MAIN_ROUTE.PAGE}
                component={Settings}
              />
              <Route
                path={PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.PAGE}
                component={Settings}
              />
              <Route path={PRIVATE_ROUTE.PROFILE} component={Profile} />
              <Route
                path={PRIVATE_ROUTE.MY_PROFILE}
                render={() => <Profile userId={loginUserId} />}
              />
              {featurePages.map((page) => {
                const { abilitySubject, abilityAction } = page;
                if (ability.can(abilityAction, abilitySubject)) {
                  return <Route path={page.key} component={page.component} />;
                }
                checkExactPath(page.key);
              })}
              {!loading && <Route component={Page404} />}
            </Switch>
          </Suspense>
        </>
      </Switch>
    </>
  );
}

export default withRouter(App);
