import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { x } from 'design/Styled';

const LoaderContent = styled.div`
  ${x};
  justify-content: center;
  height: 100%;
  width: 100%;
`;
const Loader = ({ size }) => (
  // <LoaderContent><Spin indicator={loadingIcon} /></LoaderContent>
  <LoaderContent>
    <Spin size={size || 'large'} />
  </LoaderContent>
);

export default Loader;
