import { Layout } from 'antd';
import styled from 'styled-components';
import Spacings from 'design/Spacings';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';

const { Content: AntContent } = Layout;

export const Content = styled(AntContent)`
  .ant-table{
    border: 1px solid ${Colors.Border};
  }
  .anticon-check {
    color: ${Colors.ButtonPrimary};
    font: ${fonts.xxLarge.regular};
  }

  .anticon-info-circle {
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Comments = styled.div`
  color: ${Colors.ButtonPrimary};
  font: ${fonts.medium.regular};
  white-space: nowrap;
  cursor: pointer;
`;

export const Title = styled.div`
  white-space: normal;
`;
