import { gql } from '@apollo/client';

export const GET_ATTENDANCE = gql`
  query attendances($where: AttendanceWhereInput!, $skip: Int, $limit: Int, $sortOn: sortOnAttendanceInput, $sortBy: sortByAttendanceInput) {
    attendances(where: $where, skip: $skip, limit: $limit, sortOn: $sortOn, sortBy: $sortBy){
      attendances
      count
    }
  }
`;
