import { gql } from '@apollo/client';
import { actionFragment } from './Fragments';

export const GET_MY_TASKS = gql`
query myProfileActions($where:MyProfileActionWhereInput, $sortBy: SortByInput, $sortOn: SortOnDailyGoalsAndBlockersInput){
  myProfileActions(where:$where, sortBy: $sortBy, sortOn: $sortOn){
    dailyGoals{
      ...ActionFragment
    }
  }
}
${actionFragment}
`;

export const GET_MY_TASKS_AND_BLOCKERS = gql`
query myProfileActions($where:MyProfileActionWhereInput, $sortBy: SortByInput, $sortOn: SortOnDailyGoalsAndBlockersInput){
  myProfileActions(where:$where, sortBy: $sortBy, sortOn: $sortOn){
    dailyGoals{
      ...ActionFragment
    }
  }
  myProfileActions(where:$where, sortBy: $sortBy, sortOn: $sortOn){
    blockers{
      ...ActionFragment
    }
    blockersToRemove{
      ...ActionFragment
    }
  }
}
${actionFragment}
`;

export const GET_MY_BLOCKERS = gql`
query myProfileActions($where:MyProfileActionWhereInput, $sortBy: SortByInput, $sortOn: SortOnDailyGoalsAndBlockersInput){
  myProfileActions(where:$where, sortBy: $sortBy, sortOn: $sortOn){
    blockers{
      ...ActionFragment
    }
    blockersToRemove{
      ...ActionFragment
    }
  }
}
${actionFragment}
`;

export const GET_MY_BLOCKERS_TO_REMOVE = gql`
query myProfileActions($where:MyProfileActionWhereInput){
  myProfileActions(where:$where){
    blockersToRemove{
      ...ActionFragment
    }
  }
}
${actionFragment}
`;
