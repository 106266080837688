import React, { useEffect, useState } from 'react';
import { Layout, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  Router,
  Switch,
  Route,
  useLocation,
  useParams,
  useHistory,
} from 'react-router-dom';
import Meta from 'atoms/Meta';
import * as Sentry from '@sentry/react';
import Button from 'atoms/Button';
import { FETCH_POLICY, META_TYPE, PRIVATE_ROUTE } from 'utils/constants';
import { BackArrow } from 'assets';
import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
import { fonts } from 'design/Fonts';
import styled from 'styled-components';
import InfoIcon from 'atoms/InfoIcon';
import _map from 'lodash/map';
import Header from 'layout/Header';
import { useQuery } from '@apollo/client';
import { GET_FEEDBACK_SUMMARY } from 'features/feedbacks/graphql/Queries';
import ability from 'utils/ability/ability';
import Summary from '../../templates/Polls/Summary';
import Response from '../../templates/Polls/Response';
import { ContentTitle, Arrow } from './AntStyled';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = styled.div`
  margin-left: ${Spacings.xxSmall.margin};
  .tab {
    width: 100px;
    height: 34px;
    font-size: ${fonts.small.size};
    padding: 0px ${Spacings.xxSmall.padding};
    box-sizing: border-box;
    border-radius: 6px 0px 0px 6px;

    &:nth-child(2) {
      border-radius: 0px 6px 6px 0px;
    }
    &:hover {
      color: ${Colors.TextPrimaryHover};
      border-color: ${Colors.TextPrimaryHover};
    }
    @media screen and (min-width: 1100px) {
      width: 128px;
    }
  }
  & .tab.active {
    font-weight: 600;
  }
`;
export default function Polls(props) {
  const { history } = props;
  const { pathname } = useLocation();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(null);
  const tabs = [];
  const { data = {}, loading } = useQuery(GET_FEEDBACK_SUMMARY, {
    variables: { where: { id } },
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const { feedbackSummary: { feedback = {} } = {} } = data;

  switch (feedback?.resultVisibility) {
    case 'EVERYONE':
      if (ability.can('use', 'feedbacks_feature')) {
        tabs.push(
          <FormattedMessage id="feedback.tab.summary" />,
          <FormattedMessage id="feedback.tab.response" />
        );
      }
      break;
    case 'MANAGERS':
      if (ability.can('manage', 'feedbacks')) {
        tabs.push(
          <FormattedMessage id="feedback.tab.summary" />,
          <FormattedMessage id="feedback.tab.response" />
        );
      }
      break;

    default:
      break;
  }

  const Title = () => (
    <Wrapper>
      <ContentTitle>
        <Arrow
          src={BackArrow}
          alt="arrow"
          onClick={() => history.push(PRIVATE_ROUTE.FEED_BACK_COMPLETED)}
        />
        <InfoIcon
          title={<FormattedMessage id={`Poll: ${feedback?.title || ''}`} />}
        />
      </ContentTitle>
      <Tabs>
        {_map(tabs, (list, index) => (
          <Button
            key={index}
            keyProp={index}
            className={`tab ${index === activeTab ? 'active' : ''}`}
            background={Colors.TextLight}
            border={
              index === activeTab ? Colors.PrimaryColor : Colors.SecondaryColor
            }
            color={
              index === activeTab ? Colors.PrimaryColor : Colors.TextSecondary
            }
            focus={Colors.TextLight}
            name={list}
            onClick={() => callback(index)}
          />
        ))}
      </Tabs>
    </Wrapper>
  );

  const callback = (key) => {
    setActiveTab(key);
    if (key === 0) {
      history.push(`/feedback/poll/${id}/summary`);
    } else {
      history.push(`/feedback/poll/${id}/response`);
    }
  };
  useEffect(() => {
    if (pathname === `/feedback/poll/${id}/summary`) {
      setActiveTab(0);
    } else if (pathname === `/feedback/poll/${id}/response`) {
      setActiveTab(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Sentry.ErrorBoundary fallback="Something went wrong">
      <Layout>
        <Router history={history}>
          <Meta title={META_TYPE.POLLS} />
          <Header title={<Title />} />
          <Switch>
            <Route
              exact
              path={PRIVATE_ROUTE.POLLS_SUMMARY}
              render={() => <Summary response={activeTab === 0} />}
            />
            <Route
              exact
              path={PRIVATE_ROUTE.POLLS_RESPONSE}
              render={() => <Response response={activeTab === 0} />}
            />
          </Switch>
        </Router>
      </Layout>
    </Sentry.ErrorBoundary>
  );
}
