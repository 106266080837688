import React, { useEffect, useState, useContext } from 'react';
import { Layout } from 'antd';
import {
  Router,
  Switch,
  Route,
  withRouter,
  useLocation,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import _map from 'lodash/map';
import _has from 'lodash/has';
import Colors from 'design/Colors';
import Meta from 'atoms/Meta';
import Button from 'atoms/Button';
import Spacings from 'design/Spacings';
import InfoIcon from 'atoms/InfoIcon';
import {
  EMPTY_ARRAY,
  PRIVATE_ROUTE,
  META_TYPE,
  ACTIONS_TYPE,
  FETCH_POLICY,
} from 'utils/constants';
import * as Sentry from '@sentry/react';
import Filters from 'molecules/Filters';
import { AppContext } from 'AppContext';
import { fonts } from 'design/Fonts';
import { useQuery } from '@apollo/client';
import Header from 'layout/Header';
import ability from 'utils/ability/ability';
import { GET_ALL_SOPS } from '../graphql/Queries';
import SopList from '../templates/SopList';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = styled.div`
  margin-left: ${Spacings.xxSmall.margin};
  .tab {
    width: 128px;
    height: 34px;
    font: ${fonts.small.regular};
    padding: 0px ${Spacings.xxSmall.padding};
    box-sizing: border-box;
    border-radius: 6px 0px 0px 6px;
    &:nth-child(2) {
      border-radius: 0px 0px 0px 0px;
    }
    &:nth-child(3) {
      border-radius: 0px 6px 6px 0px;
    }
    &:hover {
      color: ${Colors.TextPrimaryHover};
      border-color: ${Colors.TextPrimaryHover};
    }
  }
  & .tab.active {
    font-weight: 600;
  }
`;

const sopStatus = ['PUBLISHED', 'DRAFT', 'ARCHIVED'];

const Sop = (props) => {
  const { pathname } = useLocation();
  const { history } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [groupId, setGroupId] = useState(EMPTY_ARRAY);
  const [sops, setSops] = useState(EMPTY_ARRAY);
  const tabs = [
    <FormattedMessage id="sops.tabs.active" />,
    <FormattedMessage id="sops.tabs.draft" />,
    <FormattedMessage id="sops.tabs.archived" />,
  ];

  const { data: sopData, loading: sopLoading } = useQuery(GET_ALL_SOPS, {
    variables: {
      where: {
        departments: groupId.length ? groupId : undefined,
        status: sopStatus[activeTab],
      },
    },
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });

  useEffect(() => {
    if (_has(sopData, 'getAllSops')) {
      setSops(sopData.getAllSops);
    }
  }, [sopData, sopLoading]);

  useEffect(() => {
    if (pathname === PRIVATE_ROUTE.SOP_ACTIVE) {
      setActiveTab(0);
    } else if (pathname === PRIVATE_ROUTE.SOP_DRAFT) {
      setActiveTab(1);
    } else if (pathname === PRIVATE_ROUTE.SOP_ARCHIVE) {
      setActiveTab(2);
    }
  }, [pathname]);

  const callback = (key) => {
    setActiveTab(key);
    if (key === 0) {
      history.push(PRIVATE_ROUTE.SOP_ACTIVE);
    } else if (key === 1) {
      history.push(PRIVATE_ROUTE.SOP_DRAFT);
    } else {
      history.push(PRIVATE_ROUTE.SOP_ARCHIVE);
    }
  };

  const Title = () => (
    <Wrapper>
      <InfoIcon title={<FormattedMessage id="sops.title" />} />
      {ability.can('manage', 'sops') && (
        <Tabs>
          {_map(tabs, (list, index) => (
            <Button
              key={index}
              className={`tab ${index === activeTab ? 'active' : ''}`}
              background={Colors.TextLight}
              border={
                index === activeTab ? Colors.PrimaryColor : Colors.TextDisable
              }
              color={
                index === activeTab ? Colors.PrimaryColor : Colors.TextSecondary
              }
              focus={Colors.TextLight}
              name={list}
              onClick={() => callback(index)}
            />
          ))}
        </Tabs>
      )}
    </Wrapper>
  );

  return (
    <Sentry.ErrorBoundary fallback="Something went wrong">
      <Layout>
        <Meta title={META_TYPE.SOPS} />
        <Header title={<Title />} />
        {activeTab !== null && (
          <Filters
            masterFilter
            groupValue={groupId}
            groupsFilters={setGroupId}
            // doSearch
            addButton={ability.can('manage', 'sops')}
            addButtonText={<FormattedMessage id="sops.add" />}
            addButtonEvent={() => history.push(PRIVATE_ROUTE.SOP_ADD)}
          />
        )}
        <Router history={history}>
          <Switch>
            <Route
              exact
              path={PRIVATE_ROUTE.SOP_ACTIVE}
              render={() => <SopList data={sops} loading={sopLoading} />}
            />
            <Route
              path={PRIVATE_ROUTE.SOP_ARCHIVE}
              render={() => (
                <SopList data={sops} loading={sopLoading} isArchive />
              )}
            />
            <Route
              path={PRIVATE_ROUTE.SOP_DRAFT}
              render={() => <SopList data={sops} loading={sopLoading} />}
            />
          </Switch>
        </Router>
      </Layout>
    </Sentry.ErrorBoundary>
  );
};

export default withRouter(Sop);
