/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { DatePicker as AntDatePicker, Tag, ConfigProvider } from 'antd';
import _map from 'lodash/map';
import styled from 'styled-components';
import moment from 'moment';
import Spacings from 'design/Spacings';
import { titleCase } from 'utils/stringUtilities';
import Colors from 'design/Colors';
import locale from 'antd/es/locale/en_GB';
import { fonts } from 'design/Fonts';
import { CloseCircle } from 'assets';
import Variables from 'design/Variables';

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spacings.xxSmall.padding} 0;
  & .types-wrapper {
    display: inline-flex;
  }
  & .cancel-btn {
    margin: 0;
  }
  & .ant-tag {
    cursor: pointer;
  }
  .ant-picker {
    padding: 10px 8px 10px;
  }
`;
const DatePicker = styled(AntDatePicker)`
  display: inline-flex;
  height: 34px;
  /* justify-content: space-evenly; */
  background-color: ${({ hasValue }) =>
    hasValue ? Colors.Transparent : 'inherit'};
  line-height: 1.5715;
  color: ${({ hasValue }) => (hasValue ? 'Colors.Text' : 'inherit')};
  opacity: ${({ hasValue }) => (hasValue ? 1 : 1)};
  max-width: 140px;
  padding: 9px 8px 9px;
  font: ${fonts.small.regular};
  border-radius: ${Variables.AtomRadius};
  border: 1px solid ${Colors.SecondaryColor};
  cursor: pointer;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.SecondaryColor};
  }
  .ant-picker-input > input {
    color: ${Colors.TextSecondary};
    font: ${fonts.small.regular};
  }
  .anticon svg {
    display: none;
  }
  & .arrow-right {
    align-self: flex-end;
    font-size: ${fonts.small.size};
  }
  input,
  input::placeholder {
    color: ${Colors.Text};
  }
`;
const Close = styled.img`
  cursor: pointer;
`;

const SwitchableDatePicker = ({
  onClickData,
  setDateFilterValue,
  showDay,
  setType,
  dateFilterValue,
  typeValue = showDay ? 'day' : 'week',
  yearOnly,
}) => {
  const types = showDay ? ['day', 'week', 'month'] : ['week', 'month', 'year'];
  const [selectedType, setSelectedType] = useState(typeValue);
  const [value, setValue] = useState(
    (dateFilterValue && dateFilterValue[0]) || moment()
  );
  const [lastValue, setLastValue] = useState();
  const [lastType, setLastType] = useState();
  const [open, setOpen] = useState(false);

  const handleType = (type) => {
    setSelectedType(type);
    if (value !== null) {
      setLastValue(value);
    }
    setValue(null);
  };

  useEffect(() => {
    if (!open) {
      setLastType(selectedType);
    }
  }, [open, selectedType]);

  const Footer = () => (
    <FooterWrapper>
      <div className="types-wrapper">
        {_map(types, (type, index) => (
          <Tag
            key={index}
            onClick={() => handleType(type)}
            color={type === selectedType ? Colors.PrimaryColor : 'default'}
          >
            {titleCase(type)}
          </Tag>
        ))}
      </div>
      <Tag
        className="cancel-btn"
        onClick={() => {
          setOpen(false);
          if (value === null) {
            setValue(lastValue);
            setSelectedType(lastType);
          }
        }}
        color={Colors.TextDanger}
      >
        Close
      </Tag>
    </FooterWrapper>
  );

  const onChange = (selectedValue) => {
    if (selectedValue === null) {
      setOpen(true);
      setValue(selectedValue);
    } else {
      onClickData && onClickData();
      setValue(selectedValue);
      setLastValue(selectedValue);
      setDateFilterValue([
        moment(selectedValue).startOf(selectedType),
        moment(selectedValue).endOf(selectedType),
      ]);
      setOpen(false);
      setLastType(selectedType);
      setType && setType(selectedType);
    }
  };
  const PickerWithType = ({ type }) => (
    <DatePicker
      onClick={() => {
        setOpen(true);
      }}
      showToday={false}
      format={type === 'day' ? 'MM/DD/YYYY' : null}
      value={value}
      picker={type === 'day' ? undefined : type}
      onChange={onChange}
      open={open}
      renderExtraFooter={() => !yearOnly && <Footer />}
      defaultValue={moment().endOf('day')}
      suffixIcon={<Close src={CloseCircle} alt="close" />}
    />
  );
  return (
    <ConfigProvider locale={locale}>
      <PickerWithType type={selectedType} />
    </ConfigProvider>
  );
};

export default SwitchableDatePicker;
