import React from 'react';
import { map } from 'lodash';
import { CornerDown } from 'assets';
import { REGEX } from './constants';

export const taskFormat = (value, addedLater, small) => {
  const taskArray = value?.split(REGEX.WEB_URL);
  return (
    <>
      <div>
        {map(taskArray, (val, index) => {
          if (REGEX.WEB_URL.test(val)) {
            return (
              <a href={val} target="_blank">
                {val}
              </a>
            );
          }
          return (
            <span>
              {val}{' '}
              {addedLater && index === taskArray?.length - 1 && (
                <span className="ml-8">
                  <img
                    src={CornerDown}
                    alt="icon"
                    height={small ? 14 : 18}
                    width={small ? 14 : 18}
                  />
                </span>
              )}
            </span>
          );
        })}
      </div>
    </>
  );
};
