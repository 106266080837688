import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    role
    profileImage
    contact
    username
    title
    rules
  }
`;

export const commentFragment = gql`
  fragment CommentFragment on Comment {
    id
    content
    createdBy {
      ...UserFragment
    }
    updatedBy {
      ...UserFragment
    }
    createdAt
    updatedAt
  }
  ${userFragment}
`;

export const actionFragment = gql`
  fragment ActionFragment on Action {
    id
    name
    type
    status
    comments {
      id
      content
      createdBy {
        ...UserFragment
      }
      updatedBy {
        ...UserFragment
      }
      createdAt
      updatedAt
    }
  }
  ${userFragment}
`;
