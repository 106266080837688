import { gql } from '@apollo/client';

export const featureFragment = gql`
  fragment FeatureFragment on Feature {
    key
    name
    description
    isActive
    iconImage
    isAvailable
    featureItems {
      id
      featureImage
      title
      description
      order
      featureKey
    }
    createdAt
    updatedAt
  }
`;

export const companyFragment = gql`
  fragment CompanyFragment on Company {
    id
    name
    subDomain
    featureKeys
    logo
    createdAt
    updatedAt
  }
`;

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    role
    profileImage
    title
    type
    contact
    username
    isActive
    createdAt
    updatedAt
  }
`;

export const leaveSettingFragment = gql`
  fragment LeaveSettingFragment on LeaveSetting {
    id
    type
    totalLeaves
    carryForward
    isPaid
    isSystem
    company {
      ...CompanyFragment
    }
    createdBy {
      ...UserFragment
    }
    createdAt
    updatedAt
  }
  ${companyFragment}
  ${userFragment}
`;

export const punchSettingFragment = gql`
  fragment PunchSettingFragment on PunchSetting {
    id
    company {
      ...CompanyFragment
    }
    startTime
    minStartTime
    endTime
    minEndTime
    allowedTimeFlexibility
    firstHalfStartTime
    firstHalfEndTime
    firstHalfAllowedTimeFlexibility
    secondHalfStartTime
    secondHalfEndTime
    secondHalfAllowedTimeFlexibility
    requiredDuration
    minDuration
    firstHalfRequiredDuration
    firstHalfMinDuration
    secondHalfRequiredDuration
    secondHalfMinDuration
    fixedTiming
    fixedBreakTiming
    totalBreakDuration
    breaks {
      id
      startTime
      endTime
    }
    createdAt
    updatedAt
  }
  ${companyFragment}
`;

export const holidayFragment = gql`
  fragment HolidayFragment on Holiday {
    id
    name
    date
    type
    dayType
    createdAt
    updatedAt
    country
  }
`;
