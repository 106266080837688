import { gql } from '@apollo/client';
import { feedbackFragment } from './Fragments';

export const GET_FEEDBACKS = gql`
  query feedbacks($where: feedbacksWhere) {
    feedbacks(where: $where) {
      feedbacks {
        ...FeedbackFragment
      }
      count
    }
  }
  ${feedbackFragment}
`;

export const GET_RESPONSE_COUNT = gql`
  query feedbacksResponseCount($where: feedbacksResponseCountInput) {
    feedbacksResponseCount(where: $where) {
      count
    }
  }
`;

export const GET_FEEDBACK_BYID = gql`
  query feedback($where: feedbackUniqueInput) {
    feedback(where: $where) {
      ...FeedbackFragment
    }
  }
  ${feedbackFragment}
`;

export const GET_FEEDBACK_TEMPLATES = gql`
  query templateFeedbacks($where: feedbackWhereInput!) {
    templateFeedbacks(where: $where) {
      ...FeedbackFragment
    }
  }
  ${feedbackFragment}
`;

export const GET_FEEDBACK_SUMMARY = gql`
  query feedbackSummary($where: feedbackUniqueInput) {
    feedbackSummary(where: $where) {
      feedback {
        ...FeedbackFragment
      }
      participants
      responded
      pollResult
    }
  }
  ${feedbackFragment}
`;
export const GET_FEEDBACK_RESPONSE = gql`
  query feedbackResponse($where: feedbackResponseInput) {
    feedbackResponse(where: $where) {
      questionsAnswered
      createdAt
      answeredBy {
        id
        firstName
        lastName
        email
        role
        profileImage
      }
      answers {
        id
        answer
        question {
          id
          questionType
          sequenceNo
          question
          choices
          scaleLabel
        }
      }
    }
  }
`;
