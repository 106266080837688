import styled, { css } from 'styled-components';
import { EMPTY_STRING } from 'utils/constants';
import Colors from './Colors';
import { fonts } from './Fonts';

const scrollSize = 6;
const scrollPadding = 8;

const isPrimary = ({ primary }) => {
  if (typeof primary === 'boolean') {
    return 'flex: 1';
  }

  if (typeof primary === 'number') {
    return css`
      flex: ${primary};
    `;
  }

  return EMPTY_STRING;
};

const getPadding = ({ padding }) => {
  if (typeof padding === 'number') {
    return `${padding}em`;
  }

  if (typeof padding === 'string') {
    return padding;
  }

  return 0;
};

export const x = css`
  ${isPrimary};
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  width: ${({ width }) => width || 100}%;
  padding: ${getPadding};
`;

export const y = css`
  ${isPrimary};
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  width: ${({ width }) => width || 100}%;
  padding: ${getPadding};
`;

export const row = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const scrollbarYPadding = css`
  padding-right: ${scrollPadding}px;
`;

export const scrollbarXPadding = css`
  padding-bottom: ${scrollPadding}px;
`;

export const scrollbars = css`
  ::-webkit-scrollbar {
    width: ${scrollSize}px;
    height: ${scrollSize}px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.Border};
    border-radius: 10px;
  }

  .ant-table::-webkit-scrollbar {
    width: 3px;
  }
  .ant-table::-webkit-scrollbar-track {
    background: ${Colors.Border};
  }
`;

export const scrollYContainer = css`
  ${scrollbars};
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const scrollXContainer = css`
  ${scrollbars};
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
`;

export const link = css`
  text-decoration: underline;
  color: ${Colors.ButtonPrimary};
  font: ${({ font }) => font || fonts.small.regular};
  cursor: pointer;
`;

export const InfiniteScrollTableWrapper = styled.div`
  max-height: ${({ height }) => height || 'calc(100vh - 96px - 60px)'};
  overflow: auto;
  border-radius: 8px;
  border: ${({ bordered }) =>
    bordered === false ? 'none' : `1px solid ${Colors.Border}`};
  ::-webkit-scrollbar {
    display: none;
  }
`;
