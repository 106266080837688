import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    role
    profileImage
    title
    type
    contact
    username
    userGroups{
      group {
        id
        name
        color
        type
      }
    }
    location {
      id
      name
      shortName
      timeZone
    }
    isActive
    createdAt
    updatedAt
  }
`;

export const commentFragment = gql`
  fragment CommentFragment on Comment {
    id
    content
    createdBy {
      ...UserFragment
    }
    createdAt
    updatedAt
  }
  ${userFragment}
`;

export const leaveRequestFragment = gql`
  fragment LeaveRequestFragment on LeaveRequest {
    id
    user{
      ...UserFragment
    }
    assignments{
      ...UserFragment
    }
    approvedBy {
      ...UserFragment
    }
    rejectedBy {
      ...UserFragment
    }
    comments { 
      ...CommentFragment
    }
    status
    description
    isCancelled
    leaves {
      id
      leaveSetting {
        id
        type
        totalLeaves
        isPaid
      }
      user {
        ...UserFragment
      }
      dayType
      leaveType
      isCancelled
    }
    leaveTypes
    from
    to
    createdAt
    updatedAt
  }
  ${userFragment}
  ${commentFragment}
`;
