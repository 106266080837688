import styled from 'styled-components';
import { Layout, Divider as AntDivider, Typography } from 'antd';
import Colors from 'design/Colors';
import { fonts, defaultSize as defaultFontSize } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;
// const { Title, Text } = Typography;
export const Content = styled(AntContent)`
  .col-1 {
    width: 292px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 16px;
  }
  .col-2 {
    padding: 16px;
  }
  .progress {
    margin-right: ${Spacings.small.margin};
  }
  .poll-content {
    font-size: ${defaultFontSize};
  }
  .poll-head {
    font: ${fonts.xxLarge.bold};
    color: ${Colors.ButtonPrimary};
  }
  .poll-percent {
    font-size: 16px;
    font-weight: 700;
  }
  .poll-description {
    font: ${fonts.xSmall.bold};
    color: ${Colors.TextSecondary};
    margin-top: 5px;
  }
  .departments-tag-row {
    margin-top: ${Spacings.xSmall.margin};
  }
  .timeline {
    align-items: center;
    color: ${Colors.TextSecondary};
    :last-child {
      margin-top: calc(${Spacings.xSmall.margin} - 4px);
    }
  }
  .ant-divider.ant-divider-vertical {
    background: rgba(0, 0, 0, 0.2);
  }
  .line {
    margin: 0 12px;
  }
  .col-statistics {
    display: flex;
    margin-top: ${Spacings.xSmall.margin};
  }
  .ant-divider.ant-divider-vertical {
    background: ${Colors.Border};
    margin-right: ${Spacings.xSmall.margin};
    margin-left: 0px;
  }
  .ant-divider.ant-divider-horizontal {
    background: ${Colors.Border};
  }
  .large-button {
    width: 260px;
  }
  .card-1 {
    width: 400px;
    margin-right: 15px;
  }
  .card-2 {
    padding: 16px;
  }
  .col-end {
    margin-left: 30px;
    color: ${Colors.TextSecondary};
  }
`;

export const Divider = styled(AntDivider)`
  height: 100%;
  color: red;
`;
export const HR = styled(AntDivider)`
  margin: 16px 0px;
`;
export const Title = styled(Typography)`
  font: ${fonts.medium.medium};
  color: ${Colors.TextDark};
  line-height: 24px;
  margin-bottom: ${Spacings.xSmall.margin};
`;
export const Text = styled(Typography)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  line-height: 22px;
`;
export const Created = styled(Typography)`
  font: ${fonts.xSmall.bold};
  color: ${Colors.TextSecondary};
  line-height: 22px;
`;
export const TextBy = styled.span`
  font: ${fonts.xSmall.bold};
  color: ${Colors.TextSecondary};
  line-height: 22px;
  margin-right: ${Spacings.xxSmall.margin};
`;
export const UserDiv = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.TextSecondary};
  line-height: 22px;
  .text {
    font-size: 12px;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
`;
