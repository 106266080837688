import React, { useContext, useEffect } from 'react';

import {
  ACTIONS_TYPE,
  AMPLITUDE_EVENTS,
  EMPTY_OBJECT,
  PUBLIC_ROUTE,
} from 'utils/constants';
import { AppContext } from 'AppContext';
import { removeToken } from 'utils/auth';
import { createAmplitudeEvent } from 'utils/amplitude';
import OneSignal from 'react-onesignal';

export default function () {
  const userContext = useContext(AppContext);
  const {
    dispatch,
    state: { loginUser },
  } = userContext;
  useEffect(() => {
    removeToken();
    OneSignal.deleteTag('userId');
    const eventProperties = {
      Id: loginUser?.id,
      Username: `${loginUser?.firstName} ${loginUser?.lastName}`,
      Email: loginUser?.email,
    };
    createAmplitudeEvent(AMPLITUDE_EVENTS.USER_LOGOUT, eventProperties);
    dispatch({ type: ACTIONS_TYPE.LOGIN_USER, data: EMPTY_OBJECT });
    setTimeout(() => {
      window.location.href = PUBLIC_ROUTE.LOGIN;
    }, 500);
  }, [dispatch]);

  return <div />;
}
