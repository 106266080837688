import { gql } from '@apollo/client';
import {
  featureFragment,
  holidayFragment,
  leaveSettingFragment,
  punchSettingFragment,
} from './Fragments';

export const GET_FEATURES = gql`
  query features {
    features {
      ...FeatureFragment
    }
  }
  ${featureFragment}
`;

export const GET_LEAVE_SETTINGS = gql`
  query leaveSettings($where: LeaveSettingWhereInput) {
    leaveSettings(where: $where) {
      ...LeaveSettingFragment
    }
  }
  ${leaveSettingFragment}
`;

export const GET_HOLIDAYS = gql`
  query holidays($where: HolidayWhereInput) {
    holidays(where: $where) {
      ...HolidayFragment
    }
  }
  ${holidayFragment}
`;

export const GET_PUNCH_SETTING = gql`
  query getPunchSetting($where: PunchSettingWhereInput!) {
    getPunchSetting(where: $where) {
      ...PunchSettingFragment
    }
  }
  ${punchSettingFragment}
`;

export const GET_HOLIDAY_TEMPLATE = gql`
  query getHolidayTemplateData($where: HolidayTemplateDataInput) {
    getHolidayTemplateData(where: $where)
  }
`;

export const GET_DEPARTMENTS = gql`
  query getDepartments {
    getDepartments {
      id
      name
      companyId
      deletable
      createdAt
      updatedAt
    }
  }
`;
