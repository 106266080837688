import { gql } from '@apollo/client';

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($data:NotificationDataInput! $where:NotificationWhereUniqueInput!){
    updateNotification(data:$data where:$where){
      id
    }
  }
`;

export const CLEAR_ALL_NOTIFICATIONS = gql`
  mutation clearAllNotifications{
    clearAllNotifications
  }
`;

export const UPDATE_USER_LAST_SEEN = gql`
mutation updateUser(
  $lastNotificationSeenAt: DateTime
  $where: UserWhereUniqueInput!
) {
  updateUser(
    data: {
      lastNotificationSeenAt: $lastNotificationSeenAt
    },
    where: $where
  ) {
    id
  }
}
`;
