import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    role
    profileImage
    contact
    username
    title
    rules
  }
`;
