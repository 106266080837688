import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  split,
  disableFragmentWarnings,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { WebSocketLink } from '@apollo/link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ErrorLink } from '@apollo/link-error';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import { message } from 'antd';
import * as Sentry from '@sentry/react';
import { removeToken } from 'utils/auth';
import { PUBLIC_ROUTE } from 'utils/constants';
import { get } from 'lodash';
import history from './history';

const logoutError = [
  'invalid signature',
  'jwt malformed',
  'jwt expired',
  'Not Authorised!',
];
let disableToastTimeout = null;
disableFragmentWarnings();

function stripTypeNames(obj, propToDelete) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property;
      const newData = stripTypeNames(obj[property], propToDelete);
      obj[property] = newData;
    } else if (property === propToDelete) {
      delete obj[property];
    }
  }
  return obj;
}

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation?.variables) {
    operation.variables = stripTypeNames(operation?.variables, '__typename');
  }
  return forward ? forward(operation) : null;
});

const responseMessageLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const { data } = response;
    if (
      data &&
      typeof data === 'object' &&
      Object.keys(data)?.length > 0 &&
      data[`${Object.keys(data)[0]}`]?.message
    ) {
      if (Object.keys(data)[0] === 'forgotUserPassword') {
        if (data?.[`${Object.keys(data)[0]}`]?.status !== 'ERROR') {
          setTimeout(() => {
            message.success(
              data?.[`${Object.keys(data)[0]}`]?.message ||
                'Operation successful'
            );
          }, 500);
        }
      } else {
        setTimeout(() => {
          message.success(
            data?.[`${Object.keys(data)[0]}`]?.message || 'Operation successful'
          );
        }, 500);
      }
    }
    return response;
  })
);

const errorLink = new ErrorLink(({ graphQLErrors, networkError, response }) => {
  if (networkError?.statusCode === 405) {
    if (disableToastTimeout) {
      clearTimeout(disableToastTimeout);
    }

    disableToastTimeout = setTimeout(() => {
      if (networkError?.result?.message) {
        message.destroy();
        message.error(networkError?.result?.message);
      }
    }, 200);
    history.replace('/logout');
    return;
  }
  if (graphQLErrors?.length > 0) {
    const isForBidden =
      get(graphQLErrors[0], 'extensions.code') === 'FORBIDDEN';

    if (!isForBidden) {
      setTimeout(() => {
        message.destroy();
        message.error(graphQLErrors?.[0]?.message);
      }, 1000);
    }
  } else {
    setTimeout(() => {
      message.destroy();
      message.error('Something went wrong!');
    }, 1000);
  }

  if (response) {
    response.errors.map(({ message: errorMessage, locations, path }) => {
      message.destroy();
      message.error(errorMessage);
      Sentry.captureException(
        new Error(
          `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
        )
      );

      if (logoutError.includes(errorMessage)) {
        message.destroy();
        removeToken();
        setTimeout(() => {
          window.location.href = PUBLIC_ROUTE.TOKEN_EXPIRED;
        }, 500);

        // history.push('/logout');
        // message.destroy();
        // message.error('Token expired. Please login again.');
      }
      return console.log(
        `[Response error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    message.destroy();
    message.error('Network error');
    Sentry.captureException(new Error(`[Network error]: ${networkError}`));
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = createHttpLink({
  credentials: 'include',
  uri: process.env.REACT_APP_SERVER_GRAPH_URL,
});
const helpLink = createHttpLink({
  credentials: 'include',
  uri: process.env.REACT_APP_HELP_SERVER_GRAPH_URL,
});

// add the authorization to the headers
const authorizationLink = setContext((request, previousContext) => {
  const authorizationToken = localStorage.getItem('token');
  return {
    headers: {
      authorization: authorizationToken || null,
    },
  };
});

const wsLink = new WebSocketLink(
  new SubscriptionClient(process.env.REACT_APP_SERVER_SOCKET_URL, {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    async connectionParams() {
      const authorizationToken = localStorage.getItem('token');
      return {
        authorization: authorizationToken || null,
      };
    },
  })
);

const subscriptionLink = new WebSocketLink(
  new SubscriptionClient(process.env.REACT_APP_SUBSCRIPTION_GRAPH_URL, {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    async connectionParams() {
      const authorizationToken = localStorage.getItem('token');
      return {
        authorization: authorizationToken || null,
      };
    },
  })
);

// const retryLink = new RetryLink({
//   attempts: (count, operation, error) => !!error && operation.operationName !== 'specialCase',
//   delay: (count, operation, error) => count * 100000 * Math.random(),
// });

window.addEventListener('beforeunload', () => {
  // @ts-ignore - the function is private in typescript
  wsLink.subscriptionClient.close();
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const uploadLink = createUploadLink({
  credentials: 'include',
  uri: process.env.REACT_APP_SERVER_GRAPH_URL,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    removeTypenameMiddleware,
    responseMessageLink,
    authorizationLink,
    errorLink,
    uploadLink,
    link,
  ]),
  name: 'Undiffer',
});

export const helpClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([helpLink]),
  name: 'Undiffer-help-server',
});

export const subscriptionClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([subscriptionLink]),
});

export default client;
