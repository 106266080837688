import NotificationDefault from 'Widgets/Notifications/NotificationDefault/NotificationDefault';
import history from '../../history';

export const notificationWidgets = {
  NEW_BLOCKER: {
    title: () => 'New comment on your blocker',
    icon: () => 'message-circle-outline',
    component: NotificationDefault,
  },
};
