import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    role
    profileImage
    username
  }
`;

export const groupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    color
    type
  }
`;

export const workUpdateFragment = gql`
  fragment WorkUpdateFragment on WorkUpdate {
    id
    title
    content
    data
    groups{
      ...GroupFragment
    }
    createdBy{
      ...UserFragment
    }
    createdAt
    updatedAt
    updatableType
    updatableId
    type
  }
  ${userFragment}
  ${groupFragment}
`;

export const workUpdateUserFragment = gql`
  fragment WorkUpdateUserFragment on WorkUpdateUser {
    id
    isViewed
    isNotified
    viewedAt
    workUpdate {
      ...WorkUpdateFragment
    }
    createdAt
    updatedAt
  }
  ${workUpdateFragment}
`;
