import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import _concat from 'lodash/concat';
import { Input, Row, Spin } from 'antd';
import { CloseCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { PlusOutline, CloseCircle } from 'assets';
import Avatar from 'atoms/Avatar';
import { EMPTY_STRING, EMPTY_ARRAY } from 'utils/constants';
import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import {
  Member,
  PopoverContent,
  UserProfile,
  AvatarSpan,
  Delete,
  Widget,
} from './AntStyled';

const UsersWidgetAddRemove = ({
  alreadyMembers = [],
  addUsers = [],
  createGroupUser,
  deleteGroupUser,
  spinLoader = false,
  modify,
  setIsPopoverOpen,
}) => {
  const [users, setUsers] = useState(EMPTY_ARRAY);
  const [search, setSearch] = useState(EMPTY_STRING);
  const [searchUsers, setSearchUsers] = useState(EMPTY_ARRAY);

  useEffect(() => {
    const newData = _map(alreadyMembers, (dataItem) =>
      dataItem?.user
        ? {
            ...dataItem?.user,
            isMember: true,
          }
        : {
            ...dataItem,
            isMember: true,
          }
    );
    const mergeUsers = [...newData];
    setUsers(mergeUsers);
  }, [addUsers, alreadyMembers]);

  useEffect(() => {
    setSearchUsers(
      users.filter(
        (item) =>
          item?.email?.toLowerCase().includes(search.toLowerCase()) ||
          item?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
          item?.lastName?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, users]);

  const handlePopover = () => {
    setIsPopoverOpen && setIsPopoverOpen(true);
  };

  const usersData = _map(searchUsers, (member, index) => (
    <UserProfile key={index}>
      <Widget
        role="button"
        tabIndex={0}
        className={`row-member ${member?.isMember && 'delete-able'}`}
        onClick={() => {
          modify && !member?.isMember && createGroupUser(member?.id);
          handlePopover();
        }}
      >
        {' '}
        <div className="avatar">
          <Avatar
            profile={member?.profileImage}
            name={member?.firstName || member?.lastName || member?.email}
            size={29}
          />
          <AvatarSpan className={member?.isMember ? 'avtBold' : ''}>
            <div className="name">
              {member?.firstName || ''} {member?.lastName || ''}
            </div>
          </AvatarSpan>
        </div>
      </Widget>
    </UserProfile>
  ));

  return (
    <Spin spinning={spinLoader}>
      <PopoverContent>
        {/* <Row>
          <Input
            className="popover-input"
            placeholder="Search"
            onChange={e => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
            allowClear
          />
        </Row> */}
        {usersData && usersData.length > 0 ? (
          <Member>{usersData}</Member>
        ) : (
          <Row justify="center">No users</Row>
        )}
      </PopoverContent>
    </Spin>
  );
};

export default UsersWidgetAddRemove;
