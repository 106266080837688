import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    profileImage
  }
`;

export const groupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    color
    type
    members{
      user{
        ...UserFragment
      }
    }
    managers{
      user{
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;
