import { Layout as AntLayout } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { scrollYContainer } from 'design/Styled';
import { MenuExpand } from 'assets';

const { Sider: AntSider } = AntLayout;

export const Icon = styled.img`
  filter: invert(96%) sepia(8%) saturate(61%) hue-rotate(177deg) brightness(96%)
    contrast(103%);
`;

export const Sider = styled(AntSider)`
  /*need to use !important as antd uses inline CSS */
  min-width: 201px !important;
  height: 100%;
  background-color: ${Colors.PrimaryDark};
  .ant-menu {
    font-size: ${fonts.small.size};
    background: ${Colors.SidebarBackground};
  }
  .ant-menu-item:active {
    background: ${Colors.SidebarBackground};
    opacity: 0.75;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${Colors.SidebarActiveItem};
    color: ${Colors.TextAccent};
    ${Icon} {
      filter: invert(81%) sepia(63%) saturate(294%) hue-rotate(343deg)
        brightness(106%) contrast(99%);
    }
  }
  .ant-layout-sider-trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Colors.SidebarTriggerBlock};
    color: ${Colors.SidebarText};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    min-width: 201px;
    height: 48px;
  }

  .ant-menu-item .icon {
    margin-right: ${`calc(${Spacings.xSmall.margin} - 4px )`};
    line-height: 1;
    & svg {
      transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  .ant-menu-item {
    height: initial;
    padding: 10px;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 10px !important;
    line-height: 22px;
    font-size: ${fonts.small.size};
    border-radius: 8px;
    color: ${Colors.SidebarText};
    display: flex;
    align-items: center;
    & .icon {
      line-height: 0;
      fill: ${Colors.SidebarText};
    }
    :hover {
      color: ${Colors.TextAccent};
      background-color: ${Colors.SidebarActiveItem};
      .icon {
        fill: ${Colors.TextAccent};
      }
      ${Icon} {
        filter: invert(81%) sepia(63%) saturate(294%) hue-rotate(343deg)
          brightness(106%) contrast(99%);
      }
    }
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-item {
    margin-bottom: ${`calc(${Spacings.xSmall.margin} - 4px )`};
  }
  .ant-menu-item::after {
    display: none;
  }
  .ant-menu-item-selected,
  .ant-menu-item-selected .icon {
    fill: ${Colors.TextAccent};
  }
  &.ant-layout-sider {
    background: ${Colors.SidebarBackground};
  }

  & li > span {
    font: ${fonts.small.regular};
  }
  & .ant-menu-inline-collapsed {
    width: 100%;
  }
  &.ant-layout-sider-collapsed {
    min-width: 70px !important;
    max-width: 70px !important;
    width: 70px !important;
  }
  &.ant-layout-sider-collapsed .ant-layout-sider-trigger {
    min-width: 70px !important;
    max-width: 70px !important;
    width: 70px !important;
  }
  &.ant-layout-sider-collapsed .ant-menu-item .anticon {
    margin-right: 0;
  }

  .ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: 50%;
    right: -16.5px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 16px;
    height: 48px;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid ${Colors.Border};
    background: ${Colors.IconWrapper};
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .back-button {
    margin-bottom: ${Spacings.xSmall.margin};
    width: 100%;
    font: ${fonts.medium.regular};
    height: 40px;

    & button {
      border-radius: 8px;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      text-align: left;
      padding: 0 7px;
      & svg {
        margin-right: ${Spacings.xxSmall.margin};
        transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        fill: inherit;
        min-height: 24px;
        min-width: 24px;
      }
      :hover {
        border-color: ${Colors.TextAccent};
        background: ${Colors.TextAccent};
      }
    }
  }
`;

export const MenuTitle = styled.div`
  font: ${fonts.xxLarge.regular};
  padding: ${Spacings.small.padding};
  color: ${Colors.Text};
  .ant-menu-inline .ant-menu-item:last-child {
    padding-bottom: ${Spacings.small.padding};
  }
`;

export const StyledDivider = styled.div`
  border: 2px solid #515983;
  border-radius: 15px;
  margin: 0px 0px Calc(${Spacings.xSmall.margin} - 4px) 0px;
`;

export const SubMenuTitle = styled.div`
  padding-top: ${Spacings.xxSmall.padding};
  padding-bottom: Calc(${Spacings.xxSmall.padding} + 4px);
  font: ${fonts.small.regular};
  color: ${Colors.SidebarText};
`;
export const GlobalStyles = createGlobalStyle`
  .main-sidebar-block-button {
    position: absolute !important;
    top: 50% !important;
    -ms-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
    width: 20px !important;
    height: 48px !important;
    border-radius: 0px 8px 8px 0px !important;
    border: 1px solid #cad6e3 !important;
    background: #dfe4ea !important;
    padding: 0px !important;
    background-image: url(${MenuExpand})!important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    .anticon {
      display: none;
    }
  }
`;
export const MenuItemsWrapper = styled.div`
  padding: ${`calc(${Spacings.medium.padding} / 2) calc(${Spacings.medium.padding} / 2) 0`};
  overflow: auto;
  overflow-x: hidden;
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }

  .ant-divider-horizontal {
    margin: ${Spacings.xxSmall.margin} 0;
  }
`;

export const LogoWrapper = styled.div`
  height: 48px;
  background-color: ${Colors.PrimaryColor};
  justify-content: center;
  display: flex;
  & .logo-text {
    width: 55%;
    cursor: pointer;
  }
  & .only-logo {
    width: 35%;
    cursor: pointer;
  }
`;
export const Arrow = styled.img`
  transform: ${({ rotate }) => `rotate(${rotate}deg)!important`};
  top: 42% !important;
  transition: 0.2s transform ease-in;
`;

export const Expand = styled.i`
  fill: white;
  justify-self: flex-end;
`;
export const MenusContent = styled.div`
  ${scrollYContainer};
  height: Calc(100vh - 50px);
  background: ${Colors.SidebarBackground};
  padding: ${`calc(${Spacings.medium.padding} / 2) calc(${Spacings.medium.padding} / 2) 0`};
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }

  .text {
    padding-left: 14px;
  }

  .ant-menu-submenu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px !important;
    color: ${Colors.SidebarText};
    font-size: ${fonts.small.size};
  }
  .ant-divider-horizontal {
    margin: ${Spacings.xxSmall.margin} 0;
  }
`;

export const MenuWrapper = styled.div`
  padding: ${Spacings.medium.padding}
    ${`calc(${Spacings.small.padding} - 4px )`};
  a {
    color: ${Colors.Text};
  }
  & .logout a {
    color: ${Colors.TextWarning};
  }
`;
export const NameWrapper = styled.div`
  font-weight: 600;
  font-size: ${fonts.large.size};
  color: ${Colors.TextPrimary};
  white-space: nowrap;
  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${Spacings.xxSmall.margin};
`;
export const CompanyWrapper = styled.div`
  color: ${Colors.Text};
  white-space: nowrap;
  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CompanyLogo = styled.img`
  width: 38px;
  height: 36px;
  margin-right: ${Spacings.xxSmall.margin};
  border-radius: 4px;
`;

export const DropDownContent = styled.div`
  font: ${fonts.small.regular};
  .user {
    padding-bottom: ${Spacings.xxSmall.padding};
    font-weight: bold;
  }

  .anticon {
    margin-right: ${Spacings.xSmall.margin};
  }

  .ant-divider-horizontal {
    margin: ${Spacings.xSmall.margin} 0;
  }
`;

export const Menu = styled.div`
  padding-bottom: ${Spacings.small.padding};
  color: ${Colors.Text};
  &:last-child {
    padding-bottom: 0;
  }
`;

export const PopoverChildWrapper = styled.div`
  position: absolute;
  bottom: 3px;
  width: 100%;
  background-color: ${Colors.PrimaryDark};
  padding: ${`18px calc(${Spacings.medium.padding} / 2)`};
  display: flex;
`;
