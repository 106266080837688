import styled from 'styled-components';
import { Layout, Typography } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;
const { Title } = Typography;

export const Text = styled(Title)`
  font-size: 14px !important;
  color: ${Colors.Text}!important;
  margin-top: ${Spacings.xSmall.margin};
  line-height: 26px;
  margin-bottom: ${Spacings.xSmall.margin}!important;
`;
export const Arrow = styled.img`
  cursor: pointer;
  margin-right: ${Spacings.xSmall.margin}!important;
`;

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  font: ${fonts.large.bold};
  color: ${Colors.Text};
  cursor: default;
  .anticon {
    margin-right: ${Spacings.xSmall.margin};
  }
`;

export const Content = styled(AntContent)`
  display: flex;
  justify-content: center;

  &.ant-layout-content {
    padding: ${Spacings.small.padding};
    overflow-y: hidden;
    .ant-btn-primary {
      line-height: 22px;
    }
  }
  .or {
    margin: Calc(${Spacings.xSmall.margin} - 4px) 0px;
    font: ${fonts.medium.regular};
    color: ${Colors.Text};
    line-height: 26px;
  }
  .card {
    max-width: 700px;
    height: fit-content;
  }
  @media (max-width: 1300px) {
    .card {
      max-width: 600px;
    }
  }
  .ant-table-row {
    cursor: pointer;
  }
`;
