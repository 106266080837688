/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Popover, Skeleton } from 'antd';
import { titleCase } from 'utils/stringUtilities';
import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  LEAVE_STATUS,
  EMPTY_ARRAY,
  UNDEFINED,
  FETCH_POLICY,
  PRIVATE_ROUTE,
} from 'utils/constants';
import UsersListWidgetAsync from 'organisms/UsersListWidgetAsync';
import _sortBy from 'lodash/sortBy';
import _concat from 'lodash/concat';
import _map from 'lodash/map';
import moment from 'moment';
import CommentsPopover from 'organisms/CommentsPopover';
import Table from 'atoms/Table';
import UserProfile from 'molecules/UserProfile';
import DateTime from 'atoms/DateTime';
import ContentWrapper from 'atoms/ContentWrapper';
import {
  Leaves1,
  Leaves2,
  Leaves3,
  Leaves4,
  Leaves5,
} from 'assets';
import Header from 'layout/Header';
import InfoIcon from 'atoms/InfoIcon';
import { FormattedMessage } from 'react-intl';
import Filters from 'molecules/Filters';
import queryString from 'query-string';
import _findIndex from 'lodash/findIndex';
import client from 'apollo';
import { GET_LEAVE_REQUESTS } from 'features/people/graphql/Leaves/Queries';
import { useQuery } from '@apollo/client';
import GroupTagList from 'organisms/GroupTagList';
import { getTrimValues } from 'utils';
import { UPDATE_LEAVE_REQUEST } from 'features/people/graphql/Leaves/Mutation';
import LeavesPopover from './LeavesPopover';
import {
  Content, Comments, TimeDiv, Icon,
} from './AntStyled';


const LeaveRequest = (props) => {
  const { history } = props;
  const { id, d } = queryString.parse(history);
  const [groupId, setGroupId] = useState(id ? [parseInt(id, 10)] : EMPTY_ARRAY);
  const [userId, setUserId] = useState(UNDEFINED);
  const [date, setDate] = useState(
    d
      ? [moment(d, 'MM-DD-YYYY'), moment(d, 'MM-DD-YYYY')]
      : [moment().startOf('isoWeek'), moment().endOf('isoWeek')]
  );
  const [leaveRequests, setLeaveRequests] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowId, setRowId] = useState();

  const { data: leaveRequestData, loading: leaveLoading } = useQuery(
    GET_LEAVE_REQUESTS,
    {
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      variables: {
        where: {
          userId: userId && userId.length > 0 ? userId : UNDEFINED,
          from: date && date.length > 0 ? date[0].format('YYYY-MM-DD') : moment().startOf('isoWeek').format('YYYY-MM-DD'),
          to: date && date.length > 0 ? date[1].format('YYYY-MM-DD') : moment().endOf('isoWeek').format('YYYY-MM-DD'),
          groupId: groupId.length !== 0 ? groupId : UNDEFINED,
        },
      },
    }
  );

  useEffect(() => {
    if (leaveRequestData && leaveRequestData.leaveRequests) {
      let leaveTableData = [];
      leaveTableData = _map(leaveRequestData.leaveRequests.leaveRequest, (leaveData, key) => ({
        key: key.toString(),
        leaveId: leaveData.id,
        user: leaveData.user,
        leaveType: leaveData.leaveTypes,
        requestedOn: leaveData.createdAt,
        from: leaveData.from,
        to: leaveData.to,
        managers: leaveData.assignments,
        status: leaveData.status,
        comments: leaveData.comments,
        description: leaveData.description,
        isCancelled: leaveData.isCancelled,
        department: leaveData.user && leaveData.user.userGroups,
        dayType: leaveData.leaves && leaveData.leaves.length && leaveData.leaves[0].dayType,
      }));
      setLeaveRequests(leaveTableData);
    }
  }, [leaveRequestData]);

  const Status = ({
    status = LEAVE_STATUS.PENDING,
  }) => {
    let showStatus;
    switch (status) {
      case LEAVE_STATUS.APPROVED:
        showStatus = <Icon src={Leaves1} alt="icons"></Icon>;
        break;
      case LEAVE_STATUS.CANCEL:
        showStatus = <Icon src={Leaves2} alt="icons"></Icon>;
        break;
      case LEAVE_STATUS.HOLD:
        showStatus = <Icon src={Leaves4} alt="icons"></Icon>;
        break;
      case LEAVE_STATUS.DELETED:
        showStatus = <Icon src={Leaves5} alt="icons"></Icon>;
        break;
      case LEAVE_STATUS.REJECTED:
        showStatus = <Icon src={Leaves5} alt="icons"></Icon>;
        break;
      default:
        showStatus = <Icon src={Leaves3} alt="icons"></Icon>;
        break;
    }
    return showStatus;
  };

  const renderGroups = (userGroups) => {
    const group = [];
    _map(userGroups, items => group.push(items.group));
    return <GroupTagList limit={1} departments={group} />;
  };

  const approveRejectLeave = async (val, setLoading) => {
    const values = getTrimValues(val);
    const index = _findIndex(leaveRequests, { leaveId: rowId });
    const rowData = leaveRequests[index];
    try {
      setLoading(true);
      await client.mutate({
        mutation: UPDATE_LEAVE_REQUEST,
        variables: {
          data: {
            id: rowData.leaveId,
            description: values.rejectReason,
            leaveTypes: rowData.leaveType,
            status: values.status,
            fromDayType: rowData.fromDayType,
            toDayType: rowData.toDayType,
            assignmentIds: parseInt(rowData.managers[0].id, 10),
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_LEAVE_REQUESTS,
            fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
            variables: {
              where: {
                userId: userId && userId.length > 0 ? userId : UNDEFINED,
                from: date && date.length > 0 ? date[0].format('YYYY-MM-DD') : moment().startOf('isoWeek').format('YYYY-MM-DD'),
                to: date && date.length > 0 ? date[1].format('YYYY-MM-DD') : moment().endOf('isoWeek').format('YYYY-MM-DD'),
                groupId: groupId.length !== 0 ? groupId : UNDEFINED,
              },
            },
          },
        ],
      }).then(() => {
        setLoading(false);
        setIsModalVisible(false);
      }).catch((err) => {
        setLoading(false);
        setIsModalVisible(false);
        console.log(err);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      title: 'Name',
      dataIndex: 'user',
      key: 'user',
      // eslint-disable-next-line no-nested-ternary
      render: (user, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : user ? (
        <UserProfile
          profile={user.profileImage}
          name={
            user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : user.email
          }
          userId={user.id}
          showLink
        />
      ) : (
        '-'
      ),
    },
    {
      title: 'Group',
      dataIndex: 'department',
      key: 'department',
      width: '12%',
      render: (userGroups, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : renderGroups(userGroups),
    },
    {
      title: 'Requested On',
      dataIndex: 'requestedOn',
      key: 'requestedOn',
      width: '10%',
      render: (requestedOnDate, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : (
        <DateTime date={requestedOnDate} />
      ),
    },
    {
      title: 'Type',
      dataIndex: 'leaveType',
      key: 'type',
      width: '9%',
      editable: true,
      render: (type, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : (
        titleCase(type)
      ),
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      width: '10%',
      editable: true,
      render: (fromdate, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : (
        <span>
          <DateTime date={fromdate} />
          <TimeDiv justify="end">
            {rowData.dayType && rowData.dayType}
          </TimeDiv>
        </span>
      ),
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      width: '12%',
      editable: true,
      render: (todate, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : (
        <span>
          <DateTime date={todate} />
          <TimeDiv justify="end">
            {rowData.dayType && rowData.dayType}
          </TimeDiv>
        </span>
      ),
    },
    {
      title: 'Reporting Managers',
      dataIndex: 'managers',
      key: 'managers',
      width: '18%',
      render: (row, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : (
        <UsersListWidgetAsync
          skip={2}
          placement="bottom"
          data={_sortBy(rowData.managers, 'user.firstName')}
          alreadyMembers={_concat(rowData.managers, rowData.members)}
          profileSize={25}
        />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : (
        <Status status={rowData.isCancelled ? LEAVE_STATUS.DELETED : LEAVE_STATUS[`${status}`]} />
      ),
    },
    {
      dataIndex: 'comments',
      key: 'comments',
      width: '5.5%',
      render: (reason, rowData) => rowData.loading ? (
        <Skeleton paragraph={{ rows: 1 }} title={false} active />
      ) : (
        <Comments>
          <CommentsPopover actionId={rowData.leaveId} data={rowData.comments} commentableType="LeaveRequest" />
        </Comments>
      ),
    },
    {
      dataIndex: 'description',
      key: 'description',
      width: '4.5%',
      render: (row, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : (
        <Popover content={row} title="Reason">
          <InfoCircleTwoTone />
        </Popover>
      ),
    },
    {
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      render: (row, rowData) => rowData.loading ? <Skeleton paragraph={{ rows: 1 }} title={false} active /> : (
        <span>
          <LeavesPopover
            approveRejectLeave={approveRejectLeave}
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            rowData={rowData}
            setRowId={setRowId}
          />
        </span>
      ),
    },
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];

  return (
    <Content>
      <Header
        title={(
          <InfoIcon
            title={<FormattedMessage id="Leaves.request" />}
          />
        )}
      />
      <Filters
        masterFilter
        daysFilter
        groupsFilters={setGroupId}
        setDateFilterValue={setDate}
        dateFilterValue={date}
        groupValue={groupId}
        setUsersValue={setUserId}
        showUsersFilter
        usersValue={userId}
        showTaskFilter
      />
      <ContentWrapper>
        <div style={{ maxHeight: 'calc(100vh - 99px - 30px)', overflow: 'auto' }}>
          <Table
            columns={columns}
            dataSource={leaveRequests}
            height="745px"
            loading={leaveLoading}
            isInfinite
            className="bb-0"
          />
        </div>
      </ContentWrapper>
    </Content>
  );
};

export default {
  key: PRIVATE_ROUTE.LEAVES_REQUEST,
  component: LeaveRequest,
  name: 'Leave Requests',
  icon: <i data-eva="bar-chart-outline" data-eva-height="20" data-eva-width="20"></i>,
  abilitySubject: 'leave request',
  abilityAction: 'manage',
};
