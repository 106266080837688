import React, { useEffect } from 'react';
import { Form, message } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import client from 'apollo';
import Button from 'atoms/Button';
import Meta from 'atoms/Meta';
import Card from 'atoms/Card';
import Input from 'atoms/Input';
import { RESET_PASSWORD } from 'graphql/Onboarding/Mutations';
import {
  BUTTON_TYPE,
  PUBLIC_ROUTE,
  META_TYPE,
  FORM_NAME,
  REGEX,
} from 'utils/constants';
import * as Sentry from '@sentry/react';
import { useForm } from 'antd/lib/form/util';
import { UndifferLogo } from 'assets';
import { NavLink } from 'react-router-dom';
import { Container, Wrapper, Title, Background, Logo } from './AntStyled';

const ResetPassword = (props) => {
  const {
    match: {
      params: { token },
    },
    history,
    intl,
  } = props;
  const [form] = useForm();

  useEffect(() => {
    history.push(`${PUBLIC_ROUTE.RESET_PASSWORD}/${token}`);
  }, [history, token]);

  const onFinish = async (values) => {
    const { password, newPassword } = values;
    if (password === newPassword) {
      try {
        await client
          .mutate({
            mutation: RESET_PASSWORD,
            variables: {
              data: {
                token,
                newPassword,
              },
            },
          })
          .then(() => {
            message.destroy();
            message.success(
              intl.formatMessage({ id: 'resetPassword.successMessages.change' })
            );
            history.push(PUBLIC_ROUTE.LOGIN);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      message.error(
        intl.formatMessage({ id: 'resetPassword.errorMessages.confirm' })
      );
    }
  };

  const handleFieldsChange = (e) => {
    form.setFieldsValue({ [e?.[0]?.name[0]]: e?.[0]?.value?.trim() });
  };

  return (
    <Container>
      <Background>
        <Logo src={UndifferLogo} alt="logo"></Logo>
        <NavLink to="/help" className="bottom-right">
          <FormattedMessage id="terms.help" />
        </NavLink>
        <NavLink to="/copyright" className="bottom-left">
          <FormattedMessage id="terms.copyright" />
        </NavLink>
      </Background>
      <Wrapper>
        <Card padding="30px 20">
          <Meta title={META_TYPE.RESET_PASSWORD} />
          <Form
            form={form}
            onFieldsChange={handleFieldsChange}
            name={FORM_NAME.BASIC}
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Title>
              <FormattedMessage id="resetPassword.resetTitle" />
            </Title>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.errorMessage" />
                  ),
                },
                {
                  whitespace: true,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.errorMessage" />
                  ),
                },
                {
                  pattern: REGEX.PASSWORD,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.passwordPattern" />
                  ),
                },
              ]}
            >
              <Input type="password" placeholder="Password" allowClear />
            </Form.Item>
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.errorMessage" />
                  ),
                },
                {
                  min: 6,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.minCharacters" />
                  ),
                },
                {
                  whitespace: true,
                  message: (
                    <FormattedMessage id="resetPassword.formRules.password.errorMessage" />
                  ),
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Confirm password"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                accent
                primary
                type={BUTTON_TYPE.PRIMARY}
                htmlType={BUTTON_TYPE.SUBMIT}
                className="right"
                name={<FormattedMessage id="resetPassword.updatePassword" />}
              />
            </Form.Item>
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default injectIntl(ResetPassword);
