import React from 'react';
import styled, { css } from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { Button as AntButton } from 'antd';
import Variables from 'design/Variables';
import Spacings from 'design/Spacings';

const StyledButton = styled(AntButton)`
  border-color: ${({ border }) => border || Colors.ButtonPrimary};
  background: ${({ background }) => background || Colors.ButtonPrimary};
  color: ${({ color }) => color || Colors.TextLight};
  text-align: center;
  height:auto;
  padding: Calc(${Spacings.xxSmall.padding} - 3px) ${Spacings.xSmall.padding};
  border-radius: ${Variables.AtomRadius};
  line-height: ${({ lineHeight }) => lineHeight || Variables.LineHeightMd};
  cursor:pointer;
  &:hover {
    border-color: ${Colors.ButtonPrimaryHover};
    background: ${({ focus }) => focus || Colors.ButtonPrimaryHover};
    color: ${({ color }) => color || Colors.TextLight};
  }
  &:focus {
    border-color: ${Colors.ButtonPrimaryClicked};
    background: ${({ focus }) => focus || Colors.ButtonPrimaryClicked};
    color: ${({ color }) => color || Colors.TextLight};
  }
  &.ant-btn-primary[disabled] {
    border-color: ${({ border, primary }) =>
      border || primary ? Colors.ButtonPrimary : Colors.ButtonSecondary};
    background: ${({ background, primary }) =>
      background || primary ? Colors.ButtonPrimary : Colors.ButtonSecondary};
    color: ${({ color, primary }) =>
      color || primary ? Colors.TextLight : Colors.TextPrimary};
  }
  &.ant-btn[disabled]{
    border-color: ${({ border, primary }) =>
      border || primary ? Colors.ButtonPrimary : Colors.ButtonSecondary};
    background: ${({ background, primary }) =>
      background || primary ? Colors.ButtonPrimary : Colors.ButtonSecondary};
    color: ${({ color, primary }) =>
      color || primary ? Colors.TextLight : Colors.TextPrimary};
  }
  .ant-btn-loading-icon .anticon {
    padding: 2px 0 0 0;
  }
  span.name {
    padding-left: ${({ loading }) => (loading ? '8px' : 0)};
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: ${fonts.small.size};
    color: inherit;
    font: inherit;
  }
  ${(props) =>
    props?.primary &&
    css`
      border-color: ${Colors.ButtonPrimary};
      background: ${Colors.ButtonPrimary};
      color: ${Colors.TextLight};
      height: auto;
      text-align: center;
      opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
      &:hover {
        border-color: ${Colors.ButtonPrimaryHover};
        background: ${Colors.ButtonPrimaryHover};
        color: ${Colors.TextLight};
      }
      &:focus {
        border-color: ${Colors.ButtonPrimaryClicked};
        background: ${Colors.ButtonPrimaryClicked};
        color: ${Colors.TextLight};
      }
    `}
   ${(props) =>
     props?.secondary &&
     css`
       border-color: ${Colors.ButtonSecondary};
       background: ${Colors.ButtonSecondary};
       color: ${Colors.TextPrimary};
       text-align: center;
       height: auto;
       &:hover {
         border-color: ${Colors.ButtonSecondaryHover};
         background: ${Colors.ButtonSecondaryHover};
         color: ${Colors.TextPrimary};
       }
       &:focus {
         border-color: ${Colors.ButtonSecondary};
         background: ${Colors.ButtonSecondary};
         color: ${Colors.TextPrimary};
       }
       opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
     `}
    ${(props) =>
      props?.accent &&
      css`
        border-color: ${Colors.ButtonAccent};
        background: ${Colors.ButtonAccent};
        color: ${Colors.TextPrimary};
        text-align: center;
        height: auto;
        &:hover {
          border-color: ${Colors.ButtonAccentHover};
          background: ${Colors.ButtonAccentHover};
          color: ${Colors.TextPrimary};
        }
        &:focus {
          border-color: ${Colors.ButtonAccentClicked};
          background: ${Colors.ButtonAccentClicked};
          color: ${Colors.TextPrimary};
        }
        opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
      `}
      ${(props) =>
        props?.iconbtn &&
        css`
          background: ${Colors.SidebarText};
          border: 0px;
          width: 92px;
          padding: unset;
          height: 44px;
          text-align: center;
          border-radius: ${Variables.AtomRadius};
          margin-right: ${Spacings.small.margin};
          opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            border-color: none;
            background: ${Colors.SidebarText};
            color: none;
          }
          &:focus {
            border-color: none;
            background: none;
            color: none;
          }
        `}

        ${(props) =>
          props?.tableButton &&
          css`
            font-size: 12px !important;
            line-height: ${Variables.LineHeightSm} !important;
            border-radius: 4px;
            padding: 1px ${Spacings.xxSmall.padding};
          `}

        ${(props) =>
          props?.rounded &&
          css`
            width: 80px;
            height: 22px;
            font: ${fonts.xSmall.medium};
            border-radius: ${Variables.AtomRadius};
            text-align: center;
            border: none;
            line-height: 14px;
            margin: 2px;
            &:hover {
              background-color: ${Colors.TextLight};
              color: ${Colors.PrimaryColor};
              border-color: transparent;
              box-shadow: 0px 4px 10px rgba(81, 89, 131, 0.3);
            }
            &:focus {
              background-color: ${Colors.TextLight};
              border-color: ${Colors.TextLight};
              color: ${Colors.PrimaryColor};
              box-shadow: 0px 4px 10px rgba(81, 89, 131, 0.3);
            }
            &:active {
              box-shadow: 0px 4px 10px rgb(81 89 131 / 30%) !important;
            }
          `}
          ${(props) =>
            props?.outline &&
            css`
              border: 2px dashed ${Colors.TextEnable};
              color: ${Colors.TextEnable};
              background: ${Colors.TextLight};
              &:hover {
                border: 2px dashed ${Colors.TextEnable};
                color: ${Colors.TextEnable};
                background: ${Colors.TextLight};
              }
              &:focus {
                border: 2px dashed ${Colors.TextEnable};
                color: ${Colors.TextEnable};
                background: ${Colors.TextLight};
              }
            `}
          ${(props) =>
            props?.add &&
            css`
              border: none;
              color: ${Colors.TextSecondary};
              background: ${Colors.Transparent};
              font: ${fonts.xSmall.medium};
              &:hover {
                border: none;
                color: ${Colors.TextSecondary};
                background: ${Colors.Transparent};
                font: ${fonts.xSmall.medium};
              }
              &:focus {
                border: none;
                color: ${Colors.TextSecondary};
                background: ${Colors.Transparent};
                font: ${fonts.xSmall.medium};
              }
            `}
          &.a3 {
    border-color: ${Colors.ButtonAccentClicked};
    background: ${Colors.ButtonAccentClicked};
    color: ${Colors.TextPrimary};
  }
  &.ant-btn-link {
    color: #5F3996;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    padding: 0px;
    border:0px;
    display:flex; 
    align-items: center;
    width:fit-content;
  }
`;

const SolidButton = styled(AntButton)`
  font: ${fonts.small.regular};
  background: ${({ background }) => background || Colors.ButtonPrimary};
  border-color: ${({ border }) => border || Colors.ButtonPrimary};
  color: ${({ color }) => color || Colors.TextLight};
  cursor: pointer;
  :hover,
  :focus {
    background: ${({ background }) => background || Colors.ButtonPrimary};
    border-color: ${({ border }) => border || Colors.ButtonPrimary};
    color: ${({ color }) => color || Colors.TextLight};
  }
  .ant-btn-loading-icon .anticon {
    padding: 2px 0 0 0;
  }
  span.name {
    padding-left: ${({ loading }) => (loading ? '8px' : 0)};
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: inherit;
    font: inherit;
  }
  // Storybook variations
  &.p1 {
    border-color: ${Colors.ButtonPrimary};
    background: ${Colors.ButtonPrimary};
    color: ${Colors.Container};
  }
  &.p2 {
    border-color: ${Colors.TextPrimaryHover};
    background: ${Colors.TextPrimaryHover};
    color: ${Colors.Container};
  }
  &.p3 {
    border-color: ${Colors.PrimaryDark};
    background: ${Colors.PrimaryDark};
    color: ${Colors.Container};
  }

  &.s1 {
    border-color: ${Colors.SecondaryColor};
    background: ${Colors.SecondaryColor};
    color: ${Colors.TextPrimary};
  }
  &.s2 {
    border-color: ${Colors.ButtonSecondaryHover};
    background: ${Colors.ButtonSecondaryHover};
    color: ${Colors.TextPrimary};
  }
  &.s3 {
    border-color: ${Colors.ButtonSecondaryClicked};
    background: ${Colors.ButtonSecondaryClicked};
    color: ${Colors.TextPrimary};
  }

  &.a1 {
    border-color: ${Colors.ButtonAccent};
    background: ${Colors.ButtonAccent};
    color: ${Colors.TextPrimary};
  }
  &.a2 {
    border-color: ${Colors.ButtonAccentHover};
    background: ${Colors.ButtonAccentHover};
    color: ${Colors.TextPrimary};
  }
  &.a3 {
    border-color: ${Colors.ButtonAccentClicked};
    background: ${Colors.ButtonAccentClicked};
    color: ${Colors.TextPrimary};
  }
`;

const Button = ({
  tableButton,
  keyProp,
  background,
  border,
  disabled,
  block,
  className,
  color,
  htmlType,
  focus,
  name,
  link,
  icon,
  onClick,
  shape,
  size,
  type,
  solid,
  ghost,
  loading,
  accent,
  primary,
  secondary,
  iconbtn,
  rounded,
  outline,
  add,
  lineHeight,
}) =>
  solid ? (
    <SolidButton
      key={keyProp}
      block={block}
      disabled={disabled}
      shape={shape}
      className={className}
      type={type}
      size={size}
      background={background}
      icon={icon}
      border={border}
      color={color}
      focus={focus}
      link={link}
      htmlType={htmlType}
      onClick={onClick}
      ghost={ghost}
      loading={loading}
      tableButton={tableButton}
    >
      {name && <span className="name">{name}</span>}
    </SolidButton>
  ) : (
    <StyledButton
      key={keyProp}
      block={block}
      shape={shape}
      accent={accent}
      primary={primary}
      secondary={secondary}
      rounded={rounded}
      className={className}
      iconbtn={iconbtn}
      type={type}
      size={size}
      add={add}
      background={background}
      icon={icon}
      border={border}
      color={color}
      focus={focus}
      htmlType={htmlType}
      onClick={onClick}
      ghost={ghost}
      loading={loading}
      disabled={disabled}
      outline={outline}
      tableButton={tableButton}
      lineHeight={lineHeight}
    >
      {name && <span className="name">{name}</span>}
    </StyledButton>
  );

export default Button;
