import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SettingOutlined } from '@ant-design/icons';
import { PeopleGroup } from 'assets';
import { Icon } from './AntStyled';
export const companySetting = [
  {
    key: 'general-settings',
    action: 'manage',
    subject: 'general setting',
    icon: (
      <i
        data-eva="settings-2-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    text: <FormattedMessage id="settings.generalSetting" />,
  },
  {
    key: 'locations',
    action: 'manage',
    subject: 'location setting',
    secondaryAction: 'use',
    secondarySubject: 'multiple_locations',
    icon: (
      <i data-eva="pin-outline" data-eva-height="20" data-eva-width="20"></i>
    ),
    text: <FormattedMessage id="settings.locations" />,
  },
  {
    key: 'groups',
    action: 'manage',
    subject: 'projects',
    className: 'group-menu-item',
    icon: (
      <Icon src={PeopleGroup} alt="icon" />
    ),
    text: <FormattedMessage id="settings.groups" />,
  },
  {
    key: 'users',
    action: 'manage',
    subject: 'users',
    className: 'users-menu-item',
    icon: (
      <i data-eva="people-outline" data-eva-height="20" data-eva-width="20"></i>
    ),
    text: <FormattedMessage id="settings.users" />,
  },
  {
    key: 'integrations',
    action: 'manage',
    subject: 'integrations',
    className: 'users-menu-item',
    icon: (
      <i data-eva="cube-outline" data-eva-height="20" data-eva-width="20"></i>
    ),
    text: <FormattedMessage id="settings.integrations" />,
  },
  // {
  //   key: 'projects',
  //   action: 'manage',
  //   subject: 'projects',
  //   icon: <FolderOpenOutlined />,
  //   text: <FormattedMessage id="settings.projects" />,
  // },
  // {
  //   key: 'departments',
  //   action: 'manage',
  //   subject: 'departments',
  //   icon: <ApartmentOutlined />,
  //   text: <FormattedMessage id="settings.departments" />,
  // },
  // {
  //   key: 'quotes/active',
  //   action: 'manage',
  //   subject: 'quotes',
  //   icon: <MessageOutlined />,
  //   text: <FormattedMessage id="settings.quotes" />,
  // },
  // {
  //   key: 'billings',
  //   icon: <DollarOutlined />,
  //   text: <FormattedMessage id="settings.billings" />,
  // },
  // {
  //   key: 'my-profile/details',
  //   icon: <UserOutlined />,
  //   text: <FormattedMessage id="settings.myProfile" />,
  // },
  // {
  //   key: 'group-access-settings',
  //   action: 'manage',
  //   subject: 'access settings',
  //   icon: <SafetyCertificateOutlined />,
  //   text: <FormattedMessage id="settings.groupAccessManagement" />,
  // },
  // {
  //   key: 'danger-area',
  //   action: 'manage',
  //   subject: 'danger area',
  //   icon: <ThunderboltOutlined />,
  //   text: <FormattedMessage id="settings.dangerArea" />,
  // },
];

export const featureSetting = [
  {
    key: 'daily-goals-settings',
    action: 'manage',
    subject: 'daily goals settings',
    icon: (
      <i
        data-eva="checkmark-circle-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    text: <FormattedMessage id="dailyGoalsSettings.text" />,
  },
  {
    key: 'feedback/current',
    action: 'manage',
    subject: 'daily goals settings',
    icon: (
      <i
        data-eva="checkmark-circle-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    text: <FormattedMessage id="feedback.title" />,
  },
  {
    key: 'sops/activate',
    action: 'manage',
    subject: 'sops',
    icon: (
      <i
        data-eva="checkmark-circle-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    text: <FormattedMessage id="sops.sidebarTitle" />,
  },
  {
    key: 'people',
    action: 'manage',
    subject: 'attendance',
    icon: (
      <i
        data-eva="download-outline"
        data-eva-height="20"
        data-eva-width="20"
      ></i>
    ),
    text: <FormattedMessage id="settings.people.featureTitle" />,
  },
];

export const userSetting = [
  // commented for future use
  // {
  //   key: 'profile/details',
  //   action: 'manage',
  //   subject: 'my profiles',
  //   icon: (
  //     <i data-eva="person-outline" data-eva-height="20" data-eva-width="20"></i>
  //   ),
  //   text: <FormattedMessage id="settings.myProfile" />,
  // },
  {
    // key: 'profile-settings',
    key: 'my-profile',
    action: 'manage',
    subject: 'my profiles',
    icon: (
      <i data-eva="person-outline" data-eva-height="20" data-eva-width="20"></i>
    ),
    text: <FormattedMessage id="mainSidebar.profileSettings" />,
  },
  // {
  //   key: 'my-profile',
  //   action: 'manage',
  //   subject: 'my profiles',
  //   icon: <UserOutlined />,
  //   text: <FormattedMessage id="settings.myProfile" />,
  // },
  {
    key: 'notification-settings',
    action: 'manage',
    subject: 'notification setting',
    icon: (
      <i data-eva="bell-outline" data-eva-height="20" data-eva-width="20"></i>
    ),
    text: <FormattedMessage id="settings.notification" />,
  },
];
