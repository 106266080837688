import { gql } from '@apollo/client';
import { commentFragment } from './fragments';

export const CREATE_ACTION_COMMENT = gql`
  mutation createComment($data: CommentCreateInput!) {
    createComment(data: $data) {
      ...CommentFragment
    }
  }
  ${commentFragment}
`;

export const UPDATE_ACTION_COMMENT = gql`
  mutation updateComment($data: CommentUpdateInput, $where: CommentWhereUniqueInput!) {
    updateComment(data: $data, where: $where) {
      ...CommentFragment
    }
  }
  ${commentFragment}
`;

export const DELETE_ACTION_COMMENT = gql`
  mutation deleteComment($where: CommentWhereUniqueInput!) {
    deleteComment(where: $where) {
      ...CommentFragment
    }
  }
  ${commentFragment}
`;
