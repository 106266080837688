import styled from 'styled-components';
import { Layout } from 'antd';
import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
const { Content: AntContent } = Layout;

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Text};
  cursor: default;
  .anticon {
    margin-right: ${Spacings.xSmall.margin};
  }
`;
export const Arrow = styled.img`
  cursor: pointer;
  margin-right: ${Spacings.xSmall.margin}!important;
`;
export const Content = styled(AntContent)`
  &.ant-layout-content {
    padding: ${Spacings.medium.padding};
    overflow: auto;
  }
`;
