import { gql } from '@apollo/client';
import { groupFragment, userFragment } from './Fragments';

export const GET_GROUPS = gql`
  query groups {
    groups {
      groups {
        ...GroupFragment
      }
    }
  }
  ${groupFragment}
`;

export const GET_USERS = gql`
  query users {
    users {
      users {
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;

export const GET_GROUP_USERS = gql`
  query groupUsers($where: GroupUserWhereInput!) {
    groupUsers(where: $where) {
      id
      user {
        ...UserFragment
      }
      group {
        id
      }
      role
      createdAt
      updatedAt
    }
  }
  ${userFragment}
`;
