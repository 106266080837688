import React from 'react';
import _get from 'lodash/get';
import NotificationTemplate from 'Widgets/Notifications/NotificationTemplate';
import { notificationWidgets } from 'features';
import { Widgets } from 'Widgets';
import { Content } from './AntStyled';
export default function ({ data, clearAble, onCheck, decreaseNotification }) {
  return (
    <Content>
      {data?.length
        && data.map((notificationData) => {
          const {
            generator, createdAt, id, activity, type, isRead, updatedAt
          } = notificationData;
          const widgets = { ...Widgets, ...notificationWidgets };
          const widget = widgets[type];
          if (widget) {
            const {
              title, actions, component, icon,
            } = widget;
            return (
              <NotificationTemplate
                notificationData={notificationData}
                isRead={isRead}
                updatedAt={updatedAt}
                generator={generator}
                newData={activity?.newData}
                createdAt={createdAt}
                key={id}
                icon={icon(notificationData)}
                actions={actions}
                component={component}
                title={title(notificationData)}
                type={
                  title === 'Commented'
                    ? 'COMMENT'
                    : _get(notificationData, 'activity.newData.type')
                }
                id={id}
                onBtnClick={() => onCheck(id)}
                decreaseNotification={decreaseNotification}
                clearAble={clearAble}
                group={_get(notificationData, 'activity.newData.group')}
              />
            );
          }
        })}
    </Content>
  );
}
