import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import Variables from 'design/Variables';

export const Divider = styled.hr`
  margin-left: -16px;
  margin-right: -16px;
  border: 1px solid ${Colors.Border};
  border-bottom: 0px;
  margin-bottom: ${Spacings.xSmall.margin};
  margin-top: ${Spacings.xSmall.margin};
`;
export const StyledDiv = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #515983;
  > span {
    color: ${Colors.TextDark};
  }
`;
export const LeavesCol = styled.div`
  background: #f0e8fd;
  border: 1px solid #dacbf0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${Colors.TextSecondary};
  margin-bottom: ${Spacings.xSmall.margin};
  padding: 10px 12px;
  > span {
    color: ${Colors.TextDark};
  }
`;
export const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
