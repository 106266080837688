import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query users($where:UserWhereInput) {
    users(where:$where) {
      users {
        id
        firstName
        lastName
        email
        role
        profileImage
        contact
        username
      }
    }
  }
`;
