import { Col, Divider, Row, Space, Tag } from 'antd';
import { AppContext } from 'AppContext';
import Button from 'atoms/Button';
import DateTime from 'atoms/DateTime';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import { get } from 'lodash';
import UserProfile from 'molecules/UserProfile';
import moment from 'moment';
import GroupTagList from 'organisms/GroupTagList';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FEEDBACK_TYPE, TODO_ICONS, TODO_TYPE } from 'utils/constants';
import { Invite } from 'Widgets/Notifications/NotificationTemplate/AntStyled';

function AddFeedbackWorkUpdate({
  data,
  addToTodo,
  performTask,
  isViewed,
  createdAt,
  updatedAt,
}) {
  const {
    data: { title: feedbackTitle, type: feedbackType, endOn, hasCompleted },
    groups,
    title,
    createdBy: user,
    type,
  } = data;
  const { state } = useContext(AppContext);
  const [timeZone, setTimeZone] = useState('Asia/Kolkata');
  const userTimeZone = get(state, 'loginUser.location.timeZone', null);

  const isEnded = endOn
    ? !isViewed &&
      type === TODO_TYPE.FEEDBACK_REQUEST &&
      moment(moment(endOn).tz(timeZone)).isAfter(moment())
    : !isViewed && type === TODO_TYPE.FEEDBACK_REQUEST;

  useEffect(() => {
    if (userTimeZone) setTimeZone(userTimeZone);
  }, [userTimeZone]);
  return (
    <div>
      <div className="update-template-header">
        <Row className="title-row" align="middle">
          <Col>
            {
              TODO_ICONS[
                type === TODO_TYPE.FEEDBACK_REQUEST
                  ? feedbackType
                  : feedbackType
              ]
            }
          </Col>
          <Col className="title">{title}</Col>
          {endOn && (
            <Col className="feedback-end-date">
              <b>
                {moment(moment(endOn).tz(timeZone)).isBefore(moment())
                  ? 'Ended on'
                  : 'Ends on'}
              </b>
              <DateTime date={endOn} format={MomentFormat.at} />
            </Col>
          )}
          {type === TODO_TYPE.FEEDBACK_REQUEST &&
            moment(moment(endOn).tz(timeZone)).isBefore(moment()) && (
              <Col>
                <Invite>
                  <Tag color="error">Expired</Tag>
                </Invite>
              </Col>
            )}
        </Row>
        {user && (
          <>
            <UserProfile
              name={`${user?.firstName} ${user?.lastName}`}
              stackedName={false}
              userId={user?.id}
              size={16}
              profile={user?.profileImage}
              showLink
            />
            <div className="dot" />
          </>
        )}
        <div className="time">
          <DateTime date={isViewed ? updatedAt : createdAt} fromNow />
        </div>
      </div>
      <div className="update-template-body">{feedbackTitle}</div>
      <div className="update-template-footer">
        <div>
          <GroupTagList departments={groups} limit={3} />
        </div>
        <div>
          {/* hiding this because it is not researched fully or had it's correct purpose for  */}
          {/* {isEnded && (
            <Button
              className="template-button"
              name={<FormattedMessage id="workUpdate.addTodo" />}
              onClick={addToTodo}
            />
          )} */}
          {isEnded && (
            <Button
              name={
                <FormattedMessage
                  id={
                    feedbackType === FEEDBACK_TYPE.POLL
                      ? 'workUpdate.ansPoll'
                      : 'workUpdate.takeSurvey'
                  }
                />
              }
              onClick={performTask}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AddFeedbackWorkUpdate;
