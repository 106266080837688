import GoogleIcon from './logo/google.png';
import MicrosoftIcon from './logo/microsoft-office.png';
import SlackIcon from './Icons/slack-icon.svg';
import MicrosoftTeams from './Icons/microsoft-teams.svg';
import GoogleChat from './Icons/google-chat.svg';
import BitBucket from './Icons/bitbucket.svg';
import Asana from './Icons/asana.svg';
import Jira from './Icons/jira.svg';
import GitHub from './Icons/github.svg';
import GitLab from './Icons/gitlab.svg';
import Clickup from './Icons/clickup.svg';
import Zendesk from './logo/zendesk.png';
import NoActivity from './Icons/noActivity.svg';
import CloudDownload from './Icons/cloud-download.svg';
import CloudUpload from './Icons/cloud-upload.svg';
import CopyToClipboard from './Icons/copyToClipboard.svg';
import AvatarImage from './images/avatar.png';
import AvatarImage2 from './images/avatar2.png';
import AvatarImage3 from './images/avatar3.png';
import AvatarImage4 from './images/avatar4.png';
import AvatarImage5 from './images/avatar5.png';
import AvatarImage6 from './images/avatar6.png';
import AvatarImage7 from './images/avatar7.png';
import ConfirmEmail from './images/confirm-email.png';
import Security from './images/security.png';
import Done from './images/done.png';
import Todos from './images/todos.png';
import Standup from './images/standup.png';
import Selected from './images/checked.png';
import Bench from './images/Bench.png';
import NoResponse from './images/noResponse.png';
import Updates from './images/updates.png';
import Followers from './images/followers.png';
import CheckIn from './Icons/check-in.svg';
import CheckOut from './Icons/checkout.svg';
import DefaultUserProfile from './images/user-profile.png';
import ComingSoon from './images/coming-soon.png';
import Warning from './images/warning.png';
import InternetAccessError from './images/internet-access-error.jpg';
import Loginbg from './images/loginbg.svg';
import UndifferLogo from './logo/logo.svg';
import UndifferIcon from './logo/undiffer-icon.svg';
import UserIcon from './logo/user.svg';
import Eye from './logo/eva_eye-outline.svg';
import ConfirmAvatar from './Icons/email 1.svg';
import CommentsIcon from './Icons/commentsicon.svg';
import CommentsWhiteIcon from './Icons/commentswhiteicon.svg';
import ArrowIcon from './Icons/Arrow.svg';
import BellIcon from './Icons/Vector.svg';
import LogoCompany from './Icons/addlogo.svg';
import WorkspaceIcon from './Icons/camera-outline.svg';
import UnCheck from './Icons/uncheck.svg';
import CheckIcon from './Icons/check.svg';
import ArchiveIcon from './Icons/Archive.svg';
import AddIcon from './Icons/add.svg';
import Checked from './Icons/checked.svg';
import AddProfile from './Icons/Profilephoto.svg';
import ViewAll from './Icons/viewall.svg';
import CheckInIcon from './Icons/checkin.svg';
import DailyGoalsIcon from './Icons/goals.svg';
import BlockerIcon from './Icons/blocker.svg';
import UserAbsent from './Icons/attendance.svg';
import BlockerTriangle from './Icons/danger.svg';
import leftArrow from './Icons/datepicker.svg';
import RightArrow from './Icons/datepicker2.svg';
import RightArrowDisabled from './Icons/datepicker3.svg';
import NoNotification from './Icons/nonotification.svg';
import Users from './Icons/users.svg';
import Groups from './Icons/groups.svg';
import Blank from './Icons/blank 1.svg';
import accessDenied from './Icons/browser 1.svg';
import EmailVerified from './Icons/emailverified.svg';
import Expired from './Icons/expired.svg';
import Failed from './Icons/failed.svg';
import Trash from './Icons/trash.svg';
import Pencil from './Icons/pencil.svg';
import PencilEdit from './Icons/PencilEdit.svg';
import CloseCircle from './Icons/closecircle.svg';
import BlockerWhiteIcon from './Icons/blockerwhite.svg';
import RangeArrow from './Icons/rangearrow.svg';
import Clock from './Icons/ClockCircle.svg';
import DeleteIcon from './Icons/eva_trash-2-outline.svg';
import LeaveIcon from './Icons/leaves.svg';
import RequestIcon from './Icons/leaverequest.svg';
import SummaryIcon from './Icons/leavesummary.svg';
import Leaves1 from './Icons/leave1.svg';
import Leaves2 from './Icons/leave2.svg';
import Leaves3 from './Icons/leave3.svg';
import Leaves4 from './Icons/leave4.svg';
import Leaves5 from './Icons/leave5.svg';
import Sick from './Icons/sickleave.svg';
import CalendarIcon from './Icons/calender.svg';
import PlusOutline from './Icons/plus.svg';
import fileIcon from './Icons/file-text-outline 1.svg';
import OptionIcon from './Icons/options-outline 3.svg';
import CheckedLight from './Icons/checkedlight.svg';
import PlaceHolder from './Icons/placeholder.svg';
import BackArrow from './Icons/arrow-ios-back-outline.svg';
import AttendanceMenu from './Icons/AttendanceMenu.svg';
import EmailIcon from './Icons/email-logo.svg';
import LeaveRequestTodoIcon from './Icons/leave-request.svg';
import OptionsTodoIcon from './Icons/options-outline.svg';
import PantoneIcon from './Icons/pantone-outline.svg';
import SpeakerIcon from './Icons/speaker-outline.svg';
import LikeIcon from './Icons/like.svg';
import DislikeIcon from './Icons/dislike.svg';
import MarkAsRead from './Icons/mark-as-read.svg';
import ReOrder from './Icons/reorder.svg';
import YesterdayIcon from './Icons/eva_calendar-outline-yesterday.svg';
import CheckMarkGreen from './Icons/checkmark-circle-outline-green.svg';
import CheckMarkGrey from './Icons/checkmark-circle-outline.svg';
import CalenderPrimary from './Icons/eva_calendar-filled.svg';
import BlockerPrimary from './Icons/eva_alert-triangle-primary.svg';
import ArrowUp from './Icons/arrow-up.svg';
import ActivityLight from './Icons/activity-light.svg';
import ActivityDark from './Icons/activity-dark.svg';
import DailyGoalsLight from './Icons/goals-light.svg';
import DailyGoalsDark from './Icons/daily-goals-dark.svg';
import Inbox from './Icons/inbox-outline.svg';
import SmilingFace from './Icons/smiling-face-outline.svg';
import CheckboxGreenEmpty from './Icons/checkbox-empty.svg';
import CheckboxGreenFilled from './Icons/checkbox-filled.svg';
import PersonDeleteOutline from './Icons/person-delete-outline.svg';
import PersonAddOutline from './Icons/eva_person-add-outline.svg';
import StandUpIcon from './Icons/standup-icon.svg';
import ArrowDownIcon from './Icons/arrow-down-fill.svg';
import DoneAllOutline from './Icons/done-all-outline.svg';
import ArrowCircleUpOutline from './Icons/arrow-circle-up-outline.svg';
import ArrowCircleDownOutline from './Icons/arrow-circle-down-outline.svg';
import TrashSecondary from './Icons/trash_1.svg';
import Alert from './Icons/alert.svg';
import People from './Icons/people-outline.svg';
import Bar from './Icons/bar-chart.svg';
import Loader from './Icons/loader.svg';
import CalendarEmpty from './Icons/calender-empty.svg';
import GoalsSecondary from './Icons/goals-secondary.svg';
import PeopleSecondary from './Icons/people-secondary.svg';
import ActivityEmpty from './Icons/activity-empty.svg';
import Source from './Icons/source.png';
import Task from './Icons/task.png';
import AddMore from './Icons/icon-park_more-app.svg';
import Info from './Icons/eva_info-outline.svg';
import InfoLight from './Icons/info-light.svg';
import Building from './Icons/fluent_building-bank-28-regular (1).svg';
import PlusAddOutline from './Icons/pluse-outline.svg';
import ClockPrimary from './Icons/eva_clock-outline-2.svg';
import MinusCircle from './Icons/eva_minus-circle-outline.svg';
import PlusPrimary from './Icons/plus-primary.svg';
import SearchIcon from './Icons/search-icon.svg';
import ColorChecked from './Icons/color-picker.svg';
import PlusCircle from './Icons/eva_plus-circle-outline.svg';
import AlertDark from './Icons/alert-dark.svg';
import CornerDown from './Icons/corner-down.svg';
import NavExpand from './Icons/nav-expand.svg';
import MenuExpand from './Icons/nav-expand.2.svg';
import CloseIcon from './Icons/close-icon.svg';
import MessageOutline from './Icons/eva_message-circle-outline.svg';
import SoundIcon from './Icons/sound-icon.svg';
import PeopleGroup from './Icons/eva_people-group.svg';
import GchatStep1 from './Icons/gchat_step-1.svg';
import GchatStep2 from './Icons/gchat_step-2.svg';
import GchatStep3 from './Icons/gchat_step-3.svg';
import SlackStep from './Icons/slack_step.svg';

export {
  AvatarImage,
  AvatarImage2,
  AvatarImage3,
  AvatarImage4,
  AvatarImage5,
  AvatarImage6,
  AvatarImage7,
  AttendanceMenu,
  CloudDownload,
  CloudUpload,
  ConfirmEmail,
  BlockerWhiteIcon,
  ArrowCircleDownOutline,
  ArrowCircleUpOutline,
  ComingSoon,
  CheckIn,
  CheckOut,
  Done,
  Standup,
  NoResponse,
  DefaultUserProfile,
  Followers,
  GoogleIcon,
  MicrosoftIcon,
  Security,
  InternetAccessError,
  CommentsWhiteIcon,
  SlackIcon,
  Warning,
  Loginbg,
  UndifferLogo,
  UserIcon,
  Eye,
  BitBucket,
  GitHub,
  GitLab,
  Clickup,
  Zendesk,
  CopyToClipboard,
  ConfirmAvatar,
  CommentsIcon,
  ArrowIcon,
  BellIcon,
  LogoCompany,
  UnCheck,
  CheckIcon,
  ArchiveIcon,
  AddIcon,
  Checked,
  AddProfile,
  UndifferIcon,
  ViewAll,
  CheckInIcon,
  DailyGoalsIcon,
  BlockerIcon,
  UserAbsent,
  BlockerTriangle,
  leftArrow,
  NoActivity,
  RightArrow,
  RightArrowDisabled,
  NoNotification,
  accessDenied,
  Blank,
  EmailVerified,
  Expired,
  Failed,
  CloseCircle,
  Trash,
  Pencil,
  RangeArrow,
  Clock,
  DeleteIcon,
  LeaveIcon,
  RequestIcon,
  SummaryIcon,
  Leaves1,
  Leaves2,
  Leaves3,
  Leaves4,
  Leaves5,
  Sick,
  CalendarIcon,
  PlusOutline,
  fileIcon,
  OptionIcon,
  PlaceHolder,
  CheckedLight,
  BackArrow,
  MicrosoftTeams,
  GoogleChat,
  EmailIcon,
  LeaveRequestTodoIcon,
  PantoneIcon,
  SpeakerIcon,
  OptionsTodoIcon,
  LikeIcon,
  DislikeIcon,
  MarkAsRead,
  ReOrder,
  YesterdayIcon,
  CheckMarkGreen,
  CalenderPrimary,
  BlockerPrimary,
  ArrowUp,
  ActivityLight,
  ActivityDark,
  DailyGoalsLight,
  CheckMarkGrey,
  Inbox,
  SmilingFace,
  CheckboxGreenEmpty,
  CheckboxGreenFilled,
  PersonDeleteOutline,
  StandUpIcon,
  ArrowDownIcon,
  DoneAllOutline,
  Todos,
  Updates,
  TrashSecondary,
  Selected,
  Bench,
  Alert,
  People,
  Bar,
  CalendarEmpty,
  GoalsSecondary,
  PeopleSecondary,
  ActivityEmpty,
  Source,
  Task,
  AddMore,
  Info,
  InfoLight,
  DailyGoalsDark,
  Building,
  PlusAddOutline,
  PlusPrimary,
  ClockPrimary,
  MinusCircle,
  PencilEdit,
  PersonAddOutline,
  WorkspaceIcon,
  Asana,
  Jira,
  SearchIcon,
  Loader,
  Users,
  Groups,
  ColorChecked,
  PlusCircle,
  AlertDark,
  CornerDown,
  NavExpand,
  MenuExpand,
  CloseIcon,
  MessageOutline,
  SoundIcon,
  PeopleGroup,
  GchatStep1,
  GchatStep2,
  GchatStep3,
  SlackStep,
};
