import React, { useEffect } from 'react';
import Card from 'atoms/Card';
import {
  Row, Col, Form, Radio, Input, Popover,
} from 'antd';
import UserProfile from 'molecules/UserProfile';
import Tag from 'atoms/Tag';
import { FormattedMessage } from 'react-intl';
import * as eva from 'eva-icons';
import { ArchiveIcon } from 'assets';
import Button from 'atoms/Button';
import { todosData } from './MockData';
import { Content } from './AntStyled';

const { TextArea } = Input;

function LeavesTodo() {
  useEffect(() => {
    eva.replace();
  }, []);

  return (
    <Content>
      <Card
        className="card"
        padding={16}
        title={(
          <div>
            <Row justify="space-between" align="middle">
              <Col>
                <Row gutter={12}>
                  <Col>{todosData?.title?.icon}</Col>
                  <Col>{todosData?.title?.heading}</Col>
                </Row>
              </Col>
              <Col>
                <UserProfile
                  name={`${todosData?.user?.firstName} ${todosData?.user?.lastName}`}
                />
              </Col>
            </Row>
          </div>
        )}
      >
        <p className="title">{todosData?.content?.title}</p>
        <p className="message">{todosData?.content?.message}</p>
        <Row gutter={16}>
          <Col>
            <Tag className="text-tag" content={todosData?.content?.duration} />
          </Col>
          <Col>
            <Tag className="text-tag" content={todosData?.content?.date} />
          </Col>
          <Col>
            <Tag className="text-tag" content={todosData?.content?.type} />
          </Col>
        </Row>
      </Card>
      <Form layout="vertical">
        <p className="heading">{todosData?.form?.title}</p>
        <Form.Item
          label={(
            <div className="label">
              <FormattedMessage id="todos.action" />
            </div>
          )}
          name="leaveRequest"
          rules={[
            {
              required: true,
              message: <FormattedMessage id="todos.formRules.isRequired" />,
            },
          ]}
        >
          <Radio.Group>
            <Radio value>
              <FormattedMessage id="settings.attendance.breaks.yes" />
            </Radio>
            <Radio value={false}>
              <FormattedMessage id="settings.attendance.breaks.no" />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="todos.reason" />}
          name="reason"
          rules={[
            {
              required: true,
              message: <FormattedMessage id="todos.formRules.isRequired" />,
            },
          ]}
        >
          <TextArea rows={4} allowClear />
        </Form.Item>
        <Row align="middle" justify="space-between">
          <Col>
            <Popover
              content={<div></div>}
              overlayClassName="popover-no-padding"
              trigger="click"
              placement="left"
            >
              <img src={ArchiveIcon} alt="icon" />
            </Popover>
          </Col>
          <Col>
            <Button name="Submit" htmlType="submit" />
          </Col>
        </Row>
      </Form>
    </Content>
  );
}

export default LeavesTodo;
