import styled from 'styled-components';

import { fonts } from 'design/Fonts';
import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
import { Row } from 'antd';

export const Content = styled.div`
.task-status{
  &.modify:hover{
    color:${Colors.PrimaryColor};
    cursor:pointer;
  }
  color:${Colors.IconColor};
  font-size:${fonts.large.size};
  line-height: 0;
}
.ant-form-item {
    margin-bottom: 0;
  }
.anticon-check {
    color: ${Colors.ButtonPrimary};
    font: ${fonts.xxLarge.regular};
  }
  .ant-checkbox-wrapper {
    margin-right: ${Spacings.xxSmall.margin};
  }
  .ant-card-body {
    padding: 0;
  }
  & td.task-checkbox{
    .ant-checkbox-wrapper{
      margin-right:0;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after{
      border-color:${Colors.LightDark};
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner{
      background-color:${Colors.PrimaryColor};
      border-color: ${Colors.PrimaryColor}!important;
    }
  }
`;

export const Image = styled.img`
  width:18px;
  height:18px;

`;
export const Project = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.TextEnable};
`;

export const ContentTitle = styled.div`
  font: ${fonts.small.bold};
  padding: ${Spacings.xSmall.padding};
  background: ${Colors.Container};
`;

export const Comments = styled.div`
  color: ${Colors.ButtonPrimary};
  font: ${fonts.medium.regular};
  cursor: pointer;


`;

export const Title = styled.div`
  white-space: normal;
  word-break:break-all;
  /* text-decoration:${({ isCompleted }) => isCompleted ? 'line-through' : 'none'};
  text-decoration-color: ${Colors.LineThrough}; */
`;

export const AssignedToWrapper = styled.div`
  display:flex;
  align-items: center;
  .anticon{
    margin:${Spacings.xxSmall.margin};
    cursor:pointer;
  }
`;

export const BlockersRemoveCount = styled(Row)`
  display: flex;
  justify-content: center;
  padding: 16px;

  span{
    font: 400 0.875em Roboto,sans-serif
  }
`;
