import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import {
  Router,
  Switch,
  Route,
  withRouter,
  useLocation,
} from 'react-router-dom';
import _get from 'lodash/get';
import Meta from 'atoms/Meta';
import Filters from 'molecules/Filters';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import InfoIcon from 'atoms/InfoIcon';
import _map from 'lodash/map';
import Button from 'atoms/Button';
import FeedBackList from 'features/feedbacks/templates/FeedBackList';
import Page404 from 'pages/404';
import {
  META_TYPE,
  EMPTY_ARRAY,
  PRIVATE_ROUTE,
  FETCH_POLICY,
  PAGE_LIMIT,
} from 'utils/constants';
import * as Sentry from '@sentry/react';
import Colors from 'design/Colors';
import Spacings from 'design/Spacings';
import { fonts } from 'design/Fonts';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import Header from 'layout/Header';
import ability from 'utils/ability/ability';
import useDebounce from 'utils/useDebounce';
import { GET_FEEDBACKS } from '../../graphql/Queries';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = styled.div`
  display: flex;
  margin-left: ${Spacings.xxSmall.margin};
  .tab {
    width: 100px;
    height: 34px;
    font-size: ${fonts.small.size};
    padding: 0px ${Spacings.xxSmall.padding};
    box-sizing: border-box;
    border-radius: 6px 0px 0px 6px;

    &:nth-child(2) {
      border-radius: 0px 0px 0px 0px;
    }
    &:nth-child(3) {
      border-radius: 0px 6px 6px 0px;
    }
    &:last-of-type {
      border-radius: 0px 6px 6px 0px;
    }
    &:hover {
      color: ${Colors.TextPrimaryHover};
      border-color: ${Colors.TextPrimaryHover};
    }
    @media screen and (min-width: 1100px) {
      width: 128px;
    }
  }
  & .tab.active {
    font-weight: 600;
  }
`;
const FeedBackPage = (props) => {
  const { pathname } = useLocation();
  const { history } = props;
  const { id } = queryString.parse(history.location.search);
  const [activeTab, setActiveTab] = useState(0);
  const [groupId, setGroupId] = useState(EMPTY_ARRAY);
  const [feedbackList, setFeedbackList] = useState([]);
  const [search, setSearch] = useState('');
  const [dataCount, setDataCount] = useState();
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [queryCount, setQueryCount] = useState(0);

  const debounceSearchTerm = useDebounce(search, 500);

  const tabList = {
    0: ['ACTIVE', 'SCHEDULED'],
    1: ['COMPLETED'],
    2: ['DRAFT'],
  };

  const { data: feedbackData, loading, refetch: refetchFeedbacks } = useQuery(
    GET_FEEDBACKS,
    {
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      variables: {
        where: {
          status: tabList[activeTab],
          groups:
            search?.group?.length > 0
              ? _map(search?.group, (val) => parseInt(val?.slice(2), 10))
              : null,
          users:
            search?.user?.length > 0
              ? _map(search?.user, (val) => parseInt(val?.slice(2), 10))
              : null,
          searchByTitle: search?.text?.length > 0 ? search?.text[0] : '',
          skip,
          limit: PAGE_LIMIT,
        },
      },
    }
  );

  useEffect(() => {
    if (feedbackData?.feedbacks && !loading) {
      const feedbackValues = [
        ...feedbackList,
        ...feedbackData?.feedbacks?.feedbacks,
      ];
      setFeedbackList(feedbackValues);
      if (queryCount === 0) {
        setQueryCount(1);
      }
      setDataCount(feedbackData?.feedbacks?.count);
      setLoader(false);
      setDataLoading(false);
    }
  }, [activeTab, feedbackData]);

  //* for future use
  // useEffect(() => {
  //   resetFeedbackData();
  // }, [groupId, debounceSearchTerm]);

  useEffect(() => {
    setDataLoading(true);
    resetFeedbackData();
  }, [search]);

  const resetFeedbackData = () => {
    setHasMore(true);
    setFeedbackList([]);
    setSkip(0);
    setPage(1);
    setDataLoading(true);
  };

  const handleInfiniteOnLoad = () => {
    if (!loading) {
      setLoader(true);
      if (dataCount <= feedbackList.length) {
        setHasMore(false);
        setLoader(false);
        setPage(1);
      } else {
        setPage((prev) => prev + 1);
        const count = page * PAGE_LIMIT;
        setSkip(count);
      }
    }
  };

  const callback = (key) => {
    if (activeTab !== key) {
      setActiveTab(key);
      setQueryCount(0);
      if (key === 0) {
        history.push(PRIVATE_ROUTE.FEED_BACK_CURRENT);
        resetFeedbackData();
      } else if (key === 1) {
        history.push(PRIVATE_ROUTE.FEED_BACK_COMPLETED);
        resetFeedbackData();
      } else if (key === 2) {
        history.push(PRIVATE_ROUTE.FEED_BACK_DRAFT);
        resetFeedbackData();
      }
    }
  };

  useEffect(() => {
    if (pathname === PRIVATE_ROUTE.FEED_BACK_CURRENT) {
      setFeedbackList(EMPTY_ARRAY);
      setActiveTab(0);
    } else if (pathname === PRIVATE_ROUTE.FEED_BACK_COMPLETED) {
      setFeedbackList(EMPTY_ARRAY);
      setActiveTab(1);
    } else if (pathname === PRIVATE_ROUTE.FEED_BACK_DRAFT) {
      setFeedbackList(EMPTY_ARRAY);
      setActiveTab(2);
    }
  }, [pathname]);

  const tabs = [
    <FormattedMessage id="feedback.tab.current" />,
    <FormattedMessage id="feedback.tab.completed" />,
  ];
  if (ability.can('manage', 'feedbacks')) {
    tabs.push(<FormattedMessage id="feedback.tab.draft" />);
  }
  const Title = () => (
    <Wrapper>
      <InfoIcon
        title={<FormattedMessage id="feedback.title" />}
        contentKey={
          ability.can('manage', 'feedbacks')
            ? META_TYPE?.FEED_BACK_SETTINGS
            : META_TYPE?.FEED_BACK
        }
      />
      <Tabs>
        {_map(tabs, (list, index) => (
          <Button
            key={index}
            keyProp={index}
            className={`tab ${index === activeTab ? 'active' : ''}`}
            background={Colors.TextLight}
            border={
              index === activeTab ? Colors.PrimaryColor : Colors.SecondaryColor
            }
            color={
              index === activeTab ? Colors.PrimaryColor : Colors.TextSecondary
            }
            focus={Colors.TextLight}
            name={list}
            onClick={() => callback(index)}
          />
        ))}
      </Tabs>
    </Wrapper>
  );

  return (
    <Sentry.ErrorBoundary fallback="Something went wrong">
      <Layout>
        <Meta title={META_TYPE.FEED_BACK} />
        <>
          <Header title={<Title />} />
          <Filters
            doSearch
            onSearch={setSearch}
            masterFilter
            addButton
            addButton={ability.can('manage', 'feedbacks')}
            dropdown
            groupsFilters={setGroupId}
            groupValue={groupId}
          />
        </>
        <Router history={history}>
          <Switch>
            <Route
              exact
              path={PRIVATE_ROUTE.FEED_BACK_CURRENT}
              render={() => (
                <FeedBackList
                  feedbackList={feedbackList}
                  feedbackLoading={dataLoading}
                  queryCount={queryCount}
                  search={search}
                  isCompleted={activeTab === 1}
                  refetchFeedbacks={refetchFeedbacks}
                  handleInfiniteOnLoad={handleInfiniteOnLoad}
                  hasMore={hasMore}
                  loader={loader}
                  dataCount={dataCount}
                  status={activeTab}
                  setFeedbackList={setFeedbackList}
                />
              )}
            />
            <Route
              exact
              path={PRIVATE_ROUTE.FEED_BACK_COMPLETED}
              render={() => (
                <FeedBackList
                  feedbackList={feedbackList}
                  feedbackLoading={dataLoading}
                  queryCount={queryCount}
                  search={search}
                  isCompleted={activeTab === 1}
                  refetchFeedbacks={refetchFeedbacks}
                  handleInfiniteOnLoad={handleInfiniteOnLoad}
                  hasMore={hasMore}
                  loader={loader}
                  dataCount={dataCount}
                  status={activeTab}
                />
              )}
            />
            <Route
              exact
              path={PRIVATE_ROUTE.FEED_BACK_DRAFT}
              render={() => (
                <FeedBackList
                  feedbackList={feedbackList}
                  feedbackLoading={dataLoading}
                  queryCount={queryCount}
                  search={search}
                  isCompleted={activeTab === 1}
                  refetchFeedbacks={refetchFeedbacks}
                  handleInfiniteOnLoad={handleInfiniteOnLoad}
                  hasMore={hasMore}
                  loader={loader}
                  dataCount={dataCount}
                  status={activeTab}
                />
              )}
            />
            <Route exact path="*" render={() => <Page404 />} />
          </Switch>
        </Router>
      </Layout>
    </Sentry.ErrorBoundary>
  );
};
export default withRouter(FeedBackPage);
