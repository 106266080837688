import React from 'react';
import { Form } from 'antd';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Meta from 'atoms/Meta';
import { UndifferLogo, Expired } from 'assets';
import Card from 'atoms/Card';
import Button from 'atoms/Button';
import {
  FORM_NAME,
  META_TYPE,
  PUBLIC_ROUTE,
  BUTTON_TYPE,
} from 'utils/constants';
import {
  Container,
  Description,
  EmailPop,
  Wrapper,
  Background,
  ImageWrapper,
  Title,
  Logo,
  Token,
} from './AntStyled';

export default function (props) {
  const { history } = props;

  const continueToLogin = () => {
    history.push('/login');
  };

  return (
    <Container>
      <Meta title={META_TYPE.TOKEN_EXPIRED} />
      <Background>
        <Logo src={UndifferLogo} alt="logo"></Logo>
        <NavLink to="/help" className="bottom-right">
          <FormattedMessage id="terms.help" />
        </NavLink>
        <NavLink to="/copyright" className="bottom-left">
          <FormattedMessage id="terms.copyright" />
        </NavLink>
      </Background>
      <Wrapper>
        <Card className="auth">
          <Token>
            <ImageWrapper>
              <EmailPop src={Expired} />
            </ImageWrapper>
            <Title>Signed Out</Title>
            <Description>
              For Security reason, This session has been signed out.
            </Description>
            <Description>Please sign in again.</Description>
            <Button
              className="center"
              primary
              type={BUTTON_TYPE.PRIMARY}
              htmlType={BUTTON_TYPE.SUBMIT}
              name="Continue to login"
              onClick={continueToLogin}
            />
          </Token>
        </Card>
      </Wrapper>
    </Container>
  );
}
