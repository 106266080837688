import React from 'react';
import { Row, Rate, Col, Progress } from 'antd';
import Statistics from 'atoms/Statistics';
import PercentFormat from 'utils/PercentFormat';
import Colors from 'design/Colors';
import _map from 'lodash/map';
import { Text } from './AntStyled';
const RateCard = ({ data }) => {
  const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b);

  let sum = 0;

  _map(data?.pollResult, (val, i) => {
    sum += val * parseInt(i, 10);
  });

  return (
    <div>
      <Row align="middle" gutter={8} className="mb-2">
        <Text>{`${data?.responded} Reviews`} </Text>
        <Col>
          <Rate disabled allowHalf value={sum / data?.responded}></Rate>
        </Col>
        <Col>
          {isNaN(sum / data?.responded)
            ? 0
            : Math.abs(sum / data?.responded).toFixed(
                sum % data?.responded === 0 ? 0 : 1
              )}
          &nbsp;out of 5 stars
        </Col>
      </Row>
      <Row>
        <Statistics
          className="stars"
          number={_map(data?.pollResult, (value, index) => (
            <Row align="middle">
              <Col className="normal">{`${index} stars`}</Col>
              <Col className="result-progress">
                <Progress
                  width={54}
                  className="progress"
                  trailColor="lightgrey"
                  strokeLinecap="square"
                  percent={PercentFormat(sumValues(data?.pollResult), value)}
                  strokeWidth={10}
                  status="normal"
                />
              </Col>
            </Row>
          )).reverse()}
          description=""
        />
      </Row>
    </div>
  );
};

export default RateCard;
