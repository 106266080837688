import React, { useState } from 'react';
import { Col, Divider } from 'antd';
import Button from 'atoms/Button';
import DaysFilter from 'atoms/DaysFilter';
import LocationFilter from 'atoms/LocationFilter';
import { PlusAddOutline } from 'assets';
import { BUTTON_TYPE, COL_TYPE, DIVIDER_TYPE } from 'utils/constants';
import UniversalFilter from 'organisms/UniversalFilter';
import SwitchableDatePicker from 'atoms/SwitchableDatePicker';
import SingleDatePicker from 'atoms/SingleDatePicker';
import ButtonDropdown from 'molecules/ButtonDropdown';
import LeavesPopover from 'organisms/LeavesPopover';
import { Header, Row } from './AntStyled';

export default function (props) {
  const {
    addButton,
    leaveRequest,
    addButtonText,
    addButtonEvent,
    addButtonClass,
    masterFilter,
    locationFilter,
    daysFilter,
    extra,
    setDateFilterValue,
    dateFilterValue,
    locationFilters,
    groupsFilters,
    multipleMode,
    switchableDateFilter,
    showDay,
    filterBy,
    singleDatePicker,
    setSingleDatePicker,
    datePickerValue,
    setType,
    groupValue,
    setUsersValue,
    showUsersFilter,
    usersValue,
    typeValue,
    showTaskFilter,
    taskFilters,
    taskType,
    showGroupTypeFilter,
    groupTypeFilters,
    groupType,
    onClickData,
    setSelectedGoalsType,
    selectedGoalsType,
    setSelectedGroupsType,
    selectedGroupsType,
    onChangeDateFilter,
    handleTaskTypeChange,
    handleGroupOrUserFilterChange,
    doSearch,
    onSearch,
    dropdown,
    createLeaveRequest,
    setIsLeaveModalVisible,
    isLeaveModalVisible,
    userGroups,
    leaveTypes,
    yearOnly,
    showGroupUserFilter,
    showToday,
    platFormFilter,
    onPlatformChange,
  } = props;
  const [showLocationFilter, setShowLocationFilter] = useState(false);
  return (
    <Header>
      <Row align="middle" className="overflow">
        <Col className="universal-filter">
          {masterFilter && (
            <UniversalFilter
              multipleMode={multipleMode}
              groupsFilters={groupsFilters}
              groupValue={groupValue}
              filterBy={filterBy}
              showUsersFilter={showUsersFilter}
              usersValue={usersValue}
              setUsersValue={setUsersValue}
              showTaskFilter={showTaskFilter}
              taskFilters={taskFilters}
              taskType={taskType}
              showGroupTypeFilter={showGroupTypeFilter}
              groupTypeFilters={groupTypeFilters}
              groupType={groupType}
              setSelectedGoalsType={setSelectedGoalsType}
              selectedGoalsType={selectedGoalsType}
              setSelectedGroupsType={setSelectedGroupsType}
              selectedGroupsType={selectedGroupsType}
              handleTaskTypeChange={handleTaskTypeChange}
              handleGroupOrUserFilterChange={handleGroupOrUserFilterChange}
              doSearch={doSearch}
              onSearch={onSearch}
              showGroupUserFilter={showGroupUserFilter}
              platFormFilter={platFormFilter}
              onPlatformChange={onPlatformChange}
            />
          )}
        </Col>
        <Col flex={COL_TYPE.AUTO} className="right">
          {extra}
          {locationFilter && (
            <LocationFilter
              locationFilters={locationFilters}
              showLocationFilter={showLocationFilter}
              setShowLocationFilter={setShowLocationFilter}
              handleGroupOrUserFilterChange={handleGroupOrUserFilterChange}
            />
          )}
          {daysFilter && (
            <>
              {locationFilter && showLocationFilter && (
                <Divider type={DIVIDER_TYPE.VERTICAL} />
              )}
              <DaysFilter
                onChangeDateFilter={onChangeDateFilter}
                setDateFilterValue={setDateFilterValue}
                dateFilterValue={dateFilterValue}
              />
            </>
          )}
          {switchableDateFilter && (
            <>
              {locationFilter && showLocationFilter && (
                <Divider type={DIVIDER_TYPE.VERTICAL} />
              )}
              <SwitchableDatePicker
                onClickData={onClickData}
                setDateFilterValue={setDateFilterValue}
                dateFilterValue={dateFilterValue}
                showDay={showDay}
                setType={setType}
                typeValue={typeValue}
                yearOnly={yearOnly}
              />
            </>
          )}
          {singleDatePicker && (
            <>
              <SingleDatePicker
                setSingleDatePicker={setSingleDatePicker}
                datePickerValue={datePickerValue}
                showToday={showToday}
              />
            </>
          )}
          {addButton && (
            <>
              {dropdown ? (
                <ButtonDropdown />
              ) : (
                <Button
                  className="add-button"
                  type={BUTTON_TYPE.PRIMARY}
                  onClick={() => addButtonEvent()}
                  icon={
                    <img
                      className="plus-icon"
                      width="13px"
                      height="13px"
                      src={PlusAddOutline}
                      alt="addicon"
                    />
                  }
                  name={addButtonText}
                />
              )}
            </>
          )}
          {leaveRequest && (
            <span>
              <LeavesPopover
                createLeaveRequest={createLeaveRequest}
                setIsLeaveModalVisible={setIsLeaveModalVisible}
                isLeaveModalVisible={isLeaveModalVisible}
                userGroups={userGroups}
                leaveTypes={leaveTypes}
              />
            </span>
          )}
        </Col>
      </Row>
    </Header>
  );
}
