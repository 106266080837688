import amplitude from 'amplitude-js';
import { toUpper } from 'lodash';

export const createAmplitudeEvent = (eventName, eventProperties) => {
  amplitude.getInstance().logEvent(eventName, eventProperties);
};

export const createPageAmplitudeEvent = (pathname) => {
  const eventProperties = {
    path: pathname,
  };
  const pathParts = pathname?.split('/');
  const part2 = pathParts.length > 2 ? `_${pathParts[2]}` : '';
  createAmplitudeEvent(
    `Visit_${toUpper(pathParts[1])}${toUpper(part2)}`,
    eventProperties
  );
};
