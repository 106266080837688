import { EMPTY_OBJECT, EMPTY_STRING } from 'utils/constants';

const flatten = ((nested, prefix = EMPTY_STRING) => {
  if (!nested) return EMPTY_OBJECT;

  return Object.keys(nested).reduce((messages, key) => {
    const value = nested[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      // eslint-disable-next-line no-param-reassign
      messages = { ...messages, [prefixedKey]: value };
    } else {
      Object.assign(messages, flatten(value, prefixedKey));
    }

    return messages;
    // return { ...messages, [prefixedKey]: typeof value === 'string' ? value : flatten(value, prefixedKey) };
  }, {});
});

export default flatten;
