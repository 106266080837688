import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    profileImage
    title
    timeZone
    originalEmail
  }
`;

export const groupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    color
    type
  }
`;

export const standUpAnswerFragment = gql`
  fragment StandUpAnswerFragment on StandUpAnswer {
    id
    content {
      task
      isChecked
      isPrevious
    }
    standUpQuestion {
      questionText
    }
    addedLater
    groups {
      ...GroupFragment
    }
    assignedTo {
      ...UserFragment
    }
    checked
  }
  ${userFragment}
  ${groupFragment}
`;

export const standUpFragment = gql`
  fragment StandUpFragment on StandUpEntry {
    id
    mood
    status
    createdAt
    updatedAt
    allowed
    yesterday {
      count
      standUpAnswers {
        ...StandUpAnswerFragment
      }
    }
    today {
      count
      standUpAnswers {
        ...StandUpAnswerFragment
      }
    }
    blocker {
      count
      standUpAnswers {
        ...StandUpAnswerFragment
      }
    }
    user {
      ...UserFragment
    }
  }
  ${standUpAnswerFragment}
  ${userFragment}
`;
