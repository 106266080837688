import React, { useEffect, useContext, useState, useCallback } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { message, Space } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import client from 'apollo';
import { AppContext } from 'AppContext';
import Button from 'atoms/Button';
import Meta from 'atoms/Meta';
import Card from 'atoms/Card';
import Input from 'atoms/Input';
import { useMutation } from '@apollo/client';
import _filter from 'lodash/filter';
import {
  BUTTON_TYPE,
  META_TYPE,
  FORM_NAME,
  ONBOARDING,
  PUBLIC_ROUTE,
  USER_ROLE,
  PRIVATE_ROUTE,
} from 'utils/constants';
import {
  GOOGLE_AUTH_SIGN_UP,
  LOCAL_SIGN_UP,
  MICROSOFT_AUTH_SIGN_UP,
  REQUEST_COMPANY,
  SLACK_AUTH_SIGN_UP,
} from 'graphql/Onboarding/Mutations';
import * as Sentry from '@sentry/react';
import { UndifferLogo } from 'assets';
import { getTrimValues } from 'utils';
import { GET_CURRENT_USER } from 'graphql/CurrentUser/queries';
import TermsAndPolicy from 'pages/Auth/TermsAndPolicy';
import ReactGA from 'react-ga';
import { removeToken } from 'utils/auth';
import {
  Container,
  Title,
  Logo,
  Background,
  Wrapper,
  Form,
  StyledDivider,
  Existing,
  Terms,
} from './AntStyled';
import firebase from '../../../analytics';
function Selection(props) {
  const history = useHistory();
  const {
    dispatch,
    state: { loginUser },
  } = useContext(AppContext);
  const [isBackButtonClicked, setBackButtonPress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const Click = () => setShowResults(true);

  useEffect(() => {
    if (loginUser?.company && loginUser?.isInvited && loginUser?.isActive) {
      history.push(PRIVATE_ROUTE.DASHBOARD);
    }
  }, [loginUser]);

  useEffect(() => {
    if (!loginUser?.lastLoggedIn) {
      ReactGA.event({
        category: 'New signup',
        action: 'New google signup',
        label: 'Signup with google',
        value: loginUser?.email,
      });
    }
  }, [loginUser]);

  useEffect(() => {
    window.history.pushState(null, null, window?.location?.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      if (window.confirm('Do you want to go back?')) {
        setBackButtonPress(true);
        history.replace(PUBLIC_ROUTE.LOGOUT);
      } else {
        window.history.pushState(null, null, window?.location?.pathname);
        setBackButtonPress(false);
      }
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const trimValues = getTrimValues(values);
    const { workspaceName } = trimValues;
    try {
      client
        .mutate({
          mutation: REQUEST_COMPANY,
          refetchQueries: [
            {
              query: GET_CURRENT_USER,
            },
          ],
          variables: { workspaceName },
        })
        .then(() => {
          setLoading(false);
          history.push(ONBOARDING.WORKSPACE_RESULT);
        })
        .catch((e) => {
          if (e?.message?.includes('Company not found')) {
            setLoading(false);
            history.push({
              pathname: ONBOARDING.WORKSPACE_RESULT,
              state: { workspaceName },
            });
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const Results = () => (
    <Existing>
      <Form.Item
        name="workspaceName"
        label="Workspace Name / URL"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Type name or URL" allowClear />
      </Form.Item>
      <Button
        accent
        className="right"
        loading={loading}
        htmlType={BUTTON_TYPE.SUBMIT}
        name="Continue"
      />
    </Existing>
  );

  return (
    <Container>
      <Background>
        <Logo src={UndifferLogo} alt="logo"></Logo>
        <NavLink to="/help" className="bottom-right">
          <FormattedMessage id="terms.help" />
        </NavLink>
        <NavLink to="/copyright" className="bottom-left">
          <FormattedMessage id="terms.copyright" />
        </NavLink>
      </Background>
      <Wrapper>
        <Card padding="32">
          <Meta title={META_TYPE.WORK_SPACE} />
          <Form
            name={FORM_NAME.BASIC}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Title>
              <FormattedMessage id="onboarding.workspace" />
            </Title>
            <Form.Item>
              <Button
                primary
                type={BUTTON_TYPE.PRIMARY}
                onClick={() => {
                  history.push(ONBOARDING.ROOT);
                }}
                name={<FormattedMessage id="onboarding.selection.new" />}
              />
            </Form.Item>
            <StyledDivider>OR</StyledDivider>
            <Form.Item className="mb-0">
              <Button
                accent
                onClick={Click}
                type={BUTTON_TYPE.PRIMARY}
                name={<FormattedMessage id="onboarding.selection.existing" />}
              />
            </Form.Item>
            <span>{showResults ? <Results /> : null}</span>
          </Form>
        </Card>
        <TermsAndPolicy />
      </Wrapper>
    </Container>
  );
}

export default Selection;
