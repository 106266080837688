/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Col, Row } from 'antd';
import Tag from 'atoms/Tag';
import UserProfile from 'molecules/UserProfile';
import { toLower } from 'lodash';
import { ActivityData } from '../MockData';
import ActivityCard from '../ActivityCard';

export const AsanaCommentMessage = (data, type = '') => (
  <Row align="middle" className="message">
    {type !== 'teams' && (
      <UserProfile
        size={20}
        stackedName={false}
        profile={data?.actor?.profilePic || data?.processedData?.actor_image}
        name={data?.actor?.name || data?.processedData?.actor_name}
      />
    )}
    <span className="text-regular">
      {toLower(data?.processedData?.action)} a comment&nbsp;
      <span>{data?.processedData?.action === 'DELETED' ? 'from' : 'in'}</span>
      &nbsp;the task&nbsp;
    </span>
    <span
      className="heading"
      onClick={() => {
        window.open(data?.processedData?.task_url);
      }}
    >
      “{data?.processedData?.task_name}”
    </span>
    &nbsp;
    <span className="text-regular">in project</span> &nbsp;&nbsp;
    {type === 'teams' ? (
      <div className="bold-text">{data?.processedData?.project_name}</div>
    ) : (
      <Tag className="tag-text" content={data?.processedData?.project_name} />
    )}
  </Row>
);

const AsanaComment = ({ data }) => (
  <ActivityCard
    activityDate={data?.addedAt}
    platformIcon={ActivityData?.asana?.title?.icon}
    message={AsanaCommentMessage(data)}
  />
);

export default AsanaComment;
