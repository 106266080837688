/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Row,
  Skeleton,
  Spin,
  Radio,
  Typography,
  Space,
  Rate,
  Col,
  Checkbox,
} from 'antd';
import _map from 'lodash/map';
import Table from 'atoms/Table';
import Card from 'atoms/Card';
import ContentWrapper from 'atoms/ContentWrapper';
import { useQuery } from '@apollo/client';
import { GET_FEEDBACK_RESPONSE } from 'features/feedbacks/graphql/Queries';
import { FETCH_POLICY, LIKE_TYPE, QUESTION_TYPE } from 'utils/constants';
import { useParams } from 'react-router-dom';
import DateTime from 'atoms/DateTime';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import UserProfile from 'molecules/UserProfile';
import { LikeIcon, DislikeIcon, NoResponse } from 'assets';
import Loader from 'atoms/Loader';
import AccessDenied from 'pages/AccessDenied';
import EmptyState from 'pages/EmptyState';
import { Content, Modal, QuestionTitle, LikeDislikeWrapper } from './AntStyled';
import { responseData, questions } from '../../MockData';

const { Title } = Typography;
export default function Response() {
  const [responseId, setResponseId] = useState(null);
  const { id } = useParams();
  const { data = {}, loading, error } = useQuery(GET_FEEDBACK_RESPONSE, {
    variables: { where: { feedbackId: id } },
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const { feedbackResponse = [] } = data;
  const [answeredBy] = feedbackResponse;
  const responseColumns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      title: 'Name',
      dataIndex: 'answeredBy',
      key: 'name',
      render: (user, rowData) =>
        // eslint-disable-next-line no-nested-ternary
        rowData.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : user ? (
          <UserProfile
            stackedName={false}
            profile={user?.profileImage}
            name={
              user?.firstName
                ? `${user?.firstName} ${user?.lastName || ''}`
                : user?.email
            }
            userId={user?.id}
            medium
          />
        ) : (
          '-'
        ),
    },
    {
      title: 'Submitted On',
      dataIndex: 'createdAt',
      align: 'center',
      key: 'date',
      render: (date) => (
        <DateTime date={date} format={MomentFormat.MMM}></DateTime>
      ),
    },
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <ContentWrapper>
      {loading ? (
        <Loader />
      ) : error?.message === 'GraphQL error: You cannot view this feedback.' ? (
        <AccessDenied />
      ) : (
        <Content>
          {data?.feedbackResponse?.length > 0 ? (
            <Table
              className="bb-0"
              onRow={(record) => ({
                onClick: () => {
                  setResponseId(record?.answeredBy?.id);
                  setIsModalVisible(true);
                },
              })}
              columns={responseColumns}
              dataSource={data?.feedbackResponse}
            />
          ) : (
            <EmptyState
              title="Oops!"
              description="You don’t have access to view all responses."
              image={NoResponse}
            ></EmptyState>
          )}
          {_map(feedbackResponse, (response) =>
            responseId === response?.answeredBy?.id ? (
              <Modal
                title={
                  <UserProfile
                    stackedName={false}
                    profile={response?.answeredBy?.profileImage}
                    name={`${response?.answeredBy?.firstName} ${response?.answeredBy?.lastName}`}
                    userId={response?.answeredBy?.id}
                    showLink
                    medium
                  />
                }
                footer={null}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}
              >
                {_map(response?.answers, (question) => (
                  <>
                    <QuestionTitle level={4}>
                      {question?.question?.question}
                    </QuestionTitle>
                    <Card>
                      {question?.question?.questionType ===
                      QUESTION_TYPE.SINGLE_CHOICE ? (
                        <Radio.Group value={question?.answer?.answer}>
                          {_map(question?.question?.choices, (choice) => (
                            <Radio value={choice}>{choice}</Radio>
                          ))}
                        </Radio.Group>
                      ) : question?.question?.questionType ===
                        QUESTION_TYPE.LIKE ? (
                        <LikeDislikeWrapper>
                          <Space>
                            <div
                              className={`like ${
                                question?.answer?.answer === LIKE_TYPE.LIKE
                                  ? 'active'
                                  : ''
                              }`}
                            >
                              <img
                                alt="like-logo"
                                src={LikeIcon}
                                height={20}
                                width={20}
                              />
                            </div>
                            <div
                              className={`dislike ${
                                question?.answer?.answer === LIKE_TYPE.DISLIKE
                                  ? 'active'
                                  : ''
                              }`}
                            >
                              <img
                                alt="dislike-logo"
                                src={DislikeIcon}
                                height={20}
                                width={20}
                              />
                            </div>
                          </Space>
                        </LikeDislikeWrapper>
                      ) : question?.question?.questionType ===
                        QUESTION_TYPE.RATE ? (
                        <Row align="middle" gutter={8}>
                          <Col>
                            <Rate
                              disabled
                              value={question?.answer?.answer}
                            ></Rate>
                          </Col>

                          <Col>{question?.answer?.answer} out of 5 stars</Col>
                        </Row>
                      ) : question?.question?.questionType ===
                        QUESTION_TYPE.MCQ ? (
                        <Checkbox.Group
                          options={question?.question?.choices}
                          value={question?.answer?.answer}
                        ></Checkbox.Group>
                      ) : (
                        ''
                      )}
                    </Card>
                  </>
                ))}
              </Modal>
            ) : (
              ''
            )
          )}
        </Content>
      )}
    </ContentWrapper>
  );
}
