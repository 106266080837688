/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Tag,
  Col,
  Tabs,
  Drawer as AntDrawer,
  Empty,
  Spin,
  Checkbox,
} from 'antd';
import UserProfile from 'molecules/UserProfile';
import {
  ACTIVITY_EVENT_TYPE,
  AVATAR_TYPE,
  EMOJIS,
  EMPTY_ARRAY,
  FETCH_POLICY,
  PLATFORM_TYPE,
  STATUS,
} from 'utils/constants';
import * as eva from 'eva-icons';
import Colors from 'design/Colors';
import styled from 'styled-components';
import { fonts } from 'design/Fonts';
import DateTime from 'atoms/DateTime';
import { filter, isNumber, map } from 'lodash';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import {
  YesterdayIcon,
  BlockerPrimary,
  CalenderPrimary,
  DailyGoalsDark,
  DailyGoalsLight,
  Info,
  InfoLight,
  GitHub,
  GitLab,
  Building,
  NoActivity,
  ActivityDark,
  ActivityLight,
  Todos,
  CornerDown,
} from 'assets';
import Variables from 'design/Variables';
import Spacings from 'design/Spacings';
import { FormattedMessage } from 'react-intl';
import { ActivityData } from 'pages/Activities/MockData';
import { useLazyQuery, useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_TEAM_ACTIVITY } from 'graphql/Team/Queries';
import { CommitMessage } from 'pages/Activities/VersionControl/CommitCard';
import { ClickupMessage } from 'pages/Activities/Clickup/ClickupCard';
import { AssignedMessage } from 'pages/Activities/Clickup/ClickupAssigneeCard';
import ZendeskCard, {
  ZendeskMessage,
} from 'pages/Activities/Zendesk/ZendeskCard';
import { EmptyWrapper } from 'pages/Activities/AntStyled';
import Loader from 'atoms/Loader';
import Card from 'atoms/Card';
import Button from 'atoms/Button';
import { GET_USER } from 'graphql/Settings/Users/Queries';
import { AsanaAttachmentMessage } from 'pages/Activities/Asana/AsanaAttachment';
import { AsanaStoryMessage } from 'pages/Activities/Asana/AsanaStory';
import { AsanaTaskMessage } from 'pages/Activities/Asana/AsanaTask';
import { AsanaCommentMessage } from 'pages/Activities/Asana/AsanaComment';
import { JiraSprintMessage } from 'pages/Activities/Jira/JiraSprint';
import { JiraIssueMessage } from 'pages/Activities/Jira/JiraIssue';
import { JiraCommentMessage } from 'pages/Activities/Jira/JiraComment';
import { JiraAttachmentMessage } from 'pages/Activities/Jira/JiraAttachment';
import { scrollbars } from 'design/Styled';
import { GET_TEAM_CHECKINS } from 'features/standUp/graphql/Queries';
import { taskFormat } from 'utils/utils';
import { standupData } from './MockData';
const { TabPane } = Tabs;

export const Divider = styled.hr`
  margin-left: -16px;
  margin-right: -16px;
  border: 1px solid ${Colors.Border};
  border-bottom: 0px;
  margin-bottom: ${Spacings.xSmall.margin};
  margin-top: 16px;
`;
const TimeExpanded = styled.div`
  font: ${fonts.xxSmall.regular};
  line-height: 14px;
  text-align: right;
  color: ${Colors.TextSecondary};
  .time {
    font-weight: bold;
  }
`;
const TabBody = styled.div`
  .title {
    color: ${Colors.TextSecondary};
    font: ${fonts.small.medium};
    line-height: ${Variables.LineHeightSm};
  }
  .text {
    color: ${Colors.TextSecondary};
    font: ${fonts.small.regular};
    line-height: ${Variables.LineHeightMd};
    word-break: break-all;
  }
  .nodata {
    color: ${Colors.SecondaryColor};
    font: ${fonts.small.regular};
    line-height: 14px;
    font-size: 12px;
  }
  .goals {
    position: relative;
    height: fit-content;
    margin: 10px 0px 14px 8px;
    padding-left: ${Spacings.Small.padding};
  }
  .activity {
    position: relative;
    height: fit-content;
    margin: 0px 0px 14px 8px;
    padding-left: ${Spacings.Small.padding};
  }
  .checkbox {
    position: relative;
    height: fit-content;
    margin: 10px 0px 12px 2px;
  }
  .checkbox-disabled {
    margin-right: 8px;
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: ${Colors.TextEmpty};
      border-color: ${Colors.TextEmpty} !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${Colors.TextEmpty};
      border-color: ${Colors.TextEmpty};
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #fff;
    }
  }
  .checkbox-disabled-primary {
    margin-right: 8px;
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: ${Colors.PrimaryColor};
      border-color: ${Colors.PrimaryColor} !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${Colors.PrimaryColor};
      border-color: ${Colors.PrimaryColor};
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #fff;
    }
  }
  .ant-checkbox-wrapper {
    display: flex;
    justify-content: center;
    border-radius: 3px;
    color: ${Colors.TextSecondary};
    font: ${fonts.small.regular};
    line-height: ${Variables.LineHeightMd};
  }
  .ant-checkbox-disabled + span {
    color: ${Colors.TextSecondary};
    font: ${fonts.small.regular};
    line-height: ${Variables.LineHeightMd};
  }
  .ant-checkbox {
    margin-top: 4px;
  }
  .ant-checkbox-inner {
    border-radius: 3px;
    border-width: 0.875px;
  }
  .today {
    position: relative;
    height: fit-content;
    margin: 10px 0px 12px 8px;
    padding-left: ${Spacings.Small.padding};
  }
  .blocker {
    position: relative;
    height: fit-content;
    margin: 10px 0px 12px 8px;
    padding-left: ${Spacings.Small.padding};
  }
  .border-blocker {
    width: 4px;
    border-radius: 200px;
    background-color: ${Colors.TextDanger};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .border-goals {
    width: 4px;
    border-radius: 200px;
    background-color: ${Colors.TextGreen};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .border-today {
    width: 4px;
    border-radius: 200px;
    background-color: ${Colors.PrimaryColor};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .border-nodata {
    width: 4px;
    border-radius: 200px;
    background-color: ${Colors.SecondaryColor};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .cal-icon {
    width: 20px;
  }
  .main-icon {
    margin-right: ${Spacings.xxSmall.margin};
    display: flex;
    align-items: center;
  }
  .icon {
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info-text {
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
    line-height: ${Variables.LineHeightSm};
  }
  .info {
    color: ${Colors.TextSecondary};
    font: ${fonts.small.medium};
    margin-left: 21px;
    line-height: ${Variables.LineHeightSm};
    word-break: break-word;
  }
  .activity-text {
    color: ${Colors.TextSecondary};
    font: ${fonts.medium.medium};
    line-height: ${Variables.LineHeightL};
  }
  .top-row {
    margin-bottom: 32px;
  }
  .row-bottom {
    margin-bottom: 24px;
  }
  .time-activity {
    color: ${Colors.TextSecondary};
    font: ${fonts.xxSmall.medium};
    line-height: ${Variables.LineHeightXs};
    margin-top: 12px;
    margin-left: 30px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .new-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .flex-box {
    display: flex;
    align-items: center;
  }
  .unchecked {
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: unset;
    }
  }
`;
const StyledTag = styled(Tag)`
  border: none;
  background: transparent;
  margin-right: 0;
  font: ${fonts.xxSmall.bold};
  line-height: 14px;
  padding: 0;
  color: ${({ textColor }) => textColor};
`;

const Content = styled.div``;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-header {
    background-color: #f2f4f7;
    font-weight: 400;
    color: ${Colors.TextPrimary};
    padding: 18px 16px;
    border-bottom: 1px solid #cad6e3;
  }
  .ant-drawer-body {
    padding: 0px;
    background: ${Colors.Container};
    font: ${fonts.small.regular};
    overflow: auto;
    ${scrollbars};
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 16px 0 0;
    padding: 8px 0;
  }
  .ant-tabs-nav-wrap {
    padding: 0px 16px;
  }
  .ant-tabs-bar {
    margin: 0 0 16px 0;
    border-bottom: 1px solid #cad6e3;
  }
  .scroll {
    height: calc(100vh - 140px);
    overflow: auto;
    ${scrollbars};
    overflow-x: hidden;
    padding: 0px 16px 16px;
  }
  .greenBorder {
    .ant-tabs-ink-bar {
      background-color: ${Colors.TextGreen};
    }
  }
  .bold-text {
    font: ${fonts.small.medium};
    color: ${Colors.TextSecondary};
    height: 18px;
    margin: 2px 0px 0px 0px;
  }
  .heading {
    font: ${fonts.small.medium};
    cursor: pointer;
    color: ${Colors.PrimaryColor};
    min-height: 20px;
    height: 100%;
    padding: 2px 0px 0px 0px;
  }
  .empty {
    height: Calc(100vh - 500px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .description {
    color: ${Colors.TextSecondary};
    font: ${fonts.medium.regular};
    line-height: ${Variables.LineHeightSm};
  }
  .ml-8 {
    margin-left: 8px;
  }
  .ant-drawer-wrapper-body {
    flex-direction: column;
  }
  .text-regular {
    font: ${fonts.small.regular};
    height: 16px;
  }
  .user-profile {
    margin: 3px 5px 0px 0px;
  }
`;

const HeaderView = styled.div`
  & div.user-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .employee-name {
      font: ${fonts.xSmall.bold};
      margin-left: 0;
    }
    .employee-designation {
      font: ${fonts.xxSmall.bold};
      margin-left: 0;
    }
  }
`;
const UserTaskDrawer = (props) => {
  const [isActive, setIsActive] = useState('1');
  const [tabIndex, setTabIndex] = useState('0');
  const [isReCheckedInToday, setIsReCheckedInToday] = useState(true);
  const [users, setUsers] = useState(EMPTY_ARRAY);
  const borderClass = { 2: 'greenBorder', 3: 'greenBorder' };
  const {
    status,
    onClose,
    visible,
    taskStatus = false,
    time,
    firstName,
    lastName,
    profileImage,
    userId,
    yesterday = EMPTY_ARRAY,
    today = EMPTY_ARRAY,
    blocker = EMPTY_ARRAY,
    userRole,
    date,
    standUpLoading,
    hasStandupEntries,
    emoji,
    allowed,
    email,
  } = props;

  const badgeColor = {
    DONE: Colors.SuccessGreen,
    CHECKEDIN: Colors.Working,
    ONBREAK: Colors.OnBreak,
  };

  const [getTeamActivity, { data, loading }] = useLazyQuery(GET_TEAM_ACTIVITY, {
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });

  const [user, { data: userInfo, loading: userDataLoading }] = useLazyQuery(
    GET_USER,
    {
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    }
  );

  useEffect(() => {
    if (visible && userId) {
      getTeamActivity({
        variables: {
          where: {
            userId,
            fromDateTime: moment(date).format('YYYY-MM-DD'),
            toDateTime: moment(date).add(1, 'days').format('YYYY-MM-DD'),
          },
        },
      });
      user({
        variables: {
          where: { id: userId },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, visible, userId]);

  useEffect(() => {
    eva.replace();
  });

  const TabRender = (key) => {
    setIsActive(key);
    setTabIndex(key);
  };
  const activityMessage = (val) => {
    switch (val?.platformKey) {
      case PLATFORM_TYPE.GITHUB:
      case PLATFORM_TYPE.GITLAB:
      case PLATFORM_TYPE.BITBUCKET:
        return CommitMessage(val, 'teams');
      case PLATFORM_TYPE.CLICKUP:
        return val?.eventType === ACTIVITY_EVENT_TYPE.TASK_STATUS_UPDATED
          ? ClickupMessage(val, 'teams')
          : AssignedMessage(val, 'teams');
      case PLATFORM_TYPE.ZENDESK:
        return ZendeskMessage(val, 'teams');
      case PLATFORM_TYPE.ASANA:
        switch (data?.eventType) {
          case ACTIVITY_EVENT_TYPE.ATTACHMENT_CREATED:
          case ACTIVITY_EVENT_TYPE.ATTACHMENT_DELETED:
            return AsanaAttachmentMessage(val, 'teams');
          case ACTIVITY_EVENT_TYPE.STORY_ADDED:
          case ACTIVITY_EVENT_TYPE.STORY_CREATED:
          case ACTIVITY_EVENT_TYPE.STORY_REMOVED:
          case ACTIVITY_EVENT_TYPE.STORY_UNDELETED:
            return AsanaStoryMessage(val, 'teams');
          case ACTIVITY_EVENT_TYPE.TASK_CREATED:
          case ACTIVITY_EVENT_TYPE.TASK_CHANGED:
          case ACTIVITY_EVENT_TYPE.TASK_DELETED:
            return AsanaTaskMessage(val, 'teams');
          case ACTIVITY_EVENT_TYPE.COMMENT_ADDED:
          case ACTIVITY_EVENT_TYPE.COMMENT_CREATED:
          case ACTIVITY_EVENT_TYPE.COMMENT_UPDATED:
          case ACTIVITY_EVENT_TYPE.COMMENT_DELETED:
            return AsanaCommentMessage(val, 'teams');
          default:
            break;
        }
        break;
      case PLATFORM_TYPE.JIRA:
        switch (data?.eventType) {
          case ACTIVITY_EVENT_TYPE.SPRINT_STARTED:
          case ACTIVITY_EVENT_TYPE.SPRINT_CLOSED:
            return JiraSprintMessage(val, 'teams');
          case ACTIVITY_EVENT_TYPE.ISSUE_CREATED:
          case ACTIVITY_EVENT_TYPE.ISSUE_DELETED:
          case ACTIVITY_EVENT_TYPE.ISSUE_UPDATED:
            return JiraIssueMessage(val, 'teams');
          case ACTIVITY_EVENT_TYPE.COMMENT_ADDED:
          case ACTIVITY_EVENT_TYPE.COMMENT_CREATED:
          case ACTIVITY_EVENT_TYPE.COMMENT_UPDATED:
          case ACTIVITY_EVENT_TYPE.COMMENT_DELETED:
            return JiraCommentMessage(val, 'teams');
          case ACTIVITY_EVENT_TYPE.ATTACHMENT_CREATED:
          case ACTIVITY_EVENT_TYPE.ATTACHMENT_UPDATED:
          case ACTIVITY_EVENT_TYPE.ATTACHMENT_DELETED:
            return JiraAttachmentMessage(val, 'teams');
          default:
            break;
        }
        break;
      default:
        break;
    }
  };
  const hasData = filter(
    data?.getActivitiesByPlatform,
    (val) => val?.count > 0
  );

  return (
    <Content>
      <Drawer
        destroyOnClose
        className="ant-drawer"
        width={480}
        title={
          <HeaderView>
            <Row align="middle" justify="space-between">
              <div className="user-profile">
                <UserProfile
                  bold
                  dropdown={false}
                  designation={userInfo?.user?.title || userRole}
                  stackedName={false}
                  userId={userId}
                  size={32}
                  showLink={false}
                  badgeColor={badgeColor[status]}
                  name={
                    firstName ? `${firstName} ${lastName||''}` : email
                  }
                  profile={profileImage}
                  badge
                  emoji={emoji}
                />
                <div>
                  {time && (
                    <TimeExpanded justify="end">
                      <div>Checked In</div>
                      <DateTime
                        className="time"
                        date={time}
                        format={MomentFormat.time}
                      />
                    </TimeExpanded>
                  )}
                </div>
              </div>
            </Row>
          </HeaderView>
        }
        placement="right"
        closable={false}
        onClose={() => {
          onClose();
          setIsActive('1');
        }}
        visible={visible}
      >
        <Tabs
          defaultActiveKey="1"
          onChange={TabRender}
          className={`${borderClass[tabIndex]}`}
        >
          <TabPane
            tab={
              <span>
                <img
                  className="cal-icon"
                  width="20px"
                  src={isActive === '1' ? DailyGoalsDark : DailyGoalsLight}
                  alt="icon"
                />
              </span>
            }
            key="1"
          >
            <div className="scroll">
              {standUpLoading ? (
                <Loader size="small" />
              ) : hasStandupEntries && today?.count > 0 ? (
                <>
                  <TabBody>
                    <Row align="middle">
                      <Col className="main-icon">
                        <img
                          className="cal-icon"
                          width="20px"
                          src={YesterdayIcon}
                          alt="icon"
                        />
                        <Col className="title ml-8 ">
                          {yesterday?.count > 0 ? (
                            <div>{yesterday?.questionText}</div>
                          ) : (
                            <FormattedMessage id="standUps.formLabel.noYesterdayGoal" />
                          )}
                        </Col>
                      </Col>
                    </Row>
                    {map(
                      filter(
                        yesterday?.standUpAnswers,
                        (answer) => answer?.content?.isChecked
                      ),
                      (answer) => (
                        <div className="checkbox text flex-box">
                          <Checkbox
                            disabled
                            checked={answer?.checked}
                            className={`checkbox-disabled ${
                              !answer?.checked && 'unchecked'
                            }`}
                          >
                            {taskFormat(answer?.content?.task)}
                          </Checkbox>
                        </div>
                      )
                    )}
                  </TabBody>
                  <Divider />
                  <TabBody>
                    <Row align="middle">
                      <Col className="main-icon">
                        <img
                          className="cal-icon"
                          width="20px"
                          src={CalenderPrimary}
                          alt="icon"
                        />
                        <Col className="title ml-8">
                          <div>{today?.questionText}</div>
                        </Col>
                      </Col>
                    </Row>
                    {map(today?.standUpAnswers, (answer) => (
                      <div className="checkbox text flex-box">
                        <Checkbox
                          disabled
                          checked={answer?.checked}
                          className={`checkbox-disabled-primary ${
                            !answer?.checked && 'unchecked'
                          }`}
                        >
                          {taskFormat(
                            answer?.content?.task,
                            answer?.addedLater
                          )}
                        </Checkbox>
                      </div>
                    ))}
                  </TabBody>
                  <Divider />
                  <TabBody>
                    <Row align="middle">
                      <Col className="main-icon">
                        <img
                          className="cal-icon"
                          width="20px"
                          src={BlockerPrimary}
                          alt="icon"
                        />
                        <Col className="title ml-8 ">
                          {blocker?.count > 0 ? (
                            <div>{blocker?.questionText}</div>
                          ) : (
                            <FormattedMessage id="standUps.formLabel.noBlocker" />
                          )}
                        </Col>
                      </Col>
                    </Row>
                    {map(blocker?.standUpAnswers, (answer) => (
                      <div className="checkbox text flex-box">
                        <Checkbox
                          disabled
                          checked={answer?.checked}
                          className={`checkbox-disabled-primary ${
                            !answer?.checked && 'unchecked'
                          }`}
                        >
                          {taskFormat(
                            answer?.content?.task,
                            answer?.addedLater
                          )}
                        </Checkbox>
                      </div>
                    ))}
                  </TabBody>
                </>
              ) : (
                <div className="empty">
                  <Empty
                    image={Todos}
                    imageStyle={{
                      height: 60,
                    }}
                    description={
                      <span>
                        <div className="description">
                          {!allowed && isNumber(today?.count)
                            ? 'Tasks visibility is hidden'
                            : 'Not yet checked in!'}
                        </div>
                      </span>
                    }
                  />
                </div>
              )}
            </div>
          </TabPane>

          <TabPane
            tab={
              <span>
                <img
                  className="cal-icon"
                  width="20px"
                  src={isActive === '2' ? ActivityDark : ActivityLight}
                  alt="icon"
                />
              </span>
            }
            key="2"
          >
            <div className="scroll">
              {loading ? (
                <Loader size="small" />
              ) : hasData?.length > 0 ? (
                map(data?.getActivitiesByPlatform, (val) => (
                  <>
                    {val?.count > 0 && (
                      <>
                        <TabBody>
                          <Row align="middle">
                            <Col className="main-icon">
                              {' '}
                              <img
                                className="cal-icon"
                                width="20px"
                                src={
                                  ActivityData?.[val?.platformKey]?.title?.icon
                                }
                                alt="icon"
                              />
                            </Col>
                            <Col className="title">
                              {ActivityData?.[val?.platformKey]?.title?.heading}
                            </Col>
                          </Row>

                          {map(val?.activities, (activity, index) => (
                            <div key={index}>
                              <Row className="time-activity">
                                <Col>
                                  <DateTime
                                    className="time"
                                    date={activity?.addedAt}
                                    format={
                                      moment(activity?.addedAt).date() ===
                                      moment().date()
                                        ? '[Today, ]LT'
                                        : MomentFormat.lll
                                    }
                                  />
                                </Col>
                              </Row>
                              <div className="activity text">
                                <span className="border-today">&nbsp;</span>
                                {activityMessage(activity)}
                              </div>
                            </div>
                          ))}
                        </TabBody>
                        <Divider />
                      </>
                    )}
                  </>
                ))
              ) : (
                <div className="empty">
                  <Empty
                    image={NoActivity}
                    imageStyle={{
                      height: 60,
                    }}
                    description={
                      <span>
                        <div className="description">
                          No activities performed
                        </div>
                      </span>
                    }
                  />
                </div>
              )}
            </div>
          </TabPane>
          <TabPane
            tab={
              <span>
                <img
                  className="cal-icon"
                  width="20px"
                  src={isActive === '3' ? Info : InfoLight}
                  alt="icon"
                />
              </span>
            }
            key="3"
          >
            <div className="scroll">
              {userDataLoading ? (
                <Loader size="small" />
              ) : (
                <TabBody>
                  <Row className="top-row" justify="space-between">
                    <Col span={12}>
                      <Row align="middle">
                        <Col className="icon">
                          <i
                            data-eva="phone-outline"
                            data-eva-height="14"
                            data-eva-width="14"
                            data-eva-fill="#515983"
                          />
                        </Col>
                        <Col className="info-text ">Contact number</Col>
                      </Row>
                      <Row className="info">
                        {userInfo?.user?.contact?.length > 0
                          ? `${userInfo?.user?.contact?.slice(
                              0,
                              3
                            )} ${userInfo?.user?.contact?.slice(3)}`
                          : '-'}
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row align="middle">
                        <Col className="icon">
                          <i
                            data-eva="email-outline"
                            data-eva-height="14"
                            data-eva-width="14"
                            data-eva-fill="#515983"
                          />
                        </Col>
                        <Col className="info-text ">Email id</Col>
                      </Row>
                      <Row className="info">
                        {userInfo?.user?.originalEmail || userInfo?.user?.email}
                      </Row>
                    </Col>
                  </Row>
                  <Row justify="space-between" className="row-bottom">
                    <Col span={12}>
                      <Row align="middle">
                        <Col className="icon">
                          <i
                            data-eva="pin-outline"
                            data-eva-height="14"
                            data-eva-width="14"
                            data-eva-fill="#515983"
                          />
                        </Col>
                        <Col className="info-text ">Location</Col>
                      </Row>
                      <Row className="info">
                        {userInfo?.user?.userLocation || '-'}
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row align="middle">
                        <Col className="icon">
                          <img width="14px" src={Building} alt="icon" />
                        </Col>
                        <Col className="info-text ">Department</Col>
                      </Row>
                      <Row className="info">
                        {userInfo?.user?.department?.name || '-'}
                      </Row>
                    </Col>
                  </Row>
                  {/* for future use */}
                  {/* <Card padding={16}>
                    <Row justify="space-between" align="middle">
                      <Col className="activity-text">
                        Track activities of this employee
                      </Col>
                      <Col>
                        <Button primary name="View analytics" />
                      </Col>
                    </Row>
                  </Card> */}
                </TabBody>
              )}
            </div>
          </TabPane>
        </Tabs>
      </Drawer>
    </Content>
  );
};

export default UserTaskDrawer;
