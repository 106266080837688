import { gql } from '@apollo/client';
import { userFragment, groupFragment, activityLogFragment } from './Fragments';

export const GET_ALL_ANNOUNCEMENTS = gql`
  query announcements($where: announcementWhereInput) {
    announcements(where: $where) {
      announcements {
        id
        title
        isRead
        company {
          id
        }
        groups {
          ...GroupFragment
        }
        body
        createdBy {
          ...UserFragment
        }
        status
        updatedAt
        totalReaders
        sequenceNo
        readBy
      }
      count
    }
  }
  ${userFragment}
  ${groupFragment}
`;

export const GET_ANNOUNCEMENT_BY_ID = gql`
  query announcement($where: announcementWhereUniqueInput) {
    announcement(where: $where) {
      id
      title
      company {
        id
      }
      groups {
        ...GroupFragment
      }
      body
      createdBy {
        ...UserFragment
      }
      status
      updatedAt
      assignTodos
      sequenceNo
    }
  }
  ${userFragment}
  ${groupFragment}
`;

export const IS_ANNOUNCEMENT_READ = gql`
  query isAnnouncementRead($where: readAnnouncementInput!) {
    isAnnouncementRead(where: $where)
  }
`;
