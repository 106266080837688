import { Popover as AntPopover } from 'antd';
import styled from 'styled-components';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { scrollYContainer, x } from 'design/Styled';

export const Profile = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
`;

export const Popover = styled(AntPopover)`
  cursor: pointer;

  &.ant-popover-inner-content {
    padding: 0px !important;
    font: ${fonts.xSmall.regular};
  }

  &.anticon {
    width: 34px;
    cursor: pointer;
  }
`;

export const PopoverContent = styled.div`
  font-size: ${fonts.small.medium};
  margin: -12px -16px;
  width: 247px;
  .ant-input-suffix {
    display: flex;
    align-items: center;
  }

  input.ant-input::placeholder {
    color: ${Colors.TextMedium};
  }

  .popover-input:focus,
  .ant-input-affix-wrapper-focused {
    border: none;
    box-shadow: none;
  }

  input,
  .popover-input {
    background: ${Colors.PopoverInput};
    border: none;
  }

  .popover-input {
    min-height: 45px;
    border-radius: 14px;
  }

  .ant-input-prefix {
    align-self: center;
  }
`;

export const Member = styled.div`
  overflow: auto;
  ${scrollYContainer};
  max-height: 178px;
  font: ${fonts.small.regular};
  color: ${Colors.Text};
  padding: 10px 0;
  position: relative;

  .row-member {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    width: 100%;
    outline: none;
  }

  .row-member:hover {
    background-color: ${Colors.DefaultBackground};
    border-radius: 4px;
  }

  div {
    cursor: pointer;
  }

  div:last-child {
    margin-bottom: 0;
  }

  img {
    border-radius: 50%;
  }

  .anticon.anticon-close-circle {
    display: none;
    position: absolute;
    left: 30px;
    margin-top: -12px;
  }

  .delete-able:hover .anticon.anticon-close-circle {
    display: inline-block;
  }
`;

export const Labels = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: ${Colors.MemberCircle};
  border-radius: 50%;
  font-style: normal;
  color: ${Colors.Text};
`;

export const UserProfile = styled.div`
  ${x};
  width: auto;
  white-space: nowrap;
  padding: 0px 10px;
`;
export const AvatarSpan = styled.span`
  &.name {
    color: ${Colors.TextSecondary};
    font: ${fonts.small.medium};
  }
`;
export const Delete = styled.img`
  display: none;
`;
export const Widget = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 247px;
  .avatar {
    display: flex;
    align-items: center;
  }
  :hover {
    ${Delete} {
      display: block;
    }
  }
`;
