import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ACTIONS_TYPE, PRIVATE_ROUTE } from 'utils/constants';
import ability from 'utils/ability/ability';
import { FeatureActivation } from 'features/components/FeatureActivation';
import { AppContext } from 'AppContext';
import InfoIcon from 'atoms/InfoIcon';
import { FormattedMessage } from 'react-intl';
import { FeatureDeactivation } from 'features/components/FeatureDeactivation';
import SopSettings from './SopSettings';

const Sops = () => {
  const [activated, setActivated] = useState(
    ability.can('use', 'standard_procedures')
  );
  const { dispatch } = useContext(AppContext);
  const Title = () => <InfoIcon title={<FormattedMessage id="sops.title" />} />;

  useEffect(() => {
    dispatch({ type: ACTIONS_TYPE.SETTING_TITLE, data: <Title /> });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!activated) {
    return (
      <FeatureActivation
        featureKey="standard_procedures"
        setActivated={setActivated}
      />
    );
  }

  return (
    <div>
      {/* <SopSettings /> //todo: add settings for SOP */}
      <FeatureDeactivation
        featureKey="standard_procedures"
        setActivated={setActivated}
      />
    </div>
  );
};

export default {
  component: withRouter(Sops),
  path: PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.SOPS,
  key: 'sops',
  abilityAction: 'manage',
  abilitySubject: 'sops',
  icon: (
    <i data-eva="list-outline" data-eva-height="20" data-eva-width="20"></i>
  ),
  text: <FormattedMessage id="sops.sidebarTitle" />,
};
