import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ACTIONS_TYPE, PRIVATE_ROUTE } from 'utils/constants';
import ability from 'utils/ability/ability';
import { FeatureActivation } from 'features/components/FeatureActivation';
import { AppContext } from 'AppContext';
import InfoIcon from 'atoms/InfoIcon';
import { FormattedMessage } from 'react-intl';
import { FeatureDeactivation } from 'features/components/FeatureDeactivation';
import PeopleSettings from './PeopleSettings';

const People = () => {
  const [activated, setActivated] = useState(
    ability.can('use', 'people_feature')
  );
  const { dispatch } = useContext(AppContext);
  const Title = () => (
    <InfoIcon
      title={<FormattedMessage id="settings.people.settingsHeader" />}
    />
  );

  useEffect(() => {
    dispatch({ type: ACTIONS_TYPE.SETTING_TITLE, data: <Title /> });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!activated) {
    return (
      <FeatureActivation
        featureKey="people_feature"
        setActivated={setActivated}
      />
    );
  }

  return (
    <div>
      <PeopleSettings />
      <FeatureDeactivation
        featureKey="people_feature"
        setActivated={setActivated}
      />
    </div>
  );
};

export default {
  component: withRouter(People),
  path: PRIVATE_ROUTE.SETTINGS.SUB_ROUTE.PEOPLE_SETTINGS,
  key: 'people',
  abilityAction: 'manage',
  abilitySubject: 'people',
  icon: (
    <i data-eva="download-outline" data-eva-height="20" data-eva-width="20"></i>
  ),
  text: <FormattedMessage id="settings.people.featureTitle" />,
};
