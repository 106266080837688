import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import _map from 'lodash/map';
import { Col, Form, Row, message, Skeleton } from 'antd';
import { titleCase } from 'utils/stringUtilities';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ArrowIcon, DeleteIcon } from 'assets';
import client from 'apollo';
import PopConfirm from 'atoms/PopConfirmation';
import Button from 'atoms/Button';
import Card from 'atoms/Card';
import Input from 'atoms/Input';
import InlineEditable from 'molecules/InlineEditable';
import {
  EMPTY_ARRAY,
  FETCH_POLICY,
  BUTTON_TYPE,
  FORM_NAME,
  EMPTY_OBJECT,
} from 'utils/constants';
import { GET_LEAVE_SETTINGS } from 'features/people/graphql/Settings/People/Queries';
import { GET_LOCATION_YEAR_START } from 'graphql/Settings/Location/Queries';
import {
  CREATE_LEAVE_SETTINGS,
  DELETE_LEAVE_SETTINGS,
  UPDATE_LEAVE_SETTINGS,
} from 'features/people/graphql/Settings/People/Mutations';
import * as Sentry from '@sentry/react';
import { UPDATE_LOCATION } from 'graphql/Settings/Location/Mutations';
import { confirmationModal } from 'atoms/ConfirmationModal';
import _has from 'lodash/has';
import { leaveStatus, monthsData } from './MockData';
import { ManageLeavesSection, Image, Select, Arrow } from './AntStyled';
const { Option } = Select;

const Leaves = ({ locationId, intl }) => {
  const [form] = Form.useForm();
  const [yearStartForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const {
    data: leaveSettingsData,
    loading: leaveSettingsDataLoading,
  } = useQuery(GET_LEAVE_SETTINGS, {
    fetchPolicy: FETCH_POLICY.CACHE_FIRST,
    variables: {
      where: { locationId: parseInt(locationId, 10) },
    },
  });
  const { data: locationData, loading: locationLoading } = useQuery(
    GET_LOCATION_YEAR_START,
    {
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      variables: {
        where: {
          id: locationId,
        },
      },
    }
  );
  const [dataSource, setDataSource] = useState(EMPTY_ARRAY);
  const monthsChildren = _map(monthsData, (list, index) => (
    <Option key={index} value={list.value}>
      {list.month}
    </Option>
  ));
  const leaveStatusChildren = _map(leaveStatus, (list, index) => (
    <Option key={index} value={list.status}>
      {list.status}
    </Option>
  ));
  const [initialYearStart, setInitialYearStart] = useState(EMPTY_OBJECT);
  const [updateLocation] = useMutation(UPDATE_LOCATION);
  const [hasYearStart, setHasYearStart] = useState(false);

  useEffect(() => {
    if (leaveSettingsData && leaveSettingsData.leaveSettings) {
      let leaveSettingsTableData = EMPTY_ARRAY;
      leaveSettingsTableData = _map(
        leaveSettingsData.leaveSettings,
        (data, key) => ({
          key: key.toString(),
          id: data.id,
          type: data.type,
          isPaid: data.isPaid,
          totalLeaves: data.totalLeaves,
          carryForward: data.carryForward,
          createdAt: data.createdAt,
          updatedBy: data.updatedBy,
        })
      );
      setDataSource(leaveSettingsTableData);
    }
  }, [leaveSettingsData]);

  useEffect(() => {
    if (_has(locationData, 'location.yearStart') && !locationLoading) {
      const { yearStart } = locationData.location;
      if (yearStart) {
        const date = yearStart.split('-')[1];
        const month = yearStart.split('-')[0];
        setInitialYearStart({ date, month });
        setHasYearStart(true);
      }
    }
  }, [locationData, locationLoading]);

  useEffect(() => {
    yearStartForm.resetFields();
  }, [yearStartForm, initialYearStart]);

  const inLineEditable = (dataIndex, inputRef, save) => {
    let node;
    switch (dataIndex) {
      case 'type':
        node = (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                whitespace: true,
                message: (
                  <FormattedMessage id="settings.leaves.manageLeaves.formRules.type.errorMessage" />
                ),
              },
            ]}
          >
            <Input
              placeholder="Leave name"
              innerRef={inputRef}
              onPressEnter={save}
              onBlur={save}
            />
          </Form.Item>
        );
        break;
      case 'isPaid':
        node = (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="settings.leaves.manageLeaves.formRules.paid.errorMessage" />
                ),
              },
            ]}
          >
            <Select
              placeholder="Select leave type"
              allowClear
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              getPopupContainer={(trigger) => trigger?.parentNode}
            >
              {leaveStatusChildren}
            </Select>
          </Form.Item>
        );
        break;
      default:
        node = (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                whitespace: true,
                message: (
                  <FormattedMessage id="settings.leaves.manageLeaves.formRules.leave.errorMessage" />
                ),
              },
            ]}
          >
            <Input
              placeholder="No of leave"
              innerRef={inputRef}
              onPressEnter={save}
              onBlur={save}
            />
          </Form.Item>
        );
        break;
    }
    return node;
  };

  const columns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      title: 'Leave Types',
      dataIndex: 'type',
      key: 'type',
      width: '25%',
      render: (type, rowData) =>
        rowData.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : (
          titleCase(type)
        ),
    },
    {
      title: 'Paid/Unpaid',
      dataIndex: 'isPaid',
      width: '25%',
      editable: true,
    },
    {
      title: 'Number of',
      dataIndex: 'totalLeaves',
      width: '17%',
      editable: true,
    },
    {
      title: 'Carry Forward',
      dataIndex: 'carryForward',
      width: '28%',
      editable: true,
    },
    {
      align: 'center',
      width: '10%',
      dataIndex: 'operation',
      render: (text, record) =>
        dataSource.length >= 1 ? (
          <PopConfirm
            placement="bottomRight"
            title="Are you sure to delete this leave?"
            onConfirm={() => deleteLeaveSetting(record.id)}
            icon={<Image src={DeleteIcon} className="icon-delete" />}
          />
        ) : null,
    },
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];

  const createLeaveSetting = async (values) => {
    setLoading(true);
    const { type, isPaid, totalLeaves, carryForward } = values;
    await client
      .mutate({
        mutation: CREATE_LEAVE_SETTINGS,
        variables: {
          data: {
            type: type.toUpperCase(),
            isPaid: isPaid.toUpperCase(),
            totalLeaves: parseInt(totalLeaves, 10),
            carryForward: parseInt(carryForward, 10),
            locationId: parseInt(locationId, 10),
          },
        },
        refetchQueries: [
          {
            query: GET_LEAVE_SETTINGS,
            variables: { where: { locationId: parseInt(locationId, 10) } },
            fetchPolicy: FETCH_POLICY.CACHE_FIRST,
          },
        ],
      })
      .then(() => {
        message.destroy();
        message.success(
          intl.formatMessage({
            id: 'settings.leaves.manageLeaves.successMessages.addLeave',
          })
        );
        form.resetFields();
        setLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.log(error);
        setLoading(false);
      });
  };

  const updateLeaveSetting = async (row) => {
    setLoading(true);
    await client
      .mutate({
        mutation: UPDATE_LEAVE_SETTINGS,
        variables: {
          data: {
            type: row.type,
            isPaid: row.isPaid.toUpperCase(),
            totalLeaves: parseInt(row.totalLeaves, 10),
            carryForward: parseInt(row.carryForward, 10),
          },
          where: { id: row.id },
        },
        refetchQueries: [
          {
            query: GET_LEAVE_SETTINGS,
            variables: { where: { locationId: parseInt(locationId, 10) } },
            fetchPolicy: FETCH_POLICY.CACHE_FIRST,
          },
        ],
      })
      .then(() => {
        message.destroy();
        message.success(
          intl.formatMessage({
            id: 'settings.leaves.manageLeaves.successMessages.updateLeave',
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.log(error);
        setLoading(false);
      });
  };

  const deleteLeaveSetting = async (id) => {
    try {
      await client.mutate({
        mutation: DELETE_LEAVE_SETTINGS,
        variables: {
          where: { id: parseInt(id, 10) },
        },
        refetchQueries: [
          {
            query: GET_LEAVE_SETTINGS,
            variables: { where: { locationId: parseInt(locationId, 10) } },
            fetchPolicy: FETCH_POLICY.CACHE_FIRST,
          },
        ],
      });
      message.destroy();
      message.success(
        intl.formatMessage({
          id: 'settings.leaves.manageLeaves.successMessages.deleteLeave',
        })
      );
      // eslint-disable-next-line no-empty
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleYearStartChange = (e) => {
    const { date, month } = e;
    const yearStart = `${month}-${date}`;
    const confirmYearUpdate = () => {
      updateLocation({
        variables: {
          where: {
            id: locationId,
          },
          data: {
            yearStart,
          },
        },
      })
        .then(() => {
          setHasYearStart(true);
          message.destroy();
          message.success('Year start date added successfully');
        })
        .catch((error) => {
          Sentry.captureException(error);
          // eslint-disable-next-line no-console
          console.log(error);
        });
    };
    confirmationModal(
      'This action cannot be reverted?',
      confirmYearUpdate,
      null,
      'Update'
    );
  };

  return (
    <ManageLeavesSection>
      <FormattedMessage id="settings.leaves.manageLeaves.startdate" />
      <Form
        initialValues={initialYearStart}
        onFinish={handleYearStartChange}
        form={yearStartForm}
      >
        <Row className="row">
          <Col span={5}>
            <Form.Item name="date">
              <Input
                disabled={hasYearStart}
                className="input"
                placeholder="Number"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="month">
              <Select
                disabled={hasYearStart}
                suffixIcon={<Arrow src={ArrowIcon} />}
                placeholder="Month"
                className="drop-down"
                getPopupContainer={(trigger) => trigger?.parentNode}
              >
                {monthsChildren}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item hidden={hasYearStart}>
              <Button
                htmlType="submit"
                tableButton
                className="year-update"
                name="Update"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Card>
        <InlineEditable
          border="true"
          dataSource={dataSource}
          columns={columns}
          updateData={updateLeaveSetting}
          inLineEditable={inLineEditable}
          loading={leaveSettingsDataLoading || loading}
        >
          <Form
            layout={FORM_NAME.INLINE}
            onFinish={createLeaveSetting}
            form={form}
          >
            <Col span={4}>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: (
                      <FormattedMessage id="settings.leaves.manageLeaves.formRules.type.errorMessage" />
                    ),
                  },
                ]}
              >
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item
                name="isPaid"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="settings.leaves.manageLeaves.formRules.paid.errorMessage" />
                    ),
                  },
                ]}
              >
                <Select
                  small
                  placeholder="Paid/Unpaid"
                  allowClear
                  suffixIcon={<Arrow src={ArrowIcon} />}
                  getPopupContainer={(trigger) => trigger?.parentNode}
                >
                  {leaveStatusChildren}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item
                name="totalLeaves"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: (
                      <FormattedMessage id="settings.leaves.manageLeaves.formRules.leave.errorMessage" />
                    ),
                  },
                ]}
              >
                <Input placeholder="Number" />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item
                name="carryForward"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: (
                      <FormattedMessage id="settings.leaves.manageLeaves.formRules.leave.errorMessage" />
                    ),
                  },
                ]}
              >
                <Input placeholder="Number" />
              </Form.Item>
            </Col>
            <Col span={2} offset={1}>
              <Form.Item>
                <Button
                  type={BUTTON_TYPE.PRIMARY}
                  htmlType={BUTTON_TYPE.SUBMIT}
                  name="Add"
                />
              </Form.Item>
            </Col>
          </Form>
        </InlineEditable>
      </Card>
    </ManageLeavesSection>
  );
};

export default injectIntl(Leaves);
