import React, { useContext, useState, useEffect } from 'react';
import { Form, Divider, message, Row, Col } from 'antd';
import { NavLink } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import Colors from 'design/Colors';
import Button from 'atoms/Button';
import Meta from 'atoms/Meta';
import { AppContext } from 'AppContext';
import Card from 'atoms/Card';
import Input from 'atoms/Input';
import client from 'apollo';
import {
  FORM_NAME,
  PRIVATE_ROUTE,
  ACTIONS_TYPE,
  META_TYPE,
  BUTTON_TYPE,
  PUBLIC_ROUTE,
  AMPLITUDE_EVENTS,
  REGEX,
  LINKS,
} from 'utils/constants';
import {
  GoogleIcon,
  MicrosoftIcon,
  SlackIcon,
  UndifferLogo,
  UserIcon,
  Eye,
} from 'assets';
import {
  GOOGLE_AUTH_LOGIN,
  MICROSOFT_AUTH_LOGIN,
  SLACK_AUTH_LOGIN,
  USER_LOGIN,
} from 'graphql/Onboarding/Mutations';
import * as Sentry from '@sentry/react';
import firebase from 'analytics';
import { getTrimValues } from 'utils';
import { useForm } from 'antd/lib/form/util';
import { createAmplitudeEvent } from 'utils/amplitude';
import jwt_decode from 'jwt-decode';
import {
  Container,
  GoogleLogin,
  Or,
  SignupPage,
  Title,
  Wrapper,
  SIcon,
  Background,
  StyledInput,
  StyledForm,
  BtnWrapper,
  Logo,
  InputIcon,
  StyledDivider,
  Terms,
  FullDivider,
} from './AntStyled';
import TermsAndPolicy from '../TermsAndPolicy';
import OneSignal from 'react-onesignal';

export default function (props) {
  const { history } = props;
  const { dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const qs = new URLSearchParams(history.location.search);
  const error = qs.get('error');
  const token = qs.get('token');
  const [form] = useForm();
  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      history.push(PRIVATE_ROUTE.DASHBOARD);
    }
  }, [history, token]);

  const onFinish = async (values) => {
    setLoading(true);
    const trimValues = getTrimValues(values);
    const { email, password } = trimValues;
    try {
      await client
        .mutate({
          mutation: USER_LOGIN,
          variables: {
            data: {
              email: email.toLowerCase(),
              password,
            },
          },
        })
        .then((res) => {
          const token = res?.data?.login;
          const loginUser = token && jwt_decode(token);
          setLoading(false);
          const eventProperties = {
            Id: loginUser?.id,
            Role: loginUser?.role,
            CompanyIds: loginUser?.companyIds,
            Email: loginUser?.email,
          };
          if (token) {
            OneSignal.init({
              appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
              serviceWorkerParam: { scope: '/static/oneSignal/' },
              serviceWorkerPath: '/static/oneSignal/',
            });
            OneSignal.showSlidedownPrompt();
          }
          createAmplitudeEvent(AMPLITUDE_EVENTS.USER_LOGIN, eventProperties);
          localStorage.setItem('token', res?.data?.login);
          firebase.analytics().logEvent('login_users');
          history.push(PRIVATE_ROUTE.DASHBOARD);
          dispatch({ type: ACTIONS_TYPE.LOGIN_USER, data: true });
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onFinishFailed = () => {
    //
  };

  const handleFieldsChange = (e) => {
    form.setFieldsValue({ [e?.[0]?.name[0]]: e?.[0]?.value?.trim() });
  };

  return (
    <Container>
      <Background>
        <Logo src={UndifferLogo} alt="logo"></Logo>
        <NavLink to="/help" className="bottom-right">
          <FormattedMessage id="terms.help" />
        </NavLink>
        <NavLink to="/copyright" className="bottom-left">
          <FormattedMessage id="terms.copyright" />
        </NavLink>
      </Background>

      <Wrapper>
        <Card padding="36px 36px 24" className="small">
          <Meta title={META_TYPE.LOGIN} />
          <StyledForm
            onFieldsChange={handleFieldsChange}
            name={FORM_NAME.BASIC}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            form={form}
            centered
            requiredMark={false}
            initialValues={{ remember: true }}
          >
            <Title>
              <FormattedMessage id="login.title" />
            </Title>
            <Form.Item
              required={false}
              colon={false}
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="login.formRules.email.errorMessage" />
                  ),
                },
                {
                  pattern: REGEX.EMAIL,
                  message: (
                    <FormattedMessage id="signUp.formRules.email.emailPattern" />
                  ),
                },
              ]}
            >
              <Input
                suffix={<InputIcon src={UserIcon} />}
                placeholder="johndoe@abc.xyz"
                allowClear
              />
            </Form.Item>
            <Form.Item
              required={false}
              label="Password"
              colon={false}
              name="password"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="login.formRules.password.errorMessage" />
                  ),
                },
              ]}
            >
              <Input type="password" placeholder="Enter your password" />
            </Form.Item>
            <Form.Item>
              <BtnWrapper>
                <NavLink className="anchor" to="/forgot-password">
                  <FormattedMessage id="login.forgot" />
                </NavLink>
                <Button
                  className="accent-btn"
                  accent
                  type={BUTTON_TYPE.PRIMARY}
                  htmlType={BUTTON_TYPE.SUBMIT}
                  name="Confirm"
                  loading={loading}
                />
              </BtnWrapper>
              <StyledDivider>OR</StyledDivider>
              <Or>
                <FormattedMessage id="login.follow" />
              </Or>
              <GoogleLogin>
                <Button
                  background={Colors.Icons}
                  border={Colors.Icons}
                  focus={Colors.Icons}
                  icon={<SIcon src={GoogleIcon} />}
                  iconbtn
                  onClick={() => {
                    client
                      .mutate({ mutation: GOOGLE_AUTH_LOGIN })
                      .then((response) => {
                        firebase.analytics().logEvent('login_users');
                        window.location = response.data.loginWithGoogle;
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }}
                />
                <Button
                  disabled
                  background={Colors.Icons}
                  border={Colors.Icons}
                  focus={Colors.Icons}
                  icon={<SIcon src={MicrosoftIcon} />}
                  iconbtn
                />
                <Button
                  disabled
                  background={Colors.Icons}
                  border={Colors.Icons}
                  focus={Colors.Icons}
                  icon={<SIcon src={SlackIcon} />}
                  iconbtn
                />
              </GoogleLogin>
            </Form.Item>
          </StyledForm>
          <FullDivider></FullDivider>
          <SignupPage>
            <FormattedMessage id="login.account" />
            &nbsp;
            <NavLink to={PUBLIC_ROUTE.SIGNUP} className="signup">
              <FormattedMessage id="login.signUp" />
            </NavLink>
          </SignupPage>
        </Card>
        <TermsAndPolicy />
      </Wrapper>
    </Container>
  );
}
