import React from 'react';
import { Row, Col } from 'antd';
import UserProfile from 'molecules/UserProfile';
import { Content, StyledText } from './AntStyled';

export default function (props) {
  const { generator, data } = props;
  return (
    <Content>
      <Row className="notification column">
        <Col className="users justify-space-between">
          <div className="flex align-items-center">
            {generator && (
              <UserProfile
                size={18}
                stackedName={false}
                profile={generator?.profileImage}
                name={
                  generator?.firstName && generator?.lastName
                    ? `${generator?.firstName} ${generator?.lastName}`
                    : generator?.email
                }
              />
            )}
          </div>
        </Col>
        {data?.description && (
          <Col className="text-row">
            <StyledText>
              {data?.description}
            </StyledText>
          </Col>
        )}
      </Row>
    </Content>
  );
}
