/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { Menu, Popover } from 'antd';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _findKey from 'lodash/findKey';
import { Link, withRouter } from 'react-router-dom';
import * as eva from 'eva-icons';
import Avatar from 'atoms/Avatar';
import { AppContext } from 'AppContext';
import { PRIVATE_ROUTE, AVATAR_TYPE, MENU_MODE } from 'utils/constants';
import ability, { verifyAbility } from 'utils/ability/ability';
import { UndifferLogo, UndifferIcon, NavExpand } from 'assets';
import { mainPages } from 'features';
import { filter, findIndex, indexOf } from 'lodash';
import DropDownContent from './DropDownMenu';
import { sidebarMenuSection1 } from './MenuItems';
import {
  Header,
  MenusContent,
  Sider,
  VersionWrapper,
  StyledDivider,
  LogoWrapper,
  PopoverChildWrapper,
  Expand,
  Arrow,
  GlobalStyles,
} from './AntStyled';

const { SubMenu } = Menu;

function HeaderSection(props) {
  const { history } = props;
  const { state } = useContext(AppContext);
  const { loginUser } = state;
  const [collapsed, setCollapsed] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [activeMenu, setActiveMenu] = useState(PRIVATE_ROUTE.ME);
  const userProfile = _get(state, 'loginUser.profileImage', null);
  const company = _get(state, 'loginUser.company', null);
  const userId = _get(state, 'loginUser.id', null);
  const userName =
    _get(state, 'loginUser.firstName', null) ||
    _get(state, 'loginUser.lastName', null) ||
    _get(state, 'loginUser.email', null);

  useEffect(() => {
    const { pathname } = history?.location;
    setActiveMenu(pathname);
    switch (pathname.split('/')[1]) {
      case PRIVATE_ROUTE.KEY.UPDATES:
        setActiveMenu(PRIVATE_ROUTE.UPDATES);
        break;
      case PRIVATE_ROUTE.KEY.TODOS:
        setActiveMenu(PRIVATE_ROUTE.TODOS);
        break;
      case PRIVATE_ROUTE.KEY.PROFILE:
        setActiveMenu(PRIVATE_ROUTE.PROFILE);
        break;
      default:
        setActiveMenu(pathname);
        break;
    }
  }, [history?.location, state?.userAbility]);

  useEffect(() => {
    eva.replace();
  }, []);

  const onCollapse = (collapsedData) => {
    setCollapsed(collapsedData);
  };

  const handleClick = (e) => {
    setActiveMenu(e?.key);
    switch (e?.key) {
      case PRIVATE_ROUTE.PROFILE:
        history.push(`${e?.key}/${userId}/details`);
        break;
      default:
        history.push(e?.key);
        break;
    }
  };

  function getAbilityAction(value) {
    const key = _findKey(ability.rules, ['action.0', value]); // Return the index if value exists in ability(ex:- value = manage)
    if (key !== undefined) {
      return value; // if index found then return the value i.e 'manage' which means ability is true
    }
  }

  function getAbilitySubject(value) {
    const key = _findKey(ability.rules, ['subject.0', value]); // Return the index if value exists in ability(ex:- value = me)
    if (key !== undefined) {
      return value; // if index found then return the value i.e 'me' which means ability is true
    }
  }

  const getSubMenuChildren = (subPages) =>
    _map(subPages, (page) => {
      const { abilityAction, abilitySubject } = page;
      if (
        abilityAction &&
        abilitySubject &&
        ability.cannot(abilityAction, abilitySubject)
      ) {
        return null;
      }
      return (
        <Menu.Item style={{ marginBottom: '0px' }} key={page?.key}>
          <span className="icon">{page?.icon}</span>
          <span>{page?.name}</span>
        </Menu.Item>
      );
    });

  const sidebarMenus1 = _map(
    sidebarMenuSection1,
    (list) =>
      verifyAbility(
        getAbilityAction(list?.action),
        getAbilitySubject(list?.subject)
      ) && (
        <Menu.Item key={list?.key}>
          <span className="icon">{list?.icon}</span>
          <span>{list?.text}</span>
        </Menu.Item>
      )
  );

  const mainPagesNew =
    loginUser?.role !== 'USER'
      ? filter(mainPages, (data) => data?.type !== 'user')
      : filter(mainPages, (data) => data?.type !== 'admin');

  const featureMenu = _map(mainPagesNew, (page) => {
    const { abilityAction, abilitySubject } = page;
    if (
      abilityAction &&
      abilitySubject &&
      ability.cannot(abilityAction, abilitySubject)
    ) {
      return null;
    }
    if (!page?.subPages) {
      return (
        <Menu.Item key={page?.defaultPath || page?.key}>
          <span className="icon">{page?.icon}</span>
          <span>{page?.name}</span>
        </Menu.Item>
      );
    }
    return (
      <SubMenu
        expandIcon={
          <Expand
            data-eva="arrow-down"
            data-eva-height="16"
            data-eva-width="16"
          />
        }
        className="submenu"
        key={page?.key}
        title={
          <span className="flex">
            <span className="icon flex align-items-center">{page?.icon}</span>
            <span className="text">{page?.name}</span>
          </span>
        }
      >
        {getSubMenuChildren(page?.subPages)}
      </SubMenu>
    );
  });

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        trigger={null}
      >
        <span
          onClick={() => {
            setCollapsed(!collapsed);
            setIsActive(true);
          }}
          className="flex ant-layout-sider-zero-width-trigger ant-layout-sider-zero-width-trigger-left"
        >
          <Arrow
            src={NavExpand}
            alt="icon"
            rotate={isActive && collapsed ? 180 : 0}
          />
        </span>
        <LogoWrapper>
          <img
            src={collapsed ? UndifferIcon : UndifferLogo}
            alt="logo"
            className={collapsed ? 'only-logo' : 'logo-text'}
            onClick={() => history.push(PRIVATE_ROUTE?.DASHBOARD)}
          />
        </LogoWrapper>
        <MenusContent className="hide-scrollbar">
          <Menu
            selectedKeys={[activeMenu]}
            mode={MENU_MODE.IN_LINE}
            onClick={handleClick}
          >
            {sidebarMenus1}
          </Menu>
          {featureMenu?.length > 0 && <StyledDivider />}
          <Menu
            inlineCollapsed="false"
            selectedKeys={[activeMenu]}
            mode={MENU_MODE.IN_LINE}
            onClick={handleClick}
          >
            {featureMenu}
          </Menu>
        </MenusContent>
        <Header style={{ width: collapsed && 70 }}>
          <Popover
            autoAdjustOverflow={false}
            placement="rightBottom"
            trigger="click"
            content={
              <DropDownContent
                userName={
                  state?.loginUser?.firstName
                    ? `${state?.loginUser?.firstName} ${state?.loginUser?.lastName}`
                    : state?.loginUser?.email
                }
                company={company}
              />
            }
            getPopupContainer={(triggerNode) => triggerNode}
          >
            <PopoverChildWrapper collapsed={collapsed}>
              <Avatar
                right={collapsed ? '0px' : undefined}
                profile={userProfile}
                name={`${loginUser?.firstName} ${loginUser.lastName}`}
                size={38}
              />
              <div
                className="company-name"
                style={{ display: collapsed && 'none' }}
              >
                <div className="title">
                  <div className="user-name">
                    {state?.loginUser?.firstName
                      ? `${state?.loginUser?.firstName} ${state?.loginUser?.lastName}`
                      : state?.loginUser?.email}
                  </div>
                </div>
                <div className="subtitle">
                  <Avatar
                    shape={AVATAR_TYPE.SQUARE}
                    right={collapsed ? '0px' : undefined}
                    profile={company?.logo}
                    name={company?.name}
                    size={16}
                  />
                  {company?.name}
                </div>
              </div>
            </PopoverChildWrapper>
          </Popover>
        </Header>
        {/* might need for future */}
        {/* <VersionWrapper>v{process.env.REACT_APP_VERSION}</VersionWrapper> */}
        <GlobalStyles />
      </Sider>
    </>
  );
}

export default withRouter(HeaderSection);
