import {
  Layout, Input, Modal as AntModal, Form as AntForm,
} from 'antd';
import styled from 'styled-components';
import Spacings from 'design/Spacings';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
const { TextArea } = Input;
const { Content: AntContent } = Layout;

export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 ${Spacings.tiny.padding};
  }
  .ant-form-item-label > label {
    height: 16px;
    color: ${Colors.TextSecondary};
    font: ${fonts.xSmall.regular};
  }
  .btn-cancel-reason {
    float: right
  }
`;
export const Modal = styled(AntModal)`
  .ant-modal-body{
    padding: 20px 20px 0px;
  }
  .ant-modal-header{
    padding: 20px;
    border-bottom: 1px solid ${Colors.Border};
  }
  .ant-modal-footer {
    padding: 0px 20px 20px;
    border-top: 0px;
  }

  .ant-modal-title {
    font: ${fonts.xLarge.bold};
    line-height: 24px;
    color: ${Colors.TextDark};
  }

  .ant-modal-content{
    max-height: calc(100vh - 120px);
    overflow: auto;
    border-radius: 12px;
    border: 1px solid ${Colors.Border}
  }
`;

export const Content = styled(AntContent)`
  .button{
    color:${Colors.TextWarning};
    font: ${fonts.small.bold};
    display: flex;
    align-items: center;
  }

  .ant-table{
    border: 1px solid ${Colors.Border};
  }
  .anticon-check {
    color: ${Colors.ButtonPrimary};
    font: ${fonts.xxLarge.regular};
  }

  .anticon-info-circle {
    font-size: 20px;
    cursor: pointer;
  }
`;
export const TimeDiv = styled.div`
  font-size: calc(${fonts.xxSmall.size} - 1px);
  font-weight: 500;
  color: ${Colors.TextEnable};
`;
export const TextBox = styled(TextArea)`
  border: 1px solid ${Colors.Border};
  border-radius: 10px;
`;
export const Icon = styled.img`
`;
export const Btn = styled.img`
  width: 17px;
  height: 17px;
  margin-right: 5px;
  filter: invert(60%) sepia(57%) saturate(6899%) hue-rotate(335deg) brightness(99%) contrast(90%);
`;
export const Comments = styled.div`
  color: ${Colors.ButtonPrimary};
  font: ${fonts.medium.regular};
  white-space: nowrap;
  cursor: pointer;
`;
export const PopoverContent = styled.div`
 .title{
  color: ${Colors.TextDark};
  font: ${fonts.xSmall.bold};
  margin-bottom: 8px;
  }
  .text{
    color: ${Colors.TextSecondary};
    font: ${fonts.xxSmall.bold};
  }
`;
export const Title = styled.div`
  white-space: normal;
  word-break: break-all;
  min-width: 200px;
`;

export const LoaderDiv = styled.div`
  padding-bottom: 35px;
  padding-top: 10px;
`;
export const Hyphen = styled.span`
  color: ${Colors.GraphDarkGreen};
  padding: 0 7px;
`;

export const InlineWrapper = styled.div`
  white-space: nowrap;
`;
export const StyledTag = styled.span`
  color: ${({ textColor }) => textColor || 'inherit'};
  font-size: ${fonts.small.size};
`;
