/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Checkbox, Row, Skeleton, Spin, Col, Slider, Input } from 'antd';
import _map from 'lodash/map';
import Table from 'atoms/Table';
import Card from 'atoms/Card';
import ContentWrapper from 'atoms/ContentWrapper';
import UserProfile from 'molecules/UserProfile';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_FEEDBACK_RESPONSE } from 'features/feedbacks/graphql/Queries';
import { FETCH_POLICY, QUESTION_TYPE } from 'utils/constants';
import DateTime from 'atoms/DateTime';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import EmptyState from 'pages/EmptyState';
import { NoResponse } from 'assets';
import Loader from 'atoms/Loader';
import ResponseModal from '../../ResponseModal/index';
import { Content, Modal, QuestionTitle } from './AntStyled';
import { questions } from '../../MockData';

const { TextArea } = Input;
export default function Response() {
  const [responseId, setResponseId] = useState(null);
  const { id } = useParams();
  const { data = {}, loading } = useQuery(GET_FEEDBACK_RESPONSE, {
    variables: { where: { feedbackId: id } },
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });

  const { feedbackResponse = [] } = data;
  const [answeredBy] = feedbackResponse;

  const responseColumns = [
    {
      dataIndex: 'spanone',
      key: 'spanone',
      className: 'no-padding mw-20',
    },
    {
      title: 'Name',
      dataIndex: 'answeredBy',
      key: 'name',
      render: (user, rowData) =>
        rowData.loading ? (
          <Skeleton paragraph={{ rows: 1 }} title={false} active />
        ) : user ? (
          <UserProfile
            stackedName={false}
            profile={user?.profileImage}
            name={
              user?.firstName
                ? `${user?.firstName} ${user?.lastName || ''}`
                : user?.email
            }
            userId={user?.id}
            medium
          />
        ) : (
          '-'
        ),
    },
    {
      title: 'Took On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => (
        <DateTime date={date} format={MomentFormat.MMM}></DateTime>
      ),
    },
    {
      title: 'Questions Answered',
      dataIndex: 'questionsAnswered',
      key: 'questionsAnswered',
      width: '16%',
    },
    {
      dataIndex: 'spantwo',
      key: 'spantwo',
      className: 'no-padding mw-20',
    },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <ContentWrapper>
      {loading ? (
        <Loader />
      ) : (
        <>
          {feedbackResponse?.length > 0 ? (
            <Content>
              <Table
                className="bb-0"
                onRow={(record) => ({
                  onClick: () => {
                    setResponseId(record?.answeredBy?.id);
                    setIsModalVisible(true);
                  },
                })}
                columns={responseColumns}
                dataSource={feedbackResponse}
              />
            </Content>
          ) : (
            <EmptyState
              title="Oops!"
              description="You don’t have access to view all responses."
              image={NoResponse}
            ></EmptyState>
          )}

          {_map(feedbackResponse, (response) =>
            responseId === response?.answeredBy?.id ? (
              <Modal
                title={
                  <UserProfile
                    stackedName={false}
                    profile={response?.answeredBy?.profileImage}
                    name={`${response?.answeredBy?.firstName} ${response?.answeredBy?.lastName}`}
                    userId={response?.answeredBy?.id}
                    showLink
                    medium
                  />
                }
                footer={null}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}
              >
                {_map(response?.answers, (question, index) => (
                  <>
                    <QuestionTitle level={4}>
                      {index + 1}. {question?.question?.question}
                    </QuestionTitle>
                    {question?.question?.questionType ===
                      QUESTION_TYPE.OPINION_SCALE ? (
                      <Card>
                        <Row gutter={[16, 16]} className="no-wrap">
                          <Col className="flex align-items-center">
                            {question?.question?.scaleLabel?.minLabel}
                          </Col>
                          <Col span={12}>
                            {' '}
                            <Slider
                              min={0}
                              max={10}
                              value={question?.answer?.answer}
                            />
                          </Col>
                          <Col className="flex align-items-center">
                            {question?.question?.scaleLabel?.maxLabel}
                          </Col>
                        </Row>
                      </Card>
                    ) : question?.question?.questionType ===
                      QUESTION_TYPE.MCQ ? (
                      <Card>
                        <Checkbox.Group
                          options={question?.question?.choices}
                          value={question?.answer?.answer}
                        ></Checkbox.Group>
                      </Card>
                    ) : question?.question?.questionType ===
                      QUESTION_TYPE.TEXT ? (
                      <TextArea
                        value={question?.answer?.answer}
                        rows={4}
                      ></TextArea>
                    ) : (
                      ''
                    )}
                  </>
                ))}
              </Modal>
            ) : (
              ''
            )
          )}
        </>
      )}
    </ContentWrapper>
  );
}
