/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Progress,
  Spin,
  Space,
  Checkbox,
  Rate,
  Radio,
  Tooltip,
} from 'antd';
import GroupTagList from 'organisms/GroupTagList';
import UserProfile from 'molecules/UserProfile';
import Statistics from 'atoms/Statistics';
import Colors from 'design/Colors';
import Card from 'atoms/Card';
import AccessDenied from 'pages/AccessDenied';
import ContentWrapper from 'atoms/ContentWrapper';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_FEEDBACK_RESPONSE,
  GET_FEEDBACK_SUMMARY,
} from 'features/feedbacks/graphql/Queries';
import { FETCH_POLICY, LIKE_TYPE, QUESTION_TYPE } from 'utils/constants';
import PercentFormat from 'utils/PercentFormat';
import DateTime from 'atoms/DateTime';
import { MomentFormat } from 'atoms/DateTime/MomentFormat';
import _map from 'lodash/map';
import { LikeIcon, DislikeIcon } from 'assets';
import Loader from 'atoms/Loader';
import Button from 'atoms/Button';
import { AppContext } from 'AppContext';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import ability from 'utils/ability/ability';
import { END_FEEDBACK } from 'features/feedbacks/graphql/Mutations';
import moment from 'moment';
import {
  LikeDislikeWrapper,
  Modal,
  QuestionTitle,
} from '../Response/AntStyled';
import {
  Content,
  Divider,
  Title,
  Text,
  HR,
  Created,
  TextBy,
  UserDiv,
  StyledDiv,
} from './AntStyled';
import RateCard from './RateCard';

export default function Report() {
  const history = useHistory();
  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { state } = useContext(AppContext);
  const loginUserId = _get(state, 'loginUser.id', null);
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const [timeZone, setTimeZone] = useState('Asia/Kolkata');
  const userTimeZone = _get(state, 'loginUser.location.timeZone', null);
  useEffect(() => {
    if (userTimeZone) setTimeZone(userTimeZone);
  }, [userTimeZone]);
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [endFeedback] = useMutation(END_FEEDBACK, {
    onCompleted() {
      history.goBack();
    },
  });
  const { data = {}, loading, error } = useQuery(GET_FEEDBACK_SUMMARY, {
    variables: { where: { id } },
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const { feedbackSummary = {} } = data;
  const { feedback = {} } = feedbackSummary;
  const { data: responseData = {}, loading: responseLoading } = useQuery(
    GET_FEEDBACK_RESPONSE,
    {
      variables: { where: { feedbackId: id, userIds: loginUserId } },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    }
  );
  const { feedbackResponse = [] } = responseData;
  return (
    <ContentWrapper>
      {loading ? (
        <Loader />
      ) : error?.message === 'GraphQL error: You cannot view this feedback.' ? (
        <AccessDenied />
      ) : (
        <Content>
          <StyledDiv>
            <Row
              gutter={16}
              className="width-100 flex no-wrap"
              justify="center"
            >
              <Col flex="300px">
                <Card>
                  <Row>
                    <Col className="col-1">
                      <Progress
                        width={170}
                        type="circle"
                        trailColor="lightgrey"
                        strokeLinecap="square"
                        percent={PercentFormat(
                          data?.feedbackSummary?.participants,
                          data?.feedbackSummary?.responded
                        )}
                        strokeWidth={7}
                        format={(percent) => (
                          <div className="poll-content">
                            <div className="poll-head">
                              {percent}
                              <span className="poll-percent">%</span>
                            </div>
                            <div className="poll-description">
                              Poll Completion
                            </div>
                          </div>
                        )}
                      />
                      <Col className="col-statistics">
                        <Statistics
                          width="260px"
                          number={data?.feedbackSummary?.participants}
                          description="Total Participants"
                          color={Colors.TagPurple}
                        />
                      </Col>
                      <Row gutter={16}>
                        <Col className="col-statistics">
                          <Statistics
                            width="122px"
                            number={data?.feedbackSummary?.responded}
                            description="Responded"
                            color={Colors.TextSuccess}
                          />
                        </Col>
                        <Col className="col-statistics">
                          <Statistics
                            width="122px"
                            number={
                              _isNumber(
                                data?.feedbackSummary?.participants ||
                                  data?.feedbackSummary?.responded
                              )
                                ? data?.feedbackSummary?.participants -
                                  data?.feedbackSummary?.responded
                                : 0
                            }
                            description="Not Responded"
                            color={Colors.TextDanger}
                          />
                        </Col>
                      </Row>
                      <Tooltip
                        trigger="hover"
                        title={
                          feedbackResponse?.length === 0
                            ? 'You haven’t answered this feedback'
                            : ''
                        }
                        mouseLeaveDelay={0}
                      >
                        <Col className="col-statistics">
                          <Button
                            className="large-button"
                            name="View my response"
                            onClick={() => setIsModalVisible(true)}
                            primary
                            disabled={feedbackResponse?.length === 0}
                          />
                        </Col>
                      </Tooltip>
                      {ability.can('manage', 'feedbacks') &&
                      (moment(moment(feedback?.endOn).tz(timeZone)).isAfter(
                        moment()
                      ) ||
                        feedback?.endOn === null) ? (
                        <Col className="col-statistics">
                          <Button
                            className="large-button"
                            name="End this Poll"
                            onClick={() =>
                              endFeedback({ variables: { where: { id } } })
                            }
                            secondary
                          ></Button>
                        </Col>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col flex="auto">
                <Card className="card-2">
                  <Title>Poll: {feedback?.title}</Title>
                  <Text type="secondary">{feedback?.description}</Text>
                  <div className="departments-tag-row">
                    <GroupTagList
                      type="department"
                      departments={feedback?.groups}
                    />
                  </div>
                  <HR type="horizontal" />
                  <Row justify="start">
                    <Col className="timeline">
                      <Created>Created on&nbsp;</Created>
                      <DateTime
                        date={feedback?.scheduleOn}
                        format={MomentFormat.at}
                      />
                    </Col>
                    <Col className="col-end">
                      {feedback?.endOn ? (
                        <>
                          <Created>Ends on &nbsp;</Created>
                          <DateTime
                            date={feedback?.endOn}
                            format={MomentFormat.at}
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                  <Col className="timeline text profile-col no-padding">
                    <UserDiv>
                      <TextBy className="text">Created By:&nbsp;</TextBy>
                      <UserProfile
                        size={20}
                        stackedName={false}
                        name={`${feedback?.createdBy?.firstName} ${feedback?.createdBy?.lastName}`}
                        profile={feedback?.createdBy?.profileImage}
                        userId={feedback?.createdBy?.id}
                        showLink
                      />
                    </UserDiv>
                  </Col>
                  <HR type="horizontal" />
                  <Col span={16} className="no-padding ">
                    <Title>Result conclusion</Title>
                  </Col>
                  <Col className="col-statistics-2 no-padding">
                    {feedback?.questions?.questions[0]?.questionType ===
                    QUESTION_TYPE.RATE ? (
                      <RateCard data={feedbackSummary}></RateCard>
                    ) : (
                      ''
                    )}
                    {_map(data?.feedbackSummary?.pollResult, (value, index) => (
                      <>
                        {feedback?.questions?.questions[0]?.questionType ===
                          QUESTION_TYPE.SINGLE_CHOICE ||
                        feedback?.questions?.questions[0]?.questionType ===
                          QUESTION_TYPE.MCQ ? (
                          <Statistics
                            className="noFlex"
                            number={
                              <Progress
                                width={54}
                                type="circle"
                                className="progress"
                                trailColor="lightgrey"
                                strokeLinecap="square"
                                percent={
                                  PercentFormat(
                                    data?.feedbackSummary?.responded,
                                    value
                                  ) || 0
                                }
                                strokeWidth={7}
                                format={(percent) => (
                                  <div className="poll-content-xs">
                                    <div className="poll-head-xs">
                                      {percent}
                                      <span className="poll-percent-xs">%</span>
                                    </div>
                                  </div>
                                )}
                              />
                            }
                            description={index}
                          />
                        ) : feedback?.questions?.questions[0]?.questionType ===
                          QUESTION_TYPE.LIKE ? (
                          <Statistics
                            className="noFlex"
                            number={
                              <LikeDislikeWrapper>
                                <Space>
                                  {index === LIKE_TYPE.LIKE ? (
                                    <div className="like active">
                                      <img
                                        src={LikeIcon}
                                        height={20}
                                        width={20}
                                      />
                                    </div>
                                  ) : (
                                    <div className="dislike active">
                                      <img
                                        src={DislikeIcon}
                                        height={20}
                                        width={20}
                                      />
                                    </div>
                                  )}
                                </Space>
                              </LikeDislikeWrapper>
                            }
                            description={value}
                          />
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                  </Col>
                </Card>
              </Col>
            </Row>
          </StyledDiv>
          {_map(feedbackResponse, (response) => (
            <Modal
              title={
                <UserProfile
                  profile={response?.answeredBy?.profileImage}
                  name={`${response?.answeredBy?.firstName} ${response?.answeredBy?.lastName}`}
                  userId={response?.answeredBy?.id}
                  showLink
                />
              }
              footer={null}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              width={1000}
            >
              {_map(response?.answers, (question) => (
                <>
                  <QuestionTitle level={4}>
                    {question?.question?.question}
                  </QuestionTitle>
                  <Card>
                    {question?.question?.questionType ===
                    QUESTION_TYPE.SINGLE_CHOICE ? (
                      <Radio.Group value={question?.answer?.answer}>
                        {_map(question?.question?.choices, (choice) => (
                          <Radio value={choice}>{choice}</Radio>
                        ))}
                      </Radio.Group>
                    ) : question?.question?.questionType ===
                      QUESTION_TYPE.LIKE ? (
                      <LikeDislikeWrapper>
                        <Space>
                          <div
                            className={`like ${
                              question?.answer?.answer === LIKE_TYPE.LIKE
                                ? 'active'
                                : ''
                            }`}
                          >
                            <img src={LikeIcon} height={20} width={20} />
                          </div>
                          <div
                            className={`dislike ${
                              question?.answer?.answer === LIKE_TYPE.DISLIKE
                                ? 'active'
                                : ''
                            }`}
                          >
                            <img src={DislikeIcon} height={20} width={20} />
                          </div>
                        </Space>
                      </LikeDislikeWrapper>
                    ) : question?.question?.questionType ===
                      QUESTION_TYPE.RATE ? (
                      <Row align="middle" gutter={8}>
                        <Col>
                          <Rate
                            disabled
                            value={question?.answer?.answer}
                          ></Rate>
                        </Col>

                        <Col>{question?.answer?.answer} out of 5 stars</Col>
                      </Row>
                    ) : question?.question?.questionType ===
                      QUESTION_TYPE.MCQ ? (
                      <Checkbox.Group
                        options={question?.question?.choices}
                        value={question?.answer?.answer}
                      ></Checkbox.Group>
                    ) : (
                      ''
                    )}
                  </Card>
                </>
              ))}
            </Modal>
          ))}
        </Content>
      )}
    </ContentWrapper>
  );
}
