import { defineAbility } from '@casl/ability';

/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
}

const ability = defineAbility({ subjectName }, (can) => {
  can('read', 'all');
});

export default ability;

export const verifyAbility = (action, subject) => {
  if (!action || !subject) {
    return false;
  }

  return ability.can(action, subject);
};

export const abilitySubject = {
  Dashboard: 'Dashboard',
  Comment: 'Comment',
  CheckIn: 'CheckIn',
  Action: 'Action',
  Group: 'Group',
  WorkUpdate: 'WorkUpdate',
  User: 'User',
  Standup: 'standups_feature',
  Feedback: 'feedbacks_feature',
  Quotes: 'quotes_feature',
  Announcement: 'announcements_feature',
};

export const abilityActions = {
  new_comment: 'new_comment',
  access_team_detail_view: 'access_team_detail_view',
  assign_blocker_to_self: 'assign_blocker_to_self',
  assign_blocker_to_other: 'assign_blocker_to_other',
  archive_daily_goals: 'archive_daily_goals',
  archive_blockers: 'archive_blockers',
  access_public_profile: 'access_public_profile',
  add_members_to_department: 'add_members_to_department',
  remove_members_from_department: 'remove_members_from_department',
  comment_on_others_daily_goals: 'comment_on_others_daily_goals',
  delete_others_comments_from_daily_goals:
    'delete_others_comments_from_daily_goals',
  delete_own_comments_from_daily_goals: 'delete_own_comments_from_daily_goals',
  delete_others_comments_from_blocker: 'delete_others_comments_from_blocker',
  delete_own_comments_from_blocker: 'delete_own_comments_from_blocker',
  show_updates: 'show_updates',
  show_people: 'show_people',
  comment_on_own_daily_goals: 'comment_on_own_daily_goals',
};
