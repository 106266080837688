const fontStyle = 'Inter, sans-serif';
const defaultSize = '16px';

const PrimaryFontName = 'Inter';
const PrimaryFontFamily = 'Inter, sans-serif';
const SecondaryFontName = 'Roboto';
const SecondaryFontFamily = 'Roboto, serif';

const fonts = {
  xTiny: {
    size: '0.450em',
    regular: `400 0.450em ${fontStyle}`,
    medium: `500 0.450em ${fontStyle}`,
    bold: `600 0.450em ${fontStyle}`,
  },
  tiny: {
    size: '0.60em',
    regular: `400 0.60em ${fontStyle}`,
    medium: `500 0.60em ${fontStyle}`,
    bold: `600 0.60em ${fontStyle}`,
  },
  // 10px
  xxSmall: {
    size: '0.710em',
    regular: `400 0.710em ${fontStyle}`,
    medium: `500 0.710em ${fontStyle}`,
    bold: `600 0.710em ${fontStyle}`,
  },
  // 12px
  xSmall: {
    size: '0.875em',
    regular: `400 0.875em ${fontStyle}`,
    medium: `500 0.875em ${fontStyle}`,
    bold: `600 0.875em ${fontStyle}`,
  },
  // 14px
  small: {
    size: '1.000em',
    regular: `400 1.000em ${fontStyle}`,
    medium: `500 1.000em ${fontStyle}`,
    bold: `600 1.000em ${fontStyle}`,
  },
  // 16px
  medium: {
    size: '1.125em',
    regular: `400 1.125em ${fontStyle}`,
    medium: `500 1.125em ${fontStyle}`,
    bold: `600 1.125em ${fontStyle}`,
  },
  // 18px
  large: {
    size: '1.250em',
    regular: `400 1.250em ${fontStyle}`,
    medium: `500 1.250em ${fontStyle}`,
    bold: `600 1.250em ${fontStyle}`,
  },
  // 20px
  xLarge: {
    size: '1.375em',
    regular: `400 1.375em ${fontStyle}`,
    medium: `500 1.375em ${fontStyle}`,
    bold: `600 1.375em ${fontStyle}`,
  },
  // 22px
  xxLarge: {
    size: '1.500em',
    regular: `400 1.500em ${fontStyle}`,
    medium: `500 1.500em ${fontStyle}`,
    bold: `600 1.500em ${fontStyle}`,
  },
  Big: {
    size: '1.710em',
    regular: `400 1.710em ${fontStyle}`,
    medium: `500 1.710em ${fontStyle}`,
    bold: `600 1.710em ${fontStyle}`,
  },
  huge: {
    size: '2.00em',
    regular: `400 2.00em ${fontStyle}`,
    medium: `500 2.00em ${fontStyle}`,
    bold: `600 2.00em ${fontStyle}`,
  },
  title: {
    size: '2.25em',
    regular: `400 2.25em ${fontStyle}`,
    medium: `500 2.25em ${fontStyle}`,
    bold: `600 2.25em ${fontStyle}`,
  },
  xHuge: {
    size: '2.500em',
    regular: `400 2.500em ${fontStyle}`,
    medium: `500 2.500em ${fontStyle}`,
    bold: `600 2.500em ${fontStyle}`,
  },
  xxHuge: {
    size: '3.00em',
    regular: `400 3.00em ${fontStyle}`,
    medium: `500 3.00em ${fontStyle}`,
    bold: `600 3.00em ${fontStyle}`,
  },
};

export {
  defaultSize,
  fonts,
  fontStyle,
  PrimaryFontName,
  PrimaryFontFamily,
  SecondaryFontName,
  SecondaryFontFamily,
};
