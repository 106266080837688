import React from 'react';
import { Col, Row as AntRow } from 'antd';
import Spacings from 'design/Spacings';
import styled from 'styled-components';
import Colors from 'design/Colors';

const TemplateRow = styled(AntRow)`
  background-color:${Colors.TemplateBackground};
  h3.section-heading{
    padding-bottom:${Spacings.xSmall.padding};
    /* border-bottom:2px solid ${Colors.Border} */
  }
  h3.ant-typography{
    color:${Colors.TemplateHeading};
    font-weight:500;
  }
`;

const SettingsTemplate = ({ children, title }) => (
  <TemplateRow gutter={[0, 0]}>
    {title && (
      <Col span={24}>
        <h3 className="section-heading">{title}</h3>
      </Col>
    )}
    <Col span={24}>{children}</Col>
  </TemplateRow>
);

export default SettingsTemplate;
