import React, { useEffect, useState } from 'react';
import { Empty } from 'antd';
import { workUpdateWidgets } from 'features';
import UpdatesTemplate from 'templates/Updates/UpdatesTemplate';
import ContentWrapper from 'atoms/ContentWrapper';
import Loader from 'atoms/Loader';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import client from 'apollo';
import { UPDATE_WORK_UPDATE_USER } from 'graphql/Updates/Mutations';
import { EMPTY_ARRAY } from 'utils/constants';
import { ADD_TO_TODOS } from 'graphql/Todos/Mutations';
import NoWidget from 'templates/Updates/NoWidget';
import { InfiniteScrollTableWrapper } from 'design/Styled';
import InfiniteScroll from 'react-infinite-scroller';
import { LoaderDiv } from 'features/dailyGoals/templates/DailyGoals/AntStyled';
import Button from 'atoms/Button';
import ButtonDropdown from 'molecules/ButtonDropdown';
import { EmptyWrapper } from './AntStyled';

const EmptyState = ({
  title,
  description,
  image,
  buttonTitle,
  onClick,
  buttonDropdown,
}) => (
  <EmptyWrapper>
    <Empty
      image={image}
      imageStyle={{
        height: 60,
      }}
      description={
        <>
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </>
      }
    >
      {buttonDropdown ? (
        <ButtonDropdown title={buttonTitle} />
      ) : (
        <Button type="primary" name={buttonTitle} onClick={onClick} />
      )}
    </Empty>
  </EmptyWrapper>
);

export default EmptyState;
