import styled from 'styled-components';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { x, y } from 'design/Styled';
import Spacings from 'design/Spacings';

export const Container = styled.div`
  ${y};
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2em;

  .ant-card {
    width: 100%;
    max-width: 408px;
  }
`;

export const Description = styled.div`
  font: ${fonts.xSmall.regular};
  color: ${Colors.Text};
  text-align: center;
`;

export const Title = styled.div`
   font: ${fonts.large.bold};
  color: ${Colors.TextDark};
  text-align: center;
  margin-top: ${Spacings.small.margin};
  margin-bottom: ${Spacings.small.margin};
`;

export const ImageWrapper = styled.div`
  ${x};
  justify-content: center;
`;

export const Image = styled.img`
  height: 15em;
`;
