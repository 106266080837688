import styled from 'styled-components';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { Row, Form as AntForm, Select as AntSelect } from 'antd';

export const Select = styled(AntSelect)`
  font: ${fonts.small.regular};
  color: ${Colors.TextSecondary};
  border: 1px solid ${Colors.SecondaryColor};
  border-radius: 8px;
  height: 32px;
  min-width: 152px;
  max-width: 200px;
  display: table-cell;
  word-wrap: break-word;
  word-break: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.BorderHover};
  }
  &:focus {
    box-shadow: none !important;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 12px;
    border: none;
    height: 30px;
    background-color: transparent;
  }
  .ant-select-selection-placeholder {
    opacity: 1;
  }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item{
   padding-right:0px;
   margin-right:20px;
  }
`;
export const Form = styled(AntForm)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const TaskContent = styled.div`
  .task-status {
    &.modify:hover {
      color: ${Colors.PrimaryColor};
      cursor: pointer;
    }
    color: ${Colors.IconColor};
    font-size: ${fonts.large.size};
    line-height: 0;
  }
  .anticon-check {
    color: ${Colors.ButtonPrimary};
    font: ${fonts.xxLarge.regular};
  }
  ul.ant-skeleton-paragraph {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .icon-delete {
    cursor: pointer;
    color: ${Colors.IconColor};
    &:hover {
      color: ${Colors.TextDanger};
    }
    font-size: ${fonts.xLarge.size};
  }

  .ant-form-inline .ant-form-item {
    margin-right: 0;
  }
  .ant-checkbox-wrapper {
    margin-right: ${Spacings.xSmall.margin};
  }

  .ant-col-8 {
    display: flex;
  }
  & td.task-checkbox {
    .ant-checkbox-wrapper {
      margin-right: 0;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: ${Colors.LightDark};
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${Colors.PrimaryColor};
      border-color: ${Colors.PrimaryColor}!important;
    }
  }
`;
export const Arrow = styled.img`
  position: absolute;
  top: 53%;
  right: 2px;
  margin-top: -4px;
`;
export const Image = styled.img`
  width: 18px;
  height: 18px;
`;
export const Title = styled.div`
  white-space: normal;
  word-break: break-all;
  min-width:250px;
  @media (max-width: 850px) {
    min-width:100px;
  }
`;

export const ContentTitle = styled.div`
  font: ${fonts.small.bold};
  padding: ${Spacings.xSmall.padding};
  background: ${Colors.Container};
  color: ${Colors.TextPrimary};
  margin-left: 32px;
`;

export const Comments = styled.div`
  color: ${Colors.ButtonPrimary};
  font: ${fonts.medium.regular};
  cursor: pointer;
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DailyGoalsCount = styled(Row)`
  display: flex;
  justify-content: center;
  padding: 16px;

  span {
    font: 400 0.875em Roboto, sans-serif;
  }
`;
