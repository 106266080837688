import React from 'react';
import { Layout } from 'antd';
import {
  Switch, Route, Router, withRouter,
} from 'react-router-dom';
import Meta from 'atoms/Meta';
import * as Sentry from '@sentry/react';
import Page404 from 'pages/404';
import { PRIVATE_ROUTE, META_TYPE } from 'utils/constants';
import DailyGoals from './DailyGoals';
import Me from './Me';
import Blockers from './Blockers';

const DailyGoalsMain = ({ history }) => (
  <Sentry.ErrorBoundary fallback="Something went wrong">
    <Layout>
      <Meta title={META_TYPE.LEAVES} />
      <Router history={history}>
        <Switch>
          <Route path={PRIVATE_ROUTE.ME} component={Me.component} />
          <Route path={PRIVATE_ROUTE.BLOCKER} component={Blockers.component} />
          <Route
            path={PRIVATE_ROUTE.DAILY_GOALS}
            component={DailyGoals.component}
          />
          <Route component={Page404} />
        </Switch>
      </Router>
    </Layout>
  </Sentry.ErrorBoundary>
);

export default {
  key: PRIVATE_ROUTE.DAILY_GOALS,
  component: withRouter(DailyGoalsMain),
  name: 'Daily Goals',
  icon: (
    <i
      fill="white"
      data-eva="bar-chart-outline"
      data-eva-height="20"
      data-eva-width="20"
    >
    </i>
  ),
  expandIcon: (
    <i
      fill="white"
      data-eva="arrow-down"
      data-eva-height="16"
      data-eva-width="16"
    >
    </i>
  ),
  subPages: [Me, DailyGoals, Blockers],
  abilitySubject: 'daily_goals_feature',
  abilityAction: 'use',
};
