import styled from 'styled-components';
import { Typography } from 'antd';
import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import Spacings from 'design/Spacings';
import { x } from 'design/Styled';
const { Text } = Typography;

export const Container = styled.div`
  &.notification.ant-row {
    display: flex;
    justify-content: space-between;
    color: ${Colors.TextDark};
    background-color: ${Colors.Container};
  }
  & .column {
    flex-direction: column;
  }
  .notification-card {
    margin-top: ${Spacings.xSmall.margin};
  }
  & .users {
    display: flex;
    align-items: center;
  }
  .btn {
    margin-top: ${Spacings.xxSmall.margin};
  }
  & .time {
    font: ${fonts.tiny.regular};
    color: ${Colors.Text};
  }
  & .ant-typography code {
    margin: 0 0.8em;
    padding: 0.4em 0.6em 0.2em;
    background: ${Colors.LightDark};
    color: ${Colors.Text};
    border: 0;
  }
  & .notification-title {
    margin-left: ${Spacings.xxSmall.margin};
    color: ${Colors.Text};
    font: ${fonts.xxSmall.medium};
    opacity: 0.7;
  }
  .name {
    font: ${fonts.xSmall.medium};
    color: ${Colors.TextDark};
    max-width: 100%;
  }
  .text-row {
    margin-left: Calc(${Spacings.small.margin} + 2px);
  }
  .ant-avatar {
    margin-right: Calc(${Spacings.xxSmall.margin} - 2px);
  }
  .ant-card-head-title {
    white-space: initial;
    padding: 0px;
  }
  .ant-card-head {
    border-bottom: 1px solid ${Colors.Border};
    padding: 12px;
    display: flex;
  }
  .ant-card-head-wrapper {
    min-width: 100%;
  }
`;

export const StyledText = styled(Text)`
  font: ${fonts.xSmall.regular};
  color: ${Colors.Text};
`;

export const Wrapper = styled.div`
  color: ${Colors.Text};
  width: 100%;
  .content {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .title {
    font-size: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #5f3996;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    width: 100%;
    max-width: 230px;
  }
  .ant-btn {
    width: 28px;
    height: 28px;
    margin-right: ${Spacings.xxSmall.margin};
  }
  .ant-avatar {
    background-color: red;
  }
  .anticon-small-dash {
    cursor: pointer;
  }
`;
export const IconContainer = styled.div`
  margin-right: ${Spacings.tiny.margin};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Invite = styled.div`
  ${x};
  .ant-tag-error {
    margin-left: ${Spacings.xxSmall.margin};
    border-radius: 10px;
    color: ${Colors.TextSecondary};
  }
`;
