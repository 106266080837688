import React, { useState } from 'react';
import { Layout as LayoutAnt, Alert } from 'antd';
import { Route, Switch, Router } from 'react-router-dom';
import styled from 'styled-components';
import Login from 'pages/Auth/Login';
import Signup from 'pages/Auth/SignUp';
import AlreadyRegistered from 'pages/Auth/AlreadyRegistered';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import AuthError from 'pages/Auth/AuthError';
import ResetPassword from 'pages/Auth/ResetPassword';
import Confirmation from 'pages/Auth/Confirmation';
import Selection from 'onboarding/Screens/Selection';
import Results from 'onboarding/Screens/Selection/Results';
import WorkSpaceLocation from 'onboarding/Screens/Selection/WorkSpaceLocation';
import Logout from 'pages/Auth/Logout';
import Colors from 'design/Colors';
import { scrollbars } from 'design/Styled';
import { PUBLIC_ROUTE } from 'utils/constants';
import GuardedRoute from 'onboarding/GuardedRoute';
import LayoutComponent from 'pages/Layout';
import { fontStyle } from 'design/Fonts';
import ability from 'utils/ability/ability';
import * as Sentry from '@sentry/react';
import TokenExpired from 'pages/Auth/TokenExpired';
import history from './history';

export default function () {
  const [alert, setAlert] = useState(false);
  const idToken = localStorage.getItem('token');

  function handleConnectionChange(event) {
    if (event.type === 'offline') {
      setAlert(true);
    }
    if (event.type === 'online') {
      setAlert(false);
    }
  }
  window.addEventListener('online', handleConnectionChange);
  window.addEventListener('offline', handleConnectionChange);

  function RedirectToLogin() {
    window.location = PUBLIC_ROUTE.LOGIN;
  }

  return (
    <Sentry.ErrorBoundary fallback={<div>Something went wrong!</div>}>
      <Switch>
        <Route exact path={PUBLIC_ROUTE.LOGIN} component={Login} />
        <Route exact path={PUBLIC_ROUTE.SIGNUP} component={Signup} />
        <Route
          exact
          path={PUBLIC_ROUTE.FORGOT_PASSWORD}
          component={ForgotPassword}
        />
        <Route exact path={PUBLIC_ROUTE.AUTH_ERROR} component={AuthError} />
        <Route exact path={PUBLIC_ROUTE.CONFIRM} component={Confirmation} />
        <Route
          exact
          path={PUBLIC_ROUTE.CONFIRM_TOKEN}
          component={Confirmation}
        />
        <Route
          exact
          path={PUBLIC_ROUTE.RESET_PASSWORD_TOKEN}
          component={ResetPassword}
        />
        <Route
          exact
          path={PUBLIC_ROUTE.ALREADY_REGISTERED}
          component={AlreadyRegistered}
        />
        <Route exact path="/selection" component={Selection} />
        <Route exact path="/selection/results" component={Results} />
        <Route exact path="/selection/location" component={WorkSpaceLocation} />
        <Route
          exact
          path={PUBLIC_ROUTE.TOKEN_EXPIRED}
          component={TokenExpired}
        />
        <Route path="*" render={RedirectToLogin} />
      </Switch>
    </Sentry.ErrorBoundary>
  );
}
