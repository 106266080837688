import styled from 'styled-components';

import Colors from 'design/Colors';
import { fonts } from 'design/Fonts';
import { y, x, link } from 'design/Styled';
import Spacings from 'design/Spacings';
import { Loginbg } from 'assets';

export const Container = styled.div`
  ${x};
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${Colors.SidebarText};
  @media (max-width: 1120px) {
    flex-direction: column;
  }
  .center {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .ant-btn-primary {
    font: ${fonts.small.medium};
    line-height: 22px;
  }
  .terms {
    margin-right: ${Spacings.Small.margin};
    color: ${Colors.TextSecondary};
    :last-child {
      margin-right: 0px;
    }
    @media (max-width: 1000px) {
      color: ${Colors.SidebarText};
    }
  }
`;
export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${Colors.Text};
  .anticon {
    margin-right: ${Spacings.xSmall.margin};
  }
  .title {
    font: ${fonts.small.regular};
    color: ${Colors.TextSecondary};
  }
`;
export const Arrow = styled.img`
  margin-right: ${Spacings.xxSmall.margin};
  height: 12px;
`;
export const Verified = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${Loginbg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  .bottom-left {
    position: absolute;
    left: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
  .bottom-right {
    position: absolute;
    right: 5%;
    top: 92%;
    font: ${fonts.xSmall.bold};
    color: ${Colors.SidebarText};
  }
`;
export const Logo = styled.img`
  max-width: 180px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 1120px) {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Title = styled.div`
  font: ${fonts.xxLarge.bold};
  color: ${Colors.TextPrimary};
  text-align: center;
  line-height: 41px;
  margin-bottom: ${Spacings.xxSmall.margin};
`;

export const Wrapper = styled.div`
  .ant-card {
    overflow: hidden;
    width: 100%;
    max-width: 408px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  @media (max-width: 1120px) {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;
export const FullDivider = styled.hr`
  border: 1px solid ${Colors.SecondaryColor};
  margin-left: -50px;
  margin-right: -50px;
  overflow: hidden;
  border-bottom: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
`;
export const Resend = styled.div`
  text-align: center;
  color: ${Colors.TextPrimary};
  font: ${fonts.small.regular};
  line-height: 22px;
  padding-top: 24px;
  padding-bottom: 6px;
  width: 100%;
  background-color: ${Colors.Container};
  align-self: center;
  .signup {
    text-decoration: underline;
    text-underline-position: under;
    color: ${Colors.PrimaryColor};
  }
`;

export const Description = styled.div`
  font-size: ${fonts.medium.regular};
  line-height: 22px;
  text-align: center;
  padding: 0px 0px 16px;
  color: ${Colors.TextSecondary};
`;
export const ConfirmLink = styled.p`
  font-size: ${fonts.small.regular};
  line-height: 20px;
  text-align: center;
  color: ${Colors.TextSecondary};
`;

export const ImageWrapper = styled.div`
  ${x};
  justify-content: center;
  padding: 0px 0px 24px;
`;
export const EmailPop = styled.img`
  width: 100px;
  height: 100px;
  image-rendering: crisp-edges;
`;
export const Terms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 480px;
  padding-top: 10px;
  align-self: center;
  font: ${fonts.xSmall.bold};
`;
