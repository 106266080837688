import React from 'react';
import GroupTag from 'atoms/GroupTag';
import UserProfile from 'molecules/UserProfile';
import { GROUP_TYPE } from 'utils/constants';

const GroupOrUser = ({
  data: { group, user },
  dropDown,
  stackedName = false,
  size = 'small',
  medium,
  bold,
  className,
  showLink = true,
}) => {
  const element = typeof group === 'string' ? JSON.parse(group) : group;
  const userElement = typeof user === 'string' ? JSON.parse(user) : user;
  // eslint-disable-next-line no-nested-ternary
  return element ? (
    <GroupTag
      color={element.color}
      name={element.name}
      type={GROUP_TYPE[element?.type?.toUpperCase()]}
    />
  ) : userElement && userElement !== 'null' ? (
    <UserProfile
      stackedName={stackedName}
      dropDown={dropDown}
      profile={userElement?.profileImage}
      name={
        userElement?.firstName 
          ? `${userElement?.firstName} ${userElement?.lastName}`
          : userElement?.email
      }
      userId={userElement?.id}
      showLink={showLink}
      size={size}
      bold={bold}
      medium={medium}
      className={className}
    />
  ) : null;
};

export default GroupOrUser;
