import { gql } from '@apollo/client';

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription notification {
    notification
  }
`;

export const GET_USER_SUBSCRIPTION = gql`
  subscription userData {
    userData
  }
`;
